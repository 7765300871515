import FilledTextField from "core/inputs/FilledTextField";
import React from "react";
import {usePlacesWidget} from "react-google-autocomplete";
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export default function AddressAutocomplete({
  label,
  placeholder,
  onPlaceSelected = () => null,
  textFieldProps = {},
}) {
  const {ref} = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: onPlaceSelected,
    options: {
      types: ["address"], // https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
      fields: ["address_components", "formatted_address", "geometry.location"], // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
    },
  });

  return (
    <FilledTextField
      fullWidth
      label={label}
      inputRef={ref}
      placeholder={placeholder}
      {...textFieldProps}
    />
  );
}
