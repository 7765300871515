import React from "react";
import {Typography, makeStyles} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import WarningIcon from "assets/icons/WarningIcon";
import {useTranslation} from "react-i18next";
import clsx from "clsx";

const error_color = "#d32f2f";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },
  phoneInput: {
    backgroundColor: "transparent!important",
    border: "none !important",
    color: theme.palette.primary.main + "!important",
    height: 57,
    borderRadius: "13px !important",
    padding: "18.5px 0 18.5px 60px !important",
    width: "100%!important",
    "&:focus": {boxShadow: "none!important"},
    "&:disabled": {
      backgroundColor: theme.palette.action.disabledBackground + "!important",
    },
  },
  phoneInputDropdown: {border: "none!important"},
  label: {
    fontSize: 12,
    position: "absolute",
    zIndex: 2,
    backgroundColor: theme.palette.primary.contrast,
    left: 11,
    top: -10,
    display: "inline-block",
    padding: "0px 4px",
    borderRadius: 10,
    "&.-error": {color: error_color},
  },
  phoneInputContainer: {
    border: `solid 2px ${theme.palette.primary.main}`,
    width: "99.5%",
    borderRadius: 15,
    "&.-error": {border: `solid 2px #d32f2f`},
    "& > .flag-dropdown": {
      borderRight: `solid 2px ${theme.palette.primary.main} !important`,
      backgroundColor: theme.palette.primary.contrast,
      borderRadius: "15px 0 0 15px",
    },
  },
}));

export default function KeycardPhoneComponent({
  label,
  country,
  error,
  required,
  initialCode,
  phone,
  onChange,
  ...other
}) {
  const classes = useStyles();
  const {t} = useTranslation();

  const onPhoneChange = (value, country, e, formattedValue) => {
    onChange(value);
  };

  return (
    <div className={classes.container}>
      <PhoneInput
        enableSearch
        country={country}
        specialLabel=""
        disableSearchIcon
        countryCodeEditable={false}
        placeholder=""
        priority={{ca: 0, us: 1}}
        preferredCountries={["ca", "us", "fr"]}
        dropdownClass={classes.phoneInputDropdown}
        containerClass={clsx(classes.phoneInputContainer, {"-error": !!error})}
        inputClass={classes.phoneInput}
        onChange={onPhoneChange}
        value={phone}
        {...other}
      />
      {!!label && (
        <Typography className={clsx(classes.label, {"-error": !!error})}>
          {label}
          {required && "*"}
        </Typography>
      )}
      {error && (
        <>
          <WarningIcon
            style={{marginBottom: -12, marginLeft: 15, color: error_color}}
          />
          <span style={{color: error_color, fontSize: 13}}>
            {t("phone_number")} {t("is_required")}
          </span>
        </>
      )}
    </div>
  );
}
