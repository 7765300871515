import React from "react";
// UI
import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import {darken, makeStyles} from "@material-ui/core/styles";
import RightArrowIcon from "@material-ui/icons/NavigateNext";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(3),
    backgroundColor: "#F1F3F9",
    borderRadius: 10,
    maxHeight: 83,
    "&:hover": {backgroundColor: darken("#F1F3F9", 0.02) + "!important"},
  },
  itemSelected: {backgroundColor: darken("#F1F3F9", 0.05) + "!important"},
  listItemWhite: {
    padding: theme.spacing(3, 4),
    borderBottom: "1px solid rgba(13,86,140,0.03)",
  },
  listItemText: {margin: 0},
  icon: {
    color: "rgba(0,0,0,0.5)",
    minWidth: 0,
    opacity: 0.5,
    paddingRight: theme.spacing(4),
  },
  title: {color: "#000"},
  text: {
    color: "#707070",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    WebkitLineClamp: 1,
  },
  arrow: {
    marginLeft: theme.spacing(3),
    color: "rgba(60, 60, 67, 0.301961)",
  },
  secondaryAction: {right: theme.spacing(4)},
}));

export default function FilledItem({
  title,
  text,
  icon,
  onClick,
  disabled,
  actions,
  selected,
  disableBackground,
  hideArrow,
}) {
  const classes = useStyles();
  const btnProps = !disableBackground
    ? {button: true, disableRipple: true}
    : {};

  return (
    <ListItem
      disabled={disabled}
      onClick={onClick}
      selected={selected}
      className={clsx({
        [classes.listItem]: !disableBackground,
        [classes.listItemWhite]: !!disableBackground,
      })}
      classes={{selected: classes.itemSelected}}
      {...btnProps}
    >
      {!!icon && <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>}
      <ListItemText
        primary={title}
        secondary={text}
        className={classes.listItemText}
        primaryTypographyProps={{variant: "h1", className: classes.title}}
        secondaryTypographyProps={{variant: "body1", className: classes.text}}
      />
      {!hideArrow && !actions && <RightArrowIcon className={classes.arrow} />}
      {!!actions && (
        <ListItemSecondaryAction className={classes.secondaryAction}>
          {actions}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
