// https://www.xe.com/symbols/

const currencySymbols = {
  USD: {symbol: "$", suffix: " USD"},
  AED: {symbol: "د.إ", suffix: " AED"},
  AFN: {symbol: "؋", suffix: " AFN"},
  ALL: {symbol: "L", suffix: " ALL"},
  AMD: {symbol: "֏", suffix: " AMD"},
  ANG: {symbol: "ƒ", suffix: " ANG"},
  AOA: {symbol: "Kz", suffix: " AOA"},
  ARS: {symbol: "$", suffix: " ARS"},
  AUD: {symbol: "A$", suffix: " AUD"},
  AWG: {symbol: "ƒ", suffix: " AWG"},
  AZN: {symbol: "₼", suffix: " AZN"},
  BAM: {symbol: "KM", suffix: " BAM"},
  BDT: {symbol: "৳", suffix: " BDT"},
  BGN: {symbol: "лв", suffix: " BGN"},
  BHD: {symbol: ".د.ب", suffix: " BHD"},
  BIF: {symbol: "FBu", suffix: " BIF"},
  BMD: {symbol: "$", suffix: " BMD"},
  BND: {symbol: "B$", suffix: " BND"},
  BOB: {symbol: "Bs.", suffix: " BOB"},
  BRL: {symbol: "R$", suffix: " BRL"},
  BSD: {symbol: "B$", suffix: " BSD"},
  BTN: {symbol: "Nu.", suffix: " BTN"},
  BWP: {symbol: "P", suffix: " BWP"},
  BYN: {symbol: "Br", suffix: " BYN"},
  BZD: {symbol: "BZ$", suffix: " BZD"},
  CAD: {symbol: "C$", suffix: " CAD"},
  CDF: {symbol: "FC", suffix: " CDF"},
  CHF: {symbol: "CHF", suffix: ""},
  CLP: {symbol: "$", suffix: " CLP"},
  CNY: {symbol: "¥", suffix: " CNY"},
  COP: {symbol: "$", suffix: " COP"},
  CRC: {symbol: "₡", suffix: " CRC"},
  CUP: {symbol: "$", suffix: " CUP"},
  CVE: {symbol: "$", suffix: " CVE"},
  CZK: {symbol: "Kč", suffix: " CZK"},
  DJF: {symbol: "Fdj", suffix: " DJF"},
  DKK: {symbol: "kr", suffix: " DKK"},
  DOP: {symbol: "RD$", suffix: " DOP"},
  DZD: {symbol: "دج", suffix: " DZD"},
  EGP: {symbol: "£", suffix: " EGP"},
  ERN: {symbol: "Nfk", suffix: " ERN"},
  ETB: {symbol: "Br", suffix: " ETB"},
  EUR: {symbol: "€", suffix: ""},
  FJD: {symbol: "FJ$", suffix: " FJD"},
  FKP: {symbol: "£", suffix: " FKP"},
  GBP: {symbol: "£", suffix: " GBP"},
  GEL: {symbol: "₾", suffix: " GEL"},
  GHS: {symbol: "GH₵", suffix: " GHS"},
  GIP: {symbol: "£", suffix: " GIP"},
  GMD: {symbol: "D", suffix: " GMD"},
  GNF: {symbol: "FG", suffix: " GNF"},
  GTQ: {symbol: "Q", suffix: " GTQ"},
  GYD: {symbol: "G$", suffix: " GYD"},
  HKD: {symbol: "HK$", suffix: " HKD"},
  HNL: {symbol: "L", suffix: " HNL"},
  HRK: {symbol: "kn", suffix: " HRK"},
  HTG: {symbol: "G", suffix: " HTG"},
  HUF: {symbol: "Ft", suffix: " HUF"},
  IDR: {symbol: "Rp", suffix: " IDR"},
  ILS: {symbol: "₪", suffix: " ILS"},
  INR: {symbol: "₹", suffix: " INR"},
  IQD: {symbol: "ع.د", suffix: " IQD"},
  IRR: {symbol: "﷼", suffix: " IRR"},
  ISK: {symbol: "kr", suffix: " ISK"},
  JMD: {symbol: "J$", suffix: " JMD"},
  JOD: {symbol: "JD", suffix: " JOD"},
  JPY: {symbol: "¥", suffix: " JPY"},
  KES: {symbol: "KSh", suffix: " KES"},
  KGS: {symbol: "лв", suffix: " KGS"},
  KHR: {symbol: "៛", suffix: " KHR"},
  KMF: {symbol: "CF", suffix: " KMF"},
  KPW: {symbol: "₩", suffix: " KPW"},
  KRW: {symbol: "₩", suffix: " KRW"},
  KWD: {symbol: "KD", suffix: " KWD"},
  KYD: {symbol: "CI$", suffix: " KYD"},
  KZT: {symbol: "₸", suffix: " KZT"},
  LAK: {symbol: "₭", suffix: " LAK"},
  LBP: {symbol: "£", suffix: " LBP"},
  LKR: {symbol: "Rs", suffix: " LKR"},
  LRD: {symbol: "L$", suffix: " LRD"},
  LSL: {symbol: "L", suffix: " LSL"},
  LYD: {symbol: "LD", suffix: " LYD"},
  MAD: {symbol: "MAD", suffix: ""},
  MDL: {symbol: "L", suffix: " MDL"},
  MGA: {symbol: "Ar", suffix: " MGA"},
  MKD: {symbol: "ден", suffix: " MKD"},
  MMK: {symbol: "K", suffix: " MMK"},
  MNT: {symbol: "₮", suffix: " MNT"},
  MOP: {symbol: "P", suffix: " MOP"},
  MRU: {symbol: "UM", suffix: " MRU"},
  MUR: {symbol: "₨", suffix: " MUR"},
  MVR: {symbol: "Rf", suffix: " MVR"},
  MWK: {symbol: "MK", suffix: " MWK"},
  MXN: {symbol: "$", suffix: " MXN"},
  MYR: {symbol: "RM", suffix: " MYR"},
  MZN: {symbol: "MT", suffix: " MZN"},
  NAD: {symbol: "$", suffix: " NAD"},
  NGN: {symbol: "₦", suffix: " NGN"},
  NIO: {symbol: "C$", suffix: " NIO"},
  NOK: {symbol: "kr", suffix: " NOK"},
  NPR: {symbol: "₨", suffix: " NPR"},
  NZD: {symbol: "NZ$", suffix: " NZD"},
  OMR: {symbol: "ر.ع.", suffix: " OMR"},
  PAB: {symbol: "B/.", suffix: " PAB"},
  PEN: {symbol: "S/", suffix: " PEN"},
  PGK: {symbol: "K", suffix: " PGK"},
  PHP: {symbol: "₱", suffix: " PHP"},
  PKR: {symbol: "₨", suffix: " PKR"},
  PLN: {symbol: "zł", suffix: " PLN"},
  PYG: {symbol: "₲", suffix: " PYG"},
  QAR: {symbol: "ر.ق", suffix: " QAR"},
  RON: {symbol: "lei", suffix: " RON"},
  RSD: {symbol: "дин", suffix: " RSD"},
  RUB: {symbol: "₽", suffix: " RUB"},
  RWF: {symbol: "FRw", suffix: " RWF"},
  SAR: {symbol: "ر.س", suffix: " SAR"},
  SBD: {symbol: "SI$", suffix: " SBD"},
  SCR: {symbol: "₨", suffix: " SCR"},
  SDG: {symbol: "ج.س.", suffix: " SDG"},
  SEK: {symbol: "kr", suffix: " SEK"},
  SGD: {symbol: "S$", suffix: " SGD"},
  SHP: {symbol: "£", suffix: " SHP"},
  SLL: {symbol: "Le", suffix: " SLL"},
  SOS: {symbol: "Sh", suffix: " SOS"},
  SRD: {symbol: "$", suffix: " SRD"},
  SSP: {symbol: "£", suffix: " SSP"},
  STN: {symbol: "Db", suffix: " STN"},
  SVC: {symbol: "$", suffix: " SVC"},
  SYP: {symbol: "£", suffix: " SYP"},
  SZL: {symbol: "E", suffix: " SZL"},
  THB: {symbol: "฿", suffix: " THB"},
  TJS: {symbol: "ЅМ", suffix: " TJS"},
  TMT: {symbol: "m", suffix: " TMT"},
  TND: {symbol: "د.ت", suffix: " TND"},
  TOP: {symbol: "T$", suffix: " TOP"},
  TRY: {symbol: "₺", suffix: " TRY"},
  TTD: {symbol: "TT$", suffix: " TTD"},
  TWD: {symbol: "NT$", suffix: " TWD"},
  TZS: {symbol: "Sh", suffix: " TZS"},
  UAH: {symbol: "₴", suffix: " UAH"},
  UGX: {symbol: "USh", suffix: " UGX"},
  ZAR: {symbol: "R", suffix: " ZAR"},
};

export function getCurrencyFormat(currency) {
  const defaultCurrency = "USD";
  const currencyInfo =
    currencySymbols[currency] || currencySymbols[defaultCurrency];
  return {
    prefix: currencyInfo.symbol,
    suffix: currencyInfo.suffix,
  };
}
