import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {
  Typography,
  Button,
  IconButton,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import EditIcon from "@material-ui/icons/Edit";
// Custom
import OperationHoursDates from "../../Lists/OperationHoursDates";
import VirtualizedGrid from "components/Grids/VirtualizedGrid";
import CustomCardHeader from "core/cards/CustomCardHeader";
import PrimaryButton from "core/buttons/PrimaryButton";
import CustomDialog from "core/dialogs/CustomDialog";
import ListingContentList from "../../Lists/ListingContentList";
import Temperature from "../../TextFields/Temperature";
import SimpleInfo from "../../TextFields/SimpleInfo";
import Cost from "../../TextFields/Cost";
// Actions
import {updateListing} from "redux/actions/listingsActions";
// Utilities
import {AmenityTypes, DefaultAmenityFields} from "configuration/enums.js";
import {amenityLabels, amenityTypeLabels} from "configuration/constants";
import {THEME} from "configuration/settings";
import clsx from "clsx";
import _ from "lodash";

const amenityHeight = 38;
const amenityTypes = Object.keys(AmenityTypes);
const avalAmenities = {};
_.each(
  amenityTypes,
  (at) => (avalAmenities[at] = Object.keys(AmenityTypes[at])),
);

const emptyHoursData = {
  mon: ["7:00", "22:00"],
  tue: ["7:00", "22:00"],
  wed: ["7:00", "22:00"],
  thu: ["7:00", "22:00"],
  fri: ["7:00", "22:00"],
  sat: ["7:00", "22:00"],
  sun: ["7:00", "22:00"],
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  cardHeader: {padding: theme.spacing(4)},
  cancelBtn: {
    color: "#A8A8A8",
    fontSize: 14,
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4, 0),
  },
  divider: {borderTop: `1px solid rgba(13,86,140,0.05)`},
  menuList: {padding: "8px 0 !important"},
  menuItem: {padding: theme.spacing(1, 4)},
  menuLabel: {
    ...THEME.customTypography.title2,
    color: "rgba(60, 60, 67, 0.85098)",
  },
  amenityRow: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& .add-icon": {opacity: 0},
    "&:hover": {
      "& .add-icon": {opacity: 1},
    },
  },
  amenityLabel: {
    textTransform: "none",
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: "rgba(0, 0, 0, 0.870588)",
    "&.-not-checked": {color: "rgba(0, 0, 0, 0.65)"},
  },
  checkbox: {
    backgroundColor: "#FFF !important",
    padding: 0,
    paddingRight: 9,
  },
  iconBtn: {transition: "0.15s"},
  addIcon: {
    color: "#C4C4C4",
    fontSize: 16,
  },
  gridItem: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 4),
  },
  amenitiesContainer: {flexGrow: 1},
}));

function AmenitiesPanel({
  outsideView,
  autoHeight,
  hideTitleRow,
  listing,
  selectedAmenities = [],
  onChange,
  create,
  onClose,
  width,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.defaultReducer.language);
  const [metadataOpen, setMetadataOpen] = useState(false);
  const [selectedAmenity, setSelectedAmenity] = useState(null);
  const [currAmenities, setCurrAmenities] = useState(null);
  const [metadataFields, setMetadataFields] = useState(null);
  const amenityIds = selectedAmenities.map((a) => a.id);

  const columns = React.useMemo(() => (width === "lg" ? 3 : 2), [width]);
  const invalidHours = React.useMemo(() => {
    if (!!metadataFields?.hours) {
      const keys = Object.keys(metadataFields.hours);
      if (keys.some((k) => metadataFields.hours[k].includes(null))) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }, [metadataFields]);

  const totalHeight = React.useMemo(() => {
    if (!autoHeight) {
      return 0;
    }
    const padding = 32;
    let totalRowsHeight = 0;
    _.each(
      amenityTypes,
      (at, ind) => (totalRowsHeight += getRowHeight({index: ind})),
    );

    return totalRowsHeight + padding;
  }, [columns]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (!!currAmenities && !!onChange) {
        const newData = getData();
        if (!_.isEqual(newData, selectedAmenities)) {
          onChange(newData);
        }
      }
    }, 200);

    return () => clearTimeout(timer);
  }, [currAmenities]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const selectedAmenitiesObj = {};
      const newAmenities = {};

      _.each(selectedAmenities, (am) => {
        selectedAmenitiesObj[am.id] = am;
      });
      _.each(amenityTypes, (at) => {
        _.each(avalAmenities[at], (k) => {
          newAmenities[k] = amenityIds.includes(k)
            ? {...selectedAmenitiesObj[k]}
            : {enabled: false, new: true};
        });
      });
      setCurrAmenities((prev) => newAmenities);
    });

    return () => clearTimeout(timer);
  }, [selectedAmenities]);

  React.useEffect(() => {
    if (!!selectedAmenity) {
      const existingAmenity = !!currAmenities[selectedAmenity.key].new
        ? currAmenities[selectedAmenity.key]
        : selectedAmenities.find((am) => am.id === selectedAmenity.key);
      const defaultFields = DefaultAmenityFields[selectedAmenity.type] || [];
      const additionalFields =
        AmenityTypes[selectedAmenity.type][selectedAmenity.key] || [];
      const options = [...additionalFields, ...defaultFields, "special_note"];

      const newFields = {};
      _.each(options, (opt) => {
        if (opt === "hours") {
          newFields[opt] = !!existingAmenity
            ? existingAmenity.hours || emptyHoursData
            : emptyHoursData;
        } else {
          newFields[opt] = !!existingAmenity
            ? existingAmenity[opt] || [{language: language, value: ""}]
            : [{language: language, value: ""}];
        }
      });

      setMetadataFields((prev) => newFields);
      setMetadataOpen((prev) => true);
    }
  }, [selectedAmenity]);

  React.useEffect(() => {
    let timer = null;
    if (!metadataOpen) {
      setSelectedAmenity((prev) => null);
      timer = setTimeout(() => {
        setMetadataFields((prev) => null);
      }, 100);
    }

    return () => clearTimeout(timer);
  }, [metadataOpen]);

  const closeMetadataDialog = () => setMetadataOpen((prev) => false);
  const onCancel = () => onClose();

  function getData() {
    return Object.keys(currAmenities)
      .filter(
        (am) =>
          currAmenities[am].hasOwnProperty("special_note") ||
          !!currAmenities[am].enabled,
      )
      .map((am) => {
        const newAmenity = {
          id: am,
          quantity: 1,
          description: [{language: "en", value: ""}],
          ...currAmenities[am],
        };
        delete newAmenity.new;
        return newAmenity;
      });
  }

  const saveMetadata = () => {
    const amenityKey = selectedAmenity.key;
    setCurrAmenities((prev) => ({
      ...prev,
      [amenityKey]: {...prev[amenityKey], ...metadataFields},
    }));
    setMetadataOpen((prev) => false);
  };

  const openMetadataMenu = (amenityType, amenityKey) => (e) => {
    setSelectedAmenity((prev) => ({type: amenityType, key: amenityKey}));
  };

  const onCheck = (amenityKey) => (e) => {
    const val = e.target.checked;
    setCurrAmenities((prev) => ({
      ...prev,
      [amenityKey]: {...prev[amenityKey], enabled: val},
    }));
  };

  const handleSave = () => {
    const newData = getData();
    dispatch(updateListing({body: {amenities: newData}, listing}));
    onClose();
  };

  const handleMetadataChange = (type) => (newValue) => {
    setMetadataFields((prev) => ({...prev, [type]: newValue}));
  };

  function getRowHeight({index}) {
    const listSize = avalAmenities[amenityTypes[index]].length;
    const rowTitleHeight = 32;
    const paddingBottom = 32;

    return (
      Math.ceil(listSize / columns) * amenityHeight +
      rowTitleHeight +
      paddingBottom
    );
  }

  function getMetadataField(metadataKey) {
    const metadata = metadataFields[metadataKey];
    const val = metadataKey === "hours" ? metadata : metadata[0].value;
    const generalProps = {
      key: metadataKey,
      value: val,
      updateValue: handleMetadataChange(metadataKey),
    };

    switch (metadataKey) {
      case "cost":
        return <Cost {...generalProps} />;
      case "temp":
        return <Temperature {...generalProps} />;
      case "hours":
        return <OperationHoursDates {...generalProps} />;
      case "password":
      case "location":
      case "instructions":
      case "restrictions":
      case "special_note":
        return <SimpleInfo {...generalProps} type={metadataKey} />;
      default:
        return null;
    }
  }

  const getAmenity =
    (category) =>
    ({columnIndex, rowIndex}) => {
      const amenity = avalAmenities[category][3 * rowIndex + columnIndex];
      const isChecked = !!currAmenities?.[amenity]?.enabled;

      if (!amenityLabels[amenity]) {
        return null;
      }
      return (
        <div className={classes.amenityRow}>
          <Checkbox
            size="small"
            color="secondary"
            checked={isChecked}
            className={classes.checkbox}
            onChange={onCheck(amenity)}
            inputProps={{"aria-label": `${amenityLabels[amenity]} checkbox`}}
          />
          <Tooltip
            title={amenityLabels[amenity]}
            enterDelay={500}
            enterNextDelay={500}
          >
            <Typography
              variant="button"
              noWrap
              className={clsx(classes.amenityLabel, {
                "-not-checked": !isChecked,
              })}
            >
              {amenityLabels[amenity]}
            </Typography>
          </Tooltip>
          <IconButton
            size="small"
            className={clsx(classes.iconBtn, "add-icon")}
            onClick={openMetadataMenu(category, amenity)}
          >
            <EditIcon className={classes.addIcon} />
          </IconButton>
        </div>
      );
    };

  function getCellContent({rowIndex}) {
    const item = amenityTypes[rowIndex];

    return (
      <div className={classes.gridItem}>
        <Typography variant="h1" className="mb-2">
          {amenityTypeLabels[item]}
        </Typography>
        <div className={classes.amenitiesContainer}>
          <VirtualizedGrid
            hideScrollbar
            getCellContent={getAmenity(item)}
            columnCount={columns}
            rowHeight={38}
            rowCount={Math.ceil(avalAmenities[item].length / columns)}
          />
        </div>
      </div>
    );
  }

  function getDialogContent() {
    if (!!selectedAmenity && !!metadataOpen && !!metadataFields) {
      return (
        <div>
          {Object.keys(metadataFields).map((mk) => getMetadataField(mk))}
        </div>
      );
    } else {
      return null;
    }
  }

  const metadataDialog = (
    <CustomDialog
      open={metadataOpen}
      labelConfirm="Save"
      disableConfirm={invalidHours}
      labelCancel="Cancel"
      title="Additional information"
      maxWidth="md"
      fullWidth
      content={getDialogContent()}
      onClose={closeMetadataDialog}
      actionCancel={closeMetadataDialog}
      actionConfirm={saveMetadata}
    />
  );

  return (
    <div
      className={classes.root}
      style={{height: !!autoHeight ? totalHeight : "auto"}}
    >
      {metadataDialog}
      {!create && !outsideView && (
        <CustomCardHeader
          title="Edit Listing Amenities"
          type="title"
          className={classes.cardHeader}
          action={
            <>
              <Button
                className="mr-2"
                classes={{label: classes.cancelBtn}}
                size="small"
                onClick={onCancel}
              >
                Close
              </Button>
              <PrimaryButton size="small" label="Save" onClick={handleSave} />
            </>
          }
        />
      )}
      {!!outsideView && !hideTitleRow && (
        <ListingContentList
          listingId={listing.listing_id}
          onlyItems={["amenities"]}
          disableBackground
        />
      )}
      <div className={clsx(classes.content, {[classes.divider]: !create})}>
        <VirtualizedGrid
          getCellContent={getCellContent}
          columnCount={1}
          rowHeight={getRowHeight}
          rowCount={amenityTypes.length}
        />
      </div>
    </div>
  );
}

export default withWidth()(AmenitiesPanel);
