import {
  SET_AVAILABLE_EXPERIENCES,
  SET_CONFIGURED_EXPERIENCES,
  SET_INSTANT_EXPERIENCES,
  ADD_EXPERIENCE,
  ADD_LISTING_EXPERIENCES,
  ADD_PRESET_EXPERIENCE,
  REMOVE_EXPERIENCE,
  LOADING_X_VERIFICATION_SETTINGS,
} from "../actionTypes";
import _ from "lodash";

const initial_state = {
  available_experiences: {},
  instant_experiences: {},
  configured_experiences: {
    experienceId: {},
    experienceType: {},
    listingId: {},
  },
  verification_settings: {
    isLoading: false,
    totalExp: 0,
  },
};

export default function experiencesReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_AVAILABLE_EXPERIENCES:
      const newExpKeys = Object.keys(action.experiences);
      const newExpPresets = {...action.experiences};
      _.each(newExpKeys, (k) => {
        newExpPresets[k] = newExpPresets[k].map((e) => ({
          ...e,
          enabled: false,
        }));
      });
      return {...state, available_experiences: newExpPresets};

    case SET_INSTANT_EXPERIENCES:
      return {...state, instant_experiences: action.experiences};

    case SET_CONFIGURED_EXPERIENCES:
      const newConfiguredExperiences = {
        experienceId: {},
        experienceType: {},
        listingId: {...state.configured_experiences.listingId},
      };

      _.each(action.experiences, (exp) => {
        newConfiguredExperiences.experienceId[exp.experience_id] = exp;
        newConfiguredExperiences.experienceType[exp.experience_type] = {
          ...newConfiguredExperiences.experienceType[exp.experience_type],
          [exp.experience_id]: exp,
        };
      });

      return {...state, configured_experiences: newConfiguredExperiences};

    case ADD_EXPERIENCE:
      const newConfiguredExpByListingId = {
        ...state.configured_experiences.listingId,
      };
      if (!!action.listingId) {
        newConfiguredExpByListingId[action.listingId] = {
          ...newConfiguredExpByListingId[action.listingId],
          [action.experienceId]: action.experience,
        };
      }

      return {
        ...state,
        configured_experiences: {
          experienceId: {
            ...state.configured_experiences.experienceId,
            [action.experienceId]: action.experience,
          },
          experienceType: {
            ...state.configured_experiences.experienceType,
            [action.experience.experience_type]: {
              ...state.configured_experiences.experienceType[
                action.experience.experience_type
              ],
              [action.experienceId]: action.experience,
            },
          },
          listingId: newConfiguredExpByListingId,
        },
      };

    case ADD_LISTING_EXPERIENCES:
      const newConfiguredExp = {...state.configured_experiences};
      newConfiguredExp.listingId[action.listingId] = {};

      _.each(action.experiences, (el) => {
        newConfiguredExp.listingId[action.listingId][el.experience_id] = el;
        newConfiguredExp.experienceId[el.experience_id] = el;
        if (!!newConfiguredExp.experienceType[el.experience_type]) {
          newConfiguredExp.experienceType[el.experience_type] = {
            ...newConfiguredExp.experienceType[el.experience_type],
            [el.experience_id]: el,
          };
        } else {
          newConfiguredExp.experienceType[el.experience_type] = [el];
        }
      });

      return {
        ...state,
        configured_experiences: newConfiguredExp,
      };

    case ADD_PRESET_EXPERIENCE:
      const preset = {...action.preset, enabled: false};
      const presetType = preset.experience_type;
      const newPresets = !!state.available_experiences[presetType]
        ? [...state.available_experiences[presetType]]
        : [];
      const presetIndex = newPresets.findIndex(
        (exp) => exp.preset_id === preset.preset_id,
      );

      if (presetIndex !== -1) {
        newPresets[presetIndex] = preset;
      }

      return {
        ...state,
        available_experiences: {
          ...state.available_experiences,
          [presetType]: newPresets,
        },
      };

    case REMOVE_EXPERIENCE:
      const newConfigExpById = {...state.configured_experiences.experienceId};
      const newExperiencesByType = {
        ...state.configured_experiences.experienceType[action.experienceType],
      };
      const newExperiencesByListingId = {
        ...state.configured_experiences.listingId,
      };

      delete newExperiencesByType[action.experienceId];
      delete newConfigExpById[action.experienceId];

      if (!!action.listingId && !!newExperiencesByListingId[action.listingId]) {
        delete newExperiencesByListingId[action.listingId][action.experienceId];
      }

      const newConfigExpByType = {
        ...state.configured_experiences.experienceType,
        [action.experienceType]: newExperiencesByType,
      };
      const newExperiences = {
        experienceId: newConfigExpById,
        experienceType: newConfigExpByType,
        listingId: newExperiencesByListingId,
      };
      return {
        ...state,
        configured_experiences: newExperiences,
      };
    case LOADING_X_VERIFICATION_SETTINGS:
      return {
        ...state,
        verification_settings: {
          isLoading: action.isLoading,
          totalExp: action.totalExp,
        },
      };

    default:
      return state;
  }
}
