import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import defaultBGImg from "assets/img/article_placeholder_image.webp";
import KeycardHTMLField from "./KeycardHTMLField";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    minHeight: 150,
    borderRadius: "10px !important",
    background: (props) =>
      `linear-gradient(270deg, ${theme.palette.primary.contrast + "cc"} 0%, ${theme.palette.primary.contrast + "a6"} 48.96%, ${theme.palette.primary.contrast + "00"} 100%), linear-gradient(270deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.4) 48.96%, rgba(0,0,0,0) 100%), url("${props.BGImg}") no-repeat center center / cover`,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    aspectRatio: "3 / 1",
    boxShadow: "none !important",
  },
  cardActionArea: {
    width: "100% !important",
    height: "100% !important",
    display: "flex !important",
    flexDirection: "column",
    alignItems: "flex-end !important",
  },
  cardContent: {
    flex: 1,
    height: 150,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    padding: "0 !important",
  },
  textContainer: {
    padding: theme.spacing(1),
    maxWidth: "45%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "60%",
    },
  },
  description: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    lineHeight: "20px !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
    width: "100%",
    lineClamp: 3,
    opacity: 0.7,
  },
  name: {
    fontSize: "16px !important",
    fontWeight: "400 !important",
    lineHeight: "24px !important",
    marginBottom: "4px !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
    width: "100%",
    lineClamp: 2,
  },
}));

const HorizontalCard = ({resource, onClick}) => {
  const classes = useStyles({BGImg: resource?.header_image ?? defaultBGImg});

  return (
    <Card className={classes.card}>
      <CardActionArea
        disableRipple
        onClick={onClick}
        className={classes.cardActionArea}
      >
        <CardContent className={classes.cardContent}>
          <Box className={classes.textContainer}>
            <Typography className={classes.name}>
              {resource?.name || ""}
            </Typography>
            <KeycardHTMLField
              content={resource?.text ?? ""}
              className={classes.description}
            />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default HorizontalCard;
