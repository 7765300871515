import React from "react";
import {Typography} from "@material-ui/core";

export default function HTMLContent({v}) {
  return (
    <Typography
      className="html-content"
      style={{wordWrap: "break-word", whiteSpace: "break-spaces", fontSize: 12}}
      dangerouslySetInnerHTML={{__html: v}}
    />
  );
}
