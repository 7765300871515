import React from "react";
import {Box, styled, Typography, useTheme} from "@material-ui/core";
import {gradientRgb} from "utilities/helperFunctions";

const Container = styled(Box)(({theme}) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "center",
  minHeight: "25%",
}));

const Content = styled(Box)(({theme}) => ({
  width: "100%",
  maxWidth: 800,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  gap: 12,
  marginTop: -68,
}));

const SectionHeaderCard = ({
  img,
  title,
  actions,
  titleRef,
  disableGrayscale,
}) => {
  const theme = useTheme();

  return (
    <>
      <Container
        style={{
          background: `linear-gradient(180deg, rgba(255,255,255,0) 30%, ${gradientRgb(
            theme.palette.primary.contrast,
          )} 95%), url("${img}") no-repeat center center / cover`,
        }}
      />
      <Content ref={titleRef}>
        <Typography
          variant="h3"
          component={"div"}
          lineHeight={"40px"}
          style={{
            padding: theme.spacing(0, 2.5),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(4),
          }}
        >
          {title}
        </Typography>
        {!!actions && actions}
      </Content>
    </>
  );
};

export default SectionHeaderCard;
