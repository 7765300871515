import React, {useState, useRef} from "react";
import {Link, Redirect, useLocation} from "react-router-dom";
import {
  makeStyles,
  CircularProgress,
  CardHeader,
  CardContent,
  Divider,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import {useDispatch} from "react-redux";
import clsx from "clsx";
import {signUpUrl} from "redux/actions/accountsActions";
import {getGreeting} from "utilities/helperFunctions";
import {
  channelIntegrationTypeMap,
  channelIntegrationList,
} from "configuration/enums.js";
import ChannelRegisterForm from "./ChannelRegisterForm";
import FullSizeCard from "../../../core/cards/FullSizeCard";
import IntegrationSelectionPanel from "../../../components/Panels/Device/IntegrationSelectionPanel";
import SearchBar from "../../../core/bars/SearchBar";
import {ReactComponent as EnsoLogo} from "assets/icons/Enso_Icon.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    width: "100vw",
    overflow: "hidden",
  },
  container: {
    flexGrow: 1, // overflowY: 'auto',
    display: "flex",
    flexDirection: "column",
    paddingBottom: "0px !important",
    // padding: theme.spacing(2),`
  },
  content: {
    width: "100%",
    height: "auto",
    display: "flex",
    marginRight: theme.spacing(2),
    overflow: "hidden auto",
    flexDirection: "column",
  },
  cardHeader: {
    fontWeight: 300,
    paddingBottom: 0,
  },
  title: {
    color: theme.palette.primary.main,
  },
  subheader: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    fontWeight: 600,
  },
  link: {
    textDecoration: "underline !important",
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  searchContainer: {
    display: "flex",
    justifyContent: "flex-start", // marginLeft: '-px',
    marginBottom: theme.spacing(2),
  },
  searchBar: {
    minWidth: "170px",
    maxWidth: "300px",
    width: "100%",
  },
  quotesPanel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(4),
    height: "100%",
    width: 230,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {display: "none"},
    color: theme.palette.common.white,
  },
  quotes: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  ensoLogo: {
    color: theme.palette.common.white,
    width: "100%",
    height: 60,
    marginBottom: theme.spacing(5),
  },
  fullSizeCard: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  gridContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  gridContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  cardsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    maxWidth: "100%",
    gap: "10px",
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

export default function Register(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    match: {params},
  } = props;
  const {channel} = params;
  const greeting = useRef(getGreeting());
  const [step, setStep] = useState(
    channel || location.state?.originParams?.channel,
  );
  const [redirect] = useState("");
  const [searchText, setSearchText] = useState("");
  const [filteredItems, setFilteredItems] = useState(
    channelIntegrationList.filter(
      (integration) => integration.signup_type !== null,
    ),
  );
  const handleSearchChange = (val) => {
    const searchText = val;
    setSearchText(searchText);
    const list = channelIntegrationList.filter(
      (integration) => integration.signup_type !== null,
    );
    const filtered = list.filter((integration) =>
      integration.title.toLowerCase().includes(searchText.toLowerCase()),
    );

    setFilteredItems(filtered);
  };

  const handleRedirectSignup = (integration_spec) => {
    setStep("loading");
    dispatch(
      signUpUrl(
        {integration_type: integration_spec.integration_type},
        (response) => (window.location.href = response.redirect_url),
        () => setStep("error_connecting"),
      ),
    );
  };

  function getCardHeader() {
    switch (step) {
      case "channels":
      //     ?redirect_url=https://yourlink.com/ redirects to the specified URL after signup
      //     ?redirect_url=direct redirects to the dashboard after signup
      // eslint-disable-next-line no-fallthrough
      case "pms":
        const queryParams = new URLSearchParams(window.location.search);
        const redirectUrl = queryParams.get("redirect_url");

        if (redirectUrl) {
          localStorage.setItem("signup_redirect_url", redirectUrl);
          console.log(redirectUrl, "Added redirect url to local storage");
        }
        return (
          <>
            <CardHeader
              title={`Good ${greeting.current},`}
              titleTypographyProps={{
                variant: "h6",
                className: classes.title,
                style: {
                  fontWeight: 300,
                  marginBottom: "5px",
                  marginLeft: "3px",
                },
              }}
              subheader={
                <span>
                  Get started below or{" "}
                  <Link className={classes.link} to="/auth/login">
                    login
                  </Link>
                  .
                </span>
              }
              subheaderTypographyProps={{
                variant: "h4",
                className: classes.subheader,
                style: {marginLeft: "3px"},
              }}
              className={classes.cardHeader}
            />
            <div className={classes.searchContainer}>
              <SearchBar
                stopPropagation
                searchInput={searchText}
                handleSearchInput={(val) => {
                  handleSearchChange(val);
                }}
                className={classes.searchBar}
              />
            </div>
            <Divider />
          </>
        );
      default:
        return null;
    }
  }

  const goBack = () => {
    setStep("pms");
  };

  function getCardContent() {
    switch (step) {
      case "pms":
        return (
          <>
            <IntegrationSelectionPanel
              selectPMS={(integration) => {
                setStep(integration.integration_type);
              }}
              onlyPms
              pmsList={filteredItems}
            />
          </>
        );
      case "loading":
        return (
          <div className={classes.centered}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress color="primary" />
              <Typography
                variant={"h1"}
                color="primary"
                style={{marginTop: 20}}
              >
                Please Wait
              </Typography>
            </div>
          </div>
        );
      default: {
        const spec = channelIntegrationTypeMap[step];
        if (!spec) return <></>;
        if (spec.signup_type === "redirect") {
          handleRedirectSignup({...spec, integration_type: step});
        } else {
          return <ChannelRegisterForm integration={step} />;
        }
      }
    }
  }

  if (redirect) return <Redirect push to={redirect} />;
  return (
    <div className={classes.root}>
      <div className={classes.quotesPanel}>
        <SvgIcon
          viewBox="0 0 687.57 283.26"
          className={classes.ensoLogo}
          component={EnsoLogo}
        />
      </div>
      <div className={classes.content}>
        <FullSizeCard
          className={classes.fullSizeCard}
          goBack={step === "pms" ? null : goBack}
        >
          {getCardHeader()}
          <CardContent className={clsx(classes.content, classes.gridContainer)}>
            <div className={clsx(classes.container, classes.gridContent)}>
              {getCardContent()}
            </div>
          </CardContent>
        </FullSizeCard>
      </div>
    </div>
  );
}
