import React, {useEffect, useState} from "react";
import {
  Box,
  Checkbox,
  Divider,
  makeStyles,
  Paper,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import {ReactComponent as CalendarIcon} from "assets/icons/calendar.svg";
import {ReactComponent as UnlockIcon} from "assets/icons/unlock.svg";
import {DateRangePickerCalendar, END_DATE, START_DATE} from "react-nice-dates";
import {enCA} from "date-fns/locale";
import clsx from "clsx";
import FilledTextField from "core/inputs/FilledTextField";
import DeviceList from "components/Lists/DeviceList";
import {useSelector} from "react-redux";
import useCalendarStyles from "styles/useCalendarStyles";
import PrimaryButton from "core/buttons/PrimaryButton";
import {useTranslation} from "react-i18next";
import {useFlags} from "launchdarkly-react-client-sdk";

const now = Date.now();

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    "&.centered": {alignItems: "center"},
  },
  icon: {
    fontSize: 18,
    marginRight: theme.spacing(2),
  },
  calendarContainer: {
    borderRadius: 10,
    boxShadow: "0px 6px 24px 0px rgba(27, 32, 50, 0.10)",
    "&.disabled": {opacity: 0.5},
  },
  deviceListContainer: {
    border: "1px solid #F3F5FA",
    borderRadius: 10,
    overflow: "hidden",
    maxHeight: 300,
    padding: theme.spacing(4),
    display: "flex",
  },
}));

export default ({disableEdit, device, loading, setData}) => {
  const classes = useStyles();
  const calendarStyles = useCalendarStyles();
  const devices = useSelector((state) => state.defaultReducer.devices);
  const [permanent, setPermanent] = useState(true);
  const [startDate, setStartDate] = useState(new Date(now));
  const [endDate, setEndDate] = useState(new Date(now + 604800000)); // 1 week
  const [deviceListOpen, setDeviceListOpen] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([device.device_id]);
  const [focus, setFocus] = useState(START_DATE);
  const [name, setName] = useState("");
  const [isCustomCode, setIsCustomCode] = useState(false);
  const [customCode, setCustomCode] = useState("");
  const {t} = useTranslation();
  let disabled = !!permanent || !!disableEdit;
  const flag = useFlags();

  useEffect(() => {
    setData({
      name,
      code: isCustomCode ? customCode : null,
      startDate,
      endDate,
      permanent,
      devicesList: selectedDevices,
    });
  }, [
    startDate,
    endDate,
    permanent,
    name,
    selectedDevices,
    isCustomCode,
    customCode,
  ]);

  const onValueChange = (e) => {
    let val = e.target.value;
    setName(val);
  };

  const handleDeviceSelect = (device) => {
    if (selectedDevices.includes(device.device_id)) {
      setSelectedDevices((prev) =>
        selectedDevices.filter((d) => d !== device.device_id),
      );
    } else {
      setSelectedDevices((prev) => [...prev, device.device_id]);
    }
  };

  const handleOpenDeviceList = () => {
    setDeviceListOpen((prev) => true);
  };

  const handleClose = () => {
    setDeviceListOpen((prev) => false);
  };

  return (
    <Paper elevation={0}>
      <Box mb={3}>
        <Box className={clsx(classes.row, "centered")} alignItems="center">
          <FilledTextField
            fullWidth
            value={name}
            disabled={disableEdit || loading}
            label="Access code name"
            onChange={onValueChange}
          />
        </Box>
        {flag.customAccessCodes && (
          <Box display="flex" alignItems="center" mt={1}>
            <Checkbox
              checked={isCustomCode}
              onChange={(e) => setIsCustomCode(e.target.checked)}
              disabled={disableEdit || loading}
              color="primary"
            />
            <Typography variant="body2" style={{marginRight: 16}}>
              {t("custom-access-code-checkbox-label")}
            </Typography>
            {isCustomCode && (
              <Box flex={1} ml={2}>
                <FilledTextField
                  fullWidth
                  // characterLimit={10}
                  value={customCode}
                  disabled={disableEdit || loading}
                  label={t("custom-access-code-field-label")}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setCustomCode(value);
                    }
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Box mb={4}>
        <Box className={clsx(classes.row, "centered")}>
          <SvgIcon
            viewBox="0 0 16 17"
            component={UnlockIcon}
            className={classes.icon}
          />
          <Typography variant="h1">{"Additional devices"}</Typography>
        </Box>
        <Box my={2}>
          <Typography>
            {"Choose whether to add your access code to additional devices."}
          </Typography>
        </Box>
        <div className={classes.deviceListContainer}>
          {deviceListOpen ? (
            <Box
              flex={1}
              width={"100%"}
              display={"flex"}
              overflow={"hidden"}
              flexDirection={"column"}
            >
              <Box mb={3} className={clsx(classes.row, "centered")}>
                <Box
                  flex={1}
                  display={"flex"}
                  alignItems={"center"}
                  sx={{gap: 8}}
                >
                  <Typography variant="h1">{"Select devices"}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {`${selectedDevices.length} devices selected`}
                  </Typography>
                </Box>
                <Box mr={2}>
                  <PrimaryButton
                    size="small"
                    variant="outlined"
                    subdued
                    label={"Close"}
                    onClick={handleClose}
                  />
                </Box>
              </Box>
              <Box sx={{overflow: "auto"}}>
                <DeviceList
                  flat
                  multiselect
                  selectedDevice={device}
                  initialDevices={devices}
                  selectedList={selectedDevices}
                  onDeviceSelect={handleDeviceSelect}
                  disableEdit={disableEdit || loading}
                />
              </Box>
            </Box>
          ) : (
            <Box flex={1} className={classes.row}>
              <Box flex={1}>
                <Typography variant="h1">
                  {selectedDevices.length}{" "}
                  {`device${selectedDevices.length === 1 ? "" : "s"}`}
                </Typography>
              </Box>
              <PrimaryButton
                label="Edit connected devices"
                onClick={handleOpenDeviceList}
              />
            </Box>
          )}
        </div>
      </Box>
      <Divider color="#F0F0F0" />
      <div className={classes.row}>
        <Box flex={1} maxWidth={"50%"}>
          <div className={clsx(classes.row, "centered")}>
            <SvgIcon
              component={CalendarIcon}
              viewBox="0 0 16 16"
              className={classes.icon}
            />
            <Typography variant="h1">{"Expiry date"}</Typography>
            <Box flex={1}>
              <Checkbox
                checked={!permanent}
                disabled={disableEdit}
                onChange={(event) => setPermanent(!event.target.checked)}
              />
            </Box>
          </div>
          <Typography>
            {
              "By default, newly generated codes do not have an expiry date. If you want to enable an expiry date. Please check the checkbox."
            }
          </Typography>
        </Box>
        <Box flex={1} maxWidth={"50%"} display={"flex"} minHeight={320} p={3}>
          <div
            className={clsx(
              classes.calendarContainer,
              calendarStyles.calendar,
              "subcalendar",
              {disabled: disabled},
            )}
          >
            <DateRangePickerCalendar
              startDate={disabled ? null : startDate}
              endDate={disabled ? null : endDate}
              onStartDateChange={(newDate) => setStartDate(newDate)}
              onEndDateChange={(newDate) => setEndDate(newDate)}
              locale={enCA}
              focus={focus}
              modifiers={{
                disabled: () => {
                  return disabled;
                },
              }}
              onFocusChange={() =>
                focus == END_DATE ? setFocus(START_DATE) : setFocus(END_DATE)
              }
            />
          </div>
        </Box>
      </div>
    </Paper>
  );
};
