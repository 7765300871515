import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, SvgIcon, Typography} from "@material-ui/core";
import {ReactComponent as WarningIcon} from "assets/icons/alert-triangle.svg";
import PrimaryButton from "core/buttons/PrimaryButton";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    borderRadius: 10,
    background: "rgba(47, 122, 251, 0.05)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(3),
  },
  warningIcon: {
    color: theme.palette.secondary.main,
    fontSize: 16,
  },
}));

const BPAccessCodesWarningCard = ({onClick}) => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <SvgIcon
        className={classes.warningIcon}
        component={WarningIcon}
        viewBox="0 0 24 24"
      />
      <Typography
        component="div"
        variant="caption"
        color="secondary"
        style={{flex: 1}}
      >
        {"Revoking the boarding pass "}
        <Typography
          component="span"
          variant="caption"
          style={{fontWeight: 600}}
        >
          {"does not disable booking access codes. "}
        </Typography>
        {
          "Please revoke these manually to ensure the guest does not have access."
        }
      </Typography>
      <PrimaryButton
        color="secondary"
        variant="outlined"
        label={"Edit Codes"}
        onClick={onClick}
      />
    </Box>
  );
};

export default BPAccessCodesWarningCard;
