import React from "react";
import {IconButton, SvgIcon, makeStyles} from "@material-ui/core";
import {ReactComponent as EditIcon} from "assets/icons/pencil.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: (props) => (!!props.light ? "#FFF" : theme.palette.text.primary),
    width: 15,
    height: 15,
    transition: "0.15s",
    opacity: 0.5,
    marginLeft: (props) => theme.spacing(props.spaceLeft),
    marginRight: (props) => theme.spacing(props.spaceRight),
    "&:hover": {opacity: 1},
  },
  noPadding: {padding: 0},
}));

export default function EditIconButton({
  onClick,
  light,
  spaceLeft = 0,
  spaceRight = 0,
  disablePadding = false,
  extraClass,
  disabled,
}) {
  const classes = useStyles({spaceLeft, spaceRight, light});

  return (
    <IconButton
      size="small"
      disabled={disabled}
      onClick={onClick}
      className={clsx({[classes.noPadding]: !!disablePadding}, extraClass)}
    >
      <SvgIcon
        className={classes.icon}
        viewBox="0 0 12 12"
        component={EditIcon}
      />
    </IconButton>
  );
}
