import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {MenuItem} from "@material-ui/core";
import FilledSelect from "core/selects/FilledSelect";
import SimpleField from "./SimpleField";
import {monthEnum} from "configuration/enums";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
}));

export default function TravelReason({edit, value, onValueChange}) {
  const classes = useStyles();
  const [selectedReason, setSelectedReason] = React.useState(value);

  React.useEffect(() => {
    if (edit && value !== selectedReason) {
      setSelectedReason((prev) => value);
    }
  }, [value]);

  React.useEffect(() => {
    if (edit) {
      onValueChange(selectedReason);
    }
  }, [selectedReason]);

  const handleReasonChange = (e) => {
    const newVal = e.target.value;
    setSelectedReason((prev) => newVal);
  };

  if (!edit) {
    return <SimpleField value={value} />;
  } else {
    return (
      <div className={classes.root}>
        <FilledSelect
          fullWidth
          label="Month"
          value={selectedReason}
          onChange={handleReasonChange}
        >
          <MenuItem value="" disabled>
            Select a month
          </MenuItem>
          {Object.keys(monthEnum).map((tr) => (
            <MenuItem value={monthEnum[tr]} key={tr}>
              {tr}
            </MenuItem>
          ))}
        </FilledSelect>
      </div>
    );
  }
}
