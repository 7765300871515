import React, {useState} from "react";
import {
  Typography,
  Card,
  CardContent,
  SvgIcon,
  makeStyles,
  CircularProgress,
  Chip,
  FormControl,
  TextField,
  Box,
} from "@material-ui/core";
import PrimaryButton from "core/buttons/PrimaryButton";
import {useDispatch, useSelector} from "react-redux";
import {postChannels} from "redux/actions/accountsActions";
import {serviceProviderMap} from "configuration/enums";
import {THEME} from "configuration/settings";
import {
  closeGeneralError,
  openGeneralError,
} from "redux/actions/settingsActions";
import CustomDialog from "core/dialogs/CustomDialog";

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 50,
    padding: 10,
  },
}));

export default function WhatsappAccountCard() {
  const dispatch = useDispatch();
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [wabaId, setWabaId] = useState(null);
  const [open, setOpen] = useState(false);

  const createAccountModal = (
    <CustomDialog
      open={open}
      title={"Create Whatsapp Business Account"}
      content={
        <FormControl>
          <TextField
            label={"Whatsapp Business Account ID"}
            onChange={(e) => setWabaId(e.target.value)}
          />
          <TextField
            label={"Token"}
            onChange={(e) => setToken(e.target.value)}
          />
        </FormControl>
      }
      labelConfirm={"Create"}
      onClose={() => setOpen(false)}
      actionConfirm={handleSubmit}
    />
  );

  function handleSubmit() {
    setLoading(true);
    dispatch(
      postChannels(
        {
          enso_key: current_user,
          data: {
            service: "msg",
            provider: "whatsapp",
            options: {
              token,
              waba_id: wabaId,
            },
          },
        },
        (response) => {
          setLoading(false);
          window.location.reload();
        },
      ),
      (error) => {
        setLoading(false);
      },
    );
  }

  return (
    <>
      {createAccountModal}
      <Card style={{marginTop: THEME.spacing.md}} id="whatsapp-connect-account">
        <CardContent>
          <Typography variant="h2">
            <img
              src={serviceProviderMap["whatsapp"]?.icon}
              className={classes.icon}
            />
            <Chip
              color="primary"
              label={<Box sx={{marginTop: 4}}>{"BETA"}</Box>}
            />
          </Typography>
          <div style={{display: "flex", marginTop: 5}}>
            <Typography>
              Heads up! Connecting your WhatsApp Business Account requires a
              Facebook Business Manager platform and a meeting with our CS team.
              Please reach out to your Onboarding Manager if you would like to
              schedule a WhatsApp session.
            </Typography>
          </div>
          <div style={{display: "flex", marginTop: 5}}>
            <div style={{flex: 1, display: "flex", justifyContent: "right"}}>
              {loading ? (
                <CircularProgress />
              ) : (
                <PrimaryButton
                  size="small"
                  variant="text"
                  label={"Connect account"}
                  color="primary"
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
