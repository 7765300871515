import React from "react";
import {SvgIcon, Typography, makeStyles} from "@material-ui/core";
import {ReactComponent as ArrowIcon} from "assets/icons/arrow-up-right.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 10,
    padding: 20,
    margin: (props) => (!!props.disableGutters ? 0 : "0px 20px"),
    backgroundColor: theme.palette.primary.main + "26",
    cursor: (props) => (!!props.clickable ? "pointer" : "default"),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(0.5),
  },
  icon: {
    color: theme.palette.primary.main,
    width: "24px !important",
    height: "24px !important",
  },
}));

const SimpleCard = ({label, disableGutters = false, onClick = null}) => {
  const classes = useStyles({disableGutters, clickable: !!onClick});

  const handleClick = () => {
    if (!onClick) {
      return;
    }
    onClick();
  };

  return (
    <div className={classes.container} onClick={handleClick}>
      <Typography align="center">{label}</Typography>
      {!!onClick && (
        <SvgIcon
          viewBox="0 0 24 24"
          component={ArrowIcon}
          className={classes.icon}
        />
      )}
    </div>
  );
};

export default SimpleCard;
