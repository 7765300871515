import {makeStyles} from "@material-ui/core";

const useProductPanelStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  subtitle: {
    marginBottom: 10,
    marginTop: 20,
  },
  sku_card: {
    border: "solid 1px #F0F0F0",
    borderRadius: 10,
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    "&.-expanded": {borderTop: "none"},
  },
  itemActionIcon: {
    transition: "0.15s",
    fontSize: 32,
    color: theme.palette.action.disabled,
    "&:hover": {opacity: 0.75},
  },
  section: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    "&.-gap-md": {gap: theme.spacing(3)},
    "&.-space-top": {marginTop: theme.spacing(4)},
    "& .expand-btn": {margin: 0},
  },
  sectionTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
    padding: theme.spacing(1, 0),
    "& > .icon": {
      width: 16,
      height: 16,
    },
    "& > .actions": {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: theme.spacing(2),
    },
  },
  divider: {
    margin: theme.spacing(4, 0),
    backgroundColor: "#F0F0F0",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(3),
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      flexBasis: 150,
    },
  },
  radioLabel: {fontWeight: 500},
  radioDescription: {
    marginLeft: 31,
    marginTop: -15,
    marginBottom: theme.spacing(3),
  },
  collectDepositContainer: {
    flexBasis: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  switchField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  switchButton: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginLeft: -10,
  },
  dialogHeader: {
    background: (props) =>
      `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0.26%, rgba(0, 0, 0, 0.28) 99.71%), url(${props.dialogBGImage}), lightgray 0px -643.099px / 100% 500% no-repeat`,
    backgroundPosition: "50% !important",
    backgroundSize: "cover !important",
  },
}));

export default useProductPanelStyles;
