import {makeStyles} from "@material-ui/core/styles";

const usePreviewBPPanelStyles = makeStyles((theme) => ({
  previewContainer: {
    width: "fit-content",
    height: "100%",
    padding: theme.spacing(3, 6),
    overflow: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "&::-webkit-scrollbar": {
      width: "0!important",
      height: "0!important",
    },
  },
  loader: {color: "white"},
  loaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  contentContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: 50,
    zoom: 0.8,
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  frame: {
    background: "rgba(0, 0, 0, 0.30)",
    boxShadow: "0px 0px 25px 5px rgba(0,0,0,0.3)",
    border: "10px solid #000000",
    backgroundColor: "#000000",
    height: "100%",
    borderRadius: 42,
  },
  emptyIDCollectionBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    position: "relative",
  },
  closeIconEmpty: {
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(4),
  },
  autohostContainer: {
    borderRadius: 50,
    height: "100%",
    width: "100%",
  },
  autohostImg: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  chekinImg: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
}));

export default usePreviewBPPanelStyles;
