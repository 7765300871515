import {userTypes} from "configuration/constants";

// takes a user's scope and determines if it is admin, team, VA, or cohost
export function determineUserType(userScopes) {
  const userScopesKeys = Object.keys(userScopes);

  // Loop through each user type
  for (const userTypeKey in userTypes) {
    const userType = userTypes[userTypeKey];
    const userTypeScopes = userType.scopes;

    // Check if all scopes for the user type match with the user's scopes
    const matches = userScopesKeys.every(
      (key) =>
        userTypeScopes.hasOwnProperty(key) &&
        userTypeScopes[key] === userScopes[key],
    );

    // If all scopes match, return the user type
    if (matches) {
      return userTypeKey;
    }
  }

  // If none of the predefined user types match, consider it custom
  return "custom";
}

// setUserType function to set the user type and corresponding scopes in userInfo state
export function setUserType(userTypeKey, setUserInfo) {
  // Get the scopes for the specified user type
  const scopes = userTypes[userTypeKey]?.scopes || {};

  // Update the userInfo state with the determined scopes
  setUserInfo((prevUserInfo) => ({...prevUserInfo, scopes: scopes}));
}
