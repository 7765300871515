import React from "react";
import PropTypes from "prop-types";

/**
 * @description Handles differentiation of custom component rendering for any field in the platform.
 *
 * @param custom For fully custom render types, this could be a custom dropdown elsewhere in the platform (e.g., guest sentiment selector).
 * @param text For returning a custom, formatted text value with data provided.
 * @param display For displaying a custom-rendered entry anywhere in the platform (e.g., an icon or similar).
 * @param input For rendering the collection input; this can be used anywhere.
 * @type {{input: string, display: string, custom: string, text: string}}
 */
export const customComponentDisplayType = {
  input: "input",
  display: "display",
  custom: "custom",
  text: "text",
};

/**
 * @name BaseFieldComponent
 * @description Base class for handling custom field rendering.
 * @usage You can extend this class to create custom render fields:
 * @author Chloe Hill
 */
export default class BaseFieldComponent extends React.Component {
  /**
   * @description Delegates rendering based on the `displayType` prop.
   */
  render() {
    const {displayType} = this.props;

    // Make it possible to override this
    switch (displayType) {
      case customComponentDisplayType.input:
        return this.renderInput(this.props);
      case customComponentDisplayType.display:
        return this.renderDisplay(this.props);
      case customComponentDisplayType.custom:
        return this.renderCustom(this.props);
      case customComponentDisplayType.text:
        return this.renderText(this.props);
      default:
        return null;
    }
  }

  renderInput(props) {
    return null;
  }

  renderDisplay(props) {
    return null;
  }

  renderCustom(props) {
    return null;
  }

  /**
   * @description Used for just text, i.e. formatted value.
   * @param props
   * @example You can extract the text using renderToStaticMarkup from ReactDOMServer:
   * const renderedText = ReactDOMServer.renderToStaticMarkup(
   *   countryFieldInstance.renderText({ fieldData, classes })
   * );
   */
  renderText(props) {
    // Use getDefaultFieldValue(value); for default.
    throw new Error("renderText not implemented");
  }
}

BaseFieldComponent.propTypes = {
  displayType: PropTypes.oneOf(Object.values(customComponentDisplayType))
    .isRequired,
};
