import React, {useEffect, useRef} from "react";
import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {THEME} from "configuration/settings";
import IconCard from "../../../base/card/IconCard";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {guestViewsUrlMap} from "../../../../configuration/constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    borderRadius: 10,
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.2), 0px 26px 80px rgba(0, 0, 0, 0.2)",
    maxHeight: "calc(100% - 100px)",
    maxWidth: "1000px",
    // minWidth: "calc(100% - 200px)",
    backgroundColor: THEME.white,
    display: "flex",
    flexDirection: "column",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: theme.spacing(2),
    backgroundColor: "#f5f5f5",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  titleText: {
    padding: THEME.spacing.lg,
    fontWeight: 600,
    fontSize: 18,
  },
  dialogContent: {
    padding: THEME.spacing.xl,
    overflowY: "auto",
    flexGrow: 1,
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    fontSize: 14,
    color: THEME.subdued,
  },
}));

const CrmViewsDialog = ({onClose}) => {
  const classes = useStyles();
  const history = useHistory();
  const {t} = useTranslation();
  const dialogRef = useRef(null);
  const location = useLocation();

  const handleRedirect = (queryString) => {
    if (queryString) {
      const baseUrl = window.location.pathname;
      history.push(`${baseUrl}${queryString}`);
    }
    onClose();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <Paper className={classes.paper} ref={dialogRef}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography className={classes.titleText}>
          {t("guest-views-dialog-title")}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body2" className={classes.subtitle}>
          {t("guest-views-dialog-subtitle")}
        </Typography>
        <Grid container spacing={3}>
          {Object.entries(guestViewsUrlMap).map(([key, view]) => (
            <Grid item xs={4} key={key}>
              <IconCard
                icon={view.icon}
                title={view.title}
                description={view.description}
                onClick={() => {
                  handleRedirect(
                    key !== "all_guests" ||
                      new URLSearchParams(location.search).get("columns") !==
                        null ||
                      new URLSearchParams(location.search).get("filters") !==
                        null
                      ? view.redirect_url
                      : null,
                  );
                }}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Paper>
  );
};

export default CrmViewsDialog;
