import React from "react";
// UI
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  SvgIcon,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import ImageIcon from "@material-ui/icons/Image";
import headerBG from "assets/img/product_header_bg.jfif";
import {ReactComponent as DragIcon} from "assets/icons/Icon_Reorder.svg";
// custom
import DeleteIconButton from "core/buttons/DeleteIconButton";
import EditIconButton from "core/buttons/EditIconButton";
// Utils
import {FIXED_SIZES} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  listItem: {
    minHeight: `calc(${FIXED_SIZES.resource_item}px - ${theme.spacing(2)}px)`,
    margin: theme.spacing(2, 0, 0),
    background: `linear-gradient(0deg, #F5F5F5, #F5F5F5), url(${headerBG})`,
    borderRadius: 10,
    padding: (props) =>
      !!props.onlyTitle ? theme.spacing(4, 3) : theme.spacing(3),
    cursor: "pointer",
  },
  container: {width: "100%"},
  moveCursor: {cursor: "move !important"},
  pointerCursor: {cursor: "pointer"},
  listItemAvatar: {minWidth: 78},
  listItemIcon: {minWidth: (props) => (!!props.onlyTitle ? 34 : 40)},
  root: {
    paddingRight: (props) => (!!props.edit ? 56 : 0),
    margin: 0,
  },
  text: {
    fontSize: 13,
    lineHeight: "16px",
    color: theme.palette.text.primary,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
    lineClamp: 2,
  },
  placeholder: {
    fontStyle: "italic",
    opacity: 0.5,
  },
  avatar: {
    backgroundColor: "#D9D9D9",
    borderRadius: 5,
    width: 62,
    height: 62,
  },
  dragIcon: {
    width: 12,
    marginRight: theme.spacing(3),
  },
  imageIcon: {
    fontSize: 84,
    color: "#FFF",
  },
}));

export default function DraggableItem({
  picture = null,
  icon = null,
  title = "",
  text = null,
  edit,
  showEmptyPicture,
  disabled,
  placeholder,
  onItemClick,
  onEdit = () => null,
  onDelete = () => null,
  disableReorder = false,
}) {
  const classes = useStyles({onlyTitle: text === null, edit});

  return (
    <ListItem
      button
      disabled={disabled}
      disableRipple
      ContainerComponent="div"
      className={clsx(classes.listItem, {
        [classes.pointerCursor]: !!onItemClick && !disableReorder,
        [classes.moveCursor]: !!edit && !disableReorder,
      })}
      classes={{container: classes.container}}
      onClick={onItemClick}
    >
      {!disableReorder && !!edit && (
        <SvgIcon
          viewBox="0 0 14 15"
          component={DragIcon}
          className={classes.dragIcon}
        />
      )}
      {!!picture || !!showEmptyPicture ? (
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar alt="Step Image" src={picture} className={classes.avatar}>
            <ImageIcon className={classes.imageIcon} />
          </Avatar>
        </ListItemAvatar>
      ) : !!icon ? (
        <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      ) : null}
      <ListItemText
        primary={title}
        classes={{root: classes.root}}
        primaryTypographyProps={{variant: "h1"}}
        secondary={!!text ? text : placeholder}
        secondaryTypographyProps={{
          component: "div",
          className: clsx(classes.text, {
            [classes.placeholder]: !text && !!placeholder,
          }),
        }}
      />
      {!!edit && (
        <ListItemSecondaryAction>
          <EditIconButton disabled={disabled} disablePadding onClick={onEdit} />
          <DeleteIconButton
            disabled={disabled}
            disablePadding
            marginLeft={2}
            onClick={onDelete}
          />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
