import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {Button, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import ListingConnection from "components/MultiOption/ListingConnection";
import FilledTextField from "core/inputs/FilledTextField";
import PrimaryButton from "core/buttons/PrimaryButton";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import UpdateSession from "components/Dialogs/UpdateSession";
// Actions
import {
  deleteListingGroup,
  editListingGroup,
  saveListingGroup,
} from "redux/actions/listingsActions";
import {specialFilters} from "configuration/constants";

const useStyles = makeStyles((theme) => ({
  groupSummary: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  actionsRow: {
    display: "flex",
    padding: theme.spacing(4, 0),
    justifyContent: "flex-end",
    gap: theme.spacing(2),
  },
  deleteBtn: {
    color: theme.palette.error.main,
    fontWeight: 500,
    width: "fit-content",
    marginRight: "auto",
    minWidth: 0,
    textTransform: "none",
  },
  cancelBtn: {padding: theme.spacing(1, 3)},
  container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
}));

export default function ListingGroupSettings({
  edit,
  data,
  loading,
  selectedGroup,
  hideActions,
  setData,
  resetData,
  setLoading,
  closeGroup,
  onEdit,
  isInsideModal,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const newGroupToSave = React.useRef(null);
  // General
  const connectedListings = selectedGroup?.connected_to?.ids || [];
  const [updateSession, setUpdateSession] = React.useState(null);
  const [deleteUpdateSession, setDeleteUpdateSession] = React.useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );

  const handleNameChange = (e) => {
    const val = e.target.value;
    setData({...data, name: val});
  };

  const handleDataChange = (newData) => setData({...data, ...newData});
  const handleCancelUpdates = () => setUpdateSession((prev) => null);

  const closeDeleteConfirmation = () => {
    setOpenDeleteConfirmation((prev) => false);
    setDeleteUpdateSession((prev) => null);
  };

  const onSuccess = () => {
    setLoading(false);
    setUpdateSession((prev) => null);
    closeDeleteConfirmation();
  };

  const onError = () => {
    setLoading(false);
    setUpdateSession((prev) => null);
    closeDeleteConfirmation();
  };

  const handleCloseSettings = () => {
    resetData();
    onEdit(false);
  };

  const handleSave = (saveUpdate) => {
    const groupId = selectedGroup.group_id;
    setLoading("Saving group...");
    let newData = {
      group_id: groupId,
      name: data.name,
      connected_to: {
        object: "listing",
        ids: data.listings,
      },
    };

    if (!!saveUpdate && !!updateSession) {
      const newGroup = {...selectedGroup, ...newData};
      dispatch(editListingGroup(newGroup));
      newData = {update_id: updateSession.update_session.update_id};
    }
    if (!saveUpdate) {
      newGroupToSave.current = {...selectedGroup, ...newData};
    }

    dispatch(
      saveListingGroup({
        body: newData,
        groupId,
        onSuccess: (response) => {
          if (!updateSession) {
            dispatch(editListingGroup(newGroupToSave.current));
            newGroupToSave.current = null;
          }
          onSuccess();
          handleCloseSettings();
        },
        onUpdate: !saveUpdate
          ? (update) => {
              setUpdateSession((prev) => update);
              setLoading(false);
            }
          : null,
        onError: onError,
      }),
    );
  };

  const handleDelete = (confirm) => {
    setLoading(confirm ? "Deleting group..." : "Processing changes...");
    dispatch(
      deleteListingGroup({
        groupId: selectedGroup.group_id,
        updateId:
          !!confirm && !!deleteUpdateSession
            ? deleteUpdateSession.update_session.update_id
            : null,
        onSuccess: () => {
          closeGroup(!!confirm);
          onSuccess();
        },
        onError: onError,
        onUpdate: !confirm
          ? (update) => {
              setDeleteUpdateSession((prev) => update);
              setLoading(false);
              setOpenDeleteConfirmation(true);
            }
          : null,
      }),
    );
  };

  const confirmModal = !!updateSession && (
    <ConfirmDialog
      disableDismiss
      open={!loading}
      onClose={handleCancelUpdates}
      title="Confirm updates?"
      message={
        <>
          {"Updating this group will affect the following:"}
          <UpdateSession updates={updateSession.update_session.payload} />
        </>
      }
      confirmLabel="Confirm"
      confirmAction={() => handleSave(true)}
      cancelLabel="Cancel"
      cancelAction={handleCancelUpdates}
    />
  );

  const confirmDeleteModal = !!deleteUpdateSession && (
    <ConfirmDialog
      disableDismiss
      open={!loading && openDeleteConfirmation}
      onClose={closeDeleteConfirmation}
      title="Delete listing group?"
      message={
        <>
          {!!deleteUpdateSession.update_session.updates?.length ? (
            <>
              {"Deleting this group will affect the following:"}
              <UpdateSession
                updates={deleteUpdateSession.update_session.payload}
              />
            </>
          ) : (
            "This listing group with all its configuration will be deleted."
          )}
        </>
      }
      confirmLabel="Delete"
      confirmAction={() => handleDelete(true)}
      cancelLabel="Cancel"
      cancelAction={closeDeleteConfirmation}
    />
  );

  return edit ? (
    <div className={classes.container}>
      {confirmModal}
      {confirmDeleteModal}
      {!hideActions && (
        <div className={classes.actionsRow}>
          <Button
            className={classes.deleteBtn}
            size="small"
            onClick={() => handleDelete()}
          >
            Delete listing group
          </Button>
          <PrimaryButton
            label="Cancel"
            variant="outlined"
            className={classes.cancelBtn}
            onClick={handleCloseSettings}
          />
          <PrimaryButton label="Save" onClick={() => handleSave()} />
        </div>
      )}
      <FilledTextField
        id="group-name-input"
        label="Group name"
        placeholder="e.g: Residences"
        onChange={handleNameChange}
        value={data.name}
        fullWidth
      />
      <ListingConnection
        data={data}
        keepOpen
        showHouseList
        defaultFilters={
          selectedGroup === "create"
            ? specialFilters.ungrouped.filters
            : undefined
        }
        itemLimit={user_profile?.name === "admin" ? 1000 : 200}
        disableConnectionTypes
        isInsideModal={isInsideModal}
        setData={handleDataChange}
      />
    </div>
  ) : (
    <div className={classes.groupSummary}>
      <div className={classes.text}>
        <Typography variant="subtitle2">
          {`${connectedListings.length} Listing${connectedListings.length !== 1 ? "s" : ""}`}
        </Typography>
        <Typography>
          {
            "Edit settings below to customize your guest journey for these listings."
          }
        </Typography>
      </div>
      <PrimaryButton
        size="small"
        label="Edit group settings"
        onClick={() => onEdit(true)}
      />
    </div>
  );
}
