import React from "react";
import {useDispatch} from "react-redux";
// UI
import {Col, Row} from "reactstrap";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import PrimaryButton from "core/buttons/PrimaryButton";
import FilledTextField from "core/inputs/FilledTextField";
import ConnectedToButton from "core/buttons/ConnectedToButton";
import CustomDialog from "core/dialogs/CustomDialog";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import {
  deleteListingResource,
  editHouse,
  saveListingResource,
  updateListingResource,
} from "redux/actions/listingsActions";
// Utilities
import clsx from "clsx";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  addButton: {
    padding: theme.spacing(2, 0),
    marginTop: -theme.spacing(5),
    "&:hover": {backgroundColor: "#FFF"},
  },
  actionIcon: {
    color: "#ABB1C8",
    fontSize: 18,
    marginLeft: 6,
    transition: "0.15s",
    "&.-initial": {marginLeft: 11},
    "&:hover": {opacity: 0.7},
  },
  viewOnlyField: {
    display: "flex",
    alignItems: "center",
    "& .-action-icons": {
      opacity: 0,
      transition: "0.15s",
    },
    "&:hover .-action-icons": {opacity: 1},
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  chekinDetailsContainer: {
    height: "100%",
    "& iframe": {border: "none"},
  },
}));

export default function CustomFields({
  listing,
  disabled,
  fields,
  setIsEditing = () => {},
  onChange,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [customFields, setCustomFields] = React.useState(fields);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [newField, setNewField] = React.useState({properties: {}});
  const [loadingChekin, setLoadingChekin] = React.useState(false);
  const [chekinModalOpen, setChekinModalOpen] = React.useState(false);
  const [listingsOpen, setListingsOpen] = React.useState(false);
  let saveDisabled = !newField.properties.name || !newField.properties.value;

  React.useEffect(() => {
    setCustomFields((prev) => fields);
  }, [fields]);

  React.useEffect(() => {
    setIsEditing(openDialog);
  }, [openDialog]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (!_.isEqual(customFields, fields)) {
        onChange(customFields);
      }
    }, 200);

    return () => clearTimeout(timer);
  }, [customFields]);

  React.useEffect(() => {
    if (!window.ChekinHousingsSDK || !chekinModalOpen || !listing) {
      return;
    }

    async function initializeChekin() {
      console.log("INITIALIZING CHEKIN SDK");

      const ChekinHousingsSDK = new window.ChekinHousingsSDK();

      let chekinListingId = null;
      if (fields) {
        let chekinListingCstmField = fields.filter(
          (cf) => cf?.properties?.name === "chekin_listing_id",
        );
        if (chekinListingCstmField) {
          chekinListingId = chekinListingCstmField[0]?.properties?.value;
        }
      }

      if (ChekinHousingsSDK && chekinListingId) {
        ChekinHousingsSDK.initialize({
          apiKey: process.env.REACT_APP_CHEKIN_HOST_SDK_API_KEY,
          housingId: chekinListingId,
        });

        ChekinHousingsSDK.renderApp({targetNode: "chekin-details-target"});
      }
    }

    setTimeout(() => {
      setLoadingChekin(false);
      initializeChekin();
    }, 100);
  }, [chekinModalOpen]);

  function handleAddField() {
    setNewField({
      resource: "cv",
      properties: {
        name: "",
        value: "",
      },
    });
    setOpenDialog(true);
  }

  function handleSelectField(cf) {
    setNewField(cf);
    setOpenDialog(true);
  }

  function handleDeleteField(cf) {
    setNewField(cf);
    setDeleteModalOpen(true);
  }

  const getUpdatedListing = (newCustomField, type) => {
    let newFields = [...listing?.listing_content?.custom];
    switch (type) {
      case "add":
        newFields.push(newCustomField);
        break;
      case "update":
        newFields = newFields.map((f) =>
          f.resource_id === newCustomField.resource_id ? newCustomField : f,
        );
        setCustomFields((prev) =>
          prev.map((f) =>
            f.resource_id === newCustomField.resource_id ? newCustomField : f,
          ),
        );
        break;
      case "delete":
        newFields = newFields.filter(
          (f) => f.resource_id !== newCustomField.resource_id,
        );
        setCustomFields((prev) =>
          prev.filter((f) => f.resource_id !== newCustomField.resource_id),
        );
        break;
    }

    return {
      ...listing,
      listing_content: {...listing.listing_content, custom: newFields},
    };
  };

  const deleteField = () => {
    let newListing = getUpdatedListing(newField, "delete");
    dispatch(
      deleteListingResource({
        resource: newField.resource,
        resource_id: encodeURIComponent(newField.resource_id),
        type: "field",
        listing: newListing,
      }),
    );
    setDeleteModalOpen(false);
  };

  const saveAdditionalInfo = () => {
    let newListing = getUpdatedListing(
      newField,
      !!newField.resource_id ? "update" : "add",
    );
    if (!!newField.resource_id) {
      dispatch(
        updateListingResource({
          body: newField,
          listing: newListing,
          onSuccess: (r) => {
            dispatch(editHouse(getUpdatedListing(r, "update")));
          },
        }),
      );
    } else {
      dispatch(
        saveListingResource({
          body: newField,
          type: "field",
          listing: newListing,
          onSuccess: (r) => {
            dispatch(editHouse(getUpdatedListing(r, "add")));
          },
        }),
      );
    }
    closeDialog();
  };

  const closeDialog = () => {
    setOpenDialog((prev) => false);
    setNewField((prev) => ({properties: {}}));
  };

  const onNewFieldChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    setNewField((prev) => ({
      ...prev,
      properties: {...prev.properties, [id]: value},
    }));
  };

  function getDialogContent() {
    return (
      <>
        <Row style={{height: listingsOpen ? "100%" : "fit-content"}}>
          <Col xs={12}>
            <ConnectedToButton
              listing={listing}
              listing_id={listing?.listing_id}
              group_id={listing?.group_id}
              data={newField}
              editData={setNewField}
              listingsOpen={listingsOpen}
              setListingsOpen={setListingsOpen}
            />
          </Col>
        </Row>
        {!listingsOpen && (
          <Row className="mt-3">
            <Col xs={6}>
              <FilledTextField
                id="name"
                fullWidth
                label="Information Type"
                value={newField.properties.name}
                placeholder="e.g: Guest Portal"
                onChange={onNewFieldChange}
              />
            </Col>
            <Col xs={6}>
              <FilledTextField
                id="value"
                fullWidth
                label="Details"
                value={newField.properties.value}
                placeholder="e.g: www.yourguestportal.com"
                onChange={onNewFieldChange}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }

  const additionalInformationDialog = (
    <CustomDialog
      open={openDialog}
      labelConfirm="Add information"
      disableConfirm={saveDisabled}
      labelCancel="Cancel"
      title="Additional information"
      maxWidth="md"
      fullWidth
      customHeight={listingsOpen ? "80vh" : null}
      content={getDialogContent()}
      onClose={closeDialog}
      actionCancel={closeDialog}
      actionConfirm={saveAdditionalInfo}
    />
  );

  const confirmDeleteModal = !!deleteModalOpen && (
    <ConfirmDialog
      open
      maxWidth="sm"
      disableDismiss
      onClose={() => setDeleteModalOpen(false)}
      title={`Delete sentence?`}
      message={<>{"Delete this field from the listings it's connected to"}</>}
      confirmLabel="Delete"
      confirmAction={deleteField}
      cancelLabel="Cancel"
      cancelAction={() => setDeleteModalOpen(false)}
    />
  );

  const additionalButton = (
    <Row>
      <Col xs={12}>
        <Button
          color="secondary"
          startIcon={<AddIcon />}
          classes={{root: classes.addButton}}
          onClick={() => handleAddField()}
        >
          Add Additional Information
        </Button>
      </Col>
    </Row>
  );

  function openChekinModal() {
    setLoadingChekin(true);
    setChekinModalOpen(true);
  }

  function onCloseChekin() {
    setLoadingChekin(false);
    setChekinModalOpen(false);
  }

  function getViewOnlyField(cf, ref = null) {
    const name = cf.properties.name;
    const val =
      typeof cf?.properties?.value === "string"
        ? cf?.properties?.value
        : cf?.properties?.value?.[0]?.value;

    const isChekinListing = name?.toLowerCase().startsWith("chekin_");

    return (
      <div className={classes.viewOnlyField}>
        <div ref={ref} style={{flex: 1}}>
          <div className={classes.row}>
            <Typography
              className={classes.fieldLabel}
            >{`${name.charAt(0).toUpperCase()}${name.substring(1)}`}</Typography>
            {isChekinListing && (
              <PrimaryButton
                size="small"
                variant="text"
                color="secondary"
                label="See Chekin details"
                className={classes.autohostBtn}
                onClick={openChekinModal}
              />
            )}
            {!disabled && (
              <div className="-action-icons">
                <IconButton size="small" onClick={() => handleSelectField(cf)}>
                  <EditIcon className={clsx(classes.actionIcon, "-initial")} />
                </IconButton>
                <IconButton size="small" onClick={() => handleDeleteField(cf)}>
                  <DeleteIcon className={classes.actionIcon} />
                </IconButton>
              </div>
            )}
          </div>
          <Typography variant="h1">{val || "None"}</Typography>
        </div>
      </div>
    );
  }

  const chekinModal = (
    <CustomDialog
      open={chekinModalOpen}
      fullWidth
      maxWidth="sm"
      customHeight={"100%"}
      disableContentPadding
      title="Chekin details"
      content={
        loadingChekin ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={"100%"}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div
            id="chekin-details-target"
            className={classes.chekinDetailsContainer}
          />
        )
      }
      onClose={() => onCloseChekin()}
      disableActions
    />
  );

  return (
    <>
      {chekinModal}
      {deleteModalOpen && confirmDeleteModal}
      {!!openDialog && additionalInformationDialog}
      {!!customFields.length &&
        customFields.map((cf) => {
          return (
            <Row key={cf.resource_id}>
              <Col xs={12} className="mb-4">
                {getViewOnlyField(cf)}
              </Col>
            </Row>
          );
        })}
      {!disabled && additionalButton}
    </>
  );
}
