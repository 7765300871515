const infoTexts = {
  checkinDirections:
    "Your check-in instructions are used for scheduled automations, messages & workflows. This is an optional field, and is automatically synchronized from your property management software.",
  checkoutDirections:
    "Enter your check-out instructions for scheduled automations, messages, workflows. This is an optional field.",
  checkinSteps: `If you are using the Boarding Pass - the check-in instructions provided to your guests are here. Please create step by step, picture by picture instructions. Example: Your first image can be the front door, and the instruction can be how to unlock the door! You can add as many steps as you'd like!`,
  checkoutSteps: `If you are using the Boarding Pass - the check-out instructions provided to your guests are here. Please create step by step, picture by picture instructions. Example: Your first image can be the place to put the garbage, and the instructions is how to take out the garbage. You can add as many steps as you'd like.`,
  listingtimezone: "In listing’s timezone",
  productOption: "Offer guests upsell options at different prices",
  totalRevenue:
    "Total fee & upsell revenue across all currencies (converted using recent exchange rates.) Excludes uncollected safety deposits & any cancelled, refunded or unpaid purchases.",
  totalRevenueUpsells:
    "Total upsell revenue across all currencies (converted using recent exchange rates.) Excludes cancelled, refunded or unpaid upsells.",
  totalRevenueFees:
    "Total fee revenue across all currencies (converted using recent exchange rates.) Excludes uncollected safety deposits & any cancelled, refunded or unpaid fees.",
  bpProgress:
    "Total guests who have opened, been verified & checked-in through the Boarding Pass.",
  guestSatisfaction:
    "Average satisfaction measured by Enso AI using your guest’s conversation history.",
  totalBookings:
    "Total bookings (all statuses) broken down by channel or booking status.",
};

export default infoTexts;
