import React, {useState} from "react";
import {
  Typography,
  ListItem,
  ListItemIcon,
  Grid,
  Link,
  CircularProgress,
  makeStyles,
  useTheme,
  Box,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import ContactInfo from "./ContactInfo";
import {getAbsoluteUrl, sleep} from "utilities/helperFunctions";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
  box: {
    borderRadius: 999,
    margin: 0,
    marginBottom: 10,
    "&.right-aligned": {minWidth: "0!important"},
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
});

export default function LockButton({
  state,
  handleUnlock,
  components,
  device_id,
  hideCode = false,
  showLink,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false);
  const [recoveryFailed, setRecoveryFailed] = useState(false);

  let color = theme.palette.primary.main;
  const LOCK_STATES = {
    locked: {
      lock_loading: false,
      lock_button: t("unlock_door"),
      icon: <LockOpenIcon style={{color: color}} />,
      locked: true,
    },
    unlocked: {
      lock_loading: false,
      lock_button: t("lock_door"),
      icon: <LockIcon style={{color: color}} />,
      locked: false,
    },
  };
  const LOADING_STATES = {
    locked: {
      lock_loading: true,
      lock_button: `${t("unlocking_door")}...`,
      icon: <CircularProgress size={20} style={{color: color}} />,
      locked: null,
    },
    unlocked: {
      lock_loading: true,
      lock_button: `${t("locking_door")}...`,
      icon: <CircularProgress size={20} style={{color: color}} />,
      locked: null,
    },
  };
  const button_state =
    state.hasOwnProperty("smart_lock") && loading
      ? LOADING_STATES[state.smart_lock]
      : LOCK_STATES[state.smart_lock];

  function unlockDoor() {
    setLoading(true);
    sleep(1000).then(() => setLoading(false));
  }

  function requestRecoveryCode() {
    setCodeLoading(true);
    sleep(1000).then((response) => {
      setCodeLoading(false);
    });
  }

  function handleCodeNotWorking() {
    if (!state.recovery_code) {
      requestRecoveryCode();
    } else {
      setRecoveryFailed(true);
    }
  }

  function handleUrlClick(url) {
    let absoluteUrl = getAbsoluteUrl(url);
    const newWindow = window.open(absoluteUrl, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }

  let error_msg = (state.error || recoveryFailed) && (
    <div>
      <div
        style={{
          backgroundColor: theme.palette.primary.main,
          borderRadius: 10,
          padding: 10,
          marginBottom: 15,
        }}
      >
        <Typography style={{color: theme.palette.primary.contrastText}}>
          {state.error || t("contact_host")}
        </Typography>
      </div>
      <ContactInfo components={components} />
    </div>
  );

  let not_working_button = (
    <Grid className={classes.link}>
      <Link
        onClick={() => handleCodeNotWorking()}
        style={{pointerEvents: codeLoading ? "none" : ""}}
      >
        {t("code_not_working")}
      </Link>
    </Grid>
  );

  let code = (
    <Grid flex="1">
      <Typography variant="h5">
        {codeLoading ? (
          <CircularProgress size={20} style={{color: color}} />
        ) : (
          state?.smart_lock_code
        )}
      </Typography>
    </Grid>
  );

  function getButton(rightAligned) {
    return (
      (button_state || showLink) && (
        <ListItem
          button
          id={`lock-${device_id}`}
          style={{
            border: `1.5px solid ${theme.palette.primary.main}`,
            padding: 10,
            margin: 0,
            marginTop: showLink ? 10 : 0,
            marginBottom: 10,
            minWidth: "220px",
          }}
          className={`${classes.box} ${rightAligned ? "right-aligned" : ""}`}
          disabled={!showLink && button_state.lock_loading}
          onClick={() =>
            showLink ? handleUrlClick(state.link_url) : unlockDoor()
          }
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              width: showLink ? "100%" : "fit-content",
            }}
          >
            {!showLink && (
              <ListItemIcon style={{minWidth: 0, marginRight: 10}}>
                {button_state.icon}
              </ListItemIcon>
            )}
            <Typography variant="h6">
              {showLink ? state.link_label || "" : button_state.lock_button}
            </Typography>
          </Box>
        </ListItem>
      )
    );
  }

  return (
    <>
      <Typography variant="body2">{state.smart_lock_name}</Typography>
      {!!state.smart_lock_code && !hideCode ? (
        <Grid
          container
          style={{marginBottom: theme.spacing(2)}}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid flex="1">{code}</Grid>
          <Grid>{getButton(true)}</Grid>
        </Grid>
      ) : (
        getButton()
      )}
      {!!state.smart_lock_code && !hideCode && (
        <Grid container style={{marginBottom: theme.spacing(2)}}>
          {not_working_button}
        </Grid>
      )}
      {error_msg}
    </>
  );
}
