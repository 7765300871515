import React from "react";
import {Button, CardContent, makeStyles, Typography} from "@material-ui/core";
import CustomCardHeader from "core/cards/CustomCardHeader";

const useStyles = makeStyles((theme) => ({
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    wordBreak: "break-word",
  },
  cardDetails: {
    width: "100%",
    cursor: "pointer",
  },
}));

export default function FormControlLabelPosition({
  listing,
  updateData = () => null,
}) {
  const classes = useStyles();
  return (
    <div>
      <CustomCardHeader title="Automatic Pricing" type="title" />
      <div className={classes.cardDetails}>
        <CardContent style={{paddingTop: 0}}>
          <Typography
            variant="subtitle1"
            className={classes.ellipsis}
            style={{fontWeight: "500"}}
          >
            Enable automated pricing, powered by Use Wheelhouse.
          </Typography>
          <Button
            className="mt-2"
            color="primary"
            variant="outlined"
            onClick={() =>
              updateData({use_wheelhouse: !listing.use_wheelhouse})
            }
          >
            {listing.use_wheelhouse ? "Disable" : "Enable"}
          </Button>
          {listing.use_wheelhouse && (
            <Typography>Login to use wheelhouse:</Typography>
          )}
        </CardContent>
      </div>
    </div>
  );
}
