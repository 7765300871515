import React from "react";
import {Button, Card, makeStyles} from "@material-ui/core";
import {Container} from "reactstrap";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    zIndex: 1,
  },
  card: {
    height: "calc(100vh - 120px)",
    width: "100%",
    minHeight: 300,
    display: "flex",
    flexDirection: "column",
  },
  btn: {marginTop: -theme.spacing(5)},
}));

export default function FullSizeCard({goBack, children}) {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      {goBack && (
        <Button className={classes.btn} color="primary" onClick={goBack}>
          {"Back"}
        </Button>
      )}
      <Card className={clsx("ml-auto mt-1", classes.card)}>{children}</Card>
    </Container>
  );
}
