import {makeStyles} from "@material-ui/core";
import {THEME} from "configuration/settings";

const useGuestPanelStyles = makeStyles((theme) => ({
  container: {paddingBottom: theme.spacing(2)},
  guestName: {
    color: "#25213B",
    paddingBottom: theme.spacing(2),
  },
  nameRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: theme.spacing(2),
  },
  mobileCol: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: theme.spacing(3),
    gap: theme.spacing(3),
  },
  divider: {
    backgroundColor: "#8C9196",
    opacity: 0.2,
    margin: theme.spacing(0, 1),
  },
  columnContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  col: {
    flex: 1,
    maxWidth: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(3),
  },
  sectionLabel: {color: "rgba(109, 113, 117, 1)"},
  textButtonRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "&.-last": {marginBottom: 0},
  },
  outlinedContainer: {
    border: "1px solid #D9D9D9",
    borderRadius: 5,
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginTop: theme.spacing(3),
  },
  notesInput: {
    padding: 0,
    lineHeight: "24px",
  },
  modal: {
    padding: THEME.spacing.lg,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    maxWidth: 600,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  link: {
    marginBottom: 0,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "24px",
    cursor: "pointer",
    textDecoration: "underline",
    color: `${theme.palette.secondary.main} !important`,
    "&.mr4": {marginRight: THEME.spacing.xs},
  },
  viewButton: {minWidth: 0},
  blueButton: {
    margin: theme.spacing(2, 0),
    borderRadius: 5,
    textTransform: "none",
    backgroundColor: theme.palette.secondary.main + "0d !important",
  },
  iconBtn: {
    padding: 0,
    width: "fit-content",
    marginBottom: theme.spacing(1),
  },
  icon: {
    color: THEME.grey,
    width: 24,
    height: 24,
  },
  guestNameText: {
    ...theme.typography.h4,
  },
}));

export default useGuestPanelStyles;
