import React from "react";
// UI
import {makeStyles} from "@material-ui/core/styles";
import {ListItemIcon, MenuItem, Typography} from "@material-ui/core";
// Custom
import {CustomCountryFlag} from "components/Helpers/filterHelpers";
import FilledSelect from "core/selects/FilledSelect";
// Utilities
import {countryName} from "configuration/constants";
import {THEME} from "configuration/settings";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  text: {
    fontWeight: 400,
    color: THEME.grey,
  },
  listItemIcon: {
    minWidth: "fit-content",
    paddingRight: theme.spacing(3),
  },
  select: {
    "& > div": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

export default function Country({
  onlyCountries = [],
  edit,
  value,
  disabled,
  onValueChange,
  label = "Country",
}) {
  const classes = useStyles();
  const [country, setCountry] = React.useState(value);
  const avalCountries = React.useMemo(() => {
    return !!onlyCountries.length ? onlyCountries : Object.keys(countryName);
  }, [onlyCountries]);

  React.useEffect(() => {
    if (edit && value !== country) {
      setCountry((prev) => value);
    }
  }, [value]);

  React.useEffect(() => {
    if (edit && country !== value) {
      onValueChange(country);
    }
  }, [country]);

  const handleCountryChange = (e) => {
    const val = e.target.value;
    setCountry((prev) => val);
  };

  if (!edit) {
    return (
      <Typography variant="body1" className={classes.text}>
        {value ?? "-"}
      </Typography>
    );
  }
  return (
    <div className={classes.root}>
      <FilledSelect
        fullWidth
        disabled={disabled}
        label={label}
        value={country}
        className={classes.select}
        onChange={handleCountryChange}
      >
        <MenuItem value="" disabled>
          Select a country
        </MenuItem>
        {avalCountries.map((c) => (
          <MenuItem value={c} key={c}>
            <ListItemIcon className={classes.listItemIcon}>
              <CustomCountryFlag countryCode={c} />
            </ListItemIcon>
            {countryName[c]}
          </MenuItem>
        ))}
      </FilledSelect>
    </div>
  );
}
