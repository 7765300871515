import {darken, lighten, makeStyles} from "@material-ui/core";
import {THEME} from "configuration/settings.js";

const useBookingPanelStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #F0F0F0",
    boxShadow: "none",
    marginBottom: theme.spacing(4),
  },
  header: {
    padding: theme.spacing(4),
    transition: "0.15s",
  },
  avatar: {marginRight: theme.spacing(3)},
  cardMedia: {height: 140},
  cardHeaderActions: {
    margin: 0,
    marginLeft: theme.spacing(4),
    alignSelf: "center",
  },
  cardContent: {
    "&:last-child": {paddingBottom: theme.spacing(3)},
    padding: theme.spacing(3),
    gap: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  pointerCursor: {cursor: "pointer"},
  defaultCursor: {cursor: "default"},
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  bpContainer: {
    backgroundColor: "#FAFAFA",
    borderRadius: 10,
    padding: theme.spacing(4),
  },
  customFieldsContainer: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    marginTop: theme.spacing(4),
    marginLeft: -theme.spacing(4),
    marginRight: -theme.spacing(4),
    marginBottom: -theme.spacing(4),
    padding: theme.spacing(0, 4, 4),
    backgroundColor: "#FAFAFA",
  },
  title: {
    fontSize: 15,
    lineHeight: "18px",
    fontWeight: 700,
  },
  subtitle: {
    fontSize: 13,
    lineHeight: "16px",
    fontWeight: 400,
    paddingTop: 0,
  },
  icon: {
    width: 25,
    height: 25,
  },
  img: {
    width: 170,
    height: 74,
    borderRadius: 10,
    objectFit: "cover",
  },
  arrowIcon: {
    fontSize: 16,
    color: "#202223",
    margin: theme.spacing(0, 2),
  },
  text: {
    fontSize: "15px",
    lineHeight: "20px",
    color: "#202223",
  },
  greyContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#F9F9F9",
    borderRadius: 10,
    padding: theme.spacing(3),
    gap: theme.spacing(2),
  },
  nights: {fontWeight: 700},
  total: {color: "#202223 !important"},
  leftText: {fontWeight: 500},
  customFieldRow: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    gap: theme.spacing(1),
    cursor: "pointer",
    "&:not(.-disable):hover": {
      paddingRight: theme.spacing(1),
      "& .-iconBtn": {opacity: 1},
    },
    "&.-disable": {cursor: "default"},
    "& .-iconBtn": {
      opacity: 0,
      alignItems: "start",
      paddingTop: "2px",
    },
  },
  tagText: {
    color: "#4353607F",
    fontSize: "10px",
  },
  btnActions: {
    marginLeft: theme.spacing(2),
  },
  btnDeny: {
    backgroundColor: THEME.airbnb,
    color: "white",
    marginRight: theme.spacing(2),
    "&:hover": {backgroundColor: darken(THEME.airbnb, 0.15)},
  },
  btnReverify: {
    backgroundColor: "#FF9D2B",
    marginRight: theme.spacing(2),
    color: "white",
    "&:hover": {backgroundColor: darken("#FF9D2B", 0.15)},
  },
  btnApprove: {
    backgroundColor: "#00A740",
    color: theme.palette.getContrastText(THEME.airbnb),
    "&:hover": {backgroundColor: darken("#00A740", 0.15)},
  },
  centeredContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "row",
    padding: theme.spacing(2),
    "&.-hover": {
      borderRadius: 5,
      "&:hover": {border: `1px solid ${theme.palette.secondary.main}`},
    },
    "&.-center": {alignItems: "center"},
    "&.-end": {justifyContent: "flex-end"},
    "&.-p0": {padding: 0},
  },
  paddedRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(4),
    alignItems: "flex-start",
    "&.-mb0": {marginBottom: 0},
    "&.-center": {alignItems: "center"},
    "&.-end": {justifyContent: "flex-end"},
  },
  col: {
    display: "flex",
    flexDirection: "column",
    "&.-md-space": {gap: theme.spacing(3)},
  },
  tagsRow: {
    padding: theme.spacing(0, 2),
  },
  listingGroup: {
    color: "#707070",
    fontWeight: 500,
    opacity: 0.5,
    fontSize: 12,
  },
  houseIcon: {
    backgroundColor: "#f3f3f3",
    color: "#fff",
    width: "100%",
    height: "100%",
  },
  bpIcon: {
    color: "#202223",
    width: 20,
    height: 20,
  },
  bpText: {
    fontSize: 15,
    color: "#202223",
    margin: theme.spacing(0, 3),
  },
  bpTag: {
    backgroundColor: lighten("#9CA3A9", 0.85),
    padding: theme.spacing(0, 3),
  },
  bpTagLabel: {
    color: "#202223",
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "14px",
    textTransform: "uppercase",
  },
  link: {
    marginBottom: 0,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "24px",
    cursor: "pointer",
    textDecoration: "underline",
    color: `${theme.palette.secondary.main} !important`,
  },
  iconBtn: {
    opacity: 0,
    "&.-dense": {marginTop: theme.spacing(2)},
  },
  editIcon: {
    width: 12,
    height: 12,
  },
  input: {
    border: "1px solid #F0F0F0",
    borderRadius: 10,
    padding: theme.spacing(2, 3),
    backgroundColor: "#FFF",
    textAlign: "right",
  },
  cancelBtn: {
    color: "rgba(0,0,0,0.54)",
    fontWeight: 400,
  },
  saveBtn: {
    padding: theme.spacing(0, 3),
    minWidth: "fit-content",
  },
  accordion: {
    width: "100%",
    boxShadow: "none",
    backgroundColor: "transparent",
    "&.subitem": {
      border: "1px solid #F0F0F0",
      padding: theme.spacing(3),
      borderRadius: 5,
    },
  },
  accordionSummary: {
    padding: 0,
    minHeight: "0 !important",
  },
  summaryContent: {margin: "0 !important"},
  expandIcon: {
    marginRight: -5,
    padding: 0,
  },
  accordionDetails: {
    padding: theme.spacing(2, 0, 0),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  subitemRow: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F5F5F5",
    borderRadius: 5,
    gap: theme.spacing(2),
  },
  subtitleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
    "& .-icon": {
      width: 16,
      height: 16,
    },
  },
  expandedArea: {
    "&.-open": {
      backgroundColor: "#F5F5F5",
      borderRadius: 5,
    },
  },
  expandedSection: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  customFieldText: {
    color: THEME.subdued,
    flex: 1,
  },
  chip: {
    padding: theme.spacing(1, 2),
    height: "fit-content",
  },
  chipLabel: {
    height: 16,
    padding: 0,
  },
  stepsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  mt3: {marginTop: theme.spacing(3)},
  disabledBtn: {backgroundColor: THEME.text + "!important"},
  tertiaryButton: {
    color: theme.palette.common.white,
    backgroundColor: THEME.text,
    "&:hover": {backgroundColor: THEME.text + "cc"},
    "&.-disabled": {backgroundColor: THEME.text + "!important"},
  },
  deleteButton: {
    color: THEME.error,
    borderColor: THEME.error + "!important",
    "&:hover": {backgroundColor: THEME.error + "0a"},
  },
  subActionButton: {
    color: THEME.secondary,
    borderColor: THEME.secondary + "!important",
    "&:hover": {backgroundColor: THEME.secondary + "0a"},
  },
  autohostBtn: {
    "&:hover": {backgroundColor: "transparent"},
  },
  autohostDetailsContainer: {
    height: "100%",
    "& iframe": {border: "none"},
  },
  blueWarning: {
    padding: theme.spacing(3),
    borderRadius: 10,
    background: "rgba(47, 122, 251, 0.05)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(3),
  },
  warningIcon: {
    color: theme.palette.secondary.main,
    fontSize: 16,
  },
}));

export default useBookingPanelStyles;
