import React from "react";
import clsx from "clsx";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {makeStyles} from "@material-ui/core/styles";
import {IconButton, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: 0,
    position: "absolute",
    "& > * + *": {
      marginTop: theme.spacing(4),
    },
  },
  content: {
    display: "flex",
    alignItems: "center",
  },
  fullWidth: {width: "100%"},
  snackbarRoot: {
    "& .MuiSnackbarContent-root": {backgroundColor: theme.palette.common.white},
    "& .MuiSnackbarContent-action": {
      alignSelf: (props) => (props.centerActionIcon ? "center" : "flex-start"),
    },
  },
  snackbarTop: {
    alignItems: "center",
    marginBottom: 0,
    zIndex: theme.zIndex.tooltip,
  },
  snackbarBottom: {
    height: "fit-content",
    top: "auto",
    marginBottom: 20,
  },
  textContainer: {paddingLeft: theme.spacing(4)},
  greyColor: {color: "rgba(0,0,0,0.6)"},
}));

export default function ErrorAlert({
  open,
  message,
  subtitle,
  disableSeverity,
  onClose,
  duration = 4000,
  vertical = "top",
  horizontal = "right",
}) {
  const styleProps = {centerActionIcon: !subtitle};
  const classes = useStyles(styleProps);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose && onClose();
  };

  return (
    <div className={clsx(classes.container, {[classes.fullWidth]: open})}>
      <Snackbar
        open={open}
        className={
          vertical === "top" ? classes.snackbarTop : classes.snackbarBottom
        }
        classes={{root: classes.snackbarRoot}}
        anchorOrigin={{vertical: vertical, horizontal: horizontal}}
        autoHideDuration={duration}
        disableWindowBlurListener
        onClose={handleClose}
        message={
          disableSeverity ? (
            <div className={classes.content}>
              <ErrorIcon color="error" fontSize="large" />
              <div className={classes.textContainer}>
                <Typography variant="h1" className={classes.greyColor}>
                  {message}
                </Typography>
                {!!subtitle && (
                  <Typography variant="body1" className={classes.greyColor}>
                    {subtitle}
                  </Typography>
                )}
              </div>
            </div>
          ) : null
        }
        action={
          disableSeverity && (
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon fontSize="small" color="action" />
            </IconButton>
          )
        }
      >
        {!disableSeverity ? (
          <Alert onClose={handleClose} severity="error">
            {message}
          </Alert>
        ) : null}
      </Snackbar>
    </div>
  );
}
