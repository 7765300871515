import React from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {timeElapsed} from "utilities/helperFunctions";
import {THEME} from "configuration/settings";
import SimpleField from "./SimpleField";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "14.32px",
    letterSpacing: "0.05em",
    color: "#25213B",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    width: "100%",
    wordBreak: "break-all",
  },
  textColorVariant: {color: `${THEME.grey} !important`},
}));

export default function Communication({
  numMessages = null,
  responseTime = null,
  showEmptyResponseTime,
}) {
  const classes = useStyles();

  if (numMessages === null && responseTime === null)
    return <SimpleField value="-" />;
  return (
    <div className={classes.root}>
      {numMessages !== null && (
        <Typography className={classes.text}>
          {numMessages} message{numMessages === 1 ? "" : "s"}
        </Typography>
      )}
      {(!!responseTime || !!showEmptyResponseTime) && (
        <Typography
          className={clsx(classes.text, {
            [classes.textColorVariant]: !!numMessages,
          })}
        >
          {`${!!responseTime ? timeElapsed(responseTime) : "Unknown"} response time`}
        </Typography>
      )}
    </div>
  );
}
