import React from "react";
import {Box} from "@material-ui/core";
import CustomDialog from "core/dialogs/CustomDialog";
import CreateFromExistingList from "components/Lists/CreateFromExistingList";

const SelectExistingDialog = ({
  open,
  title,
  resource,
  onClose,
  handleItemSelect,
}) => {
  return (
    <CustomDialog
      fullWidth
      disableExit
      disableActions
      open={open}
      maxWidth="md"
      customHeight="100%"
      disableEnforceFocus
      titleVariant="header"
      transitionDuration={0}
      title={title}
      onClose={onClose}
      content={
        <Box height="100%">
          <CreateFromExistingList
            resource={resource}
            onSelect={handleItemSelect}
          />
        </Box>
      }
    />
  );
};

export default SelectExistingDialog;
