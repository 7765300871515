import React from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {THEME} from "configuration/settings";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "14.32px",
    letterSpacing: "0.05em",
    color: THEME.grey,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    width: "100%",
    wordBreak: "break-all",
  },
}));

export default function Stay({adults = 0, childs = 0, showEmptyChilds}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>
        {`${adults} adult${adults === 1 ? "" : "s"}`}
      </Typography>
      {(!!childs || !!showEmptyChilds) && (
        <Typography className={classes.text}>
          {`${childs} child${childs === 1 ? "" : "s"}`}
        </Typography>
      )}
    </div>
  );
}
