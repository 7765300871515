import React from "react";
import {SvgIcon} from "@material-ui/core";

export default function WarningIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M7.49994 15C9.23749 15.002 10.922 14.4014 12.2662 13.3004C13.6105 12.1995 14.5313 10.6665 14.8718 8.96259C15.2122 7.25873 14.9512 5.48952 14.1333 3.95655C13.3153 2.42358 11.991 1.22173 10.3861 0.555869C8.78121 -0.109992 6.99505 -0.198649 5.3321 0.30501C3.66916 0.808669 2.23235 1.87347 1.2666 3.3179C0.300851 4.76234 -0.134064 6.497 0.0359926 8.22621C0.206049 9.95541 0.97055 11.5721 2.19918 12.8008C3.60466 14.2076 5.51133 14.9987 7.49994 15ZM7.49994 1.4998C8.89006 1.49814 10.2377 1.97865 11.3132 2.85941C12.3887 3.74017 13.1255 4.96666 13.3979 6.32983C13.6703 7.69299 13.4615 9.10844 12.8071 10.3349C12.1528 11.5614 11.0933 12.5229 9.80931 13.0557C8.52532 13.5884 7.09632 13.6594 5.76587 13.2564C4.43543 12.8535 3.28591 12.0017 2.51325 10.846C1.74058 9.69044 1.39262 8.30263 1.52865 6.91919C1.66469 5.53574 2.27631 4.2423 3.25927 3.25932C4.3836 2.13372 5.909 1.50081 7.49994 1.4998V1.4998ZM6.75004 8.2499L6.75004 4.50006C6.75004 4.30117 6.82904 4.11043 6.96968 3.96979C7.11031 3.82916 7.30105 3.75015 7.49994 3.75015C7.69883 3.75015 7.88957 3.82916 8.0302 3.9698C8.17084 4.11043 8.24984 4.30117 8.24984 4.50006L8.24984 8.25022C8.24984 8.44911 8.17084 8.63985 8.0302 8.78048C7.88957 8.92112 7.69883 9.00013 7.49994 9.00013C7.30105 9.00013 7.11031 8.92112 6.96968 8.78048C6.82904 8.63985 6.75004 8.44911 6.75004 8.25022L6.75004 8.2499ZM7.49994 11.2537C7.67513 11.2544 7.84512 11.1942 7.98092 11.0835C8.11671 10.9729 8.20989 10.8185 8.24455 10.6468C8.27922 10.475 8.25322 10.2966 8.17099 10.1419C8.08876 9.98721 7.9554 9.86584 7.79367 9.79851C7.63193 9.73118 7.45185 9.72205 7.28414 9.77269C7.11642 9.82334 6.97148 9.9306 6.87404 10.0762C6.7766 10.2218 6.7327 10.3967 6.74983 10.571C6.76696 10.7454 6.84406 10.9084 6.96797 11.0322C7.10894 11.1736 7.30028 11.2533 7.49994 11.2537Z" />
    </SvgIcon>
  );
}
