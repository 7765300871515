import React from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import SimpleField from "./SimpleField";
import {THEME} from "configuration/settings";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  number: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "16.71px",
    color: "#25213B",
    textAlign: "right",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  currency: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "14.32px",
    letterSpacing: "0.05em",
    color: THEME.grey,
    textAlign: "right",
  },
}));

export default function Currency({value, currency}) {
  const classes = useStyles();

  if (value == null) {
    return <SimpleField value={"-"} />;
  }
  return (
    <div className={classes.root}>
      <NumberFormat
        value={value ?? 0}
        decimalScale={2}
        fixedDecimalScale
        thousandSeparator
        className={classes.number}
        displayType="text"
        prefix={currency === "EUR" ? "€" : "$"}
      />
      {!!currency && (
        <Typography className={classes.currency}>{currency}</Typography>
      )}
    </div>
  );
}
