import React from "react";
import CustomDialog from "core/dialogs/CustomDialog";
import TemplatesList from "../list/TemplatesList";

const SelectTemplateDialog = ({
  open,
  title,
  obj = {},
  resource,
  onClose,
  templateKeyName = "id",
  currency = "USD",
  handleItemSelect,
}) => {
  return (
    <CustomDialog
      fullWidth
      disableExit
      disableActions
      open={open}
      maxWidth="md"
      customHeight="100%"
      disableEnforceFocus
      titleVariant="header"
      transitionDuration={0}
      title={title}
      onClose={onClose}
      content={
        <TemplatesList
          resource={resource}
          obj={obj}
          templateKeyName={templateKeyName}
          currency={currency}
          handleItemSelect={handleItemSelect}
        />
      }
    />
  );
};

export default SelectTemplateDialog;
