import {makeStyles} from "@material-ui/core/styles";
import {THEME} from "configuration/settings";
import headerBG from "assets/img/product_header_bg.jfif";

const useExperiencePanelStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  titleRow: {
    width: "100%",
    padding: theme.spacing(4),
    borderBottom: "1px solid #0D568C0D",
  },
  buttonsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  btn: {
    flex: 1,
    border: `1px solid ${theme.palette.text.primary}`,
    padding: theme.spacing(1, 3),
    textTransform: "none",
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
    padding: theme.spacing(4),
    "&.pt0": {paddingTop: 0},
  },
  listContent: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  gutters: {margin: (props) => theme.spacing(0, !!props.extraGutters ? 5 : 4)},
  icon: {
    color: THEME.grey,
    fontSize: 18,
    marginRight: theme.spacing(3),
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  listItem: {
    padding: theme.spacing(3),
    paddingRight: 140,
    borderRadius: 10,
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(3),
    alignItems: "center",
    transition: "0.15s",
    background: `linear-gradient(0deg, #F5F5F5, #F5F5F5), url(${headerBG})`,
    "&:hover": {
      background: `linear-gradient(0deg, #EEE, #EEE), url(${headerBG})`,
    },
    "&.-full-pad": {paddingRight: 140},
    "&.-partial-pad": {paddingRight: 86},
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    transition: "0.15s",
  },
  selected: {background: `linear-gradient(0deg, #EEE, #EEE), url(${headerBG})`},
  itemText: {textTransform: "unset"},
  itemSecondaryText: {
    textTransform: "unset",
    color: "rgba(0,0,0,0.5)",
    fontWeight: 400,
    marginLeft: theme.spacing(1),
  },
  switch: {margin: "0 -4px"},
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  itemTitleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  tag: {
    color: theme.palette.text.primary,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: 5,
    padding: theme.spacing(1),
    minWidth: 100,
    maxWidth: "fit-content",
    flex: 1,
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    WebkitLineClamp: 1,
  },
}));

export default useExperiencePanelStyles;
