import React from "react";
import {Tooltip, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import FilledTextField from "core/inputs/FilledTextField";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    width: "100%",
    height: "100%",
  },
  text: {
    fontWeight: 400,
    color: THEME.grey,
  },
  bold: {
    fontWeight: 500,
    color: "#25213B",
  },
  capitalize: {textTransform: "capitalize"},
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: (props) => props.linesToShow ?? 1,
    width: "100%",
    wordBreak: "break-all",
  },
}));

export default function SimpleField({
  value = null,
  bold,
  capitalize,
  linesToShow = null,
  useTooltip,
  edit,
  onValueChange,
  defaultType,
}) {
  const styleProps = {linesToShow};
  const classes = useStyles(styleProps);
  const [text, setText] = React.useState(value);
  const val = value !== null ? (typeof value === "object" ? "-" : value) : "-";

  React.useEffect(() => {
    if (edit && value !== val) {
      setText((prev) => value);
    }
  }, [value]);

  React.useEffect(() => {
    if (edit) {
      onValueChange(text);
    }
  }, [text]);

  const handleValueChange = (e) => {
    const newVal = e.target.value;
    setText((prev) => newVal);
  };

  const getTooltip = (child) => {
    return (
      <Tooltip title={val} enterDelay={500} enterNextDelay={500}>
        {child}
      </Tooltip>
    );
  };

  const label = (
    <Typography
      variant="body1"
      className={clsx(classes.text, {
        [classes.bold]: !!bold,
        [classes.capitalize]: !!capitalize,
        [classes.ellipsis]: !!linesToShow,
      })}
    >
      {val}
    </Typography>
  );

  if (edit) {
    return (
      <div className={classes.fieldContainer}>
        <FilledTextField
          fullWidth
          label="Value"
          value={text}
          type={defaultType === "number" ? "number" : "text"}
          inputProps={defaultType === "number" ? {min: 0} : undefined}
          placeholder={defaultType === "number" ? "10" : "Enter value"}
          onChange={handleValueChange}
        />
      </div>
    );
  } else {
    return useTooltip ? getTooltip(label) : label;
  }
}
