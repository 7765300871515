import React from "react";
import BaseFieldComponent from "../BaseFieldComponent";
import GenericSelectionInput from "../../input/GenericSelectionInput";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";

class ProductNamesField extends BaseFieldComponent {
  renderInput({customFieldData, fieldData, handleValueChange}) {
    const options =
      customFieldData["Product Names"] &&
      customFieldData["Product Names"].length > 0
        ? customFieldData["Product Names"].map((product) =>
            product.name?.toLowerCase(),
          )
        : [];

    return (
      <GenericSelectionInput
        options={options}
        enableSearch={true}
        edit={true}
        value={fieldData.val}
        multiselect={fieldData?.operator !== "is_not"}
        onValueChange={(newValue) => {
          handleValueChange(
            fieldData.type,
            Array.isArray(newValue)
              ? newValue.filter((f) => f !== "")
              : newValue,
          );
        }}
        label="Choose Product Name"
      />
    );
  }

  renderText({value}) {
    return getDefaultFieldLabel(value);
  }
}

export default ProductNamesField;
