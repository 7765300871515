import React from "react";
import ReactCodeInput from "react-code-input";
import clsx from "clsx";
// UI
import {Container, Row, Col} from "reactstrap";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 900,
    marginTop: theme.spacing(5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  textfield: {
    marginBottom: theme.spacing(4),
  },
  label: {fontSize: theme.typography.fontSize},
  form: {
    width: "100%",
    maxWidth: 300,
  },
}));

export default function LoginDevicePanel({nextStep}) {
  const classes = useStyles();
  const [accountInfo, setAccountInfo] = React.useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = React.useState({
    email: false,
    password: false,
    authCode: false,
  });
  const [showAuthCode, setShowAuthCode] = React.useState(false);

  const handleChange = (e) => {
    e.persist();
    setAccountInfo((prev) => ({...prev, [e.target.id]: e.target.value}));
  };
  const changeCode = () => {};

  return (
    <div className={clsx("mx-auto", classes.root)}>
      <Container className={clsx("mt-3", classes.container)}>
        {showAuthCode ? (
          <div>
            <span className="mt-5 mb-2">
              {" "}
              A 6-digit code has been sent to your phone number. Please enter
              it.
            </span>{" "}
            <br />
            {errors.authCode && (
              <Typography color="error">Incorrect Code</Typography>
            )}
            <ReactCodeInput onChange={changeCode} fields={6} />
          </div>
        ) : (
          <form className={classes.form}>
            <Typography className={classes.label} color="textSecondary">
              Email
            </Typography>
            <TextField
              id="email"
              fullWidth
              type="text"
              placeholder="Email Address"
              value={accountInfo.email}
              onChange={handleChange}
              className={classes.textfield}
              helperText={errors.email ? "Incorrect email address" : ""}
              error={errors.email}
            />
            <Typography className={classes.label} color="textSecondary">
              Password
            </Typography>
            <TextField
              id="password"
              fullWidth
              type="password"
              placeholder="Password"
              value={accountInfo.password}
              onChange={handleChange}
              className={classes.textfield}
              helperText={errors.password ? "Incorrect password" : ""}
              error={errors.password}
            />
          </form>
        )}
        <Row className="mt-3">
          <Col md={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={nextStep}
              disabled={!accountInfo.email.trim() || !accountInfo.password}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
