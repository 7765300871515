import React from "react";
import BaseFieldComponent from "../BaseFieldComponent";
import {withStyles} from "@material-ui/core/styles";
import {PaymentStatusEnum} from "configuration/enums";
import GenericSelectionInput from "../../input/GenericSelectionInput";
import {Checkbox, ListItemText, MenuItem} from "@material-ui/core";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";

const styles = (theme) => ({
  menuItemCheckbox: {
    padding: 0,
    minWidth: 0,
  },
});

const externalOptions = PaymentStatusEnum;

class BookingPaymentStatusField extends BaseFieldComponent {
  renderInput({fieldData, handleValueChange, classes}) {
    return (
      <GenericSelectionInput
        options={Object.keys(externalOptions)}
        enableSearch={true}
        edit={true}
        value={fieldData.val}
        multiselect={true}
        onValueChange={(newValue) => {
          handleValueChange(
            fieldData.type,
            newValue.filter((f) => f !== ""),
          );
        }}
        label="Payment Status"
        customMenuItem={(option, selectedOptions) => (
          <MenuItem key={option} value={option}>
            <Checkbox
              checked={selectedOptions.includes(option)}
              className={classes.menuItemCheckbox}
            />
            <ListItemText primary={externalOptions[option]} />
          </MenuItem>
        )}
      />
    );
  }

  renderDisplay({fieldData}) {
    return <span>{externalOptions[fieldData.val]}</span>;
  }

  renderText({value}) {
    return getDefaultFieldLabel(value);
  }
}

export default withStyles(styles)(BookingPaymentStatusField);
