import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import MoreIcon from "@material-ui/icons/MoreVertOutlined";
import {IconButton} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iconBtn: {padding: theme.spacing(1)},
}));

const MoreIconButton = ({btnRef, onClick}) => {
  const classes = useStyles();

  return (
    <IconButton className={classes.iconBtn} ref={btnRef} onClick={onClick}>
      <MoreIcon fontSize="small" />
    </IconButton>
  );
};

export default MoreIconButton;
