import React from "react";
import clsx from "clsx";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles} from "@material-ui/core/styles";
import ArrowUpIcon from "@material-ui/icons/ArrowDropUp";
import {AutoSizer, Column, InfiniteLoader, Table} from "react-virtualized";
import {Typography} from "@material-ui/core";
import {THEME} from "configuration/settings";

const defaultHeaderHeight = 48;

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    "&.header-light": {
      alignItems: "flex-start",
      height: "fit-content !important",
    },
  },
  headerLabel: {
    color: "#425460",
    display: "flex",
    alignItems: "center",
    "&.light": {
      color: THEME.subdued,
      fontWeight: 400,
      textTransform: "none",
      justifyContent: "flex-end",
    },
    "&.start": {justifyContent: "flex-start"},
  },
  table: {
    "& .ReactVirtualized__Table__Grid": {
      "&::-webkit-scrollbar": {width: "0!important"},
    },
  },
  tableRow: {cursor: "pointer"},
  tableRowHover: {"&:hover": {backgroundColor: "rgba(67,84,96,0.04)"}},
  tableRowSelected: {backgroundColor: "rgba(67,84,96,0.04)"},
  tableCell: {
    flex: 1,
    padding: "4px 8px",
    overflow: "hidden",
  },
  cellBody: {
    borderBottom: "1px solid #D5E8EF",
    "&.light": {borderBottom: "1px solid rgba(13, 86, 140, 0.05)"},
    "&.light-text": {
      padding: 0,
      alignItems: "center",
      overflow: "hidden",
      flexWrap: "wrap",
      textOverflow: "ellipsis",
    },
  },
  cellHeader: {
    "&:hover .-sort-icon": {opacity: 0.5},
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    width: "100%",
    wordBreak: "break-word",
    padding: "4px 8px",
    paddingRight: 0,
    border: "none",
    "&.light": {padding: 0},
    "&.padd": {padding: theme.spacing(0, 0, 2)},
  },
  noClick: {cursor: "initial"},
  centered: {justifyContent: "center"},
  sortIcon: {
    opacity: 0,
    transition: "0.15s",
    "&.light": {opacity: 0.2},
  },
  descending: {transform: "rotate(180deg)"},
  iconActive: {opacity: "1 !important"},
  headerCellWrapper: {
    borderTop: "1px solid #C7D7DD",
    borderBottom: "1px solid #C7D7DD",
    backgroundColor: "#F6FAFB",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "&.light": {
      border: "none",
      backgroundColor: "transparent",
    },
  },
}));

function SortIndicator({active, sortDirection, light}) {
  const classes = useStyles();

  return (
    <ArrowUpIcon
      className={clsx("-sort-icon", classes.sortIcon, {
        [classes.iconActive]: active,
        [classes.descending]: active && sortDirection === "DESC",
        light: !!light,
      })}
    />
  );
}

function HeaderCell({
  columns,
  label,
  columnIndex,
  dataKey,
  sortBy,
  sortDirection,
  updateHeaderHeight,
  light,
}) {
  const classes = useStyles();
  const hideLabel = !!columns[columnIndex].hideLabel;
  const sortColumn = !!columns[columnIndex].sort;
  const headerCellRef = React.useRef();

  React.useLayoutEffect(() => {
    if (!!updateHeaderHeight && !!headerCellRef.current?.clientHeight) {
      const isFirstEl = columnIndex === 0;
      updateHeaderHeight(headerCellRef.current.clientHeight, isFirstEl);
    }
  });

  return (
    <TableCell
      component="div"
      ref={headerCellRef}
      className={clsx(classes.flexContainer, classes.cellHeader, {
        [classes.noClick]: !sortColumn,
        light: light,
        padd: light,
      })}
      variant="head"
      align={columns[columnIndex].numeric || false ? "right" : "left"}
    >
      <Typography
        variant="subtitle1"
        className={clsx(classes.headerLabel, {
          light: light,
          start: light && !columns[columnIndex].numeric,
        })}
      >
        {hideLabel ? "" : label}
        {!!sortColumn && (
          <SortIndicator
            active={sortBy === dataKey}
            sortDirection={sortDirection}
            light={light}
          />
        )}
      </Typography>
    </TableCell>
  );
}

export default function VirtualizedTable({
  columns = [],
  rowHeight = 48,
  headerHeight = "auto",
  onRowClick,
  getCustomCell,
  useInfiniteLoader,
  isRowLoaded,
  loadMoreRows,
  loaderRowCount,
  selectedGetter,
  disableAutoHeaderHeight,
  light,
  ...tableProps
}) {
  const classes = useStyles();
  const headerMaxHeight = React.useRef(
    headerHeight !== "auto" ? headerHeight : defaultHeaderHeight,
  );

  const updateMaxHeight = (newHeight, isFirstEl) => {
    if (!!isFirstEl) {
      headerMaxHeight.current = newHeight;
    } else if (headerMaxHeight.current < newHeight) {
      headerMaxHeight.current = newHeight;
    }
  };

  const cellRenderer = ({cellData, columnIndex, rowIndex}) => {
    const isNumeric = !!columns[columnIndex].numeric;
    const centered = !!columns[columnIndex].centered;
    const isCustomCell = !!columns[columnIndex].customCell;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.cellBody,
          classes.flexContainer,
          {
            [classes.noClick]: onRowClick == null,
            [classes.centered]: centered,
            light: light,
            "light-text": light && !isNumeric,
          },
        )}
        variant="body"
        style={{height: rowHeight}}
        align={(columnIndex != null && isNumeric) || false ? "right" : "left"}
      >
        {!isCustomCell
          ? cellData
          : getCustomCell(columns[columnIndex], cellData, rowIndex)}
      </TableCell>
    );
  };

  const getRowClassName = ({index}) => {
    const isSelected = !!selectedGetter ? selectedGetter(index) : false;
    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
      [classes.tableRowSelected]: isSelected,
      "header-light": light,
    });
  };

  const headerRenderer = ({
    label,
    columnIndex,
    dataKey,
    sortBy,
    sortDirection,
  }) => {
    const wrapperStyle =
      headerHeight === "auto"
        ? {minHeight: light ? "fit-content" : headerMaxHeight.current + 2}
        : {height: headerHeight};
    return (
      <div
        style={wrapperStyle}
        className={clsx(classes.headerCellWrapper, {light: light})}
      >
        <HeaderCell
          label={label}
          sortBy={sortBy}
          columns={columns}
          dataKey={dataKey}
          light={light}
          columnIndex={columnIndex}
          sortDirection={sortDirection}
          updateHeaderHeight={
            !!disableAutoHeaderHeight ? null : updateMaxHeight
          }
        />
      </div>
    );
  };

  const getTable = (onRowsRendered, registerChild) => (
    <AutoSizer>
      {({height, width}) => {
        return (
          <Table
            height={height}
            width={width}
            ref={registerChild}
            onRowsRendered={onRowsRendered}
            rowHeight={rowHeight}
            className={classes.table}
            gridStyle={{direction: "inherit"}}
            headerHeight={
              headerHeight === "auto"
                ? headerMaxHeight.current + 2
                : headerHeight
            }
            {...tableProps}
            rowClassName={getRowClassName}
            onRowClick={onRowClick}
          >
            {columns.map(({dataKey, ...other}, index) => (
              <Column
                key={dataKey}
                headerRenderer={(headerProps) =>
                  headerRenderer({
                    ...headerProps,
                    columnIndex: index,
                    dataKey: dataKey,
                    sortBy: tableProps.sortBy,
                  })
                }
                className={classes.flexContainer}
                cellRenderer={cellRenderer}
                dataKey={dataKey}
                disableSort={!columns[index].sort}
                {...other}
              />
            ))}
          </Table>
        );
      }}
    </AutoSizer>
  );

  return (
    <>
      {useInfiniteLoader ? (
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          rowCount={loaderRowCount}
        >
          {({onRowsRendered, registerChild}) =>
            getTable(onRowsRendered, registerChild)
          }
        </InfiniteLoader>
      ) : (
        getTable()
      )}
    </>
  );
}
