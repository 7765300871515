import React from "react";
import {Typography, makeStyles} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  text: {
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(3),
    opacity: 0.5,
    borderRadius: 10,
    "&.basic": {
      backgroundColor: "transparent",
      padding: 0,
      fontStyle: "italic",
    },
  },
}));

export default function EmptyContentText({basic, label = "None"}) {
  const classes = useStyles();

  return (
    <div>
      <Typography
        align={basic ? "left" : "center"}
        variant={basic ? "body1" : "h1"}
        className={clsx(classes.text, {basic: !!basic})}
      >
        {label}
      </Typography>
    </div>
  );
}
