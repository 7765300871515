import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFlags, withLDConsumer} from "launchdarkly-react-client-sdk";
// UI
import {Box, SvgIcon, Typography} from "@material-ui/core";
import {ReactComponent as KeyIcon} from "assets/icons/key.svg";
import useBookingPanelStyles from "styles/useBookingPanelStyles";
// Custom
import DeviceAccessCodeItem from "core/listItems/DeviceAccessCodeItem";
import ButtonLabelLoader from "core/loaders/ButtonLabelLoader";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import AccordionItem from "core/listItems/AccordionItem";
import PrimaryButton from "core/buttons/PrimaryButton";
// Redux
import {postAccessCode} from "redux/actions/experiencesActions";
import {getDevices} from "redux/actions/devicesActions";
// Utils
import clsx from "clsx";
import _ from "lodash";

const BookingAccessCodes = ({booking, forceOpen}) => {
  const classes = useBookingPanelStyles();
  const flags = useFlags();
  const iconRef = React.useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [visibilities, setVisibilities] = React.useState({});
  const [codeToBeRevoked, setCodeToBeRevoked] = React.useState(null);
  const devices = useSelector((state) => state.defaultReducer.devices_dict);
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const lockCodes = booking?.lock_codes ?? [];

  React.useEffect(() => {
    const newVisibilities = {};
    _.each(lockCodes, (lc) => (newVisibilities[lc.access_code] = false));
    setVisibilities((prev) => newVisibilities);
  }, [booking]);

  React.useEffect(() => {
    if (!!!!iconRef.current) {
      iconRef.current.scrollIntoView({behavior: "smooth"});
    }
    if (!!forceOpen && !expanded) {
      setExpanded((prev) => !prev);
    }
  }, [forceOpen, expanded]);

  const toggleVisibility = (accessCode) => {
    setVisibilities((prev) => ({...prev, [accessCode]: !prev[accessCode]}));
  };

  const handleRevokeRecoveryCode = () => {
    setLoading((prev) => true);
    dispatch(
      postAccessCode({
        action: {
          action_id: "delete_temporary_codes",
          code: codeToBeRevoked.access_code,
        },
        deviceID: codeToBeRevoked.device_ids?.[0],
        bookingID: booking.booking_id,
        guestID: booking.guest_id,
        onSuccess: () => {
          setCodeToBeRevoked(null);
          setLoading((prev) => false);
        },
        onError: () => {
          setLoading((prev) => false);
        },
      }),
    );
  };

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code);
  };

  const toggleExpanded = () => {
    if (!Object.keys(devices).length) {
      let params = `enso_key=${current_user}`;
      dispatch(getDevices(params));
    }
    setExpanded((prev) => !prev);
  };

  if (!lockCodes.length) {
    return null;
  }
  return (
    <>
      <ConfirmDialog
        open={codeToBeRevoked !== null}
        title="Revoke Access Code?"
        message="Any guests with this access code will lose access to this lock."
        confirmLabel={
          loading ? <ButtonLabelLoader variant="white" /> : "Revoke"
        }
        cancelLabel="Cancel"
        confirmDisabled={loading}
        confirmAction={handleRevokeRecoveryCode}
        cancelAction={() => {
          setCodeToBeRevoked(null);
        }}
        onClose={() => {
          setCodeToBeRevoked(null);
        }}
      />
      <AccordionItem
        divider
        disableHover
        infoText={
          lockCodes.length === 1
            ? "1 access code"
            : `${lockCodes.length} access codes`
        }
        expanded={expanded}
        title={"Access codes"}
        onClick={toggleExpanded}
        icon={<SvgIcon ref={iconRef} viewBox="0 0 16 16" component={KeyIcon} />}
      />
      {!!expanded && (
        <div className={classes.expandedSection}>
          {lockCodes.map((lock_code) => (
            <Box key={lock_code.access_code} p={2}>
              {!!flags.bookingPanelRedesign ? (
                <DeviceAccessCodeItem
                  deviceCode={lock_code}
                  visible={visibilities[lock_code.access_code]}
                  toggleVisibility={toggleVisibility}
                  onDelete={() => setCodeToBeRevoked(lock_code)}
                />
              ) : (
                <>
                  <Typography variant="subtitle2">
                    {lock_code.device}
                  </Typography>
                  <div className={clsx(classes.row, "-p0", "-center")}>
                    <Typography>{lock_code.access_code}</Typography>
                    <PrimaryButton
                      label="Copy"
                      color="secondary"
                      variant="text"
                      onClick={() => handleCopyCode(lock_code.access_code)}
                    />
                  </div>
                </>
              )}
            </Box>
          ))}
        </div>
      )}
    </>
  );
};

export default withLDConsumer()(BookingAccessCodes);
