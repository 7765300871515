import React from "react";
import {Button, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  tab: {
    padding: theme.spacing(2),
    borderRadius: 10,
    textTransform: "none",
    "&.selected": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  },
  label: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
  },
  count: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px 4px",
    borderRadius: 30,
    minWidth: 28,
    backgroundColor: "rgba(32, 34, 35, 0.10)",
    color: "rgba(32, 34, 35, 0.80)",
    "&.selected": {
      backgroundColor: "rgba(255, 255, 255, 0.10)",
      color: theme.palette.common.white,
    },
  },
  fw500: {fontWeight: 500},
}));

const InboxTabItem = ({
  label,
  disabled,
  selected,
  count = 0,
  hideCount,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Button
      disableRipple
      disableElevation
      disabled={disabled}
      className={clsx(classes.tab, {selected: !!selected})}
      classes={{label: classes.label}}
      onClick={onClick}
    >
      <Typography className={classes.fw500}>{label}</Typography>
      {!hideCount && !!count && (
        <div
          className={clsx(classes.count, classes.fw500, {selected: !!selected})}
        >
          {count}
        </div>
      )}
    </Button>
  );
};

export default InboxTabItem;
