import {fieldParams} from "../../../configuration/specs";
import GenericSelectionInput from "../input/GenericSelectionInput";
import {collectFieldTypeEnum} from "../../../configuration/enums";
import React from "react";
import NumberField from "../../../components/TextFields/NumberField";
import FilledTextField from "../../../core/inputs/FilledTextField";
import DateComponent from "../../../components/TextFields/Date";
import ElapsedTime from "../../../components/TextFields/ElapsedTime";

import {customComponentDisplayType} from "../field/BaseFieldComponent";
import GenericSwitchInput from "../input/GenericSwitchInput";

/**
 * @author Chloe Hill
 * @returns {JSX.Element}
 * @description Turns standardized input types to a generic one which can be used by custom condition.
 */
export default function ConditionInputRenderer({
  fieldData, // this is info
  customFieldData,
  optionData,
  handleValueChange,
  componentMap,
  operatorType,
}) {
  let field_type = fieldParams[fieldData.field]?.field_type || operatorType;

  switch (field_type) {
    case collectFieldTypeEnum.multi_select:
    case collectFieldTypeEnum.single_select:
    case "array":
    case "keyword":
      return (
        <GenericSelectionInput
          options={optionData}
          enableSearch={true}
          edit={true}
          value={fieldData.val}
          multiselect={field_type === collectFieldTypeEnum.multi_select}
          onValueChange={(newValue) => {
            handleValueChange(
              fieldData.type,
              newValue.filter((f) => f !== ""),
            );
          }}
          label="Choose Options"
        />
      );
    case collectFieldTypeEnum.checkbox:
    case "boolean":
      return (
        <GenericSwitchInput
          value={fieldData.val}
          edit={true}
          onValueChange={(e) => {
            handleValueChange(fieldData.type, e);
          }}
        />
      );
    case collectFieldTypeEnum.number:
    case "number":
      return (
        <NumberField
          edit={true}
          value={fieldData.val}
          onValueChange={(newValue) => {
            handleValueChange(fieldData.type, newValue);
          }}
        />
      );
    case collectFieldTypeEnum.time:
    case "time":
      return (
        <DateComponent
          date={new Date()}
          showInfoText
          time
          edit={true}
          value={fieldData.val}
          onValueChange={(newValue) => {
            handleValueChange(fieldData.type, newValue);
          }}
        />
      );
    case collectFieldTypeEnum.date:
    case "date":
      return (
        <DateComponent
          date={new Date()}
          showInfoText
          value={fieldData.val}
          edit={true}
          onValueChange={(newValue) => {
            handleValueChange(fieldData.type, newValue);
          }}
        />
      );
    case collectFieldTypeEnum.time_offset:
      return (
        <ElapsedTime
          value={fieldData.val}
          onValueChange={(newValue) => {
            handleValueChange(fieldData.type, newValue);
          }}
          edit
        />
      );
    case collectFieldTypeEnum.custom:
      const customComponentName =
        fieldParams[fieldData.field]?.custom_component;
      if (!customComponentName) break;
      const CustomComponent = componentMap[customComponentName];
      if (!CustomComponent) break;

      return (
        <CustomComponent
          fieldData={fieldData}
          options={optionData}
          customFieldData={customFieldData}
          displayType={customComponentDisplayType.input}
          handleValueChange={(type, newValue) => {
            handleValueChange(type, newValue);
          }}
        />
      );
    case collectFieldTypeEnum.text:
    case "text":
    default:
      return (
        <FilledTextField
          fullWidth
          label="Value"
          value={fieldData.val}
          type={"text"}
          placeholder={"Enter value"}
          onChange={(newValue) => {
            handleValueChange(fieldData.type, newValue.target.value);
          }}
        />
      );
  }
}
