import * as types from "../actionTypes";
import * as API from "../api/dashboardAPI";
import qs from "qs";
// Mockdata
import dashboardDataSummary from "assets/mockdata/dashboard_summary.json";
import dashboardDataRevenue from "assets/mockdata/dashboard_revenue_stats.json";
import dashboardDataBP from "assets/mockdata/dashboard_bp_stats.json";
import dashboardDataGuestSatisfaction from "assets/mockdata/dashboard_guest_stats.json";
import dashboardDataBookings from "assets/mockdata/dashboard_booking_stats.json";

export function setDashboardData(section, data) {
  return {type: types.SET_DASHBOARD_DATA, section, data};
}

export function triggerDashboardRefresh(value) {
  return {type: types.TRIGGER_DASHBOARD_REFRESH, value};
}

export function getDashboardSummary({
  page = null,
  limit = null,
  start_date = null,
  end_date = null,
  listing_group_ids = null,
  pending_action = null,
  bucket = null,
  isUpcoming,
  disableUpdate,
  onSuccess = () => {},
  onError = () => {},
}) {
  return async (dispatch, getState) => {
    let currSummaryData = getState().dashboardReducer.summaryData;
    try {
      let params = qs.stringify(
        {
          page,
          limit,
          start_date,
          end_date,
          listing_group_ids,
          pending_action,
          bucket,
        },
        {skipNulls: true, arrayFormat: "comma"},
      );
      const response = await API.getSummary(params);
      let newData = {...currSummaryData};
      if (!disableUpdate) {
        if (isUpcoming) {
          if (!!page && page > 1) {
            newData.upcoming = {
              ...response,
              bookings: [
                ...(currSummaryData.upcoming?.bookings || []),
                ...response.bookings,
              ],
            };
          } else {
            newData.upcoming = response;
          }
        } else {
          if (!!page && page > 1) {
            newData.today = {
              ...response,
              bookings: [
                ...(currSummaryData.today?.bookings || []),
                ...response.bookings,
              ],
            };
          } else {
            newData.today = response;
          }
        }
        dispatch(setDashboardData("summaryData", newData));
      }
      onSuccess(response);
    } catch (error) {
      console.log("ERROR - GET DASHBOARD SUMMARY", error);
      onError();
    }
  };
}

export function getDashboardRevenue({
  start_date = null,
  end_date = null,
  listing_group_ids = null,
  display_currency = null,
  stat = "product_name",
  onSuccess = () => {},
  onError = () => {},
}) {
  return async (dispatch) => {
    try {
      let params = qs.stringify(
        {
          stat,
          start_date,
          end_date,
          listing_group_ids,
          display_currency,
        },
        {skipNulls: true, arrayFormat: "comma"},
      );
      const response = await API.getRevenueStats(params);
      dispatch(setDashboardData("revenueData", response));
      onSuccess(response);
    } catch (error) {
      console.log("ERROR - GET DASHBOARD REVENUE STATS", error);
      onError();
    }
  };
}

export function getDashboardBPStats({
  start_date = null,
  end_date = null,
  listing_group_ids = null,
  onSuccess = () => {},
  onError = () => {},
}) {
  return async (dispatch) => {
    try {
      let params = qs.stringify(
        {
          start_date,
          end_date,
          listing_group_ids,
        },
        {skipNulls: true, arrayFormat: "comma"},
      );
      const response = await API.getBoardingPassStats(params);
      dispatch(setDashboardData("bpData", response));
      onSuccess(response);
    } catch (error) {
      console.log("ERROR - GET DASHBOARD BP STATS", error);
      onError();
    }
  };
}

export function getDashboardGuestSatisfaction({
  start_date = null,
  end_date = null,
  listing_group_ids = null,
  onSuccess = () => {},
  onError = () => {},
}) {
  return async (dispatch) => {
    try {
      let params = qs.stringify(
        {
          start_date,
          end_date,
          listing_group_ids,
        },
        {skipNulls: true, arrayFormat: "comma"},
      );
      const response = await API.getGuestStats(params);
      dispatch(setDashboardData("guestSatisfactionData", response));
      onSuccess(response);
    } catch (error) {
      console.log("ERROR - GET DASHBOARD GUEST STATS", error);
      onError();
    }
  };
}

export function getDashboardBookingStats({
  start_date = null,
  end_date = null,
  listing_group_ids = null,
  stat = null,
  onSuccess = () => {},
  onError = () => {},
}) {
  return async (dispatch) => {
    try {
      let params = qs.stringify(
        {
          start_date,
          end_date,
          listing_group_ids,
          stat,
        },
        {skipNulls: true, arrayFormat: "comma"},
      );
      const response = await API.getBookingStats(params);
      dispatch(setDashboardData("bookingsData", response));
      onSuccess(response);
    } catch (error) {
      console.log("ERROR - GET DASHBOARD BOOKING STATS", error);
      onError();
    }
  };
}

export function getDashboardMessageStats({
  start_date = null,
  end_date = null,
  listing_group_ids = null,
  stat = null,
  onSuccess = () => {},
  onError = () => {},
}) {
  return async (dispatch) => {
    try {
      let params = qs.stringify(
        {
          start_date,
          end_date,
          listing_group_ids,
          stat,
        },
        {skipNulls: true, arrayFormat: "comma"},
      );
      const response = await API.getMessageStats(params);
      dispatch(setDashboardData("messageData", response));
      onSuccess(response);
    } catch (error) {
      console.log("ERROR - GET DASHBOARD MESSAGE STATS", error);
      onError();
    }
  };
}

export function getDashboardMessageAnalytics({
  start_date = null,
  end_date = null,
  listing_group_ids = null,
  stat = null,
  onSuccess = () => {},
  onError = () => {},
}) {
  return async (dispatch) => {
    try {
      let params = qs.stringify(
        {
          start_date,
          end_date,
          listing_group_ids,
          stat,
        },
        {skipNulls: true, arrayFormat: "comma"},
      );
      const response = await API.getMessageAnalytics(params);
      dispatch(setDashboardData("messageAnalytics", response));
      onSuccess(response);
    } catch (error) {
      console.log("ERROR - GET DASHBOARD MESSAGE ANALYTICS", error);
      onError();
    }
  };
}
