import React from "react";
// UI
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
// Custom
import MultiButtonBar from "core/buttons/MultiButtonBar";
// Utils
import {compareTexts} from "utilities/formatUtilities";
import {capitalize} from "utilities/helperFunctions";
import {triggerParams} from "configuration/specs";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  notificationsContainer: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    border: "1px solid rgba(229, 229, 234, 0.75)",
    borderRadius: 10,
  },
  triggerList: {
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    gap: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  formControl: {
    flexGrow: 1,
    flexBasis: "100%",
  },
  labelContainer: {padding: 0},
  tabs: {minHeight: 30},
  tab: {
    minWidth: 0,
    minHeight: 30,
    padding: 0,
    marginRight: theme.spacing(4),
  },
  strong: {
    color: "#000",
    fontWeight: 600,
  },
  checkbox: {padding: theme.spacing(2)},
  controlLabel: {
    marginBottom: 0,
    alignItems: "flex-start",
    "& svg": {
      width: 16,
      height: 16,
    },
  },
  checkboxLabel: {paddingTop: 6},
}));

const NOTIFICATION_TYPES = {
  essential: "Essential",
  custom: "Custom",
  none: "None",
};

const ESSENTIAL_TRIGGERS = {
  sms_notifications: ["pr_pndg"],
  email_notifications: ["pr_pndg"],
  mobile_notifications: [
    "pr_pndg",
    "msg",
    "pr_cfmrd",
    "pr_cncld",
    "pr_refd",
    "dep_clct",
    "ver",
    "re_ver",
  ],
};

export default function NotificationSettingsPanel({
  user,
  userNotifs,
  triggerList,
  setTriggerList,
}) {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = React.useState("sms_notifications");
  const [notificationType, setNotificationType] = React.useState({
    sms_notifications: "essential",
    email_notifications: "essential",
    mobile_notifications: "essential",
  });
  const [temporaryCustomTriggers, setTemporaryCustomTriggers] = React.useState({
    sms_notifications: null,
    email_notifications: null,
    mobile_notifications: null,
  });
  const triggerTypes = React.useMemo(
    () => Object.keys(triggerParams),
    [triggerParams],
  );

  React.useEffect(() => {
    const smsNotif = triggerList.sms_notifications;
    const emailNotif = triggerList.email_notifications;
    const mobileNotif = triggerList.mobile_notifications;
    const newNotifTypes = {
      sms_notifications: "",
      email_notifications: "",
      mobile_notifications: "",
    };

    // SMS
    if (!smsNotif.length) {
      newNotifTypes.sms_notifications = "none";
    } else if (
      _.isEqual(
        ESSENTIAL_TRIGGERS.sms_notifications.sort(compareTexts),
        smsNotif.sort(compareTexts),
      )
    ) {
      newNotifTypes.sms_notifications = "essential";
    } else {
      newNotifTypes.sms_notifications = "custom";
    }

    // EMAIL
    if (!emailNotif.length) {
      newNotifTypes.email_notifications = "none";
    } else if (
      _.isEqual(
        ESSENTIAL_TRIGGERS.email_notifications.sort(compareTexts),
        emailNotif.sort(compareTexts),
      )
    ) {
      newNotifTypes.email_notifications = "essential";
    } else {
      newNotifTypes.email_notifications = "custom";
    }

    // MOBILE
    if (!mobileNotif.length) {
      newNotifTypes.mobile_notifications = "none";
    } else if (
      _.isEqual(
        ESSENTIAL_TRIGGERS.mobile_notifications.sort(compareTexts),
        mobileNotif.sort(compareTexts),
      )
    ) {
      newNotifTypes.mobile_notifications = "essential";
    } else {
      newNotifTypes.mobile_notifications = "custom";
    }

    setNotificationType((prev) => newNotifTypes);
  }, [userNotifs]);

  const handleNotificationTypeChange = (newOption) => {
    if (newOption === "essential") {
      if (notificationType[selectedTab] === "custom") {
        // Current option selected
        setTemporaryCustomTriggers((prev) => ({
          ...prev,
          [selectedTab]: triggerList[selectedTab],
        }));
      }
      setTriggerList({
        ...triggerList,
        [selectedTab]: ESSENTIAL_TRIGGERS[selectedTab],
      });
    } else if (newOption === "custom") {
      setTriggerList({
        ...triggerList,
        [selectedTab]: temporaryCustomTriggers[selectedTab] ?? [],
      });
    }
    setNotificationType((prev) => ({...prev, [selectedTab]: newOption}));
  };

  const handleTabChange = (e, newValue) => {
    setSelectedTab((prev) => newValue);
  };

  const toggleTrigger = (e) => {
    const name = e.target.name;
    const val = e.target.checked;
    if (!val) {
      setTriggerList({
        ...triggerList,
        [selectedTab]: triggerList[selectedTab].filter((t) => t !== name),
      });
    } else {
      setTriggerList({
        ...triggerList,
        [selectedTab]: triggerList[selectedTab].concat(name),
      });
    }
  };

  const getText = () => {
    switch (notificationType[selectedTab]) {
      case "essential":
        return (
          <Box mt={2}>
            <Typography color="textPrimary">
              You will receive notifications about
              <strong className={classes.strong}>{" upsell requests"}</strong>,
              <strong className={classes.strong}>
                {" pending verifications"}
              </strong>{" "}
              &
              <strong className={classes.strong}>
                {" other important notifications"}
              </strong>
              .
            </Typography>
          </Box>
        );
      case "custom":
        return (
          <Box mt={2}>
            <Typography color="textPrimary">
              {"You will receive notifications for:"}
            </Typography>
          </Box>
        );
      case "none":
        return (
          <Box mt={2}>
            <Typography color="textPrimary">
              {"You will not receive any notifications."}
            </Typography>
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="secondary"
        variant="scrollable"
        scrollButtons="off"
        classes={{root: classes.tabs}}
      >
        <Tab
          value="sms_notifications"
          label="SMS"
          classes={{root: classes.tab, wrapper: classes.labelContainer}}
        />
        <Tab
          value="email_notifications"
          label="EMAIL"
          classes={{root: classes.tab, wrapper: classes.labelContainer}}
        />
        <Tab
          value="mobile_notifications"
          label="MOBILE"
          classes={{root: classes.tab, wrapper: classes.labelContainer}}
        />
      </Tabs>
      <div className={classes.notificationsContainer}>
        <MultiButtonBar
          thin
          dividers
          coloredBG
          value={notificationType[selectedTab]}
          options={Object.keys(NOTIFICATION_TYPES)}
          labels={Object.values(NOTIFICATION_TYPES)}
          onChange={handleNotificationTypeChange}
          id="notification-type"
        />
        {getText()}
        {notificationType[selectedTab] === "custom" && (
          <div className={classes.triggerList}>
            {triggerTypes.map((triggerType) => (
              <FormControl
                key={triggerType}
                component="fieldset"
                className={classes.formControl}
              >
                <FormLabel component="legend">
                  {capitalize(triggerType)}
                </FormLabel>
                <FormGroup>
                  {triggerParams[triggerType].map(
                    (trigger) =>
                      !!trigger.enable_user_notification && (
                        <FormControlLabel
                          key={trigger.trigger}
                          label={`When ${triggerType} ${trigger.text ?? "triggers an event"}`}
                          classes={{
                            root: classes.controlLabel,
                            label: classes.checkboxLabel,
                          }}
                          control={
                            <Checkbox
                              checked={
                                !!triggerList[selectedTab].includes(
                                  trigger.trigger,
                                )
                              }
                              className={classes.checkbox}
                              onChange={toggleTrigger}
                              name={trigger.trigger}
                            />
                          }
                        />
                      ),
                  )}
                </FormGroup>
              </FormControl>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
