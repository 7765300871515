import {makeStyles} from "@material-ui/core/styles";
import BGImage from "assets/img/CTAsBackground.jpg";
import {THEME} from "configuration/settings";

const useCTAPanelStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    height: "100vh",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: theme.spacing(4),
  },
  card: {
    width: "80%",
    maxWidth: 1100,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
    padding: theme.spacing(4),
    overflow: "auto",
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  cardContainer: {
    boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.05)",
    background: `linear-gradient(180deg, #2A6EBB 0%, rgba(0, 122, 255, 0.80) 100%), url(${BGImage}), lightgray`,
    backgroundPosition: "50%",
    display: "flex",
    flexDirection: "column",
    backgroundSize: "cover",
    borderRadius: 10,
    gap: theme.spacing(4),
    padding: theme.spacing(4, 5),
    backgroundRepeat: "no-repeat",
  },
  featureContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
  },
  col: {
    display: "flex",
    flexDirection: "column",
    "&.flex": {flex: 1},
  },
  row: {
    display: "flex",
    flexDirection: "row",
    "&.centered": {alignItems: "center"},
    "&.space": {
      gap: theme.spacing(4),
      flexWrap: "wrap",
    },
  },
  icon: {
    width: 30,
    height: 30,
    marginBottom: 6,
    color: "rgba(17, 20, 33, 0.25)",
    "&.checked": {color: THEME.mutedGreen},
  },
  media: {
    borderRadius: "5px 5px 0 0",
    backgroundColor: "rgba(155, 160, 176, 0.10)",
    "&.large": {
      borderRadius: 5,
      //opacity: 0.7,
    },
  },
  imageBtn: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  largeImageContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    borderRadius: 5,
    flex: 1,
    minWidth: 285,
  },
  cardItem: {
    boxShadow: "none",
    position: "relative",
    border: "none",
    minWidth: 190,
    flex: 1,
  },
  largeCard: {
    boxShadow: "none",
    display: "flex",
    padding: theme.spacing(4),
    flexWrap: "wrap",
    gap: theme.spacing(3),
    overflow: "visible",
  },
  cardTitle: {
    fontSize: 26,
    fontWeight: 600,
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  details: {
    alignItems: "flex-start",
    justifyContent: "center",
    flex: 1,
  },
  cardActions: {
    flex: 1,
    alignItems: "flex-end",
  },
  cardInfoRow: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    justifyContent: "space-between",
    padding: theme.spacing(3),
  },
}));

export default useCTAPanelStyles;
