import React from "react";
import {FormControl, IconButton, InputLabel, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PrimaryButton from "core/buttons/PrimaryButton";
import {THEME} from "configuration/settings.js";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > label": {
      color: "rgba(0, 0, 0, 0.6)",
      "&.Mui-focused": {color: "rgba(0, 0, 0, 0.6)"},
    },
  },
  fullHeight: {height: "100%"},
  select: {
    backgroundColor: `${THEME.inputBg}!important`,
    borderRadius: 5,
    "&.disabled": {backgroundColor: "rgba(0,0,0,0.06) !important"},
  },
  noLabel: {padding: theme.spacing(3)},
  filled: {
    backgroundColor: "transparent!important",
    textWrap: "wrap",
    paddingRight: (props) =>
      `${props.fixedPR ?? theme.spacing(4)}px !important`,
    "&.disabled": {cursor: "not-allowed"},
  },
  small: {
    paddingTop: 8,
    paddingBottom: 7,
  },
  iconBtn: {
    padding: theme.spacing(0, 2, 2, 0),
    borderRadius: 0,
    alignSelf: "stretch",
    alignItems: "flex-end",
    "&.disabled": {cursor: "not-allowed !important"},
  },
  icon: {color: "rgba(60, 60, 67, 0.301961)"},
  connectButton: {marginRight: theme.spacing(2)},
}));

function FilledSelect(props) {
  const {
    children,
    label,
    small,
    fullWidth,
    fullHeight,
    className,
    fixedPR,
    disabled,
    value,
    useConnectButton,
    onConnectClick,
    renderValue,
    multiple,
    input,
    ...other
  } = props;
  const classes = useStyles({fixedPR});
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen((prev) => true);
  };
  const handleClose = () => {
    setOpen((prev) => false);
  };

  return (
    <FormControl
      variant="filled"
      fullWidth={fullWidth}
      disabled={disabled}
      className={clsx(classes.root, {[classes.fullHeight]: !!fullHeight})}
    >
      <InputLabel id={`${label}-select-label`} shrink={true}>
        {label}
      </InputLabel>
      <Select
        labelId={`${label}-select-label`}
        id={`${label}-select`}
        disableUnderline
        open={open}
        value={value}
        onOpen={handleOpen}
        onClose={handleClose}
        renderValue={renderValue}
        multiple={multiple}
        input={input}
        IconComponent={() => {
          return useConnectButton && !value ? (
            <PrimaryButton
              variant="outlined"
              color="secondary"
              label="Connect"
              className={classes.connectButton}
              onClick={() =>
                !!onConnectClick ? onConnectClick() : handleOpen()
              }
            />
          ) : (
            <IconButton
              disabled={disabled}
              className={clsx(classes.iconBtn, {disabled: !!disabled})}
              onClick={handleOpen}
            >
              <ExpandMoreIcon className={classes.icon} />
            </IconButton>
          );
        }}
        className={clsx(classes.select, {disabled: !!disabled}, className)}
        classes={{
          root: clsx({[classes.noLabel]: !label}),
          filled: clsx(classes.filled, {
            [classes.small]: !!small,
            disabled: !!disabled,
          }),
        }}
        {...other}
      >
        {children}
      </Select>
    </FormControl>
  );
}

export default FilledSelect;
