import React from "react";
// UI
import {MenuItem} from "@material-ui/core";
import useExperienceActionsStyles from "styles/useExperienceActionsStyles";
// Custom
import FilledSelect from "core/selects/FilledSelect";
// Utils
import {deviceTriggerLabels} from "configuration/constants";
import {deviceTriggerEnum} from "configuration/enums";

const options = Object.values(deviceTriggerEnum);

export default function DeviceTriggerSelector({value, onChange}) {
  const classes = useExperienceActionsStyles();

  React.useEffect(() => {
    if (!!value.key && !value.label) {
      onChange({key: value.key, label: deviceTriggerLabels[value.key]});
    }
  }, [value]);

  const handleChange = (e) => {
    const val = e.target.value;
    onChange({key: val, label: deviceTriggerLabels[val]});
  };

  return (
    <FilledSelect
      fullWidth
      value={value.key}
      onChange={handleChange}
      className={classes.inlineInput}
      title={deviceTriggerLabels[value.key]}
    >
      <MenuItem disabled value="">
        Select a device trigger
      </MenuItem>
      {options.map((opt) => (
        <MenuItem
          key={opt}
          value={opt}
          selected={value.key === opt}
          classes={{selected: classes.selected}}
        >
          {deviceTriggerLabels[opt]}
        </MenuItem>
      ))}
    </FilledSelect>
  );
}
