import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

const consoleAAPIKey = "9RFz1mc0UnsE4YFIkndl2w";
const keycardAPIKey = "aOOUJbGAfNd8XS0klnN_vQ";
const consoleLoadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${consoleAAPIKey}`;
const keycardLoadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${keycardAPIKey}`;

export const consoleI18next = i18next.createInstance();
export const keycardI18next = i18next.createInstance();

consoleI18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",

    ns: ["default"],
    defaultNS: "default",

    supportedLngs: ["en"],

    backend: {
      loadPath: consoleLoadPath,
    },
  });

keycardI18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",

    ns: ["default"],
    defaultNS: "default",

    supportedLngs: ["en", "es", "fr", "de", "sv", "it", "pl"],

    backend: {
      loadPath: keycardLoadPath,
    },
  });
