import React, {createContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  useHistory,
  useRouteMatch,
  useLocation,
  useParams,
} from "react-router-dom";
import {withLDConsumer} from "launchdarkly-react-client-sdk";
import {useTranslation} from "react-i18next";
// UI
import {Box, Button, isWidthUp, makeStyles, withWidth} from "@material-ui/core";
import "../styles/scrollbar.css";
// Custom
import BookingInfoDrawer from "components/Panels/Booking/BookingInfoDrawer";
import {EmptyInboxes} from "components/Helpers/EmptyPanels";
import MessagesPanel from "components/Panels/MessagesPanel";
import InboxTabItem from "components/Misc/InboxTabItem";
import GuestList from "components/Lists/GuestList";
import ThreePanel from "core/panels/ThreePanel.jsx";
import NextButton from "core/buttons/NextButton";
import BackButton from "core/buttons/BackButton";
// Actions
import {
  archiveGuests,
  getGuest,
  getGuests,
  setGuests,
  updateGuest,
} from "redux/actions/guestsActions";
import {setStartKeys, setTotals} from "redux/actions/settingsActions";
// Utils
import {getEncodedFilters, sleep} from "utilities/helperFunctions";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  mobileContainer: {
    flex: 1,
    width: "100%",
    backgroundColor: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    position: "relative",
  },
  headerContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 3,
    backgroundColor: "#FFFFFF",
  },
  headerButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme.spacing(2, 3),
  },
  inboxesContainer: {
    padding: theme.spacing(2),
    borderBottom: "1px solid #F0F0F0",
    borderRight: "1px solid #F0F0F0",
    background: "#FAFAFA",
    borderTopLeftRadius: 10,
    justifyContent: "space-between",
    overflowX: "auto",
    overflowY: "hidden",
    "&::-webkit-scrollbar": {height: "0px!important"},
    "&.tabletView": {
      boxShadow: "2px 0px 8px -3px rgba(28,41,90,0.101961)",
      borderRight: "none",
      borderTopRightRadius: 10,
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
  },
  archiveBtn: {
    textTransform: "none",
    padding: theme.spacing(2),
    color: THEME.subdued,
    borderRadius: 10,
    "&.selected": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  },
}));

const defaultContext = {
  currentInbox: null,
  modifiedData: {
    forceInboxesUpdate: false,
    addToInbox: null,
    guest: null,
    subtractFromInbox: null,
  },
  mobileData: {
    data: [],
    searchText: "",
    filters: [],
    totalData: 0,
    hasNextPage: false,
  },
  setMobileData: () => {},
  setModifiedData: () => {},
};

export const MessagesContext = createContext(defaultContext);

function Messages({width, flags}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {guest_id} = useParams();
  const {t} = useTranslation();
  const mobileChatMatch = useRouteMatch("/admin/messages/chat/:guest_id");
  const mobileProfileMatch = useRouteMatch("/admin/messages/profile/:guest_id");
  const match = useRouteMatch("/admin/messages/:guest_id");
  const didLoadGuest = React.useRef(false);
  const currentUser = useSelector((state) => state.defaultReducer.current_user);
  const messages = useSelector((state) => state.messagesReducer.messages);
  const guests = useSelector((state) => state.defaultReducer.guests_dict);
  const deviceType = useSelector((state) => state.defaultReducer.deviceType);
  const start_keys = useSelector((state) => state.defaultReducer.start_keys);
  const inboxes = useSelector((state) => state.defaultReducer.inbox_data);
  const [view, setView] = React.useState(null);
  const [detailsPanel, setDetailsPanel] = React.useState(false);
  const [loadingPanel2, setLoadingPanel2] = React.useState(false);
  const [loadingPanel3, setLoadingPanel3] = React.useState(false);
  const [initialGuestMsgs, setInitialGuestMsgs] = React.useState([]);
  const [tab, setTab] = React.useState("all");
  const [refreshing, setRefreshing] = React.useState(false);
  const [loadingInbox, setLoadingInbox] = React.useState(false);
  const [mobileData, setMobileData] = React.useState({
    data: [],
    searchText: "",
    filters: [],
    totalData: 0,
    hasNextPage: false,
  });
  const [modifiedData, setModifiedData] = React.useState({
    addToInbox: null,
    guest: null,
    subtractFromInbox: null,
    forceInboxesUpdate: false,
  });
  const availableInboxes = React.useMemo(() => {
    return Object.keys(inboxes).filter(
      (k) => k !== "archived" && !flags.inboxes.includes(k),
    );
  }, [inboxes, flags]);
  const isTabletView = deviceType === "tablet";
  const isMobileView = deviceType === "mobile";
  let paramsGuestId =
    mobileChatMatch?.params?.guest_id ??
    mobileProfileMatch?.params?.guest_id ??
    guest_id;
  let guest = guests[paramsGuestId];

  React.useEffect(() => {
    if (!!isMobileView && !view) {
      setView("panel1");
    }
  }, [isMobileView]);
  React.useEffect(() => {
    if (!!match?.isExact) {
      setView("panel2");
    }
  }, []);

  React.useEffect(() => {
    if (
      !isWidthUp("md", width) &&
      !!mobileChatMatch?.isExact &&
      !didLoadGuest.current
    ) {
      didLoadGuest.current = "loading";
      dispatch(
        getGuest(
          paramsGuestId,
          () => {
            didLoadGuest.current = true;
          },
          () => {
            didLoadGuest.current = true;
          },
        ),
      );
    }
  }, [paramsGuestId, guests]);

  const openProfile = () => {
    setView((prev) => "panel3");
  };

  const closeProfile = () => {
    setView((prev) => "panel2");
  };

  const goToChat = (guestId) => {
    const isEmpty = !messages[guestId]?.messages;
    if (isMobileView) {
      setInitialGuestMsgs((prev) =>
        isEmpty ? [] : messages[guestId]?.messages,
      );
    }
    setLoadingPanel2((prev) => isMobileView && isEmpty);
    setLoadingPanel3((prev) => isMobileView && isEmpty);
    setView((prev) => "panel2");
    if (!isWidthUp("md", width)) {
      history.push(`/admin/messages/chat/${guestId}`);
    } else {
      history.replace(`/admin/messages/${guestId}`);
    }
  };

  const goToMessages = () => {
    sleep(THEME.transitions.mobileScreens + 200).then(() => {
      if (isMobileView) {
        setInitialGuestMsgs((prev) => []);
      }
    });
    setView((prev) => "panel1");
    history.replace(`/admin/messages`);
  };

  const setLoading = (panel, value) => {
    if (!view || view === "panel1") {
      return;
    } else if (panel === 2) {
      setLoadingPanel2((prev) => value);
    } else if (panel === 3) {
      setLoadingPanel3((prev) => value);
    }
  };

  const openDetails = () => {
    history.push(`/admin/messages/profile/${paramsGuestId}`);
  };
  const closeDetails = () => {
    history.replace(`/admin/messages/chat/${paramsGuestId}`);
  };
  const closeChat = () => {
    history.replace("/admin/messages");
  };

  const getArchivedTmstmp = () => {
    const now = new Date();
    now.setDate(now.getDate() - 14);
    now.setHours(0, 0, 0, 0);
    return now.getTime();
  };

  const handleArchive = (guestId) => {
    if (!!guestId) {
      let newTags = guests[guestId].tags || [];
      if (!newTags.includes("archived")) {
        newTags.push("archived");
      }
      dispatch(
        updateGuest({
          guest_id: guestId,
          field: "tags",
          val: newTags,
          disableAlert: true,
        }),
      );
    } else {
      let params = `enso_key=${currentUser}`;
      let tmstmp = getArchivedTmstmp();
      params += `&filters=${getEncodedFilters([{path: "guest_updated_at", value: tmstmp, operator: ">"}])}`;
      dispatch(
        getGuests({
          params,
          skipLocalUpdate: true,
          onSuccess: (response) => {
            let newHits = response?.hits || [response];
            let filteredGuests = newHits.filter(
              (g) => !!g.unanswered_messages || !!g.unread_messages,
            );
            let total = filteredGuests.length ?? "";
            dispatch(setGuests(filteredGuests));
            dispatch(setTotals("guests", total));
            dispatch(setStartKeys("guests", response.start_key));
          },
        }),
      );
      dispatch(archiveGuests({}));
    }
  };

  const handleViewArchived = () => {
    handleTabChange("archived");
  };

  const handleTabChange = (newTab) => {
    if (newTab !== "all" && tab !== newTab) {
      setLoadingInbox((prev) => true);
    }
    setTab(newTab);
  };

  const getTabs = (readOnly) => {
    return (
      <Box
        className={clsx(classes.row, classes.inboxesContainer, {
          tabletView: isTabletView,
        })}
      >
        <Box className={classes.row}>
          {!availableInboxes.length ? (
            <EmptyInboxes />
          ) : (
            availableInboxes.map((ik) => (
              <InboxTabItem
                key={ik}
                disabled={readOnly}
                selected={tab === ik}
                count={inboxes[ik]?.total_count ?? 0}
                label={inboxes[ik]?.label}
                hideCount={ik === "all"}
                onClick={() => handleTabChange(ik)}
              />
            ))
          )}
        </Box>
        <Button
          disableRipple
          variant="text"
          onClick={handleViewArchived}
          className={clsx(classes.archiveBtn, {selected: tab === "archived"})}
        >
          {t("Archived")}
        </Button>
      </Box>
    );
  };

  const panelActions = (
    <NextButton
      secondary
      transparent
      goBack={openProfile}
      label={`${guest?.name ?? "Guest"} Profile`}
    />
  );

  function getMobileView() {
    if (location.pathname.includes("/profile")) {
      return (
        <BookingInfoDrawer
          hideRedirectIcon
          extraTopPadding
          guestId={paramsGuestId}
          loadingAnimations={loadingPanel3}
          setLoadingAnimations={(val) => setLoading(3, val)}
        />
      );
    } else if (location.pathname.includes("/chat")) {
      return (
        <MessagesPanel
          guestId={paramsGuestId}
          initialGuestMsgs={messages[paramsGuestId]?.messages || []}
          loadingAnimations={loadingPanel2}
          setLoadingAnimations={(val) => setLoading(2, val)}
        />
      );
    } else {
      return (
        <GuestList
          isMobile
          tab={tab}
          refreshing={refreshing}
          loadingInbox={loadingInbox}
          setTab={handleTabChange}
          archiveChats={handleArchive}
          setLoadingInbox={setLoadingInbox}
          setRefreshing={setRefreshing}
          onGuestClick={goToChat}
        />
      );
    }
  }

  let contextValues = {
    modifiedData,
    currentInbox: tab,
    mobileData,
    setModifiedData,
    setMobileData,
  };
  if (isWidthUp("sm", width)) {
    // Desktop
    return (
      <MessagesContext.Provider value={contextValues}>
        <ThreePanel
          panel1={
            <GuestList
              tab={tab}
              refreshing={refreshing}
              loadingInbox={loadingInbox}
              setTab={handleTabChange}
              archiveChats={handleArchive}
              setLoadingInbox={setLoadingInbox}
              setRefreshing={setRefreshing}
              onGuestClick={goToChat}
            />
          }
          panel2={
            <MessagesPanel
              initialGuestMsgs={initialGuestMsgs}
              loadingAnimations={loadingPanel2}
              setLoadingAnimations={(val) => setLoading(2, val)}
            />
          }
          panel3={
            <BookingInfoDrawer
              hideRedirectIcon
              loadingAnimations={loadingPanel3}
              setLoadingAnimations={(val) => setLoading(3, val)}
              setDetailsPanel={setDetailsPanel}
            />
          }
          inboxTabs={getTabs(start_keys.guests === null || refreshing)}
          mobileActiveView={view}
          backLabel={[null, "Messages", "Chat"]}
          goBack={[null, goToMessages, closeProfile]}
          actions={[null, panelActions, null]}
          hideSubnavControls={[true, false, !!detailsPanel]}
          bg={["#FAFAFA", "#FAFAFA", "#FFF"]}
        />
      </MessagesContext.Provider>
    );
  } else {
    // Mobile
    return (
      <MessagesContext.Provider value={contextValues}>
        <Box
          className={clsx(classes.mobileContainer, {
            guestDetails: location.pathname.includes("profile"),
          })}
        >
          <Box className={classes.headerContainer}>
            {location.pathname.includes("/chat") && (
              <Box className={classes.headerButtons}>
                <BackButton goBack={closeChat} label={"Messages"} />
                <NextButton
                  secondary
                  transparent
                  goBack={openDetails}
                  label={`${guests[paramsGuestId]?.name ?? "Guest"} Profile`}
                />
              </Box>
            )}
            {location.pathname.includes("/profile") && (
              <Box px={3} py={2}>
                <BackButton
                  header
                  goBack={closeDetails}
                  label={"Chat"}
                  icon={"back"}
                />
              </Box>
            )}
          </Box>
          {getMobileView()}
        </Box>
      </MessagesContext.Provider>
    );
  }
}

export default withLDConsumer()(withWidth()(Messages));
