import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {Col, Row} from "reactstrap";
import {IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
// Custom
import LanguageSelector from "components/MultiOption/LanguageSelector";
import {EmptyPolicies} from "components/Helpers/EmptyPanels";
import CustomCardHeader from "core/cards/CustomCardHeader";
import InputField from "core/inputs/InputField";
import ListingContentList from "../../Lists/ListingContentList";
import BackButton from "core/buttons/BackButton";
import CustomFieldList from "../../Lists/CustomFieldList";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import SaveContentButton from "components/MultiOption/SaveContentButton";
// Actions
import {editHouse, updateListing} from "redux/actions/listingsActions";
// Utils
import {addArrayItemsToSet} from "utilities/helperFunctions";
import {THEME} from "configuration/settings";
import infoTexts from "assets/infoTexts";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.primary.main + "0D"}`,
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: theme.spacing(4),
    position: "relative",
  },
  selectorContainer: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "#FFF",
  },
  closeBtn: {
    padding: 2,
    marginLeft: theme.spacing(3),
    marginRight: -theme.spacing(2),
  },
  closeBtnIcon: {
    fontSize: 18,
    color: "#AAA",
  },
}));

export default function ListingPoliciesPanel({
  outsideView,
  listing,
  isParentLoading,
  onClose,
  disableEdit,
  goBack,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const updatedFields = React.useRef([]);
  const langToRemove = React.useRef(null);
  const defaultLang = useSelector((state) => state.defaultReducer.language);
  const loadingContent = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_content;
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  // State
  const [selectedLang, setSelectedLang] = React.useState(defaultLang);
  const [availableLangs, setAvailableLangs] = React.useState([]);
  const [customFields, setCustomFields] = React.useState({});
  const [checkinDescription, setCheckinDescription] = React.useState("");
  const [checkoutDescription, setCheckoutDescription] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [shortDesc, setShortDesc] = React.useState("");
  const [isEditing, setIsEditing] = React.useState({});
  const [deleteConfirmationModal, setDeleteConfirmationModal] =
    React.useState(false);
  const [multiLangValues, setMultiLangValues] = React.useState({
    checkinDescription: {},
    checkoutDescription: {},
    listingDesc: {},
    shortDesc: {},
  });
  const [langIndex, setLangIndex] = React.useState({
    checkinDescription: 0,
    checkoutDescription: 0,
    listingDesc: 0,
    shortDesc: 0,
  });
  // General
  const listingContent = listing?.listing_content || {};

  React.useEffect(() => {
    let descriptionObj = {};
    let checkoutDescriptionObj = {};
    let descObj = {};
    let shortDescObj = {};

    if (Object.keys(isEditing).find((i) => !!isEditing[i])) {
      return;
    }
    // Listing long & short descriptions
    if (!!listingContent.description) {
      _.each(
        listingContent.description,
        (d) => (descObj[d.language] = d.value),
      );
    } else {
      descObj[defaultLang] = "";
    }
    if (!!listingContent.short_description) {
      _.each(
        listingContent.short_description,
        (sd) => (shortDescObj[sd.language] = sd.value),
      );
    } else {
      shortDescObj[defaultLang] = "";
    }

    // CHECKIN description
    if (!!listingContent.access_method?.description) {
      _.each(
        listingContent.access_method.description,
        (desc) => (descriptionObj[desc.language] = desc.value),
      );
    } else {
      descriptionObj[defaultLang] = "";
    }

    // CHECKOUT description
    if (!!listingContent.access_method?.checkout_description) {
      _.each(
        listingContent.access_method.checkout_description,
        (desc) => (checkoutDescriptionObj[desc.language] = desc.value),
      );
    } else {
      checkoutDescriptionObj[defaultLang] = "";
    }

    setMultiLangValues((prev) => ({
      checkinDescription: descriptionObj,
      checkoutDescription: checkoutDescriptionObj,
      listingDesc: descObj,
      shortDesc: shortDescObj,
    }));
    setCustomFields((prev) => listingContent.custom || {});
  }, [isEditing, listing]);

  React.useEffect(() => {
    if (Object.keys(isEditing).find((i) => !!isEditing[i])) {
      return;
    }
    const allAvalLangs = new Set();
    addArrayItemsToSet(
      Object.keys(multiLangValues.checkinDescription),
      allAvalLangs,
    );
    addArrayItemsToSet(
      Object.keys(multiLangValues.checkoutDescription),
      allAvalLangs,
    );
    addArrayItemsToSet(Object.keys(multiLangValues.listingDesc), allAvalLangs);
    addArrayItemsToSet(Object.keys(multiLangValues.shortDesc), allAvalLangs);
    const newAvalLangs = [...allAvalLangs];

    setCheckinDescription(
      (prev) => multiLangValues.checkinDescription[selectedLang] || "",
    );
    setCheckoutDescription(
      (prev) => multiLangValues.checkoutDescription[selectedLang] || "",
    );
    setDescription((prev) => multiLangValues.listingDesc[selectedLang] || "");
    setShortDesc((prev) => multiLangValues.shortDesc[selectedLang] || "");
    setAvailableLangs((prev) => newAvalLangs);

    setSelectedLang((prev) =>
      newAvalLangs.includes(selectedLang)
        ? selectedLang
        : newAvalLangs.includes(defaultLang)
          ? defaultLang
          : newAvalLangs[0] || defaultLang,
    );
  }, [selectedLang, isEditing, multiLangValues, listing]);

  React.useEffect(() => {
    const descriptionIndex =
      listingContent.access_method?.description?.findIndex(
        (d) => d.language === selectedLang,
      ) ?? -1;
    const checkoutDescriptionIndex =
      listingContent.access_method?.checkout_description?.findIndex(
        (d) => d.language === selectedLang,
      ) ?? -1;
    const descIndex =
      listingContent.description?.findIndex(
        (n) => n.language === selectedLang,
      ) ?? -1;
    const shortDescIndex =
      listingContent.short_description?.findIndex(
        (n) => n.language === selectedLang,
      ) ?? -1;
    setLangIndex({
      checkinDescription:
        descriptionIndex > -1
          ? descriptionIndex
          : (listingContent.access_method?.description?.length ?? 0),
      checkoutDescription:
        checkoutDescriptionIndex > -1
          ? checkoutDescriptionIndex
          : (listingContent.access_method?.checkout_description?.length ?? 0),
      listingDesc:
        descIndex > -1 ? descIndex : (listingContent.description?.length ?? 0),
      shortDesc:
        shortDescIndex > -1
          ? shortDescIndex
          : (listingContent.short_description?.length ?? 0),
    });
  }, [selectedLang]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setMultiLangValues((prev) => ({
        checkinDescription: {
          ...prev.checkinDescription,
          [selectedLang]: checkinDescription,
        },
        checkoutDescription: {
          ...prev.checkoutDescription,
          [selectedLang]: checkoutDescription,
        },
        listingDesc: {...prev.listingDesc, [selectedLang]: description},
        shortDesc: {...prev.shortDesc, [selectedLang]: shortDesc},
      }));
    }, 200);

    return () => clearTimeout(timer);
  }, [checkinDescription, checkoutDescription, description, shortDesc]);

  const handleCustomFieldChange = (newCustomFields) =>
    setCustomFields((prev) => newCustomFields);

  const handleCancel = (type) => () => {
    updatedFields.current = [];
    switch (type) {
      case "checkin":
        setMultiLangValues((prev) => ({
          ...prev,
          checkinDescription: {
            ...prev.checkinDescription,
            [selectedLang]:
              listingContent.access_method?.description?.[
                langIndex.checkinDescription
              ]?.value || "",
          },
        }));
        break;
      case "checkout":
        setMultiLangValues((prev) => ({
          ...prev,
          checkoutDescription: {
            ...prev.checkoutDescription,
            [selectedLang]:
              listingContent.access_method?.checkout_description?.[
                langIndex.checkoutDescription
              ]?.value || "",
          },
        }));
        break;
      case "desc":
        setMultiLangValues((prev) => ({
          ...prev,
          listingDesc: {
            ...prev.listingDesc,
            [selectedLang]:
              listingContent.description?.[langIndex.listingDesc]?.value || "",
          },
        }));
        break;
      case "short_desc":
        setMultiLangValues((prev) => ({
          ...prev,
          shortDesc: {
            ...prev.shortDesc,
            [selectedLang]:
              listingContent.short_description?.[langIndex.shortDesc]?.value ||
              "",
          },
        }));
        break;
    }
  };

  const addUpdatedField = (f) => {
    if (!updatedFields.current.includes(f)) {
      updatedFields.current.push(f);
    }
  };

  const handleCheckinDescriptionChange = (e) => {
    const val = e.target.value;
    setCheckinDescription((prev) => val);
    addUpdatedField("checkin_directions");
  };

  const handleCheckoutDescriptionChange = (e) => {
    const val = e.target.value;
    setCheckoutDescription((prev) => val);
    addUpdatedField("checkout_directions");
  };

  const handleDescriptionChange = (e) => {
    const val = e.target.value;
    setDescription((prev) => val);
    addUpdatedField("desc");
  };

  const handleShortDescriptionChange = (e) => {
    const val = e.target.value;
    setShortDesc((prev) => val);
    addUpdatedField("short_desc");
  };

  const handleRemoveLang = (lang) => {
    langToRemove.current = lang;
    setDeleteConfirmationModal((prev) => true);
  };

  const closeDeleteModal = () => {
    setDeleteConfirmationModal((prev) => false);
  };

  const confirmRemoveLang = (option) => {
    setDeleteConfirmationModal((prev) => false);
    removeLang(langToRemove.current, option);
  };

  const addLang = (lang) => {
    setAvailableLangs((prev) => [...prev, lang]);
    setSelectedLang((prev) => lang);
    setMultiLangValues((prev) => ({
      checkinDescription: {...prev.checkinDescription, [lang]: ""},
      checkoutDescription: {...prev.checkoutDescription, [lang]: ""},
      listingDesc: {...prev.listingDesc, [lang]: ""},
      shortDesc: {...prev.shortDesc, [lang]: ""},
    }));
  };

  const removeLang = (lang, option) => {
    let newLangs = availableLangs.filter((l) => l !== lang);
    setAvailableLangs((prev) => newLangs);
    setSelectedLang((prev) => (prev === lang ? newLangs[0] : prev));
    const newContent = {...multiLangValues};
    delete newContent.checkinDescription[lang];
    delete newContent.checkoutDescription[lang];
    delete newContent.listingDesc[lang];
    delete newContent.shortDesc[lang];
    setMultiLangValues((prev) => newContent);
    let fields = [
      "checkin_directions",
      "checkout_directions",
      "desc",
      "short_desc",
    ];
    _.each(fields, (f) => addUpdatedField(f));
    handleSave(option);
  };

  const getData = () => {
    const directionKeys = Object.keys(multiLangValues.checkinDescription);
    const checkoutDirectionKeys = Object.keys(
      multiLangValues.checkoutDescription,
    );
    const descKeys = Object.keys(multiLangValues.listingDesc);
    const shortDescKeys = Object.keys(multiLangValues.shortDesc);
    let body = null;

    if (updatedFields.current.includes("checkin_directions")) {
      body = {
        ...body,
        access_method: {
          ...body?.access_method,
          description: _.map(directionKeys, (dk) => ({
            language: dk,
            value: multiLangValues.checkinDescription[dk] || "",
          })).filter((langText) => !!langText.value),
        },
      };
    }
    if (updatedFields.current.includes("checkout_directions")) {
      body = {
        ...body,
        access_method: {
          ...body?.access_method,
          checkout_description: _.map(checkoutDirectionKeys, (dk) => ({
            language: dk,
            value: multiLangValues.checkoutDescription[dk] || "",
          })).filter((langText) => !!langText.value),
        },
      };
    }
    if (updatedFields.current.includes("desc")) {
      body = {
        ...body,
        description: _.map(descKeys, (dk) => ({
          language: dk,
          value: multiLangValues.listingDesc[dk] || "",
        })).filter((langText) => !!langText.value),
      };
    }
    if (updatedFields.current.includes("short_desc")) {
      body = {
        ...body,
        short_description: _.map(shortDescKeys, (sdk) => ({
          language: sdk,
          value: multiLangValues.shortDesc[sdk] || "",
        })).filter((langText) => !!langText.value),
      };
    }
    return body;
  };

  const handleSave = (option) => {
    const body = getData();
    if (!body) {
      return;
    }
    const newListing = {
      ...listing,
      listing_content: {
        ...listing.listing_content,
        access_method: {
          ...listing.listing_content.access_method,
          ...body.access_method,
        },
        description: body.description ?? listing.listing_content.description,
        short_description:
          body.short_description ?? listing.listing_content.short_description,
        custom: customFields,
      },
    };
    dispatch(editHouse(newListing));
    dispatch(
      updateListing({
        body,
        disableListingUpdate: true,
        listing: newListing,
        option,
      }),
    );
    updatedFields.current = [];
  };

  function getField(type) {
    let infoText = null;
    let label = "";
    let placeholder = "";
    let val = "";
    let onChange = () => {};
    switch (type) {
      case "checkin":
        infoText = infoTexts.checkinDirections;
        label = `Check-in directions (Optional)`;
        placeholder = "Enter instructions here";
        val = checkinDescription;
        onChange = handleCheckinDescriptionChange;
        break;
      case "checkout":
        infoText = infoTexts.checkoutDirections;
        label = `Check-out directions (Optional)`;
        placeholder = "Enter instructions here";
        val = checkoutDescription;
        onChange = handleCheckoutDescriptionChange;
        break;
      case "desc":
        label = `Description`;
        placeholder = "Listing description";
        val = description;
        onChange = handleDescriptionChange;
        break;
      case "short_desc":
        label = `Short description (Optional)`;
        placeholder = "Description summary";
        val = shortDesc;
        onChange = handleShortDescriptionChange;
        break;
    }

    return (
      <InputField
        fullWidth
        disableEnterSave
        disableCentered
        useMultiOptionSave
        usePartialLayout
        infoText={infoText}
        label={label}
        value={val}
        emptyLabel="None"
        placeholder={placeholder}
        onSave={handleSave}
        setIsEditing={(newVal) =>
          setIsEditing((prev) => ({...prev, [type]: newVal}))
        }
        onCancel={handleCancel(type)}
        onChange={onChange}
      />
    );
  }

  const customFieldItems = React.useMemo(() => {
    return (
      <CustomFieldList
        listing={listing}
        disabled={disableEdit}
        fields={customFields}
        setIsEditing={(newVal) =>
          setIsEditing((prev) => ({...prev, custom_field: newVal}))
        }
        onChange={handleCustomFieldChange}
      />
    );
  }, [customFields, disableEdit]);

  const languageSelector = React.useMemo(() => {
    return (
      <LanguageSelector
        preventOverflow
        selectedLangs={availableLangs}
        selectedLanguage={selectedLang}
        addLang={addLang}
        removeLang={handleRemoveLang}
        selectLang={setSelectedLang}
      />
    );
  }, [listing, selectedLang, availableLangs]);

  const titleRow = (
    <CustomCardHeader
      title={
        !!goBack ? (
          <BackButton
            header={!isMobileView}
            color={!!isMobileView ? "secondary" : "primary"}
            goBack={goBack}
          />
        ) : (
          "Custom Fields"
        )
      }
      className={classes.header}
      action={
        !goBack && (
          <IconButton className={classes.closeBtn} onClick={onClose}>
            <CloseIcon className={classes.closeBtnIcon} />
          </IconButton>
        )
      }
    />
  );

  const outsideTitleRow = (
    <ListingContentList
      listingId={listing?.listing_id}
      onlyItems={["policies"]}
      disableBackground
    />
  );

  const removeLangConfirmationModal = !!deleteConfirmationModal && (
    <ConfirmDialog
      open
      maxWidth="sm"
      disableDismiss
      onClose={closeDeleteModal}
      title={"Delete language ?"}
      message={
        <>
          {
            "If you delete this language, all its content will be removed from the connected listings. Are you sure you want to delete this language?"
          }
        </>
      }
      cancelLabel="Cancel"
      cancelAction={closeDeleteModal}
      customConfirmBtn={
        <SaveContentButton
          label="Delete"
          disableFieldConfirmation
          onSave={confirmRemoveLang}
        />
      }
    />
  );

  return (
    <div className={classes.root}>
      {titleRow}
      {!!outsideView && outsideTitleRow}
      {removeLangConfirmationModal}
      <div className={classes.content}>
        {isParentLoading || loadingContent ? (
          <EmptyPolicies loading />
        ) : (
          <>
            {!outsideView && (
              <div className={classes.selectorContainer}>
                {languageSelector}
              </div>
            )}
            <Row style={{marginTop: THEME.spacing.lg}}>
              <Col xs={12} className="mb-4">
                {getField("desc")}
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-4">
                {getField("short_desc")}
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-4">
                {getField("checkin")}
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-4">
                {getField("checkout")}
              </Col>
            </Row>
            {customFieldItems}
          </>
        )}
      </div>
    </div>
  );
}
