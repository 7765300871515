import React, {useCallback, useEffect, useMemo, useState} from "react";
import CustomCellElement from "components/Helpers/CustomCellElement";
import VirtualizedTable from "ui/base/table/VirtualizedTable";
import {isFirefox} from "react-device-detect";
import {useTheme} from "@material-ui/core/styles";
import {ArrowDownward, ArrowUpward} from "@material-ui/icons";
import {Button} from "@material-ui/core";
import {THEME} from "../../../../configuration/settings";

export default function CampaignListTable({campaigns, onCampaignSelect}) {
  const theme = useTheme();

  const [sortParams, setSortParams] = useState({
    sortBy: "send_time",
    sortDirection: "DESC",
  });
  const [filteredCampaigns, setFilteredCampaigns] = useState(campaigns);

  useEffect(() => {
    setFilteredCampaigns(campaigns);
  }, [campaigns]);

  const handleSort = useCallback(({sortBy, sortDirection}) => {
    setSortParams({sortBy, sortDirection});
  }, []);

  const sortedCampaigns = useMemo(() => {
    if (!sortParams.sortBy) return filteredCampaigns;

    return [...filteredCampaigns].sort((a, b) => {
      let aValue, bValue;

      switch (sortParams.sortBy) {
        case "name":
          aValue = a.name ? a.name.toLowerCase() : "";
          bValue = b.name ? b.name.toLowerCase() : "";
          break;
        case "send_time":
          aValue =
            a.actions && a.actions.length > 0
              ? Number(a.actions[0].trigger_value)
              : 0;
          bValue =
            b.actions && b.actions.length > 0
              ? Number(b.actions[0].trigger_value)
              : 0;
          break;
        case "num_guests":
          aValue = a.connected_to?.ids?.length || 0;
          bValue = b.connected_to?.ids?.length || 0;
          break;
        default:
          return 0;
      }

      if (aValue < bValue) {
        return sortParams.sortDirection === "ASC" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortParams.sortDirection === "ASC" ? 1 : -1;
      }
      return 0;
    });
  }, [filteredCampaigns, sortParams]);

  const rowCount = sortedCampaigns.length;

  const rowGetter = useCallback(
    ({index}) => {
      const campaign = sortedCampaigns[index];
      const sendTime =
        campaign?.actions && campaign.actions.length > 0
          ? Number(campaign.actions[0].trigger_value)
          : null;
      const numGuests = campaign.connected_to?.ids?.length ?? 0;
      return {
        ...campaign,
        send_time: sendTime,
        num_guests: numGuests,
      };
    },
    [sortedCampaigns],
  );

  const onRowClick = useCallback(
    ({index}) => {
      if (index >= sortedCampaigns.length) return;
      const selectedCampaign = sortedCampaigns[index];
      onCampaignSelect(selectedCampaign);
    },
    [sortedCampaigns, onCampaignSelect],
  );

  const getCustomCell = useCallback(
    (columnConfig, cellData, rowIndex) => {
      const columnDataKey = columnConfig.dataKey;
      const campaign = sortedCampaigns[rowIndex];
      let displayValue = cellData;
      let isSent = false;

      if (columnConfig.field === "send_time") {
        if (cellData === null || isNaN(cellData)) {
          displayValue = "N/A";
        } else {
          const date = new Date(cellData);
          displayValue = date.toLocaleString();
          isSent = date < new Date();
        }
      }

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(0, 1),
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: isFirefox ? "pre-wrap" : "nowrap",
          }}
        >
          <CustomCellElement
            data={displayValue}
            rowIndex={rowIndex}
            obj={campaign}
            field={columnConfig.field}
          />
          {isSent && columnConfig.field === "send_time" && (
            <Button
              variant="contained"
              className="ml-3"
              style={{
                borderRadius: "5px",
                background: "#DCE5EC",
                color: "#275588",
                marginLeft: THEME.spacing.lg,
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "1rem",
                maxHeight: "1.5rem",
              }}
            >
              Sent
            </Button>
          )}
        </div>
      );
    },
    [sortedCampaigns, theme],
  );

  const table_layout = useMemo(
    () => [
      {
        width: 20,
        label: "",
        field: "",
        dataKey: "",
        flexGrow: 0,
        hideLabel: true,
      },
      {
        width: 1,
        label: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              let newDirection = "ASC";
              if (
                sortParams.sortBy === "name" &&
                sortParams.sortDirection === "ASC"
              ) {
                newDirection = "DESC";
              }
              handleSort({sortBy: "name", sortDirection: newDirection});
            }}
          >
            Name
            {sortParams.sortBy === "name" ? (
              sortParams.sortDirection === "ASC" ? (
                <ArrowUpward fontSize="small" />
              ) : (
                <ArrowDownward fontSize="small" />
              )
            ) : null}
          </div>
        ),
        field: "name",
        dataKey: "name",
        flexGrow: 0.8,
        customCell: true,
        sortable: true,
      },
      {
        width: 1,
        label: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              let newDirection = "ASC";
              if (
                sortParams.sortBy === "send_time" &&
                sortParams.sortDirection === "ASC"
              ) {
                newDirection = "DESC";
              }
              handleSort({sortBy: "send_time", sortDirection: newDirection});
            }}
          >
            Send Time
            {sortParams.sortBy === "send_time" ? (
              sortParams.sortDirection === "ASC" ? (
                <ArrowUpward fontSize="small" />
              ) : (
                <ArrowDownward fontSize="small" />
              )
            ) : null}
          </div>
        ),
        field: "send_time",
        dataKey: "send_time",
        flexGrow: 0.8,
        customCell: true,
        sortable: true,
      },
      {
        width: 1,
        label: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              let newDirection = "ASC";
              if (
                sortParams.sortBy === "num_guests" &&
                sortParams.sortDirection === "ASC"
              ) {
                newDirection = "DESC";
              }
              handleSort({sortBy: "num_guests", sortDirection: newDirection});
            }}
          >
            # Guests
            {sortParams.sortBy === "num_guests" ? (
              sortParams.sortDirection === "ASC" ? (
                <ArrowUpward fontSize="small" />
              ) : (
                <ArrowDownward fontSize="small" />
              )
            ) : null}
          </div>
        ),
        field: "num_guests",
        dataKey: "num_guests",
        flexGrow: 0.8,
        sortable: true,
      },
    ],
    [sortParams, handleSort],
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <div
        style={{
          flexGrow: 1,
          overflow: "hidden",
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <VirtualizedTable
          rowCount={rowCount}
          loaderRowCount={rowCount}
          rowGetter={rowGetter}
          columns={table_layout}
          onRowClick={onRowClick}
          rowHeight={56}
          getCustomCell={getCustomCell}
          useInfiniteLoader={false}
          isRowLoaded={() => true}
          loadMoreRows={() => null}
          sort={handleSort}
          sortBy={sortParams.sortBy}
          sortDirection={sortParams.sortDirection}
          disableAutoHeaderHeight
        />
      </div>
    </div>
  );
}
