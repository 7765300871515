import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import TextSnippetIcon from "@material-ui/icons/Description";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AppsIcon from "@material-ui/icons/Apps";
import PetsIcon from "@material-ui/icons/Pets";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import EventIcon from "@material-ui/icons/Event";
import {makeStyles, useTheme} from "@material-ui/core";
import CheckInIcon from "assets/icons/CheckInIcon";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
  box: {
    marginBottom: 15,
    borderRadius: "10px",
  },
});

const VerifyItem = ({
  verified,
  component_type,
  onClick,
  disabled = false,
  margin = 0,
  name = null,
  components,
  error = false,
  setView,
}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const theme = useTheme();
  let spec = {
    fee: {
      icon: <AttachMoneyIcon style={{color: theme.palette.primary.main}} />,
      id: "fee",
      disabled: !!error,
    },
    pet_fee: {
      icon: <PetsIcon style={{color: theme.palette.primary.main}} />,
      id: "pet_fee",
      disabled: !!error,
    },
    custom_fee: {
      icon: <AttachMoneyIcon style={{color: theme.palette.primary.main}} />,
      id: "custom_fee",
      disabled: !!error,
    },
    deposit: {
      icon: <AccountBalanceIcon style={{color: theme.palette.primary.main}} />,
      id: "deposit",
      disabled: !!error,
    },
    id_scan: {
      name: t("id_check"),
      icon: <ContactMailIcon style={{color: theme.palette.primary.main}} />,
      id: "idCheck",
    },
    id_upload: {
      name:
        t("id_check") +
        (!!!components?.id_upload ? ` - ${t("failed_contact_host")}` : ""),
      icon: <ContactMailIcon style={{color: theme.palette.primary.main}} />,
      id: "idCheck",
      disabled: !!!components?.id_upload,
    },
    agreement: {
      name: t("agreement"),
      icon: <TextSnippetIcon style={{color: theme.palette.primary.main}} />,
      id: "agreements",
    },
    details: {
      name: t("your_details"),
      icon: <AccountCircleIcon style={{color: theme.palette.primary.main}} />,
      id: "details",
    },
    govt_id_front: {
      name: t("front_id"),
      icon: <ContactMailIcon style={{color: theme.palette.primary.main}} />,
      id: "idFront",
    },
    govt_id_back: {
      name: t("back_id"),
      icon: <ContactMailIcon style={{color: theme.palette.primary.main}} />,
      id: "idBack",
    },
    selfie: {
      name: t("selfie_word"),
      icon: <AccountCircleIcon style={{color: theme.palette.primary.main}} />,
      id: "selfie",
    },
    upload_photo: {
      name: t("upload_photo"),
      icon: <PhotoLibraryIcon style={{color: theme.palette.primary.main}} />,
    },
    check_in_steps: {
      name: t("checkin"),
      icon: <CheckInIcon style={{color: theme.palette.primary.main}} />,
    },
    add_to_home: {
      name: t("add_to_home"),
      icon: <AppsIcon style={{color: theme.palette.primary.main}} />,
    },
    add_to_cal: {
      name: t("add_to_calendar"),
      icon: <EventIcon style={{color: theme.palette.primary.main}} />,
    },
  }[component_type];

  return (
    <ListItem
      key={component_type}
      style={{
        border: `1.5px solid ${theme.palette.primary.main}`,
        margin: 0,
        marginBottom: margin,
      }}
      className={classes.box}
      id={`${spec.id}${verified ? "-disabled" : ""}`}
      button
      disabled={disabled || spec?.disabled || verified}
      onClick={(e) => (!!onClick ? onClick(e) : setView(e))}
    >
      <ListItemIcon style={{minWidth: 0, marginRight: 5}}>
        {spec.icon}
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{variant: "h5"}}
        primary={spec.name || name}
        style={{padding: 10}}
      />
      {verified ? (
        <CheckCircleIcon style={{color: theme.palette.primary.main}} />
      ) : (
        <KeyboardArrowRightIcon style={{color: theme.palette.primary.main}} />
      )}
    </ListItem>
  );
};

export default VerifyItem;
