import React, {useState, useEffect} from "react";
import {useRouteMatch} from "react-router-dom";
import {
  makeStyles,
  Typography,
  Card,
  CardContent,
  CardActionArea,
} from "@material-ui/core";
import clsx from "clsx";
import {deviceList} from "configuration/specs.js";
import {channelIntegrationList} from "configuration/enums.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    width: "100%",
  },
  cardsContainer: {
    display: "flex",
    flexWrap: "wrap",
    height: "100%",
    justifyContent: "center",
    maxWidth: "100%",
    gap: "10px",
  },
  card: {
    width: 200,
    height: 164,
    marginBottom: theme.spacing(1),
  },
  deviceMedia: {
    borderRadius: 10,
    marginBottom: 10,
    height: 50,
    maxWidth: "100%",
    objectFit: "scale-down",
  },
  pmsMedia: {
    borderRadius: 10,
    marginBottom: 10,
    height: 40,
    maxWidth: "100%",
    objectFit: "scale-down",
  },
  disabledCard: {
    width: 200,
    height: 164,
    marginBottom: theme.spacing(1),
    backgroundColor: "rgba(0,0,0,0.5)",
    position: "relative",
  },
  disabledText: {
    position: "absolute",
    top: "50%",
    left: "20%",
    display: "inline",
    fontSize: 20,
    color: theme.palette.common.white,
    zIndex: theme.zIndex.appBar,
  },
  sectionHeader: {
    alignSelf: "flex-start",
  },
}));

export default function IntegrationSelectionPanel({
  selectDevice,
  selectPMS,
  pmsList,
}) {
  const classes = useStyles();
  const match = useRouteMatch("/admin/:section");
  const [searchText] = useState("");
  const [filteredItems, setFilteredItems] = useState(
    pmsList ||
      channelIntegrationList.filter(
        (integration) => integration.signup_type !== null,
      ),
  );

  useEffect(() => {
    if (pmsList) {
      const filtered = pmsList.filter((integration) =>
        integration.title.toLowerCase().includes(searchText.toLowerCase()),
      );
      setFilteredItems(filtered);
    }
  }, [searchText, pmsList]);

  function mapCards(devices) {
    return devices.map((d) => (
      <Card
        className={d.enabled ? classes.card : classes.disabledCard}
        key={`${d.name}-${d.text}`}
      >
        {!d.enabled && <div className={classes.disabledText}>Coming Soon</div>}
        <CardActionArea disabled={!d.enabled} onClick={() => selectDevice(d)}>
          <CardContent style={{height: 164}}>
            <img src={d.picture} className={classes.deviceMedia} />
            <Typography variant="h1" component="h2">
              {d.name}
            </Typography>
            <Typography component="h2">{d.text}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    ));
  }

  function mapPmsCards() {
    return filteredItems.map((integration) => (
      <Card className={classes.card} key={integration.title}>
        <CardActionArea onClick={() => selectPMS(integration)}>
          <CardContent style={{height: 164}}>
            <img
              src={integration.icon}
              className={classes.pmsMedia}
              alt={integration.title}
            />
            <Typography variant="h1" component="h2">
              {integration.title}
            </Typography>
            <Typography component="h2">
              Sync your bookings & listings
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    ));
  }

  return (
    <div className={clsx(classes.root)}>
      {selectPMS && (
        <>
          {selectDevice && (
            <>
              <Typography
                variant="h2"
                className={classes.sectionHeader}
                color="primary"
              >
                Property Management Softwares
              </Typography>
            </>
          )}

          <div className={clsx(classes.cardsContainer, "mt-3")}>
            {mapPmsCards()}
          </div>
        </>
      )}
      {selectDevice != null && (
        <>
          <Typography
            variant="h2"
            className={classes.sectionHeader}
            color="primary"
          >
            Smart Locks
          </Typography>
          <div className={clsx(classes.cardsContainer, "mt-3")}>
            {mapCards(deviceList.access)}
          </div>
          <Typography
            variant="h2"
            className={classes.sectionHeader}
            color="primary"
          >
            Noise Sensors
          </Typography>
          <div className={clsx(classes.cardsContainer, "mt-3")}>
            {mapCards(deviceList.sensor)}
          </div>
        </>
      )}
    </div>
  );
}
