import React from "react";
import {useSelector} from "react-redux";
// UI
import {Col, Row} from "reactstrap";
import {Checkbox, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import FilledTextField from "core/inputs/FilledTextField";
import usePrevious from "hooks/usePrevious";

const useStyles = makeStyles((theme) => ({
  row: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  checkbox: {marginLeft: -10},
  label: {color: "rgba(0, 0, 0, 0.65)"},
  title: {
    fontWeight: 700,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.6)",
    marginBottom: theme.spacing(3),
  },
}));

export default function Cost({value = "", updateValue}) {
  const classes = useStyles();
  const language = useSelector((state) => state.defaultReducer.language);
  const [cost, setCost] = React.useState(value);
  const [isFree, setIsFree] = React.useState(!value);
  const prevIsFree = usePrevious(isFree);

  React.useEffect(() => {
    if (value !== cost) {
      setCost((prev) => value);
      setIsFree((prev) => !value);
    }
  }, [value]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (!!prevIsFree && !isFree) {
        setCost((prev) => 1);
      } else if (value !== cost) {
        updateValue([{language, value: cost}]);
      }
    }, 150);

    return () => clearTimeout(timer);
  }, [cost, isFree]);

  const onCheck = (e) => {
    const val = e.target.checked;
    if (!!val) {
      setCost((prev) => 0);
    }
    setIsFree((prev) => val);
  };

  const onChange = (e) => {
    const val = e.target.value;
    setCost((prev) => val);
  };

  return (
    <div className="mb-3">
      <Typography variant="subtitle1" className={classes.title}>
        Cost or Free
      </Typography>
      <Row>
        <Col xs={12} md={6}>
          <FilledTextField
            id="Cost"
            label="Cost"
            placeholder=""
            type="number"
            inputProps={{min: 0, max: 100000}}
            disabled={isFree}
            onChange={onChange}
            value={cost}
            fullWidth
          />
        </Col>
        <Col xs={12} md={6}>
          <div className={classes.row}>
            <Checkbox
              size="small"
              color="secondary"
              checked={isFree}
              className={classes.checkbox}
              onChange={onCheck}
            />
            <Typography variant="caption" noWrap className={classes.label}>
              Free
            </Typography>
          </div>
        </Col>
      </Row>
    </div>
  );
}
