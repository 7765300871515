import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  getElapsedDays,
  isWithinRange,
  setEndOfDay,
  setStartOfDay,
} from "utilities/helperFunctions";
import {eachDayOfInterval, format, isAfter, isBefore, subDays} from "date-fns";
import {enCA} from "date-fns/locale";
import _ from "lodash";
// UI
import {DateRangePicker} from "react-nice-dates";
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Slide,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIcon from "@material-ui/icons/NavigateNext";
import useCalendarStyles from "../../../styles/useCalendarStyles";
// Custom
import FilledTextField from "core/inputs/FilledTextField";
import NumberFormatCustom from "../../TextFields/NumberFormatCustom";
import CustomSwitch from "core/switches/CustomSwitch";
import CustomCardHeader from "core/cards/CustomCardHeader";
import WarningAlert from "core/alerts/WarningAlert";

import {editHouse, updateHouseRanges} from "redux/actions/listingsActions";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  cancelBtn: {
    color: "#A8A8A8",
    fontSize: 14,
  },
  paper: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  subtitle: {fontWeight: theme.typography.fontWeightMedium},
  content: {
    width: "100%",
    padding: theme.spacing(4),
    marginTop: theme.spacing(2),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    "& > *": {marginBottom: theme.spacing(4)},
  },
  formControlLabel: {
    marginLeft: 0,
    marginRight: 0,
  },
  switch: {marginRight: theme.spacing(4)},
  specifySwitch: {},
  specifyInput: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgba(0,0,0,0.09)",
    padding: theme.spacing(2, 3),
  },
  specifyLink: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    cursor: "pointer",
  },
  warningContainer: {marginBottom: theme.spacing(4)},
}));

function CalendarDetails({
  listing,
  selectedRange,
  formattedRanges,
  setStartRange,
  setEndRange,
  onClose,
  selectEndDate,
  disableEdit,
}) {
  const classes = useStyles();
  const calendarStyles = useCalendarStyles();

  const dispatch = useDispatch();
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const bookings = useSelector((state) => state.defaultReducer.bookings_dict);
  // State
  const [bkg, setBkg] = React.useState(null);
  const [disabledSave, setDisabledSave] = React.useState(false);
  const [displayWarning, setDisplayWarning] = React.useState(false);
  const [startDate, setStartDate] = React.useState(selectedRange[0]);
  const [endDate, setEndDate] = React.useState(selectedRange[1]);
  const [available, setAvailable] = React.useState(null);
  const [rate, setRate] = React.useState(null);
  const [specifyValue, setSpecifyValue] = React.useState({
    rate: false,
    availability: false,
  });
  const [showFields, setShowFields] = React.useState({
    rate: true,
    availability: true,
  });
  const warningDates = !!bkg
    ? [format(bkg.checkin_raw, "MMM d"), format(bkg.checkout_raw, "MMM d, y")]
    : ["", ""];

  React.useEffect(() => {
    setShowFields((prev) => ({
      rate: !specifyValue.rate,
      availability: !specifyValue.availability,
    }));
  }, [specifyValue]);

  React.useEffect(() => {
    if ((!startDate || !endDate) && !disabledSave) setDisabledSave(true);
    else if (!!startDate && !!endDate && disabledSave) setDisabledSave(false);

    if (!endDate) {
      setEndDate(startDate);
      selectedRange[0] !== startDate && setStartRange(startDate);
      selectedRange[1] !== endDate && setEndRange(startDate);
    } else {
      selectedRange[0] !== startDate && setStartRange(startDate);
      selectedRange[1] !== endDate && setEndRange(endDate);
    }

    if (!endDate || !startDate) {
      setSpecifyValue({rate: false, availability: false});
      setDisplayWarning(false);
      setAvailable(null);
      setRate(null);
      setBkg(null);
      return;
    }

    const bkgRanges = getBkgRanges();
    const selectedInterval = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });
    const rates = new Set([]);
    const availabilities = new Set([]);
    let bkgId = false;
    _.each(selectedInterval, (date) => {
      let noRateInfo = true;
      let noAvalInfo = true;
      _.each(formattedRanges.rates, (r) => {
        if (isWithinRange(date, [r[0], r[1]])) {
          rates.add(r[2].amount);
          noRateInfo = false;
        }
      });
      _.each(formattedRanges.availability, (a) => {
        if (isWithinRange(date, [a[0], a[1]])) {
          availabilities.add(a[2].availability);
          noAvalInfo = false;
        }
      });
      noRateInfo && rates.add(0);
      noAvalInfo && availabilities.add(true);
      _.each(bkgRanges, (b) => {
        if (isWithinRange(date, b.range)) {
          bkgId = b.bkgId;
          return false;
        }
      });
    });
    const multipleValues = {rate: false, availability: false};
    multipleValues.rate = rates.size > 1;
    multipleValues.availability = availabilities.size > 1;
    setSpecifyValue((prev) => ({...multipleValues}));

    if (!!bkgId) {
      setBkg((prev) => bookings.booking_id[bkgId]);
      setDisplayWarning(true);
    }

    if (rates.size > 1) setRate(null);
    else if (rates.size === 1) setRate([...rates][0]);
    else if (!rates.size) setRate("");

    if (availabilities.size > 1) setAvailable(null);
    else if (availabilities.size === 1) setAvailable([...availabilities][0]);
    else if (!availabilities.size) setAvailable(true);
  }, [startDate, endDate]);

  React.useEffect(() => {
    selectedRange[0] !== startDate && setStartDate(selectedRange[0]);
    selectedRange[1] !== endDate && setEndDate(selectedRange[1]);
  }, [selectedRange]);

  const getBkgRanges = () => {
    const newBookingsRange = [];
    _.each(formattedRanges.bookings, (b) => {
      const ci_date = new Date(b.checkin_raw);
      const co_date = setEndOfDay(new Date(b.checkout_raw));
      newBookingsRange.push({bkgId: b.booking_id, range: [ci_date, co_date]});
    });
    return newBookingsRange;
  };

  const resetSelection = () => {
    setStartRange(null);
    setEndRange(null);
  };
  const handleClose = () => {
    resetSelection();
    onClose();
  };
  const clearSelection = () => {
    setStartDate(null);
    setEndDate(null);
  };
  const toggleAvailability = () =>
    !disableEdit && setAvailable((prev) => !prev);
  const handleRateChange = (event) => setRate(event.target.value);

  const enableAvalControl = () => {
    setAvailable(true);
    setShowFields((prev) => ({...prev, availability: true}));
  };

  const handleInputBlur = () => {
    if (specifyValue.rate && (!rate || !rate.trim()))
      setShowFields((prev) => ({...prev, rate: false}));
  };

  const updateData = (data) => {
    let body = {
      enso_key: current_user,
      listing_id: listing.listing_id,
      data: data,
    };
    dispatch(updateHouseRanges(body, listing));
    resetSelection();
    onClose();
  };

  const handleSave = () => {
    if (!rate && available == null) {
      resetSelection();
      onClose();
      return;
    }
    const updateRange = {
      begin_date: getElapsedDays(setStartOfDay(startDate)),
      end_date: getElapsedDays(subDays(setEndOfDay(endDate), 1)),
    };
    const newData = {};
    if (available != null)
      newData.availabilities = [{...updateRange, availability: available}];
    if (rate) newData.rates = [{...updateRange, amount: rate}];

    let newHouse = {
      ...listing,
      listing_ranges: {
        ...listing.listing_ranges,
        availabilities:
          available != null
            ? [
                ...listing.listing_ranges.availabilities,
                ...newData.availabilities,
              ]
            : listing.listing_ranges.availabilities,
        rates: rate
          ? [...listing.listing_ranges.rates, ...newData.rates]
          : listing.listing_ranges.rates,
      },
    };
    dispatch(editHouse(newHouse));

    newData.update_range = updateRange;
    updateData(newData);
  };

  const handleInputNewDate = (type) => (newDate) => {
    if (!newDate) return;
    if (type === "start") {
      if (isAfter(newDate, endDate)) {
        setStartDate(newDate);
        setEndDate(newDate);
      } else setStartDate(newDate);
    } else {
      if (isBefore(newDate, startDate)) {
        setStartDate(newDate);
        setEndDate(newDate);
      } else setEndDate(newDate);
    }
  };

  return (
    <Grid container className={classes.root}>
      <Slide in direction="left">
        <Paper elevation={0} className={classes.paper}>
          <CustomCardHeader
            title={`${disableEdit ? "" : "Edit "}Availability Details`}
            type="title"
            action={
              !disableEdit ? (
                <>
                  <Button
                    className="mr-2"
                    classes={{label: classes.cancelBtn}}
                    size="small"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    disabled={disabledSave}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              )
            }
          />
          <div className={classes.content}>
            {displayWarning && (
              <div className={classes.warningContainer}>
                <WarningAlert
                  title="Changes Won't Apply to Booked Dates"
                  disableMargin
                  subtitle={`${bkg.name} booked this listing between ${warningDates[0]} and ${warningDates[1]}`}
                  onClose={() => setDisplayWarning(false)}
                />
              </div>
            )}
            <Typography className={classes.subtitle}>
              Listing Availability
            </Typography>
            <div className={calendarStyles.inputsContainer}>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={handleInputNewDate("start")}
                onEndDateChange={handleInputNewDate("end")}
                format="MMM dd yyyy"
                locale={enCA}
                touchDragEnabled={true}
              >
                {({startDateInputProps, endDateInputProps}) => (
                  <div className="date-range">
                    <div id="inputs">
                      <FilledTextField
                        className="input"
                        label="Start Date"
                        placeholder="MMM dd yyyy"
                        value={
                          startDateInputProps.value ||
                          (startDate ? format(startDate, "MMM dd, yyyy") : "")
                        }
                        {...startDateInputProps}
                        ref={null}
                      />
                      <ArrowForwardIcon className={calendarStyles.icon} />
                      <FilledTextField
                        className="input"
                        label="End Date"
                        placeholder="MMM dd yyyy"
                        value={
                          endDateInputProps.value ||
                          (endDate ? format(endDate, "MMM dd, yyyy") : "")
                        }
                        {...endDateInputProps}
                        ref={null}
                        onFocus={() => selectEndDate(true)}
                        onBlur={() => selectEndDate(false)}
                      />
                    </div>
                  </div>
                )}
              </DateRangePicker>
              <Button
                color="primary"
                variant="text"
                size="small"
                className={calendarStyles.clearBtn}
                disabled={!startDate && !endDate}
                onClick={clearSelection}
              >
                Clear Selection
              </Button>
            </div>
            {showFields.availability ? (
              <FormControlLabel
                classes={{root: classes.formControlLabel}}
                control={
                  <CustomSwitch
                    alignStart
                    checked={available ?? true}
                    disabled={disableEdit}
                    onChange={toggleAvailability}
                  />
                }
                label="Available to Guests"
              />
            ) : (
              <div>
                <Typography>Available to Guests</Typography>
                <Typography
                  className={classes.specifyLink}
                  variant="subtitle1"
                  onClick={enableAvalControl}
                >
                  Click to Specify
                </Typography>
              </div>
            )}
            {disableEdit ? (
              <div className={classes.column}>
                <Typography
                  className="mt-3"
                  color="textSecondary"
                  variant="caption"
                >
                  {"Rate"}
                </Typography>
                {showFields.rate ? (
                  !!rate ? (
                    <NumberFormat
                      value={rate}
                      thousandSeparator
                      prefix="$"
                      displayType="text"
                    />
                  ) : (
                    <Typography>No rate</Typography>
                  )
                ) : (
                  <Typography>Mixed</Typography>
                )}
              </div>
            ) : showFields.rate ? (
              <FilledTextField
                label="Rate"
                value={rate || ""}
                placeholder="$123"
                autoFocus
                onChange={handleRateChange}
                onBlur={handleInputBlur}
                name="rate-input"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  inputProps: {prefix: "$"},
                }}
              />
            ) : (
              <div className={classes.specifyInput}>
                <Typography variant="caption">Rate</Typography>
                <Typography
                  className={classes.specifyLink}
                  variant="subtitle1"
                  onClick={() =>
                    setShowFields((prev) => ({...prev, rate: true}))
                  }
                >
                  Click to Specify
                </Typography>
              </div>
            )}
          </div>
        </Paper>
      </Slide>
    </Grid>
  );
}

const areEqual = (prevProps, nextProps) => {
  return (
    _.isEqual(prevProps.selectedRange, nextProps.selectedRange) &&
    _.isEqual(prevProps.formattedRanges, nextProps.formattedRanges) &&
    _.isEqual(prevProps.showWarning, nextProps.showWarning)
  );
};

export default React.memo(CalendarDetails, areEqual);
