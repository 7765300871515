import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import PrimaryButton from "core/buttons/PrimaryButton";

const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: 6,
    boxShadow:
      "0px 10px 20px rgba(32, 37, 38, 0.1), 0px 20px 50px rgba(32, 37, 38, 0.1)",
  },
  dialogTitle: {
    padding: theme.spacing(4),
    fontWeight: 590,
    fontSize: 24,
    lineHeight: "24px",
    color: "#3F575C",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogContent: {padding: theme.spacing(4)},
  iconBtn: {marginRight: -8},
  closeIcon: {
    color: "rgba(0,0,0,0.25)",
    fontSize: 20,
  },
  actions: {
    backgroundColor: "#F0F1F1",
    justifyContent: "right",
    padding: theme.spacing(3, 4),
  },
  sb: {justifyContent: "space-between"},
  end: {justifyContent: "flex-end"},
  optionBtn: {
    backgroundColor: "transparent !important",
    minWidth: "fit-content",
    padding: 0,
  },
  cancelBtn: {
    minWidth: "fit-content",
    marginRight: theme.spacing(1),
    padding: theme.spacing(1, 3),
    textTransform: "none",
  },
  cancelBtnLabelGray: {color: "#A8A8A8"},
  cancelBtnLabel: {
    textTransform: "none",
    fontSize: 14,
  },
  confirmBtn: {
    textTransform: "none",
    minWidth: "fit-content",
    lineHeight: "19px",
    padding: theme.spacing(1, 3),
  },
}));

export default function ConfirmDialog({
  open,
  title,
  message,
  confirmLabel,
  cancelLabel,
  optionLabel,
  disableDismiss,
  confirmAction,
  cancelAction,
  optionAction,
  onClose,
  confirmDisabled = false,
  maxWidth = "xs",
  cancelBtnVariant = "text",
  cancelBtnColor = "default",
  optionBtnVariant = "text",
  optionBtnColor = "secondary",
  customConfirmBtn = null,
}) {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (disableDismiss && reason === "backdropClick") {
      return;
    }
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      classes={{paper: classes.dialog}}
      disableEscapeKeyDown={disableDismiss}
      aria-labelledby="dialog-title"
    >
      <DialogTitle
        id="dialog-title"
        disableTypography
        className={classes.dialogTitle}
      >
        {title}
        {!disableDismiss && (
          <IconButton
            size="small"
            className={classes.iconBtn}
            onClick={onClose}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <DialogContentText component="div">{message}</DialogContentText>
      </DialogContent>
      <DialogActions
        disableSpacing
        className={clsx(classes.actions, {
          [classes.sb]: !!optionLabel,
          [classes.end]: !optionLabel,
        })}
      >
        {!!optionLabel && (
          <Button
            size="small"
            variant={optionBtnVariant}
            color={optionBtnColor}
            onClick={optionAction}
            classes={{
              root: classes.optionBtn,
              label: clsx({
                [classes.cancelBtnLabelGray]: optionBtnColor === "default",
              }),
            }}
          >
            {optionLabel}
          </Button>
        )}
        <div>
          <Button
            variant={cancelBtnVariant}
            color={cancelBtnColor}
            onClick={cancelAction}
            classes={{
              root: classes.cancelBtn,
              label: clsx(classes.cancelBtnLabel, {
                [classes.cancelBtnLabelGray]: cancelBtnColor === "default",
              }),
            }}
          >
            {cancelLabel}
          </Button>
          {customConfirmBtn ?? (
            <PrimaryButton
              id={"confirm-dialog-action-button"}
              size="small"
              color="secondary"
              disabled={confirmDisabled}
              className={classes.confirmBtn}
              label={confirmLabel}
              onClick={confirmAction}
            />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
}
