import React from "react";
import {Box} from "@material-ui/core";
import useThreePanelStyles from "styles/useThreePanelStyles";
import clsx from "clsx";

export default function Panel2({
  hidden,
  sm,
  lg,
  disableStandaloneStyle,
  isPanel3Open,
  content,
  navigationBtn,
  hasContentAbove,
  flexAuto,
  bg = "#FFFFFF",
}) {
  const classes = useThreePanelStyles({
    sm,
    lg,
    disableStandaloneStyle,
    isPanel3Open,
    bg,
  });

  return (
    <Box
      className={clsx(classes.basePanel, classes.panel2, {
        "-lg": !!lg,
        "-hidden": !!hidden,
        "-lg-last-panel": !!lg && !isPanel3Open,
        "-last-panel": !lg && !isPanel3Open,
        [classes.btrr0]: !!hasContentAbove,
        flexAuto: !!flexAuto,
      })}
    >
      {!!navigationBtn && (
        <Box pt={3} px={4} textAlign="end">
          {navigationBtn}
        </Box>
      )}
      {content}
    </Box>
  );
}
