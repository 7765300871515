import React from "react";
import {IconButton, makeStyles} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#5C5F62",
    fontSize: 28,
    "&.-large": {fontSize: 36},
    "&.-small": {fontSize: 20},
    "&.-xsmall": {fontSize: 12},
    "&.-black": {color: "#202223"},
    "&.-light": {color: "#FFF"},
    "&.-error": {color: theme.palette.error.main},
    "&.-primary": {color: theme.palette.primary.main},
  },
  noPadding: {padding: 0},
  backrop: {
    padding: 2,
    backgroundColor: "rgba(0,0,0,0.6)",
    "&:hover": {backgroundColor: "rgba(0,0,0,0.4)"},
  },
}));

export default function CloseIconButton({
  onClick,
  sm,
  xs,
  lg,
  primary,
  error,
  black,
  useBackrop,
  disablePadding,
  light,
}) {
  const classes = useStyles();

  return (
    <IconButton
      size="small"
      onClick={onClick}
      className={clsx({
        [classes.noPadding]: !!disablePadding,
        [classes.backrop]: !!useBackrop,
      })}
    >
      <Close
        className={clsx(classes.icon, {
          "-small": !!sm,
          "-xsmall": !!xs,
          "-large": !!lg,
          "-black": !!black && !light,
          "-light": !!light,
          "-error": !!error,
          "-primary": !!primary,
        })}
      />
    </IconButton>
  );
}
