import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router";
import {Button, Paper, Slide, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ExperienceBuilder from "../../../../components/Panels/Experience/ExperienceBuilder";
import WarningAlert from "core/alerts/WarningAlert";
import FullLoader from "components/Dialogs/FullLoader";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import PrimaryButton from "core/buttons/PrimaryButton";
import FilledTextField from "core/inputs/FilledTextField";
import CustomCardHeader from "core/cards/CustomCardHeader";
import DateTimePicker from "core/inputs/DateTimePicker";
import {
  deleteExperience,
  updateExperience,
} from "redux/actions/experiencesActions";
import {isValid} from "date-fns";
import clsx from "clsx";
import UpdateSession from "../../../../components/Dialogs/UpdateSession";
import {THEME} from "../../../../configuration/settings";
import {useFlags} from "launchdarkly-react-client-sdk";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  paper: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  content: {
    flexGrow: 1,
    borderTop: "1px solid rgba(13,86,140,0.05)",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  cancelBtn: {color: "#A8A8A8", fontSize: 14},
  cardHeader: {padding: theme.spacing(4)},
  warningContainer: {padding: theme.spacing(4)},
  container: {padding: theme.spacing(3, 4)},
  betaChip: {height: 24, paddingTop: 3},
  errorButton: {
    color: THEME.error,
    marginRight: theme.spacing(2),
    borderColor: `${THEME.error}!important`,
    "&:hover": {backgroundColor: `${THEME.error}0a`},
  },
}));

export default function CampaignPanel({
  selectedCampaign,
  connectedIds,
  hideContent,
  hideTriggers,
  hideConnection,
  closeDetails,
  getCampaigns,
}) {
  const flags = useFlags();

  const classes = useStyles();
  const dispatch = useDispatch();
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const [isDataValid, setIsDataValid] = React.useState(false);
  const [data, setData] = React.useState({});
  const [warnings, setWarnings] = React.useState({
    emptyIds: false,
    overLimitIds: false,
  });
  const maximumGuestsSelected = flags.enableIncreasedCrmGuestLimit ? 500 : 50;
  const [showCancelConfirmation, setShowCancelConfirmation] =
    React.useState(false);
  const [redirect, setRedirect] = React.useState(null);
  const [updateSession, setUpdateSession] = React.useState(null);
  const [loading, setLoading] = React.useState({active: false, reason: ""});
  const [name, setName] = React.useState("");
  const [date, setDate] = React.useState(null);

  const [, setSaveDisabled] = React.useState(false);
  const create = !selectedCampaign;
  const {t} = useTranslation();

  React.useEffect(() => validateData(data), [connectedIds, name, date]);

  React.useEffect(() => {
    const today = new Date();
    today.setDate(today.getDate() + 7);
    setDate(
      create
        ? today
        : new Date(
            parseInt(
              selectedCampaign?.actions[0]?.trigger_value ?? today.getTime(),
            ),
          ),
    );
    setName(selectedCampaign?.name ?? "");
  }, [selectedCampaign, create]);

  const validateData = (newData) => {
    const isValidData =
      !!name.trim() &&
      isValid(date) &&
      !!connectedIds.length &&
      !!newData.actions?.every((step) => !!step.actions?.length);

    const newWarnings = {
      emptyIds: connectedIds.length === 0,
      overLimitIds: connectedIds.length > maximumGuestsSelected,
    };
    setWarnings(newWarnings);

    if (isDataValid !== isValidData) setIsDataValid(isValidData);
  };

  const handleDelete = (confirm) => {
    setLoading({active: true, reason: "deleting"});
    dispatch(
      deleteExperience({
        experienceId: data.experience_id,
        experience_type: "cmp",
        deleteScheduled: true,
        updateId:
          !!confirm && !!updateSession
            ? updateSession.update_session.update_id
            : null,
        onSuccess: () => {
          setLoading({active: false, reason: "deleting"});
          setUpdateSession(null);
          closeDetails(true);
          getCampaigns();
        },
        onError: () => {
          setLoading({active: false, reason: "deleting"});
          setUpdateSession(null);
        },
        onUpdate: !confirm
          ? (update) => {
              setUpdateSession(() => update);
              setLoading({active: false, reason: "deleting"});
            }
          : null,
      }),
    );
  };

  const handleSave = (saveUpdate) => {
    setIsDataValid(false);
    setLoading({active: true, reason: "saving"});
    const newData = {
      experience: {
        ...data,
        name,
        enabled: true,
        experience_type: "cmp",
        connected_to: {object: "guest", ids: connectedIds},
        actions: [
          {
            ...data.actions[0],
            trigger: "specific_time",
            trigger_value: date.getTime(),
          },
        ],
      },
    };

    if (saveUpdate && updateSession)
      newData.update_id = updateSession.update_session.update_id;
    if (data.experience_id) newData.experience_id = data.experience_id;
    // if (newData.experience.experience_id) addCampaign(newData.experience);

    dispatch(
      updateExperience({
        body: newData,
        type: "campaign",
        onSuccess: () => {
          setLoading({active: false, reason: "saving"});
          setUpdateSession(null);
          getCampaigns();
        },
        onError: () => {
          setLoading({active: false, reason: "saving"});
          setUpdateSession(null);
        },
        onUpdates: !saveUpdate
          ? (update) => {
              setUpdateSession(update);
              setLoading({active: false, reason: "saving"});
            }
          : null,
      }),
    );
  };

  const getErrors = () => {
    return (selectedCampaign?.errors || []).map((err, i) => (
      <WarningAlert
        key={`${err.title}-${i}`}
        title={err.title}
        subtitle={err.message}
        severity={err.severity}
        actionButtonLabel={err.path_to_resolution ? "Resolve" : null}
        action={
          err.path_to_resolution
            ? () => setRedirect(err.path_to_resolution)
            : null
        }
      />
    ));
  };

  if (!!redirect) {
    if (redirect.slice(0, 4) === "http") {
      window.location.href = redirect;
    } else {
      return <Redirect push to={`/admin/${redirect}`} />;
    }
  }

  return (
    <div className={classes.root}>
      {!!updateSession && (
        <ConfirmDialog
          disableDismiss
          open={!loading.active}
          onClose={() => {
            setUpdateSession(null);
          }}
          title={t("confirm-updates-crm")}
          message={
            <>
              {t("confirm-updates-crm-desc")}
              <UpdateSession updates={updateSession.update_session.updates} />
            </>
          }
          confirmLabel={t("confirm-updates-crm-confirm")}
          confirmAction={() => {
            if (loading.reason === "deleting") {
              handleDelete(true);
            } else if (loading.reason === "saving") {
              handleSave(true);
            }
          }}
          cancelLabel={t("confirm-updates-crm-cancel")}
          cancelAction={() => {
            setUpdateSession(null);
          }}
        />
      )}
      <FullLoader
        open={loading.active}
        disableDismiss
        onClose={() => {
          setLoading({active: false, reason: "saving"});
        }}
        loadingText={
          loading.reason === "deleting"
            ? t("deleting-campaign-loading-text")
            : t("saving-campaign-loading-text")
        }
      />
      <ConfirmDialog
        disableDismiss
        open={showCancelConfirmation}
        onClose={() => {
          setShowCancelConfirmation(false);
        }}
        title={t("quit-editing-crm-modal")}
        message={t("quit-editing-crm-modal-message")}
        confirmLabel={t("quit-editing-crm-modal-confirm")}
        confirmAction={() => {
          closeDetails();
        }}
        cancelLabel={t("quit-editing-crm-modal-cancel")}
        cancelAction={() => {
          setShowCancelConfirmation(false);
        }}
      />
      <Slide direction="left" in unmountOnExit mountOnEnter timeout={0}>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.column}>
            <CustomCardHeader
              title={t("crm-campaign-editor-title")}
              type="header"
              className={classes.cardHeader}
              action={
                <>
                  <Button
                    className="mr-2"
                    classes={{label: classes.cancelBtn}}
                    size="small"
                    onClick={() => {
                      setShowCancelConfirmation(true);
                    }}
                  >
                    Cancel
                  </Button>
                  {!create && (
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.errorButton}
                      onClick={() => handleDelete(false)}
                    >
                      Delete
                    </Button>
                  )}
                  <PrimaryButton
                    size="small"
                    label="Publish"
                    className="mr-2"
                    disabled={
                      !isDataValid || user_profile.scopes?.crm === "read"
                    }
                    onClick={() => handleSave()}
                    id="button-save-experience"
                  />
                </>
              }
            />
            <div className={classes.content}>
              {create && (
                <>
                  <WarningAlert
                    hide={!warnings.emptyIds}
                    title={t("crm-campaign-editor-empty-warning-title")}
                    subtitle={t("crm-campaign-editor-empty-warning-subtitle")}
                  />

                  <WarningAlert
                    hide={!warnings.overLimitIds}
                    title={t("crm-campaign-editor-over-warning-title")}
                    subtitle={t("crm-campaign-editor-over-warning-subtitle")}
                  />
                </>
              )}
              {getErrors()}
              <div className={clsx(classes.container, "mb-3")}>
                <Typography color="textSecondary" className="mb-3">
                  {t("crm-campaign-editor-connected-guests")}{" "}
                  {connectedIds.length}
                </Typography>
                <FilledTextField
                  fullWidth
                  value={name}
                  className="mb-3"
                  label={t("crm-campaign-editor-campaign-name")}
                  onChange={(e) => setName(e.target.value)}
                />
                <DateTimePicker
                  value={date}
                  filled
                  label={t("crm-campaign-editor-campaign-sendtime")}
                  onChange={setDate}
                />
              </div>
              <ExperienceBuilder
                type="instant"
                experience={selectedCampaign}
                hideConnection={hideConnection}
                hideContent={hideContent}
                hideTriggers={hideTriggers}
                setSaveDisabled={setSaveDisabled}
                setData={(newData) => {
                  validateData(newData);
                  setData(newData);
                }}
              />
            </div>
          </div>
        </Paper>
      </Slide>
    </div>
  );
}
