import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
// UI
import {Paper, Slide} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import ServiceInfoDialog from "components/Dialogs/ServiceInfoContent";
import CustomCardHeader from "core/cards/CustomCardHeader";
import PrimaryButton from "core/buttons/PrimaryButton";
import DeviceList from "../../Lists/DeviceList";
import CloseIconButton from "core/buttons/CloseIconButton";
import DevicePanel from "./DevicePanel";
// Actions
import {getDevices} from "redux/actions/devicesActions";
// Utilities
import {addonServices} from "configuration/enums";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    position: "relative",
  },
  column: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    overflowY: "auto",
  },
  content: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: "1px solid #0D568C0D",
    position: "relative",
  },
}));

const ManageDevicesPanel = ({listingId, goBack, onClose}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.defaultReducer.current_user);
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const [selectedDevice, setSelectedDevice] = React.useState(null);
  const [devices, setDevices] = React.useState([]);
  const [redirectPage, setRedirectPage] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const readOnly = user_profile.scopes?.smart_devices !== "write";

  React.useEffect(() => {
    if (!listingId || !currentUser) {
      return;
    }
    dispatch(
      getDevices(
        `enso_key=${currentUser}&listing_id=${listingId}`,
        (newDevices) => {
          setDevices(newDevices);
        },
      ),
    );
  }, [listingId, currentUser]);

  const handleDeviceUpdate = (newDevice) => {
    if (!newDevice.listing_ids.includes(listingId)) {
      removeDevice(newDevice);
    } else {
      setDevices((prev) =>
        prev.map((d) => {
          if (d.device_id === newDevice.device_id) {
            return newDevice;
          } else {
            return d;
          }
        }),
      );
    }
    handleCloseDetails();
  };

  const removeDevice = (device) => {
    setDevices((prev) => prev.filter((d) => d.device_id !== device.device_id));
  };

  const handleSelectDevice = (device) => {
    setSelectedDevice((prev) => device);
  };

  const handleCloseDetails = () => {
    setSelectedDevice((prev) => null);
  };

  const handleEnable = () => {
    setRedirectPage("adddevices");
  };

  const validateDeviceService = () => {
    if (!user_profile?.connected_services?.includes("sl")) {
      setShowDialog((prev) => true);
    } else {
      setRedirectPage("adddevices");
    }
  };

  const deviceDialog = (
    <ServiceInfoDialog
      open={showDialog}
      service={addonServices.seam[0]}
      headerIcon={addonServices.seam[0].icon_url}
      dialogBGImage={addonServices.seam[0].cover_photo_url}
      handleEnable={handleEnable}
      onClose={() => setShowDialog((prev) => false)}
      dialogBGColors={THEME.seam ?? null}
    />
  );

  if (!!redirectPage) {
    return <Redirect push to={`/admin/${redirectPage}`} />;
  }

  return (
    <div className={clsx(classes.column, classes.root)}>
      {deviceDialog}
      <Slide
        direction="right"
        in={!selectedDevice}
        unmountOnExit
        mountOnEnter
        timeout={0}
      >
        <Paper elevation={0} className={classes.paper}>
          <CustomCardHeader
            title="Manage smart home devices"
            className={classes.header}
            action={
              <>
                {!readOnly && (
                  <PrimaryButton
                    size="small"
                    label="Add Device"
                    className="mr-3"
                    onClick={validateDeviceService}
                  />
                )}
                <CloseIconButton sm onClick={goBack ?? onClose} />
              </>
            }
          />
          <div className={classes.content}>
            <DeviceList
              includeHeader={false}
              disableEdit={!!readOnly}
              initialDevices={devices}
              onDeviceSelect={handleSelectDevice}
              useOnePanelStyle
            />
          </div>
        </Paper>
      </Slide>
      <Slide
        direction="left"
        in={!!selectedDevice}
        unmountOnExit
        mountOnEnter
        timeout={0}
      >
        <Paper elevation={0} className={classes.paper}>
          {!!selectedDevice && (
            <>
              <DevicePanel
                showBack
                disableEdit={!!readOnly}
                selectedDevice={selectedDevice}
                onDeviceEdit={handleDeviceUpdate}
                removeDevice={removeDevice}
                handleClose={handleCloseDetails}
              />
            </>
          )}
        </Paper>
      </Slide>
    </div>
  );
};

export default ManageDevicesPanel;
