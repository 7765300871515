import {makeStyles} from "@material-ui/core";
import defaultHeaderBG from "assets/img/product_header_bg.jfif";

const useTemplateCardStyles = makeStyles((theme) => ({
  card: {
    border: "1px solid #F5F5F5",
    backgroundColor: "#F5F5F5",
    boxShadow: "none",
    width: "100%",
  },
  cardHeader: {
    padding: theme.spacing(4),
    height: 100,
    fontWeight: 500,
    borderRadius: "10px 10px 0 0",
    background: (props) =>
      !!props.bgImage
        ? `linear-gradient(0deg, rgba(245, 245, 245, 0.90) 0%, rgba(245, 245, 245, 0.90) 100%), url(${props.bgImage}), lightgray 50% / cover no-repeat`
        : `linear-gradient(0deg, rgba(245, 245, 245, 0.90) 0%, rgba(245, 245, 245, 0.90) 100%), url(${defaultHeaderBG}), lightgray 50% / cover no-repeat`,
    backgroundPosition: (props) => `${props.bgPosition} !important`,
    backgroundSize: "cover !important",
  },
  cardContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  cardActions: {
    backgroundColor: "#FFF",
    padding: theme.spacing(4),
    justifyContent: "flex-end",
  },
  titleRow: {
    fontWeight: 500,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  subheader: {fontWeight: 500},
  icon: {
    width: 24,
    height: 24,
    backgroundColor: theme.palette.text.primary,
    borderRadius: "50%",
    padding: theme.spacing(1),
  },
  categoryIcon: {fontSize: 32},
  section: {whiteSpace: "pre-wrap"},
  listItem: {paddingLeft: 0},
  listDivider: {borderBottom: "1px solid #0D568C1A"},
  satisfiedIcon: {color: "#9AD4D6"},
  row: {
    display: "flex",
    flexDirection: "row",
  },
  bold: {fontWeight: 500},
  iconsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  addonServicesContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
}));

export default useTemplateCardStyles;
