import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import Link from "@material-ui/core/Link";
import Alert from "@material-ui/lab/Alert";
import {Col, Container, Row} from "reactstrap";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "100vh",
  },
  col: {
    padding: 0,
    overflow: "hidden",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  logo: {
    width: "150px",
  },
  button: {
    width: "220px !important",
  },
  card: {
    width: "80vw",
    maxWidth: "600px",
    boxShadow: "none",
    border: "none",
  },
}));

export default function Verification({
  handleChange,
  handleSubmit,
  code,
  loading,
  signup,
  error,
  password,
}) {
  const classes = useStyles();

  return (
    <Grid
      container
      style={{marginTop: "15%"}}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Card className={classes.card}>
          <CardHeader title="Enter Verification Code and a New Password" />

          <CardContent>
            {error && <Alert severity="error">{`ERROR: ${error}`}</Alert>}
            {!signup && !error && (
              <Alert severity="success">
                An email was sent to your account.
              </Alert>
            )}
          </CardContent>

          <CardHeader
            title={
              <TextField
                fullWidth
                label="Verification Code"
                placeholder="Enter Verification Code..."
                id="code"
                value={code}
                onChange={handleChange}
              />
            }
          />
          {!signup && (
            <CardHeader
              title={
                <TextField
                  fullWidth
                  type="password"
                  label="Password"
                  placeholder="Enter New Password..."
                  id="password"
                  value={password}
                  onChange={handleChange}
                />
              }
            />
          )}
        </Card>
      </Grid>
      <Grid item className="mt-4">
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}
