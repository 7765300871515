import React, {Fragment, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Storage} from "aws-amplify";
// UI
import {
  CardContent,
  IconButton,
  ListItem,
  makeStyles,
  MenuList,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Close";
import CloseIcon from "@material-ui/icons/Close";
// Custom
import ImageDialog from "core/dialogs/ImageDialog";
import UploadImages from "components/Misc/UploadImages";
import ErrorAlert from "core/alerts/ErrorAlert";
import DraggableLayout from "components/Grids/DraggableLayout";
import ListingContentList from "../../Lists/ListingContentList";
import CustomCardHeader from "core/cards/CustomCardHeader";
import BackButton from "core/buttons/BackButton";
import CustomMenu from "core/menus/CustomMenu";
import CloseIconButton from "core/buttons/CloseIconButton";
import {EmptyPictures} from "components/Helpers/EmptyPanels";
// Actions
import {updateListing} from "redux/actions/listingsActions";
// Utils
import {getFileErrorMessage} from "utilities/formatUtilities";
import {asyncLoop, newUuid} from "utilities/helperFunctions";
import config, {THEME} from "configuration/settings.js";
import clsx from "clsx";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.primary.main + "0D"}`,
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: theme.spacing(5),
    position: "relative",
  },
  closeBtn: {
    padding: 2,
    marginLeft: theme.spacing(3),
    marginRight: -theme.spacing(2),
  },
  closeBtnIcon: {
    fontSize: 18,
    color: "#AAA",
  },
  icon: {
    backgroundColor: "rgba(255,255,255,0.5)",
    padding: 8,
    margin: 3,
    outline: "none!important",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.7)",
    },
  },
  imgContainer: {
    width: "100%",
    height: "100%",
    minHeight: 150,
    maxHeight: 300,
    borderRadius: 10,
    position: "relative",
    overflow: "hidden",
    backgroundColor: "#E0E0E0",
  },
  img: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
    objectFit: "cover",
    backgroundColor: theme.palette.common.white,
  },
  layer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: 10,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    background: "transparent",
    "& > .img-icon": {
      zIndex: -1,
      opacity: 0,
    },
    "&:hover": {
      background: `linear-gradient(to bottom, ${theme.palette.action.active} 10%, transparent)`,
      "& > .img-icon": {
        zIndex: 0,
        opacity: 1,
      },
    },
  },
  saveBtn: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  mainImg: {
    width: "100%",
    height: 200,
    borderRadius: 10,
    objectFit: "cover",
  },
  imagesContainer: {
    margin: -24,
    paddingTop: theme.spacing(3),
  },
  modal: {
    position: "absolute",
    backgroundColor: "#FFF",
    boxShadow:
      "0px 10px 20px rgba(32, 37, 38, 0.1), 0px 20px 50px rgba(32, 37, 38, 0.1)",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "fit-content",
    width: "fit-content",
    maxHeight: "80%",
    maxWidth: "80%",
    minWidth: "60%",
    borderRadius: 6,
    overflow: "auto",
  },
  cardHeader: {padding: theme.spacing(4)},
  cardContent: {
    padding: theme.spacing(0, 4, 4) + "!important",
    display: "grid",
    gap: theme.spacing(2),
    gridTemplateColumns: "repeat(4,1fr)",
  },
  listingImg: {
    borderRadius: 10,
    width: 200,
    height: 130,
    objectFit: "cover",
    transition: "0.15s",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.9,
    },
  },
}));

export default function PicturesPanel({
  pictures,
  listing,
  outsideView,
  updateImages,
  isHouseContentView,
  disableEdit,
  onClose,
  goBack,
  isParentLoading,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const inputRef = React.useRef(null);
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const loading = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_content;
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState({show: false, message: ""});
  const [uploadingText, setUploadingText] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mainPicture, setMainPicture] = React.useState(listing?.picture);
  let noPictures = (!pictures || !pictures.length) && isHouseContentView;

  React.useEffect(() => {
    setMainPicture((prev) => listing?.picture);
  }, [listing]);

  const saveImages = (pics) => {
    if (isHouseContentView) {
      const body = {pictures: pics};
      dispatch(
        updateListing({
          body,
          listing,
          onSuccess: () => handleClose(),
          onError: () => handleClose(),
        }),
      );
    } else if (!!updateImages) {
      updateImages(pics);
      handleClose();
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = (image) => () => {
    image && setSelectedImage({...image});
    setOpenDialog(true);
  };

  const handleUploadImages = async (newImages) => {
    await asyncLoop(newImages, async (picture) => {
      const imageName =
        picture.imageName || `${current_user}/listing_content-${newUuid()}`;
      const response = await Storage.put(imageName, picture.file, {
        contentType: picture.file.type,
      });
      console.log("IMAGE UPLOADED", response);
    });
  };

  const handleSetLoading = (text) => {
    setUploadingText(text);
    setOpenDialog(true);
  };

  const handleErrorClose = () => {
    setError({show: false, message: ""});
  };

  const displayError = (picture, errorType, customMessage) => {
    let message = getFileErrorMessage({picture, errorType, customMessage});
    setError({show: true, message});
  };

  const deletePicture = (picture_url) => () => {
    const newPictures = [...pictures];
    const imgIndex = newPictures.findIndex((i) => i.picture === picture_url);
    if (imgIndex === -1) {
      return false;
    }
    const imageToDelete = newPictures[imgIndex];
    const imageName = imageToDelete.picture.substring(
      imageToDelete.picture.search(current_user),
    );
    // Storage.remove(imageName).catch(err => console.log('ERROR REMOVING PHOTO', err))
    newPictures.splice(imgIndex, 1);
    setPictures(newPictures);
  };

  const editPictureDescription = (picture) => {
    const newPictures = [...pictures];
    const imgIndex = newPictures.findIndex(
      (i) => i.picture === picture.picture,
    );
    if (imgIndex > -1) {
      return false;
    }
    newPictures[imgIndex] = picture;
    setPictures(newPictures);
  };

  function setPictures(updatedPictures) {
    const newImages = [];
    const formatted_pictures = _.map(updatedPictures, (p) => {
      if (!p.picture.startsWith("https://")) {
        const imageName = `${current_user}/listing_content-${newUuid()}`;
        newImages.push({...p, imageName});
        return {
          picture: !!p.file ? `${config.s3.URL}${imageName}` : "undefined",
          description: p.description,
        };
      } else {
        return p;
      }
    });
    if (newImages.length || !_.isEqual(pictures, formatted_pictures)) {
      handleUploadImages(newImages);
      saveImages(formatted_pictures);
    }
  }

  const renderItem = (img) => {
    return (
      <div className={classes.imgContainer}>
        {!disableEdit && (
          <div className={classes.layer}>
            <IconButton
              className={clsx(classes.icon, "img-icon")}
              title="Edit"
              onClick={handleOpenDialog(img)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              className={clsx(classes.icon, "img-icon")}
              title="Remove"
              onClick={deletePicture(img.picture)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        )}
        <img
          alt={img.description[0]?.value || ""}
          src={img.picture}
          className={classes.img}
        />
      </div>
    );
  };

  const handleCloseMenu = () => {
    setAnchorEl((prev) => null);
  };

  function editListingImage(picture) {
    dispatch(updateListing({body: {picture: picture}, listing}));
  }

  function handlePictureToggle(picture) {
    setMainPicture((prev) => picture);
    setOpenDialog((prev) => false);
    setUploadingText((prev) => false);
    editListingImage(picture);
  }

  const handleUploadImage = async (file) => {
    const imageName = `${current_user}/listing-${listing.listing_id}/listing_main-image-${newUuid()}`;
    await Storage.put(imageName, file, {contentType: file.type});
    const link = `${config.s3.URL}${imageName}`;
    handlePictureToggle(link);
  };

  const fileUploaded = (e) => {
    handleSetLoading("Uploading image...");
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () => {
      handleUploadImage(file);
    };
    reader.readAsDataURL(file);
  };

  const uploadMenu = (
    <CustomMenu
      transformOrigin="right top"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleCloseMenu}
      content={
        <MenuList>
          <ListItem
            button
            disableRipple
            onClick={() => {
              inputRef.current.click();
              handleCloseMenu();
            }}
          >
            Upload Photo
          </ListItem>
          <ListItem
            button
            disableRipple
            disabled={noPictures}
            onClick={() => {
              setModalOpen(true);
              handleCloseMenu();
            }}
          >
            Select from existing photos
          </ListItem>
        </MenuList>
      }
    />
  );

  const pictureModal = (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
      <Paper elevation={0} className={classes.modal}>
        <CustomCardHeader
          type="title"
          title="Select an image"
          className={classes.cardHeader}
          action={<CloseIconButton onClick={() => setModalOpen(false)} />}
        />
        <CardContent className={classes.cardContent}>
          {listing?.listing_content?.pictures.map((p, i) => (
            <img
              key={p.picture ?? i}
              src={p.picture}
              className={classes.listingImg}
              onClick={() => {
                setModalOpen((prev) => false);
                handlePictureToggle(p.picture);
              }}
            />
          ))}
        </CardContent>
      </Paper>
    </Modal>
  );

  const mainImage = (
    <div className={classes.imgContainer}>
      <input
        ref={inputRef}
        style={{display: "none"}}
        type="file"
        accept="image/*"
        onChange={fileUploaded}
      />
      <div className={classes.layer}>
        <IconButton
          className={clsx(classes.icon, "img-icon")}
          title="Edit"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <EditIcon />
        </IconButton>
      </div>
      <img alt="Main image" src={mainPicture} className={classes.mainImg} />
    </div>
  );

  const title = (
    <CustomCardHeader
      title={
        !!goBack ? (
          <BackButton
            header={!isMobileView}
            color={!!isMobileView ? "secondary" : "primary"}
            goBack={goBack}
          />
        ) : (
          "Pictures"
        )
      }
      className={classes.header}
      action={
        !goBack && (
          <IconButton className={classes.closeBtn} onClick={onClose}>
            <CloseIcon className={classes.closeBtnIcon} />
          </IconButton>
        )
      }
    />
  );

  const outsideTitleRow = (
    <ListingContentList
      listingId={listing?.listing_id}
      onlyItems={["pictures"]}
      disableBackground
    />
  );

  return (
    <div className={classes.root}>
      {isHouseContentView && title}
      {isHouseContentView && !!outsideView && outsideTitleRow}
      <div className={classes.content}>
        <ImageDialog
          open={openDialog}
          currentUser={current_user}
          simpleEditView={!isHouseContentView}
          uploadingText={uploadingText}
          pictures={pictures}
          selectedImage={selectedImage}
          detailsView={selectedImage !== null}
          //updateContent={updateContent}
          handleClose={handleClose}
          displayError={displayError}
          onSave={!isHouseContentView ? editPictureDescription : undefined}
        />
        <ErrorAlert
          open={error.show}
          onClose={handleErrorClose}
          message={error.message}
        />
        <div className={classes.gridContainer}>
          {!isHouseContentView ? (
            <Typography
              className="mt-2"
              variant="subtitle1"
              color="textSecondary"
            >
              Upload between 7 and 50 pictures
            </Typography>
          ) : undefined}
          <Fragment>
            {!disableEdit && (
              <UploadImages
                setLoading={handleSetLoading}
                pictures={pictures}
                disabled={isParentLoading || loading}
                setPictures={(p) => setPictures(p)}
                displayError={(picture, errorType, customMessage) =>
                  displayError(picture, errorType, customMessage)
                }
              />
            )}
            {noPictures && (isParentLoading || loading) ? (
              <EmptyPictures loading />
            ) : (
              <>
                {pictureModal}
                {uploadMenu}
                <Typography
                  variant="h1"
                  className={classes.subtitle}
                  style={{marginBottom: THEME.spacing.sm}}
                >
                  Main image
                </Typography>
                {mainImage}
                {noPictures ? (
                  <EmptyPictures />
                ) : (
                  <>
                    <Typography
                      variant="h1"
                      className={classes.subtitle}
                      style={{marginTop: THEME.spacing.xl}}
                    >
                      Additional images
                    </Typography>
                    {!!pictures.length && (
                      <Typography color="textSecondary">
                        {`Uploaded pictures: ${pictures.length}`}
                      </Typography>
                    )}
                    <div className={classes.imagesContainer}>
                      <DraggableLayout
                        disableDrag={disableEdit}
                        items={pictures}
                        renderItem={renderItem}
                        setNewOrder={(p) => setPictures(p)}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </Fragment>
        </div>
      </div>
    </div>
  );
}
