import React from "react";
import {isMobile} from "react-device-detect";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {Col, Container, Row} from "reactstrap";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import LinearProgress from "@material-ui/core/LinearProgress";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "100vh",
  },
  img_col: {
    padding: 0,
    maxHeight: "100vh",
    overflow: "hidden",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  logo: {
    width: "230px",
  },
  button: {
    width: "210px !important",
  },
  login_col: {
    display: "grid",
    maxHeight: "100vh",
    overflow: "auto",
  },
  token: {
    color: "lightgrey",
    fontWeight: 100,
    fontSize: 10,
    overflowWrap: "anywhere",
  },
  hiddenToken: {visibility: "hidden"},
  tokenBtn: {
    marginTop: 10,
    padding: "4px 0px",
    color: "lightgrey",
    fontSize: 12,
  },
}));

export default function LoginForm({
  loading,
  handleChange,
  wrong_user,
  wrong_pass,
  handleSubmit,
  network,
  email,
}) {
  const classes = useStyles();
  const [showToken, setShowToken] = React.useState(false);
  const [token, setToken] = React.useState("");

  React.useEffect(() => {
    // Get device token
    if (!!window.deviceToken) {
      setToken(window.deviceToken);
    }
  }, window.deviceToken);

  const handleKeyUp = (e) => {
    if (["Enter"].includes(e.key)) handleSubmit(e);
  };

  return (
    <Container fluid>
      <Row style={{minHeight: "100vh", maxHeight: "100vh"}}>
        <Col className={classes.img_col}>
          <img
            src={require("assets/img/enso_login_image.jpg")}
            alt="Login Image"
            className={classes.image}
          />
        </Col>
        <Col className={classes.login_col}>
          {loading && (
            <LinearProgress
              style={{position: "absolute", top: 0, width: "100%"}}
            />
          )}
          <Grid
            container
            style={{margin: "1rem 0"}}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                alt="Enso Connect logo"
                className={classes.logo}
                src="https://enso-image-assets.s3.amazonaws.com/enso_blue_logo.svg"
              />
            </Grid>
            <Grid item>
              <TextField
                type="username"
                label="Username"
                variant="outlined"
                className={"mt-5"}
                value={email}
                onKeyUp={handleKeyUp}
                onChange={handleChange}
                error={wrong_user}
                id="email"
                helperText={wrong_user ? "incorrect username" : " "}
              />
            </Grid>
            <Grid item>
              <TextField
                type="password"
                label="Password"
                variant="outlined"
                className={"mt-2"}
                onKeyUp={handleKeyUp}
                onChange={handleChange}
                error={wrong_pass}
                id="password"
                helperText={wrong_pass ? "incorrect password" : " "}
              />
            </Grid>
            <Grid item>
              {network && (
                <div
                  style={{
                    color: "red",
                    fontSize: "15px",
                    paddingBottom: "10px",
                  }}
                >
                  No internet connection.
                </div>
              )}
            </Grid>
            <Grid item>
              <Button
                id="signin"
                disableElevation
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={handleSubmit}
              >
                Login
              </Button>
            </Grid>
            <Grid item className="mt-3">
              <Typography align="center">
                <Link href="/auth/reset">Forgot password?</Link>
                {!isMobile && (
                  <>
                    <span style={{display: "inline", margin: "5px"}}>·</span>
                    <Link href="https://www.ensoconnect.com/contact">
                      {" "}
                      {/*auth/register/selectaccount*/}
                      Sign Up
                    </Link>
                  </>
                )}
              </Typography>
              {!!isMobile && (
                <>
                  <div style={{width: "100%", textAlign: "center"}}>
                    <Button
                      disableElevation
                      variant="text"
                      className={classes.tokenBtn}
                      color="default"
                      onClick={() => setShowToken((prev) => !prev)}
                    >
                      {!showToken ? "Show token" : "Hide token"}
                    </Button>
                  </div>
                  <label
                    className={clsx(classes.token, {
                      [classes.hiddenToken]: !showToken,
                    })}
                  >
                    {token}
                  </label>
                </>
              )}
            </Grid>
          </Grid>
        </Col>
      </Row>
    </Container>
  );
}
