import {makeStyles} from "@material-ui/core";
import {THEME} from "configuration/settings";

const useLanguageSelectorStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
  },
  filled: {
    backgroundColor: "rgba(13, 86, 140, 0.1) !important",
    borderRadius: 25,
    padding: "5px 16px",
    fontWeight: 600,
    lineHeight: "17px",
  },
  expandBtn: {
    border: `1px solid ${THEME.text} !important`,
    color: THEME.text,
    padding: theme.spacing(1, 3),
    "&:hover": {backgroundColor: THEME.text + "0d"},
    "&.light": {
      border: `1px solid ${theme.palette.common.white} !important`,
      color: theme.palette.common.white,
      "&:hover": {backgroundColor: "transparent"},
    },
  },
  list: {padding: theme.spacing(0, 3, 3) + "!important"},
  search: {
    zIndex: 2,
    position: "sticky",
    top: 0,
    minWidth: 200,
    backgroundColor: "#FFF !important",
    padding: theme.spacing(3, 0, 0),
    "&.-modal-item": {padding: theme.spacing(4, 0, 0)},
  },
  searchInputIcon: {
    color: "rgba(60, 60, 67, 0.6)",
    position: "absolute",
    bottom: 9,
    left: 7,
    width: 18,
    height: 18,
  },
  searchInput: {
    border: "none",
    borderRadius: 10,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "20px",
    padding: theme.spacing(2, 3),
    paddingLeft: 28,
    outline: "none",
    width: "100%",
    backgroundColor: "#F5F5F5",
  },
  noResultsMessage: {
    padding: theme.spacing(2, 3),
    "&.-modal-item": {padding: theme.spacing(2, 0)},
  },
  menuItem: {
    minWidth: 200,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    border: "1px solid #F0F0F0",
    borderRadius: 5,
  },
  secondaryAction: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  disabledMenuItem: {
    ...theme.typography.subtitle2,
    color: THEME.subdued,
    padding: 0,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  modalContent: {
    width: 350,
    padding: theme.spacing(0, 4, 4) + "!important",
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  modalContentItem: {padding: theme.spacing(2, 0)},
}));

export default useLanguageSelectorStyles;
