import {SET_CRM_LOADING_HITS, ADD_EXPORT_CRM} from "../actionTypes";

const initial_state = {
  report_list: [],
  reports: {},
  selected_report_id: null,
  dashboard_report: {},
  export_reports: {},
  loadingHits: false,
};

export default function crmReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_CRM_LOADING_HITS:
      return {...state, loadingHits: action.loading};
    case ADD_EXPORT_CRM:
      const reportData = state.reports[action.reportId];
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.reportId]: {
            ...reportData,
            [action.contentType]: action.data,
          },
        },
      };

    default:
      return state;
  }
}
