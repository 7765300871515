import React, {useState} from "react";
import {Redirect} from "react-router";
import {useSelector, useDispatch} from "react-redux";
import {isMobile} from "react-device-detect";
import {setSelectedViewItem} from "redux/actions/accountsActions";
// UI
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import useCTAPanelStyles from "styles/useCTAPanelStyles";
import CircleIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckIcon from "@material-ui/icons/CheckCircle";
// Custom
import {LoadingCTAs} from "components/Helpers/EmptyPanels";
import {useTranslation} from "react-i18next";
import PrimaryButton from "core/buttons/PrimaryButton";
import EnsoAILabel from "components/Misc/EnsoAILabel";
// Utils
import clsx from "clsx";

export default function CTAPanel({setCreateListingGroupOpen}) {
  const classes = useCTAPanelStyles();
  const groups = useSelector((state) => state.defaultReducer.listing_groups);
  const userProfile = useSelector((state) => state.defaultReducer.user_profile);
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  const [redirectPage, setRedirectPage] = useState(null);
  const dispatch = useDispatch();
  const start_keys = useSelector((state) => state.defaultReducer.start_keys);
  let inactive_group =
    groups.find((g) => g.group_id != "ALL" && !g?.enabled) ||
    groups.find((g) => g.group_id != "ALL" && g?.enabled);
  const {t} = useTranslation();
  let syncInProgress =
    serviceAccounts?.sync_in_progress?.action === "SYNC_LISTINGS";

  const {hasStripeAccount, hasDeviceAccount} = React.useMemo(() => {
    return {
      hasStripeAccount: serviceAccounts?.accounts?.stripe?.length > 0,
      hasDeviceAccount: !!serviceAccounts.seam_client_session_id,
    };
  }, [serviceAccounts]);

  function hasEnabledFeature(feature) {
    return !!groups.find((g) => g.section_progress?.[feature] === "enabled");
  }

  const handleBtnClick = (articleId) => () => {
    console.log("articleId:", articleId);
    if (!!window.Intercom && !isMobile) {
      window.Intercom("showArticle", articleId);
    }
  };

  const redirectToGJE = (route) => {
    setRedirectPage(
      `listings/groups/${inactive_group?.group_id}/${route ?? ""}`,
    );
  };

  function ActionItemCard({text, subtitle, completed, onClick, disabled}) {
    return (
      <ListItem
        disabled={disabled}
        onClick={onClick}
        button
        style={{display: "flex", marginBottom: 2, borderRadius: 15}}
      >
        <div style={{marginRight: 5}}>
          {completed ? (
            <CheckIcon className={clsx(classes.icon, "checked")} />
          ) : (
            <CircleIcon className={classes.icon} />
          )}
        </div>
        <div>
          <Typography variant="subtitle2">{text}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {subtitle}
          </Typography>
        </div>
      </ListItem>
    );
  }

  function GJECard() {
    return (
      <Card className={classes.largeCard}>
        <div className={clsx(classes.col, classes.details)}>
          <Box alignItems="center" className={classes.row}>
            <Typography variant="h2" className={classes.cardTitle}>
              {t("cta-main-title")}
            </Typography>
          </Box>
          <Box mb={4} alignItems="center" className={classes.row}>
            <Typography variant="subtitle2" color="textSecondary">
              {t("cta-main-subtitle")}
            </Typography>
          </Box>
          <List>
            <ActionItemCard
              onClick={() => setRedirectPage("settings/users")}
              text="Invite your team"
              subtitle="Add team members & provide restricted access to co-hosts"
              completed={userProfile?.enso_users?.length > 1}
            />
            <ActionItemCard
              onClick={() => setRedirectPage("settings/branding")}
              text="Add your brand info"
              subtitle="Personalize your boarding pass & communications"
              completed={!!userProfile?.logo}
            />
            <ActionItemCard
              onClick={() => setCreateListingGroupOpen()}
              text="Create your listing groups"
              disabled={syncInProgress}
              subtitle={
                syncInProgress
                  ? "Please wait for listing sync to complete before accessing this step"
                  : "Organize your listings into groups based on location."
              }
              completed={groups?.length > 1}
            />
            <ActionItemCard
              onClick={() => redirectToGJE()}
              text="Build your first guest journey"
              disabled={syncInProgress || !inactive_group}
              subtitle={
                syncInProgress
                  ? "Please wait for listing sync to complete before accessing this step"
                  : !inactive_group
                    ? "Please create your listing groups before accessing this step"
                    : "Follow the steps in the Guest Journey Editor to build your Boarding Pass & enable automated messages."
              }
              completed={groups?.find((g) => !!g.enabled)}
            />
          </List>
        </div>
        <div className={classes.largeImageContainer}>
          <CardMedia
            className={clsx(classes.media, "large")}
            component="img"
            height={250}
            image={
              "https://enso-image-assets.s3.amazonaws.com/CTA+Images-Listings/OnBoarding+Welcome+Thumbnail.jpg"
            }
          />
        </div>
      </Card>
    );
  }

  function CardItem({image, type, title, subtitle, steps, aiLabel = false}) {
    return (
      <Card className={clsx(classes.col, classes.cardItem)}>
        <CardMedia
          className={classes.media}
          component="img"
          height={150}
          image={image}
        />
        <CardContent>
          <Box mb={4}>
            <div style={{display: "flex"}}>
              <Typography className={classes.cardTitle} variant="h2">
                {title}
              </Typography>
              {aiLabel && <EnsoAILabel />}
            </div>
            <Typography variant="subtitle2" color="textSecondary">
              {subtitle}
            </Typography>
          </Box>
          {steps}
        </CardContent>
      </Card>
    );
  }

  if (!!redirectPage) {
    if (redirectPage.includes("settings")) {
      dispatch(setSelectedViewItem("settings", null));
    }
    return <Redirect push to={`/admin/${redirectPage}`} />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        {start_keys.listings === null ? (
          <LoadingCTAs />
        ) : (
          <>
            <div className={classes.cardContainer}>
              {/*<Typography style={{color: 'white', fontWeight: 600}} variant='h2'>Get Started</Typography>*/}
              <GJECard />
              <div className={classes.featureContainer}>
                <Typography
                  style={{color: "white", fontWeight: 600}}
                  variant="h2"
                >
                  Explore & Set up
                </Typography>
                <div className={clsx(classes.row, "space")}>
                  <CardItem
                    title={t("label-personalized-upsells")}
                    type="step1"
                    image="https://enso-image-assets.s3.amazonaws.com/CTA+Images-Listings/Upsells+Image.jpg"
                    subtitle={t("subtitle-personalized-upsells")}
                    steps={
                      <>
                        <ActionItemCard
                          onClick={() =>
                            setRedirectPage(
                              "settings/accounts/:redirect/:redirect_status",
                            )
                          }
                          text={t("text-connect-create-stripe")}
                          subtitle={t("subtitle-connect-create-stripe")}
                          completed={hasStripeAccount}
                        />
                        <ActionItemCard
                          onClick={() => redirectToGJE("pci/upsells")}
                          text={t("text-enable-upsells")}
                          disabled={!hasStripeAccount || !inactive_group}
                          completed={hasEnabledFeature("pci_upsells")}
                          subtitle={
                            !hasStripeAccount
                              ? t("subtitle-connect-stripe")
                              : !inactive_group
                                ? t("subtitle-create-listing-group")
                                : t("subtitle-add-upsells")
                          }
                        />
                        <ActionItemCard
                          onClick={() => setRedirectPage("dashboard")}
                          text={t("text-approve-requests")}
                          subtitle={t("subtitle-approve-requests")}
                          completed={false}
                        />
                      </>
                    }
                  />
                  <CardItem
                    aiLabel
                    title={t("label-unified-inbox")}
                    image="https://enso-image-assets.s3.amazonaws.com/CTA+Images-Listings/Platform+-+CTA+Images+-+Unified+Inbox.jpg"
                    subtitle={t("subtitle-unified-inbox")}
                    type="step2"
                    steps={
                      <>
                        <ActionItemCard
                          text={t("text-setup-messaging")}
                          onClick={() => setRedirectPage("settings/messaging")}
                          subtitle={t("subtitle-connect-channels")}
                          completed={userProfile?.managed_emails?.length > 0}
                        />
                        <ActionItemCard
                          text={t("text-enable-auto-responses")}
                          onClick={redirectToGJE}
                          disabled
                          subtitle={t("subtitle-enable-auto-responses")}
                          completed={false}
                        />
                        <ActionItemCard
                          text={t("text-get-priority-notifications")}
                          onClick={redirectToGJE}
                          disabled
                          subtitle={t("subtitle-get-priority-notifications")}
                          completed={false}
                        />
                      </>
                    }
                  />
                </div>
                <div className={clsx(classes.row, "space")}>
                  <CardItem
                    aiLabel
                    title={t("label-guidebooks")}
                    subtitle={t("subtitle-enable-guidebooks")}
                    image="https://enso-image-assets.s3.amazonaws.com/CTA+Images-Listings/Guidebook+Image.jpg"
                    type="step2"
                    steps={
                      <>
                        <ActionItemCard
                          text={t("label-create-guidebooks")}
                          onClick={() => redirectToGJE("cu/guidebooks")}
                          subtitle={t("subtitle-create-guidebooks")}
                          completed={hasEnabledFeature("cu_guidebooks")}
                        />
                        <ActionItemCard
                          text={t("label-create-local-guidebooks")}
                          onClick={() => redirectToGJE("cu/guidebooks")}
                          subtitle={t("subtitle-create-local-guidebooks")}
                          completed={hasEnabledFeature("cu_guidebooks")}
                        />
                        <ActionItemCard
                          text={t("label-share-guidebooks")}
                          onClick={() => redirectToGJE("cnf/experiences")}
                          subtitle={t("subtitle-share-guidebooks")}
                          completed={hasEnabledFeature("cnf_experiences")}
                        />
                      </>
                    }
                  />
                  <CardItem
                    title={t("label-seamless-checkin")}
                    type="step1"
                    image="https://enso-image-assets.s3.amazonaws.com/CTA+Images-Listings/Check-in+Image.jpg"
                    subtitle={t("subtitle-seamless-checkin")}
                    steps={
                      <>
                        <ActionItemCard
                          onClick={() => setRedirectPage("adddevices")}
                          text={t("text-add-devices")}
                          subtitle={t("subtitle-add-devices")}
                          completed={hasDeviceAccount}
                        />
                        <ActionItemCard
                          onClick={() => setRedirectPage("settings/devices")}
                          text={t("text-pair-devices")}
                          disabled={!hasDeviceAccount || !inactive_group}
                          completed={hasDeviceAccount}
                          subtitle={
                            !hasDeviceAccount
                              ? t("subtitle-connect-devices-first")
                              : t("subtitle-pair-devices")
                          }
                        />
                        <ActionItemCard
                          onClick={redirectToGJE}
                          text={t("label-create-checkin-steps")}
                          disabled={!hasDeviceAccount || !inactive_group}
                          subtitle={
                            !hasDeviceAccount
                              ? t("subtitle-connect-devices-first")
                              : t("subtitle-add-to-checkin-steps")
                          }
                          completed={false}
                        />
                      </>
                    }
                  />
                </div>
                <div className={clsx(classes.row, "space")}>
                  <CardItem
                    title={t("label-guest-verification")}
                    subtitle={t("subtitle-guest-verification")}
                    image="https://enso-image-assets.s3.amazonaws.com/CTA+Images-Listings/Platform+-+CTA+Images+-+Verification.jpg"
                    type="step2"
                    steps={
                      <>
                        <ActionItemCard
                          text={t("label-select-verifier")}
                          onClick={() => redirectToGJE("cnf/boarding_pass")}
                          subtitle={t("subtitle-select-verifier")}
                          completed={hasEnabledFeature("cnf_boarding_pass")}
                        />
                        <ActionItemCard
                          text={t("label-collect-details")}
                          subtitle={t("subtitle-collect-details")}
                          onClick={() => redirectToGJE("cnf/boarding_pass")}
                          completed={hasEnabledFeature("cnf_boarding_pass")}
                        />
                        <ActionItemCard
                          text={t("label-setup-verification-reminders")}
                          onClick={() => redirectToGJE("pci/experiences")}
                          subtitle={t("subtitle-setup-verification-reminders")}
                          completed={hasEnabledFeature("pci_experiences")}
                        />
                      </>
                    }
                  />
                  <CardItem
                    aiLabel
                    title={t("label-enable-crm")}
                    type="step1"
                    image="https://enso-image-assets.s3.amazonaws.com/CTA+Images-Listings/Platform+-+CTA+Images+-+CRM.jpg"
                    subtitle={t("subtitle-enable-crm")}
                    steps={
                      <>
                        <ActionItemCard
                          text={t("text-explore-insights")}
                          subtitle={t("subtitle-explore-insights")}
                          onClick={() => setRedirectPage("admin/guests")}
                          //completed={hasStripeAccount}
                        />
                        <ActionItemCard
                          onClick={() => setRedirectPage("admin/guests")}
                          text={t("text-send-campaign")}
                          completed={false}
                          subtitle={t("subtitle-send-campaign")}
                        />
                        <ActionItemCard
                          onClick={() => setRedirectPage("admin/guests")}
                          text={t("text-export-data")}
                          subtitle={t("subtitle-export-data")}
                          completed={false}
                        />
                      </>
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
