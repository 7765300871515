import React from "react";
// UI
import {makeStyles} from "@material-ui/core/styles";
import {SvgIcon} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/PhoneIphone";
import IconButton from "@material-ui/core/IconButton";
import WhatsappIcon from "@material-ui/icons/WhatsApp";
import {ReactComponent as AirbnbIconOutlined} from "assets/icons/airbnb.svg";
import {ReactComponent as EmailIconOutlined} from "assets/icons/mail.svg";
import {ReactComponent as MessageIconOutlined} from "assets/icons/message.svg";
import {ReactComponent as WhatsappIconOutlined} from "assets/icons/whatsapp.svg";
import {ReactComponent as AirbnbIcon} from "assets/icons/Airbnb_Icon.svg";
import {ReactComponent as VrboIcon} from "assets/icons/Vrbo_Icon.svg";
import {ReactComponent as HomeawayIcon} from "assets/icons/homeaway_icon.svg";
import {ReactComponent as BookingcomIcon} from "assets/icons/bookingcom_icon.svg";
import {THEME} from "configuration/settings.js";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    padding: theme.spacing(0, 3),
    backgroundColor: theme.palette.grey[50],
    minWidth: "fit-content",
    marginLeft: 0,
  },
  buttonsContainerSecondary: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
    "&.-disabled": {opacity: 0.5},
  },
  sms: {color: THEME.sms},
  airbnb: {color: THEME.airbnb},
  email: {color: THEME.email},
  whatsapp: {color: THEME.whatsapp},
  iconBtn: {
    marginRight: theme.spacing(1),
    transition: "0.15s",
    padding: theme.spacing(3),
    "& .icon": {color: theme.palette.action.disabled},
    "&:hover": {
      "& .sms": {color: THEME.sms},
      "& .email": {color: THEME.email},
    },
    [theme.breakpoints.down("sm")]: {padding: theme.spacing(2, 3)},
  },
  iconBtnSecondary: {
    padding: 0,
    opacity: 0.5,
    transition: "0.15s",
    "&:hover": {opacity: 1},
    "&.-selected": {opacity: 1},
    "&.-disabled": {opacity: 0.5},
  },
  icon: {
    height: 22,
    width: 22,
    objectFit: "fill",
    cursor: "pointer",
    transition: "all 0.15s",
    [theme.breakpoints.down("sm")]: {
      height: 26,
      width: 26,
    },
  },
  iconSecondary: {
    height: 14,
    width: 14,
  },
  svgIconAirbnb: {
    height: 20,
    width: 20,
    transition: "all 0.15s",
    "&:hover": {color: THEME.airbnb},
    [theme.breakpoints.down("sm")]: {
      height: 22,
      width: 22,
    },
  },
  svgIconVrbo: {
    height: 20,
    width: 20,
    transition: "all 0.15s",
    "&:hover": {color: THEME.vrbo},
    [theme.breakpoints.down("sm")]: {
      height: 22,
      width: 22,
    },
  },
  svgIconHomeaway: {
    height: 20,
    width: 20,
    transition: "all 0.15s",
    "&:hover": {color: THEME.homeaway},
    [theme.breakpoints.down("sm")]: {
      height: 22,
      width: 22,
    },
  },
  svgIconBookingcom: {
    height: 20,
    width: 20,
    transition: "all 0.15s",
    "&:hover": {color: THEME.bookingcom},
    [theme.breakpoints.down("sm")]: {
      height: 22,
      width: 22,
    },
  },
  svgIconAirbnbColored: {color: THEME.airbnb},
  svgIconVrboColored: {color: THEME.vrbo},
  svgIconHomeawayColored: {color: THEME.homeaway},
  svgIconBookingcomColored: {color: THEME.bookingcom},
  svgIconSecondary: {
    height: 20,
    width: 20,
    "&.-lg": {
      height: 22,
      width: 22,
    },
  },
}));

export default function MessageSelector({
  selectChannel,
  slc_channel,
  email = true,
  SMS = true,
  airbnb = true,
  vrbo = true,
  homeaway = true,
  bookingcom = true,
  whatsapp = true,
  disabled,
  variant = "primary",
  readOnly = false,
  disabledChannels = [],
}) {
  const classes = useStyles();

  return variant === "secondary" ? (
    <div
      className={clsx(classes.buttonsContainerSecondary, {
        "-disabled": disabled,
      })}
    >
      {airbnb && ( // Airbnb
        <IconButton
          disabled={readOnly}
          onClick={() => selectChannel(1)}
          classes={{
            root: clsx(classes.iconBtnSecondary, {
              "-selected": !!readOnly || slc_channel === 1,
              "-disabled": !disabled && disabledChannels.includes("airbnb"),
            }),
          }}
        >
          <SvgIcon
            className={clsx(classes.svgIconSecondary, "-lg")}
            component={AirbnbIconOutlined}
            color={disabledChannels.includes("airbnb") ? "disabled" : "primary"}
          />
        </IconButton>
      )}
      {vrbo && ( // vrbo
        <IconButton
          disabled={readOnly}
          onClick={() => selectChannel(4)}
          classes={{
            root: clsx(classes.iconBtnSecondary, {
              "-selected": !!readOnly || slc_channel === 4,
              "-disabled": !disabled && disabledChannels.includes("vrbo"),
            }),
          }}
        >
          <SvgIcon
            className={clsx(classes.svgIconSecondary, "-lg")}
            component={VrboIcon}
            color={disabledChannels.includes("vrbo") ? "disabled" : "primary"}
          />
        </IconButton>
      )}
      {homeaway && ( // homeaway
        <IconButton
          disabled={readOnly}
          onClick={() => selectChannel(5)}
          classes={{
            root: clsx(classes.iconBtnSecondary, {
              "-selected": !!readOnly || slc_channel === 5,
              "-disabled": !disabled && disabledChannels.includes("homeaway"),
            }),
          }}
        >
          <SvgIcon
            className={clsx(classes.svgIconSecondary, "-lg")}
            component={HomeawayIcon}
            viewBox="15 44 15 12"
            color={
              disabledChannels.includes("homeaway") ? "disabled" : "primary"
            }
          />
        </IconButton>
      )}
      {bookingcom && ( // bookingcom
        <IconButton
          disabled={readOnly}
          onClick={() => selectChannel(6)}
          classes={{
            root: clsx(classes.iconBtnSecondary, {
              "-selected": !!readOnly || slc_channel === 6,
              "-disabled": !disabled && disabledChannels.includes("bookingcom"),
            }),
          }}
        >
          <SvgIcon
            className={clsx(classes.svgIconSecondary, "-lg")}
            component={BookingcomIcon}
            viewBox="8 6 15 15"
            color={
              disabledChannels.includes("bookingcom") ? "disabled" : "primary"
            }
          />
        </IconButton>
      )}
      {SMS && ( // SMS
        <IconButton
          disabled={readOnly}
          onClick={() => selectChannel(0)}
          classes={{
            root: clsx(classes.iconBtnSecondary, {
              "-selected": !!readOnly || slc_channel === 0,
              "-disabled": !disabled && disabledChannels.includes("SMS"),
            }),
          }}
        >
          <SvgIcon
            className={classes.svgIconSecondary}
            component={MessageIconOutlined}
            color={disabledChannels.includes("SMS") ? "disabled" : "primary"}
          />
        </IconButton>
      )}
      {email && ( // Email
        <IconButton
          disabled={readOnly}
          onClick={() => selectChannel(2)}
          classes={{
            root: clsx(classes.iconBtnSecondary, {
              "-selected": !!readOnly || slc_channel === 2,
              "-disabled": !disabled && disabledChannels.includes("email"),
            }),
          }}
        >
          <SvgIcon
            className={classes.svgIconSecondary}
            component={EmailIconOutlined}
            color={disabledChannels.includes("email") ? "disabled" : "primary"}
          />
        </IconButton>
      )}
      {whatsapp && ( // Whatsapp
        <IconButton
          disabled={readOnly}
          onClick={() => selectChannel(3)}
          classes={{
            root: clsx(classes.iconBtnSecondary, {
              "-selected": !!readOnly || slc_channel === 3,
              "-disabled": !disabled && disabledChannels.includes("whatsapp"),
            }),
          }}
        >
          <SvgIcon
            className={clsx(classes.svgIconSecondary, "-lg")}
            component={WhatsappIconOutlined}
            viewBox="0 0 24 24"
            color={
              disabledChannels.includes("whatsapp") ? "disabled" : "primary"
            }
          />
        </IconButton>
      )}
    </div>
  ) : (
    <div className={classes.buttonsContainer}>
      {airbnb && ( // Airbnb
        <IconButton
          className={classes.iconBtn}
          onClick={() => selectChannel(1)}
          disabled={disabled}
        >
          <SvgIcon
            className={clsx(classes.svgIconAirbnb, {
              [classes.svgIconAirbnbColored]: slc_channel === 1,
            })}
            component={AirbnbIcon}
            viewBox="0 0 25 26"
            color="disabled"
          />
        </IconButton>
      )}
      {vrbo && ( // vrbo
        <IconButton
          className={classes.iconBtn}
          onClick={() => selectChannel(4)}
          disabled={disabled}
        >
          <SvgIcon
            className={clsx(classes.svgIconVrbo, {
              [classes.svgIconVrboColored]: slc_channel === 4,
            })}
            component={VrboIcon}
            viewBox="0 0 25 26"
            color="disabled"
          />
        </IconButton>
      )}
      {homeaway && ( // homeaway
        <IconButton
          className={classes.iconBtn}
          onClick={() => selectChannel(5)}
          disabled={disabled}
        >
          <SvgIcon
            className={clsx(classes.svgIconHomeaway, {
              [classes.svgIconHomeawayColored]: slc_channel === 5,
            })}
            component={HomeawayIcon}
            viewBox="15 44 15 12"
            color="disabled"
          />
        </IconButton>
      )}
      {bookingcom && ( // bookingcom
        <IconButton
          className={classes.iconBtn}
          onClick={() => selectChannel(6)}
          disabled={disabled}
        >
          <SvgIcon
            className={clsx(classes.svgIconBookingcom, {
              [classes.svgIconBookingcomColored]: slc_channel === 6,
            })}
            component={BookingcomIcon}
            viewBox="8 6 15 15"
            color="disabled"
          />
        </IconButton>
      )}
      {SMS && ( // SMS
        <IconButton
          className={classes.iconBtn}
          onClick={() => selectChannel(0)}
          disabled={disabled}
        >
          <PhoneIcon
            className={clsx("sms", classes.icon, {
              [classes.sms]: slc_channel === 0,
            })}
            color="disabled"
          />
        </IconButton>
      )}
      {email && ( // Email
        <IconButton
          className={classes.iconBtn}
          onClick={() =>
            slc_channel === 2 ? selectChannel(-1) : selectChannel(2)
          }
          disabled={disabled}
        >
          <EmailIcon
            className={clsx("email", classes.icon, {
              [classes.email]: slc_channel === 2,
            })}
            color="disabled"
          />
        </IconButton>
      )}
      {whatsapp && ( // Whatsapp
        <IconButton
          className={classes.iconBtn}
          onClick={() => selectChannel(3)}
          disabled={disabled}
        >
          <WhatsappIcon
            className={clsx("whatsapp", classes.icon, {
              [classes.whatsapp]: slc_channel === 3,
            })}
            color="disabled"
          />
        </IconButton>
      )}
    </div>
  );
}
