import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 3),
    textTransform: "none",
    lineHeight: (props) => (!!props.sm ? "16px" : "20px"),
  },
  autoWidth: {width: "fit-content"},
  alignStart: {alignSelf: "flex-start"},
  alignEnd: {alignSelf: "flex-end"},
  rounded: {borderRadius: 999},
  disabled: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.common.white} !important`,
    opacity: 0.5,
  },
  subdued: {
    border: (props) =>
      props.variant !== "text"
        ? `1px solid ${THEME.subdued} !important`
        : "none",
    color: THEME.subdued,
    "&:hover": {backgroundColor: THEME.subdued + "0d"},
  },
  dark: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.common.white,
    border: "none !important",
    "&:hover": {backgroundColor: theme.palette.text.primary + "cc"},
    "&.disabled": {
      backgroundColor: theme.palette.text.primary + "!important",
      color: theme.palette.common.white,
      opacity: 0.5,
    },
  },
  light: {
    border: "none",
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
    "&:hover": {backgroundColor: "rgba(255, 255, 255, 0.85)"},
  },
  big: {
    fontSize: 20,
    padding: theme.spacing(2, 3),
    borderRadius: 999,
  },
}));

export default function PrimaryButton({
  label,
  id,
  big,
  variant = "contained",
  fullWidth,
  alignStart,
  alignEnd,
  sm,
  color = "primary",
  icon,
  iconPlacement = "start",
  className,
  classes,
  rounded,
  subdued,
  dark,
  light,
  ...other
}) {
  const styles = useStyles({sm, variant});
  return (
    <Button
      color={color}
      disableElevation
      disableRipple
      className={clsx(
        {
          [styles.dark]: !!dark,
          [styles.subdued]: !!subdued,
          [styles.autoWidth]: !fullWidth,
          [styles.alignStart]: alignStart,
          [styles.alignEnd]: alignEnd,
          [styles.rounded]: rounded,
          [styles.light]: !!light,
          [styles.big]: !!big,
          disabled: !!other.disabled,
        },
        className,
      )}
      classes={{
        root: styles.root,
        disabled: variant === "contained" ? styles.disabled : undefined,
        ...classes,
      }}
      variant={variant}
      startIcon={iconPlacement === "start" ? icon : null}
      endIcon={iconPlacement === "end" ? icon : null}
      {...other}
      id={id}
    >
      {label}
    </Button>
  );
}
