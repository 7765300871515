import React, {Fragment} from "react";
import {IconButton, Typography, makeStyles, useTheme} from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SmsIcon from "@material-ui/icons/Sms";
import EmailIcon from "@material-ui/icons/Email";
import PhoneCallIcon from "assets/icons/PhoneCallIcon";

const useStyles = makeStyles((theme) => ({
  line: {
    height: 1,
    border: "none",
    opacity: "10%",
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    margin: 0,
  },
  iconBtn: {padding: "0px !important"},
}));

export default function ContactInfo({components}) {
  const classes = useStyles();
  const theme = useTheme();

  let contact = components?.contact;

  return (
    !!contact && (
      <Fragment>
        <div style={{display: "flex", alignItems: "center", gap: 20}}>
          {components.contact.phone && (
            <div style={{flex: 1}}>
              <Typography id="contactInfoPhone">
                {components.contact.phone}
              </Typography>
            </div>
          )}
          {!components.contact.phone && !!components.contact.email && (
            <div style={{flex: 1}}>
              <Typography id="contactInfoEmail">
                {components.contact.email}
              </Typography>
            </div>
          )}
          {!!contact.email && (
            <IconButton
              className={classes.iconBtn}
              onClick={() => (window.location.href = `mailto:${contact.email}`)}
            >
              <EmailIcon
                sx={{fontSize: 30, color: theme.palette.primary.main}}
              />
            </IconButton>
          )}
          {!!contact.sms_phone && (
            <IconButton
              className={classes.iconBtn}
              onClick={() => (window.location.href = contact.sms_phone)}
            >
              <SmsIcon sx={{fontSize: 30, color: theme.palette.primary.main}} />
            </IconButton>
          )}
          {!!contact.whatsapp_phone && (
            <IconButton
              className={classes.iconBtn}
              onClick={() => window.open(contact.whatsapp_phone)}
            >
              <WhatsAppIcon
                sx={{fontSize: 30, color: theme.palette.primary.main}}
              />
            </IconButton>
          )}
          {!!contact.call_phone && (
            <IconButton
              className={classes.iconBtn}
              onClick={() => (window.location.href = contact.call_phone)}
            >
              <PhoneCallIcon
                sx={{fontSize: 30, color: theme.palette.primary.main}}
              />
            </IconButton>
          )}
        </div>
        <hr className={classes.line} />
      </Fragment>
    )
  );
}
