import * as types from "../actionTypes";
import * as API from "../api/bookingsAPI";
import {
  setApiError,
  setApiStart,
  setApiSuccess,
  setStartKeys,
  setTotals,
} from "./settingsActions";
import qs from "qs";

export function setBookings(data) {
  return {type: types.SET_BOOKINGS, bookings: data};
}
export function addBooking(booking) {
  return {type: types.ADD_BOOKING, booking: booking};
}
export function editBooking(booking) {
  return {type: types.EDIT_BOOKING, booking: booking};
}
export function selectBooking(bookingId) {
  return {type: types.SET_SELECTED_BOOKING, bookingId};
}
export function setSelectedGuestBookings(guest_bookings) {
  return {type: types.SET_SELECTED_GUEST_BOOKINGS, guest_bookings};
}

export function getBookings(params, add, onSuccess) {
  return async (dispatch) => {
    dispatch(setApiStart("bookings"));
    try {
      const bookings = await API.getBookings(params);
      console.log("GOT BOOKINGS", bookings);
      const total = bookings.count ?? "";
      if (add) {
        dispatch(addBooking(bookings.hits));
      } else {
        dispatch(setBookings(bookings.hits));
        dispatch(setTotals("bookings", total));
        bookings.hasOwnProperty("start_key") &&
          dispatch(setStartKeys("bookings", bookings.start_key));
      }
      onSuccess && onSuccess(bookings);
      dispatch(setApiSuccess("bookings"));
    } catch (error) {
      console.log("ERROR - GET BOOKINGS", error);
      dispatch(setApiError("bookings"));
    }
  };
}

export function getVerificationReport(
  booking_id,
  guest_id,
  onSuccess,
  onError,
) {
  return async (dispatch) => {
    try {
      const response = await API.getVerificationReport(booking_id, guest_id);
      console.log("RESPONSE - GET VERIFICATION REPORT", response);
      onSuccess && onSuccess(response);
    } catch (error) {
      console.log("ERROR - GET VERIFICATION REPORT", error);
      onError && onError();
    }
  };
}

export function updateBooking(body, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const response = await API.patchBooking({body: body});
      console.log("GOT UPDATED BOOKING", response);
      onSuccess && onSuccess(response);
    } catch (err) {
      console.log("ERROR - UPDATE BOOKING", err);
      onError && onError();
    }
  };
}

export function updateBookingStatus(body, onSuccess, onError) {
  return (dispatch) => {
    API.patchBookingStatus({body: body})
      .then((response) => {
        console.log("GOT UPDATED BOOKING", response);
        onSuccess && onSuccess(response);
      })
      .catch((err) => {
        console.log("ERROR - UPDATE BOOKING", err);
        onError && onError();
      });
  };
}

export function getBookingCheckinouts(onSuccess) {
  return async (dispatch, getState) => {
    const ensoKey = getState().defaultReducer.current_user;
    const body = {
      enso_key: ensoKey,
      get_checkin: true,
      get_checkout: true,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    dispatch(setApiStart("booking_checkinouts"));
    try {
      const response = await API.getBookingCheckinouts(qs.stringify(body));
      console.log("GOT CHECKINS/OUTS", response);
      dispatch(setApiSuccess("booking_checkinouts"));
      onSuccess && onSuccess(response);
    } catch (error) {
      dispatch(setApiError("booking_checkinouts"));
      console.log("ERROR - GET BOOKING CHECKINS/OUTS", error);
    }
  };
}
