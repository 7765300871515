import React, {useState} from "react";
import {useSelector} from "react-redux";
// UI
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as InboxIcon} from "assets/icons/inbox.svg";
import {ReactComponent as OpenIcon} from "assets/icons/chat-text-notification.svg";
import {ReactComponent as PriorityIcon} from "assets/icons/chat-exclamation.svg";
import {ReactComponent as EmailIcon} from "assets/icons/email.svg";
import {ReactComponent as SMSIcon} from "assets/icons/sms.svg";
import {ReactComponent as InquiriesIcon} from "assets/icons/chat-question.svg";
// Custom
import ExpandButton from "core/buttons/ExpandButton";
import CustomMenu from "core/menus/CustomMenu";
// Utils
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  disabledMenuItem: {
    ...theme.typography.subtitle2,
    color: THEME.subdued,
    padding: 0,
    "&.-section": {marginTop: theme.spacing(4)},
    "&.-pb": {paddingBottom: theme.spacing(3)},
  },
  paper: {
    borderRadius: 20,
    background: "#FFFFFF",
    boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.16)",
    padding: theme.spacing(3),
  },
  btn: {padding: theme.spacing(1, 3)},
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  listItem: {
    padding: theme.spacing(2),
    gap: theme.spacing(3),
  },
  itemText: {margin: 0},
  itemSelected: {
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main + "!important",
    color: theme.palette.common.white,
  },
  itemIcon: {
    minWidth: 0,
    borderRadius: 5,
    marginRight: theme.spacing(3),
    padding: theme.spacing(1),
    background: "rgba(32, 34, 35, 0.10)",
  },
  itemTitle: {
    fontSize: 14,
    fontWeight: 500,
  },
  itemSubtitle: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "16px",
    color: "rgba(32, 34, 35, 0.80)",
    "&.selected": {color: theme.palette.common.white},
  },
  fw500: {fontWeight: 500},
  count: {
    borderRadius: 30,
    padding: theme.spacing(1, 2),
    background: "rgba(32, 34, 35, 0.10)",
  },
}));

const iconMap = {
  all: {icon: InboxIcon, viewBox: "0 0 24 24"},
  open: {icon: OpenIcon, viewBox: "0 0 24 24"},
  priority: {icon: PriorityIcon, viewBox: "0 0 24 24"},
  email: {icon: EmailIcon, viewBox: "0 0 24 24"},
  sms: {icon: SMSIcon, viewBox: "0 0 24 24"},
  inquiry: {icon: InquiriesIcon, viewBox: "0 0 24 24"},
};

const MobileInboxes = ({selected, onClick}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const inboxes = useSelector((state) => state.defaultReducer.inbox_data);
  const inboxesKeys = React.useMemo(() => Object.keys(inboxes), [inboxes]);
  let selectedLabel =
    selected === "all" ? "All" : (inboxes[selected]?.label ?? "");

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (newOpt) => {
    handleCloseMenu();
    onClick(newOpt);
  };

  function getMenuContent() {
    return (
      <List className={classes.list}>
        <ListItem disabled className={classes.disabledMenuItem}>
          Select inbox
        </ListItem>
        {inboxesKeys.map((inbK) => {
          return (
            <ListItem
              button
              key={inbK}
              disableRipple
              selected={selected === inbK}
              className={classes.listItem}
              classes={{selected: classes.itemSelected}}
              onClick={() => handleOptionClick(inbK)}
            >
              {/* <ListItemAvatar className={classes.itemIcon}>
            <SvgIcon
              component={iconMap[inbK].icon}
              viewBox={iconMap[inbK].viewBox}
            />
          </ListItemAvatar> */}
              <ListItemText
                className={classes.itemText}
                primary={inboxes[inbK]?.label ?? ""}
                secondary={inboxes[inbK]?.subheader}
                primaryTypographyProps={{className: classes.itemTitle}}
                secondaryTypographyProps={{
                  className: clsx(classes.itemSubtitle, {
                    selected: selected === inbK,
                  }),
                }}
              />
              {inbK !== "all" && (
                <div className={classes.count}>
                  <Typography className={classes.fw500}>
                    {inboxes[inbK]?.total_count ?? 0}
                  </Typography>
                </div>
              )}
            </ListItem>
          );
        })}
      </List>
    );
  }

  return (
    <>
      <CustomMenu
        onClose={handleCloseMenu}
        content={getMenuContent()}
        open={!!anchorEl}
        anchorEl={anchorEl}
        customPaperClass={classes.paper}
      />
      <ExpandButton
        size="small"
        variant="contained"
        label={selectedLabel}
        className={classes.btn}
        // startIcon={<SvgIcon
        //   component={iconMap[selected].icon}
        //   viewBox={iconMap[selected].viewBox}
        // />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
    </>
  );
};

export default MobileInboxes;
