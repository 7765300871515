import React from "react";
import {Route, Switch} from "react-router-dom";
import routes from "configuration/routes.js";

class Pages extends React.Component {
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    return (
      <div ref="fullPages">
        <Switch>{this.getRoutes(routes)}</Switch>
      </div>
    );
  }
}

export default Pages;
