import React from "react";
import NumberFormat from "react-number-format";
// UI
import {Box, Typography} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import useBookingPanelStyles from "styles/useBookingPanelStyles";
// Custom
import Payment from "./Payment";
import InfoText from "components/Misc/InfoText";
import TagList from "components/Lists/TagList";
import WarningAlert from "core/alerts/WarningAlert";
import AccordionItem from "core/listItems/AccordionItem";
// Utils
import {PaymentStatusEnum} from "configuration/enums";
import infoTexts from "assets/infoTexts";
import {format} from "date-fns";
import clsx from "clsx";

export default function BookingSummary({
  booking,
  guest,
  CTAsOnly,
  handleAction,
}) {
  const classes = useBookingPanelStyles();
  const [expanded, setExpanded] = React.useState(false);
  const tags = !!booking.tags?.length
    ? booking.tags.filter((t) => !["Verified", "Revoked"].includes(t))
    : [];
  const bookingActions = booking?.actions?.booking_actions || [];

  const bookingCheckTimes = React.useMemo(() => {
    const {
      checkin_date,
      checkin_time,
      checkout_date,
      checkout_time,
      checkin_raw,
      checkout_raw,
    } = booking;
    const newCheckTimes = {
      checkinTime: null,
      checkoutTime: null,
      isListingTimezone: false,
    };
    if (!!checkin_date || !!checkout_date) {
      if (!!checkin_date) {
        newCheckTimes.checkinTime = format(
          new Date(`${checkin_date} ${checkin_time || ""}`),
          "MMM d y, h:mm a",
        );
      }
      if (!!checkout_date) {
        newCheckTimes.checkoutTime = format(
          new Date(`${checkout_date} ${checkout_time || ""}`),
          "MMM d y, h:mm a",
        );
      }
      newCheckTimes.isListingTimezone = true;
    } else if (!!checkin_raw || !!checkout_raw) {
      if (!!checkin_raw) {
        newCheckTimes.checkinTime = format(checkin_raw, "MMM d y, h:mm a");
      }
      if (!!checkout_raw) {
        newCheckTimes.checkoutTime = format(checkout_raw, "MMM d y, h:mm a");
      }
    }

    return newCheckTimes;
  }, [booking]);

  const handleActionClick = (props, type) => () => handleAction(props, type);

  const bookingDates = (!!bookingCheckTimes.checkinTime ||
    !!bookingCheckTimes.checkoutTime) && (
    <Typography
      className={clsx(
        classes.text,
        classes.leftText,
        classes.centeredContainer,
      )}
    >
      {bookingCheckTimes.checkinTime ?? ""}
      {bookingCheckTimes.checkoutTime && (
        <>
          <ArrowForwardIcon className={classes.arrowIcon} />
          {bookingCheckTimes.checkoutTime}
        </>
      )}
      {bookingCheckTimes.isListingTimezone && (
        <InfoText text={infoTexts.listingtimezone} />
      )}
    </Typography>
  );

  const bookingNights = !!booking.num_nights && (
    <Typography variant="caption" className={classes.nights}>
      {booking.num_nights} NIGHT{booking.num_nights > 1 ? "S" : ""}
    </Typography>
  );

  const getOccupants = () => {
    if (!booking.num_adult) {
      return null;
    }
    const adults = `${booking.num_adult} Adult${booking.num_adult === 1 ? "" : "s"}`;
    const children = !!booking.num_child
      ? `, ${booking.num_child} Child${booking.num_child === 1 ? "" : "ren"}`
      : "";
    const pets = !!booking.num_pets
      ? `, ${booking.num_pets} Pet${booking.num_pets === 1 ? "" : "s"}`
      : "";
    return (
      <>
        <span>{adults}</span>
        <span>{children}</span>
        <span>{pets}</span>
      </>
    );
  };

  const bookingTotal = (
    <span>
      <NumberFormat
        value={booking.payment?.total_guest_paid ?? (booking.total_paid || 0)}
        decimalScale={2}
        fixedDecimalScale
        thousandSeparator
        displayType="text"
        prefix={booking.payment?.currency === "EUR" ? "€" : "$"}
        suffix={
          booking.payment?.currency === "EUR"
            ? ""
            : ` ${booking.payment?.currency || ""}`
        }
      />
      {!!booking?.payment?.status && (
        <Typography component="span">
          {` - ${PaymentStatusEnum[booking?.payment?.status]}`}
        </Typography>
      )}
    </span>
  );

  return (
    <div className={clsx(classes.col, "-md-space")}>
      {bookingActions.map((props, index) => (
        <WarningAlert
          key={`bkg_action_${index}`}
          hide={false}
          disableMargin
          action={handleActionClick(props, "confirm")}
          actionButtonLabel={props.confirm?.label}
          secondaryAction={handleActionClick(props, "deny")}
          secondaryActionLabel={props.deny?.label}
          title={props.label}
        />
      ))}
      {!CTAsOnly && (
        <>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            paddingX={2}
            paddingY={2}
          >
            {bookingDates}
            {bookingNights}
          </Box>
          <div className={clsx(classes.expandedArea, {"-open": !!expanded})}>
            <AccordionItem
              expanded={expanded}
              title={getOccupants()}
              infoText={bookingTotal}
              infoTextProps={{bold: true}}
              onClick={
                !!booking.payment ? () => setExpanded((prev) => !prev) : null
              }
            />
            {expanded && !!booking.payment && (
              <Payment booking={booking} metadata={guest?.metadata} />
            )}
          </div>
          {!!tags.length && (
            <div className={classes.tagsRow}>
              <TagList disabled tags={tags} />
            </div>
          )}
        </>
      )}
    </div>
  );
}
