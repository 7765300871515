import React from "react";
import {Box, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import useScheduledMessagePanelStyles from "styles/useScheduledMessagePanelStyles";
// Custom
import MessageSelector from "components/MultiOption/MessageSelector";
import InfoText from "components/Misc/InfoText";
// Utilities
import {formatScheduledMessageTitle} from "utilities/formatUtilities";
import {getScheduledMsgsData} from "utilities/helperFunctions";
import {format, isValid} from "date-fns";
import clsx from "clsx";
import _ from "lodash";

export default function ScheduledMessageList({
  messages = [],
  showAll = false,
  onClick,
}) {
  const classes = useScheduledMessagePanelStyles();
  const schMsgGroups = React.useMemo(() => {
    return getScheduledMsgsData(
      showAll
        ? messages
        : messages.filter(
            (m) =>
              (!m.hasOwnProperty("meets_conditions") || !!m.meets_conditions) &&
              !m.sent,
          ),
    );
  }, [messages, showAll]);

  const handleItemClick = (m) => {
    if (showAll) {
      onClick(m);
    } else {
      const expMessages = [];
      _.each(messages, (msg) => {
        if (msg.trigger?.trigger_id === m.data[0].trigger?.trigger_id) {
          expMessages.push(msg);
        }
      });

      onClick({data: expMessages});
    }
  };

  return (
    <div>
      <div component="div" className={classes.messagesInfo}>
        {!showAll && (
          <Typography>
            {
              "The following messages meet your current conditions and will be sent to the guest at the set time."
            }
          </Typography>
        )}
      </div>
      {!schMsgGroups.length ? (
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.emptyLabel}
        >
          {!messages.length
            ? "No scheduled messages yet"
            : `No upcoming messages to show`}
        </Typography>
      ) : (
        <List className={classes.list}>
          {schMsgGroups.map((m, i) => {
            const allChannelsDisabled =
              m.data.every((d) => !!d.errors?.length) ||
              m.data.every((d) => d.meets_conditions === false);
            const disabledChannels = !allChannelsDisabled
              ? Object.entries(m.channels)
                  .filter((e) => !e[1])
                  .map((e) => e[0])
              : [];
            const channels = {
              airbnb: m.channels.hasOwnProperty("airbnb"),
              email: m.channels.hasOwnProperty("email"),
              SMS: m.channels.hasOwnProperty("SMS"),
              whatsapp: m.channels.hasOwnProperty("whatsapp"),
              vrbo: m.channels.hasOwnProperty("vrbo"),
              homeaway: m.channels.hasOwnProperty("homeaway"),
              bookingcom: m.channels.hasOwnProperty("bookingcom"),
            };

            return (
              <React.Fragment key={m.data[0].id}>
                <ListItem
                  dense
                  button
                  id={`scheduled-message-item-${i}`}
                  disableRipple
                  className={clsx(classes.listItem, {
                    "-disabled": allChannelsDisabled,
                  })}
                  onClick={() => handleItemClick(m)}
                >
                  <ListItemText
                    primary={formatScheduledMessageTitle(m.data[0].message)}
                    className={classes.listItemText}
                    primaryTypographyProps={{
                      className: clsx({
                        [classes.itemTitleDisabled]: allChannelsDisabled,
                      }),
                    }}
                  />
                  <Box display="flex" alignItems="center" minHeight={22}>
                    <MessageSelector
                      readOnly
                      variant="secondary"
                      disabled={allChannelsDisabled}
                      disabledChannels={disabledChannels}
                      {...channels}
                    />
                  </Box>
                  <Typography
                    variant="caption"
                    className={classes.timeInfo}
                    component="div"
                  >
                    {isValid(m.data[0].sent ?? m.data[0].send_time) && (
                      <>
                        {format(
                          m.data[0].sent ?? m.data[0].send_time,
                          "MMM d, h:mm a",
                        )}
                      </>
                    )}
                  </Typography>
                  <Box display="flex" minHeight={22} alignItems="center">
                    <InfoText text="In your timezone" disableGutters />
                  </Box>
                </ListItem>
              </React.Fragment>
            );
          })}
        </List>
      )}
    </div>
  );
}
