import React from "react";
// UI
import {
  Box,
  Button,
  IconButton,
  InputBase,
  makeStyles,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import {ReactComponent as PencilIcon} from "assets/icons/pencil.svg"; // Custom
import PartialComponentLayer from "components/Helpers/PartialComponentLayer";
import SaveContentButton from "components/MultiOption/SaveContentButton";
import PrimaryButton from "core/buttons/PrimaryButton";
import InfoText from "components/Misc/InfoText";
import DateTimePicker from "./DateTimePicker";
import PhoneField from "./PhoneField"; // Utils
import useComponentHeightObserver from "hooks/useComponentHeightObserver";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: (props) => (!!props.edit ? 52 : 0),
    minWidth: (props) => props.minWidth ?? undefined,
    cursor: "pointer",
    height: "fit-content",
    width: (props) => (!!props.fullWidth ? "100%" : "fit-content"),
    display: (props) => (!!props.inlineItem ? "inline-flex" : "flex"),
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: (props) =>
      !!props.disableCentered ? "flex-start" : "center",
    borderRadius: 5,
    "&:hover .-icon": {opacity: "1 !important"},
    "&.-edit": {
      cursor: "default",
      backgroundColor: "#F8F8F9",
      padding: theme.spacing(2),
    },
    "&.-error": {border: `1px solid ${theme.palette.error.main}`},
    "&.-edit-disabled": {cursor: "default"},
    "&.-full": {maxHeight: "fit-content"},
    "&.-partial": {
      maxHeight: 150,
      overflow: "hidden",
    },
  },
  label: {
    color: "rgba(109, 113, 117, 1)",
    "&.-error": {color: theme.palette.error.main},
  },
  iconBtn: {
    padding: 0,
    marginLeft: theme.spacing(2),
    opacity: 0,
  },
  icon: {
    width: 12,
    height: 12,
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    "&.-pt-sm": {paddingTop: theme.spacing(1)},
  },
  input: {width: "100%"},
  text: {
    overflow: "hidden",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
  },
  button: {
    padding: 0,
    backgroundColor: "transparent !important",
  },
  cancelBtnLabel: {color: "#A8A8A8"},
  helperText: {display: "block"},
}));

export default function InputField({
  label,
  value,
  placeholder,
  emptyLabel = "",
  infoText = null,
  helperText = "",
  type = "text",
  fullWidth = false,
  isInvalid = false,
  editOnly = false,
  inlineItem = false,
  id,
  minWidth,
  disableEdit = false,
  disableCentered = false,
  disableEnterSave = false,
  disableMultiline = false,
  required = false,
  useButtons = false,
  useMultiOptionSave = false,
  usePartialLayout = false,
  setIsEditing = () => {},
  timePickerProps = {},
  inputProps = {},
  phoneInputProps = {},
  textProps = {},
  partialLayoutProps = {minHeight: 140, maxHeight: 140},
  onChange = () => null,
  onCancel = () => null,
  onSave = () => null,
  disablePartialContentLayer = false,
}) {
  const wrapperRef = React.useRef(null);
  const [edit, setEdit] = React.useState(editOnly);
  const [hasErrors, setHasErrors] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles({
    edit,
    fullWidth,
    disableCentered,
    inlineItem,
    minWidth,
  });

  const isLargeText = useComponentHeightObserver({
    ref: wrapperRef,
    expanded,
    minHeight: partialLayoutProps.minHeight,
    maxHeight: partialLayoutProps.maxHeight,
  });
  let isLarge = !edit && isLargeText;

  React.useEffect(() => {
    if (!!editOnly && !edit) {
      setEdit((prev) => true);
    }
  }, [editOnly]);
  React.useEffect(() => {
    if (!!disableEdit && !!edit) {
      setEdit((prev) => false);
    }
  }, [disableEdit]);
  React.useEffect(() => {
    setIsEditing(edit);
  }, [edit]);

  React.useEffect(() => {
    if (!isInvalid && !!hasErrors && type === "timepicker") {
      setHasErrors((prev) => false);
    } else if (!useButtons) {
      setHasErrors((prev) => !!isInvalid);
    }
  }, [isInvalid]);

  const handleSave = (option = null) => {
    if (isInvalid) {
      setHasErrors((prev) => true);
      return;
    }
    if (!editOnly) {
      setEdit((prev) => false);
    }
    if (option !== null) {
      onSave(option);
    } else {
      onSave();
    }
  };

  const handleCancel = () => {
    setEdit((prev) => false);
    setHasErrors((prev) => false);
    onCancel();
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      if (!disableEnterSave) {
        handleSave();
      }
    } else if (e.key === "Escape") {
      if (!editOnly) {
        handleCancel();
      }
    } else if (!!hasErrors && !!useButtons) {
      setHasErrors((prev) => false);
    }
  };

  const handleFocus = (e) => {
    if (!!disableMultiline) {
      return;
    }
    const el = e.currentTarget;
    if (!!el && el.type !== "number") {
      el.setSelectionRange(el.value.length, el.value.length);
    }
  };

  const handleEdit = () => {
    if (disableEdit || editOnly) {
      return;
    }
    setEdit((prev) => true);
  };

  const getInputComponent = () => {
    switch (type) {
      case "phone":
        return (
          <PhoneField
            edit
            phone={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            disableEdit={disableEdit}
            {...phoneInputProps}
          />
        );
      case "timepicker":
        return (
          <DateTimePicker
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            {...timePickerProps}
          />
        );
      case "text":

      default:
        return (
          <InputBase
            autoFocus
            minRows={1}
            value={value}
            required={required}
            placeholder={placeholder}
            multiline={!disableMultiline}
            classes={{root: classes.input}}
            onFocus={handleFocus}
            onChange={onChange}
            onKeyDown={onKeyDown}
            {...inputProps}
          />
        );
    }
  };

  const getTextComponent = () => {
    switch (type) {
      case "phone":
        return <PhoneField phone={value} {...phoneInputProps} />;
      case "timepicker":
      case "text":

      default:
        return (
          <Typography
            className={clsx(classes.text, textProps.className)}
            {...textProps}
          >
            {" "}
            {value || emptyLabel}
          </Typography>
        );
    }
  };

  return (
    <>
      {!expanded && isLarge && !disablePartialContentLayer && (
        <PartialComponentLayer onExpand={() => setExpanded(true)} />
      )}
      <div
        id={id}
        className={clsx(classes.container, {
          "-edit": !!edit,
          "-edit-disabled": !!disableEdit,
          "-error": !!hasErrors && !!edit,
          "-full": !usePartialLayout || expanded,
          "-partial": !!usePartialLayout && isLarge && !expanded,
        })}
        onClick={handleEdit}
        ref={wrapperRef}
      >
        {!!label && (
          <Typography
            variant="caption"
            className={clsx(classes.label, {"-error": hasErrors && !!edit})}
          >
            {label}
            {!!infoText && <InfoText text={infoText} />}
          </Typography>
        )}
        <div className={clsx(classes.row, {"-pt-sm": type === "phone"})}>
          {edit ? getInputComponent() : getTextComponent()}
          {!edit && !disableEdit && (
            <IconButton
              className={clsx(classes.iconBtn, "-icon")}
              onClick={() => setEdit((prev) => true)}
            >
              <SvgIcon
                viewBox="0 0 12 12"
                className={classes.icon}
                component={PencilIcon}
              />
            </IconButton>
          )}
        </div>
      </div>
      {!!helperText && (
        <Typography
          color={isInvalid || hasErrors ? "error" : "textPrimary"}
          variant="caption"
          className={classes.helperText}
        >
          {helperText}
        </Typography>
      )}
      {!!edit && (!!useButtons || !!useMultiOptionSave) && (
        <Box
          mt={2}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            disableElevation
            variant="text"
            onClick={handleCancel}
            classes={{
              root: classes.button,
              label: classes.cancelBtnLabel,
            }}
          >
            {"Cancel"}
          </Button>
          {useMultiOptionSave ? (
            <SaveContentButton
              disabled={hasErrors}
              modalType="field"
              onSave={handleSave}
            />
          ) : (
            <PrimaryButton
              sm
              label="Save"
              disabled={hasErrors}
              onClick={handleSave}
            />
          )}
        </Box>
      )}
    </>
  );
}
