import {API} from "aws-amplify";

export const postValidate = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/variables/validate", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getVariables = async (params = "") => {
  try {
    const response = await API.get("ensoAPI", `/variables?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getVariablesAuth = async (params) => {
  try {
    const response = await API.get("ensoAPI", `/variables/auth${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};
