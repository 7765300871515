import React from "react";
import {useSelector} from "react-redux";
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import PrimaryButton from "core/buttons/PrimaryButton";
import HTMLField from "components/TextFields/HTMLField";
import {getActionMessagePreview} from "utilities/formatUtilities";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  listItemContainer: {listStyle: "none"},
  listItem: {
    padding: theme.spacing(1),
    paddingRight: 80,
    marginBottom: theme.spacing(2),
  },
  listItemText: {
    margin: 0,
    overflow: "hidden",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
  },
  listItemSecondaryAction: {
    right: theme.spacing(1),
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    WebkitLineClamp: 1,
  },
  title: {
    color: "#000",
    width: "fit-content",
  },
  desc: {
    color: THEME.subdued,
    marginTop: theme.spacing(1),
    "&.empty": {opacity: 0.5},
  },
  tag: {
    color: theme.palette.text.primary,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: 5,
    padding: theme.spacing(1),
    minWidth: 100,
    maxWidth: "fit-content",
    flex: 1,
  },
}));

export default function SelectableResourceItem({
  title,
  tag,
  isExperience = false,
  lang = null,
  description,
  onClick = () => null,
}) {
  const classes = useStyles();
  const defaultLanguage = useSelector((state) => state.defaultReducer.language);
  const selectedLang = lang || defaultLanguage;
  const itemTitle = Array.isArray(title)
    ? title.find((t) => t.language === defaultLanguage)?.value || ""
    : title;
  const isWebhook = isExperience && description[0]?.action_id === "webhook";
  const itemDesc = isExperience
    ? description.length === 1 || isWebhook // If experience has only 1 message (action) or if it is a webhook
      ? getActionMessagePreview(description[0]) // Message content preview function depending on message type
      : `${description.length} messages` // Displays the total number of messages (actions)
    : Array.isArray(description) // If description has the multi language format ([{language: 'en', value: '...'}, ...])
      ? description.find((t) => t.language === selectedLang)?.value || "" // Displays the content with the selected language
      : description; // Default option if plain text

  return (
    <ListItem
      className={classes.listItem}
      classes={{
        container: classes.listItemContainer,
        root: classes.listItem,
      }}
    >
      <ListItemText
        disableTypography
        className={classes.listItemText}
        primary={
          <div className={classes.titleContainer}>
            <Typography
              variant="h1"
              className={clsx(classes.ellipsis, classes.title)}
            >
              {itemTitle}
            </Typography>
            {!!tag && (
              <Typography
                variant="caption"
                className={clsx(classes.ellipsis, classes.tag)}
              >
                {tag}
              </Typography>
            )}
          </div>
        }
        secondary={
          !!itemDesc ? (
            itemDesc.toLowerCase().startsWith("<!doctype html") ? (
              <HTMLField content={itemDesc} />
            ) : (
              <Typography className={clsx(classes.ellipsis, classes.desc)}>
                {itemDesc}
              </Typography>
            )
          ) : (
            <Typography
              variant="h1"
              className={clsx(classes.ellipsis, classes.desc, "empty")}
            >
              {"No content"}
            </Typography>
          )
        }
      />
      <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
        <PrimaryButton
          size="small"
          color="secondary"
          label="Select"
          onClick={onClick}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}
