import React from "react";
// Custom
import DeviceTriggerSelector from "./DeviceTriggerSelector";
import ProductTriggerSelector from "./ProductTriggerSelector";

export default function TriggerValueSelector({type, value, onChange, listing}) {
  switch (type) {
    case "fees":
    case "upsells":
    case "fees_upsells":
      return (
        <ProductTriggerSelector
          type={type}
          value={value}
          onChange={onChange}
          listing={listing}
        />
      );
    case "device_trigger":
      return <DeviceTriggerSelector value={value} onChange={onChange} />;

    default:
      return null;
  }
}
