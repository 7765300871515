import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
// UI
import {Row} from "reactstrap";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
// Custom
import CustomCardHeader from "core/cards/CustomCardHeader";
// Actions
import {updateHouse, updateHouseRanges} from "redux/actions/listingsActions";

// Helpers & constants
import {setEndOfDay} from "utilities/helperFunctions";
import {useSelector} from "react-redux";
import {THEME} from "configuration/settings.js";
import _ from "lodash";

const millisecondsInADay = 86400000;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(4),
  },
  title: {...THEME.customTypography.title1},
  addButton: {
    marginLeft: -7,
    outline: "none!important",
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    wordBreak: "break-word",
  },
  miniCard: {
    display: "flex",
    maxWidth: 200,
    margin: 5,
  },
  cardContent: {
    padding: theme.spacing(1, 4),
    paddingRight: `${theme.spacing(3)}px!important`,
  },
  cardDetails: {
    width: "100%",
    cursor: "pointer",
  },
  cardAction: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 30,
    paddingRight: 5,
    cursor: "default",
  },
  cardIcon: {
    padding: 3,
    height: "min-content",
    backgroundColor: "transparent!important",
  },
  minicardContainer: {
    padding: 0,
    paddingTop: theme.spacing(2),
  },
}));

export default function FeesTaxes({listing, setDialog, disableEdit}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // Selector
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  // State
  const [taxes, setTaxes] = useState([]);
  const [fees, setFees] = useState([]);

  useEffect(() => {
    const listingContent = listing.listing_content;
    const rangesContent = listing.listing_ranges;
    const newTaxes = listingContent?.taxes || [];
    const newFees = rangesContent?.fees || [];
    setTaxes(newTaxes);
    setFees(newFees);
  }, [listing]);

  // Formatters & helper functions
  const getRangeFromDays = (daysInMilliseconds) => {
    const beginOffset = new Date(daysInMilliseconds.begin).getTimezoneOffset();
    const endOffset = new Date(daysInMilliseconds.end).getTimezoneOffset();
    const beginTimestamp = daysInMilliseconds.begin + beginOffset * 60000;
    const endTimestamp = daysInMilliseconds.end + endOffset * 60000;
    return [new Date(beginTimestamp), setEndOfDay(new Date(endTimestamp))];
  };

  const removeArrElement = (field, values, ind, updateFn) => () => {
    const newValues = _.filter(values, (val, index) => index !== ind);
    updateFn(newValues);
    updateData({[field]: newValues}, field);
  };

  const handleOpenDialog = (title, view, data) => () => {
    if (disableEdit) return false;
    if (view === "fee" && !!data) {
      const daysInMilliseconds = {
        begin: data.begin_date * millisecondsInADay,
        end: data.end_date * millisecondsInADay,
      };
      const convertedDays = getRangeFromDays(daysInMilliseconds);
      data.begin_date = convertedDays[0];
      data.end_date = convertedDays[1];
    }
    setDialog(title, view, data);
  };

  const updateData = (data, field) => {
    let body = {
      enso_key: current_user,
      listing_id: listing.listing_id,
      data: data,
    };
    const updateAction = field === "taxes" ? updateHouse : updateHouseRanges;
    dispatch(updateAction(body, listing));
  };

  const getCardContent = (field, dialogArgs, buttonLabel, values, updateFn) => {
    return (
      <div className="mb-5">
        <Typography className={classes.title}>{field}</Typography>
        {!!values && (
          <>
            <CardContent className={classes.minicardContainer}>
              {getMiniCards(field, values, dialogArgs, updateFn)}
            </CardContent>
            {!disableEdit && (
              <CardActions disableSpacing style={{paddingLeft: 0}}>
                {!!values.length ? (
                  <Button
                    className={classes.addButton}
                    color="secondary"
                    startIcon={<AddIcon />}
                    onClick={handleOpenDialog(
                      dialogArgs.title,
                      dialogArgs.view,
                    )}
                  >
                    {buttonLabel}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disableElevation
                    onClick={handleOpenDialog(
                      dialogArgs.title,
                      dialogArgs.view,
                    )}
                  >
                    {buttonLabel}
                  </Button>
                )}
              </CardActions>
            )}
          </>
        )}
      </div>
    );
  };

  const getMiniCards = (field, values, dialogArgs, updateFn) => {
    return (
      <Row className="pr-2 pl-2">
        {values.map((val, ind) => {
          const type = val.value_type;
          return (
            <Card key={ind} className={classes.miniCard} title={val.name}>
              <div
                className={classes.cardDetails}
                onClick={handleOpenDialog(dialogArgs.title, dialogArgs.view, {
                  ...val,
                  index: ind,
                })}
              >
                <CardContent className={classes.cardContent}>
                  <Typography variant="subtitle1" className={classes.ellipsis}>
                    {val.name}
                  </Typography>
                  <Typography color="textSecondary">{`${val.value}${type === "FLAT" ? "" : "%"}`}</Typography>
                </CardContent>
              </div>
              <div className={classes.cardAction}>
                {!disableEdit && (
                  <Tooltip title="Remove">
                    <IconButton
                      className={classes.cardIcon}
                      onClick={removeArrElement(
                        field.toLowerCase(),
                        values,
                        ind,
                        updateFn,
                      )}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </Card>
          );
        })}
      </Row>
    );
  };

  return (
    <div className={classes.root}>
      <CustomCardHeader title="Fees & Taxes" type="title" />
      <div className={classes.content}>
        {getCardContent(
          "Taxes",
          {title: "Tax", view: "tax"},
          "Add tax",
          taxes,
          setTaxes,
        )}
        {getCardContent(
          "Fees",
          {title: "Fee", view: "fee"},
          "Add fee",
          fees,
          setFees,
        )}
      </div>
    </div>
  );
}
