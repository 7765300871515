import React from "react";
import BaseFieldComponent from "../BaseFieldComponent";
import {
  Box,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {ReactComponent as FaceGrinHearts} from "assets/icons/face-grin-hearts.svg";
import {ReactComponent as FaceSmile} from "assets/icons/face-smile.svg";
import {ReactComponent as FaceMeh} from "assets/icons/face-meh.svg";
import {ReactComponent as FaceFrown} from "assets/icons/face-frown.svg";
import {ReactComponent as FaceAngry} from "assets/icons/face-angry.svg";
import GenericSelectionInput from "../../input/GenericSelectionInput";
import {satisfaction} from "configuration/constants";

const styles = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(2),
  },
  listItemIcon: {
    minWidth: 0,
    padding: 0,
  },
});

const icons = {
  highly_satisfied: FaceGrinHearts,
  satisfied: FaceSmile,
  neutral: FaceMeh,
  somewhat_satisfied: FaceFrown,
  unsatisfied: FaceAngry,
};

class SentimentField extends BaseFieldComponent {
  renderText({value}) {
    if (!value) {
      return satisfaction.unknown.label?.toLowerCase();
    }
    return (
      Object.values(satisfaction).find((s) => s.value === value)?.label || value
    ).toLowerCase();
  }

  renderInput({fieldData, handleValueChange, classes}) {
    // Reverse mapping for labels to numeric values
    const labelToValue = Object.values(satisfaction).reduce(
      (map, {label, value}) => {
        map[label] = value;
        return map;
      },
      {},
    );

    // Mapping values to labels for display
    const valueToLabel = Object.fromEntries(
      Object.entries(satisfaction).map(([key, {label, value}]) => [
        value,
        label,
      ]),
    );

    const options = Object.values(satisfaction).map(({label}) => label);

    return (
      <GenericSelectionInput
        options={options}
        enableSearch={true}
        edit={true}
        value={valueToLabel[fieldData.val] || null} // Convert value to label for dropdown
        multiselect={false}
        onValueChange={(newLabel) => {
          const newValue = labelToValue[newLabel]; // Convert label to numeric value
          handleValueChange(fieldData.type, newValue);
        }}
        label="Choose Sentiment"
        customMenuItem={(option, selectedOptions) => {
          const key = Object.keys(satisfaction).find(
            (k) => satisfaction[k].label === option,
          );
          return (
            <MenuItem key={option} value={option}>
              <ListItemAvatar className={classes.listItemIcon}>
                <SvgIcon
                  component={icons[key] || FaceMeh}
                  className={classes.icon}
                />
              </ListItemAvatar>
              <ListItemText primary={option} />
            </MenuItem>
          );
        }}
      />
    );
  }

  renderDisplay({fieldData, classes}) {
    // Mapping values to labels for display
    const valueToLabel = Object.fromEntries(
      Object.entries(satisfaction).map(([key, {label, value}]) => [
        value,
        label,
      ]),
    );

    return (
      <Box className={classes.root}>
        <SvgIcon
          component={
            icons[
              Object.keys(satisfaction).find(
                (key) => satisfaction[key].value === fieldData.val,
              )
            ] || FaceMeh
          }
          className={classes.icon}
        />
        <Typography variant="body1">
          {valueToLabel[fieldData.val] || fieldData.val}
        </Typography>
      </Box>
    );
  }
}

export default withStyles(styles)(SentimentField);
