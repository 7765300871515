import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {Box, Typography, Chip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import ProductIcon from "core/icons/ProductIcon";
import LocalServiceCard from "components/Cards/LocalServiceCard";
import HTMLField from "components/TextFields/HTMLField";
// Redux
import {
  editHouse,
  updateListingResourceOrder,
} from "redux/actions/listingsActions";
// Utils
import {getConnectionLabel} from "utilities/formatUtilities";
import {getContentTemplates} from "redux/actions/experiencesActions";
import {Skeleton} from "@material-ui/lab";
import useDashboardStyles from "styles/useDashboardStyles";
import {localServiceTypeEnum} from "configuration/enums";
import _ from "lodash";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    "&.templates": {gap: theme.spacing(2)},
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  subtitle: {marginBottom: theme.spacing(3)},
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingRight: theme.spacing(2),
  },
  itemTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "fit-content",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    maxWidth: "70%",
    WebkitLineClamp: 1,
  },
  connectedText: {
    fontSize: 12,
    fontWeight: 400,
    marginLeft: theme.spacing(1),
    color: theme.palette.text.disabled,
    flex: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    lineClamp: 1,
  },
}));

export default function ProductGrid({
  data = [],
  onAdd,
  onEdit,
  onDelete,
  hideSuggestions,
  disableEdit,
  currency = null,
  disableReorder = false,
  setData,
  listing,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDataOrdered = React.useRef(false);
  const orderedServices = React.useRef([]);
  const [localServiceTypeFilter, setLocalServiceTypeFilter] =
    React.useState("all");
  const defaultLang = useSelector((state) => state.defaultReducer.language);
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const availableExperiences = useSelector(
    (state) => state.experiencesReducer.available_experiences,
  );
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const loadingResource = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_resource_action;
  const [obj, setObj] = React.useState({});
  const [availableKeys, setAvailableKeys] = React.useState([]);

  React.useEffect(() => {
    dispatch(
      getContentTemplates({
        template_type: "local_service",
        locations: [],
        onSuccess: (r) => {
          setObj(
            r.reduce((acc, obj) => {
              acc[obj.id] = {
                name: obj.name,
                product_type: obj.id,
                category: obj.category,
                skus: [{url: obj.url}],
              };
              return acc;
            }, {}),
          );
          setAvailableKeys(r.map((r) => r.id));
        },
      }),
    );
  }, [data]);

  const serviceData = React.useMemo(() => {
    const dataDict = {};
    data.forEach((d, i) => {
      dataDict[d.properties.product_type] = {data: d, index: i};
    });

    if (!isDataOrdered.current && !!data.length) {
      isDataOrdered.current = true;
      const newOrder = data.map((d) => d.properties.product_type);
      newOrder.push(...availableKeys.filter((ak) => !newOrder.includes(ak)));
      orderedServices.current = newOrder;
      return {dataDict, services: newOrder};
    } else if (!orderedServices.current.length) {
      return {dataDict, services: availableKeys};
    } else {
      return {dataDict, services: orderedServices.current};
    }
  }, [availableKeys]);

  const handleCheck = ({add, product, index, isTemplate}) => {
    if (add) {
      onAdd(product, isTemplate);
    } else {
      onDelete(product, index);
    }
  };

  return (
    <>
      <div>
        <Chip
          color={localServiceTypeFilter === "all" ? "primary" : "default"}
          label={"All types"}
          className={classes.chip}
          onClick={() => setLocalServiceTypeFilter("all")}
        />
        {Object.keys(localServiceTypeEnum).map((gb_type) => (
          <Chip
            key={gb_type}
            color={localServiceTypeFilter === gb_type ? "primary" : "default"}
            label={localServiceTypeEnum[gb_type]}
            onClick={() => setLocalServiceTypeFilter(gb_type)}
            className={classes.chip}
          />
        ))}
      </div>
      <div className={clsx(classes.container, "templates")}>
        {_.isEmpty(obj) &&
          [...Array(40)].map((_, i) => (
            <Skeleton
              key={i}
              animation="wave"
              width={120}
              height={200}
              style={{marginBottom: -40, marginTop: -40}}
            />
          ))}
        {serviceData.services.map((s) => {
          if (
            localServiceTypeFilter !== "all" &&
            obj[s]?.category != localServiceTypeFilter
          ) {
            return null;
          }
          if (!!serviceData.dataDict[s]) {
            const product = serviceData.dataDict[s].data;
            const name = !!product.properties.name
              ? Array.isArray(product.properties.name)
                ? (product.properties.name.find(
                    (n) => n.language === defaultLang,
                  )?.value ??
                  (product.properties.name[0]?.value || ""))
                : product.properties.name
              : "";
            return (
              <LocalServiceCard
                sm
                key={s}
                editable
                isEnabled
                width={120}
                name={name}
                upsellId={s}
                disabled={disableEdit}
                onEdit={() => onEdit(product, serviceData.dataDict[s].index)}
                onCheckedChange={() =>
                  handleCheck({
                    add: false,
                    product,
                    index: serviceData.dataDict[s].index,
                  })
                }
              />
            );
          } else {
            return (
              <LocalServiceCard
                sm
                key={s}
                width={120}
                upsellId={s}
                isEnabled={false}
                name={obj[s]?.name}
                disabled={disableEdit}
                onCheckedChange={(add) => handleCheck({add, product: obj[s]})}
              />
            );
          }
        })}
      </div>
    </>
  );
}
