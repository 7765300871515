import React from "react";
import PrimaryButton from "./PrimaryButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {SvgIcon, isWidthDown, withWidth} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as MessagesIcon} from "assets/icons/Icon_Messages.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: (props) =>
      `${!!props.transparent ? "transparent" : "#FFF"} !important`,
    padding: 0,
    minWidth: 0,
  },
  btnLabel: {
    ...theme.typography.body1,
    fontWeight: 500,
    "&.-mobile": {fontSize: 17},
    "&.-header": {
      ...theme.typography.h2,
      fontWeight: 500,
      color: (props) =>
        props.color === "secondary"
          ? theme.palette.secondary.main
          : theme.palette.primary.main,
    },
  },
  btnIcon: {
    width: 16,
    marginRight: theme.spacing(1),
    color: (props) =>
      props.color === "secondary"
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
    "&.-mobile": {width: 20},
    "&.-header": {width: 22},
  },
  startIcon: {
    marginRight: 0,
    color: (props) =>
      props.color === "secondary"
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
    "&.-mobile": {marginLeft: 0},
  },
}));

const BackButton = ({
  label = "Back",
  size = "large",
  variant = "text",
  icon = "back",
  color = "secondary",
  transparent,
  goBack = () => null,
  width,
  header,
}) => {
  const classes = useStyles({transparent, color});
  const isMobile = isWidthDown("xs", width);
  const iconClassName = clsx(classes.btnIcon, {
    "-mobile": !!isMobile,
    "-header": !!header && !isMobile,
  });

  return (
    <PrimaryButton
      label={label}
      size={size}
      color={color}
      variant={variant}
      className={classes.btn}
      icon={
        icon === "chat" ? (
          <SvgIcon
            viewBox="0 0 35 35"
            className={iconClassName}
            component={MessagesIcon}
          />
        ) : (
          <ArrowBackIcon className={iconClassName} />
        )
      }
      onClick={() => goBack()}
      classes={{
        label: clsx(classes.btnLabel, {
          "-mobile": !!isMobile,
          "-header": !!header && !isMobile,
        }),
        startIcon: clsx(classes.startIcon, {"-mobile": !!isMobile}),
      }}
    />
  );
};

export default withWidth()(BackButton);
