import React from "react";
import {useDispatch} from "react-redux";
import _ from "lodash";
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  IconButton,
  makeStyles,
  Typography,
  withStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
// custom
import EmailEditor from "components/Misc/EmailEditor";
import FilledTextField from "core/inputs/FilledTextField";
import PrimaryButton from "core/buttons/PrimaryButton";
import EmailPreviewModal from "../Dialogs/EmailPreviewModal";
// Actions
import {getEmailTemplatePreview} from "redux/actions/messagesActions";
// Utilities
import {languages} from "configuration/constants.js";

const useStyles = makeStyles((theme) => ({
  accSummary: {
    padding: 0,
    minHeight: "40px !important",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "& > .MuiAccordionSummary-content": {
      margin: 0,
      alignItems: "center",
    },
  },
  expandIcon: {
    marginRight: theme.spacing(1),
    padding: 0,
  },
}));

const GeneralTextField = ({
  id,
  label,
  placeholder,
  value,
  inputRef,
  onChange,
  useCustomFields,
  noValidation,
  disabled,
  onValidityCheck,
  additionalObjectsVariables = [],
}) => {
  return (
    <FilledTextField
      id={id}
      inputRef={inputRef}
      label={label}
      fullWidth
      multiline
      value={value}
      disabled={disabled}
      noValidation={noValidation}
      onChange={onChange}
      placeholder={placeholder}
      useCustomFields={useCustomFields}
      style={{marginBottom: !useCustomFields ? 8 : 0}}
      onValidityCheck={onValidityCheck}
      additionalObjectsVariables={additionalObjectsVariables}
    />
  );
};

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {borderBottom: 0},
    "&:before": {display: "none"},
    "&$expanded": {margin: "auto"},
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  expandIcon: {order: -1},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {display: "block"},
}))(MuiAccordionDetails);

export default function MultiLangText({
  channel,
  fields,
  autotranslate,
  langs,
  content,
  selectedLang,
  editContent,
  removeLang,
  disableCustomFields,
  noValidation,
  disabled,
  onValidityCheck,
  additionalObjectsVariables = [],
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const inputRefs = React.useRef(null);
  const multifields = fields.length > 1;
  const [text, setText] = React.useState({...content});
  const [didMount, setDidMount] = React.useState(false);
  const [expanded, setExpanded] = React.useState(selectedLang);
  const [previewOptions, setPreviewOptions] = React.useState({
    show: false,
    content: null,
    subject: "",
    loading: false,
  });

  React.useEffect(() => setDidMount(true), []);

  React.useEffect(() => {
    const newInputRefs = {};
    _.each(fields, (f) => (newInputRefs[f.id] = null));
    inputRefs.current = newInputRefs;
  }, [fields]);

  React.useEffect(() => {
    if (!didMount) return;
    setExpanded(selectedLang);
  }, [autotranslate]);

  React.useEffect(() => {
    if (!didMount || _.isEqual(content, text)) return;
    setText({...content});
  }, [channel, content]);

  React.useEffect(() => {
    if (!didMount) return;
    editContent(channel, expanded, true);
  }, [expanded]);

  React.useEffect(() => {
    if (!didMount) return;
    const timer = setTimeout(() => {
      editContent(channel, text);
    }, 150);
    return () => clearTimeout(timer);
  }, [text]);

  const handleTextFieldChange = (lang, value, field) => {
    if (field)
      setText((prev) => ({...prev, [field]: {...prev[field], [lang]: value}}));
    else setText((prev) => ({...prev, [lang]: value}));
  };

  const handleAccordionClick = (lang) => (e) => {
    e.preventDefault();
    if (expanded !== lang) {
      setExpanded((prev) => lang);
    }
  };

  const previewClose = () => {
    setPreviewOptions((prev) => ({
      show: false,
      content: null,
      loading: false,
      subject: "",
    }));
  };

  const previewLoading = (subj) => {
    setPreviewOptions((prev) => ({
      show: true,
      content: null,
      loading: true,
      subject: subj,
    }));
  };

  const handleEmailPreview = (subj) => (preview) => {
    setPreviewOptions((prev) => ({
      show: true,
      content: preview,
      loading: false,
      subject: subj,
    }));
  };

  const getEmailPreview = (lang) => () => {
    const newSubject = text.subject?.[lang] || "";
    previewLoading(newSubject);
    const templateFields = {
      subject: newSubject,
      header: text.header?.[lang] || "",
      body: text.body?.[lang] || "",
    };
    dispatch(
      getEmailTemplatePreview(
        "fancy",
        templateFields,
        handleEmailPreview(newSubject),
      ),
    );
  };

  const getField = (lang) => {
    return (
      <>
        {fields.map((f) => {
          const value = multifields
            ? !!text[f.id]
              ? text[f.id][lang]
              : ""
            : text[lang] || "";
          return (
            <React.Fragment key={`${f.id}-${lang}`}>
              {f.showEditor ? (
                <EmailEditor
                  outlined
                  editorOnly
                  useCustomFields
                  disabled={disabled}
                  value={text.html ? text.html[lang] : ""}
                  onChange={(val) => handleTextFieldChange(lang, val, "html")}
                  onValidityCheck={onValidityCheck}
                  placeholder="Email content..."
                />
              ) : (
                <>
                  <GeneralTextField
                    id={f.id}
                    inputRef={(ref) => {
                      if (!!inputRefs.current) {
                        inputRefs.current[f.id] = ref;
                      }
                    }}
                    label={f.label}
                    placeholder={f.placeholder}
                    value={value}
                    disabled={disabled}
                    useCustomFields={!disableCustomFields}
                    noValidation={noValidation}
                    onChange={(e) =>
                      handleTextFieldChange(
                        lang,
                        e.target.value,
                        multifields ? f.id : null,
                      )
                    }
                    onValidityCheck={onValidityCheck}
                    additionalObjectsVariables={additionalObjectsVariables}
                  />
                </>
              )}
            </React.Fragment>
          );
        })}
        {channel === "email" && !disabled && (
          <Box display="flex" justifyContent="flex-end" mt={3} mb={2}>
            <PrimaryButton
              label="Preview Email"
              size="small"
              onClick={getEmailPreview(lang)}
            />
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <EmailPreviewModal
        show={previewOptions.show}
        content={previewOptions.content}
        loading={previewOptions.loading}
        subject={previewOptions.subject}
        onClose={previewClose}
      />
      {autotranslate
        ? getField(selectedLang)
        : langs.map((l) => (
            <Accordion
              key={l}
              expanded={expanded === l}
              onClick={handleAccordionClick(l)}
            >
              <AccordionSummary
                className={classes.accSummary}
                classes={{expandIcon: classes.expandIcon}}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{flexGrow: 1}}>{languages[l]}</Typography>
                {langs.length > 1 && !disabled && (
                  <IconButton
                    style={{padding: 0}}
                    onClick={() => removeLang(l)}
                  >
                    <DeleteIcon fontSize="small" color="disabled" />
                  </IconButton>
                )}
              </AccordionSummary>
              <AccordionDetails>{getField(l)}</AccordionDetails>
            </Accordion>
          ))}
    </>
  );
}
