import React from "react";
// UI
import {Col, Row} from "reactstrap";
import {
  Avatar,
  Button,
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DotIcon from "@material-ui/icons/FiberManualRecord";
import useRegisterStyles from "styles/useRegisterStyles";
// Custom
import FilledTextField from "core/inputs/FilledTextField";
import InputField from "core/inputs/InputField";

import {validateEmail} from "utilities/helperFunctions";
import {THEME} from "configuration/settings.js";
import clsx from "clsx";

export default function RegisterForm({hostData, saveInfo}) {
  const classes = useRegisterStyles();
  const [profilePicture, setProfilePicture] = React.useState({
    file: null,
    src: null,
  });
  const [data, setData] = React.useState({
    name: "",
    email: hostData.email,
    password: "",
    confirmPassword: "",
    company: hostData.business_name,
    dialCode: "1",
  });

  const [phone, setPhone] = React.useState(hostData.phone);
  const [country, setCountry] = React.useState({dialCode: "1"});
  const [validPhone, setValidPhone] = React.useState(false);

  const [showPassword, toggleShowPassword] = React.useState({
    password: false,
    confirmPassword: false,
  });
  const [passwordPolicies, setPasswordPolicies] = React.useState({
    showPolicies: false,
    hasMinLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasSpecialChar: false,
    hasNumber: false,
  });
  const [errors, setErrors] = React.useState({
    name: false,
    email: false,
    company: false,
    password: false,
    confirmPassword: false,
  });
  const isValid = Object.values(errors).every((err) => !err);

  React.useEffect(() => {
    setData((prev) => ({
      ...prev,
      dialCode: country.dialCode,
    }));
  }, [country]);

  React.useEffect(() => {
    const passwordHasErrors =
      data.password &&
      (!passwordPolicies.hasMinLength ||
        !passwordPolicies.hasLowercase ||
        !passwordPolicies.hasSpecialChar ||
        !passwordPolicies.hasNumber ||
        !passwordPolicies.hasUppercase);
    passwordHasErrors ? setPasswordError(true) : setPasswordError(false);
  }, [passwordPolicies]);

  React.useEffect(() => {
    validateData();
    validatePassword(data.password);
  }, [data]);

  const handleSave = (e) => {
    e.preventDefault();
    const accountInfo = {
      name: data.name,
      email: data.email,
      password: data.password,
      company: data.company,
      role: "admin",
      image: profilePicture,
      phone: validPhone ? phone : null,
    };
    saveInfo(accountInfo);
  };

  const validateData = () => {
    const isValidName = data.name.length >= 4 && data.name.length <= 230;
    const isValidEmail = validateEmail(data.email);
    const isValidCompany = !!data.company;
    if (isValidName === errors.name) {
      setErrors((prev) => ({...prev, name: !isValidName}));
    }
    if (isValidEmail === errors.email) {
      setErrors((prev) => ({...prev, email: !isValidEmail}));
    }
    if (isValidCompany === errors.company) {
      setErrors((prev) => ({...prev, company: !isValidCompany}));
    }
  };

  const validatePassword = (password) => {
    const hasMinLength = password.length >= 8;
    const valid_uppercase = /[A-Z]/.test(password);
    const valid_lowercase = /[a-z]/.test(password);
    const valid_special_char =
      /[\=\+\-\^\$\*\.\[\]{}()?"!@#%&/\\,><':;|_~`]/.test(password);
    const valid_number = /\d/.test(password);
    const isValid =
      hasMinLength &&
      valid_uppercase &&
      valid_lowercase &&
      valid_special_char &&
      valid_number;

    if (isValid === errors.password) {
      setErrors((prev) => ({...prev, password: !isValid}));
    }

    setPasswordPolicies((prev) => ({
      ...prev,
      hasMinLength,
      hasUppercase: valid_uppercase,
      hasLowercase: valid_lowercase,
      hasSpecialChar: valid_special_char,
      hasNumber: valid_number,
    }));
  };

  const setPasswordError = (value) => {
    setErrors((prev) => ({...prev, invalidPassword: value}));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () =>
      setProfilePicture({file: file, src: reader.result});
    reader.readAsDataURL(file);
  };

  const handleInputChange = (e) => {
    e.persist();
    setData((prev) => ({...prev, [e.target.id]: e.target.value}));
    if (e.target.id === "confirmPassword") {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: e.target.value !== data.password,
      }));
    }
  };

  const handleMouseDownPassword = (e) => e.preventDefault();

  const handleToggleShowPassword = (field) => () => {
    toggleShowPassword((prev) => ({...prev, [field]: !prev[field]}));
  };

  const handleTogglePasswordPolicies = (value) => () => {
    setPasswordPolicies((prev) => ({...prev, showPolicies: value}));
  };

  function getHelperText(key) {
    switch (key) {
      case "name":
        return "Name must be between 4 and 230 characters long";
      case "email":
        return "Email not valid";
      case "password":
        return "The password does not comply with all policies";
      case "confirmPassword":
        return "Passwords do not match";
      default:
        return "";
    }
  }

  function getAvatar() {
    return (
      <div className={clsx(classes.row, classes.centered)}>
        <input
          id="avatarInput"
          style={{display: "none"}}
          type="file"
          accept="image/*"
          onChange={handleImageChange}
        />
        <label htmlFor="avatarInput">
          <div style={{position: "relative"}}>
            <Avatar
              src={profilePicture.src}
              className={classes.avatar}
              variant="rounded"
            />
            <span className={classes.editText}>EDIT</span>
          </div>
        </label>
      </div>
    );
  }

  function getInput(id, label, value, placeholder, error) {
    return (
      <FilledTextField
        id={id}
        label={label}
        fullWidth
        value={value}
        placeholder={placeholder}
        error={!!error}
        helperText={error && getHelperText(id)}
        onChange={handleInputChange}
      />
    );
  }

  function getPolicyRow(valid, label) {
    return (
      <div className={classes.policySection}>
        <DotIcon
          className={classes.policyDot}
          color={valid ? "primary" : "disabled"}
        />
        <Typography
          className={classes.policyText}
          color={valid ? "textPrimary" : "textSecondary"}
        >
          {label}
        </Typography>
      </div>
    );
  }

  function getPasswordPolicies() {
    return (
      <React.Fragment>
        <Row className="mt-2">
          <Col className={clsx(classes.column, classes.policyCol)} xs={6}>
            {getPolicyRow(passwordPolicies.hasLowercase, "Lowercase")}
          </Col>
          <Col className={clsx(classes.column, classes.policyCol)} xs={6}>
            {getPolicyRow(passwordPolicies.hasUppercase, "Uppercase")}
          </Col>
        </Row>
        <Row>
          <Col className={clsx(classes.column, classes.policyCol)} xs={6}>
            {getPolicyRow(passwordPolicies.hasNumber, "Number")}
          </Col>
          <Col className={clsx(classes.column, classes.policyCol)} xs={6}>
            {getPolicyRow(passwordPolicies.hasSpecialChar, "Special character")}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className={clsx(classes.column, classes.policyCol)} xs={12}>
            {getPolicyRow(passwordPolicies.hasMinLength, "8 characters min")}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  return (
    <div className={clsx(classes.formRoot, classes.column)}>
      {getAvatar()}
      <Row className="mt-4">
        <Col xs={12} sm={6} className="mt-3">
          {getInput(
            "name",
            "Name",
            data.name,
            "Enter Name",
            !!data.name && errors.name,
          )}
        </Col>
        <Col xs={12} sm={6} className="mt-3">
          {getInput(
            "email",
            "Email",
            data.email,
            "Enter Email",
            !!data.email && errors.email,
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} className="mt-3">
          <FormControl variant="filled" fullWidth>
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.inputLabel}
            >
              Password
            </Typography>
            <FilledInput
              id="password"
              type={showPassword.password ? "text" : "password"}
              value={data.password}
              fullWidth
              autoComplete="new-password"
              placeholder="Enter Password"
              onChange={handleInputChange}
              onFocus={handleTogglePasswordPolicies(true)}
              onBlur={handleTogglePasswordPolicies(false)}
              error={!!data.password && errors.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleToggleShowPassword("password")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword.password ? (
                      <VisibilityIcon color="disabled" />
                    ) : (
                      <VisibilityOffIcon color="disabled" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText
              id="password-helper-text"
              style={{color: THEME.error}}
            >
              {!!data.password && errors.password && getHelperText("password")}
            </FormHelperText>
          </FormControl>
          {passwordPolicies.showPolicies && getPasswordPolicies()}
        </Col>
        <Col xs={12} sm={6} className="mt-3">
          <FormControl variant="filled" fullWidth>
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.inputLabel}
            >
              Confirm Password
            </Typography>
            <FilledInput
              id="confirmPassword"
              type={showPassword.confirmPassword ? "text" : "password"}
              value={data.confirmPassword}
              fullWidth
              autoComplete="new-password"
              placeholder="Enter Password"
              onChange={handleInputChange}
              error={!!data.confirmPassword && errors.confirmPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleToggleShowPassword("confirmPassword")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword.confirmPassword ? (
                      <VisibilityIcon color="disabled" />
                    ) : (
                      <VisibilityOffIcon color="disabled" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText
              id="confirmPassword-helper-text"
              style={{color: THEME.error}}
            >
              {!!data.confirmPassword &&
                errors.confirmPassword &&
                getHelperText("confirmPassword")}
            </FormHelperText>
          </FormControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} className="mt-3">
          {getInput(
            "company",
            "Company / Business Name",
            data.company,
            "Enter Business Name",
          )}
        </Col>
        <Col xs={12} sm={6} className="mt-3">
          <InputField
            editOnly
            fullWidth
            type="phone"
            label="Phone (Optional)"
            value={phone}
            onChange={setPhone}
            isInvalid={!validPhone}
            phoneInputProps={{
              setCountry: setCountry,
              setValidPhone: setValidPhone,
            }}
          />
        </Col>
      </Row>
      <div className={clsx(classes.row, classes.centered, "mt-5")}>
        <Button
          variant="contained"
          color="primary"
          disabled={!isValid}
          onClick={handleSave}
          id="saveBtn"
        >
          Save & Continue
        </Button>
      </div>
    </div>
  );
}
