import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router";
import {useLocation, useRouteMatch} from "react-router-dom";
// UI
import {Button, Paper, Slide, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import ExperienceBuilder from "./ExperienceBuilder";
import WarningAlert from "core/alerts/WarningAlert";
import FullLoader from "components/Dialogs/FullLoader";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import PrimaryButton from "core/buttons/PrimaryButton";
import UpdateSession from "components/Dialogs/UpdateSession";
import CustomCardHeader from "core/cards/CustomCardHeader";
import CloseIconButton from "core/buttons/CloseIconButton";
import CreateFromExistingList from "components/Lists/CreateFromExistingList";
// Actions
import {
  getExperiences,
  saveExperience,
  updateExperience,
} from "redux/actions/experiencesActions";
import {getNotifications} from "redux/actions/notificationsActions";
import {editListingGroup} from "redux/actions/listingsActions";
// Utils
import clsx from "clsx";
import qs from "qs";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "&.list": {overflow: "auto"},
  },
  paper: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  content: {
    flexGrow: 1,
    borderTop: `1px solid rgba(13,86,140,0.05)`,
    overflowX: "hidden",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    "&.list": {padding: theme.spacing(4)},
  },
  cancelBtn: {
    color: "#A8A8A8",
    fontSize: 14,
  },
  cardHeader: {padding: theme.spacing(4)},
  warningContainer: {padding: theme.spacing(4)},
  note: {
    margin: theme.spacing(5, 0),
    marginBottom: -theme.spacing(4),
  },
}));

export default function ExperiencePanel({
  listing,
  type = "default",
  experience,
  connectedIds,
  hideContent,
  hideTriggers,
  hideConnection,
  closeDetails,
  groupId,
  disableEdit,
  copyExisting,
  selectExperience,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const matchMessagingView = useRouteMatch("/admin/messages/:guest_id");
  const mobileChatMatch = useRouteMatch("/admin/messages/chat/:guest_id");
  const mobileProfileMatch = useRouteMatch("/admin/messages/profile/:guest_id");
  const matchCardSection = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card/:card_state",
  );
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const [isValid, setIsValid] = React.useState(false);
  const [saveDisabled, setSaveDisabled] = React.useState(false);
  const [data, setData] = React.useState({});
  const [emptyIds, setEmptyIds] = React.useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] =
    React.useState(false);
  const [redirect, setRedirect] = React.useState(null);
  const [updateSession, setUpdateSession] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [enableExpModalOpen, setEnableExpModalOpen] = React.useState(false);
  // General
  let paramsGuestId =
    mobileChatMatch?.params?.guest_id ??
    mobileProfileMatch?.params?.guest_id ??
    matchMessagingView?.params.guest_id;
  const isGroupView = !!matchCardSection?.isExact;
  const {section, card} = React.useMemo(() => {
    if (!matchCardSection?.isExact) {
      return {section: null, card: null};
    }
    return {
      section: matchCardSection.params.step,
      card: matchCardSection.params.card,
    };
  }, [location]);

  React.useEffect(() => validateData(data), [connectedIds]);

  React.useEffect(() => {
    if (isGroupView) {
      return;
    }
    if (type === "default" && !!experience && !experience.actions) {
      dispatch(
        getExperiences({
          experienceId: experience.experience_id,
          listingId: listing.listing_id,
        }),
      );
    }
  }, [experience]);

  const closeConfiguration = () => closeDetails();
  const handleDismiss = () => setShowCancelConfirmation((prev) => false);
  const onCancel = () => setShowCancelConfirmation((prev) => true);
  const handleCloseEnableExpModal = () =>
    setEnableExpModalOpen((prev) => false);

  const refreshNotifications = () => {
    const params = {enso_key: current_user, guest_id: paramsGuestId};
    dispatch(getNotifications(qs.stringify(params), true));
  };

  const validateData = (newData) => {
    const validName = type === "instant" ? true : !!newData.name?.trim();
    const validIds = type === "default" ? true : !!connectedIds.length;
    const validActions = newData.actions?.every(
      (step) => !!step.actions?.length,
    );
    const isNewDataValid = validName && validIds && validActions;
    if (validIds === emptyIds) {
      setEmptyIds((prev) => !validIds);
    }
    if (isNewDataValid !== isValid) {
      setIsValid((prev) => isNewDataValid);
    }
  };

  const onSaveSuccess = (response) => {
    if (isGroupView) {
      const newGroup = listingGroups[groupId];
      if (!!newGroup) {
        const newData = [
          ...newGroup.guest_journey.sections[section].cards[card].data,
          response.experience,
        ];
        newGroup.guest_journey.sections[section].cards[card].data = newData;
        dispatch(editListingGroup(newGroup));
      }
    }
    if (
      !!matchMessagingView?.isExact ||
      !!mobileChatMatch?.isExact ||
      !!mobileProfileMatch?.isExact
    ) {
      refreshNotifications();
    }
    setLoading((prev) => false);
    setUpdateSession((prev) => null);
    closeDetails(true);
  };

  const onSaveError = () => {
    setLoading((prev) => false);
    setUpdateSession((prev) => null);
  };

  const handleSave = ({
    saveUpdate = false,
    saveAsDisabled = false,
    saveAsEnabled = false,
  }) => {
    const showEnableExpModal = !data.experience_id && !data.enabled;
    const experienceExists = !!data.experience_id;
    if (
      !saveUpdate &&
      !saveAsDisabled &&
      !saveAsEnabled &&
      showEnableExpModal
    ) {
      setEnableExpModalOpen((prev) => true);
      return;
    } else if (saveAsDisabled || saveAsEnabled) {
      handleCloseEnableExpModal();
    }

    setIsValid((prev) => false);
    setLoading((prev) => true);

    const newExperience = {...data};
    if (!!saveAsEnabled) {
      newExperience.enabled = true;
    } else if (!!saveAsDisabled) {
      newExperience.enabled = false;
    }

    const newData = !!connectedIds
      ? {
          experience: {
            ...newExperience,
            connected_to: {object: "guest", ids: connectedIds},
          },
        }
      : {experience: newExperience};

    if (!!saveUpdate && !!updateSession) {
      newData.update_id = updateSession.update_session.update_id;
    }
    if (!!data.experience_id) {
      newData.experience_id = data.experience_id;
    }

    const actionProps = {
      groupId,
      listingId:
        !experience?.experience_id || !isGroupView ? listing?.listing_id : null,
      body: newData,
      onSuccess: onSaveSuccess,
      onError: onSaveError,
      onUpdates: !saveUpdate
        ? (update) => {
            setUpdateSession((prev) => update);
            setLoading((prev) => false);
          }
        : null,
    };

    if (experienceExists) {
      dispatch(updateExperience(actionProps));
    } else {
      dispatch(saveExperience(actionProps));
    }
  };

  const handleCancelUpdates = () => {
    setUpdateSession((prev) => null);
  };

  const handleLoadingDialogClose = () => {
    setLoading((prev) => false);
  };

  const handleDataChange = (newData) => {
    validateData(newData);
    setData((prev) => newData);
  };

  const handleExperienceSelect = (exp) => {
    const newExperience = {
      ...exp,
      connected_to: {object: "listings", ids: [listing.listing_id]},
      actions: exp.actions.map((act) => ({
        ...act,
        actions: act.actions.map((a) => ({...a, id: undefined})),
      })),
    };
    delete newExperience.created_at;
    delete newExperience.id;
    delete newExperience.experience_id;
    selectExperience(newExperience);
  };

  function getTitle() {
    if (!!matchCardSection?.isExact) {
      const label =
        listingGroups[groupId]?.guest_journey?.sections[section].cards[card]
          .label ?? "";
      return `${label} settings`;
    } else {
      return !experience ? "New workflow" : "Automated workflow";
    }
  }

  function getErrors() {
    const errors = experience?.errors || [];
    return errors.map((err, i) => (
      <WarningAlert
        key={`${err.title}-${i}`}
        title={err.title}
        subtitle={err.message}
        severity={err.severity}
        actionButtonLabel={err.path_to_resolution ? "Resolve" : null}
        action={
          err.path_to_resolution
            ? () => setRedirect(err.path_to_resolution)
            : null
        }
      />
    ));
  }

  const enableModal = (
    <ConfirmDialog
      disableDismiss
      open={enableExpModalOpen}
      onClose={handleCloseEnableExpModal}
      title="Enable workflow?"
      message="This workflow is DISABLED and will not send messages. Would you like to enable it?"
      optionLabel="Cancel"
      optionAction={handleCloseEnableExpModal}
      optionBtnColor="default"
      confirmLabel="Enable and save"
      confirmAction={() => handleSave({saveAsEnabled: true})}
      cancelLabel="Keep it disabled and save"
      cancelAction={() => handleSave({saveAsDisabled: true})}
      cancelBtnColor="primary"
      cancelBtnVariant="outlined"
    />
  );

  const confirmModal = !!updateSession && (
    <ConfirmDialog
      disableDismiss
      open={!loading}
      onClose={handleCancelUpdates}
      title="Confirm updates?"
      message={
        <>
          {"Updating this workflow will affect the following:"}
          <UpdateSession updates={updateSession.update_session.payload} />
          {!data.enabled && (
            <Typography color="textSecondary" className={classes.note}>
              {
                "* Scheduled messages WILL NOT SEND until you enable your workflow"
              }
            </Typography>
          )}
        </>
      }
      confirmLabel="Confirm"
      confirmAction={() => handleSave({saveUpdate: true})}
      cancelLabel="Cancel"
      cancelAction={handleCancelUpdates}
    />
  );

  const loadingModal = (
    <FullLoader
      open={loading}
      disableDismiss
      onClose={handleLoadingDialogClose}
      loadingText="Saving workflow..."
    />
  );

  if (!!redirect) {
    if (redirect.slice(0, 4) === "http") {
      window.location.href = redirect;
    } else {
      return <Redirect push to={`/admin/${redirect}`} />;
    }
  } else if (!!copyExisting) {
    return (
      <div className={clsx(classes.root, "list")}>
        <CustomCardHeader
          title="Automated workflow"
          className={classes.cardHeader}
          action={<CloseIconButton sm onClick={closeConfiguration} />}
        />
        <div className={clsx(classes.content, "list")}>
          <CreateFromExistingList
            resource="workflow"
            onSelect={handleExperienceSelect}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        {confirmModal}
        {enableModal}
        {loadingModal}
        <ConfirmDialog
          disableDismiss
          open={showCancelConfirmation}
          onClose={handleDismiss}
          title="Quit editing?"
          message="Changes you made so far will not be saved"
          confirmLabel="Yes, quit"
          confirmAction={closeConfiguration}
          cancelLabel="Keep editing"
          cancelAction={handleDismiss}
        />
        <Slide direction="left" in unmountOnExit mountOnEnter timeout={0}>
          <Paper elevation={0} className={classes.paper}>
            <div className={classes.column}>
              <CustomCardHeader
                title={getTitle()}
                className={classes.cardHeader}
                action={
                  !!disableEdit ? (
                    <CloseIconButton sm onClick={closeConfiguration} />
                  ) : (
                    <>
                      <Button
                        className="mr-2"
                        classes={{label: classes.cancelBtn}}
                        size="small"
                        onClick={onCancel}
                      >
                        Cancel
                      </Button>
                      <PrimaryButton
                        size="small"
                        label="Save"
                        className="mr-2"
                        disabled={!isValid || !!saveDisabled}
                        onClick={() =>
                          handleSave({
                            saveAsDisabled:
                              !!isGroupView &&
                              !listingGroups[groupId]?.enabled &&
                              !data.experience_id,
                          })
                        }
                        id={"button-save-experience"}
                      />
                    </>
                  )
                }
              />
              <div className={classes.content}>
                <WarningAlert
                  hide={!emptyIds}
                  title="No Selected Guests"
                  subtitle="Select at least 1 guest from the table to save this workflow."
                />
                {getErrors()}
                <ExperienceBuilder
                  groupId={groupId}
                  disableEdit={disableEdit}
                  experience={experience}
                  hideConnection={hideConnection}
                  hideContent={hideContent}
                  hideTriggers={hideTriggers}
                  setSaveDisabled={setSaveDisabled}
                  setData={handleDataChange}
                  listing={listing}
                />
              </div>
            </div>
          </Paper>
        </Slide>
      </div>
    );
  }
}
