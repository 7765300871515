import React from "react";
import {
  Button,
  Card,
  CardHeader,
  IconButton,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/WarningRounded";
import CloseIcon from "@material-ui/icons/Close";
import ButtonLabelLoader from "core/loaders/ButtonLabelLoader";
import SyncIcon from "@material-ui/icons/Sync";
import {toSnakeCase} from "utilities/formatUtilities";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.secondary.main,
    transition: ".15s",
    overflow: "visible",
    "&.disableRadius": {borderRadius: 0},
  },
  critical: {color: "#FD5C63"},
  warning: {color: "#FFC94C"},
  criticalBg: {backgroundColor: "#FD5C63"},
  warningBg: {backgroundColor: "#FFC94C"},
  cardMargin: {margin: theme.spacing(4)},
  hide: {
    height: 0,
    width: 0,
    opacity: 0,
    minHeight: 0,
    overflow: "hidden",
  },
  cardHeader: {
    padding: theme.spacing(3),
    "&.sm": {padding: theme.spacing(2, 3)},
  },
  warningIcon: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "50%",
    padding: theme.spacing(2),
    width: 46,
    height: 46,
    "&.sm": {
      padding: theme.spacing(1),
      width: 32,
      height: 32,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  cardText: {color: theme.palette.common.white},
  closeIcon: {
    color: theme.palette.common.white,
    opacity: 0.7,
  },
  icon: {
    height: 30,
    width: 30,
    marginTop: -2,
    "&.sm": {
      width: 24,
      height: 24,
    },
    "&.sync": {margin: 0},
  },
  actionBtn: {
    minWidth: "fit-content",
    padding: theme.spacing(1, 3),
    color: "#36709B",
    textTransform: "none",
    backgroundColor: theme.palette.common.white,
    "&.-secondary": {
      backgroundColor: theme.palette.secondary.main,
      border: "1px solid #FFF",
      color: "#FFF",
    },
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    margin: 0,
  },
  cardContent: {paddingRight: theme.spacing(3)},
  avatar: {marginRight: theme.spacing(3)},
}));

const WarningAlert = ({
  hide,
  disableMargin,
  title,
  subtitle,
  onClose,
  actionButtonLabel,
  action,
  severity,
  customAction,
  secondaryActionLabel,
  secondaryAction,
  actionLoading,
  secondaryActionLoading,
  disableActionBtn,
  sync,
  disableSecondaryActionBtn,
  disableCustomActionBtn,
  small,
  disableRadius,
}) => {
  const classes = useStyles();
  let iconProps = {
    color: "secondary",
    className: clsx(classes.icon, {
      [classes.warning]: severity === "warning",
      [classes.critical]: severity === "critical",
      sm: !!small,
      sync: !!sync,
    }),
  };

  function getActionBtn(label, btnAction, isSecondary = false, disabled) {
    return (
      <Button
        disableRipple
        disabled={disabled}
        onClick={btnAction}
        variant={isSecondary ? "outlined" : "contained"}
        className={clsx(classes.actionBtn, {"-secondary": !!isSecondary})}
      >
        {isSecondary ? (
          secondaryActionLoading ? (
            <ButtonLabelLoader variant="white" />
          ) : (
            label
          )
        ) : actionLoading ? (
          <ButtonLabelLoader variant="secondary" />
        ) : (
          label
        )}
      </Button>
    );
  }

  return (
    <Card
      id={`bkg-alert-${toSnakeCase(title)}`}
      className={clsx(classes.card, {
        [classes.cardMargin]: !disableMargin && !hide,
        [classes.hide]: !!hide,
        [classes.warningBg]: severity === "warning",
        [classes.criticalBg]: severity === "critical",
        sm: !!small,
        disableRadius: !!disableRadius,
      })}
    >
      <CardHeader
        className={clsx(classes.cardHeader, {sm: !!small})}
        classes={{
          action: clsx({
            [classes.actions]: !!customAction || !!action || !!secondaryAction,
          }),
          content: classes.cardContent,
          avatar: classes.avatar,
        }}
        avatar={
          sync ? (
            <CircularProgress
              style={{color: "white", marginTop: 5}}
              size={20}
            />
          ) : (
            <div className={clsx(classes.warningIcon, {sm: !!small})}>
              <WarningIcon {...iconProps} />
            </div>
          )
        }
        action={
          <>
            {!!onClose && (
              <IconButton onClick={onClose}>
                <CloseIcon className={classes.closeIcon} fontSize="small" />
              </IconButton>
            )}
            {!!action &&
              getActionBtn(actionButtonLabel, action, false, disableActionBtn)}
            {!!secondaryAction &&
              getActionBtn(
                secondaryActionLabel,
                secondaryAction,
                true,
                disableSecondaryActionBtn,
              )}
            {!!customAction &&
              getActionBtn(
                customAction.label,
                customAction.action,
                false,
                disableCustomActionBtn,
              )}
          </>
        }
        title={
          <div>
            <Typography variant="subtitle2" className={classes.cardText}>
              {title}
            </Typography>
            {!!subtitle && (
              <Typography className={classes.cardText}>{subtitle}</Typography>
            )}
          </div>
        }
      />
    </Card>
  );
};

export default WarningAlert;
