import React from "react";
import {useSelector} from "react-redux";
// UI
import {makeStyles} from "@material-ui/core/styles";
import {
  Box,
  Button,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  MenuList,
  Typography,
} from "@material-ui/core";
import RightArrowIcon from "@material-ui/icons/ArrowForwardIos";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Custom
import EnsoAIButton from "components/Misc/EnsoAIButton";
import ExpandButton from "core/buttons/ExpandButton";
import CustomMenu from "core/menus/CustomMenu";
// Utils
import {conditionsOnlyFields} from "configuration/constants";
import {recommendedConditions} from "configuration/enums";
import {capitalize, sleep} from "utilities/helperFunctions";
import {compareObjectsByName, compareTexts} from "utilities/formatUtilities";
import {fieldParams} from "configuration/specs";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  expandContainer: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(3),
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  expandBtn: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(1, 3),
  },
  expandMenuItem: {
    padding: theme.spacing(2, 0, 1, 4),
    width: "100%",
  },
  menuItem: {padding: theme.spacing(2, 4, 1)},
  listItemText: {
    textTransform: "capitalize",
    margin: 0,
  },
  listItemIcon: {
    minWidth: "auto",
    padding: `0 8px`,
  },
  arrowIcon: {
    color: theme.palette.grey[300],
    fontSize: 14,
  },
  listSubheader: {
    lineHeight: "20px",
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    "&.-first": {padding: theme.spacing(2, 4, 0, 4)},
  },
  expandIcon: {
    color: "rgba(0,0,0,0.26)",
    fontSize: "24px !important",
    "&.-expanded": {transform: "rotate(180deg)"},
  },
  fieldContainer: {
    width: "100%",
    height: "100%",
    borderRadius: 5,
    position: "relative",
    backgroundColor: "rgba(67, 84, 96, 0.0392157)",
    "& > .label": {
      top: 6,
      left: 12,
      position: "absolute",
      color: "rgba(0,0,0,0.6)",
    },
  },
  fieldButton: {
    padding: 0,
    width: "100%",
    height: "100%",
    borderRadius: 5,
    backgroundColor: "transparent !important",
  },
  fieldButtonLabel: {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    padding: theme.spacing(0, 3, 2),
    ...theme.typography.body1,
    "&.-placeholder": {color: "rgba(0,0,0,0.4)"},
  },
  search: {
    zIndex: 2,
    position: "sticky",
    top: 0,
    backgroundColor: "#FFF !important",
    padding: theme.spacing(3, 4, 2),
  },
  searchInput: {
    border: "1px solid rgba(200, 200, 204, 0.501961)",
    borderRadius: 5,
    fontSize: 15,
    lineHeight: "15px",
    padding: "3px 8px 5px",
    outline: "none",
    width: "100%",
  },
  helperItem: {padding: theme.spacing(2, 4)},
  loadingItem: {justifyContent: "center"},
}));

export default function CustomFieldDropdown({
  onSelect,
  customFields = {},
  selected = null,
  isField,
  AIprops = {},
}) {
  const classes = useStyles();
  const categoryClick = React.useRef(false);
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const [categoriesAnchorEl, setCategoriesAnchorEl] = React.useState(null);
  const [fieldsAnchorEl, setFieldsAnchorEl] = React.useState(null);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const [noResults, setNoResults] = React.useState(false);
  const categories = React.useMemo(
    () => Object.keys(customFields).sort(compareTexts),
    [customFields],
  );
  const allFields = React.useMemo(() => {
    const values = Object.values(customFields);
    if (!values.length) {
      return [];
    }
    let newFields = [];
    values.forEach((item) => newFields.push(...item));
    if (!isField) {
      newFields = newFields.filter(
        (f) => !conditionsOnlyFields.includes(f.field),
      );
    }
    return newFields.map((f) => ({
      field: f.field,
      name: f.name.toLowerCase(),
    }));
  }, [customFields]);

  React.useEffect(() => {
    const lowerSearchText = searchText.toLowerCase();
    const timer = setTimeout(() => {
      if (!searchText) {
        setSearchResults((prev) => []);
        setNoResults((prev) => false);
      } else {
        const newResults = allFields.filter((i) => {
          return (
            i.name.includes(lowerSearchText) ||
            i.field.includes(lowerSearchText)
          );
        });
        setSearchResults((prev) => newResults);
        setNoResults((prev) => !newResults.length);
      }
    }, 150);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  const handleCategoriesClose = (force) => {
    if (!force) {
      return;
    }
    setCategoriesAnchorEl((prev) => null);
    setSearchText((prev) => "");
  };

  const handleFieldsClose = () => {
    if (categoryClick.current) {
      return;
    }
    setFieldsAnchorEl((prev) => null);
    setSelectedCategory((prev) => null);
    handleCategoriesClose(true);
  };

  const handleSelectField = (field) => {
    handleFieldsClose();
    if (!isField) {
      navigator.clipboard.writeText(`<${field}>`);
    }
    onSelect(field);
  };

  const handleCategorySelect = (e, category) => {
    const el = e.currentTarget;
    setFieldsAnchorEl((prev) => el);
    setSelectedCategory((prev) => category);
    categoryClick.current = true;
    sleep(150).then(() => (categoryClick.current = false));
  };

  const handleSearchChange = (e) => {
    const val = e.target.value;
    setSearchText((prev) => val);
  };

  const handleGeneratedText = (text) => {
    AIprops.insertGeneratedText(text);
  };

  const searchItem = (value, onChange) => {
    return (
      <MenuItem
        disableRipple
        onKeyDown={(e) => e.stopPropagation()}
        className={classes.search}
      >
        <input
          className={classes.searchInput}
          autoFocus
          value={value}
          type="search"
          placeholder="search"
          autoComplete="off"
          onChange={onChange}
        />
      </MenuItem>
    );
  };

  const noResultsItem = () => {
    return (
      <MenuItem disabled className={classes.helperItem}>
        {"No search results"}
      </MenuItem>
    );
  };

  const loadingItem = () => {
    return (
      <MenuItem disabled className={[classes.helperItem, classes.loadingItem]}>
        <CircularProgress color="secondary" size={20} />
      </MenuItem>
    );
  };

  const getSearchResultsContent = () => {
    return (
      <MenuList>
        {searchItem(searchText, handleSearchChange)}
        {!!noResults && noResultsItem()}
        {!searchResults.length && !noResults && loadingItem()}
        {getFieldsContent()}
      </MenuList>
    );
  };

  function getModalContent() {
    const recommendedOptions = isField
      ? Object.keys(recommendedConditions)
      : [];
    return (
      <MenuList>
        {searchItem(searchText, handleSearchChange)}
        {isField && (
          <ListSubheader
            disableSticky
            className={clsx(classes.listSubheader, "-first")}
          >
            {"Recommended"}
          </ListSubheader>
        )}
        {recommendedOptions.map((f) => (
          <MenuItem
            key={f}
            value={f}
            selected={selected === f}
            onClick={() => handleSelectField(f)}
            className={classes.menuItem}
          >
            {isField ? "If " : ""}
            {fieldParams[f].name}
          </MenuItem>
        ))}
        {isField && (
          <ListSubheader disableSticky className={classes.listSubheader}>
            {"Field categories"}
          </ListSubheader>
        )}
        {categories.length
          ? categories.map((cat) => (
              <MenuItem
                dense
                className={classes.expandMenuItem}
                key={cat}
                onClick={(e) => handleCategorySelect(e, cat)}
              >
                <ListItemText className={classes.listItemText}>
                  {capitalize(cat, "_")}
                </ListItemText>
                <ListItemIcon className={classes.listItemIcon}>
                  <RightArrowIcon className={classes.arrowIcon} />
                </ListItemIcon>
              </MenuItem>
            ))
          : loadingItem()}
      </MenuList>
    );
  }

  function getFieldsContent() {
    const orderedFields = !searchText
      ? (customFields[selectedCategory] || []).sort(compareObjectsByName)
      : [];
    const options = !!searchText ? searchResults : orderedFields;
    return (
      <MenuList>
        {options.map((cf, i) => (
          <MenuItem
            key={`${cf.field}-${i}`}
            selected={selected === cf.field}
            onClick={() => handleSelectField(cf.field)}
            className={classes.menuItem}
          >
            {isField ? "If " : ""}
            {cf.name}
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  const mainModal = !!categoriesAnchorEl && (
    <CustomMenu
      open
      overflowAuto
      timeout={100}
      zIndex={1300}
      maxHeight={510}
      placement="bottom-start"
      anchorEl={categoriesAnchorEl}
      onClose={() => handleCategoriesClose(!fieldsAnchorEl)}
      content={!!searchText ? getSearchResultsContent() : getModalContent()}
    />
  );

  const fieldsMenu = !!categoriesAnchorEl && (
    <CustomMenu
      overflowAuto
      modifiers={{
        flip: {enabled: true},
        preventOverflow: {
          enabled: true,
          boundariesElement: "viewport",
        },
      }}
      timeout={100}
      zIndex={1300}
      placement="right-start"
      open={!!fieldsAnchorEl}
      anchorEl={fieldsAnchorEl}
      onClose={handleFieldsClose}
      content={getFieldsContent()}
    />
  );

  return (
    <>
      {mainModal}
      {fieldsMenu}
      {isField ? (
        <div className={classes.fieldContainer}>
          <Typography variant="caption" className="label">
            {"Field"}
          </Typography>
          <Button
            aria-haspopup
            size={"small"}
            disableElevation
            variant={"contained"}
            endIcon={
              <ExpandMoreIcon
                className={clsx(classes.expandIcon, {
                  "-expanded": !!categoriesAnchorEl,
                })}
              />
            }
            onClick={(e) => setCategoriesAnchorEl(e.currentTarget)}
            classes={{
              root: classes.fieldButton,
              label: clsx(classes.fieldButtonLabel, {
                "-placeholder": !selected,
              }),
            }}
          >
            {fieldParams[selected]?.name ??
              (!!selected ? capitalize(selected, "_") : "Select a field")}
          </Button>
        </div>
      ) : (
        <div className={classes.expandContainer}>
          {!!AIprops.show && (
            <Box my={3} className="expand-btn">
              <EnsoAIButton
                type={AIprops.type}
                handleGeneratedText={handleGeneratedText}
                {...AIprops.objects}
              />
            </Box>
          )}
          <ExpandButton
            hasPopup
            size="small"
            label="Add personalized text"
            className={clsx(classes.expandBtn, "expand-btn")}
            onClick={(e) => setCategoriesAnchorEl(e.currentTarget)}
          />
        </div>
      )}
    </>
  );
}
