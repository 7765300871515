import React, {useState} from "react";
import {Redirect, useParams} from "react-router-dom";
import qs from "qs";
import {
  Button,
  Typography,
  CircularProgress,
  makeStyles,
  Divider,
} from "@material-ui/core";
import FilledTextField from "core/inputs/FilledTextField";
import useRegisterStyles from "styles/useRegisterStyles";
import {
  serviceProviderMap,
  channelIntegrationTypeMap,
} from "configuration/enums";
import PrimaryButton from "core/buttons/PrimaryButton";
import {useDispatch} from "react-redux";
import {createService} from "redux/actions/servicesActions";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  signupText: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& div": {
      paddingRight: theme.spacing(6),
      paddingLeft: theme.spacing(6),
    },
    "& img": {
      maxWidth: "100%",
      boxShadow: "0px 0px 15px -6px #000",
      // outline: `1px solid ${theme.palette.text.secondary}`,
      borderRadius: "10px",
      marginBottom: theme.spacing(3),
    },
    // '& .MuiTypography-body1': {
    //     fontSize: '1.2rem',
    // },
    // '& b': {
    //     fontSize: '1.2rem',
    //
    // }
  },
  divider: {
    marginBottom: theme.spacing(4),
  },
}));

export default function ChannelRegisterForm({
  integration,
  onSuccess,
  onCancel,
  options = {},
  redirect_path = "auth",
}) {
  let integration_spec = channelIntegrationTypeMap[integration];
  const params = useParams();
  const classes = useStyles();
  // const classes = useRegisterStyles()
  const [auth, setAuth] = useState(
    Object.fromEntries(
      Object.keys(integration_spec?.signup_fields)?.map((s) => [s, null]),
    ),
  );
  const [redirect, setRedirect] = React.useState({
    pathname: "",
    search: null,
    state: null,
  });

  function handleRegister() {
    setRedirect((prev) => ({
      pathname: `/${redirect_path}/channel_auth/${integration}`,
      search: `?${qs.stringify(auth)}`,
      state: {originParams: {channel: params.channel, version: params.version}},
    }));
  }

  if (!!redirect.pathname) {
    return (
      <Redirect
        push
        to={{
          pathname: redirect.pathname,
          search: redirect.search,
          state: redirect.state,
        }}
      />
    );
  }
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          padding: "20px",
          maxWidth: "100%",
        }}
      >
        <div style={{}}>
          <Typography variant="h6" color="primary" component="span">
            <div
              dangerouslySetInnerHTML={{
                __html: "Connect with " + integration_spec.title,
              }}
            />
          </Typography>
        </div>
        <img
          src={integration_spec.icon}
          alt="Logo"
          style={{maxHeight: "40px", height: "auto"}}
        />
      </div>

      <div className={classes.formContainer}>
        <div style={{marginBottom: 20}}>
          <Typography variant="body1" color="textSecondary" component="span">
            <div className={classes.signupText}>
              <div
                dangerouslySetInnerHTML={{__html: integration_spec.signup_text}}
              />
            </div>
          </Typography>
        </div>
        {/*<Divider className={classes.divider}/>*/}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "60%",
            margin: "0 auto", // Centers the div
          }}
        >
          {Object.entries(integration_spec.signup_fields).map((s) => (
            <FilledTextField
              label={s[1].name}
              key={s[0]}
              placeholder={s[1].name}
              variant="outlined"
              fullWidth
              value={auth.code}
              onChange={(e) =>
                setAuth({...auth, [s[0]]: e.target.value.trim()})
              }
              className="mb-3"
            />
          ))}
          <Button
            variant="contained"
            color="primary"
            className={classes.pmsSelectionBtn}
            onClick={() => handleRegister()}
            disabled={!Object.values(auth).every((v) => !!v)}
          >
            Sign Up With {integration_spec.title}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
