import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {FormControlLabel, Switch} from "@material-ui/core";
import SimpleField from "./SimpleField";

const defaultLabels = {
  checked: "Active",
  unchecked: "Inactive",
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

export default function BooleanField({
  edit,
  value,
  onValueChange,
  labels = defaultLabels,
}) {
  const classes = useStyles();
  const [selectedVal, setSelectedVal] = React.useState(!!value);

  React.useEffect(() => {
    const newVal = !!value;
    if (edit && newVal !== selectedVal) {
      setSelectedVal((prev) => newVal);
    }
  }, [value]);

  React.useEffect(() => {
    if (edit) {
      onValueChange(selectedVal);
    }
  }, [selectedVal]);

  const handleChange = (e) => {
    const newVal = e.target.checked;
    setSelectedVal((prev) => newVal);
  };

  if (!edit) {
    return <SimpleField value={!!value} />;
  } else {
    return (
      <div className={classes.root}>
        <FormControlLabel
          control={
            <Switch
              checked={selectedVal}
              onChange={handleChange}
              name="booleanCheck"
            />
          }
          label={selectedVal ? labels.checked : labels.unchecked}
        />
      </div>
    );
  }
}
