import React from "react";
import {Box, Button, Typography} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import CustomCardHeader from "../../../../core/cards/CustomCardHeader";
import PrimaryButton from "../../../../core/buttons/PrimaryButton";
import clsx from "clsx";
import {THEME} from "../../../../configuration/settings";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  tab: {
    padding: "8.68px", // For some reason the text wrapping in the inbox screws with the height by 0.68 pixels LOL, This works for now, id rather unify the component
    borderRadius: 10,
    textTransform: "none",
    "&.selected": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  },
  label: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
  },
  cardHeader: {
    padding: THEME.spacing.sm,
  },
  fw500: {fontWeight: 500},
}));

export default function CrmCardHeader({
  view,
  setView,
  handleActionClick,
  viewsOpen,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <CustomCardHeader
      sx={{
        paddingLeft: "8px",
        paddingRight: THEME.spacing.sm,
        paddingBottom: 0,
        marginBottom: -10,
      }}
      className={classes.cardHeader}
      title={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(2),
          }}
        >
          <Button
            disableRipple
            disableElevation
            className={clsx(classes.tab, {selected: view === "guests"})}
            classes={{label: classes.label}}
            onClick={() => setView("guests")}
          >
            <Typography className={classes.fw500}>
              {t("crm-card-header-button-guests")}
            </Typography>
          </Button>
          <Button
            disableRipple
            disableElevation
            className={clsx(classes.tab, {selected: view === "campaigns"})}
            onClick={() => setView("campaigns")}
            classes={{label: classes.label}}
          >
            <Typography className={classes.fw500}>
              {t("crm-card-header-button-campaigns")}
            </Typography>
          </Button>
        </Box>
      }
      action={
        !viewsOpen && (
          <PrimaryButton
            rounded={true}
            color="secondary"
            label={t("crm-card-header-button-new-campaign")}
            variant="contained"
            onClick={() => handleActionClick("template")}
          />
        )
      }
    />
  );
}
