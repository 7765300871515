import React from "react";
import {Auth} from "aws-amplify";
import {isIOS, isMobile} from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import {useHistory} from "react-router-dom/cjs/react-router-dom";

import {useLDClient} from "launchdarkly-react-client-sdk";
// UI
import {isWidthDown, isWidthUp, makeStyles, withWidth} from "@material-ui/core";
import "bootstrap/dist/css/bootstrap.min.css";
// Custom
import AdminNavbar from "core/bars/AdminNavbar";
import SuccessAlert from "core/alerts/SuccessAlert";
import ErrorAlert from "core/alerts/ErrorAlert";
import NotificationAlert from "core/alerts/NotificationAlert";
import PaymentModal from "components/Dialogs/PaymentModal";
// Actions
import {
  closeAlert,
  closeGeneralError,
  openAlert,
  openGeneralError,
  resetStore,
  setDeviceType,
} from "redux/actions/settingsActions";
import {getHouses, getListingGroups} from "redux/actions/listingsActions";
import {
  getProfile,
  registerDevice,
  setKey,
  setUser,
} from "redux/actions/accountsActions";
import {getGuests} from "redux/actions/guestsActions";
import {getBookings} from "redux/actions/bookingsActions";
import {setForceBilling} from "redux/actions/accountsActions";
import {getServiceAccounts} from "redux/actions/servicesActions";
// Other
import handleAttachedObjects from "utilities/webhookObjectConverter";
import {scopes} from "configuration/constants";
import config from "configuration/settings.js";
import routes from "configuration/routes.js";
import mapBg from "assets/img/bgMap.png";
import LogRocket from "logrocket";
import clsx from "clsx";
import _ from "lodash";

const mobile_paths = [
  "/admin/home",
  "/admin/messages",
  "/admin/profile",
  "/admin/chat",
  "/admin/dashboard",
  "/admin/channel_auth",
];

const non_redirect_paths = [
  "/admin/listings",
  "/admin/messages",
  "/admin/settings",
  "/admin/dashboard",
  "/admin/createhouse",
  "/admin/channel_auth",
  "/admin/adddevice",
  "/admin/connect",
  "/admin/pairdevices",
  "/admin/pairtemplates",
  "/admin/guests",
  "/admin/error",
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${mapBg})`,
    backgroundSize: "cover",
    display: "flex",
    backgroundColor: "#F0F1EA",
    height: "100vh",
    width: "100vw",
    alignItems: "center",
    overflow: "hidden",
    flexDirection: "row",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(4),
    "&.disablePadd": {padding: 0},
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
      padding: 0,
    },
  },
  bgMobile: {
    background: "#F0F1EA",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  bgMobileIOS: {
    height: "100vh",
    backgroundColor: "#F0F1EA",
    paddingTop: "env(safe-area-inset-top) !important",
  },
  alertbar: {
    width: "30%",
    "& > * + *": {
      marginTop: theme.spacing(4),
    },
  },
  body: {
    backgroundColor: theme.palette.background.default,
  },
}));

function Admin(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const matchCardSection = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card",
  );
  const matchItemCard = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card/:item",
  );
  // Selectors
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const alert = useSelector((state) => state.defaultReducer.alert);
  const hideNavbar = useSelector((state) => state.defaultReducer.hideNavbar);
  const deviceType = useSelector((state) => state.defaultReducer.deviceType);
  const generalErrorProps = useSelector(
    (state) => state.defaultReducer.general_error,
  );
  const generalSuccessProps = useSelector(
    (state) => state.defaultReducer.general_alert_success,
  );
  const errorPathBlocked = useSelector(
    (state) => state.defaultReducer.errorPathBlocked,
  );
  const forceBilling = useSelector(
    (state) => state.defaultReducer.forceBilling,
  );
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  const selectedViewItems = useSelector(
    (state) => state.defaultReducer.selected_view_items,
  );
  // State
  const [newNotif, setNewNotif] = React.useState(false);
  const [authRequired, setAuthRequired] = React.useState(false);
  const [payloadRedirect, setPayloadRedirect] = React.useState(null);
  // General
  const ldClient = useLDClient();
  const isMobileWidth = isWidthDown("xs", props.width);
  const isTabletWidth =
    isWidthUp("sm", props.width) && isWidthDown("md", props.width);
  const isMobileView = deviceType === "mobile";
  const {groupId, step, card, item} = React.useMemo(() => {
    if (!!matchCardSection?.isExact) {
      return {
        groupId: matchCardSection.params.group_id,
        step: matchCardSection.params.step,
        card: matchCardSection.params.card,
        item: null,
      };
    } else if (!!matchItemCard?.isExact) {
      return {
        groupId: matchItemCard.params.group_id,
        step: matchItemCard.params.step,
        card: matchItemCard.params.card,
        item: matchItemCard.params.item,
      };
    } else {
      return {groupId: null, step: null, card: null, item: null};
    }
  }, [location]);
  const crypto = require("crypto");

  React.useEffect(() => {
    if (!!errorPathBlocked) {
      non_redirect_paths.push("/admin/error");
    } else if (
      !errorPathBlocked &&
      non_redirect_paths.includes("/admin/error")
    ) {
      non_redirect_paths.pop();
    }
  }, [errorPathBlocked]);

  React.useEffect(() => {
    // Handle device token
    if (!!window.deviceToken && !!user_profile?.enso_user_id) {
      console.log("Device token", window.deviceToken);
      dispatch(
        registerDevice(
          window.deviceToken,
          isIOS ? "ios" : "android",
          user_profile.enso_user_id,
        ),
      );
    }
  }, [window.deviceToken, user_profile]);

  React.useEffect(() => {
    const newDeviceType = isMobileWidth
      ? "mobile"
      : isTabletWidth
        ? "tablet"
        : "desktop";
    if (newDeviceType !== deviceType) {
      dispatch(setDeviceType(newDeviceType));
    }
  }, [isMobileWidth, isTabletWidth, deviceType]);

  const {selected_non_redirect_paths, settings_paths_to_redirect} =
    React.useMemo(() => {
      const activePaths = isMobileView ? mobile_paths : non_redirect_paths;
      if (!Object.keys(user_profile).length) {
        return {
          selected_non_redirect_paths: activePaths,
          settings_paths_to_redirect: [],
        };
      } else {
        let newPaths = [...activePaths];
        if (!!selectedViewItems.listings.item) {
          if (!!selectedViewItems.listings.props.isGroup) {
            newPaths.push(
              `/admin/listings/groups/${selectedViewItems.listings.item}`,
            );
          } else {
            if (!!selectedViewItems.listings.props.listingSection) {
              newPaths.push(
                `/admin/listings/${selectedViewItems.listings.item}/${selectedViewItems.listings.props.listingSection}`,
              );
            } else {
              newPaths.push(
                `/admin/listings/${selectedViewItems.listings.item}`,
              );
            }
          }
        }
        const hiddenScopes = [];
        const hiddenSettingsScopes = [];
        if (user_profile.scopes?.crm === "hide") {
          hiddenScopes.push("/admin/guests");
        }
        if (user_profile.scopes?.listings === "hide") {
          hiddenScopes.push("/admin/listings");
        }
        if (user_profile.scopes?.messaging === "hide") {
          hiddenScopes.push("/admin/messages");
        }
        if (user_profile.scopes?.accounts === "hide") {
          hiddenSettingsScopes.push("/admin/settings/users");
        }
        if (user_profile.scopes?.reports === "hide") {
          hiddenSettingsScopes.push("/admin/dashboard");
        }
        if (!!user_profile.listing_groups?.length) {
          if (user_profile.scopes?.messaging !== "write") {
            hiddenSettingsScopes.push("/admin/settings/messaging");
          }
          if (user_profile.scopes?.accounts !== "write") {
            hiddenSettingsScopes.push(
              "/admin/settings/accounts/:redirect/:redirect_status",
            );
          }
          if (user_profile.scopes?.smart_devices !== "write") {
            hiddenSettingsScopes.push("/admin/settings/devices");
          }
        }

        if (user_profile.scopes?.billing === "hide") {
          hiddenSettingsScopes.push("/admin/settings/billing");
        }

        newPaths = newPaths.filter((p) => !hiddenScopes.includes(p));
        return {
          selected_non_redirect_paths: newPaths,
          settings_paths_to_redirect: hiddenSettingsScopes,
        };
      }
    }, [user_profile, selectedViewItems, isMobileView]);

  const defaultPath = React.useMemo(() => {
    if (!!user_profile.homepage) {
      return user_profile.homepage.startsWith("/")
        ? user_profile.homepage.slice(1)
        : user_profile.homepage;
    } else if (user_profile.scopes?.messaging !== "hide") {
      return "messages";
    } else {
      let redirectPath = "messages";
      const selectedScopes = Object.keys(scopes).filter(
        (s) => !!scopes[s].path,
      );

      _.each(selectedScopes, (scope) => {
        if (user_profile.scopes[scope] !== "hide") {
          redirectPath = scopes[scope].path;
          return false;
        }
      });
      return redirectPath;
    }
  }, [user_profile]);

  React.useEffect(() => {
    if (!!user_profile.enso_user_id) {
      const userContext = {
        kind: "user",
        key: user_profile.enso_user_id,
        name: user_profile.name,
        email: user_profile.enso_user_email,
        //data: user_profile
      };
      const accountContext = {
        kind: "account",
        key: user_profile.enso_key,
        name: user_profile.reply_email,
      };
      const multiContext = {
        kind: "multi",
        user: userContext,
        account: accountContext,
      };
      if (!!ldClient) {
        ldClient.identify(multiContext);
      }
    }
  }, [user_profile, ldClient]);

  React.useEffect(() => {
    let new_enso_key = user_profile.enso_key;
    if (!new_enso_key || new_enso_key === current_user) return;

    if (!isMobile) {
      const secretKey = "uWsf-prpTz_OXvwS4Upzik8MmWszVgwBkp-I5vIx"; // an Identity Verification secret key (web)
      const userIdentifier = user_profile.enso_user_id; // a UUID to identify your user

      const hash = crypto
        .createHmac("sha256", secretKey)
        .update(userIdentifier)
        .digest("hex");
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "qzlj1nex",
        name: user_profile.name, // Full name
        email: user_profile.enso_user_email, // the email for your user
        user_id: user_profile.enso_user_id,
        created_at: user_profile.created_at, // Signup date as a Unix timestamp
        user_hash: hash,
      });
    }
    LogRocket.init("o1etem/enso-connect");
    LogRocket.identify(user_profile.enso_user_id, {
      name: user_profile.name,
      email: user_profile.enso_user_email,
      enso_key: new_enso_key,
    });
    openSocket(new_enso_key, user_profile.enso_user_id);
    dispatch(setUser(new_enso_key));
    const params = `enso_key=${new_enso_key}`;
    dispatch(getGuests({params}));
    dispatch(getHouses(params));
    if (!Object.keys(serviceAccounts?.accounts ?? {}).length) {
      dispatch(getServiceAccounts({}));
    }
    // check if user has permissions to view listing groups
    if (user_profile?.scopes?.listings !== "hide") {
      dispatch(getListingGroups());
    }
    dispatch(getBookings(params));
  }, [user_profile]);

  React.useEffect(() => {
    //check if session is active
    if (current_user) getCurrentSession();
    else {
      //else check if user is logged in
      Auth.currentAuthenticatedUser()
        .then((data) => getCurrentSession(data))
        // else redirect to login page
        .catch((err) => {
          console.log("ERROR", err);
          setAuthRequired((prev) => true);
        });
    }
    document.body.classList.toggle(classes.body);
  }, []);

  const getCurrentSession = (data) => {
    Auth.currentSession().then((session) => {
      dispatch(setKey(session.idToken.jwtToken));
      handleCurrentSession(data ? data.username : current_user);
    });
  };

  const handleCurrentSession = (enso_user_id) => {
    dispatch(getProfile(`enso_user_id=${enso_user_id}`));
  };

  const openSocket = (enso_key, enso_user_id) => {
    console.log("opening socket...", enso_key);
    const ws = new WebSocket(
      config.ws.URL +
        "?enso_key=" +
        enso_key +
        "&enso_user_id=" +
        enso_user_id +
        "&mobile=" +
        isMobile,
    );
    let interval = 0;
    ws.onopen = () => {
      console.log("Connected to Socket!");
      interval = setInterval(() => {
        ws.send("");
      }, 480000); // Send signal every 8 minutes to Keep alive the ws connection
    };
    ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (!data.connectionId) handleSocketIn(data);
    };
    ws.onclose = (e) => {
      console.log("socket closed");
      clearInterval(interval);
      openSocket(enso_key, enso_user_id);
    };
  };

  const handleSocketIn = async (data) => {
    console.log("webhook data: ", data);
    if (data?.message !== "Forbidden") {
      setNewNotif((prev) => data);
    }
  };

  React.useEffect(() => {
    if (["pay_now", "off", "cncl"].includes(user_profile?.plan)) {
      dispatch(setForceBilling(true));
    }
  }, [forceBilling, user_profile]);

  React.useEffect(() => {
    if (!newNotif) return;
    let data = newNotif;
    const notification = data.objects.notification;
    if (!!notification.errors?.length) {
      dispatch(
        openGeneralError({
          open: true,
          message: notification.message,
          disableSeverity: true,
          onClose: handleCloseGeneralError,
        }),
      );
    } else if (!!notification.display) {
      dispatch(
        openAlert(
          notification.notification_type,
          notification.message,
          notification.id,
        ),
      );
    }
    handleAttachedObjects(data.objects); //  Add/Update incoming objects
    setNewNotif((prev) => false);
  }, [newNotif]);

  const onCloseAlert = () => dispatch(closeAlert());
  const handleSignOut = () => {
    dispatch(resetStore());
    Auth.signOut().finally(() => setAuthRequired((prev) => true));
  };
  const handleCloseGeneralError = () => dispatch(closeGeneralError());

  if (authRequired) {
    return <Redirect push to="/auth/login" />;
  } else if (!!payloadRedirect) {
    return <Redirect push to={`/admin/${payloadRedirect}`} />;
  } else if (
    !!forceBilling &&
    !location.pathname.includes("/admin/settings/billing/:redirect")
  ) {
    return (
      <PaymentModal
        outsideOpen={true}
        confirmAction={() => history.push("/admin/settings/billing/:redirect")}
      />
    );
  } else if (
    !!current_user &&
    !selected_non_redirect_paths.some((nrp) => location.pathname.includes(nrp))
  ) {
    return <Redirect push to={`/admin/${defaultPath}`} />;
  } else if (
    !!current_user &&
    !!settings_paths_to_redirect.length &&
    settings_paths_to_redirect.some((nrp) => location.pathname.includes(nrp))
  ) {
    return <Redirect push to={`/admin/settings`} />;
  } else if (!current_user && !!groupId) {
    return (
      <Redirect
        push
        to={
          (!!matchCardSection?.isExact && card !== "timeline") ||
          !!matchItemCard?.isExact
            ? `/admin/listings/groups/${groupId}/${step}/${card}${item !== null ? `/${item}` : ""}`
            : `/admin/listings/groups/${groupId}`
        }
      />
    );
  } else if (location.pathname.includes("/admin/admin")) {
    return <Redirect to={location.pathname.replace("admin/admin", "admin")} />;
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.bgMobile]: !!isMobileWidth && !isIOS,
        [classes.bgMobileIOS]: !!isMobileWidth && isIOS,
        disablePadd: location.pathname.includes("/dashboard"),
      })}
    >
      {!isMobileWidth && (
        <AdminNavbar
          user_profile={user_profile}
          handleSignOut={handleSignOut}
        />
      )}
      <ErrorAlert {...generalErrorProps} />
      <SuccessAlert {...generalSuccessProps} />
      <NotificationAlert
        open={alert.open}
        onClose={onCloseAlert}
        message={alert.message}
      />
      <Switch>
        {routes.map((prop, key) =>
          prop.layout === "/admin" ? (
            <Route
              key={key}
              path={prop.layout + prop.path}
              component={prop.component}
            />
          ) : null,
        )}
      </Switch>
      {isMobileWidth && !hideNavbar && (
        <AdminNavbar
          user_profile={user_profile}
          handleSignOut={handleSignOut}
        />
      )}
    </div>
  );
}

export default withWidth()(Admin);
