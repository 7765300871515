import {API} from "aws-amplify";

export const getBookings = async (params) => {
  try {
    const response = await API.get("ensoAPI", `/bookings?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getVerificationReport = async (booking_id, guest_id) => {
  try {
    const response = await API.get(
      "ensoAPI",
      `/links?link_type=verification_report&booking_id=${booking_id}&guest_id=${guest_id}`,
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const patchBooking = async (body) => {
  try {
    const response = await API.patch("ensoAPI", "/bookings", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const patchBookingStatus = async (body) => {
  try {
    const response = await API.patch("ensoAPI", "/bookings/status", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getBookingCheckinouts = async (params) => {
  try {
    const response = await API.get("ensoAPI", `/bookings/checkinout?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};
