import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, MenuItem, Typography} from "@material-ui/core";
import RightArrowIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import {THEME} from "configuration/settings";

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(2),
    backgroundColor: "transparent !important",
    justifyContent: "space-between",
  },
  text: {textWrap: "wrap"},
  arrow: {
    color: "#e0e0e0",
    width: 14,
    height: 14,
    marginRight: -theme.spacing(3),
  },
}));

const ActionMenuItem = ({
  title,
  description,
  disableDivider,
  hasOptions,
  onClick,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <MenuItem
      button
      disableRipple
      onClick={onClick}
      disabled={disabled}
      className={classes.item}
      divider={!disableDivider}
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography className={classes.text} variant="button">
          {title}
        </Typography>
        {!!description && (
          <Typography className={classes.text} style={{color: THEME.subdued}}>
            {description}
          </Typography>
        )}
      </Box>
      {!!hasOptions && <RightArrowIcon className={classes.arrow} />}
    </MenuItem>
  );
};

export default ActionMenuItem;
