import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
// UI
import {Box, Typography, isWidthUp, withWidth} from "@material-ui/core";
import {useFlags, withLDConsumer} from "launchdarkly-react-client-sdk";
import useDashboardStyles from "styles/useDashboardStyles";
// Custom
import NextButton from "core/buttons/NextButton";
import BackButton from "core/buttons/BackButton";
import CloseIconButton from "core/buttons/CloseIconButton";
import MessagesPanel from "components/Panels/MessagesPanel";
import AIInsightsCard from "components/Cards/Dashboard/AIInsightsCard";
import GuestStatsCard from "components/Cards/Dashboard/GuestStatsCard";
import BookingStatsCard from "components/Cards/Dashboard/BookingStatsCard";
import MessageStatsCard from "components/Cards/Dashboard/MessageStatsCard";
import AIStatsCard from "components/Cards/Dashboard/AIStatsCard";
import RevenueStatsCard from "components/Cards/Dashboard/RevenueStatsCard";
import BookingInfoDrawer from "components/Panels/Booking/BookingInfoDrawer";
import DashboardSummaryPanel from "components/Panels/DashboardSummaryPanel";
import BoardingPassStatsCard from "components/Cards/Dashboard/BoardingPassStatsCard";
// API
import {
  getDashboardBPStats,
  getDashboardBookingStats,
  getDashboardGuestSatisfaction,
  getDashboardMessageStats,
  getDashboardMessageAnalytics,
  getDashboardRevenue,
  triggerDashboardRefresh,
} from "redux/actions/dashboardActions";
import {updateGuest} from "redux/actions/guestsActions";
import {setSelectedViewItem} from "redux/actions/accountsActions";
// Utils
import {endOfDay, startOfDay, subDays} from "date-fns";
import clsx from "clsx";

const Dashboard = ({width}) => {
  const dispatch = useDispatch();
  const flags = useFlags();
  const classes = useDashboardStyles();
  const didMount = useRef(false);
  const location = useLocation();
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const guests = useSelector((state) => state.defaultReducer.guests_dict);
  const messages = useSelector((state) => state.messagesReducer.messages);
  const refreshDasboard = useSelector(
    (state) => state.dashboardReducer.triggerDashboardRefresh,
  );
  const selectedViewItems = useSelector(
    (state) => state.defaultReducer.selected_view_items,
  );
  let savedItem = selectedViewItems.dashboard.item;
  let savedProps = selectedViewItems.dashboard.props;
  const [selectedGroups, setSelectedGroups] = useState(savedProps.groups ?? []);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [loadingGuestPanel, setLoadingGuestPanel] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [detailsPanelOpen, setDetailsPanelOpen] = useState(false);
  const [mobileSummaryOpen, setMobileSummaryOpen] = useState(true);
  const [mobileBucket, setMobileBucket] = useState(savedItem);
  const [mobileFilter, setMobileFilter] = useState(savedProps.filter);
  const [unansweredUpdate, setUnansweredUpdate] = useState(null);
  const [refreshCompleted, setRefreshCompleted] = useState({
    summary: true,
    revenueStats: true,
    bpStats: true,
    guestStats: true,
    bookingStats: true,
    messageStats: true,
    messageAnalytics: true,
  });
  const [noDataAvailable, setNoDataAvailable] = useState({
    summary: false,
    revenueStats: false,
    bpStats: false,
    guestStats: false,
    bookingStats: false,
    messageStats: false,
    messageAnalytics: false,
  });
  const [loading, setLoading] = useState({
    summary: true,
    revenueStats: true,
    bpStats: true,
    guestStats: true,
    bookingStats: true,
    messageStats: true,
    messageAnalytics: true,
  });
  let isReportsHidden = user_profile.scopes?.reports === "hide";

  const handleDataLoaded = (type) => {
    setLoading((prev) => ({...prev, [type]: false}));
  };

  const handleDataFailed = (type) => {
    setNoDataAvailable((prev) => ({...prev, [type]: true}));
    handleDataLoaded(type);
  };

  useEffect(() => {
    if (width === "lg" && !!detailsPanelOpen) {
      setDetailsPanelOpen((prev) => false);
    }
    if (width !== "xs" && !mobileSummaryOpen) {
      setMobileSummaryOpen((prev) => true);
    }
  }, [width]);

  useEffect(() => {
    if (!!refreshDasboard) {
      setRefreshCompleted(() => ({
        summary: false,
        revenueStats: false,
        bpStats: false,
        guestStats: false,
        bookingStats: false,
        messageStats: false,
        messageAnalytics: false,
      }));
    }
  }, [refreshDasboard]);

  useEffect(() => {
    if (Object.keys(refreshCompleted).every((k) => !!refreshCompleted[k])) {
      if (!!refreshDasboard) {
        dispatch(triggerDashboardRefresh(false));
      }
    }
  }, [refreshCompleted]);

  useEffect(() => {
    if (!current_user || !!didMount.current) {
      return;
    }
    let now = Date.now();
    let startDateToday = new Date(startOfDay(now)).getTime();
    let endDateToday = new Date(endOfDay(now)).getTime();
    let startDateLast30Days = new Date(subDays(startDateToday, 29)).getTime();
    let endDateLast30Days = endDateToday;

    if (!savedProps.revenueStatsRange) {
      dispatch(
        getDashboardRevenue({
          start_date: startDateLast30Days,
          end_date: endDateLast30Days,
          onSuccess: () => handleDataLoaded("revenueStats"),
          onError: () => handleDataFailed("revenueStats"),
        }),
      );
    }
    if (!savedProps.BPStatsRange) {
      dispatch(
        getDashboardBPStats({
          start_date: startDateLast30Days,
          end_date: endDateLast30Days,
          onSuccess: () => handleDataLoaded("bpStats"),
          onError: () => handleDataFailed("bpStats"),
        }),
      );
    }
    if (!savedProps.guestsStatsRange) {
      dispatch(
        getDashboardGuestSatisfaction({
          start_date: startDateLast30Days,
          end_date: endDateLast30Days,
          onSuccess: () => handleDataLoaded("guestStats"),
          onError: () => handleDataFailed("guestStats"),
        }),
      );
    }
    if (!savedProps.bookingStatsRange) {
      dispatch(
        getDashboardBookingStats({
          start_date: startDateLast30Days,
          end_date: endDateLast30Days,
          onSuccess: () => handleDataLoaded("bookingStats"),
          onError: () => handleDataFailed("bookingStats"),
        }),
      );
    }
    dispatch(
      getDashboardMessageStats({
        start_date: startDateLast30Days,
        end_date: endDateLast30Days,
        onSuccess: () => handleDataLoaded("messageStats"),
        onError: () => handleDataFailed("messageStats"),
      }),
    );
    dispatch(
      getDashboardMessageAnalytics({
        start_date: startDateLast30Days,
        end_date: endDateLast30Days,
        onSuccess: () => handleDataLoaded("messageAnalytics"),
        onError: () => handleDataFailed("messageAnalytics"),
      }),
    );
    didMount.current = true;
  }, [current_user]);

  const handleGroupChange = (newGroups) => {
    setSelectedGroups((prev) => newGroups);
  };
  const openDetails = () => {
    setDetailsPanelOpen((prev) => true);
  };
  const closeDetails = () => {
    setDetailsPanelOpen((prev) => false);
  };

  const handleRefreshCompleted =
    (key, updateLoading = false) =>
    () => {
      if (updateLoading) {
        handleDataLoaded(key);
      }
      setRefreshCompleted((prev) => ({...prev, [key]: true}));
    };

  const openMessagingPanel = (guest, isMobile) => {
    let guestId = guest.guest_id;
    setSelectedGuest((prev) => guestId);
    if (isMobile) {
      setMobileSummaryOpen((prev) => false);
    }
    if (!!guest.unread_messages) {
      dispatch(
        updateGuest({
          guest_id: guestId,
          field: "unread_messages",
          val: false,
          disableAlert: true,
        }),
      );
    }
  };

  const saveSelectedItems = (item = null, props = {}) => {
    let defaultProps = {
      filter: "all",
      groups: [],
      upcomingRange: null,
    };
    dispatch(
      setSelectedViewItem("dashboard", item, {...defaultProps, ...props}),
    );
  };

  const saveSelectedRanges = (key, newRange) => {
    dispatch(setSelectedViewItem("dashboard", savedItem, {[key]: newRange}));
  };

  const archiveChat = (guest) => {
    if (!!guest.unanswered_messages || !!guest.unread_messages) {
      let guestId = guest.guest_id;
      dispatch(
        updateGuest({
          guest_id: guestId,
          disableAlert: true,
          multipleFields: {
            unread_messages: false,
            unanswered_messages: false,
          },
        }),
      );
    }
  };

  const closeMessages = (isMobile) => {
    setSelectedGuest((prev) => null);
    setUnansweredUpdate((prev) => null);
    if (isMobile) {
      setMobileSummaryOpen((prev) => true);
      setDetailsPanelOpen((prev) => false);
    }
  };

  const onMessageSent = () => {
    setUnansweredUpdate((prev) => ({value: false}));
  };
  const onMessageError = () => {
    setUnansweredUpdate((prev) => ({value: true}));
  };

  const guestMessagingPanels = (
    <Box className={classes.messagingContainer} ml={-4}>
      <Box className={classes.closeIconContainer}>
        <CloseIconButton sm onClick={closeMessages} />
      </Box>
      <Box flex={1}>
        <MessagesPanel
          onMessageSent={onMessageSent}
          onMessageError={onMessageError}
          guestId={selectedGuest}
          initialGuestMsgs={messages[selectedGuest]?.messages || []}
          loadingAnimations={loadingMessages}
          setLoadingAnimations={(val) => setLoadingMessages((prev) => val)}
        />
      </Box>
      <Box flex={1} className={classes.hideScrollbar} overflow={"auto"}>
        <BookingInfoDrawer
          hideRedirectIcon
          guestId={selectedGuest}
          loadingAnimations={loadingGuestPanel}
          setLoadingAnimations={(val) => setLoadingGuestPanel((prev) => val)}
        />
      </Box>
    </Box>
  );

  const oneColumnGuestMessaging = (
    <Box className={clsx(classes.messagingContainer, "oneColumn")}>
      <Box
        className={clsx(classes.closeIconContainer, {
          messaging: !detailsPanelOpen,
          detailsPanel: detailsPanelOpen,
        })}
      >
        {!detailsPanelOpen && (
          <NextButton
            secondary
            transparent
            goBack={openDetails}
            label={`${guests[selectedGuest]?.name ?? "Guest"} Profile`}
          />
        )}
        {!!detailsPanelOpen && (
          <BackButton
            header
            goBack={closeDetails}
            label={"Chat"}
            icon={"chat"}
          />
        )}
        <CloseIconButton sm onClick={closeMessages} />
      </Box>
      <Box flex={1} display="flex" flexDirection="column">
        {!detailsPanelOpen ? (
          <MessagesPanel
            onMessageSent={onMessageSent}
            onMessageError={onMessageError}
            guestId={selectedGuest}
            initialGuestMsgs={messages[selectedGuest]?.messages || []}
            loadingAnimations={loadingMessages}
            setLoadingAnimations={(val) => setLoadingMessages((prev) => val)}
          />
        ) : (
          <BookingInfoDrawer
            hideRedirectIcon
            extraTopPadding
            guestId={selectedGuest}
            loadingAnimations={loadingGuestPanel}
            setLoadingAnimations={(val) => setLoadingGuestPanel((prev) => val)}
          />
        )}
      </Box>
    </Box>
  );

  if (isWidthUp("lg", width)) {
    // 1281+ => 3 columns
    return (
      <div className={classes.container}>
        <DashboardSummaryPanel
          unansweredUpdate={unansweredUpdate}
          refreshDasboard={refreshDasboard}
          noData={noDataAvailable.summary}
          loading={loading.summary}
          fullRounded={!selectedGuest}
          selectedGuest={selectedGuest}
          saveSelectedItems={saveSelectedItems}
          onRefreshCompleted={handleRefreshCompleted("summary", !!savedItem)}
          setLoading={(newVal) =>
            setLoading((prev) => ({...prev, summary: newVal}))
          }
          openMessagingPanel={openMessagingPanel}
          archiveChat={archiveChat}
          updateSelectedGroups={handleGroupChange}
        />
        {isReportsHidden && !!selectedGuest && (
          <Box
            overflow={"auto"}
            className={clsx(
              classes.cardsContainer,
              classes.col,
              classes.gap20,
              classes.fullWidth,
              classes.fullHeight,
              classes.hideScrollbar,
            )}
          >
            {guestMessagingPanels}
          </Box>
        )}
        {!isReportsHidden && (
          <Box
            overflow={"auto"}
            className={clsx(
              classes.cardsContainer,
              classes.col,
              classes.gap20,
              classes.fullWidth,
              classes.fullHeight,
              classes.hideScrollbar,
            )}
          >
            {!!selectedGuest ? (
              guestMessagingPanels
            ) : (
              <>
                <AIInsightsCard loading={loading.summary} />
                <RevenueStatsCard
                  refreshDasboard={refreshDasboard}
                  selectedGroups={selectedGroups}
                  noData={noDataAvailable.revenueStats}
                  loading={loading.revenueStats}
                  saveSelectedRange={(newRange) =>
                    saveSelectedRanges("revenueStatsRange", newRange)
                  }
                  onRefreshCompleted={handleRefreshCompleted(
                    "revenueStats",
                    !!savedProps.revenueStatsRange,
                  )}
                />
                {!!flags.advancedAi && (
                  <Box
                    className={clsx(classes.row, classes.gap20, classes.flex1)}
                  >
                    <AIStatsCard
                      row
                      refreshDasboard={refreshDasboard}
                      selectedGroups={selectedGroups}
                      loading={loading.bookingStats}
                      onRefreshCompleted={handleRefreshCompleted(
                        "bookingStats",
                      )}
                    />
                    <MessageStatsCard
                      row
                      refreshDasboard={refreshDasboard}
                      selectedGroups={selectedGroups}
                      loading={loading.messageStats}
                      onRefreshCompleted={handleRefreshCompleted(
                        "messageStats",
                      )}
                    />
                  </Box>
                )}
                <BoardingPassStatsCard
                  refreshDasboard={refreshDasboard}
                  selectedGroups={selectedGroups}
                  loading={loading.bpStats}
                  saveSelectedRange={(newRange) =>
                    saveSelectedRanges("BPStatsRange", newRange)
                  }
                  onRefreshCompleted={handleRefreshCompleted(
                    "bpStats",
                    !!savedProps.BPStatsRange,
                  )}
                />
                <Box
                  className={clsx(classes.row, classes.gap20, classes.flex1)}
                >
                  <GuestStatsCard
                    refreshDasboard={refreshDasboard}
                    selectedGroups={selectedGroups}
                    loading={loading.guestStats}
                    saveSelectedRange={(newRange) =>
                      saveSelectedRanges("guestsStatsRange", newRange)
                    }
                    onRefreshCompleted={handleRefreshCompleted(
                      "guestStats",
                      !!savedProps.guestsStatsRange,
                    )}
                  />
                  <BookingStatsCard
                    refreshDasboard={refreshDasboard}
                    selectedGroups={selectedGroups}
                    loading={loading.bookingStats}
                    saveSelectedRange={(newRange) =>
                      saveSelectedRanges("bookingStatsRange", newRange)
                    }
                    onRefreshCompleted={handleRefreshCompleted(
                      "bookingStats",
                      !!savedProps.bookingStatsRange,
                    )}
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      </div>
    );
  } else if (isWidthUp("md", width)) {
    // 993 - 1280 (lg) => 2 columns
    return (
      <div className={classes.container}>
        <DashboardSummaryPanel
          unansweredUpdate={unansweredUpdate}
          refreshDasboard={refreshDasboard}
          selectedGuest={selectedGuest}
          saveSelectedItems={saveSelectedItems}
          onRefreshCompleted={handleRefreshCompleted("summary", !!savedItem)}
          noData={noDataAvailable.summary}
          loading={loading.summary}
          archiveChat={archiveChat}
          setLoading={(newVal) =>
            setLoading((prev) => ({...prev, summary: newVal}))
          }
          openMessagingPanel={openMessagingPanel}
          updateSelectedGroups={handleGroupChange}
        />
        {isReportsHidden && !!selectedGuest && oneColumnGuestMessaging}
        {!isReportsHidden &&
          (!!selectedGuest ? (
            oneColumnGuestMessaging
          ) : (
            <Box
              overflow={"auto"}
              className={clsx(
                classes.cardsContainer,
                classes.col,
                classes.gap20,
                classes.fullWidth,
                classes.fullHeight,
                classes.hideScrollbar,
              )}
            >
              <AIInsightsCard loading={loading.summary} />
              <RevenueStatsCard
                refreshDasboard={refreshDasboard}
                selectedGroups={selectedGroups}
                noData={noDataAvailable.revenueStats}
                loading={loading.revenueStats}
                saveSelectedRange={(newRange) =>
                  saveSelectedRanges("revenueStatsRange", newRange)
                }
                onRefreshCompleted={handleRefreshCompleted(
                  "revenueStats",
                  !!savedProps.revenueStatsRange,
                )}
              />
              <BoardingPassStatsCard
                refreshDasboard={refreshDasboard}
                selectedGroups={selectedGroups}
                loading={loading.bpStats}
                saveSelectedRange={(newRange) =>
                  saveSelectedRanges("BPStatsRange", newRange)
                }
                onRefreshCompleted={handleRefreshCompleted(
                  "bpStats",
                  !!savedProps.BPStatsRange,
                )}
              />
              <GuestStatsCard
                row
                refreshDasboard={refreshDasboard}
                selectedGroups={selectedGroups}
                loading={loading.guestStats}
                saveSelectedRange={(newRange) =>
                  saveSelectedRanges("guestsStatsRange", newRange)
                }
                onRefreshCompleted={handleRefreshCompleted(
                  "guestStats",
                  !!savedProps.guestsStatsRange,
                )}
              />
              <BookingStatsCard
                row
                refreshDasboard={refreshDasboard}
                selectedGroups={selectedGroups}
                loading={loading.bookingStats}
                saveSelectedRange={(newRange) =>
                  saveSelectedRanges("bookingStatsRange", newRange)
                }
                onRefreshCompleted={handleRefreshCompleted(
                  "bookingStats",
                  !!savedProps.bookingStatsRange,
                )}
              />
              <AIStatsCard
                row
                refreshDasboard={refreshDasboard}
                selectedGroups={selectedGroups}
                loading={loading.messageAnalytics}
                onRefreshCompleted={handleRefreshCompleted("messageAnalytics")}
              />
              <MessageStatsCard
                row
                refreshDasboard={refreshDasboard}
                selectedGroups={selectedGroups}
                loading={loading.messageStats}
                onRefreshCompleted={handleRefreshCompleted("messageStats")}
              />
            </Box>
          ))}
      </div>
    );
  } else {
    // 0 - 992 (md) => 1 column
    return (
      <Box className={classes.mobileContainer}>
        {location.pathname.includes("/home") ? (
          <Box
            className={clsx(classes.mobileChartsContainer, {
              whiteBG: !mobileSummaryOpen && detailsPanelOpen,
            })}
          >
            {mobileSummaryOpen && (
              <Typography
                className={clsx(classes.mobileDashboardHeader, "home")}
              >
                {"Home"}
              </Typography>
            )}
            {mobileSummaryOpen ? (
              <DashboardSummaryPanel
                mobile
                fullRounded
                selectedGuest={selectedGuest}
                unansweredUpdate={unansweredUpdate}
                refreshDasboard={refreshDasboard}
                initialMobileBucket={mobileBucket}
                initialFilter={mobileFilter}
                setInitialMobileBucket={setMobileBucket}
                setInitialFilter={setMobileFilter}
                saveSelectedItems={saveSelectedItems}
                onRefreshCompleted={handleRefreshCompleted(
                  "summary",
                  !!savedItem,
                )}
                noData={noDataAvailable.summary}
                loading={loading.summary}
                setLoading={(newVal) =>
                  setLoading((prev) => ({...prev, summary: newVal}))
                }
                openMessagingPanel={(guest) => openMessagingPanel(guest, true)}
                archiveChat={archiveChat}
                updateSelectedGroups={handleGroupChange}
              />
            ) : (
              <Box
                className={clsx(classes.mobileMessagingContainer, {
                  guestDetails: detailsPanelOpen,
                })}
              >
                <Box
                  className={clsx(classes.closeIconContainer, {
                    detailsPanel: detailsPanelOpen,
                    mobileMessaging: !detailsPanelOpen,
                    mobileBookingDrawer: detailsPanelOpen,
                  })}
                >
                  {!detailsPanelOpen && (
                    <NextButton
                      secondary
                      transparent
                      goBack={openDetails}
                      label={`${guests[selectedGuest]?.name ?? "Guest"} Profile`}
                    />
                  )}
                  {!!detailsPanelOpen && (
                    <BackButton
                      header
                      goBack={closeDetails}
                      label={"Chat"}
                      icon={"chat"}
                    />
                  )}
                  <CloseIconButton sm onClick={() => closeMessages(true)} />
                </Box>
                {!detailsPanelOpen ? (
                  <MessagesPanel
                    onMessageSent={onMessageSent}
                    onMessageError={onMessageError}
                    guestId={selectedGuest}
                    initialGuestMsgs={messages[selectedGuest]?.messages || []}
                    loadingAnimations={loadingMessages}
                    setLoadingAnimations={(val) =>
                      setLoadingMessages((prev) => val)
                    }
                  />
                ) : (
                  <BookingInfoDrawer
                    smPadd
                    hideRedirectIcon
                    guestId={selectedGuest}
                    loadingAnimations={loadingGuestPanel}
                    setLoadingAnimations={(val) =>
                      setLoadingGuestPanel((prev) => val)
                    }
                  />
                )}
              </Box>
            )}
          </Box>
        ) : (
          <Box
            className={clsx(
              classes.mobileChartsContainer,
              "padd",
              classes.hideScrollbar,
            )}
          >
            <Typography className={classes.mobileDashboardHeader}>
              {"Dashboard"}
            </Typography>
            <RevenueStatsCard
              mobile
              refreshDasboard={refreshDasboard}
              selectedGroups={selectedGroups}
              noData={noDataAvailable.revenueStats}
              loading={loading.revenueStats}
              saveSelectedRange={(newRange) =>
                saveSelectedRanges("revenueStatsRange", newRange)
              }
              onRefreshCompleted={handleRefreshCompleted(
                "revenueStats",
                !!savedProps.revenueStatsRange,
              )}
            />
            <BoardingPassStatsCard
              mobile
              refreshDasboard={refreshDasboard}
              selectedGroups={selectedGroups}
              loading={loading.bpStats}
              saveSelectedRange={(newRange) =>
                saveSelectedRanges("BPStatsRange", newRange)
              }
              onRefreshCompleted={handleRefreshCompleted(
                "bpStats",
                !!savedProps.BPStatsRange,
              )}
            />
            <GuestStatsCard
              row
              mobile
              refreshDasboard={refreshDasboard}
              selectedGroups={selectedGroups}
              loading={loading.guestStats}
              saveSelectedRange={(newRange) =>
                saveSelectedRanges("guestsStatsRange", newRange)
              }
              onRefreshCompleted={handleRefreshCompleted(
                "guestStats",
                !!savedProps.guestsStatsRange,
              )}
            />
            <BookingStatsCard
              row
              mobile
              refreshDasboard={refreshDasboard}
              selectedGroups={selectedGroups}
              loading={loading.bookingStats}
              saveSelectedRange={(newRange) =>
                saveSelectedRanges("bookingStatsRange", newRange)
              }
              onRefreshCompleted={handleRefreshCompleted(
                "bookingStats",
                !!savedProps.bookingStatsRange,
              )}
            />
          </Box>
        )}
      </Box>
    );
  }
};

export default withWidth()(withLDConsumer()(Dashboard));
