import React from "react";
import _ from "lodash";
import {usePlacesWidget} from "react-google-autocomplete";
import {Grid, TextField, makeStyles} from "@material-ui/core";
import WarningIcon from "assets/icons/WarningIcon";
import {isAddressValid} from "utilities/helperFunctions";
import {useTranslation} from "react-i18next";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const useStyles = makeStyles((theme) => ({
  textField: {borderRadius: "12px !important"},
}));

export default function KeycardAddressAutocomplete({
  address,
  onChange,
  textFieldProps = {},
}) {
  const classes = useStyles();
  const {t} = useTranslation();
  const [showAddressFields, setShowAddressFields] = React.useState(null);
  let helperText =
    !!textFieldProps.helperText && !address?.formatted
      ? textFieldProps.helperText
      : undefined;

  React.useEffect(() => {
    if (!address?.formatted && !!showAddressFields) {
      setShowAddressFields((prev) => null);
    }
  }, [address]);

  const onPlaceSelected = (place, inputRef) => {
    setShowAddressFields((prev) => null);
    let hasErrors = false;
    const newAddress = {
      apt_number: "",
      city: "",
      country: "",
      state: "",
      street: "",
      zip_code: "",
      formatted: inputRef.value,
      lat: place.geometry?.location.lat() || "",
      lng: place.geometry?.location.lng() || "",
    };

    _.each(place.address_components || [], (addrComp) => {
      if (addrComp.types.includes("street_number")) {
        newAddress.street += `${addrComp.long_name}`;
      }
      if (addrComp.types.includes("route")) {
        newAddress.street = !!newAddress.street
          ? `${newAddress.street} ${addrComp.long_name}`
          : addrComp.long_name;
      }
      if (addrComp.types.includes("locality")) {
        newAddress.city = addrComp.long_name;
      }
      if (
        !newAddress.city &&
        addrComp.types.includes("administrative_area_level_2")
      ) {
        newAddress.city = addrComp.long_name;
      }
      if (!newAddress.city && addrComp.types.includes("postal_town")) {
        newAddress.city = addrComp.long_name;
      }
      if (addrComp.types.includes("country")) {
        newAddress.country = addrComp.short_name;
      }
      if (addrComp.types.includes("postal_code")) {
        newAddress.zip_code = addrComp.long_name;
      }
      if (addrComp.types.includes("administrative_area_level_1")) {
        newAddress.state = addrComp.long_name;
      }
    });

    if (!!textFieldProps.required) {
      let validAddressObj = isAddressValid(newAddress, true);
      if (!validAddressObj.valid) {
        hasErrors = true;
        setShowAddressFields((prev) => validAddressObj.errors);
      } else if (!!showAddressFields) {
        hasErrors = false;
        setShowAddressFields((prev) => null);
      }
    }
    onChange(newAddress, hasErrors);
  };

  const {ref} = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: onPlaceSelected,
    options: {
      types: ["address"], // https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
      fields: ["address_components", "formatted_address", "geometry.location"], // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
    },
  });

  function getMissingAddressField(field) {
    let name = t(field);
    return (
      <TextField
        focused
        fullWidth
        label={name}
        variant="outlined"
        InputProps={{classes: {root: classes.textField}}}
        InputLabelProps={{shrink: true}}
        value={address?.[field] ?? ""}
        error={!!textFieldProps.error && !!showAddressFields?.[field]}
        helperText={
          !!textFieldProps.error && !!showAddressFields?.[field] ? (
            <>
              <WarningIcon style={{marginBottom: -12}} />
              {name} {t("is_required")}
            </>
          ) : undefined
        }
        onChange={(e) => onChange({...address, [field]: e.target.value})}
      />
    );
  }

  return (
    <>
      <TextField
        label={t("address")}
        inputRef={ref}
        variant="outlined"
        InputProps={{classes: {root: classes.textField}}}
        placeholder="5 Main Street"
        onChange={(e) => onChange({...address, formatted: e.target.value})}
        {...textFieldProps}
        error={!!textFieldProps.error && !showAddressFields}
        helperText={helperText}
      />
      {!!showAddressFields && (
        <Grid container spacing={2} padding={2}>
          {showAddressFields.street && (
            <Grid item xs={12}>
              {getMissingAddressField("street")}
            </Grid>
          )}
          {showAddressFields.city && (
            <Grid item xs={12} sm={6}>
              {getMissingAddressField("city")}
            </Grid>
          )}
          {showAddressFields.country && (
            <Grid item xs={12} sm={6}>
              {getMissingAddressField("country")}
            </Grid>
          )}
          {showAddressFields.state && (
            <Grid item xs={12} sm={6}>
              {getMissingAddressField("state")}
            </Grid>
          )}
          {showAddressFields.zip_code && (
            <Grid item xs={12} sm={6}>
              {getMissingAddressField("zip_code")}
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}
