import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import {useFlags, withLDConsumer} from "launchdarkly-react-client-sdk";
// UI
import {makeStyles} from "@material-ui/core/styles";
// Custom
import Panel2 from "core/panels/Panel2.jsx";
import Panel3 from "core/panels/Panel3";
import DevicePanel from "components/Panels/Device/DevicePanel.jsx";
import DeviceList from "components/Lists/DeviceList.jsx";
// Actions
import {setSelectedViewItem} from "redux/actions/accountsActions";
import {getDevices} from "redux/actions/devicesActions";
// Utils
import usePrevious from "hooks/usePrevious";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
}));

const Devices = () => {
  const classes = useStyles();
  const flags = useFlags();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch("/admin/settings/devices/:device_id");
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const isTabletView =
    useSelector((state) => state.defaultReducer.deviceType) === "tablet";
  const selectedViewItems = useSelector(
    (state) => state.defaultReducer.selected_view_items,
  );
  let savedItem = selectedViewItems.settings.item;
  let savedDevice = selectedViewItems.settings.props.selectedDevice;
  const [devices, setDevices] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(savedDevice);
  const [seamDevices, setSeamDevices] = useState(null);
  const prevDevices = usePrevious(seamDevices);
  const readOnly = user_profile.scopes?.smart_devices !== "write";

  React.useEffect(() => {
    if (!!savedDevice) {
      history.replace(`/admin/settings/devices/${savedDevice.device_id}`);
    }
  }, []);

  React.useEffect(() => {
    if (prevDevices === null && seamDevices !== null && match?.isExact) {
      const device_id = `${match.params.device_id}${location.hash}`;
      setSelectedDevice((prev) =>
        seamDevices.find((d) => d.device_id === device_id),
      );
    }
  }, [seamDevices]);

  React.useEffect(() => {
    if (!!current_user && devices === null) {
      let params = `enso_key=${current_user}`;
      dispatch(
        getDevices(params, (newDevices) => {
          setDevices(newDevices);
        }),
      );
    }
  }, [current_user]);

  React.useEffect(() => {
    let newProps = {
      selectedDevice: selectedDevice,
      selectedUser: null,
      connectedAccount: {
        account: null,
        accountType: null,
        integrationSpec: null,
      },
    };
    dispatch(setSelectedViewItem("settings", savedItem, newProps));
  }, [selectedDevice]);

  function handleDeviceUpdate(newDevice) {
    const newDevices = devices.map((d) => {
      if (d.device_id === newDevice.device_id) {
        return newDevice;
      } else {
        return d;
      }
    });
    setDevices(newDevices);
  }

  function removeDevice(device) {
    const newDevices = devices.filter((d) => d.device_id !== device.device_id);
    setDevices(newDevices);
  }

  const handleDeviceSelect = (device) => {
    setSelectedDevice((prev) => device);
    history.replace(`/admin/settings/devices/${device.device_id}`);
  };

  const handleDeviceClose = () => {
    setSelectedDevice((prev) => null);
    history.replace("/admin/settings/devices");
  };

  const device_list = (
    <DeviceList
      selectedDevice={selectedDevice}
      initialDevices={devices || []}
      onDeviceSelect={handleDeviceSelect}
      updateSeamDevices={setSeamDevices}
      includeHeader={true}
      disableEdit={!!readOnly}
    />
  );

  function getPanel3() {
    if (!selectedDevice) {
      return;
    }
    if (!!match?.isExact) {
      return (
        <Switch>
          <Route path={"/admin/settings/devices/:device_id"}>
            <DevicePanel
              selectedDevice={selectedDevice}
              disableEdit={!!readOnly}
              onDeviceEdit={(d) => handleDeviceUpdate(d)}
              removeDevice={(d) => removeDevice(d)}
              handleClose={handleDeviceClose}
            />
          </Route>
        </Switch>
      );
    } else return null;
  }

  return (
    <div className={classes.root}>
      <Panel2
        flexAuto
        content={device_list}
        isPanel3Open={isTabletView ? false : !!selectedDevice}
        hidden={isTabletView && !!selectedDevice}
      />
      {(!isTabletView || !!selectedDevice) && (
        <Panel3
          content={getPanel3()}
          hidden={!selectedDevice}
          maintainWidth={!isTabletView}
          disableMaxWidth={isTabletView}
        />
      )}
    </div>
  );
};

export default withLDConsumer()(Devices);
