import {makeStyles} from "@material-ui/core/styles";

const useThreadListStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
  },
  header: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  searchContainer: {
    padding: theme.spacing(0, 2),
    zIndex: 1,
    "& > .searchContainer": {paddingBottom: 0},
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    overscrollBehaviorY: "contain",
    position: "relative",
  },
  refreshSection: {
    width: "fit-content",
    position: "absolute",
    top: -44,
    left: "50%",
    transform: "translateX(-50%)",
    "& > .refresh-icon": {color: theme.palette.secondary.main},
    "&.loading": {top: "0 !important"},
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexGrow: 1,
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    paddingRight: theme.spacing(1),
    "&.selected": {color: theme.palette.common.white},
  },
  name: {WebkitLineClamp: 1},
  previewMsg: {WebkitLineClamp: 2},
  iconsColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  timeSince: {
    width: "auto",
    textAlign: "right",
    color: theme.palette.text.disabled,
    whiteSpace: "nowrap",
    "&.selected": {color: theme.palette.common.white},
  },
  iconContainer: {
    width: 15,
    height: 15,
    display: "flex",
    alignSelf: "flex-end",
    justifyContent: "center",
    marginRight: theme.spacing(1),
  },
  icon: {
    width: 15,
    height: 15,
    objectFit: "contain",
  },
  dotContainer: {
    minWidth: 19,
    width: 19,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing(1),
  },
  stage: {
    padding: 5,
    borderRadius: 5,
    color: "#FFFFFF",
  },
  listItemContainer: {
    height: "100%",
    padding: theme.spacing(2, 2, 0),
  },
  listItem: {
    height: "100%",
    borderRadius: 10,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    transition: "0s",
    "&.selected": {
      backgroundColor: theme.palette.secondary.main + "!important",
    },
  },
  cardActionArea: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    "& > span": {opacity: "0 !important"},
  },
  mobileArrow: {
    position: "absolute",
    right: 6,
    top: "50%",
  },
  arrow: {
    fontSize: 16,
    color: theme.palette.action.focus,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  moreIcon: {
    fontSize: 17,
    marginLeft: theme.spacing(1),
    "&.selected": {color: theme.palette.common.white + "!important"},
  },
  subdued: {color: "rgba(60, 60, 67, 0.6)"},
  container: {
    height: "100%",
    overflow: "hidden",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
  },
  secondaryText: {
    flexGrow: 1,
    alignItems: "flex-start",
  },
  archiveMenuItem: {color: "#EE4F4F"},
  archiveBtnContainer: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  archiveBtn: {
    borderRadius: 10,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    textTransform: "none",
    background: "rgba(109, 113, 117, 0.10)",
  },
  list: {
    "& .ReactVirtualized__Grid__innerScrollContainer": {
      overflow: "visible !important",
    },
  },
  dropdownList: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(3) + "px !important",
  },
  dropdownListItem: {
    borderRadius: 10,
    padding: theme.spacing(2),
  },
  itemText: {margin: 0},
  itemTitle: {
    fontSize: 14,
    fontWeight: 500,
  },
  divider: {backgroundColor: "rgba(217, 217, 217, 0.50)"},
}));

export default useThreadListStyles;
