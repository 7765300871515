import React from "react";
import {Checkbox, Chip, ListItemText, MenuItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import SimpleField from "./SimpleField";
import FilledSelect from "core/selects/FilledSelect";
import SearchBar from "core/bars/SearchBar";
import {BookingTagEnum, guestTagEnum} from "configuration/enums";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  tag: {
    backgroundColor: "rgba(67,84,96,0.07)",
    color: "rgba(67,84,96,0.5)",
    fontSize: 12,
    height: 20,
    width: "fit-content",
  },
}));

export default function Tags({
  tags = [],
  type = "listing",
  enableSearch = false,
  customFieldData = {},
  edit,
  value,
  multiselect,
  onValueChange,
}) {
  const classes = useStyles();
  const [selectedTags, setSelectedTags] = React.useState(
    initSelectedTags(value),
  );
  const [searchText, setSearchText] = React.useState("");

  function initSelectedTags(v) {
    console.log("GOT V", v);
    if (multiselect && v && !Array.isArray(v)) {
      return [v];
    }
    return v;
  }

  const availableTags = {
    listing:
      customFieldData["Listing"]?.find((c) => c.field == "listing_tags")
        ?.data || [],
    guest:
      customFieldData["Guest"]?.find((c) => c.field == "guest_tags")?.data ||
      Object.values(guestTagEnum),
    booking: Object.values(BookingTagEnum),
  }[type];

  /*React.useEffect(() => {
    if (edit && value !== selectedTags) {
      setSelectedTags((prev) => value);
    }
  }, [value]);*/

  React.useEffect(() => {
    if (edit) {
      onValueChange(selectedTags);
    }
  }, [selectedTags]);

  const handleTagChange = (e) => {
    const val = e.target.value;
    setSearchText((prev) => "");
    setSelectedTags((prev) => val);
  };

  if (edit) {
    let avalTags = !!enableSearch
      ? availableTags.filter((t) =>
          t.toLowerCase().includes(searchText.toLocaleLowerCase()),
        )
      : availableTags;

    console.log("GOT SELECTED", selectedTags);
    return (
      <div className={classes.root}>
        <FilledSelect
          fullWidth
          label="Tag(s)"
          value={selectedTags || []}
          onChange={handleTagChange}
          multiple={multiselect}
          style={{minWidth: 150}}
          renderValue={
            multiselect ? (selectedTags) => selectedTags.join(", ") : null
          }
        >
          <MenuItem value="select-a-tag" disabled>
            Select a tag
          </MenuItem>
          {!!enableSearch && (
            <SearchBar
              stopPropagation
              searchInput={searchText}
              handleSearchInput={(val) => setSearchText((prev) => val)}
            />
          )}
          {!avalTags.length && <MenuItem disabled>No results</MenuItem>}
          {avalTags.map((tag) => (
            <MenuItem value={tag} key={tag}>
              {multiselect && <Checkbox checked={value.includes(tag)} />}
              <ListItemText primary={tag} />
            </MenuItem>
          ))}
        </FilledSelect>
      </div>
    );
  } else {
    if (!tags.length) {
      return <SimpleField value="-" />;
    }
    return tags.map((tag) => (
      <Chip key={tag} size="small" label={tag} className={classes.tag} />
    ));
  }
}
