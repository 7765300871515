import React from "react";
import {Col, Row} from "reactstrap";
import MuiSkeleton from "@material-ui/lab/Skeleton";
import SearchIcon from "@material-ui/icons/Search";
import RightArrowIcon from "@material-ui/icons/ArrowForwardIos";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import useEmptyPanelsStyles from "styles/useEmptyPanelsStyles";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Divider,
  Grid,
} from "@material-ui/core";
// Custom
import EmptyContentText from "components/Misc/EmptyContentText";
import CustomSwitch from "core/switches/CustomSwitch";
// Utilities
import {getRandomNumber, oneOf} from "utilities/helperFunctions";
import {FIXED_SIZES, THEME} from "configuration/settings.js";
import clsx from "clsx";

// Messages Panel Constants
const arrayLength = getRandomNumber(4, 6);
const evenWidth = getRandomNumber(45, 60);
const evenHeight = getRandomNumber(75, 40);
const oddWidth = getRandomNumber(45, 60);
const oddHeight = getRandomNumber(75, 40);
const channelWidth = FIXED_SIZES.message_channel.width;
const channelHeight = FIXED_SIZES.message_channel.height;
const showTimeDivider = oneOf([true, false]);
const channelsLength = 4;

const randomListItems = {
  1: getRandomNumber(2, 5),
  2: getRandomNumber(2, 5),
  3: getRandomNumber(2, 5),
  4: getRandomNumber(2, 5),
};

function Skeleton(props) {
  return <MuiSkeleton animation="wave" {...props} />;
}

function HouseItem({styleProps, multiselect, itemSize}) {
  const classes = useEmptyPanelsStyles(styleProps);

  if (!!itemSize) {
    return (
      <Box pb={2} margin={`0 -${THEME.spacing.md}px`}>
        <Skeleton
          height={itemSize - THEME.spacing.sm}
          width="100%"
          className={classes.skeletonBase}
        />
      </Box>
    );
  }

  return (
    <ListItem className={clsx(classes.listItem, classes.noLeftPad, "-house")}>
      {multiselect && (
        <Checkbox
          edge="start"
          size="small"
          checked={false}
          disabled
          disableRipple
          className={classes.checkbox}
        />
      )}
      <Skeleton
        variant="rect"
        width={85}
        height={"100%"}
        className={clsx(classes.skeletonDarker, classes.leftIcon, classes.br5)}
      />
      <ListItemText className={classes.listItemText}>
        <Skeleton
          width="100%"
          height={24}
          className={classes.skeletonDarker}
          style={{maxWidth: 200}}
        />
        <Skeleton
          width="60%"
          height={20}
          className={classes.skeletonDarker}
          style={{maxWidth: 150}}
        />
      </ListItemText>
      <div className={classes.itemActionContainer}>
        <RightArrowIcon className={classes.arrow} />
      </div>
    </ListItem>
  );
}

function GuestItem({styleProps}) {
  const classes = useEmptyPanelsStyles(styleProps);

  return (
    <ListItem
      disableGutters
      style={{alignItems: "flex-start", paddingBottom: 0}}
    >
      <Box width={"100%"}>
        <Skeleton
          height={80}
          width={"100%"}
          className={clsx(classes.skeletonBase, "br10")}
        />
      </Box>
    </ListItem>
  );
}

function ChannelAccountItem({styleProps}) {
  const classes = useEmptyPanelsStyles(styleProps);

  return (
    <ListItem className={clsx(classes.listItem, classes.noLeftPad)}>
      <Skeleton
        variant="circle"
        width={35}
        height={35}
        className={clsx(classes.skeleton, classes.leftIcon)}
      />
      <ListItemText className={classes.listItemText}>
        <Skeleton width="20%" height={35} className={classes.skeleton} />
        <div className={clsx(classes.row, classes.justSB)}>
          <Skeleton width="30%" height={30} className={classes.skeleton} />
          <Skeleton width={100} height={30} className={classes.skeleton} />
        </div>
      </ListItemText>
    </ListItem>
  );
}

function ExperienceSectionItem({}) {
  const classes = useEmptyPanelsStyles();

  return (
    <ListItem className={clsx(classes.listItem, "mb-1")}>
      <Skeleton width={90} height={35} className={classes.skeleton} />
    </ListItem>
  );
}

function ExperienceItem({styleProps}) {
  const classes = useEmptyPanelsStyles(styleProps);

  return (
    <ListItem dense disableGutters className={classes.experienceListItem}>
      <Skeleton width={"100%"} height={56} className={classes.skeletonBase} />
    </ListItem>
  );
}

function UserItem({styleProps}) {
  const classes = useEmptyPanelsStyles(styleProps);

  return (
    <ListItem className={classes.listItem} style={{marginLeft: -8}}>
      <Skeleton
        variant="circle"
        width={48}
        height={48}
        className={clsx(classes.skeleton, classes.leftIcon)}
      />
      <ListItemText className={classes.listItemText}>
        <Skeleton
          width="50%"
          height={35}
          className={classes.skeleton}
          style={{maxWidth: 140}}
        />
      </ListItemText>
    </ListItem>
  );
}

function ConnectedAccount({styleProps}) {
  const classes = useEmptyPanelsStyles(styleProps);

  return (
    <ListItem disableGutters style={{marginBottom: -103}}>
      <Skeleton
        width="100%"
        height={242}
        className={classes.skeleton}
        style={{borderRadius: 10}}
      />
    </ListItem>
  );
}

function CRMGuestTable({styleProps}) {
  const classes = useEmptyPanelsStyles(styleProps);

  return (
    <ListItem className={clsx(classes.listItem, classes.noLeftPad)}>
      <Skeleton
        width="100%"
        height={50}
        className={classes.skeletonBase}
        style={{borderRadius: 10, marginBottom: THEME.spacing.sm, top: "5px"}}
      />
    </ListItem>
  );
}

function TemplateItem({styleProps}) {
  const classes = useEmptyPanelsStyles(styleProps);

  return (
    <ListItem disableGutters>
      <Skeleton width="100%" height={102} className={classes.skeletonBase} />
    </ListItem>
  );
}

function GenericItem({styleProps}) {
  const classes = useEmptyPanelsStyles(styleProps);

  return (
    <ListItem className={clsx(classes.listItem, classes.noLeftPad)}>
      <Skeleton
        width="100%"
        height={65}
        className={classes.skeletonBase}
        style={{borderRadius: 10, marginBottom: THEME.spacing.sm}}
      />
    </ListItem>
  );
}

export function EmptyListPanel({
  loading,
  list,
  noTitle,
  noSearchbar,
  roundedSearchbar,
  disableTopSpacing,
  useSectionHeaders,
  multiselect,
  disableGutters,
  grey,
  itemsNumber = 15,
  disableBottomSpacing,
  customHeaderElement = null,
  itemSize = null,
  customListPadding = null,
}) {
  const styleProps = {
    height: !!list ? FIXED_SIZES[list] || "auto" : "auto",
    disableTopSpacing,
    disableBottomSpacing,
    customListPadding,
    grey,
    whitePaper: list === "messages",
  };
  const classes = useEmptyPanelsStyles(styleProps);

  function getListSectionItem(key) {
    switch (list) {
      case "experiences":
        return <ExperienceSectionItem key={key} />;
      default:
        return <GenericItem key={key} styleProps={styleProps} />;
    }
  }

  function getListItem(key) {
    switch (list) {
      case "messages":
        return <GuestItem key={key} styleProps={styleProps} />;
      case "listings":
        return (
          <HouseItem
            itemSize={itemSize}
            key={key}
            styleProps={styleProps}
            multiselect={multiselect}
          />
        );
      case "connected_channel_accounts":
        return <ChannelAccountItem key={key} styleProps={styleProps} />;
      case "experiences":
        return <ExperienceItem key={key} styleProps={styleProps} />;
      case "templates":
        return <TemplateItem key={key} styleProps={styleProps} />;
      case "users":
        return <UserItem key={key} styleProps={styleProps} />;
      case "connected accounts":
        return <ConnectedAccount key={key} styleProps={styleProps} />;
      case "guest table":
        return <CRMGuestTable key={key} styleProps={styleProps} />;
      default:
        return <GenericItem key={key} styleProps={styleProps} />;
    }
  }

  const renderListRows = () => {
    return (
      <div
        className={clsx({
          [classes.listContainer]: !!list && !disableTopSpacing,
          [classes.customListPadding]: !!customListPadding,
          "mt-3": !list || list === "reports",
        })}
      >
        {useSectionHeaders ? (
          <List>
            {Object.keys(randomListItems).map((k) => (
              <React.Fragment key={k}>
                {getListSectionItem(`section-${list}-${k}`)}
                {[...Array(randomListItems[k]).keys()].map((el, ind) =>
                  getListItem(`${k}-${list}-${ind}`),
                )}
                <br />
              </React.Fragment>
            ))}
          </List>
        ) : (
          <List>
            {[...Array(itemsNumber).keys()].map((i) =>
              getListItem(`${list}-${i}`),
            )}
          </List>
        )}
      </div>
    );
  };

  return (
    <>
      {loading && (!noTitle || !noSearchbar) && (
        <div className={classes.header}>
          {!noTitle && (
            <Typography variant="h6" className={classes.listTitle}>
              {list.split("_").join(" ")}
            </Typography>
          )}
          {customHeaderElement}
          {!noSearchbar && (
            <div
              className={clsx(classes.searchContainer, {
                [classes.fullRounded]: roundedSearchbar,
              })}
            >
              <SearchIcon className={classes.searchIcon} />
            </div>
          )}
        </div>
      )}
      <div
        className={clsx(classes.container, {
          [classes.disableGutters]: !!disableGutters,
          "-house": list === "listings",
          "-messages": list === "messages" && !!noSearchbar,
        })}
      >
        {loading ? (
          renderListRows()
        ) : list === "experiences" ? (
          <EmptyContentText label="No workflows" />
        ) : (
          <Typography
            className="mt-3"
            variant="h1"
            align="center"
            color="textSecondary"
            id={"experiences-none"}
          >
            No {list}
          </Typography>
        )}
      </div>
    </>
  );
}

export function EmptyMessagesPanel({
  loading,
  hideInput,
  animation = "wave",
  fixedNumber,
}) {
  const classes = useEmptyPanelsStyles();

  return (
    <div className={clsx(classes.fullHeight, classes.messagesRoot)}>
      {!loading && (
        <Typography variant="caption" className={classes.startText}>
          {"START OF CONVERSATION"}
        </Typography>
      )}
      {loading && (
        <Box flexGrow={1}>
          <Box display="flex" alignItems="" justifyContent="center">
            <Skeleton
              width="15%"
              className={classes.skeletonDarker}
              style={{borderRadius: 8}}
            />
          </Box>
          {[...Array(fixedNumber ?? arrayLength)].map((_, i) =>
            i % 2 ? (
              <React.Fragment key={`them-${i}`}>
                {showTimeDivider && (
                  <Box
                    display="flex"
                    alignItems=""
                    justifyContent="center"
                    mt={2}
                  >
                    <Skeleton
                      width="10%"
                      className={classes.skeletonDarker}
                      style={{borderRadius: 8}}
                    />
                  </Box>
                )}
                <Box mt={3} key={i} display="flex" alignItems="flex-end">
                  <Skeleton
                    animation={animation}
                    variant="circle"
                    width={channelWidth}
                    height={channelHeight}
                    className={classes.skeletonDarker}
                  />
                  <Skeleton
                    animation={animation}
                    width={`${evenWidth}%`}
                    height={evenHeight}
                    className={`ml-4 ${classes.skeletonDarker}`}
                    style={{transform: "none", borderRadius: 15}}
                  />
                </Box>
              </React.Fragment>
            ) : (
              <Box
                mt={3}
                key={i}
                display="flex"
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <Skeleton
                  animation={animation}
                  width={`${oddWidth}%`}
                  height={oddHeight}
                  className={`mr-4 ${classes.skeletonDarker}`}
                  style={{transform: "none", borderRadius: 15}}
                />
                <Skeleton
                  animation={animation}
                  variant="circle"
                  width={channelWidth}
                  height={channelHeight}
                  className={classes.skeletonDarker}
                />
              </Box>
            ),
          )}
        </Box>
      )}
      {!hideInput && (
        <Box>
          <Skeleton height={45} width="100%" className={classes.input} />
          <Box display="flex" flexDirection="row">
            {[...Array(channelsLength)].map((_, i) => (
              <Skeleton
                key={i}
                variant="circle"
                height={28}
                width={28}
                style={{marginRight: 32}}
              />
            ))}
          </Box>
        </Box>
      )}
    </div>
  );
}

export function EmptyBookingInfoPanel({loading, bkgPanel}) {
  const classes = useEmptyPanelsStyles();

  function getBookingPanel() {
    return (
      <Box marginTop={4}>
        <Box display="flex" flexDirection="column">
          {[1, 2, 3, 4].map((i) => (
            <Col xs={12} key={i}>
              <Skeleton
                key={i}
                variant="text"
                height={200}
                width="100%"
                className={clsx(classes.skeleton, classes.rounded)}
                style={{transform: "none", marginTop: 14}}
              />
            </Col>
          ))}
        </Box>
      </Box>
    );
  }

  if (loading && bkgPanel) return getBookingPanel();
  return (
    <div className={classes.fullHeight}>
      {loading ? (
        <Box height="100%" marginTop={-1} paddingTop={3}>
          <Row>
            <Col xs={12} lg={6} xl={7}>
              <Skeleton
                variant="text"
                width={120}
                height={32}
                className={classes.skeleton}
                style={{borderRadius: 24}}
              />
              <Skeleton
                width="80%"
                height={50}
                className={clsx(classes.skeleton, classes.rounded)}
              />
              <Box marginTop={0.5}>
                <Skeleton
                  width={100}
                  height={23}
                  className={clsx(classes.skeleton, classes.rounded)}
                />
              </Box>
            </Col>
            <Col xs={12} lg={6} xl={5}>
              <Box marginTop={2}>
                <Skeleton width="70%" className={classes.skeleton} />
                <Skeleton width="70%" className={classes.skeleton} />
                <Skeleton width="70%" className={classes.skeleton} />
              </Box>
              <Box marginTop={2.5}>
                <Skeleton width="40%" className={classes.skeleton} />
              </Box>
              <Box marginTop={3.5}>
                <Skeleton
                  width="50%"
                  height={40}
                  className={classes.skeleton}
                />
              </Box>
            </Col>
          </Row>
          {getBookingPanel()}
        </Box>
      ) : (
        <div>
          <Typography
            className="mt-5"
            variant="h1"
            align="center"
            color="textSecondary"
          >
            No guest info
          </Typography>
        </div>
      )}
    </div>
  );
}

export function EmptyExperienceGrid({loading}) {
  const classes = useEmptyPanelsStyles();

  return (
    <div className={clsx(classes.fullHeight, classes.gridContainer)}>
      {loading ? (
        <div className={classes.gridRow}>
          {[...Array(5).keys()].map((i) => (
            <Skeleton
              key={i}
              className={clsx(classes.skeletonBase, classes.cardElement)}
              style={{borderRadius: 10}}
            />
          ))}
        </div>
      ) : (
        <Typography
          variant="h1"
          align="center"
          color="textSecondary"
          id={"empty-panel-experience"}
        >
          No available workflows
        </Typography>
      )}
    </div>
  );
}

export function EmptyHouseProducts({type, loading, hideSuggestions}) {
  const classes = useEmptyPanelsStyles();

  function getItem(key, isTemplate) {
    return (
      <Skeleton
        key={key}
        width="100%"
        height={!!isTemplate ? 100 : 72}
        className={classes.skeletonBase}
      />
    );
  }

  return (
    <div className={classes.fullHeight} style={{padding: 0}}>
      <Typography variant="h1">{`Active ${type === "upsells" ? "custom upsells" : type}`}</Typography>
      {loading ? (
        <Box display="flex" flexWrap="wrap" paddingTop={2} style={{gap: 8}}>
          {[0, 1, 2].map((el, i) => getItem(`yp-${i}`))}
        </Box>
      ) : (
        <Typography
          variant="h2"
          className={classes.none}
        >{`No ${type} yet`}</Typography>
      )}
      {!!loading && !hideSuggestions && (
        <Box marginTop={4}>
          <Typography variant="h1">{`Recommended ${type}`}</Typography>
          <Box display="flex" flexWrap="wrap" paddingTop={2} style={{gap: 8}}>
            {[0, 1, 2].map((el, i) => getItem(`rp-${i}`, true))}
          </Box>
        </Box>
      )}
    </div>
  );
}

export function EmptyHouseOverview({loading}) {
  const classes = useEmptyPanelsStyles();

  return (
    <div className={classes.houseOverview}>
      <Skeleton
        width="100%"
        height={300}
        animation={loading ? "wave" : false}
        className={classes.skeleton}
        style={{transform: "none", borderRadius: 10}}
      />
    </div>
  );
}

export function EmptyPictures({loading}) {
  const classes = useEmptyPanelsStyles();

  if (!loading) {
    return (
      <div>
        <Typography
          className="mt-5"
          variant="h1"
          align="center"
          color="textSecondary"
        >
          No pictures
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.fullHeight} style={{padding: 0}}>
      <Grid container spacing={1} className={classes.picturesContainer}>
        <Skeleton
          width="100%"
          height={300}
          className={classes.skeleton}
          style={{borderRadius: 10, marginTop: -80, marginBottom: 0}}
        />
        {[...Array(6)].map((el, i) => (
          <Grid key={i} item xs={6} xl={4} className={classes.pictureGrid}>
            <Skeleton
              width="100%"
              height={300}
              className={classes.skeleton}
              style={{borderRadius: 10, marginTop: -70, marginBottom: -50}}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export function EmptyPolicies({loading}) {
  const classes = useEmptyPanelsStyles();

  function getItem(key, big) {
    return (
      <ListItem
        key={key}
        className={clsx(
          classes.listItem,
          classes.noLeftPad,
          classes.col,
          "-start",
          `mb-${big ? 3 : 4}`,
        )}
      >
        <Skeleton
          width={90}
          height={20}
          className={classes.skeleton}
          style={{marginTop: 0}}
        />
        <Skeleton
          width="100%"
          height={big ? 150 : 60}
          className={classes.skeleton}
          style={{marginTop: big ? -32 : -10}}
        />
      </ListItem>
    );
  }

  return (
    <div className={classes.fullHeight} style={{padding: 0}}>
      {loading ? (
        <>
          <div className={clsx(classes.row, "-end")}>
            <Skeleton
              width={105}
              height={48}
              className={classes.skeleton}
              style={{marginTop: -3, borderRadius: 20}}
            />
          </div>
          <List style={{marginTop: 32}}>
            {getItem("house-rules", true)}
            {[...Array(3)].map((el, i) => getItem(`f-${i}`))}
          </List>
        </>
      ) : (
        <Typography variant="h2" className={classes.none}>
          {"No policies"}
        </Typography>
      )}
    </div>
  );
}

export function EmptyGuidebooks({loading}) {
  const classes = useEmptyPanelsStyles();

  function getItem(key) {
    return (
      <ListItem key={key} className={clsx(classes.listItem, classes.noLeftPad)}>
        <Skeleton
          width="98%"
          height={110}
          className={classes.skeleton}
          style={{borderRadius: 10, marginTop: -35}}
        />
      </ListItem>
    );
  }

  return (
    <div style={{padding: 0}}>
      {loading ? (
        <List className="mt-2">
          {[...Array(6)].map((el, i) => getItem(`f-${i}`))}
        </List>
      ) : (
        <Typography
          variant="h2"
          align="center"
          className={clsx(classes.none, "mt-2")}
        >
          {"No articles"}
        </Typography>
      )}
    </div>
  );
}

export function EmptyListingAccess({loading}) {
  const classes = useEmptyPanelsStyles();

  function FieldItem({sm, lg}) {
    return (
      <ListItem
        className={clsx(
          classes.listItem,
          classes.noLeftPad,
          classes.col,
          "-start",
          `mb-${lg ? 3 : 4}`,
        )}
      >
        <Skeleton
          width={90}
          height={20}
          className={classes.skeleton}
          style={{marginTop: 0}}
        />
        <Skeleton
          width={sm ? 70 : "100%"}
          height={lg ? 150 : sm ? 30 : 60}
          className={classes.skeleton}
          style={{marginTop: lg ? -32 : sm ? -3 : -10}}
        />
      </ListItem>
    );
  }

  return (
    <div className={classes.fullHeight} style={{padding: 0}}>
      {loading ? (
        <>
          <div className={clsx(classes.row, "-end")}>
            <Skeleton
              width={110}
              height={48}
              className={classes.skeleton}
              style={{marginTop: -3, borderRadius: 20}}
            />
          </div>
          <Typography
            variant="h1"
            className={classes.sectionTitle}
            style={{marginTop: 14}}
          >
            {"Check-in & checkout times"}
          </Typography>
          <Row>
            <Col xs={12} md={6}>
              <FieldItem sm />
            </Col>
            <Col xs={12} md={6}>
              <FieldItem sm />
            </Col>
          </Row>

          <Typography
            variant="h1"
            className={classes.sectionTitle}
            style={{marginTop: 14}}
          >
            {"Check-in information"}
          </Typography>
          <List style={{marginTop: 12}}>
            {[0, 1, 2].map((i) => (
              <Box mb={3} key={`fci-${i}`}>
                <Skeleton
                  width="100%"
                  height={86}
                  variant="text"
                  className={classes.skeletonBase}
                />
              </Box>
            ))}
          </List>

          <Typography
            variant="h1"
            className={classes.sectionTitle}
            style={{marginTop: 30}}
          >
            {"Checkout information"}
          </Typography>
          <List style={{marginTop: 12}}>
            {[0, 1, 2].map((i) => (
              <Box mb={3} key={`fco-${i}`}>
                <Skeleton
                  width="100%"
                  height={86}
                  variant="text"
                  className={classes.skeletonBase}
                />
              </Box>
            ))}
          </List>
        </>
      ) : (
        <Typography variant="h2" className={classes.none}>
          {"No acces information"}
        </Typography>
      )}
    </div>
  );
}

export function EmptyListingAddress({loading}) {
  const classes = useEmptyPanelsStyles();

  function FieldItem() {
    return (
      <ListItem
        className={clsx(
          classes.listItem,
          classes.noLeftPad,
          classes.col,
          "-start",
          "mb-4",
        )}
      >
        <Skeleton
          width={65}
          height={20}
          className={classes.skeleton}
          style={{marginTop: 0}}
        />
        <Skeleton
          width="100%"
          height={40}
          className={classes.skeleton}
          style={{marginTop: -5}}
        />
      </ListItem>
    );
  }

  return (
    <div className={classes.fullHeight} style={{padding: 0}}>
      {loading ? (
        <Row className="mt-4">
          {[...Array(8)].map((el, i) => (
            <Col key={i} xs={12} md={6} style={{marginTop: -10}}>
              <FieldItem />
            </Col>
          ))}
        </Row>
      ) : (
        <Typography variant="h2" className={classes.none}>
          {"No address information"}
        </Typography>
      )}
    </div>
  );
}

export function EmptyListingInfo({loading}) {
  const classes = useEmptyPanelsStyles();

  function FieldItem({xs, sm, lg}) {
    return (
      <ListItem
        className={clsx(
          classes.listItem,
          classes.noLeftPad,
          classes.col,
          "-start",
          `mb-${lg ? 3 : 4}`,
        )}
      >
        <Skeleton
          width={90}
          height={20}
          className={classes.skeleton}
          style={{marginTop: 0}}
        />
        <Skeleton
          width={xs ? 25 : sm ? 70 : "100%"}
          height={lg ? 150 : sm ? 30 : xs ? 30 : 60}
          className={classes.skeleton}
          style={{marginTop: lg ? -32 : sm ? -3 : xs ? -3 : -10}}
        />
      </ListItem>
    );
  }

  return (
    <div className={classes.fullHeight} style={{padding: 0}}>
      {loading ? (
        <>
          <div className={clsx(classes.row, "-end")}>
            <Skeleton
              width={110}
              height={48}
              className={classes.skeleton}
              style={{marginTop: -3, borderRadius: 20}}
            />
          </div>
          <Row className="mt-4">
            <Col xs={12} md={6}>
              <FieldItem sm />
            </Col>
            <Col xs={12} md={6}>
              <FieldItem sm />
            </Col>
          </Row>

          <div style={{marginBottom: 34}}>
            <FieldItem lg />
          </div>
          <FieldItem lg />
          <Typography
            variant="h2"
            className={classes.sectionTitle}
            style={{marginBottom: -15, marginTop: 62}}
          >
            {"Wifi"}
          </Typography>
          <Row className="mt-4">
            <Col xs={12} md={6}>
              <FieldItem sm />
            </Col>
            <Col xs={12} md={6}>
              <FieldItem sm />
            </Col>
          </Row>

          <Typography
            variant="h2"
            className={classes.sectionTitle}
            style={{marginBottom: -15, marginTop: 30}}
          >
            {"Occupancy"}
          </Typography>
          <Row className="mt-4">
            <Col xs={6} md={4}>
              <FieldItem xs />
            </Col>
            <Col xs={6} md={6}>
              <FieldItem xs />
            </Col>
          </Row>
        </>
      ) : (
        <Typography variant="h2" className={classes.none}>
          {"No listing information"}
        </Typography>
      )}
    </div>
  );
}

export function EmptyListingGroup({loading}) {
  const classes = useEmptyPanelsStyles();

  function Section({height}) {
    return (
      <div style={{position: "relative"}}>
        <div className={classes.connector}>
          <div id="circle" />
          <div id="line" />
        </div>
        <Skeleton
          width="100%"
          height={height}
          className={classes.skeleton}
          style={{
            transform: "none",
            borderRadius: 10,
            marginBottom: THEME.spacing.md,
          }}
        />
      </div>
    );
  }

  return (
    <div className={classes.fullHeight} style={{padding: 0, flexGrow: 0}}>
      {loading ? (
        <>
          <Skeleton
            width="100%"
            height={65}
            className={classes.skeleton}
            style={{
              transform: "none",
              borderRadius: 10,
              marginTop: THEME.spacing.lg,
            }}
          />
          <Skeleton
            width="100%"
            height={160}
            className={classes.skeleton}
            style={{
              transform: "none",
              borderRadius: 10,
              marginTop: THEME.spacing.lg,
            }}
          />
          <div
            style={{
              paddingTop: THEME.spacing.lg,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Divider
              className={classes.divider}
              component="div"
              orientation="vertical"
              flexItem
            />
            <div
              style={{display: "flex", flexDirection: "column", flexGrow: 1}}
            >
              <Section height={150} />
              <Section height={200} />
              <Section height={150} />
            </div>
          </div>
        </>
      ) : (
        <Typography variant="h2" className={classes.none}>
          {"No listing group information"}
        </Typography>
      )}
    </div>
  );
}

export function EmptyBoardingPassPanel({loading, hideConditions}) {
  const classes = useEmptyPanelsStyles();

  return (
    <div className={classes.fullHeight} style={{padding: 0}}>
      {loading ? (
        <>
          {!hideConditions && (
            <>
              <Typography variant="subtitle1" style={{marginTop: 10}}>
                {"CONDITION"}
              </Typography>
              <Skeleton
                width="100%"
                height={82}
                className={classes.skeleton}
                style={{marginTop: -12, borderRadius: 10}}
              />
            </>
          )}

          <Typography
            variant="button"
            className={classes.subtitleSecondary}
            component="p"
            style={{marginTop: hideConditions ? 3 : 10}}
          >
            {"Guest information collection"}
          </Typography>
          <Skeleton
            width="100%"
            height={62}
            className={classes.skeleton}
            style={{marginTop: -8, borderRadius: 10}}
          />

          <Typography
            variant="button"
            className={classes.subtitleSecondary}
            component="p"
            style={{marginTop: 20}}
          >
            {"Verification"}
          </Typography>
          <Skeleton
            width="100%"
            height={62}
            className={classes.skeleton}
            style={{marginTop: -8, borderRadius: 10}}
          />
          <div
            className={clsx(classes.row, "-center")}
            style={{marginLeft: -10, marginTop: 20}}
          >
            <CustomSwitch checked={false} disabled />
            <Skeleton
              width="100%"
              height={30}
              className={classes.skeleton}
              style={{marginTop: -5, maxWidth: 300}}
            />
          </div>
          <div
            className={clsx(classes.row, "-center")}
            style={{marginLeft: -10}}
          >
            <CustomSwitch checked={false} disabled />
            <Skeleton
              width="100%"
              height={30}
              className={classes.skeleton}
              style={{marginTop: -5, maxWidth: 300}}
            />
          </div>

          <Typography
            variant="button"
            className={classes.subtitleSecondary}
            component="p"
            style={{marginTop: 20}}
          >
            {"Check-in instruction settings"}
          </Typography>
          <div
            className={clsx(classes.row, "-center")}
            style={{marginLeft: -10}}
          >
            <CustomSwitch checked={false} disabled />
            <Skeleton
              width="100%"
              height={30}
              className={classes.skeleton}
              style={{marginTop: -5, maxWidth: 300}}
            />
          </div>
          <div
            className={clsx(classes.row, "-center")}
            style={{marginLeft: -10}}
          >
            <CustomSwitch checked={false} disabled />
            <Skeleton
              width="100%"
              height={30}
              className={classes.skeleton}
              style={{marginTop: -5, maxWidth: 300}}
            />
          </div>

          <Typography
            variant="button"
            className={classes.subtitleSecondary}
            component="p"
            style={{marginTop: 20}}
          >
            {"Agreements"}
          </Typography>
          <Skeleton
            width="100%"
            height={106}
            className={classes.skeleton}
            style={{marginTop: -18, borderRadius: 10}}
          />

          <Typography
            variant="button"
            className={classes.subtitleSecondary}
            component="p"
            style={{marginTop: 20, marginBottom: -45}}
          >
            {"Recommend local services"}
          </Typography>
          {[...Array(3).keys()].map((i) => (
            <React.Fragment key={i}>
              <Row
                className={classes.gridRow}
                style={{marginTop: -43, marginBottom: -100}}
              >
                {[...Array(5).keys()].map((i) => (
                  <Skeleton
                    key={i}
                    width={120}
                    height={230}
                    className={classes.skeleton}
                    style={{borderRadius: 10, marginRight: 14}}
                  />
                ))}
              </Row>
            </React.Fragment>
          ))}
        </>
      ) : (
        <Typography variant="h2" className={classes.none}>
          {"No boarding pass information"}
        </Typography>
      )}
    </div>
  );
}

export function ExistingResourceListLoader({
  totalItems = 2,
  resources = false,
}) {
  const classes = useEmptyPanelsStyles();

  function getResourceSkeletons() {
    const res = [...Array(totalItems).keys()];
    return (
      <div className={classes.resourceContainer}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          style={{gap: THEME.spacing.md}}
        >
          {res.map((i, ind) => (
            <Skeleton
              key={ind}
              width="100%"
              height={FIXED_SIZES.copy_existing_resource_item}
              style={{borderRadius: 7}}
              className={classes.skeletonBase}
            />
          ))}
        </Box>
      </div>
    );
  }

  if (!!resources) {
    return getResourceSkeletons();
  }
  return (
    <div className={clsx(classes.fullHeight, "np")}>
      {[0, 1, 2].map((i, ind) => (
        <div key={ind}>
          <Box width={"100%"} mb={1} position={"relative"}>
            <Skeleton
              width="100%"
              height={46}
              className={classes.skeletonBase}
            />
            <NavigateNextIcon className={classes.navigateNextIcon} />
          </Box>
        </div>
      ))}
    </div>
  );
}

export function LoadingCTAs() {
  const classes = useEmptyPanelsStyles();

  return (
    <div className={clsx(classes.fullHeight, "np", classes.ctaContainer)}>
      <Skeleton width="100%" height={237} className={classes.skeletonBase} />
      <div className={clsx(classes.row, classes.ctaCardsContainer)}>
        {[0, 1].map((i) => (
          <Skeleton
            key={i}
            height={314}
            className={clsx(classes.ctaCard, classes.skeletonBase)}
          />
        ))}
      </div>
    </div>
  );
}

export function EmptyCheckinSteps() {
  const classes = useEmptyPanelsStyles();

  return (
    <div className={clsx(classes.fullHeight, "np")}>
      {[0, 1, 2].map((i) => (
        <Box mb={3} key={i}>
          <Skeleton
            width="100%"
            height={86}
            variant="text"
            className={classes.skeletonBase}
          />
        </Box>
      ))}
    </div>
  );
}

export function LoadingVerificationSettings({total}) {
  const classes = useEmptyPanelsStyles();

  return (
    <>
      {[...Array(total).keys()].map((i) => (
        <Skeleton
          key={i}
          width="100%"
          height={78}
          className={classes.skeletonBase}
        />
      ))}
    </>
  );
}

export function EmptyInboxes({}) {
  const classes = useEmptyPanelsStyles();

  return (
    <>
      {[1, 2, 3, 4].map((i) => (
        <Skeleton
          key={i}
          height={40}
          width={100}
          className={classes.skeletonBase}
        />
      ))}
    </>
  );
}
