import React from "react";
// UI
import {makeStyles} from "@material-ui/core/styles";
import {
  Button,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import PrimaryButton from "core/buttons/PrimaryButton";
import DeleteIconButton from "core/buttons/DeleteIconButton";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {position: "relative"},
  itemContainer: {listStyle: "none"},
  item: {
    backgroundColor: "#FFF",
    borderRadius: 10,
    position: "relative",
    boxShadow:
      "0px 3px 3px rgba(155, 160, 176, 0.1), 0px 1px 0px rgba(21, 50, 111, 0.05)",
    padding: (props) => theme.spacing(4, 4, !!props.hasActions ? 6 : 4),
    "&.-disabled": {opacity: 0.5},
    "&.-highlighted": {
      margin: -2,
      border: `2px solid ${theme.palette.error.main}`,
    },
  },
  selected: {
    margin: -2,
    backgroundColor: "#FFF !important",
    border: "2px solid rgba(13, 86, 140, 0.35)",
    "&:hover": {backgroundColor: "rgba(255,255,255,0.6) !important"},
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    display: "flex",
    "& > svg": {
      fontSize: 19,
      marginRight: theme.spacing(2),
    },
  },
  title: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: "22px",
    color: "#111421",
    flexGrow: 1,
  },
  editIcon: {
    color: "#ABB1C8",
    width: 18,
  },
  descContainer: {
    paddingTop: theme.spacing(2),
    "&.-noPadding": {padding: 0},
  },
  descTitle: {
    fontWeight: 500,
    color: "#A6ACC4",
    textTransform: "uppercase",
  },
  description: {
    fontSize: 13,
    paddingTop: 2,
    color: "#6D7175",
  },
  actions: {
    right: "auto",
    top: "auto",
    left: theme.spacing(4),
    bottom: theme.spacing(4),
    transform: "none",
    borderRadius: 10,
  },
  actionBtn: {
    fontSize: 13,
    marginRight: theme.spacing(3),
  },
  textBtn: {
    color: "rgba(67, 84, 96, 0.5)",
    fontSize: 13,
    minWidth: 0,
    padding: 0,
    "&:hover": {backgroundColor: "transparent"},
  },
  dismissed: {
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  highlighted: {
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.error.main,
  },
  multiline: {margin: 0},
  deleteIconContainer: {
    position: "absolute",
    display: "flex",
    top: 14,
    right: 12,
    zIndex: 1,
  },
}));

export default function CardItem({
  icon,
  selected,
  title,
  disabled,
  description,
  actions,
  editAction,
  type,
  descIndex = -1,
  deleteAction,
  highlighted,
  disableEdit,
  hideRemove,
}) {
  const classes = useStyles({
    hasActions: !disabled && !!actions?.length && !disableEdit,
  });
  const onEdit = React.useMemo(() => {
    let newEditAction = () => null;
    if (!!editAction) {
      newEditAction = editAction;
    } else {
      const setupAction = actions.find((act) => act.type === "primary");
      if (!!setupAction) {
        newEditAction = setupAction.onClick;
      }
    }
    return newEditAction;
  }, [actions, editAction]);

  function getActionBtn(action) {
    switch (action.type) {
      case "primary":
      case "secondary":
        return (
          <PrimaryButton
            variant={action.type === "primary" ? "contained" : "outlined"}
            label={action.label}
            onClick={action.onClick}
            className={classes.actionBtn}
          />
        );
      case "text":
        return (
          <Button
            disableElevation
            disableRipple
            variant="text"
            className={classes.textBtn}
            onClick={action.onClick}
          >
            {action.label}
          </Button>
        );

      default:
        return null;
    }
  }

  return (
    <div className={classes.root}>
      {!!deleteAction &&
        !disabled &&
        !actions?.length &&
        !disableEdit &&
        !hideRemove && (
          <div className={classes.deleteIconContainer}>
            <DeleteIconButton onClick={deleteAction} />
          </div>
        )}
      <ListItem
        button
        disableRipple
        selected={selected}
        classes={{
          container: classes.itemContainer,
          root: clsx(classes.item, {
            "-disabled": disabled || (!!actions?.length && !!disableEdit),
            "-highlighted": highlighted,
          }),
          selected: classes.selected,
        }}
        onClick={!!disableEdit && !!actions?.length ? () => null : onEdit}
      >
        <ListItemText
          disableTypography
          classes={{multiline: classes.multiline}}
          primary={
            <div className={classes.row}>
              {!!title ? (
                <>
                  <div className={classes.icon}>{icon}</div>
                  <Typography className={classes.title}>
                    {title}
                    {!!disabled ? (
                      <span className={classes.dismissed}>
                        {" • Dismissed"}
                      </span>
                    ) : !!highlighted ? (
                      <span className={classes.highlighted}>
                        {" • Please setup or dismiss this step to go live"}
                      </span>
                    ) : null}
                  </Typography>
                </>
              ) : (
                !!description && (
                  <Typography className={classes.descTitle} variant="caption">
                    {description[descIndex]?.header}
                  </Typography>
                )
              )}
            </div>
          }
          secondary={
            !!description && (
              <div
                className={clsx(classes.descContainer, {
                  "-noPadding":
                    !title ||
                    !description[descIndex !== -1 ? descIndex : 0]?.text,
                })}
              >
                {description.length > 1 && descIndex === -1 ? (
                  description.map((d, i) => (
                    <Typography
                      className={classes.description}
                      key={`${type}-${i}`}
                    >
                      {d?.text}
                    </Typography>
                  ))
                ) : (
                  <Typography className={classes.description}>
                    {description[descIndex !== -1 ? descIndex : 0]?.text}
                  </Typography>
                )}
              </div>
            )
          }
        />
        {!!actions?.length && !disabled && !disableEdit && (
          <ListItemSecondaryAction className={classes.actions}>
            {actions.map((action, ind) => (
              <React.Fragment key={`action-${ind}`}>
                {getActionBtn(action)}
              </React.Fragment>
            ))}
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </div>
  );
}
