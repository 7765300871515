import React from "react";
import {
  ClickAwayListener,
  Grow,
  makeStyles,
  Paper,
  Popper,
} from "@material-ui/core";
import OutsideAlerter from "hooks/OutsideAlerter";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    zIndex: theme.zIndex.tooltip,
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.160784)",
    borderRadius: 10,
  },
  paper: {
    width: "fit-content",
    maxHeight: (props) => props.modalMaxHeight,
    minHeight: (props) => props.modalMinHeight,
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.160784)",
    borderRadius: 10,
    backgroundColor: "#FFF",
    overflow: "hidden",
  },
  menuZIndex: {zIndex: (props) => props.zIndex ?? 2},
  overflowAuto: {
    maxHeight: (props) => props.modalMaxHeight,
    minHeight: (props) => props.modalMinHeight,
    overflowX: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: (props) => `${props.hideScrollbar ? 0 : 6}px !important`,
    },
  },
}));

export default function CustomMenu({
  open,
  anchorEl,
  content,
  growPosition,
  useGrow,
  extraClass,
  transformOrigin = "left top",
  placement = "bottom-end",
  menuRef,
  onClose,
  onMouseOver,
  style,
  timeout = "auto",
  overflowAuto,
  modifiers,
  zIndex = 2,
  disablePortal = false,
  minHeight = 0,
  bottomPadding = 30,
  hideScrollbar,
  fitAnchorElHeight,
  maxHeight = null,
  customPaperClass,
}) {
  const windowHeight = window.innerHeight;
  const anchorElDimensions = anchorEl?.getBoundingClientRect() ?? {};
  const modalMaxHeight =
    maxHeight ??
    windowHeight -
      (!!fitAnchorElHeight ? anchorElDimensions.bottom || 0 : 0) -
      bottomPadding;
  const classes = useStyles({
    zIndex,
    modalMinHeight: minHeight,
    modalMaxHeight: modalMaxHeight,
    hideScrollbar,
  });

  return !useGrow ? (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      ref={menuRef}
      popperOptions={{eventsEnabled: false}}
      disablePortal={disablePortal}
      className={clsx(classes.menuZIndex, {[extraClass]: !!extraClass})}
      style={style}
      placement={placement}
      onMouseOver={onMouseOver}
      modifiers={modifiers}
    >
      {({TransitionProps}) => (
        <Grow
          {...TransitionProps}
          style={{transformOrigin: transformOrigin}}
          timeout={timeout}
        >
          <Paper
            elevation={0}
            className={clsx(
              classes.paper,
              {[classes.overflowAuto]: !!overflowAuto},
              customPaperClass,
            )}
          >
            <ClickAwayListener onClickAway={onClose}>
              {content}
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  ) : (
    <Grow
      in={open}
      ref={menuRef}
      className={classes.root}
      style={{...growPosition, transformOrigin: "right 0 0"}}
      onMouseOver={onMouseOver}
      mountOnEnter
      unmountOnExit
    >
      <Paper elevation={4}>
        <OutsideAlerter disableListener={!open} onClickOutside={onClose}>
          <>{content}</>
        </OutsideAlerter>
      </Paper>
    </Grow>
  );
}
