import React, {useRef, useState} from "react";
import {
  Button,
  makeStyles,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import UploadIcon from "@material-ui/icons/GetApp";
import InfoIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  dashed: {
    border: `2px dashed #d2d2d2`,
    borderRadius: 10,
    width: "100%",
    maxWidth: 300,
    height: 200,
    display: "grid",
    placeContent: "center",
    backgroundColor: "#F6F9FF",
    position: "relative",
  },
  icon: {
    fontSize: 60,
    justifySelf: "center",
    marginBottom: 10,
  },
  infoIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

export default function DragAndDropFile({
  setFile,
  label = "Select Image",
  acceptedFormats = ["jpg", "png", "jpeg"],
}) {
  const inputRef = useRef(null);
  const classes = useStyles();

  const dragOver = (e) => {
    e.preventDefault();
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    setFile(e.dataTransfer.files[0]);
  };

  const filesUploaded = (e) => {
    setFile(e.target.files[0]);
  };

  const acceptedFormatsList = acceptedFormats.join(", ").toUpperCase();
  const acceptedFormatsTitle = `Accepted formats: ${acceptedFormatsList}`;

  return (
    <div
      id="branding-logo"
      className={classes.dashed}
      onDragOver={dragOver}
      onDragEnter={dragOver}
      onDragLeave={dragOver}
      onDrop={fileDrop}
    >
      <input
        id="pictureInput"
        ref={inputRef}
        style={{display: "none"}}
        type="file"
        multiple
        onChange={filesUploaded}
        accept={acceptedFormats.map((format) => `.${format}`).join(", ")}
      />
      <UploadIcon color="primary" className={classes.icon} />

      {acceptedFormats.length > 0 && (
        <Tooltip title={acceptedFormatsTitle}>
          <IconButton className={classes.infoIcon} size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      <Button variant="contained" color="primary" onClick={handleClick}>
        {label}
      </Button>
      <Typography style={{marginTop: 5, justifySelf: "center"}}>
        - OR -
      </Typography>
      <Typography>Drag & drop to upload</Typography>
    </div>
  );
}
