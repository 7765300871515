import {API} from "aws-amplify";

export const getSummary = async (params) => {
  try {
    const response = await API.get("ensoAPI", `/dashboard/summary?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getGuestStats = async (params) => {
  try {
    const response = await API.get(
      "ensoAPI",
      `/dashboard/guest_stats?${params}`,
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getBookingStats = async (params) => {
  try {
    const response = await API.get(
      "ensoAPI",
      `/dashboard/booking_stats?${params}`,
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getMessageStats = async (params) => {
  try {
    const response = await API.get(
      "ensoAPI",
      `/dashboard/message_stats?${params}`,
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getMessageAnalytics = async (params) => {
  try {
    const response = await API.get(
      "ensoAPI",
      `/dashboard/message_analytics?${params}`,
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getBoardingPassStats = async (params) => {
  try {
    const response = await API.get(
      "ensoAPI",
      `/dashboard/boarding_pass_stats?${params}`,
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getRevenueStats = async (params) => {
  try {
    const response = await API.get(
      "ensoAPI",
      `/dashboard/product_stats?${params}`,
    );
    return response;
  } catch (err) {
    throw err;
  }
};
