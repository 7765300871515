import React, {useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Storage} from "aws-amplify";
import {makeStyles} from "@material-ui/core/styles";
import {MenuList, ListItem} from "@material-ui/core";
import CustomMenu from "core/menus/CustomMenu";
import ErrorAlert from "core/alerts/ErrorAlert";
import ExpandButton from "core/buttons/ExpandButton";
import {
  closeGeneralError,
  openGeneralError,
} from "redux/actions/settingsActions";
import {getFileErrorMessage} from "utilities/formatUtilities";
import {convertToPNG, useImageCompressor} from "utilities/fileUtilities";
import config, {THEME} from "configuration/settings";
import {newUuid, sleep} from "utilities/helperFunctions";
import clsx from "clsx";
import ImageDisplayDialog from "../../ui/base/dialog/image/ImageDisplayDialog";
import PexelsImageDisplay from "../../ui/base/dialog/image/PexelsImageDisplay";

const useStyles = makeStyles((theme) => ({
  imageSelectorBtn: {
    border: `1px solid ${THEME.text} !important`,
    color: THEME.text,
    padding: theme.spacing(1, 3),
    marginRight: theme.spacing(2),
    "&:hover": {backgroundColor: THEME.text + "0d"},
    "&.light": {
      border: `1px solid ${theme.palette.common.white} !important`,
      color: theme.palette.common.white,
      "&:hover": {backgroundColor: "transparent"},
    },
  },
  subheader: {fontWeight: 600},
}));

const ImageSelector = ({light, resourceType, onPictureChange = () => {}}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [imgToUpload, setImgToUpload] = useState(null);
  const [compressEnabled, setCompressEnabled] = useState(false);
  const [error, setError] = useState({show: false, message: ""});
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );

  useImageCompressor({
    srcImgs: !!compressEnabled && !!imgToUpload ? [imgToUpload] : [],
    onSuccess: (outputImgs) => onCompressSuccess(outputImgs),
  });

  const handleCloseMenu = () => setAnchorEl(null);
  const handleErrorClose = () => setError({show: false, message: ""});

  function handlePictureToggle(picture) {
    onPictureChange(picture);
  }

  const onCompressSuccess = (outputImgs) => {
    setCompressEnabled(false);
    setImgToUpload(null);
    handleUploadImage(outputImgs[0]);
  };

  const handleUploadImage = async (file) => {
    const imageName = `${current_user}/listing-resources/${resourceType}/header-image-${newUuid()}`;
    try {
      await Storage.put(imageName, file, {contentType: file.type});
      const link = `${config.s3.URL}${imageName}`;
      handlePictureToggle(link);
    } catch (error) {
      dispatch(
        openGeneralError({
          message: "Failed to upload the image",
          subtitle: "Something went wrong, please try again later",
          open: true,
          disableSeverity: true,
          onClose: () => dispatch(closeGeneralError()),
        }),
      );
    }
  };

  const fileUploaded = async (e) => {
    const file = e.target.files[0];
    const convertedFile = await convertToPNG(file);
    setImgToUpload(convertedFile);
    sleep(250).then(() => setCompressEnabled(true));
  };

  const displayError = (picture, errorType, customMessage) => {
    let message = getFileErrorMessage({picture, errorType, customMessage});
    setError({show: true, message});
  };

  return (
    <>
      <ImageDisplayDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Select an Image"
      >
        <PexelsImageDisplay
          defaultQuery="House"
          onSelectImage={(img) => {
            setModalOpen(false);
            onPictureChange(img);
          }}
        />
      </ImageDisplayDialog>

      <ErrorAlert
        open={error.show}
        onClose={handleErrorClose}
        message={error.message}
      />
      <input
        ref={inputRef}
        style={{display: "none"}}
        type="file"
        onChange={fileUploaded}
      />
      <CustomMenu
        transformOrigin="right top"
        zIndex={1301}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        content={
          <MenuList>
            <ListItem
              button
              disableRipple
              onClick={() => {
                inputRef.current.click();
                handleCloseMenu();
              }}
            >
              Upload Photo
            </ListItem>
            <ListItem
              button
              disableRipple
              onClick={() => {
                setModalOpen(true);
                handleCloseMenu();
              }}
            >
              Select from library
            </ListItem>
          </MenuList>
        }
      />
      <ExpandButton
        hasPopup
        variant="outlined"
        className={clsx(classes.imageSelectorBtn, {light: !!light})}
        label={"Edit main image"}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
    </>
  );
};

export default ImageSelector;
