import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {FormControlLabel, Switch} from "@material-ui/core";
import SimpleField from "./SimpleField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

export default function Policy({edit, value, onValueChange}) {
  const classes = useStyles();
  const isNotAllowed = !value || value === "not allowed";
  const [selectedVal, setSelectedVal] = React.useState(
    isNotAllowed ? "not allowed" : "allowed",
  );

  React.useEffect(() => {
    const newVal = isNotAllowed ? "not allowed" : "allowed";
    if (edit && newVal !== selectedVal) {
      setSelectedVal((prev) => newVal);
    }
  }, [value]);

  React.useEffect(() => {
    if (edit) {
      onValueChange(selectedVal);
    }
  }, [selectedVal]);

  const handleChange = (e) => {
    const newVal = !!e.target.checked ? "allowed" : "not allowed";
    setSelectedVal((prev) => newVal);
  };

  if (!edit) {
    return <SimpleField value={isNotAllowed ? "Not allowed" : "Allowed"} />;
  } else {
    return (
      <div className={classes.root}>
        <FormControlLabel
          control={
            <Switch
              checked={selectedVal === "allowed"}
              onChange={handleChange}
              name="policyCheck"
            />
          }
          label={selectedVal === "allowed" ? "Allowed" : "Not allowed"}
        />
      </div>
    );
  }
}
