import React from "react";
import {IconButton, SvgIcon, makeStyles} from "@material-ui/core";
import {ReactComponent as DeleteIcon} from "assets/icons/delete.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: (props) => (!!props.light ? "#FFF" : "#EE4F4F"),
    width: 15,
    height: 15,
    transition: "0.15s",
    opacity: 0.5,
    marginLeft: (props) => theme.spacing(props.marginLeft),
    marginRight: (props) => theme.spacing(props.marginRight),
    "&:hover": {opacity: 1},
  },
  noPadding: {padding: 0},
}));

export default function DeleteIconButton({
  onClick,
  light,
  marginLeft = 0,
  marginRight = 0,
  disablePadding = false,
  disabled,
}) {
  const classes = useStyles({marginLeft, marginRight, light});

  return (
    <IconButton
      size="small"
      disabled={disabled}
      onClick={onClick}
      className={clsx({[classes.noPadding]: !!disablePadding})}
    >
      <SvgIcon
        className={classes.icon}
        viewBox="0 0 15 15"
        component={DeleteIcon}
      />
    </IconButton>
  );
}
