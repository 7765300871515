import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useRouteMatch} from "react-router-dom";
// UI
import {
  Link,
  Typography,
  MenuItem,
  MenuList,
  IconButton,
} from "@material-ui/core";
import {darken, makeStyles} from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CircleIcon from "@material-ui/icons/RadioButtonUnchecked";
// Custom
import PrimaryButton from "core/buttons/PrimaryButton";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import CustomMenu from "core/menus/CustomMenu";
import FullLoader from "components/Dialogs/FullLoader";
import ProgressBar from "core/bars/ProgressBar";
// Actions
import {
  editListingGroup,
  saveListingGroup,
  getListingGroupDetails,
} from "redux/actions/listingsActions";
// Utils
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 2,
    backgroundColor: "#FFF",
    position: "sticky",
    top: -1,
    transition: "0.15s",
  },
  container: {
    width: "100%",
    borderRadius: 10,
    opacity: 0.85,
    backgroundColor: "rgba(229, 229, 234, 0.15)",
    border: "1px solid rgba(229, 229, 234, 0.75)",
    padding: theme.spacing(4, 3),
    transition: "0.3s",
    "&.-collapsed": {padding: theme.spacing(3, 4)},
  },
  sectionItem: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(3),
    backgroundColor: "#F1F3F9",
    borderRadius: 10,
    //'&:hover': { backgroundColor: darken('#F1F3F9', 0.02) + '!important' },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  progressbarContainer: {
    padding: theme.spacing(4, 0),
    transition: "0.15s",
    "&.-collapsed": {
      padding: 0,
      paddingTop: theme.spacing(2),
    },
  },
  content: {
    display: "flex",
    alignItems: "flex-end",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    transition: "0.15s",
    "&.-collapsed": {
      transform: "scale(0)",
      height: 0,
    },
  },
  cardItemText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  moreIcon: {
    color: "white",
  },
  item: {
    flexGrow: 1,
    flexBasis: 170,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    minHeight: 50,
    borderRadius: 10,
    padding: theme.spacing(2),
    cursor: "pointer",
    minWidth: 215,
    position: "relative",
    "&.selected": {backgroundColor: theme.palette.secondary.main},
  },
  itemText: {
    fontSize: 13,
    color: "#435460",
    "&.selected": {color: "white"},
  },
  circleIcon: {
    width: 19,
    height: 19,
    marginRight: theme.spacing(2),
    color: "rgba(17, 20, 33, 0.25)",
    "&.-checked": {color: "#9AD4D6"},
    "&.disabled-checked": {color: "lightgrey"},
  },
  live: {
    display: "flex",
    color: "#3ADC61",
    fontSize: 20,
  },
  dot: {
    fontSize: 32,
    lineHeight: "17px",
    marginRight: theme.spacing(1),
  },
  list: {
    margin: 0,
    padding: theme.spacing(2, 0, 0, 4),
  },
  listItem: {padding: theme.spacing(2)},
}));

function getCards(mylist, is_message) {
  let result = [];

  for (let key in mylist) {
    if (mylist[key].cards) {
      for (let cardKey in mylist[key].cards) {
        let card = mylist[key].cards[cardKey];
        if (is_message === (cardKey === "experiences")) {
          result.push({
            ...card,
            card_key: cardKey,
            section_key: key,
            label: card.label.replace(" Message", ""),
          });
        }
      }
    }
  }

  return result;
}

export default function ProgressSummaryPanel({
  data = {},
  groupId,
  collapsed,
  containerRef,
  handleSetupAction,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [openModal, setOpenModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(null);
  const guestJourney = data.guest_journey ?? {};
  const sections = guestJourney.sections ?? {};
  const progressData = guestJourney.progress ?? {};
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const selectedGroup = React.useMemo(
    () => listingGroups[groupId] ?? {},
    [groupId, listingGroups],
  );
  const isLive = guestJourney.enabled ?? false;
  const [cards, setCards] = React.useState([]);
  const keys = React.useMemo(() => Object.keys(sections), [data]);
  const matchItemCard = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card/:item",
  );
  const matchCardSection = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card",
  );

  const handleMenuClose = () => setOpenMenu((prev) => null);
  const handleMenuOpen = (step_id) => setOpenMenu((prev) => step_id);

  const {selected_section, selected_card, itemNumber} = React.useMemo(() => {
    if (!!matchItemCard?.isExact) {
      return {
        selected_section: matchItemCard.params.step,
        selected_card: matchItemCard.params.card,
        itemNumber: matchItemCard.params.item,
      };
    } else if (!!matchCardSection?.isExact) {
      return {
        selected_section: matchCardSection.params.step,
        selected_card: matchCardSection.params.card,
        itemNumber: null,
      };
    } else {
      return {selected_section: null, selected_card: null, itemNumber: null};
    }
  }, [location]);

  const completed = React.useMemo(() => {
    let number = 0;
    let total = 0;
    for (let key in guestJourney.progress) {
      if (guestJourney.progress[key] !== "setup") {
        number++;
      }
      total++;
    }
    return !!total ? Math.floor((number * 100) / total) : 0;
  }, [data]);

  React.useEffect(() => {
    setCards([
      {
        name: "Build your Boarding Pass",
        //button: 'View Boarding Pass',
        text: "Verify your guests, collect fees, guide them through check-in/check-out, share guidebooks, offer upsells & more.",
        cards: getCards(sections, false),
      },
      {
        name: "Set up message automation",
        button: "View timeline",
        onButtonClick: () => handleSetupAction({card_key: "timeline"}),
        text: "Share the Boarding Pass link, send smart reminders, direct booking/upsell offers & more.",
        cards: getCards(sections, true),
      },
      /*{
        name:'Set up EnsoAI', 
        //button: 'Preview AI Chat',
        text: 'Automate communications, get priority escalations when urgent messages arrive & build your your knowledge base.',
        cards: [{label: 'Build Knowledge Base', enabled: true}, {'label': 'Enable auto-responses', enabled: true}, {'label': 'Configure AI escalations', enabled: true}]
      }*/
    ]);
  }, [data]);

  const handleClose = () => {
    setOpenModal((prev) => false);
  };

  const refreshGuestJourney = (section, card) => {
    dispatch(
      getListingGroupDetails({groupId: selectedGroup.group_id, section, card}),
    );
  };

  const updateGroup = (body, updatedGroup, section, card) => {
    dispatch(editListingGroup(updatedGroup));
    dispatch(
      saveListingGroup({
        disableAlerts: true,
        body: body,
        groupId: selectedGroup.group_id,
        onSuccess: () => refreshGuestJourney(section, card),
        onError: () => refreshGuestJourney(section, card),
      }),
    );
  };

  const handleDismiss = (key, cardKey) => {
    const newGuestJourney = {...selectedGroup.guest_journey};
    setOpenMenu(null);
    newGuestJourney.sections[key].cards[cardKey].enabled = false;
    const newDisabledSections = {
      ...selectedGroup.disabled_sections,
      [key]: {
        ...selectedGroup.disabled_sections?.[key],
        [cardKey]: true,
      },
    };

    const newData = {
      name: data.name,
      connected_to: selectedGroup.connected_to,
      group_id: selectedGroup.group_id,
      disabled_sections: newDisabledSections,
    };

    const updatedGroup = {
      ...selectedGroup,
      disabled_sections: newDisabledSections,
      guest_journey: newGuestJourney,
    };
    updateGroup(newData, updatedGroup, key, cardKey);
  };

  const onGoLiveClick = () => {
    if (completed != 100) {
      for (let key in sections) {
        if (sections[key].cards) {
          for (let cardKey in sections[key].cards) {
            let card_data = sections[key].cards[cardKey];
            if (card_data.enabled && card_data.setup_label) {
              handleSetupAction({card_key: cardKey, section_key: key});
            }
          }
        }
      }
    } else {
      setOpenModal((prev) => true);
    }
  };

  const handleGoLive = () => {
    handleClose();
    setLoading((prev) => true);
    dispatch(
      editListingGroup({
        ...data,
        guest_journey: {
          ...data.guest_journey,
          enabled: true,
        },
      }),
    );
    dispatch(
      saveListingGroup({
        body: {
          name: data.name,
          enabled: true,
          connected_to: data.connected_to,
        },
        groupId: groupId,
        onSuccess: () => setLoading((prev) => false),
        onError: () => setLoading((prev) => false),
      }),
    );
  };

  function StepItem({section, isDone, card, handleSetupAction, selected}) {
    const classes = useStyles();
    let step_id = `${card.section_key}_${card.card_key}`;
    return (
      <div
        className={clsx(classes.item, {selected: selected})}
        onClick={() => handleSetupAction(card)}
      >
        {isDone ? (
          <CheckCircleIcon className={clsx(classes.circleIcon, "-checked")} />
        ) : (
          <CircleIcon className={classes.circleIcon} />
        )}
        <div style={{flex: 1}}>
          <Typography
            variant="caption"
            className={clsx(classes.itemText, {selected: selected})}
          >
            {card.label}
          </Typography>
        </div>
        <div>
          {!isDone && (
            <IconButton size="small" onClick={() => handleMenuOpen(step_id)}>
              <MoreVertIcon
                className={clsx({[classes.moreIcon]: !!selected})}
              />
            </IconButton>
          )}
          <CustomMenu
            open={openMenu === step_id && !isDone}
            useGrow
            growPosition={{right: 0}}
            content={
              <MenuList>
                <MenuItem
                  onClick={() => handleDismiss(card.section_key, card.card_key)}
                >
                  {"Skip this step"}
                </MenuItem>
              </MenuList>
            }
            onClose={handleMenuClose}
          />
        </div>
      </div>
    );
  }

  const confirmationModal = (
    <ConfirmDialog
      open={openModal}
      title="Ready to go live?"
      onClose={handleClose}
      message={
        <>
          {"Going live will turn on automated messages for this guest journey"}
          <ul className={classes.list}>
            <li className={classes.listItem}>
              {
                "If this is your first guest journey or if you have any questions, please book a call with our onboarding specialist "
              }
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={"http://ensoconnect.com/onboarding-meeting"}
              >
                {" here."}
              </Link>
            </li>
            <li className={classes.listItem}>
              {
                "If you have any automated messages set up in your PMS, please disable them now."
              }
            </li>
          </ul>
        </>
      }
      confirmLabel="Yes, go live now"
      confirmAction={handleGoLive}
      cancelLabel="I'm not ready yet"
      cancelAction={handleClose}
    />
  );

  const loadingModal = (
    <FullLoader
      open={loading}
      disableDismiss
      onClose={() => setLoading((prev) => false)}
      loadingText={"Going live..."}
    />
  );

  console.log("GOT GROUP", guestJourney);
  return (
    <div className={classes.root} ref={containerRef}>
      {confirmationModal}
      {loadingModal}
      <div className={clsx(classes.container, {"-collapsed": !!collapsed})}>
        <div className={classes.header}>
          <Typography variant="h2">{"Complete your guest journey"}</Typography>
          {isLive ? (
            <Typography component="div" className={classes.live}>
              <Typography component="div" className={classes.dot}>
                {"•"}
              </Typography>
              {"Live"}
            </Typography>
          ) : (
            <PrimaryButton
              id={"go-live-btn"}
              onClick={onGoLiveClick}
              label="Go live"
              color="secondary"
            />
          )}
        </div>
        <div className={classes.progressbarContainer}>
          <ProgressBar progress={completed} />
        </div>

        {cards.map((card) => (
          <div className={classes.sectionItem} key={card.name}>
            <div style={{display: "flex"}}>
              <Typography variant="h1">{card.name}</Typography>
              <div style={{flex: 1}}></div>
              {!!card.button && (
                <div>
                  <PrimaryButton
                    id={"go-live-btn"}
                    onClick={card.onButtonClick}
                    label={card.button}
                  />
                </div>
              )}
            </div>
            <div className={classes.cardItemText}>
              <Typography>{card.text}</Typography>
            </div>
            <div className={classes.content}>
              {card.cards.map((card_data) => {
                return (
                  <StepItem
                    key={`${card_data.section_key}-${card_data.card_key}`}
                    card={card_data}
                    selected={
                      card_data.card_key === selected_card &&
                      selected_section === card_data.section_key
                    }
                    isDone={
                      guestJourney.progress?.[
                        `${card_data.section_key}_${card_data.card_key}`
                      ] !== "setup"
                    }
                    label={card_data.label}
                    handleSetupAction={handleSetupAction}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
