import React from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
// UI
import {List, ListItem, Typography} from "@material-ui/core";

export default function UpdateSession({updates}) {
  const {group_id} = useParams();
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );

  function getGroup(group) {
    if (!!group_id && group_id === group) {
      return "this group";
    } else {
      return !!listingGroups[group]?.name
        ? `group: ${listingGroups[group]?.name}`
        : "a group";
    }
  }

  function scheduleActionLabel(upd) {
    let n_unsch = upd.unschedule_actions?.length;
    let n_sch = upd.schedule_actions?.length;
    return (
      <>
        {!!upd.schedule_bookings?.length && (
          <ListItem key={`${upd.action}-${0}`} disableGutters divider>
            {n_sch} messages will be scheduled for{" "}
            {upd.schedule_bookings.length} upcoming bookings
          </ListItem>
        )}
        {!!upd.schedule_guests?.length && (
          <ListItem key={`${upd.action}-${0}`} disableGutters divider>
            {n_sch} messages will be scheduled for {upd.schedule_guests.length}{" "}
            guests
          </ListItem>
        )}
        {!!upd.unschedule_bookings?.length && (
          <ListItem key={`${upd.action}-${1}`} disableGutters divider>
            {n_unsch} messages will be cancelled for{" "}
            {upd.unschedule_bookings.length} upcoming bookings
          </ListItem>
        )}
        {!!upd.unschedule_guests?.length && (
          <ListItem key={`${upd.action}-${1}`} disableGutters divider>
            {n_unsch} messages will be cancelled for{" "}
            {upd.unschedule_guests.length} guests
          </ListItem>
        )}
      </>
    );
  }

  function getLabel(upd) {
    if (upd.action?.action_id === "write_update_group") {
      let n_sch = upd.action.add_listings?.length;
      let n_unsch = upd.action.remove_listings?.length;
      return (
        <>
          {!!n_sch && (
            <ListItem key={`${upd.action}-${0}`} disableGutters divider>
              {n_sch} listings will be added to {getGroup(upd.action.group_id)}
            </ListItem>
          )}
          {!!n_unsch && (
            <ListItem key={`${upd.action}-${1}`} disableGutters divider>
              {n_unsch} listings will be removed from{" "}
              {getGroup(upd.action.group_id)}
            </ListItem>
          )}
          {scheduleActionLabel(upd.action)}
        </>
      );
    }
    if (upd.action === "SCHEDULE_ACTION") {
      return scheduleActionLabel(upd);
    }
    if (upd.action == "QUEUE_ACTION") {
      let action = upd.scheduled_action.action;
      return (
        <>
          <ListItem key={`auto-message`} disableGutters divider>
            {action.action_id} - {!!action.remove_group_ids ? "remove" : "add"}{" "}
            {action.verifier}
          </ListItem>
        </>
      );
    }
  }

  return (
    <List dense className="mt-2">
      {!!updates?.length ? (
        updates.map((upd, i) => (
          <React.Fragment key={`${upd.action ?? "update"}-${i}`}>
            {getLabel(upd)}
          </React.Fragment>
        ))
      ) : (
        <Typography color="textSecondary">
          {"There are no updates to be made with this action"}
        </Typography>
      )}
    </List>
  );
}
