import React from "react";
// UI
import {Col, Row} from "reactstrap";
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
// Custom
import DateTimePicker from "core/inputs/DateTimePicker";
// Utilities
import {getTimeFromString} from "utilities/helperFunctions";
import {format, isValid} from "date-fns";
import clsx from "clsx";
import _ from "lodash";

const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.6)",
    marginBottom: theme.spacing(3),
  },
  list: {
    "& li .-iconBtn": {opacity: 0},
    "& li:hover .-iconBtn": {opacity: 1},
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    cursor: "pointer",
  },
  grey: {color: "rgba(0,0,0,0.6)"},
  capitalize: {textTransform: "capitalize"},
  bold: {color: "rgba(0,0,0,0.870588)"},
  iconBtn: {
    transition: "0.15s",
    padding: 2,
    marginLeft: theme.spacing(2),
  },
  icon: {
    fontSize: 16,
    color: "rgba(0, 0, 0, 0.15)",
  },
  hide: {opacity: "0 !important"},
  editSection: {padding: theme.spacing(4, 0, 2)},
}));

export default function OperationHoursDates({value, updateValue}) {
  const classes = useStyles();
  const [edit, setEdit] = React.useState(null);
  const [hours, setHours] = React.useState(null);

  React.useEffect(() => {
    setHours((prev) => ({
      sun: {
        start: getTimeFromString(value.sun[0]),
        end: getTimeFromString(value.sun[1]),
      },
      mon: {
        start: getTimeFromString(value.mon[0]),
        end: getTimeFromString(value.mon[1]),
      },
      tue: {
        start: getTimeFromString(value.tue[0]),
        end: getTimeFromString(value.tue[1]),
      },
      wed: {
        start: getTimeFromString(value.wed[0]),
        end: getTimeFromString(value.wed[1]),
      },
      thu: {
        start: getTimeFromString(value.thu[0]),
        end: getTimeFromString(value.thu[1]),
      },
      fri: {
        start: getTimeFromString(value.fri[0]),
        end: getTimeFromString(value.fri[1]),
      },
      sat: {
        start: getTimeFromString(value.sat[0]),
        end: getTimeFromString(value.sat[1]),
      },
    }));
  }, [value]);

  const closeEdit = () => setEdit((prev) => null);
  const handleEditClick = (day) => () => setEdit((prev) => day);

  const saveHours = (currValues) => {
    const newValues = {
      sun: [
        isValid(currValues.sun.start)
          ? format(currValues.sun.start, "H:mm")
          : null,
        isValid(currValues.sun.end) ? format(currValues.sun.end, "H:mm") : null,
      ],
      mon: [
        isValid(currValues.mon.start)
          ? format(currValues.mon.start, "H:mm")
          : null,
        isValid(currValues.mon.end) ? format(currValues.mon.end, "H:mm") : null,
      ],
      tue: [
        isValid(currValues.tue.start)
          ? format(currValues.tue.start, "H:mm")
          : null,
        isValid(currValues.tue.end) ? format(currValues.tue.end, "H:mm") : null,
      ],
      wed: [
        isValid(currValues.wed.start)
          ? format(currValues.wed.start, "H:mm")
          : null,
        isValid(currValues.wed.end) ? format(currValues.wed.end, "H:mm") : null,
      ],
      thu: [
        isValid(currValues.thu.start)
          ? format(currValues.thu.start, "H:mm")
          : null,
        isValid(currValues.thu.end) ? format(currValues.thu.end, "H:mm") : null,
      ],
      fri: [
        isValid(currValues.fri.start)
          ? format(currValues.fri.start, "H:mm")
          : null,
        isValid(currValues.fri.end) ? format(currValues.fri.end, "H:mm") : null,
      ],
      sat: [
        isValid(currValues.sat.start)
          ? format(currValues.sat.start, "H:mm")
          : null,
        isValid(currValues.sat.end) ? format(currValues.sat.end, "H:mm") : null,
      ],
    };
    updateValue(newValues);
  };

  const handleTimeChange = (day, key) => (date) => {
    const newHours = {...hours, [day]: {...hours[day], [key]: date}};
    setHours((prev) => newHours);
    saveHours(newHours);
  };

  if (!hours) {
    return null;
  }
  return (
    <div className="mb-3">
      <Typography variant="subtitle1" className={classes.title}>
        Hours of Operation
      </Typography>
      <List disablePadding classes={{root: classes.list}}>
        {days.map((d) => (
          <React.Fragment key={d}>
            <ListItem
              divider
              disableGutters
              className={classes.listItem}
              onClick={!!edit && edit === d ? closeEdit : handleEditClick(d)}
            >
              <ListItemText
                primary={d}
                primaryTypographyProps={{
                  variant: "caption",
                  className: clsx(classes.grey, classes.capitalize, {
                    [classes.bold]: edit === d,
                  }),
                }}
              />
              <ListItemSecondaryAction>
                <Typography
                  variant="caption"
                  className={clsx(classes.grey, {[classes.bold]: edit === d})}
                >
                  {hours[d].disabled
                    ? "Closed"
                    : `${isValid(hours[d].start) ? format(hours[d].start, "h:mmaaa") : "-"} 
                to 
                ${isValid(hours[d].end) ? format(hours[d].end, "h:mmaaa") : "-"}`}
                </Typography>
                <IconButton
                  disabled={edit === d}
                  className={clsx(classes.iconBtn, "-iconBtn", {
                    [classes.hide]: edit === d,
                  })}
                  onClick={handleEditClick(d)}
                >
                  <EditIcon className={classes.icon} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={edit === d} timeout="auto" mountOnEnter unmountOnExit>
              <div className={classes.editSection}>
                <Row>
                  <Col xs={6}>
                    <DateTimePicker
                      type="time"
                      label="Start Time"
                      value={hours[d].start}
                      disabled={hours[d].disabled}
                      onChange={handleTimeChange(d, "start")}
                    />
                  </Col>
                  <Col xs={6}>
                    <DateTimePicker
                      type="time"
                      label="End Time"
                      value={hours[d].end}
                      disabled={hours[d].disabled}
                      onChange={handleTimeChange(d, "end")}
                    />
                  </Col>
                </Row>
              </div>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}
