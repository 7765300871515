import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {Col, Row} from "reactstrap";
import {Button, IconButton, MenuItem, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
// Custom
import LanguageSelector from "components/MultiOption/LanguageSelector";
import {EmptyListingInfo} from "components/Helpers/EmptyPanels";
import CustomCardHeader from "core/cards/CustomCardHeader";
import InputField from "core/inputs/InputField";
import FilledSelect from "core/selects/FilledSelect";
import SaveContentButton from "../../MultiOption/SaveContentButton";
import ListingContentList from "../../Lists/ListingContentList";
import BackButton from "core/buttons/BackButton";
// Actions
import {updateListing} from "redux/actions/listingsActions";
// Utilities
import {
  currencyEnum,
  ListingRoomAmenityTypeEnum,
  PropertyTypesEnum,
} from "configuration/enums.js";
import {addArrayItemsToSet} from "utilities/helperFunctions";
import {THEME} from "configuration/settings";
import usePrevious from "hooks/usePrevious";
import clsx from "clsx";
import _ from "lodash";

const fields = {
  nickname: "Nickname",
  name: "Name",
  type: "Property type",
  currency: "Currency",
  wifi_net: "Wifi network",
  wifi_pass: "Wifi password",
  adults: "Adults",
  children: "Children",
};

const enums = {
  properties: Object.keys(PropertyTypesEnum),
  currencies: Object.values(currencyEnum),
  bedTypes: ListingRoomAmenityTypeEnum,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: theme.spacing(4),
  },
  formControl: {
    height: "100%",
    justifyContent: "center",
  },
  cardHeader: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.primary.main + "0D"}`,
  },
  cancelBtn: {
    color: "#A8A8A8",
    fontSize: 14,
  },
  selectorContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  formControlLabel: {marginBottom: 0},
  column: {
    display: "flex",
    flexDirection: "column",
  },
  sectionTitle: {color: THEME.grey},
  closeBtn: {
    padding: 2,
    marginLeft: theme.spacing(3),
    marginRight: -theme.spacing(2),
  },
  closeBtnIcon: {
    fontSize: 18,
    color: "#AAA",
  },
  editBtn: {
    backgroundColor: "#FFF !important",
    fontWeight: 400,
    padding: 0,
  },
  partialMessage: {
    maxHeight: 150,
    overflow: "hidden",
  },
  fullMessage: {maxHeight: "fit-content"},
}));

export default function GeneralInformationPanel({
  outsideView,
  listing,
  isParentLoading,
  onClose,
  disableEdit,
  goBack,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const updatedFields = React.useRef([]);
  const defaultLang = useSelector((state) => state.defaultReducer.language);
  const loadingContent = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_content;
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  // State
  const [nickname, setNickname] = React.useState("");
  const [name, setName] = React.useState("");
  const [propertyType, setPropertyType] = React.useState("");
  const [currency, setCurrency] = React.useState("");
  const [guests, setGuests] = React.useState({adults: 0, children: 0});
  const [wifiNetwork, setWifiNetwork] = React.useState("");
  const [wifiPassword, setWifiPassword] = React.useState("");
  const [selectedLang, setSelectedLang] = React.useState(defaultLang);
  const [availableLangs, setAvailableLangs] = React.useState([]);
  const [edit, setEdit] = React.useState(false);
  const [multiLangValues, setMultiLangValues] = React.useState({name: {}});
  const [langIndex, setLangIndex] = React.useState({name: 0});
  // General
  const prevEdit = usePrevious(edit);
  const listingContent = listing?.listing_content || {};
  const hasChildren = !!listing?.max_guests?.children;

  React.useEffect(() => {
    const allAvalLangs = new Set();
    addArrayItemsToSet(Object.keys(multiLangValues.name), allAvalLangs);
    const newAvalLangs =
      allAvalLangs.size > 0 ? [...allAvalLangs] : [defaultLang];

    setName((prev) => multiLangValues.name[selectedLang] || "");
    setAvailableLangs((prev) => newAvalLangs);

    if (!edit) {
      setSelectedLang((prev) =>
        newAvalLangs.includes(selectedLang)
          ? selectedLang
          : newAvalLangs.includes(defaultLang)
            ? defaultLang
            : newAvalLangs[0 || defaultLang],
      );
    }
  }, [selectedLang, edit, multiLangValues, listing]);

  React.useEffect(() => {
    if (!!edit && !!prevEdit) {
      return;
    }
    let nameObj = {};

    if (!!listingContent.names) {
      _.each(listingContent.names, (n) => (nameObj[n.language] = n.value));
    } else {
      nameObj[defaultLang] = "";
    }

    setMultiLangValues((prev) => ({name: nameObj}));
    setNickname((prev) => listing?.nickname || "");
    setName((prev) => listing?.name || "");
    setPropertyType((prev) => listing?.type || "");
    setCurrency((prev) => listing?.currency || "");
    setWifiNetwork((prev) => listingContent.policy?.wifi_info?.ssid || "");
    setWifiPassword((prev) => listingContent.policy?.wifi_info?.password || "");
    setGuests((prev) => ({
      adults: listing?.max_guests?.persons ?? 0,
      children: listing?.max_guests?.children ?? 0,
    }));
  }, [edit, listing]);

  React.useEffect(() => {
    const nameIndex =
      listingContent.names?.findIndex((n) => n.language === selectedLang) ?? -1;
    setLangIndex({
      name: nameIndex > -1 ? nameIndex : (listingContent.names?.length ?? 0),
    });
  }, [selectedLang]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setMultiLangValues((prev) => ({
        name: {...prev.name, [selectedLang]: name},
      }));
    }, 200);

    return () => clearTimeout(timer);
  }, [name]);

  const addLang = (lang) => {
    setAvailableLangs((prev) => [...prev, lang]);
    setSelectedLang((prev) => lang);
    setMultiLangValues((prev) => ({name: {...prev.name, [lang]: ""}}));
  };

  const removeLang = (lang) => {
    let newLangs = availableLangs.filter((l) => l !== lang);
    setAvailableLangs((prev) => newLangs);
    setSelectedLang((prev) => (prev === lang ? newLangs[0] : prev));
    const newContent = {...multiLangValues};
    delete newContent.name[lang];
    setMultiLangValues((prev) => newContent);
  };

  const handleCancel = () => {
    updatedFields.current = [];
    setEdit(false);
  };
  const handleNicknameChange = (e) => {
    const val = e.target.value;
    setNickname((prev) => val);
    addUpdatedField("nickname");
  };
  const handleNameChange = (e) => {
    const val = e.target.value;
    setName((prev) => val);
    addUpdatedField("name");
  };
  const handlePropertyTypeChange = (e) => {
    const val = e.target.value;
    setPropertyType((prev) => val);
    addUpdatedField("type");
  };
  const handleCurrencyChange = (e) => {
    const val = e.target.value;
    setCurrency((prev) => val);
    addUpdatedField("currency");
  };
  const handleWifiNetworkChange = (e) => {
    const val = e.target.value;
    setWifiNetwork((prev) => val);
    addUpdatedField("wifi_net");
  };
  const handleWifiPasswordChange = (e) => {
    const val = e.target.value;
    setWifiPassword((prev) => val);
    addUpdatedField("wifi_pass");
  };
  const addUpdatedField = (f) => {
    if (!updatedFields.current.includes(f)) {
      updatedFields.current.push(f);
    }
  };

  function getBody() {
    const nameKeys = Object.keys(multiLangValues.name);
    let body = null;

    if (updatedFields.current.includes("type")) {
      body = {property_type: propertyType};
    }
    if (updatedFields.current.includes("name")) {
      body = {
        ...body,
        names: _.map(nameKeys, (nk) => ({
          language: nk,
          value: multiLangValues.name[nk] || "",
        })).filter((langText) => !!langText.value),
      };
    }
    if (updatedFields.current.includes("nickname")) {
      body = {...body, nickname: nickname};
    }
    if (updatedFields.current.includes("currency")) {
      body = {...body, currency: currency};
    }
    if (updatedFields.current.includes("wifi_net")) {
      body = {...body, policy: {wifi_info: {ssid: wifiNetwork}}};
    }
    if (updatedFields.current.includes("wifi_pass")) {
      body = {
        ...body,
        policy: {
          wifi_info: {...body?.policy?.wifi_info, password: wifiPassword},
        },
      };
    }
    if (updatedFields.current.includes("adults")) {
      body = {...body, max_guests: {persons: Number(guests.adults)}};
    }
    if (updatedFields.current.includes("children")) {
      body = {
        ...body,
        max_guests: {...body?.max_guests, children: Number(guests.children)},
      };
    }

    return body;
  }

  function handleSave(option) {
    let body = getBody();
    if (!body) {
      return;
    }
    dispatch(updateListing({body, listing, option}));
    setEdit(false);
    updatedFields.current = [];
  }

  const handleGuestsChange = (e) => {
    const val = e.target.value;
    const id = e.target.id;
    setGuests((prev) => ({...prev, [id]: val}));
    addUpdatedField(id);
  };

  function getEditButton() {
    if (!!disableEdit) {
      return null;
    }
    return (
      <Button
        color="secondary"
        disableElevation
        disableRipple
        className={classes.editBtn}
        onClick={() => setEdit(true)}
        disabled={isParentLoading || loadingContent}
      >
        Edit
      </Button>
    );
  }

  function getViewOnlyField(label, value, ref = null, expanded, isLarge) {
    return (
      <div
        ref={ref}
        className={clsx(classes.column, {
          [classes.fullMessage]: ref !== null && expanded,
          [classes.partialMessage]: ref !== null && !expanded && isLarge,
        })}
      >
        <InputField
          fullWidth
          disableEdit
          disableCentered
          value={value || "None"}
          label={label}
        />
      </div>
    );
  }

  function getTextField({
    key,
    label,
    value,
    placeholder,
    onChange,
    number,
    optional,
  }) {
    return (
      <InputField
        key={key}
        fullWidth
        editOnly
        label={`${label}${!!optional ? " (Optional)" : ""}`}
        value={value || (!!number ? 0 : "")}
        placeholder={placeholder}
        onChange={onChange}
        inputProps={{
          multiline: !number,
          type: !!number ? "number" : "text",
          inputProps: !!number ? {min: 0, max: 1000} : undefined,
        }}
      />
    );
  }

  function getSelect({
    label,
    value,
    options,
    onChange,
    optional,
    getOptionLabel,
  }) {
    return (
      <FilledSelect
        value={value}
        label={`${label}${!!optional ? " (Optional)" : ""}`}
        fullWidth
        onChange={onChange}
      >
        <MenuItem disabled value="">
          Select an option
        </MenuItem>
        {options.map((opt) => (
          <MenuItem key={opt} value={opt}>
            {!!getOptionLabel ? getOptionLabel(opt) : opt}
          </MenuItem>
        ))}
      </FilledSelect>
    );
  }

  // Fields
  const nicknameField = React.useMemo(() => {
    return !edit
      ? getViewOnlyField("Nickname", listing?.nickname)
      : getTextField({
          key: "nickname",
          label: "Nickname",
          value: nickname,
          placeholder: "Listing nickname",
          onChange: handleNicknameChange,
        });
  }, [nickname, edit]);

  const nameField = React.useMemo(() => {
    return !edit
      ? getViewOnlyField("Name", listingContent.names?.[langIndex.name]?.value)
      : getTextField({
          key: "name",
          label: "Name",
          value: name,
          placeholder: "Listing name",
          onChange: handleNameChange,
        });
  }, [name, edit]);

  const typeField = React.useMemo(() => {
    return !edit
      ? getViewOnlyField("Property type", PropertyTypesEnum[listing?.type])
      : getSelect({
          label: "Property Type",
          value: propertyType,
          options: enums.properties,
          onChange: handlePropertyTypeChange,
          getOptionLabel: (opt) => PropertyTypesEnum[opt],
        });
  }, [propertyType, edit]);

  const currencyField = React.useMemo(() => {
    return !edit
      ? getViewOnlyField("Currency", listing?.currency)
      : getSelect({
          label: "Currency",
          value: currency,
          options: enums.currencies,
          onChange: handleCurrencyChange,
        });
  }, [currency, edit]);

  const adultsField = React.useMemo(() => {
    return !edit
      ? getViewOnlyField("Guests", listing?.max_guests?.persons)
      : getTextField({
          key: "adults",
          label: "Adults",
          value: guests.adults,
          placeholder: "0",
          onChange: handleGuestsChange,
          number: true,
        });
  }, [guests, edit]);

  const childrenField = React.useMemo(() => {
    return !edit
      ? getViewOnlyField("Children", listing?.max_guests?.children)
      : getTextField({
          key: "children",
          label: "Children",
          value: guests.children,
          placeholder: "0",
          onChange: handleGuestsChange,
          number: true,
          optional: true,
        });
  }, [guests, edit]);

  const wifiNetworkField = React.useMemo(() => {
    return !edit
      ? getViewOnlyField("Wifi network", listingContent.policy?.wifi_info?.ssid)
      : getTextField({
          key: "wifi_network",
          label: "Wifi network",
          value: wifiNetwork,
          onChange: handleWifiNetworkChange,
        });
  }, [wifiNetwork, edit]);

  const wifiPasswordField = React.useMemo(() => {
    return !edit
      ? getViewOnlyField(
          "Wifi password",
          listingContent.policy?.wifi_info?.password,
        )
      : getTextField({
          key: "wifi_password",
          label: "Wifi password",
          value: wifiPassword,
          onChange: handleWifiPasswordChange,
        });
  }, [wifiPassword, edit]);

  // Title components
  const languageSelector = React.useMemo(() => {
    return (
      <LanguageSelector
        preventOverflow
        selectedLangs={availableLangs}
        selectedLanguage={selectedLang}
        addLang={addLang}
        disableEdit={!edit}
        disableSearch={!edit}
        removeLang={removeLang}
        selectLang={setSelectedLang}
      />
    );
  }, [edit, listing, selectedLang, availableLangs]);

  const title = (
    <CustomCardHeader
      title={
        !!goBack ? (
          <BackButton
            header={!isMobileView}
            color={!!isMobileView ? "secondary" : "primary"}
            goBack={goBack}
          />
        ) : (
          "General Information"
        )
      }
      className={classes.cardHeader}
      action={
        edit ? (
          <>
            <Button
              className="mr-2"
              classes={{label: classes.cancelBtn}}
              size="small"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <SaveContentButton
              disabledOptions={["all"]}
              disabled={!updatedFields.current.length}
              fields={updatedFields.current.map((f) => fields[f])}
              onSave={handleSave}
            />
          </>
        ) : (
          <>
            {getEditButton()}
            {!goBack && (
              <IconButton className={classes.closeBtn} onClick={onClose}>
                <CloseIcon className={classes.closeBtnIcon} />
              </IconButton>
            )}
          </>
        )
      }
    />
  );

  const outsideTitleRow = (
    <ListingContentList
      listingId={listing?.listing_id}
      onlyItems={["info"]}
      disableBackground
      actions={languageSelector}
    />
  );

  return (
    <div className={classes.root}>
      {title}
      {!!outsideView && outsideTitleRow}
      <div className={classes.content}>
        {isParentLoading || loadingContent ? (
          <EmptyListingInfo loading />
        ) : (
          <>
            {!outsideView && (
              <div className={classes.selectorContainer}>
                {languageSelector}
              </div>
            )}
            <Row>
              <Col xs={12} className="mb-4">
                {nicknameField}
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-4">
                {nameField}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="mb-4">
                {typeField}
              </Col>
              <Col xs={12} md={6} className="mb-4">
                {currencyField}
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mt-4 mb-3">
                <Typography variant="h2" className={classes.sectionTitle}>
                  {"Wifi"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="mb-4">
                {wifiNetworkField}
              </Col>
              <Col xs={12} md={6} className="mb-4">
                {wifiPasswordField}
              </Col>
            </Row>
            {/* Occupancy & Space */}
            <Row>
              <Col xs={12} className="mt-4 mb-3">
                <Typography variant="h2" className={classes.sectionTitle}>
                  {"Occupancy"}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={edit ? 6 : 3} className="mb-4">
                {adultsField}
              </Col>
              {(edit || hasChildren) && (
                <Col xs={12} sm={edit ? 6 : 3} className="mb-4">
                  {childrenField}
                </Col>
              )}
            </Row>
          </>
        )}
      </div>
    </div>
  );
}
