import * as types from "../actionTypes";
import * as API from "../api/messagesAPI";
import {setApiError, setApiStart, setApiSuccess} from "./settingsActions";

// -------- MESSAGES ---------------
export function addMessages(guestId, messages, sync) {
  return {type: types.ADD_MESSAGES, guestId, messages, sync};
}

export function appendMessage(guest_id, message) {
  return {
    type: types.APPEND_MESSAGE,
    guest_id: guest_id,
    message: message,
  };
}

export function editMessages(message, previousID) {
  return {type: types.EDIT_MESSAGE, message, previousID};
}

export function setChatOptions(guestId, chatOptions) {
  return {type: types.SET_CHAT_OPTIONS, guestId, chatOptions};
}

/**
 * @param {string} guestId Get HTML content from this guest ID
 * @param {string} body The HTML email
 * @param {string} htmlId The HTML key of the S3 email bucket object
 */
export function addHTMLContent(guestId, body, htmlId) {
  return {type: types.ADD_HTML_CONTENT, guestId, body, htmlId};
}

/**
 * @param {string} guestId Get messages from this guest ID
 * @param {number | undefined} lastSent To load more messages since the last loaded message
 */
export function getMessages({guestId, lastSent, sync = false, onSuccess}) {
  const params = `guest_id=${guestId}${!lastSent ? "" : `&sent=${lastSent}`}`;
  return async (dispatch) => {
    dispatch(setApiStart("messages"));
    try {
      const response = await API.getMessages(params);
      if (response) {
        guestId && dispatch(addMessages(guestId, response, sync));
        dispatch(setApiSuccess("messages"));
        onSuccess && onSuccess(response);
      } else dispatch(setApiError("messages", "Could not receive messages"));
    } catch (error) {
      console.error(`ERROR - GET GUESTS MESSAGES: ${guestId}`, error);
      dispatch(setApiError("messages", "Could not receive messages"));
    }
  };
}

export function sendMessages(data, newMessage, onSuccess, onError) {
  return (dispatch) => {
    const guestId = data.body.guest_id;
    dispatch(appendMessage(guestId, newMessage));
    API.postMessage(data)
      .then((response) => {
        onSuccess && onSuccess(response);
      })
      .catch((err) => {
        console.log("ERROR - SEND MESSAGE", err);
        onError && onError();
      });
  };
}

export function markResponse(body, onSuccess, onError) {
  return async (dispatch) => {
    try {
      const response = await API.patchMessage({body: body});
      dispatch(editMessages(response));
      onSuccess && onSuccess(response);
    } catch (error) {
      console.error("ERROR - MARK RESPONSE", error);
      onError && onError();
    }
  };
}

export function getEmailTemplatePreview(
  templateEnum,
  templateFields,
  onSuccess,
) {
  return (dispatch, getState) => {
    const current_user = getState().defaultReducer.current_user;
    const body = {
      enso_key: current_user,
      template_enum: templateEnum,
      template_fields: templateFields,
    };
    API.postTemplatesPreview({body: body})
      .then((response) => {
        console.log("GOT TEMPLATE PREVIEW", response);
        onSuccess && onSuccess(response.html);
      })
      .catch((err) => {
        console.log("ERROR - GET TEMPLATE PREVIEW", err);
      });
  };
}

export function getGuestChatOptions({
  guestId,
  onSuccess = () => {},
  onError = () => {},
}) {
  return async (dispatch) => {
    try {
      const response = await API.getChatOptions(guestId);
      dispatch(setChatOptions(guestId, response));
      onSuccess();
    } catch (error) {
      console.error("ERROR - GET CHAT OPTIONS", error);
      onError();
    }
  };
}
