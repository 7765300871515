import React from "react";
import CustomDialog from "core/dialogs/CustomDialog";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom/cjs/react-router-dom";

export default function PaymentMessage() {
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const remaining = user_profile?.pay_by - Date.now();
  const dismissible = remaining > 0;
  const daysRemaining = Math.floor(remaining / 86400000);
  let message = "";

  if (user_profile.plan === "new") {
    if (dismissible) {
      message += `Your free trial ends in ${daysRemaining} days. `;
    } else {
      message += `Your free trial has expired. `;
    }
    message += `Please add payment information to continue with your subscription.`;
  } else if (user_profile.plan === "sus") {
    message = `Your previous payment failed. Please update your payment information and continue with your subscription.`;
  } else if (user_profile.plan === "free") {
    message =
      "Please contact your account manager for more information about billing.";
  } else if (user_profile.plan === "off") {
    message =
      "Your subscription has ended. Please update your payment information and continue with your subscription.";
  } else if (user_profile.plan === "cncl") {
    message =
      "Your subscription has been cancelled. Please update your payment information and continue with your subscription.";
  } else if (daysRemaining > 0) {
    message += `Your free trial ends in ${daysRemaining} days. `;
  }
  return message;
}
