import React from "react";
import {Typography} from "@material-ui/core";

function isHTMLEmpty(html) {
  if (!html) {
    return true;
  }
  const htmlWithoutSpaces = html
    .replace(/<style>.*<\/style>/g, "")
    .replace(/<(.|n)*?>/g, "")
    .trim();
  return !htmlWithoutSpaces.length;
}

export default function KeycardHTMLField({content, className}) {
  const isContentEmpty = React.useMemo(() => isHTMLEmpty(content), [content]);

  function getHTMLText() {
    return content
      .replace(/<style>.*<\/style>|<[^>]*>|&nbsp;/g, " ")
      .replace("&amp;", "&");
  }

  return (
    <Typography className={className}>
      {isContentEmpty ? "" : getHTMLText()}
    </Typography>
  );
}
