import React from "react";
import {
  Avatar,
  Button,
  ListItemAvatar,
  MenuItem,
  MenuList,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomMenu from "core/menus/CustomMenu";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "fit-content",
    width: (props) => (!!props.fullWidth ? "100%" : "fit-content"),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  label: {
    color: "rgba(109, 113, 117, 1)",
    paddingBottom: theme.spacing(1),
  },
  button: {
    border: "1px solid #6D7175",
    height: "fit-content",
    borderRadius: 20,
    padding: "2px 8px",
  },
  buttonLabel: {
    fontWeight: 500,
    color: THEME.subdued,
    "&.-clipped": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      wordBreak: "break-word",
      WebkitLineClamp: 1,
    },
  },
  buttonStartIcon: {marginLeft: 0},
  avatar: {
    width: 16,
    height: 16,
    fontSize: "12px!important",
  },
  listItemAvatar: {
    minWidth: "fit-content",
    marginRight: theme.spacing(3),
  },
}));

export default function ChipSelect({
  value,
  label,
  options = [],
  onChange,
  fullWidth,
  useAvatar,
  emptyOption = "",
  objKeys = {},
  onClick,
  clipped,
}) {
  const classes = useStyles({fullWidth});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const hasKeys = !!Object.keys(objKeys).length;

  const handleOptionClick = (opt) => {
    setAnchorEl((prev) => null);
    onChange(opt[objKeys.value]);
  };

  return (
    <div className={classes.container}>
      {!!label && (
        <Typography variant="caption" className={classes.label}>
          {label}
        </Typography>
      )}
      <Button
        disableElevation
        disableRipple
        fullWidth={fullWidth}
        startIcon={
          !!useAvatar && (
            <Avatar
              alt={value[objKeys.alt]}
              src={value[objKeys.src]}
              className={classes.avatar}
            />
          )
        }
        endIcon={<ExpandMoreIcon htmlColor="#6D7175" />}
        classes={{
          root: classes.button,
          startIcon: classes.buttonStartIcon,
        }}
        onClick={!!onClick ? onClick : (e) => setAnchorEl(e.currentTarget)}
      >
        <Typography
          className={clsx(classes.buttonLabel, {"-clipped": !!clipped})}
        >
          {hasKeys
            ? (value?.[objKeys.text] ?? emptyOption)
            : (value ?? emptyOption)}
        </Typography>
      </Button>
      <CustomMenu
        overflowAuto
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl((prev) => null)}
        content={
          <MenuList>
            {!!emptyOption && (
              <MenuItem
                value={null}
                onClick={() => {
                  setAnchorEl((prev) => null);
                  onChange(null);
                }}
              >
                {emptyOption}
              </MenuItem>
            )}
            {options.map((opt, index) => {
              return (
                <MenuItem key={index} onClick={() => handleOptionClick(opt)}>
                  <ListItemAvatar className={classes.listItemAvatar}>
                    <Avatar
                      alt={opt[objKeys.alt]}
                      src={opt[objKeys.src]}
                      className={classes.avatar}
                    />
                  </ListItemAvatar>
                  <Typography>{opt[objKeys.text]}</Typography>
                </MenuItem>
              );
            })}
          </MenuList>
        }
      />
    </div>
  );
}
