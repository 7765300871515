import {createTheme} from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {THEME} from "configuration/settings.js";

const theme = createTheme({
  palette: {
    primary: {main: THEME.primary},
    secondary: {main: THEME.secondary},
    warning: {main: THEME.warning},
    error: {main: THEME.error},
    text: {primary: THEME.text, secondary: THEME.textSecondary},
    background: {default: THEME.bg},
  },
  breakpoints: {
    // Mobile: 0 - 889
    // Tablet: 900 - 1279
    // Desktop: 1280 - 1920
    values: {
      xs: 0,
      sm: 900,
      md: 992,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: Object.values(THEME.spacing),
  overrides: {
    MuiTypography: {
      root: {margin: 0},
      srOnly: {
        width: 1,
        height: 1,
        overflow: "hidden",
        position: "absolute",
      },
      alignLeft: {textAlign: "left"},
      alignCenter: {textAlign: "center"},
      alignRight: {textAlign: "right"},
      alignJustify: {textAlign: "justify"},
      noWrap: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
      gutterBottom: {marginBottom: "0.35em"},
      paragraph: {marginBottom: 16},
      colorInherit: {color: "inherit"},
      colorPrimary: {color: THEME.primary},
      colorSecondary: {color: THEME.secondary},
      colorTextPrimary: {color: THEME.text},
      colorTextSecondary: {color: THEME.textSecondary},
      colorError: {color: THEME.error},
      displayInline: {display: "inline"},
      displayBlock: {display: "block"},
    },
    MuiCard: {
      root: {
        border: "1px solid #E6E6E6",
        borderRadius: "10px",
        boxShadow: "2px 6px 15px -18px #000",
      },
    },
    MuiCardHeader: {root: {paddingTop: 32}},
    MuiButton: {
      root: {
        textTransform: "capitalize",
        borderRadius: "15px",
        "&:focus": {outline: "none"},
      },
      endIcon: {marginLeft: THEME.spacing.xs},
    },
    MuiButtonBase: {root: {"&:focus": {outline: "none"}}},
    MuiIconButton: {
      root: {
        transition: "0.15s",
        "&:hover": {backgroundColor: "transparent"},
        "&:focus": {outline: "none"},
      },
    },
    MuiAppBar: {root: {boxShadow: "2px 2px 22px -15px #484848"}},
    MuiList: {root: {padding: "0 !important"}},
    MuiSnackbar: {root: {top: "50%"}},
    MuiListItem: {
      root: {
        "&.Mui-selected": {backgroundColor: "rgba(67,84,96,0.04)"},
        "&.MuiListItem-button:hover": {backgroundColor: "rgba(67,84,96,0.04)"},
      },
      button: {transition: "background-color 0.15s"},
      divider: {borderBottom: "1px solid rgba(13,86,140,0.03)"},
    },
    MuiTab: {
      wrapper: {
        fontWeight: 600,
        fontSize: 12,
        lineHeight: "16px",
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: 20,
        fontFamily: THEME.customTypography.fontFamily,
        fontWeight: 500,
        lineHeight: "28px",
        letterSpacing: "-0.00833em",
        color: "#435460",
      },
    },
    MuiDialogContentText: {
      root: {
        fontSize: 14,
        fontFamily: THEME.customTypography.fontFamily,
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: "0.01071em",
        color: THEME.text,
      },
    },
    MuiDialogContent: {
      dividers: {
        padding: "16px 24px",
        borderTop: "1px solid rgba(191, 191, 191, 0.15)",
        borderBottom: "1px solid rgba(191, 191, 191, 0.15)",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
        fontFamily: THEME.customTypography.fontFamily,
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: "0.01071em",
      },
    },
    MuiSelect: {icon: {position: "relative"}},
  },
  props: {
    MuiCard: {elevation: 0},
    MuiButton: {disableElevation: true, disableTouchRipple: true},
    MuiIconButton: {disableRipple: true},
    MuiTab: {disableRipple: true},
    MuiSelect: {IconComponent: ExpandMoreIcon},
  },
});

// ----- TYPOGRAPHY -----
// Primary headers
theme.typography.h6 = {
  color: THEME.primary,
  fontSize: 26,
  fontStyle: "normal",
  fontFamily: THEME.customTypography.fontFamily,
  fontWeight: 600,
  lineHeight: "32px",
  letterSpacing: "0px",
  [theme.breakpoints.down("sm")]: {
    fontSize: 26,
    lineHeight: "26px",
  },
};
// DisplayXLarge
theme.typography.h5 = {
  fontSize: 42,
  fontFamily: THEME.customTypography.fontFamily,
  fontWeight: 600,
  lineHeight: "44px",
  [theme.breakpoints.down("sm")]: {
    fontSize: 27,
    lineHeight: "36px",
  },
};
// DisplayLarge
theme.typography.h4 = {
  fontSize: 28,
  fontFamily: THEME.customTypography.fontFamily,
  fontWeight: 600,
  lineHeight: "32px",
  letterSpacing: "0px",
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
    lineHeight: "28px",
  },
};
// DisplayMedium
theme.typography.h3 = {
  fontSize: 26,
  fontFamily: THEME.customTypography.fontFamily,
  fontWeight: 400,
  lineHeight: "32px",
  letterSpacing: "0px",
  [theme.breakpoints.down("sm")]: {
    fontSize: 21,
    lineHeight: "28px",
  },
};
// DisplaySmall
theme.typography.h2 = {
  fontSize: 20,
  fontFamily: THEME.customTypography.fontFamily,
  fontWeight: 400,
  lineHeight: "28px",
  letterSpacing: "0px",
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
    lineHeight: "24px",
  },
};
// Heading
theme.typography.h1 = {
  color: THEME.text,
  fontSize: 16,
  fontFamily: THEME.customTypography.fontFamily,
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0px",
  [theme.breakpoints.down("sm")]: {fontSize: 17},
};
// Subheading
theme.typography.subtitle1 = {
  fontSize: 12,
  fontFamily: THEME.customTypography.fontFamily,
  fontWeight: 600,
  color: THEME.grey,
  lineHeight: "16px",
  letterSpacing: "0px",
  textTransform: "uppercase",
  [theme.breakpoints.down("sm")]: {fontSize: 13},
};
// Subheading 2
theme.typography.subtitle2 = {
  color: "#202223",
  fontFamily:
    '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu',
  fontWeight: 500,
  fontSize: 15,
  lineHeight: "22px",
  letterSpacing: "-0.36px",
};
// Body
theme.typography.body1 = {
  fontSize: 14,
  fontFamily: THEME.customTypography.fontFamily,
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0px",
  [theme.breakpoints.down("sm")]: {fontSize: 15},
};
// Button
theme.typography.button = {
  fontSize: 14,
  fontStyle: "normal",
  fontFamily: THEME.customTypography.fontFamily,
  fontWeight: 500,
  lineHeight: "20px",
  letterSpacing: "0px",
  textTransform: "none",
  [theme.breakpoints.down("sm")]: {fontSize: 15},
};
// Caption
theme.typography.caption = {
  fontSize: 12,
  fontFamily: THEME.customTypography.fontFamily,
  fontWeight: 400,
  lineHeight: "16px",
  letterSpacing: "0px",
  [theme.breakpoints.down("sm")]: {
    fontSize: 13,
    lineHeight: "20px",
  },
};
theme.typography.overline = {
  fontSize: 12,
  fontFamily: THEME.customTypography.fontFamily,
  fontWeight: 400,
  lineHeight: 2.66,
  letterSpacing: "32px",
  textTransform: "uppercase",
};

export default theme;
