import React, {useState, useRef, useEffect, Fragment} from "react";
// UI
//Custom
import {useDispatch} from "react-redux";
import FullSizeCard from "core/cards/FullSizeCard";
import {
  CardContent,
  makeStyles,
  CircularProgress,
  Typography,
  Link,
} from "@material-ui/core";
// Custom
import ChannelRegisterForm from "routes/Login/Register/ChannelRegisterForm";
import AddInterphone from "components/Panels/Device/AddInterphone";
import CustomCardHeader from "core/cards/CustomCardHeader";
import IntegrationSelectionPanel from "components/Panels/Device/IntegrationSelectionPanel";
import LoginDevicePanel from "components/Panels/Device/LoginDevicePanel";
import {channelIntegrationTypeMap} from "configuration/enums.js";
import {signUpUrl} from "redux/actions/accountsActions";
// Actions
import {createService} from "redux/actions/servicesActions";
import {
  closeGeneralError,
  openGeneralError,
} from "redux/actions/settingsActions";
// Utilities
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
  centered: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    height: "auto",
    display: "flex",
    marginRight: theme.spacing(2),
    overflow: "hidden auto",
    flexDirection: "column",
  },
  stepper: {padding: theme.spacing(4)},
  cardHeader: {paddingBottom: 0},
  subtitle: {display: "flex"},
  link: {
    margin: theme.spacing(0, 2),
    textDecoration: "underline",
  },
  paddedContainer: {
    padding: theme.spacing(4),
  },
}));

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const Polling = ({handleNextStep}) => {
  useInterval(() => {
    handleNextStep();
  }, 1000 * 10);

  return <CircularProgress />;
};

const ProcessComplete = ({redirect, reset, history}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Typography variant="h5" color="primary" className="mb-2">
        New Devices Added
      </Typography>
      <Typography
        variant="h2"
        color="textSecondary"
        component="span"
        className={classes.subtitle}
      >
        {"Go back to "}
        <Link
          component="button"
          color="secondary"
          className={classes.link}
          onClick={redirect}
        >
          your devices
        </Link>
        {" or "}
        <Link
          component="button"
          color="secondary"
          className={classes.link}
          onClick={reset}
        >
          add new devices
        </Link>
        {" or "}
        <Link
          id="link-devices"
          component="button"
          color="secondary"
          className={classes.link}
          onClick={() => history.push("/admin/pairdevices")}
        >
          pair devices
        </Link>
      </Typography>
    </Fragment>
  );
};

export default function AddDevices({history}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [provider, setProvider] = useState(null);
  const [service, setService] = useState(null);
  const [stepType, setStepType] = useState("select_devices");
  const [stepOptions, setStepOptions] = useState(null);
  const [authId, setAuthId] = useState(null);
  const isPolling = ["polling", "redirect_polling"].includes(stepType);
  const centered = loading || isPolling || stepType === null;
  const windowOpened = React.useRef(false);
  const addDevices = window.location.href.includes("adddevices");

  const handleCloseError = () => dispatch(closeGeneralError());

  const handleSuccess = () => (response) => {
    if (response.options.success === false) {
      setLoading(false);
    } else {
      setStepType(response.action);
      setStepOptions(response.options);
      setAuthId(response.temp_auth_id);
      setLoading(false);
    }
  };

  const handleFailure = (failure) => {
    setLoading(false);
    dispatch(
      openGeneralError({
        open: true,
        disableSeverity: true,
        message: "The request could not be processed",
        subtitle: "Please try again later",
        onClose: handleCloseError,
      }),
    );
  };

  const handleSelectDevice = (device) => {
    setLoading((prev) => true);
    setService(device.service);
    setProvider(device.provider);
    dispatch(
      createService({
        service: device.service,
        provider: device.provider,
        auth_id: authId,
        onSuccess: handleSuccess(0),
        onError: handleFailure,
      }),
    );
  };

  const handleSelectPms = (pms) => {
    setStepType("input_pms_credentials");
    setService(pms.integration_type);
  };

  const handleNextStep = (step_options = null) => {
    if (!isPolling) {
      setLoading((prev) => true);
    }
    dispatch(
      createService({
        service,
        provider,
        auth_id: authId,
        options: step_options,
        onSuccess: handleSuccess(),
        onError: handleFailure,
      }),
    );
  };

  const resetProcess = () => {
    setStepType("select_devices");
    setService(null);
    setProvider(null);
    setStepOptions(null);
    setAuthId(null);
    windowOpened.current = false;
  };

  const goBack = () => {
    if (stepType === "input_pms_credentials") {
      setStepType("select_devices");
    } else {
      history.goBack();
    }
  };

  const handleRedirectSignup = (integration_spec) => {
    setStepType("loading");
    dispatch(
      signUpUrl(
        {integration_type: integration_spec.integration_type},
        (response) => (window.location.href = response.redirect_url),
        () => setStepType("error_connecting"),
      ),
    );
  };

  const getStep = (step_type) => {
    switch (step_type) {
      case "input_pms_credentials":
        let spec = channelIntegrationTypeMap[service];
        return (
          <div className={classes.paddedContainer}>
            {spec.signup_type === "redirect" ? (
              handleRedirectSignup({...spec, integration_type: service})
            ) : (
              <ChannelRegisterForm
                integration={service}
                redirect_path="admin"
              />
            )}
          </div>
        );
      case "select_devices":
        return (
          <IntegrationSelectionPanel
            selectDevice={handleSelectDevice}
            {...(addDevices ? {} : {selectPMS: handleSelectPms})}
          />
        );
      case "input_credentials":
        return (
          <LoginDevicePanel nextStep={handleNextStep} options={stepOptions} />
        );
      case "redirect_polling":
        if (!stepOptions) {
          return null;
        }

        if (!windowOpened.current) {
          windowOpened.current = true;
          window.open(stepOptions.redirect_url);
        }

        return <Polling handleNextStep={handleNextStep} />;
      case "callback":
        // do redux stuff to prepare for callback
        return null;
      case "polling":
        return <Polling handleNextStep={handleNextStep} />;
      case "interphone_options":
        return (
          <AddInterphone nextStep={handleNextStep} phone_number={authId} />
        );
      case null:
        return (
          <ProcessComplete
            redirect={() => history.goBack()}
            reset={resetProcess}
            history={history}
          />
        );
    }
  };

  return (
    <FullSizeCard goBack={goBack}>
      <CustomCardHeader
        title="Connect Account"
        className={classes.cardHeader}
      />
      <CardContent className={classes.content}>
        <div
          className={clsx(classes.container, {[classes.centered]: centered})}
        >
          {!!loading && <CircularProgress />}
          {!loading && getStep(stepType)}
        </div>
      </CardContent>
    </FullSizeCard>
  );
}
