import {API} from "aws-amplify";

// -------- MESSAGES ---------------
export const getMessages = async (params) => {
  try {
    const response = await API.get("ensoAPI", `/messages?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const postMessage = async (data) => {
  try {
    const response = await API.post("ensoAPI", "/messages", data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const patchMessage = async (body) => {
  try {
    const response = await API.patch("ensoAPI", `/messages`, body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const postTemplatesPreview = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/templates/preview", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getChatOptions = async (guestId) => {
  try {
    const response = await API.get("ensoAPI", `/shortcuts/${guestId}`);
    return response;
  } catch (err) {
    throw err;
  }
};
