import React from "react";
import {MenuItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import FilledSelect from "core/selects/FilledSelect";

const useStyles = makeStyles((theme) => ({
  selectorContainer: {
    width: "100%",
    height: "100%",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  icon: {
    width: 18,
    height: 18,
    objectFit: "contain",
    margin: 4,
    marginLeft: 0,
  },
}));

export default function GuestDetailDropdown({
  options = [],
  edit,
  value,
  onValueChange,
}) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(value);

  React.useEffect(() => {
    if (edit && value !== selected) {
      setSelected((prev) => value);
    }
  }, [value]);

  React.useEffect(() => {
    if (edit) {
      onValueChange(selected);
    }
  }, [selected]);

  const handleChange = (e) => {
    const val = e.target.value;
    setSelected((prev) => val);
  };
  if (edit) {
    return (
      <div className={classes.selectorContainer}>
        <FilledSelect
          fullWidth
          label="Option"
          value={selected}
          onChange={handleChange}
        >
          <MenuItem value="" disabled>
            Select an option
          </MenuItem>
          {Object.keys(options).map((op) => (
            <MenuItem value={options[op]} key={op}>
              {options[op]}
            </MenuItem>
          ))}
        </FilledSelect>
      </div>
    );
  } else {
    return <div className={classes.iconContainer}>{options}</div>;
  }
}
