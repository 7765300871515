import BaseFieldComponent from "../BaseFieldComponent";
import {format, isValid} from "date-fns";

/**
 * I have a sneaking suspicion I will be adding more to this later, keepin it simple for now.
 */
class CheckDatesField extends BaseFieldComponent {
  renderText({value}) {
    return isValid(value) ? format(value, "PP") : "not specified";
  }
}

export default CheckDatesField;
