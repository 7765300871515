import React, {useState} from "react";
// UI
import {
  Typography,
  Select,
  Button,
  Input,
  MenuItem,
  TextField,
  Collapse,
} from "@material-ui/core";
import {useDispatch} from "react-redux";

// Custom Components
import ListingList from "components/Lists/ListingList";

export default function AddInterphone({phone_number, nextStep}) {
  const [voice_message, SetVoiceMessage] = useState(
    "Welcome! Please open the door once the buzzer rings",
  );
  const [interphone_code, setInterphoneCode] = useState("");
  const [interphone_number, setInterphoneNumber] = useState("");
  const [listing_ids, setListingIds] = useState([]);
  const [step, setStep] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const handleHouseSelect = (newSelection) => {
    if (Array.isArray(newSelection)) {
      if (!!newSelection.length) {
        setListingIds((prev) => newSelection.map((h) => h.listing_id));
        setDisabled(false);
      } else {
        setListingIds((prev) => []);
        setDisabled(false);
      }
    } else {
      setListingIds((prev) =>
        prev.includes(newSelection.listing_id)
          ? prev.filter((s) => s !== newSelection.listing_id)
          : [...prev, newSelection.listing_id],
      );
    }
  };

  const handleNextStep = () => {
    if (step === 3) {
      nextStep({
        listing_ids: listing_ids,
        interphone_number: interphone_number,
        phone_code: interphone_code,
        voice_message: voice_message,
      });
    } else {
      setStep(step + 1);
    }
  };

  return (
    <div>
      <Typography variant="h1">
        Connect your interphone to your building's listing(s)
      </Typography>
      <Typography>{listing_ids.length} Listings Connected</Typography>
      <Collapse in={step === 0}>
        <div style={{height: "600px", overflow: "hidden"}}>
          <ListingList
            multiselect
            disableGutters
            selectHouse={handleHouseSelect}
            selected={listing_ids}
            customActions={false}
          />
        </div>
      </Collapse>

      <Collapse in={step > 0}>
        <Typography className="mt-3" variant="h1">
          What is your interphone's number?
        </Typography>
        <Input
          type="number"
          value={interphone_number}
          onChange={(e) => setInterphoneNumber(e.target.value)}
        />
        {step == 1 && (
          <Button
            onClick={() => setStep(step + 1)}
            className="ml-3"
            variant="outlined"
          >
            I don't know
          </Button>
        )}
      </Collapse>

      <Collapse in={step > 1}>
        <Typography className="mt-3" variant="h1">
          Set up your interphone
        </Typography>
        <Typography>
          Please pass this number on to your building's manager to have this set
          up. Please note that some interphones require phone numbers in local
          area codes
        </Typography>
        <Typography variant="h2">{phone_number}</Typography>
      </Collapse>

      <Collapse in={step > 2}>
        <Typography className="mt-3" variant="h1">
          How should we respond to the guests's call?
        </Typography>
        <Typography>Enter code to dial</Typography>
        <TextField
          value={interphone_code}
          onChange={(e) => setInterphoneCode(parseInt(e.target.value))}
        />
        <Typography>Enter an voice message to say to your guest</Typography>
        <TextField
          fullWidth
          value={voice_message}
          onChange={(e) => SetVoiceMessage(e.target.value)}
        />
      </Collapse>

      <Button
        color="primary"
        variant="contained"
        className="mt-3"
        disabled={disabled}
        onClick={() => handleNextStep()}
      >
        {step == 3 ? "Create Interphone" : "Next"}
      </Button>
    </div>
  );
}
