import React, {useLayoutEffect, useRef} from "react";

const isBrowser = typeof window !== `undefined`;
function getScrollPosition({target}) {
  if (!isBrowser) return {x: 0, y: 0, width: 0, height: 0};
  return {
    x: target.scrollLeft,
    y: target.scrollTop,
    width: target.scrollWidth,
    height: target.scrollHeight,
  };
}

// Scroll position
export default function useScrollPosition(effect, deps, element, wait) {
  const target = element.current ?? document.body;
  const position = useRef(getScrollPosition({target}));
  let throttleTimeout = null;

  const callBack = () => {
    const currPos = getScrollPosition({target});
    effect({prevPos: position.current, currPos});
    position.current = currPos;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };
    target.addEventListener("scroll", handleScroll);
    return () => target.removeEventListener("scroll", handleScroll);
  }, deps);
}
