import React, {useState, Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {makeStyles} from "@material-ui/core/styles";
import {
  ListItem,
  ListItemText,
  Collapse,
  List,
  Typography,
  Paper,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {Col, Row} from "reactstrap";
// Custom
import CustomCardHeader from "core/cards/CustomCardHeader";
import FilledTextField from "core/inputs/FilledTextField";
import ConnectedToButton from "core/buttons/ConnectedToButton";
import ListingContentList from "../../Lists/ListingContentList";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import BackButton from "core/buttons/BackButton";
import EditIconButton from "core/buttons/EditIconButton";
import CloseIconButton from "core/buttons/CloseIconButton";
import CustomDialog from "core/dialogs/CustomDialog";
import UpdateKnowledgeInput from "core/inputs/UpdateKnowledgeInput";
// Actions
import {
  deleteListingResource,
  editHouse,
  saveListingResource,
  updateListingResource,
} from "redux/actions/listingsActions";
// Utils
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: theme.spacing(4),
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.primary.main + "0D"}`,
  },
  list: {
    display: "flex",
    flexDirection: "column",
  },
  buttonRow: {
    marginTop: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(3),
  },
  infoText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  listItem: {
    borderRadius: 10,
    border: "1px solid #F5F5F5",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    "& > div": {margin: 0},
    "&.open": {
      borderBottom: "none",
      marginBottom: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  paper: {
    border: "1px solid #F5F5F5",
    borderTop: "none",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    padding: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  sentence: {
    backgroundColor: "rgba(67, 84, 96, 0.0392157)",
    borderRadius: 5,
    padding: theme.spacing(3),
    margin: theme.spacing(2, 0),
    width: "100%",
    whiteSpace: "pre-wrap",
  },
}));

export default function KnowledgePanel({
  listing,
  outsideView,
  isParentLoading,
  onClose,
  disableEdit,
  goBack,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loadingContent = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_content;
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [contentUrl, setContentUrl] = useState(null);
  const [selectedReply, setSelectedReply] = useState({});
  const [knowledge, setKnowledge] = useState({});
  const [open, setOpen] = useState(null);
  const listingContent = listing?.listing_content || {};
  const [editQuestionType, setEditQuestionType] = useState(false);
  const [newKnowledgeText, setNewKnowledgeText] = useState("");
  const [listingsOpen, setListingsOpen] = useState(false);

  React.useEffect(() => {
    if (openDialog) {
      return;
    }
    setKnowledge((prev) => listingContent.knowledge || {});
  }, [openDialog, listing]);

  const closeDialog = () => {
    setOpenDialog(false);
  };

  function setKnowledgeQuestionType(q_type, replies) {
    setKnowledge((prev) => ({
      ...prev,
      [q_type]: replies,
    }));
  }

  const getUpdatedListing = (reply, type) => {
    const newKnowledge = {...listing?.listing_content?.knowledge};
    let q_type = reply.properties.question_type;
    switch (type) {
      case "add":
        newKnowledge[q_type] = [...(newKnowledge?.[q_type] || []), reply];
        setKnowledgeQuestionType(q_type, [
          ...(knowledge?.[q_type] || []),
          reply,
        ]);
        break;
      case "update":
        let newValues = [...(knowledge?.[q_type] || [])].map((val) =>
          val.resource_id === reply.resource_id ? reply : val,
        );
        newKnowledge[q_type] = newKnowledge[q_type].map((r) =>
          r.resource_id === reply.resource_id ? reply : r,
        );
        setKnowledgeQuestionType(q_type, newValues);
        break;
      case "delete":
        if (newKnowledge[q_type]?.length > 1) {
          newKnowledge[q_type] = newKnowledge[q_type].filter(
            (r) => r.resource_id !== reply.resource_id,
          );
          setKnowledge((prev) => ({
            ...prev,
            [q_type]: prev[q_type].filter(
              (item) => item.resource_id !== reply.resource_id,
            ),
          }));
        } else {
          delete newKnowledge[q_type];
          setKnowledge((prev) => {
            let updatedKnowledge = {...prev};
            delete updatedKnowledge[q_type];
            return updatedKnowledge;
          });
        }
        break;
    }

    return {
      ...listing,
      listing_content: {...listing.listing_content, knowledge: newKnowledge},
    };
  };

  const saveReply = () => {
    let newListing = getUpdatedListing(
      selectedReply,
      !!selectedReply.resource_id ? "update" : "add",
    );
    if (selectedReply.resource_id) {
      dispatch(
        updateListingResource({
          body: selectedReply,
          listing: newListing,
          onSuccess: (r) => {
            dispatch(editHouse(getUpdatedListing(r, "update")));
          },
        }),
      );
    } else {
      selectedReply.resource = "rp";
      dispatch(
        saveListingResource({
          body: selectedReply,
          type: "reply",
          listing: newListing,
          onSuccess: (r) => {
            dispatch(editHouse(getUpdatedListing(r, "add")));
          },
        }),
      );
    }
    setOpenDialog(false);
  };

  const deleteReply = () => {
    let newListing = getUpdatedListing(selectedReply, "delete");
    dispatch(
      deleteListingResource({
        resource: selectedReply.resource,
        resource_id: selectedReply.resource_id,
        type: "reply",
        listing: newListing,
      }),
    );
    setDeleteModalOpen(false);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleEditSentence = (e) => {
    setSelectedReply({
      ...selectedReply,
      properties: {
        ...selectedReply.properties,
        replies: [[{language: "en", value: e.target.value}]],
      },
    });
  };

  function selectSentence(sent, q_type) {
    setSelectedReply(
      sent || {
        properties: {
          question_type: q_type,
          replies: [{language: "en", value: ""}],
        },
      },
    );
    setOpenDialog(true);
    setEditQuestionType(false);
  }

  function getDialogContent() {
    return (
      <>
        <Row style={{height: listingsOpen ? "100%" : "fit-content"}}>
          <Col xs={12}>
            <ConnectedToButton
              listing={listing}
              listing_id={listing?.listing_id}
              group_id={listing?.group_id}
              data={selectedReply}
              editData={setSelectedReply}
              listingsOpen={listingsOpen}
              setListingsOpen={setListingsOpen}
            />
          </Col>
        </Row>
        {!listingsOpen && (
          <Row className="mt-3">
            <Col xs={12}>
              <FilledTextField
                id="name"
                fullWidth
                multiline
                label="Answer"
                value={
                  selectedReply?.properties?.replies?.[0]?.[0]?.value || ""
                }
                placeholder={`Enter an answer to a question about ${selectedReply?.properties?.question_type}`}
                onChange={handleEditSentence}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }

  const additionalInformationDialog = (
    <CustomDialog
      open={openDialog}
      labelConfirm="Save"
      labelCancel="Cancel"
      titleVariant="header"
      title={
        !!editQuestionType
          ? "New Saved Reply"
          : `Saved Reply - ${selectedReply?.properties?.question_type}`
      }
      maxWidth="md"
      fullWidth
      customHeight={listingsOpen ? "80vh" : null}
      content={getDialogContent()}
      onClose={closeDialog}
      actionCancel={closeDialog}
      actionConfirm={saveReply}
    />
  );

  const confirmDeleteModal = !!deleteModalOpen && (
    <ConfirmDialog
      open
      maxWidth="sm"
      disableDismiss
      onClose={() => setDeleteModalOpen(false)}
      title={`Delete sentence?`}
      message={"Delete this reply from the listings it's connected to"}
      confirmLabel="Delete"
      confirmAction={deleteReply}
      cancelLabel="Cancel"
      cancelAction={() => setDeleteModalOpen(false)}
    />
  );

  const KnowledgeText = (q_type, sentences, isOpen) => {
    let group_article = sentences.find((s) => s.connected_object == "group");
    let listing_article = sentences.find(
      (s) => s.connected_object == "listing",
    );
    let all_article = sentences.find((s) => s.connected_object == "root");

    return (
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Paper elevation={0} className={classes.paper}>
          {!sentences.length && (
            <Typography
              variant="h1"
              color="textSecondary"
              style={{padding: "0 10px 4px"}}
            >
              No sentences yet
            </Typography>
          )}
          <List>
            <Fragment key={`${q_type}-0`}>
              <ListItem disableGutters>
                <div style={{width: "100%"}}>
                  <Typography color="textSecondary">
                    Information relevant to this specific listing
                  </Typography>
                  <Typography component="pre" className={classes.sentence}>
                    {listing_article?.properties?.replies?.[0]?.[0]?.value || (
                      <i>No Content</i>
                    )}
                  </Typography>
                </div>
                {!disableEdit && (
                  <>
                    <EditIconButton
                      spaceLeft={2}
                      onClick={() => selectSentence(listing_article, q_type)}
                    />
                  </>
                )}
              </ListItem>
            </Fragment>
            <Fragment key={`${q_type}-1`}>
              <ListItem disableGutters>
                <div style={{flex: 1, width: "100%"}}>
                  <Typography color="textSecondary">
                    Information relevant to this listing's group
                  </Typography>
                  <Typography className={classes.sentence}>
                    {group_article?.properties?.replies?.[0]?.[0]?.value || (
                      <i>No Content</i>
                    )}
                  </Typography>
                </div>
                {!disableEdit && (
                  <>
                    <EditIconButton
                      spaceLeft={2}
                      onClick={() => selectSentence(group_article, q_type)}
                    />
                  </>
                )}
              </ListItem>
            </Fragment>
            <Fragment key={`${q_type}-2`}>
              <ListItem disableGutters>
                <div style={{flex: 1, width: "100%"}}>
                  <Typography color="textSecondary">
                    Information relevant to all listings
                  </Typography>
                  <Typography component="pre" className={classes.sentence}>
                    {all_article?.properties?.replies?.[0]?.[0]?.value || (
                      <i>No Content</i>
                    )}
                  </Typography>
                </div>
                {!disableEdit && (
                  <>
                    <EditIconButton
                      spaceLeft={2}
                      onClick={() => selectSentence(all_article, q_type)}
                    />
                  </>
                )}
              </ListItem>
            </Fragment>
          </List>
        </Paper>
      </Collapse>
    );
  };

  const title = (
    <CustomCardHeader
      title={
        !!goBack ? (
          <BackButton
            header={!isMobileView}
            color={!!isMobileView ? "secondary" : "primary"}
            goBack={goBack}
          />
        ) : (
          "AI Knowledge"
        )
      }
      className={classes.header}
      action={!goBack && <CloseIconButton sm onClick={onClose} />}
    />
  );

  const outsideTitleRow = (
    <ListingContentList
      listingId={listing?.listing_id}
      onlyItems={["knowledge"]}
      disableBackground
    />
  );

  return (
    <div className={classes.root}>
      {title}
      {!!outsideView && outsideTitleRow}
      {additionalInformationDialog}
      {confirmDeleteModal}
      <div className={classes.content}>
        <Typography variant="h1">Update Knowledge Base</Typography>
        <UpdateKnowledgeInput
          classes={classes}
          listing={listing}
          newKnowledgeText={newKnowledgeText}
          setNewKnowledgeText={setNewKnowledgeText}
          contentUrl={contentUrl}
          setContentUrl={setContentUrl}
        />
        <Typography variant="h1">Explore Articles</Typography>
        <Typography className={classes.infoText}>
          Articles are searchable in the unified inbox & are used by EnsoAI to
          generate content & answer guest messages.
        </Typography>
        <List className={classes.list}>
          {Object.keys(knowledge).map((q_type) => {
            let isOpen = open === q_type;
            let sentences = knowledge[q_type] || [];

            function extractAndJoinValues(sentences) {
              const joinedString = sentences
                .map(
                  (sentence) =>
                    sentence.properties?.replies?.[0]?.[0]?.value || "",
                )
                .filter((value) => value !== "") // Optional: to remove any empty strings
                .join(" ");

              // Check if the length exceeds 100 characters
              if (joinedString.length > 100) {
                return joinedString.slice(0, 100) + "...";
              }

              return joinedString;
            }

            return (
              <Fragment key={q_type}>
                <ListItem
                  button
                  cols={1}
                  key={q_type}
                  disableRipple
                  className={clsx(classes.listItem, {open: isOpen})}
                  disabled={isParentLoading || loadingContent}
                  onClick={() =>
                    setOpen((prev) => (open == q_type ? null : q_type))
                  }
                >
                  <ListItemText
                    primary={capitalizeFirstLetter(q_type)}
                    secondary={
                      isParentLoading || loadingContent
                        ? "Loading..."
                        : extractAndJoinValues(sentences)
                    }
                  />
                  {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItem>
                {KnowledgeText(q_type, sentences, isOpen)}
              </Fragment>
            );
          })}
        </List>
      </div>
    </div>
  );
}
