import React from "react";
import {useSelector} from "react-redux";
import {useLocation, useRouteMatch} from "react-router-dom";
// UI
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import LockIcon from "@material-ui/icons/Lock";
// Icons
import {ReactComponent as PinIcon} from "assets/icons/Icon_Pin.svg";
import {ReactComponent as AddressSignIcon} from "assets/icons/Icon_AddressSign.svg";
import {ReactComponent as PicturesIcon} from "assets/icons/Icon_Pictures.svg";
import {ReactComponent as ClipboardIcon} from "assets/icons/Icon_Clipboard.svg";
import {ReactComponent as ViewListIcon} from "assets/icons/Icon_ViewList.svg";
import {ReactComponent as BookOpenIcon} from "assets/icons/Icon_BookOpen.svg";
import {ReactComponent as DocIcon} from "assets/icons/Icon_Document.svg";
import {ReactComponent as UnlockIcon} from "assets/icons/Icon_Lock.svg";
// Custom
import FilledItem from "core/listItems/FilledItem";
// Utils
import clsx from "clsx";

const serviceItems = {
  info: {
    name: "General Information",
    icon: PinIcon,
    viewBox: "0 -0.2 14 19.25",
  },
  address: {name: "Address", icon: AddressSignIcon, viewBox: "0 0 20 18"},
  access: {
    name: "Check-in & Check-out",
    icon: UnlockIcon,
    viewBox: "0 -0.2 16 22.25",
  },
  //amenities: { name: 'Amenities', icon: ViewListIcon, viewBox: "0 0 16 16" },
  guidebooks: {name: "Guidebooks", icon: DocIcon, viewBox: "0 0 17 22"},
  fees: {name: "Fees", icon: DocIcon, viewBox: "0 0 17 22"},
  upsells: {name: "Upsells", icon: DocIcon, viewBox: "0 0 17 22"},
  knowledge: {name: "AI Knowledge", icon: BookOpenIcon, viewBox: "0 0 16 16"},
  policies: {
    name: "Policies & Custom Fields",
    icon: ClipboardIcon,
    viewBox: "0 -0.2 16 22.25",
  },
  pictures: {name: "Pictures", icon: PicturesIcon, viewBox: "0 0 20 18"},
  devices: {
    name: "Devices",
    icon: <LockIcon fontSize="small" />,
    disableSVG: true,
  },
};

const useStyles = makeStyles((theme) => ({
  list: {padding: theme.spacing(3, 5, 4) + "!important"},
}));

export default function ListingContentList({
  onSelect,
  listingId,
  onlyItems = [],
  disableBackground,
  gutters = null,
  actions,
  panel3,
  loading,
}) {
  const location = useLocation();
  const match = useRouteMatch("/admin/listings/:listing_id/:listing_section");
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const house = React.useMemo(
    () => houses[listingId] ?? {},
    [houses, listingId],
  );
  const [selected, setSelected] = React.useState(null);
  const classes = useStyles({gutters});

  React.useEffect(() => {
    if (!!match?.isExact) {
      setSelected((prev) => match.params.listing_section);
    } else if (!panel3) {
      setSelected((prev) => null);
    }
  }, [location]);

  const previewInfo = React.useMemo(() => {
    const allowedPets =
      house.listing_content?.policy?.pet_policy?.allowed_pets === "allowed";
    const allowedSmoking = !!house.listing_content?.policy?.smoking_allowed;
    const allowedChildren = !!house.listing_content?.policy?.children_allowed;
    const allowedArr = [];
    if (allowedPets) {
      allowedArr.push("pets, ");
    }
    if (allowedSmoking) {
      allowedArr.push("smoking, ");
    }
    if (allowedChildren) {
      allowedArr.push("children, ");
    }

    return {
      info: `Description, wifi & more`,
      address: house.address?.formatted ?? "",
      pictures: `${house.listing_content?.pictures?.length || 0} Photos`,
      amenities: `${house.listing_content?.amenities?.filter((a) => !!a.enabled).length || 0} amenities`,
      knowledge: "Answers to common questions",
      access: "Step by step check-in/check-out instructions & times",
      policies: "House Rules & Custom Fields",
      fees: "Pet fees, deposits & more",
      upsells: "Early check-in/late check-out & more",
      guidebooks: "Custom information sections",
      devices: "Manage smart home devices",
    };
  }, [house]);

  const handleItemSelect = (key) => () => {
    !disableBackground && setSelected((prev) => key);
    !!onSelect && onSelect(key);
  };

  return (
    <div style={{zIndex: 2}}>
      <List
        component="nav"
        className={clsx({[classes.list]: !disableBackground})}
      >
        {Object.keys(serviceItems).map((key) => {
          if (!!onlyItems.length && !onlyItems.includes(key)) {
            return null;
          } else {
            return (
              <FilledItem
                key={key}
                disabled={loading}
                selected={selected === key}
                hideArrow={disableBackground}
                disableBackground={disableBackground}
                title={serviceItems[key].name}
                text={previewInfo[key]}
                actions={actions}
                onClick={handleItemSelect(key)}
                icon={
                  serviceItems[key].disableSVG ? (
                    serviceItems[key].icon
                  ) : (
                    <SvgIcon
                      component={serviceItems[key].icon}
                      fontSize="small"
                      viewBox={serviceItems[key].viewBox}
                    />
                  )
                }
              />
            );
          }
        })}
      </List>
    </div>
  );
}
