import * as API from "../api/accountsAPI";
import * as servicesAPI from "../api/servicesAPI";
import * as types from "../actionTypes";
import {
  closeGeneralSuccessAlert,
  errorResponseAlert,
  openGeneralSuccessAlert,
  setApiError,
  setApiStart,
  setApiSuccess,
} from "./settingsActions";

export function setServiceAccounts(accounts) {
  return {type: types.SET_SERVICE_ACCOUNTS, accounts};
}

export function getSubscriptionDetails(
  subsID,
  onSuccess = () => {},
  onError = () => {},
) {
  return async (dispatch) => {
    try {
      dispatch(setApiStart("subscription"));
      const response = await API.getSubscription(subsID);
      console.log(`GOT SUBSCRIPTION DETAILS`, response);
      dispatch(setApiSuccess("subscription"));
      onSuccess(response);
    } catch (err) {
      dispatch(setApiError("subscription"));
      onError();
    }
  };
}

export function createService({
  verifier,
  service,
  provider,
  auth_id,
  group_id,
  options,
  onSuccess = () => null,
  onFailure = () => null,
}) {
  return async (dispatch, getState) => {
    const current_user = getState().defaultReducer.current_user;
    const newBody = {
      enso_key: current_user,
      data: {
        service,
        verifier,
        provider,
        temp_auth_id: auth_id,
        group_id,
        options: options,
      },
    };
    dispatch(setApiStart("service"));
    try {
      const response = await API.postAccount({body: newBody});
      console.log(`GOT NEW SERVICE`, response);
      if (!!response.error) {
        dispatch(setApiError("service", response.error));
        errorResponseAlert(
          dispatch,
          "Failed to enable service",
          "Something went wrong, please try to enable this service again later",
          response.error,
        );
      } else {
        successResponseAlert(dispatch, "Service enabled");
        onSuccess(response);
        dispatch(setApiSuccess("service"));
      }
    } catch (error) {
      console.log("ERROR - GET NEW SERVICE", error);
      errorResponseAlert(
        dispatch,
        "Failed to enable service",
        "Something went wrong, please try to enable this service again later",
        error,
      );
      onFailure();
      dispatch(setApiError("service"));
    }
  };
}

export function updateService({
  service_id,
  provider,
  options,
  onSuccess = () => null,
  onFailure = () => null,
}) {
  return async (dispatch, getState) => {
    console.log("GOT SERVICE ID", service_id);
    const serviceAccounts = getState().defaultReducer.service_accounts;
    const newBody = {
      service_id: service_id,
      options: options,
    };
    dispatch(setApiStart("service"));
    try {
      const response = await API.patchAccount({body: newBody});
      console.log(`GOT NEW SERVICE`, response);
      if (!!response.error) {
        dispatch(setApiError("service", response.error));
        errorResponseAlert(
          dispatch,
          "Failed to update the service",
          "Something went wrong, please try to update this service again later",
          response.error,
        );
      } else {
        if (!!provider && !!response.service_account) {
          dispatch(
            setServiceAccounts({
              ...serviceAccounts,
              accounts: {
                ...serviceAccounts.accounts,
                [provider]: [response.service_account],
              },
            }),
          );
        }
        onSuccess(response);
        dispatch(setApiSuccess("service"));
        successResponseAlert(dispatch, "Service enabled");
      }
    } catch (error) {
      console.log("ERROR - GET NEW SERVICE", error);
      onFailure();
      dispatch(setApiError("service"));
      errorResponseAlert(
        dispatch,
        "Failed to update service",
        "Something went wrong, please try to update this service again later",
        error,
      );
    }
  };
}

export function getServiceAccounts({
  stripeOnly = false,
  onSuccess = () => {},
  onError = () => {},
}) {
  return async (dispatch) => {
    try {
      dispatch(setApiStart("service_accounts"));
      const response = await servicesAPI.getServices(stripeOnly);
      console.log("GOT SERVICE ACCOUNTS", response);
      dispatch(setServiceAccounts(response));
      dispatch(setApiSuccess("service_accounts"));
      onSuccess(response);
    } catch (error) {
      console.log("ERROR - GET SERVICE ACCOUNTS");
      dispatch(setApiError("service_accounts"));
      onError();
    }
  };
}

export function updateViatorService({enable, create, onError = () => {}}) {
  return async (dispatch, getState) => {
    const serviceAccounts = getState().defaultReducer.service_accounts;
    try {
      dispatch(setApiStart("viator_service"));
      const response = !!create
        ? await servicesAPI.postServiceViator()
        : await servicesAPI.patchServiceViator({enabled: enable});
      if (response?.success) {
        dispatch(setApiSuccess("viator_service"));
        successResponseAlert(
          dispatch,
          `Service ${enable ? "enabled" : "disabled"}`,
        );
        dispatch(
          setServiceAccounts({
            ...serviceAccounts,
            accounts: {
              ...serviceAccounts.accounts,
              viator: [response.service_account],
            },
          }),
        );
      } else {
        dispatch(setApiError("viator_service"));
        errorResponseAlert(
          dispatch,
          `Failed to ${enable ? "enable" : "disable"} service`,
          "Something went wrong, please try to update this service again later",
        );
        onError();
      }
    } catch (error) {
      console.log("ERROR ENABLING VIATOR SERVICE", error);
      dispatch(setApiError("viator_service"));
      errorResponseAlert(
        dispatch,
        `Failed to ${enable ? "enable" : "disable"} service`,
        "Something went wrong, please try to update this service again later",
        error,
      );
      onError();
    }
  };
}

// HELPERS
function handleSuccessAlertClose(dispatch) {
  dispatch(closeGeneralSuccessAlert());
}

function successResponseAlert(dispatch, message = "Success!", subtitle = null) {
  dispatch(
    openGeneralSuccessAlert({
      message,
      subtitle,
      open: true,
      onClose: () => handleSuccessAlertClose(dispatch),
    }),
  );
}
