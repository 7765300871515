import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {makeStyles} from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/WarningRounded";
// Custom
import VirtualizedList from "./VirtualizedList";
import ExpandableItem from "core/listItems/ExpandableItem";
// Actions
import {getHouseContent} from "redux/actions/listingsActions";
// Utils
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  listContainer: {flex: 1},
  warningIcon: {color: "#EE4F4F"},
  contentContainer: {
    borderRadius: 10,
    backgroundColor: "rgba(255,255,255,0.85)",
    padding: theme.spacing(4),
  },
}));

export default function CollapsibleListingsList({
  content,
  selectedListing,
  allListings = [],
  containerClass,
  getDescription = () => null,
  onItemClick,
  refreshDependency = null,
  rightArrow,
  connectedListings = [],
  loadingItems,
  cacheClearDependency,
  fixedRowHeight = "auto",
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currIndexRef = React.useRef(null);
  const prevIndexRef = React.useRef(null);
  const listRef = React.useRef(null);
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const [cacheClearIndexes, setCacheClearIndexes] = React.useState([]);

  React.useEffect(() => {
    let timer = null;
    let getContentFn = null;
    if (!!selectedListing?.listing_id) {
      let newArr = [currIndexRef.current.index];
      currIndexRef.current.onLoad();
      if (!!prevIndexRef.current) {
        newArr.push(prevIndexRef.current.index);
      }
      if (!!currIndexRef.current.getHouseContent) {
        getContentFn = currIndexRef.current.getHouseContent;
      }
      setCacheClearIndexes((prev) => newArr);
    } else if (!!currIndexRef.current) {
      currIndexRef.current.onLoad();
      let currIndex = currIndexRef.current.index;
      if (!!currIndexRef.current.getHouseContent) {
        getContentFn = currIndexRef.current.getHouseContent;
      }
      setCacheClearIndexes((prev) => [currIndex]);
      currIndexRef.current = null;
    }
    if (!!listRef.current) {
      listRef.current.recomputeRowHeights();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [selectedListing]);

  React.useEffect(() => {
    if (refreshDependency === null) {
      return;
    }
    if (!!listRef.current) {
      listRef.current.recomputeRowHeights();
    }
    if (!!currIndexRef.current?.onLoad) {
      currIndexRef.current.onLoad();
    }
  }, [refreshDependency]);

  const handleItemClick = (index, item, onLoad) => {
    prevIndexRef.current = currIndexRef.current;
    currIndexRef.current = {index, onLoad};
    const house = houses[item.listing_id];
    if (!house?.listing_content) {
      dispatch(getHouseContent(item.listing_id));
    }
    onItemClick(item);
  };

  const getRowItem = ({index, onLoad = () => null}) => {
    let li = loadingItems
      ? {index: index, listing_id: connectedListings[index], hideIcon: true}
      : allListings[index];
    let isSelected = selectedListing?.listing_id === li.listing_id;
    return (
      <>
        <ExpandableItem
          disabled={loadingItems}
          rightArrow={rightArrow}
          open={selectedListing?.listing_id === li.listing_id}
          title={li.name === null ? "" : (li.name ?? "Loading listing...")}
          description={getDescription(li)}
          descIcon={
            !!li.hideIcon ? null : (
              <WarningIcon className={classes.warningIcon} />
            )
          }
          onClick={() => handleItemClick(index, li, onLoad)}
        />
        {isSelected && (
          <div className={classes.contentContainer}>{content}</div>
        )}
      </>
    );
  };

  return (
    <div className={clsx(classes.container, containerClass)}>
      <div className={classes.listContainer}>
        <VirtualizedList
          hideScrollbar
          listRef={listRef}
          cacheClearDependency={cacheClearDependency}
          cacheClearIndexes={cacheClearIndexes}
          getRowItem={getRowItem}
          rowHeight={fixedRowHeight}
          totalRows={
            loadingItems ? connectedListings.length : allListings.length
          }
        />
      </div>
    </div>
  );
}
