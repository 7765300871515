import React, {useState, Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
// UI
import {
  Tooltip,
  Typography,
  SvgIcon,
  Box,
  Button,
  Menu,
  MenuItem,
  Link,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import PersonIcon from "@material-ui/icons/Person";
import {ReactComponent as airbnbLogo} from "assets/icons/airbnb_white.svg";
import {ReactComponent as vrboLogo} from "assets/icons/vrbo_white.svg";
import useHouseDashOverviewStyles from "styles/useHouseDashOverviewStyles";
// Custom
import ListingActive from "components/TextFields/ListingActive";
import TagList from "components/Lists/TagList";
import {EmptyHouseOverview} from "components/Helpers/EmptyPanels";
import ListingGroupSelector from "../../MultiOption/ListingGroupSelector";
import CustomLink from "core/buttons/CustomLink";
// Actions
import {getCustomFields} from "redux/actions/settingsActions";
import {updateListing} from "redux/actions/listingsActions";
import {updateService} from "redux/actions/servicesActions";
// Utils
import {THEME} from "configuration/settings";
import clsx from "clsx";
import SuccessSnackbar from "core/snackbars/SuccessSnackbar";
import WarningAlert from "core/alerts/WarningAlert";

function ListingError({err, index, house}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const err_type = err.properties?.err_type;
  let action = null;
  let label = "";

  if (err_type === "LINKING_FAILED" || err_type === "UNLINKING_FAILED") {
    label = "Retry";
    action = () => {
      setLoading(true);
      dispatch(
        updateService({
          service_id: err.properties.obj_id.slice(2),
          options: {verifier: "AUTOHOST_PMS_ACCOUNT"},
          onSuccess: () => {
            dispatch(setLoading(false));
          },
        }),
      );
    };
  }

  return (
    <WarningAlert
      key={`listing_error_${index}`}
      disableMargin
      action={action}
      actionButtonLabel={
        loading ? <CircularProgress color="inherit" size="30" /> : label
      }
      title={err.title}
      subtitle={err.message}
      severity={err.severity}
    />
  );
}

export default function ListingOverviewPanel({
  listingId,
  loading,
  disableEdit,
  cardHeader,
  status,
  showAirbnbLink,
  openListingDetails,
  channel,
  onClose,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const house = React.useMemo(
    () => houses[listingId] ?? {},
    [houses, listingId],
  );
  const [tags, setTags] = useState([]);
  const [disableSwitch, setDisableSwitch] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tagSuggestions, setTagSuggestions] = useState([]);
  const houseLink = `https://guest.ensoconnect.com/${house.listing_id}`;
  const airbnb_link =
    house?.connected_channels?.[0]?.integration_type === "ab"
      ? `https://airbnb.com/rooms/${house.connected_channels[0].integration_listing_id}`
      : null;
  const classes = useHouseDashOverviewStyles({
    picture: house.picture,
    disabled: !house.active,
  });
  let isListingView = location?.pathname?.includes("/admin/listings/");

  const icons = {
    vrbo: (
      <SvgIcon
        viewBox="0 0 27 22"
        className={classes.icon}
        style={{width: 23}}
        component={vrboLogo}
      />
    ),
    ab: (
      <SvgIcon
        viewBox="0 0 21 22"
        className={classes.icon}
        component={airbnbLogo}
      />
    ),
  };

  React.useEffect(() => {
    setTags((prev) => house.tags ?? []);
  }, [house]);

  React.useEffect(() => {
    if (!listingId) {
      return;
    }
    dispatch(
      getCustomFields({
        onSuccess: (response) => {
          let listingTags =
            response?.Listing?.find((d) => d.field === "listing_tags") ?? {};
          setTagSuggestions((prev) => listingTags.data ?? []);
        },
      }),
    );
  }, [listingId]);

  const copyListingId = (listingId) => () => {
    navigator.clipboard.writeText(listingId);
    setOpenAlert((prev) => true);
  };

  const onAlertClose = () => setOpenAlert((prev) => false);

  const handleTagsChange = (newTags) => {
    dispatch(
      updateListing({
        body: {tags: newTags},
        listing: house,
        onSuccess: onSaveSuccess,
        onError: onSaveError,
      }),
    );
    setTags(newTags);
  };

  const onListingActiveSave = (newValue) => {
    setDisableSwitch((prev) => true);
    dispatch(
      updateListing({
        body: {active: newValue},
        listing: house,
        onSuccess: onSaveSuccess,
        onError: onSaveError,
      }),
    );
  };

  const onSaveSuccess = () => setDisableSwitch((prev) => false);
  const onSaveError = () => setDisableSwitch((prev) => false);

  function getHouseIntegrationData() {
    const integrationKeys = Object.keys(icons);
    return (
      <>
        {house.connected_channels?.map(
          (el) =>
            integrationKeys.includes(el.integration_type) && (
              <Fragment key={el.integration_listing_id}>
                <Tooltip title={el.integration_listing_id} interactive>
                  <button
                    className={classes.button}
                    onClick={copyListingId(el.integration_listing_id)}
                  >
                    {icons[el.integration_type]}
                  </button>
                </Tooltip>
              </Fragment>
            ),
        )}
      </>
    );
  }

  const linkMenu = (
    <>
      {!!showAirbnbLink && (
        <Menu
          id="booking-links-menu"
          disablePortal
          anchorOrigin={{vertical: "bottom", horizontal: "left"}}
          transformOrigin={{vertical: "top", horizontal: "left"}}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem>
            <Link target="_blank" rel="noopener noreferrer" href={airbnb_link}>
              Airbnb Listing
            </Link>
          </MenuItem>
        </Menu>
      )}
    </>
  );

  if (loading) {
    return <EmptyHouseOverview loading />;
  }
  return (
    <>
      <div
        className={clsx(classes.container, {
          "-card-header": !!cardHeader,
          "-switch-row": !cardHeader,
        })}
      >
        {linkMenu}
        <SuccessSnackbar
          open={openAlert}
          onClose={onAlertClose}
          label="Copied listing ID to clipboard!"
        />
        {!cardHeader && (
          <div className={classes.switchRow}>
            <ListingActive
              onClose={onClose}
              listing={house}
              disableEdit={disableEdit || disableSwitch}
              onValueChange={onListingActiveSave}
            />
          </div>
        )}
        <div className={classes.listingInfoRow}>
          <Box
            mb={3}
            mt={!!cardHeader ? 4 : 0}
            sx={{display: "inline-flex", justifyContent: "space-between"}}
          >
            <ListingGroupSelector
              house={house}
              disabled={disableEdit}
              isListingView={isListingView}
            />
            {!!cardHeader && channel && (
              <Typography className={classes.address}>{channel}</Typography>
            )}
          </Box>
          {!!house.nickname && (
            <Button
              className={clsx(classes.redirectButton, {
                "-disabled": !cardHeader,
              })}
              classes={{label: classes.redirectButtonLabel}}
              onClick={() => (cardHeader ? openListingDetails() : null)}
            >
              <Typography
                variant="h2"
                className={clsx(classes.title, {"-cut": !!cardHeader})}
              >
                {house.nickname}
              </Typography>
            </Button>
          )}
          {(!house.nickname || (!!house.nickname && !cardHeader)) && (
            <Button
              className={clsx(classes.redirectButton, {
                "-disabled": !cardHeader,
              })}
              classes={{label: classes.redirectButtonLabel}}
              onClick={() => (cardHeader ? openListingDetails() : null)}
            >
              <Typography
                align="left"
                variant={!!house.nickname ? "h1" : "h2"}
                className={
                  !!house.nickname
                    ? classes.subtitle
                    : clsx(classes.title, {"-cut": !!cardHeader})
                }
              >
                {house.name}
              </Typography>
            </Button>
          )}
          <Box mt={2}>
            <Typography className={classes.address}>
              {house.address?.formatted ?? ""}
            </Typography>
          </Box>
          {!cardHeader && (
            <CustomLink light label="Preview Boarding Pass" link={houseLink} />
          )}
          {!cardHeader && (
            <div className={classes.row} style={{marginTop: THEME.spacing.md}}>
              <TagList
                objectId={listingId}
                light
                tags={tags}
                suggestions={tagSuggestions}
                onChange={handleTagsChange}
              />
            </div>
          )}
          {!!cardHeader && !!showAirbnbLink && !!airbnb_link && (
            <IconButton
              className={classes.link}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <LinkIcon htmlColor="#FFF" />
            </IconButton>
          )}
          {!cardHeader && (
            <div
              className={clsx(classes.row, "-center")}
              style={{marginTop: THEME.spacing.md}}
            >
              <div className={clsx(classes.row, "-center")}>
                <PersonIcon className={classes.infoIcon} />
                <Typography className={classes.dataBold} align="right">
                  {house.max_guests?.persons ?? 0}
                </Typography>
              </div>
              <div className={classes.integrations}>
                {getHouseIntegrationData()}
              </div>
            </div>
          )}
        </div>
      </div>
      {cardHeader && (
        <div className={classes.statusRow}>
          <Typography variant="subtitle1" className={classes.statusLabel}>
            {status.split("_").join(" ")}
          </Typography>
        </div>
      )}
      {!!house?.errors?.length && (
        <div className={classes.errorContainer}>
          {house?.errors.map((err, index) => (
            <ListingError {...{err, index, house}} />
          ))}
        </div>
      )}
    </>
  );
}
