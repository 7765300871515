import {combineReducers} from "redux";
import defaultReducer from "./defaultReducer";
import messagesReducer from "./messagesReducer";
import crmReducer from "./crmReducer";
import experiencesReducer from "./experiencesReducer";
import dashboardReducer from "./dashboardReducer";

export default combineReducers({
  defaultReducer,
  messagesReducer,
  reportsReducer: crmReducer,
  experiencesReducer,
  dashboardReducer,
});
