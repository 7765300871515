import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {
  Collapse,
  IconButton,
  Paper,
  SvgIcon,
  TextField,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {ReactComponent as MessagesIcon} from "assets/icons/Icon_Messages.svg";
import useExperienceActionsStyles from "styles/useExperienceActionsStyles";
import TemplateInput from "components/TextFields/TemplateInput";
import {TemplateComponentsDialog} from "components/Dialogs/TemplateComponentsDialog";
import {getServiceAccounts} from "redux/actions/servicesActions";
import clsx from "clsx";

export default function WhatsappAction({
  edit,
  data,
  editData,
  disableEdit,
  disableDelete,
  hideEdit,
  variant,
  onEdit,
  onDelete,
}) {
  const styleProps = {filled: variant === "filled"};
  const dispatch = useDispatch();
  const didLoadWAAcc = useRef(false);
  const classes = useExperienceActionsStyles(styleProps);
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  const [whatsappAccountId, setWhatsappAccountId] = React.useState(
    data?.service_id,
  );
  const [templates, setTemplates] = React.useState([]);
  const [templatePreviews, setTemplatePreviews] = React.useState({empty: ""});
  const [selectedTemplateId, setSelectedTemplateId] = React.useState(
    data?.integration_template_id,
  );
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [templateMatchModalOpen, setTemplateMatchModalOpen] =
    React.useState(false);
  const [templatePairing, setTemplatePairing] = React.useState(false);

  React.useEffect(() => {
    if (
      !Object.keys(serviceAccounts?.accounts ?? {}).length ||
      !serviceAccounts?.accounts?.whatsapp
    ) {
      if (!didLoadWAAcc.current) {
        didLoadWAAcc.current = true;
        dispatch(
          getServiceAccounts({
            onSuccess: (r) => {
              handleWhatsappServiceAccount(r.accounts);
            },
          }),
        );
      } else {
        handleWhatsappServiceAccount(serviceAccounts?.accounts ?? {});
      }
    } else {
      handleWhatsappServiceAccount(serviceAccounts?.accounts ?? {});
    }
  }, [serviceAccounts]);

  React.useEffect(() => {
    editData({
      action_id: data.action_id,
      service: "msg",
      integration_template_id: selectedTemplateId,
      service_id: whatsappAccountId,
    });
  }, [selectedTemplateId, whatsappAccountId]);

  const handleWhatsappServiceAccount = (servAccnts) => {
    let account = servAccnts.whatsapp?.[0];
    if (!!account) {
      setWhatsappAccountId(account.service_id);
      setTemplates(account.properties?.templates);
    }
  };

  return (
    <>
      <Collapse in={edit} timeout={{appear: 1000, enter: 150, exit: 150}}>
        <Paper elevation={0} className={classes.paper}>
          <div className="mt-4 mb-3">
            <TemplateComponentsDialog
              template={selectedTemplate}
              serviceId={whatsappAccountId}
              open={templateMatchModalOpen}
              setOpen={setTemplateMatchModalOpen}
              setInProgress={setTemplatePairing}
              disableClose
            />

            <TemplateInput
              templates={templates}
              whatsappAccountId={whatsappAccountId}
              templatePairing={templatePairing}
              autocompleteKey={false}
              templatePreviews={templatePreviews}
              setTemplatePreviews={setTemplatePreviews}
              selectedTemplateId={selectedTemplateId}
              setSelectedTemplateId={setSelectedTemplateId}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              templateMatchModalOpen={templateMatchModalOpen}
              setTemplateMatchModalOpen={setTemplateMatchModalOpen}
              renderInput={(params) => <TextField {...params} />}
              value={
                !selectedTemplateId
                  ? {id: "empty", label: ""}
                  : {
                      id: selectedTemplateId,
                      label: templatePreviews[selectedTemplateId],
                    }
              }
            />
          </div>
        </Paper>
      </Collapse>
      <Collapse in={!edit} timeout={{appear: 1000, enter: 150, exit: 150}}>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.selectedOption}>
            <Typography
              className={clsx(
                classes.title,
                classes.flexGrow,
                classes.ellipsis,
              )}
              component="span"
            >
              <SvgIcon
                viewBox="0 0 35 35"
                className={classes.iconPreview}
                component={MessagesIcon}
              />
              <span>
                {"Send "}
                <span
                  className={clsx({
                    [classes.capitalize]: ["airbnb", "whatsapp"].includes(
                      data.action_id,
                    ),
                  })}
                >
                  {data.action_id}
                </span>
                {" message "}
              </span>
              <span className={clsx("ml-1", classes.medium)}>
                {`"${templatePreviews[selectedTemplateId]}"`}
              </span>
            </Typography>
            {!hideEdit && (
              <IconButton
                className={clsx(classes.iconBtn, {"-disabled": disableEdit})}
                onClick={onEdit}
              >
                <EditIcon className={classes.icon} />
              </IconButton>
            )}
            {!disableDelete && (
              <IconButton
                className={clsx(classes.iconBtn, {"-disabled": disableEdit})}
                onClick={onDelete}
              >
                <DeleteIcon className={classes.icon} />
              </IconButton>
            )}
          </div>
        </Paper>
      </Collapse>
    </>
  );
}
