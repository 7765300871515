import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {Box, makeStyles, Typography} from "@material-ui/core";
// Custom
import ListingConnection from "components/MultiOption/ListingConnection";
// Actions
import {getHouseContent} from "redux/actions/listingsActions";
// Utilities
import {asyncLoop} from "utilities/helperFunctions";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  listingsContainer: {
    display: "flex",
    "&.-open": {minHeight: 400},
  },
}));

export default function PairedHouses({device = {}, disabled, editData}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const [edit, setEdit] = React.useState(false);
  const [connectionData, setConnectionData] = React.useState({
    listings: [],
    groupId: null,
    connection_type: "ids",
  });

  React.useEffect(() => {
    if (!Object.keys(device).length) {
      return;
    }
    loadMissingListings();
    setConnectionData((prev) => ({
      ...prev,
      listings: device.listing_ids || [],
    }));
  }, [device]);

  const loadMissingListings = async () => {
    await asyncLoop(device.listing_ids || [], async (id) => {
      const listing = houses[id];
      if (!listing) {
        dispatch(getHouseContent(id));
      }
    });
  };

  const handleDataChange = (newData) => {
    setConnectionData((prev) => newData);
    editData(newData.listings);
  };

  return (
    <Box>
      <Box mb={3}>
        <Typography color="textSecondary" variant="h1">
          {"Connected Listings"}
        </Typography>
      </Box>
      <div className={clsx(classes.listingsContainer, {"-open": edit})}>
        <ListingConnection
          data={connectionData}
          disableConnectionTypes
          disableEdit={disabled}
          showHouseList={edit}
          setShowHouseList={setEdit}
          setData={handleDataChange}
        />
      </div>
    </Box>
  );
}
