import BaseFieldComponent from "../BaseFieldComponent";
import {getElapsedTimeLabel} from "../../../../utilities/helperFunctions";

class BookingLeadTimeField extends BaseFieldComponent {
  renderText({value}) {
    return getElapsedTimeLabel(Number(value));
  }
}

export default BookingLeadTimeField;
