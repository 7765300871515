import {Autocomplete} from "@material-ui/lab";
import React from "react";
import {postTemplatesPreview} from "redux/api/messagesAPI";
import {useSelector} from "react-redux";

export default function TemplateInput({
  templates,
  guest,

  templatePreviews,
  setTemplatePreviews,

  selectedTemplateId,
  setSelectedTemplateId,

  selectedTemplate,
  setSelectedTemplate,

  templateMatchModalOpen,
  setTemplateMatchModalOpen,

  templatePairing,

  autocompleteKey,
  whatsappAccountId,
  ...other
}) {
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );

  React.useEffect(() => {
    if (!!selectedTemplateId) {
      setSelectedTemplate(
        templates?.find((t) => t.integration_template_id == selectedTemplateId),
      );
    }
  }, [selectedTemplateId]);

  React.useEffect(() => {
    if (!!selectedTemplate && !selectedTemplate.paired) {
      setTemplateMatchModalOpen(true);
    }
  }, [selectedTemplate]);

  React.useEffect(() => {
    if (!!whatsappAccountId && !!current_user) {
      let body = {
        template_type: "whatsapp",
        enso_key: current_user,
        guest_id: guest?.guest_id,
        service_id: whatsappAccountId,
      };
      postTemplatesPreview({body}).then((result) => {
        setTemplatePreviews({empty: "", ...result});
      });
    }
  }, [whatsappAccountId, guest]);

  if (!Object.keys(templatePreviews)) {
    return null;
  }
  return (
    <div>
      <Autocomplete
        key={autocompleteKey}
        label={!!selectedTemplateId ? null : "Template"}
        options={Object.entries(templatePreviews)?.map((entry, i) => {
          return {
            id: entry[0],
            label: entry[1],
          };
        })}
        getOptionSelected={(opt, val) => opt.id === val.id}
        getOptionLabel={(option) => option.label ?? ""}
        onChange={(e, nv) => {
          setSelectedTemplateId(nv.id);
        }}
        disabled={templatePairing}
        disableClearable
        forcePopupIcon={false}
        {...other}
      />
    </div>
  );
}
