import {makeStyles} from "@material-ui/core";
import {THEME} from "configuration/settings.js";

const useCalendarStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    overflow: "hidden",
    "& .nice-dates": {width: "100%"},
  },
  menuContainer: {
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    width: "250px !important",
    height: "280px !important",
  },
  gutters: {padding: theme.spacing(0, 4)},
  calendar: {
    width: "100%",
    flexGrow: 1,
    overflow: "hidden",
    paddingBottom: theme.spacing(4),
    "&.subcalendar": {paddingBottom: 0},
    "& > div:not(.multiple-view), div.multiple-view > div": {
      width: "100%",
      height: "100%",
      overflow: "hidden auto",
      display: "flex",
      flexDirection: "column",
    },
    "& .nice-dates-navigation": {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(1),
      "& > a": {
        padding: theme.spacing(3),
        "&:before": {
          width: 10,
          height: 10,
        },
      },
      "& > .nice-dates-navigation_current": {fontSize: 14},
    },
    "& div.nice-dates-grid": {
      height: "0!important",
      flexGrow: 1,
      boxSizing: "border-box",
      "& > .nice-dates-grid_container": {
        height: "100%",
        transitionDuration: "900ms!important",
        transform: "translate3d(0px,0px,0px)!important",
        "&.-moving.-origin-bottom": {
          transform: "translate3d(0px,0px,0px)!important",
        },
        "&.-moving.-origin-top": {
          transform: "translate3d(0px,-100%,0px)!important",
        },
      },
    },
    "& span.nice-dates-day": {
      height: "20%!important",
      "&.-disabled": {opacity: 0.5},
      "& .nice-dates-day_date": {color: "#333!important"},
      "& > span.nice-dates-day_month": {display: "none"},
      "& > span.nice-dates-day_date": {
        zIndex: 100,
        position: "absolute !important",
        top: "10%",
      },
      "&.-outside .nice-dates-day_date": {color: "#999!important"},
      "&.-outside": {
        "&.-selected:before": {opacity: 0.4},
        "& .ranges-container": {opacity: 0.4},
      },
      "&.-today": {
        "&.-disabled": {opacity: 0.8 + "!important"},
        "& .nice-dates-day_date": {
          color: `${theme.palette.secondary.main}!important`,
        },
        "&.-rate .rate-element": {color: theme.palette.secondary.light},
      },
      "&._disabled": {
        "&:after": {border: "none"},
        "&.-selected": {
          "&:after": {
            backgroundColor: `${theme.palette.secondary.main}!important`,
          },
          "& > .nice-dates-day_date": {
            color: `${theme.palette.common.white}!important`,
          },
        },
      },
      "&.-selected:not(:hover) .nice-dates-day_date": {
        color: `${theme.palette.common.white}!important`,
      },
      "&.-selected:not(:hover) .rate-element": {
        color: `${theme.palette.common.white}!important`,
      },
      "&:before": {backgroundColor: theme.palette.secondary.main},
      "&:after": {borderColor: theme.palette.secondary.main},
      "& div.ranges-container": {
        position: "absolute",
        width: "100%",
        height: "100%",
        overflow: "visible",
        "& > .rate-element": {visibility: "hidden"},
        "& > .bkg-element": {visibility: "hidden"},
      },
      "&.-rate .rate-element": {
        position: "absolute",
        width: "100%",
        height: "40%",
        bottom: "15%",
        zIndex: 3,
        color: theme.palette.secondary.light,
        fontWeight: theme.typography.fontWeightMedium,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        visibility: "visible!important",
      },
      "&.-booking": {
        "& .rate-element": {display: "none!important"},
        "& .bkg-element": {
          position: "absolute",
          bottom: "15%",
          height: "40%",
          width: "100%",
          whiteSpace: "nowrap",
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.common.white,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          zIndex: 3,
          visibility: "visible!important",
        },
      },
      "&.-booking-start .bkg-element": {
        borderRadius: "999px 0 0 999px",
        paddingLeft: theme.spacing(2),
        zIndex: "4!important",
      },
      "&.-booking-end .bkg-element": {borderRadius: "0 999px 999px 0"},
      "&.-not-available": {
        backgroundColor: THEME.bgDisabled,
        "& .rate-element": {display: "none!important"},
      },
    },
    "& > .multiple-view": {
      width: "50%",
      height: "50%",
      display: "inline-block",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      "& span.nice-dates-day": {fontSize: 11},
      "&.-calendar-1 .nice-dates-navigation > .nice-dates-navigation_next": {
        visibility: "hidden",
      },
      "&.-calendar-2 .nice-dates-navigation": {
        "& > .nice-dates-navigation_previous": {visibility: "hidden"},
        "& > .nice-dates-navigation_next": {visibility: "hidden"},
      },
      "&.-calendar-3 .nice-dates-navigation": {
        "& > .nice-dates-navigation_previous": {visibility: "hidden"},
        "& > .nice-dates-navigation_next": {visibility: "hidden"},
      },
      "&.-calendar-4 .nice-dates-navigation > .nice-dates-navigation_previous":
        {visibility: "hidden"},
    },
  },

  // Inputs
  inputsContainer: {
    display: "flex",
    flexWrap: "wrap",
    "& div.nice-dates-popover": {display: "none"},
    "& div.nice-dates": {
      flexShrink: 0,
      width: "fit-content",
    },
    "& div.date-range": {
      display: "flex",
      justifyContent: "space-between",
      "& > #inputs": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
    },
  },
  icon: {
    width: 42,
    height: 42,
    color: "#d3dde6",
  },
  clearBtn: {
    padding: theme.spacing(4),
    "&:hover": {backgroundColor: "transparent"},
  },
}));

export default useCalendarStyles;
