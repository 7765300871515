import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useRouteMatch} from "react-router-dom";
// UI
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import CustomCardHeader from "core/cards/CustomCardHeader";
import LocalServiceGrid from "components/Grids/LocalServiceGrid";
import CloseIconButton from "core/buttons/CloseIconButton";
import CustomDialog from "core/dialogs/CustomDialog";
import GJEInfoCard from "components/Cards/GJEInfoCard";
import ConnectedToButton from "core/buttons/ConnectedToButton";
import FilledTextField from "core/inputs/FilledTextField";
// Actions
import {
  deleteListingResource,
  editListingGroup,
  getListingGroupDetails,
  saveListingResource,
  updateListingResource,
} from "redux/actions/listingsActions";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: theme.spacing(4),
    paddingTop: 0,
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.primary.main + "0D"}`,
  },
}));

export default function LocalServicesPanel({onClose}) {
  const classes = useStyles();
  const matchCardSection = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card",
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  // Refs
  const prevServices = React.useRef([]);
  // State
  const [services, setServices] = React.useState([]);
  const [listingsOpen, setListingsOpen] = React.useState(false);
  const [selectedService, setSelectedService] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  // General
  const {groupId, section, card} = React.useMemo(() => {
    if (!matchCardSection?.isExact) {
      return {groupId: null, section: null, card: null};
    }
    return {
      groupId: matchCardSection.params.group_id,
      section: matchCardSection.params.step,
      card: matchCardSection.params.card,
    };
  }, [location]);
  const selectedGroup = listingGroups[groupId];
  const guestJourney = selectedGroup?.guest_journey ?? {};
  const data = guestJourney.sections?.[section]?.cards[card]?.data ?? [];
  const readOnly = user_profile.scopes?.listings !== "write";

  React.useEffect(() => {
    setServices((prev) => data);
  }, []);

  const updateGroup = (newServices) => {
    setServices((prev) => newServices);
    const newGroup = {...selectedGroup};
    newGroup.guest_journey.sections.pci.cards.local_services.data = newServices;
    dispatch(editListingGroup(newGroup));
  };

  const onResponseSuccess = () => {
    setLoading((prev) => false);
    dispatch(getListingGroupDetails({groupId, section, card}));
  };

  const onResponseError = () => {
    setLoading((prev) => false);
    updateGroup(prevServices.current);
  };

  const addService = (product) => {
    console.log("ADDING SERVICE", product);
    setLoading((prev) => true);
    const newProduct = {
      properties: product,
      connected_ids: {},
      group_ids: [groupId],
      connection_type: "group",
      connected_object: "listing",
    };
    prevServices.current = [...services];
    const newServices = [
      ...services,
      {
        ...newProduct,
        properties: {
          ...newProduct.properties,
          product_id: `new_${product.product_type}`,
        },
      },
    ];
    updateGroup(newServices);
    dispatch(
      saveListingResource({
        body: {...newProduct, resource: "up"},
        type: "service",
        disableListingUpdate: true,
        onSuccess: (res) => {
          setServices((prev) =>
            prev.map((s) =>
              s.properties.product_id === `new_${product.product_type}`
                ? res
                : s,
            ),
          );
          onResponseSuccess();
        },
        onError: onResponseError,
      }),
    );
  };

  const updateService = (product) => {
    setLoading((prev) => true);
    prevServices.current = [...services];
    const newServices = services.map((s) =>
      s.properties.product_id === product.properties.product_id ? product : s,
    );
    updateGroup(newServices);
    dispatch(
      updateListingResource({
        body: {...product, resource: "up"},
        disableListingUpdate: true,
        onSuccess: onResponseSuccess,
        onError: onResponseError,
      }),
    );
    closeModal();
  };

  const handleRemoveProduct = (product) => {
    setLoading((prev) => true);
    prevServices.current = [...services];
    const newServices = services.filter(
      (s) => s.properties.product_id !== product.properties.product_id,
    );
    updateGroup(newServices);
    dispatch(
      deleteListingResource({
        resource: "up",
        type: "service",
        resource_id: product.resource_id,
        disableListingUpdate: true,
        onSuccess: onResponseSuccess,
        onError: onResponseError,
      }),
    );
  };

  const handleSelectService = (service, index) => {
    setSelectedService((prev) => service);
  };

  const closeModal = () => {
    setSelectedService((prev) => null);
    setListingsOpen((prev) => false);
  };

  const handleURLChange = (e) => {
    const val = e.target.value;
    setSelectedService((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        skus: [
          {
            ...prev.properties.skus[0],
            url: val,
          },
        ],
      },
    }));
  };

  const serviceModal = !!selectedService && (
    <CustomDialog
      fullWidth
      maxWidth="md"
      open={!!selectedService}
      titleVariant="header"
      title={selectedService.properties.name[0].value}
      onClose={closeModal}
      labelConfirm="Save"
      actionConfirm={() => updateService(selectedService)}
      disableConfirm={!!readOnly}
      content={
        <>
          <div className={listingsOpen ? "" : "mb-3"}>
            <ConnectedToButton
              isGroupView
              data={selectedService}
              disabled={!!readOnly}
              listingsOpen={listingsOpen}
              setListingsOpen={setListingsOpen}
              listing_id={selectedService.listing_id}
              group_id={groupId}
              disabledOptions={["ids"]}
              editData={setSelectedService}
            />
          </div>
          {!listingsOpen && (
            <FilledTextField
              fullWidth
              label="URL"
              disabled={!!readOnly}
              value={selectedService.properties?.skus?.[0]?.url}
              placeholder="e.g: https://www.serviceurl.com"
              onChange={handleURLChange}
            />
          )}
        </>
      }
    />
  );

  return (
    <div className={classes.container}>
      {serviceModal}
      <CustomCardHeader
        title="Local services"
        className={classes.header}
        action={<CloseIconButton sm disablePadding onClick={onClose} />}
      />
      <div className={classes.content}>
        <Box mx={-4}>
          <GJEInfoCard banner type="local_services" />
        </Box>
        <LocalServiceGrid
          type="services"
          data={services}
          disableEdit={!!readOnly || loading}
          onAdd={addService}
          onEdit={handleSelectService}
          onDelete={handleRemoveProduct}
        />
      </div>
    </div>
  );
}
