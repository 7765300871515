import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
// UI
import {
  Avatar,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
// Custom
import {EmptyListPanel} from "components/Helpers/EmptyPanels";
import VirtualizedList from "components/Lists/VirtualizedList";
import CustomCardHeader from "core/cards/CustomCardHeader";
import PrimaryButton from "core/buttons/PrimaryButton";
import SearchBar from "core/bars/SearchBar";
import Panel2 from "core/panels/Panel2.jsx";
import Panel3 from "core/panels/Panel3";
// Actions
import {setSelectedViewItem} from "redux/actions/accountsActions";
// Utilities
import {searchRows} from "utilities/helperFunctions";
import {FIXED_SIZES} from "configuration/settings";
import usePrevious from "hooks/usePrevious";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import Branding from "./Branding";

const searchKeys = ["name"];

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  panel2Container: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  header: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  searchContainer: {
    padding: theme.spacing(0, 4, 4),
    "& > .searchContainer": {paddingBottom: 0},
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
  },
  item: {
    height: "100%",
    padding: theme.spacing(0, 4),
  },
  avatar: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(4),
  },
  subtitle: {textTransform: "capitalize"},
  yourAccountText: {
    fontSize: 12,
    fontWeight: 400,
    marginLeft: theme.spacing(1),
    color: theme.palette.text.disabled,
  },
}));

export default function BrandingEntryList({
  itemListProp,
  listTitle,
  setBranding,
  branding,
  onCloseURL,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const loading = useSelector((state) => state.defaultReducer.loading).profile;
  const isTabletView =
    useSelector((state) => state.defaultReducer.deviceType) === "tablet";
  const selectedViewItems = useSelector(
    (state) => state.defaultReducer.selected_view_items,
  );
  let savedItem = selectedViewItems.settings.item;
  let savedSelectedItem = selectedViewItems.settings.props.selectedItem;
  const [itemList, setItemList] = React.useState(itemListProp);
  const [selectedItem, setSelectedItem] = React.useState(savedSelectedItem);
  const [searchText, setSearchText] = React.useState("");
  const prevSearchText = usePrevious(searchText);
  const {t} = useTranslation();

  React.useEffect(() => {
    if (!searchText) {
      if (!!prevSearchText) {
        const orderedItems = _.orderBy(itemListProp, "name", "asc");
        setItemList((prev) => orderedItems);
      }
    } else {
      filterRows(searchText);
    }
  }, [searchText]);

  React.useEffect(() => {
    const newItem = itemListProp.find((item) => item.id === "brand#new_item");
    if (newItem) {
      setSelectedItem(newItem);
    }
  }, [itemListProp]);

  React.useEffect(() => {
    if (selectedItem === "new_item") {
      return;
    } else {
      let newProps = {
        selectedDevice: null,
        selectedItem: selectedItem,
        connectedAccount: {
          account: null,
          accountType: null,
          integrationSpec: null,
        },
      };
      dispatch(setSelectedViewItem("settings", savedItem, newProps));
    }
  }, [selectedItem]);

  const onCreateNew = () => {
    setSelectedItem("new_item");
  };

  const filterRows = (searchText) => {
    setItemList((prev) =>
      _.orderBy(
        searchRows(itemListProp, searchKeys, searchText),
        "name",
        "asc",
      ),
    );
  };

  function onClose() {
    setSelectedItem(null);
    history.replace(onCloseURL);
    handleUpdateItemList("brand#new_item", "delete");
  }

  const handleUpdateItemList = (newItem, type) => {
    setItemList((prevItemList) => {
      let propList;

      if (type === "delete") {
        propList = prevItemList.filter((item) => item.id !== newItem);
      } else if (type === "create") {
        propList = [...prevItemList, newItem];
      } else if (type === "update") {
        console.log("NEW ID: ", newItem);
        propList = prevItemList.map((item) =>
          item.id === newItem.id ? newItem : item,
        );
      }

      const sortedList = _.orderBy(propList, "name", "asc");
      setBranding(sortedList);
      return sortedList;
    });
    // onClose();
  };

  function getItem({index}) {
    const i = itemList[index];
    if (!i) return null;
    return (
      <ListItem
        dense
        button
        divider
        disableRipple
        className={classes.item}
        onClick={() => setSelectedItem(i)}
      >
        <ListItemIcon>
          <Avatar
            className={classes.avatar}
            alt={`${i?.name} logo`}
            src={i.logo}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              {i?.name}
              {i?.id?.toString().includes("brand#root") ||
              i?.id?.toString().includes("root#brand") ? (
                <Button
                  variant="contained"
                  className="ml-3"
                  style={{
                    borderRadius: "5px",
                    background: "#DCE5EC",
                    color: "#275588",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "1rem",
                    maxHeight: "1.5rem",
                  }}
                >
                  {t("stripe-account-default-chip")}
                </Button>
              ) : (
                <>
                  {i?.group_ids?.length < 1 || !i?.group_ids ? (
                    <Button
                      variant="contained"
                      className="ml-3"
                      style={{
                        borderRadius: "5px",
                        background: "#ffb8b8",
                        color: "#882727",
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "1rem",
                        maxHeight: "1.5rem",
                      }}
                    >
                      {t("stripe-account-disabled-chip")}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="ml-3"
                      style={{
                        borderRadius: "5px",
                        background: "#9AD4D6",
                        color: "#229296",
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "1rem",
                        maxHeight: "1.5rem",
                      }}
                    >
                      {t("branding-account-brand-count").replace(
                        "{count}",
                        i?.group_ids?.length,
                      )}
                    </Button>
                  )}
                </>
              )}
            </>
          }
          primaryTypographyProps={{variant: "h1"}}
          secondary={i.role || ""}
          secondaryTypographyProps={{
            className: classes.subtitle,
            variant: "body1",
            color: "textSecondary",
          }}
        />
      </ListItem>
    );
  }

  const searchbar = React.useMemo(
    () => (
      <div className={classes.searchContainer}>
        <SearchBar
          disableGutters
          searchInput={searchText}
          handleSearchInput={(val) => setSearchText((prev) => val)}
        />
      </div>
    ),
    [searchText],
  );

  const itemListUI = React.useMemo(() => {
    if (!itemList) return null;

    return (
      <VirtualizedList
        hideScrollbar
        getRowItem={getItem}
        rowHeight={FIXED_SIZES.branding} // doesn't have to be branding size
        totalRows={itemList.length}
      />
    );
  }, [itemList, selectedItem]);

  const panel2 = (
    <div className={classes.panel2Container}>
      {loading ? (
        <EmptyListPanel loading list="branding" /> // list doesn't have to be branding
      ) : (
        <>
          <CustomCardHeader
            type="header"
            action={
              <PrimaryButton
                label={t("item-list-header-create-new-button")}
                size="small"
                id="create-new-item-btn"
                onClick={onCreateNew}
              />
            }
            className={classes.header}
            title={listTitle.replace("{count}", itemList?.length || 0)}
          />
          {searchbar}
          <div className={classes.content}>{itemListUI}</div>
        </>
      )}
    </div>
  );

  const panel3 = !!selectedItem && (
    <Branding
      branding={selectedItem}
      setBranding={setBranding}
      showCreateButton={
        selectedItem === "new_item" || selectedItem.id === "brand#new_item"
      }
      showSaveButton={
        selectedItem !== "new_item" && selectedItem.id !== "brand#new_item"
      }
      showDeleteButton={selectedItem !== "new_item"}
      onClose={onClose}
      onUpdateItemList={handleUpdateItemList} // Pass
    />
  );

  return (
    <div className={classes.root}>
      <Panel2
        flexAuto
        content={panel2}
        isPanel3Open={isTabletView ? false : !!selectedItem}
        hidden={isTabletView && !!selectedItem}
      />
      {(!isTabletView || !!selectedItem) && (
        <Panel3
          content={panel3}
          hidden={!selectedItem}
          maintainWidth={!isTabletView}
          disableMaxWidth={isTabletView}
        />
      )}
    </div>
  );
}
