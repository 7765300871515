import React from "react";
import {Button} from "@material-ui/core";
import {userTypes} from "configuration/constants";
import _ from "lodash";
import {THEME} from "configuration/settings";

const UserScopeTag = ({user}) => {
  let userType = "Custom";
  for (const key in userTypes) {
    if (Object.hasOwn(userTypes, key)) {
      const item = userTypes[key];
      if (_.isEqual(user.scopes, item.scopes)) {
        userType = item.displayName;
        break;
      }
    }
  }

  return (
    <Button
      variant="outlined"
      color="primary"
      style={{
        border: "none",
        color: "rgb(13, 86, 140, 100%)",
        backgroundColor: "rgb(13, 86, 140, 10%)",
        borderRadius: "5px",
        padding: `${THEME.spacing.xs}px ${THEME.spacing.sm}px`,
        margin: `0 ${THEME.spacing.sm}px`,
        fontSize: "12px",
      }}
    >
      {userType}
    </Button>
  );
};

export default UserScopeTag;
