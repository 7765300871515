import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Typography} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import InfoText from "components/Misc/InfoText";
import PrimaryButton from "core/buttons/PrimaryButton";
import FilledTextField from "core/inputs/FilledTextField";
import {useFlags, withLDConsumer} from "launchdarkly-react-client-sdk";

import {SuperflowsModal} from "@superflows/chat-ui-react";

import {Auth} from "aws-amplify";
import clsx from "clsx";

import suggestions from "../../../assets/data/superflows-suggestions.json";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    width: "100%",
    padding: theme.spacing(2, 4),
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
    borderRadius: 10,
    background: "#FFF",
    "&.mobile": {
      height: "fit-content",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  title: {fontWeight: 500},
}));

function getRandom(array, count = 0) {
  if (array.length <= count) return array;
  const result = [];

  while (result.length < count) {
    const random = Math.floor(Math.random() * array.length); //get random index
    if (result.indexOf(array[random]) === -1) {
      //check if item is already in result
      result.push(array[random]); //if not, add it
    }
  }

  return result;
}

const aiSuggestions = getRandom(suggestions, 2);

const AIInsightsCard = ({mobile, loading}) => {
  const classes = useStyles();
  const flags = useFlags();
  const [query, setQuery] = useState("");
  const [superflowsIsOpen, setsuperflowsIsOpen] = useState(false);

  const handleTextChange = (e) => {
    const val = e.target.value;
    setQuery((prev) => val);
  };

  if (!flags.aiInsights) {
    return null;
  }
  return (
    <div className={clsx(classes.card, {mobile: !!mobile})}>
      <Box display="flex" alignItems="center" width={"100%"} flexWrap="wrap">
        {!!loading ? (
          <Skeleton
            animation="wave"
            width={"100%"}
            height={80}
            className={classes.skeleton}
          />
        ) : (
          <>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              minWidth={120}
            >
              <Typography variant="h2" className={classes.title}>
                {"AI Insights"}
              </Typography>
              <Box ml={2} pb={1}>
                <InfoText disableGutters text={"AI Insights info"} subdued />
              </Box>
            </Box>
            <Box flex={1}>
              <FilledTextField
                fullWidth
                value={query}
                customBorderRadius={10}
                placeholder="What do you want to learn?"
                onChange={handleTextChange}
              />
            </Box>
            <Box ml={2}>
              <PrimaryButton
                label={"Generate"}
                color="secondary"
                onClick={() => setsuperflowsIsOpen(true)}
              />
            </Box>
            <div
              className="mt-2"
              style={{
                flex: "1 1 100%",
                textTransform: "unset",
                fontSize: ".8rem",
              }}
            >
              Suggestions:
              {aiSuggestions.map((suggestion) => (
                <Button
                  onClick={() => {
                    setQuery(suggestion);
                    setsuperflowsIsOpen(true);
                  }}
                  className="ml-1"
                >
                  {suggestion}
                </Button>
              ))}
            </div>
          </>
        )}
      </Box>
      <SuperflowsModal
        AIname="Enso"
        open={superflowsIsOpen}
        setOpen={setsuperflowsIsOpen}
        superflowsApiKey={process.env.REACT_APP_SUPERFLOWS_API_KEY}
        userApiKey={Auth.user?.username}
        suggestions={suggestions}
        initialMessage={query}
        userId={Auth.user?.username}
      />
    </div>
  );
};

export default withLDConsumer()(AIInsightsCard);
