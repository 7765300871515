import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {AutoSizer, Grid} from "react-virtualized";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  grid: {
    overflowX: "hidden !important",
    overflowY: "auto !important",
  },
  hideScrollbar: {
    overflow: "hidden !important",
    "&::-webkit-scrollbar": {width: "0!important"},
  },
}));

export default function VirtualizedGrid({
  getCellContent,
  columnWidth = "auto",
  columnCount,
  hideScrollbar,
  ...gridProps
}) {
  const classes = useStyles();

  const cellRenderer = ({columnIndex, key, rowIndex, style}) => {
    return (
      <div key={key} style={style}>
        {getCellContent({columnIndex, rowIndex})}
      </div>
    );
  };

  return (
    <AutoSizer>
      {({width, height}) => {
        return (
          <Grid
            className={clsx(classes.grid, {
              [classes.hideScrollbar]: !!hideScrollbar,
            })}
            width={width}
            height={height}
            columnCount={columnCount}
            columnWidth={
              columnWidth === "auto"
                ? Math.floor(width / columnCount)
                : columnWidth
            }
            cellRenderer={cellRenderer}
            {...gridProps}
          />
        );
      }}
    </AutoSizer>
  );
}
