import {API} from "aws-amplify";

export const getExperiencesPresets = async (params) => {
  try {
    const response = await API.get("ensoAPI", `/experiences/presets?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getExperiences = async (params) => {
  try {
    const response = await API.get("ensoAPI", `/experiences?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const postExperience = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/experiences", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const patchExperience = async (body) => {
  try {
    const response = await API.patch("ensoAPI", "/experiences", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const postCollectDetails = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/collectdetails", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const delExperience = async (params) => {
  try {
    const response = await API.del("ensoAPI", `/experiences${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const patchAction = (body) => {
  try {
    const response = API.patch("ensoAPI", "/actions/scheduled", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const postAction = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/actions/scheduled", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const postInstantAction = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/actions", {body: body});
    return response;
  } catch (err) {
    throw err;
  }
};

export const delAction = async (params) => {
  try {
    const response = await API.del("ensoAPI", `/actions/scheduled?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};
