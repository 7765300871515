import * as types from "../actionTypes";
import * as crmAPI from "../api/crmAPI";
import qs from "qs";
import {getEncodedFilters} from "utilities/helperFunctions";
import {setApiError, setApiStart, setApiSuccess} from "./settingsActions";

export function setLoadingHits(loading) {
  return {type: types.SET_CRM_LOADING_HITS, loading};
}

export function addExportCRM(reportId, contentType, data) {
  return {type: types.ADD_EXPORT_CRM, reportId, contentType, data};
}

export function getCRMGuestList(
  start,
  view,
  interval,
  filters,
  query,
  onSuccess,
  onError,
  page,
) {
  return async (dispatch, getState) => {
    const current_user = getState().defaultReducer.current_user;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const LIMIT = 50;
    let params = {
      enso_key: current_user,
      interval: interval,
      start: start,
      view: view,
      metric: "guests",
      filters: filters.map((f) => ({
        path: f.path,
        operator: f.operator,
        value: f.value,
      })),
      query: query,
      timezone: timezone,
      limit: LIMIT,
      page: page || 1,
    };

    dispatch(setApiStart("reports"));
    try {
      let response = null;

      response = await crmAPI.getCrmPreviewGuests(params);

      response.page = params.page;
      response.metric = "guests";
      response.view = view;
      response.start = start;

      console.log("GOT PREVIEW RESPONSE", response);
      onSuccess && onSuccess([response]);
      dispatch(setApiSuccess("reports"));
    } catch (error) {
      console.log("ERROR - GET REPORT PREVIEW", error);
      onError && onError();
      dispatch(setApiError("reports", "This preview is not available"));
    }
  };
}

export function getExportCRM({
  reportId,
  contentType,
  start,
  view,
  interval,
  filters,
  query,
  startHits,
  onSuccess,
}) {
  return async (dispatch, getState) => {
    const current_user = getState().defaultReducer.current_user;
    let params = null;
    if (!!reportId) {
      params = qs.stringify({
        report_id: reportId,
        content_type: contentType,
        enso_key: current_user,
        start,
      });
    } else {
      params = `content_type=${contentType}&metric=$guests&view=${view}&interval=${interval}&enso_key=${current_user}`;
      if (!!start) {
        params += `&start=${start}`;
      }
      if (!!filters?.length) {
        params += `&filters=${getEncodedFilters(filters)}`;
      }
      if (!!query) {
        params += `&query=${query}`;
      }
      if (!!startHits) {
        params += `&start_hits=${startHits}`;
      }
    }

    dispatch(setApiStart("reports"));
    try {
      let response = null;
      response = await crmAPI.getCrmExportGuests({
        query: query,
        filters: filters,
        content_type: contentType,
      });

      console.log(`GOT EXPORT CRM GUESTS`, response);
      if (!!response?.data) {
        dispatch(addExportCRM(reportId, contentType, response.data));
      }
      onSuccess && onSuccess(response);
      dispatch(setApiSuccess("reports"));
    } catch (error) {
      console.log("ERROR - GET EXPORT REPORT", error);
      dispatch(setApiError("reports"));
    }
  };
}
