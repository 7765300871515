import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Modal, Paper, Typography} from "@material-ui/core";
import InfoText from "./InfoText";
import FilledTextField from "core/inputs/FilledTextField";
import PrimaryButton from "core/buttons/PrimaryButton";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "50vw",
    width: 800,
    maxWidth: "80vw",
    padding: theme.spacing(4),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
  },
}));

const VideoPrompt = ({onConfirm, onCancel}) => {
  const classes = useStyles();
  const [videoUrl, setVideoUrl] = useState("");

  const handleConfirm = () => {
    onConfirm(videoUrl);
    setVideoUrl("");
  };

  return (
    <Modal open onClose={onCancel}>
      <Paper elevation={0} className={classes.container}>
        <Box className={classes.row}>
          <InfoText disableGutters subdued text={"Use only public videos"} />
          <Typography variant="h1">{"Enter a public Youtube URL:"}</Typography>
        </Box>
        <FilledTextField
          fullWidth
          value={videoUrl}
          placeholder="Enter video URL"
          onChange={(e) => setVideoUrl(e.target.value)}
        />
        <Box className={classes.row} justifyContent="flex-end">
          <PrimaryButton
            subdued
            variant="outlined"
            label={"Cancel"}
            onClick={onCancel}
          />
          <PrimaryButton
            color="secondary"
            label={"Insert"}
            onClick={handleConfirm}
          />
        </Box>
      </Paper>
    </Modal>
  );
};

export default VideoPrompt;
