import React from "react";
import {Button, Container, makeStyles, Typography} from "@material-ui/core";
import UploadIcon from "@material-ui/icons/GetApp";

import {
  asyncLoop,
  newUuid,
  sleep,
  validateFile,
} from "utilities/helperFunctions";
import {allowedImagesRange} from "configuration/constants.js";
import {useImageCompressor} from "utilities/fileUtilities";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 30,
  },
  disabled: {opacity: 0.5},
  dashed: {
    border: `2px dashed #d2d2d2`,
    borderRadius: 10,
    width: "100%",
    maxWidth: 600,
    height: 225,
    display: "grid",
    placeContent: "center",
    backgroundColor: "#F6F9FF",
  },
  icon: {
    fontSize: 60,
    justifySelf: "center",
    marginBottom: 10,
  },
}));

export default function UploadImages({
  pictures,
  setLoading,
  setPictures,
  displayError,
  disabled,
}) {
  const classes = useStyles();
  const inputRef = React.useRef(null);
  const [files, setFiles] = React.useState([]);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [compressEnabled, setCompressEnabled] = React.useState(false);
  useImageCompressor({
    srcImgs: !!compressEnabled && files[0] === "upload" ? files.slice(1) : [],
    onSuccess: (outputImgs) => onCompressSuccess(outputImgs),
    fileInObject: true,
  });

  React.useEffect(() => {
    if (!files.length) return;

    if (files[0] === "upload") {
      setLoading("Loading photos...");
      sleep(250).then(() => {
        setCompressEnabled((prev) => true);
      });
    }
  }, [files]);

  React.useEffect(() => {
    if (disabled || !uploadedFiles.length) return;
    loadImages(uploadedFiles);
  }, [uploadedFiles]);

  const onCompressSuccess = (outputImgs) => {
    setCompressEnabled((prev) => false);
    setFiles((prev) => []);
    setPictures([...pictures, ...outputImgs]);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };
  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const loadImages = async (uploadedFiles) => {
    await asyncLoop(uploadedFiles, async (f) => {
      await handleFile(f);
    });
    setFiles((prev) => ["upload", ...prev]);
  };

  const filesUploaded = (e) => {
    e.preventDefault();
    const uploadedFiles = e.target.files;
    validateNumberOfFiles(uploadedFiles);
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const uploadedFiles = e.dataTransfer.files;
    validateNumberOfFiles(uploadedFiles);
  };

  const validateNumberOfFiles = (uploadedFiles) => {
    if (!!uploadedFiles && Object.keys(uploadedFiles).length) {
      const max = allowedImagesRange[1] - pictures.length;
      const allowedFiles = [...uploadedFiles];
      if (allowedFiles.length > max) {
        allowedFiles.splice(max);
        displayError(null, "exceeded");
      }
      setUploadedFiles(Object.values(allowedFiles));
    }
  };

  const handleFile = (file) => {
    return new Promise((resolve) => {
      const {isValid, error} = validateFile(file);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        if (isValid) {
          setFiles((prev) => [
            ...prev,
            {
              file,
              picture: e.target.result,
              description: [{language: "en", value: ""}],
              key: newUuid(),
            },
          ]);
        } else {
          displayError(null, "custom", error);
        }
        resolve(true);
      };
    });
  };

  return (
    <Container
      className={clsx(classes.container, {[classes.disabled]: !!disabled})}
    >
      <div
        className={classes.dashed}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
      >
        <input
          id="pictureInput"
          ref={inputRef}
          style={{display: "none"}}
          type="file"
          multiple
          accept="image/*"
          onChange={filesUploaded}
        />
        <UploadIcon color="primary" className={classes.icon} />
        <Typography className="mb-2" align="center" variant="h1">
          Drag & drop files here
        </Typography>
        <Typography
          className="mb-2"
          align="center"
          color="textSecondary"
          variant="subtitle1"
        >
          or
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={handleClick}
        >
          Browse files
        </Button>
        <Typography color="textSecondary" style={{marginTop: 10}}>
          Max. upload file size: 10MB
        </Typography>
      </div>
    </Container>
  );
}
