import React from "react";
import {ReactComponent as HomeIcon} from "assets/icons/house.svg";
import {ReactComponent as UpsellsIcon} from "assets/icons/shopping-bag.svg";
import {ReactComponent as GuidebooksIcon} from "assets/icons/book.svg";
// UI
import {Button, SvgIcon, Typography, makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  navbar: {
    gap: 12,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "8px 30px",
    position: "sticky",
    bottom: -1,
    left: 0,
    right: 0,
    zIndex: 1,
    background: theme.palette.primary.contrast + "a6",
    backdropFilter: "blur(8px)",
    height: 80,
    borderRadius: "0 0 48px 48px",
  },
  icon: {
    color: theme.palette.primary.main,
    width: "24px !important",
    height: "24px !important",
  },
  text: {
    color: theme.palette.primary.main,
    width: "100%",
    textAlign: "center",
  },
  btn: {
    borderRadius: 10,
    opacity: 0.6,
    padding: theme.spacing(1),
    flex: 1,
    maxWidth: 250,
    textTransform: "none",
    "&.selected": {
      backgroundColor: theme.palette.primary.main + "59",
      opacity: 1,
    },
  },
  btnLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function NavbarButton({view, selected, disabled, onClick}) {
  const classes = useStyles();
  const {t} = useTranslation();

  const getIcon = () => {
    let newIcon = null;
    let viewBox = "0 0 24 24";
    switch (view) {
      case "home":
        newIcon = HomeIcon;
        viewBox = "0 0 18 17";
        break;
      case "upgrades":
        newIcon = UpsellsIcon;
        viewBox = "0 0 16 16";
        break;
      case "guides":
        newIcon = GuidebooksIcon;
        break;
      default:
        break;
    }
    return (
      <SvgIcon viewBox={viewBox} className={classes.icon} component={newIcon} />
    );
  };

  const getLabel = () => {
    switch (view) {
      case "home":
        return t("home_page");
      case "upgrades":
        return t("upgrades");
      case "guides":
        return t("guides");
      default:
        return "";
    }
  };

  return (
    <Button
      disableRipple
      disabled={disabled}
      classes={{
        root: clsx(classes.btn, {selected: selected === view}),
        label: classes.btnLabel,
      }}
      onClick={() => onClick(view)}
    >
      {getIcon()}
      <Typography className={classes.text}>{getLabel()}</Typography>
    </Button>
  );
}

const KeycardNavbar = ({components, selected, disabled, onClick}) => {
  const classes = useStyles();
  let hasGuides = !!components?.guidebooks?.length;
  let hasUpgrades = !!components?.upsells?.length;

  const handleClick = (view) => {
    onClick(view);
  };

  return (
    <div className={classes.navbar}>
      <NavbarButton
        view="home"
        selected={selected}
        disabled
        onClick={handleClick}
      />
      {hasUpgrades && (
        <NavbarButton
          view="upgrades"
          selected={selected}
          disabled={disabled}
          onClick={handleClick}
        />
      )}
      {hasGuides && (
        <NavbarButton
          view="guides"
          selected={selected}
          disabled={disabled}
          onClick={handleClick}
        />
      )}
    </div>
  );
};

export default KeycardNavbar;
