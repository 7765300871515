import React from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
// UI
import {Box, Collapse, Paper, SvgIcon, Typography} from "@material-ui/core";
import {ReactComponent as MessagesIcon} from "assets/icons/Icon_Messages.svg";
import useExperienceActionsStyles from "styles/useExperienceActionsStyles";
// custom
import LanguageSelector from "components/MultiOption/LanguageSelector";
import FilledTextField from "core/inputs/FilledTextField";
import SendToOptions from "components/Misc/SendToOptions";
import EditIconButton from "core/buttons/EditIconButton";
import DeleteIconButton from "core/buttons/DeleteIconButton";
// Utilities
import {contentTypeEnum} from "configuration/enums";
import {actionParams} from "configuration/specs";
import clsx from "clsx";
import _ from "lodash";

export default function MessageAction({
  edit,
  data,
  selectedAction,
  disableEdit,
  disableDelete,
  hideEdit,
  variant,
  editData,
  onEdit,
  onDelete,
  setIsContentValid,
  additionalObjectsVariables = [],
}) {
  const styleProps = {
    filled: variant === "filled",
    viewOnly: !!hideEdit && !!disableDelete,
  };
  const classes = useExperienceActionsStyles(styleProps);
  const defaultLanguage = useSelector((state) => state.defaultReducer.language);
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const groups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const {group_id, listing_id} = useParams();
  // State
  const [fields, setFields] = React.useState([]);
  const [content, setContent] = React.useState({});
  const [selectedLang, setSelectedLang] = React.useState(defaultLanguage);
  const [langs, setLangs] = React.useState(["en"]);
  const [customSMSDestinations, setCustomSMSDestinations] = React.useState([
    "guest",
  ]);
  const [customEmailDestinations, setCustomEmailDestinations] = React.useState([
    "guest",
  ]);
  const [useCustomDestinations, setUseCustomDestinations] = React.useState({
    SMS: false,
    email: false,
  });
  // General
  const attachments = [];
  const options = !!data.body
    ? data
    : {body: [{language: defaultLanguage, value: ""}]};
  const isNew = !!selectedAction;
  const isEmail = isNew
    ? selectedAction === "email"
    : data.action_id === "email";
  const AIListingData = !!listing_id
    ? houses[listing_id]
    : houses[groups[group_id]?.connected_to?.ids?.[0]];
  let showSendToOptions = ["SMS", "email"].includes(selectedAction);

  React.useEffect(() => {
    if (!Object.keys(content).length) {
      return;
    }
    let newOptions = {id: data.id};
    if (isEmail) {
      newOptions.subject = Object.entries(content.subject || {}).map((c) => ({
        language: c[0],
        value: c[1],
      }));
      newOptions.header = Object.entries(content.header || {}).map((c) => ({
        language: c[0],
        value: c[1],
      }));
      newOptions.body = Object.entries(content.body || {}).map((c) => ({
        language: c[0],
        value: c[1],
      }));
      newOptions.attachments = attachments;
    } else {
      newOptions.body = Object.entries(content).map((c) => ({
        language: c[0],
        value: c[1],
      }));
      newOptions.attachments = attachments;
    }

    if (selectedAction === "SMS") {
      if (!useCustomDestinations.SMS) {
        newOptions.to = customSMSDestinations.filter((cd) => cd === "guest");
      } else {
        newOptions.to = customSMSDestinations.map((cd) => {
          if (cd === "guest") {
            return cd;
          } else {
            return `+${cd}`;
          }
        });
      }
    } else if (selectedAction === "email") {
      if (!useCustomDestinations.email) {
        newOptions.to = customEmailDestinations.filter((cd) => cd === "guest");
      } else {
        newOptions.to = customEmailDestinations;
      }
    }

    editData(newOptions);
  }, [
    content,
    customSMSDestinations,
    customEmailDestinations,
    useCustomDestinations,
  ]);

  React.useEffect(() => {
    let newDestinations = data.to || ["guest"];
    if (selectedAction === "SMS") {
      if (!!newDestinations.filter((nd) => nd !== "guest").length) {
        setUseCustomDestinations((prev) => ({...prev, SMS: true}));
      }
      setCustomSMSDestinations(
        newDestinations.map((nd) =>
          nd === "guest" ? nd : nd.replace("+", ""),
        ),
      );
    } else if (selectedAction === "email") {
      if (!!newDestinations.filter((nd) => nd !== "guest").length) {
        setUseCustomDestinations((prev) => ({...prev, email: true}));
      }
      setCustomEmailDestinations(newDestinations);
    }
  }, [edit]);

  React.useEffect(() => {
    const newLangs = options?.body?.map((b) => b.language) || [];
    const newContent = {};
    const newFields = [];

    if (isEmail) {
      newFields.push({
        id: "subject",
        label: "Subject",
        placeholder: "e.g: Welcome!",
      });
      newFields.push({
        id: "header",
        label: "Header",
        placeholder: "e.g: Hi there!",
      });
      newFields.push({
        id: "body",
        label: "Body",
        placeholder: "e.g: Welcome and thanks for booking!",
      });

      if (!!options?.subject?.length) {
        _.each(options?.subject || [], (s) => {
          if (!newContent.subject) {
            newContent.subject = {[s.language]: s.value};
          } else {
            newContent.subject[s.language] = s.value;
          }
        });
      } else {
        newContent.subject = {};
      }

      if (!!options?.header?.length) {
        _.each(options?.header || [], (h) => {
          if (!newContent.header) {
            newContent.header = {[h.language]: h.value};
          } else {
            newContent.header[h.language] = h.value;
          }
        });
      } else {
        newContent.header = {};
      }

      if (!!options?.body?.length) {
        _.each(options?.body || [], (b) => {
          if (!newContent.body) {
            newContent.body = {[b.language]: b.value};
          } else {
            newContent.body[b.language] = b.value;
          }
        });
      } else {
        newContent.body = {};
      }
    } else {
      newFields.push({
        id: "body",
        label: "Message Text",
        placeholder: "e.g: Hi there!",
      });
      _.each(options?.body || [], (b) => {
        newContent[b.language] = b.value;
      });
    }

    setSelectedLang((prev) => options?.body?.[0].language || defaultLanguage);
    setLangs((prev) => newLangs);
    if (!_.isEqual(content, newContent)) {
      setContent((prev) => newContent);
    }
    if (!_.isEqual(fields, newFields)) {
      setFields((prev) => newFields);
    }
  }, [data, selectedAction]);

  const addLang = (lang) => {
    if (isEmail) {
      setContent((prev) => ({
        subject: {...prev.subject, [lang]: ""},
        header: {...prev.header, [lang]: ""},
        body: {...prev.body, [lang]: ""},
      }));
    } else {
      setContent((prev) => ({...prev, [lang]: ""}));
    }
    setLangs((prev) => [...prev, lang]);
    setSelectedLang((prev) => lang);
  };

  const removeLang = (lang) => {
    const newLangs = langs.filter((l) => l !== lang);
    setSelectedLang((prev) => (prev === lang ? newLangs[0] : prev));
    setLangs((prev) => newLangs);
    if (isEmail) {
      const newSubjects = {...content.subject};
      const newHeaders = {...content.header};
      const newBodies = {...content.body};
      delete newSubjects[lang];
      delete newHeaders[lang];
      delete newBodies[lang];
      setContent((prev) => ({
        subject: newSubjects,
        header: newHeaders,
        body: newBodies,
      }));
    } else {
      const newContent = {...content};
      delete newContent[lang];
      setContent((prev) => newContent);
    }
  };

  const handleFieldChange = (fieldID) => (e) => {
    const val = e.target.value;
    if (!!content[fieldID]) {
      setContent((prev) => ({
        ...prev,
        [fieldID]: {...prev[fieldID], [selectedLang]: val},
      }));
    } else {
      setContent((prev) => ({...prev, [selectedLang]: val}));
    }
  };

  const handleContentValidity = (value) => {
    setIsContentValid((prev) => value);
  };

  return (
    <>
      <Collapse in={edit} timeout={{appear: 0, enter: 150, exit: 150}}>
        <Paper elevation={0} className={classes.paper}>
          <div className="mt-2 mb-3">
            {showSendToOptions && (
              <SendToOptions
                type={selectedAction}
                defaultDestinations={
                  selectedAction === "SMS"
                    ? customSMSDestinations
                    : customEmailDestinations
                }
                useCustomDestinations={useCustomDestinations[selectedAction]}
                setUseCustomDestinations={(val) =>
                  setUseCustomDestinations((prev) => ({
                    ...prev,
                    [selectedAction]: val,
                  }))
                }
                setDefaultDestinations={
                  selectedAction === "SMS"
                    ? setCustomSMSDestinations
                    : setCustomEmailDestinations
                }
              />
            )}
            <div className={classes.languageSelectorContainer}>
              <LanguageSelector
                selectedLanguage={selectedLang}
                selectedLangs={langs}
                addLang={addLang}
                removeLang={removeLang}
                selectLang={setSelectedLang}
              />
            </div>
            <div className={classes.fieldContainer}>
              {fields.map((f) => (
                <Box width={"100%"} key={f.id}>
                  <FilledTextField
                    id={f.id}
                    fullWidth
                    multiline
                    useCustomFields
                    additionalObjectsVariables={additionalObjectsVariables}
                    placeholder={f.placeholder}
                    label={f.label}
                    value={
                      (!!content[f.id]
                        ? content[f.id][selectedLang]
                        : content[selectedLang]) || ""
                    }
                    onChange={handleFieldChange(f.id)}
                    AIprops={{
                      type: contentTypeEnum.experience,
                      objects: {listing: AIListingData},
                    }}
                    onValidityCheck={handleContentValidity}
                  />
                </Box>
              ))}
            </div>
          </div>
        </Paper>
      </Collapse>
      <Collapse in={!edit} timeout={{appear: 0, enter: 150, exit: 50}}>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.selectedOption}>
            <Typography
              className={clsx(
                classes.title,
                classes.flexGrow,
                classes.ellipsis,
              )}
              component="span"
            >
              <SvgIcon
                viewBox="0 0 35 35"
                className={classes.iconPreview}
                component={MessagesIcon}
              />
              <span>
                {"Send "}
                <span
                  className={clsx({
                    [classes.capitalize]: ["airbnb"].includes(data.action_id),
                  })}
                >
                  {actionParams[data.action_id]?.name ?? data.action_id}
                </span>
              </span>
              <span className={clsx("ml-1", classes.medium)}>
                {`"${options?.body[0].value}"`}
              </span>
            </Typography>
            {!hideEdit && (
              <EditIconButton
                disabled={disableEdit}
                onClick={disableEdit ? () => null : onEdit}
              />
            )}
            {!disableDelete && (
              <DeleteIconButton
                disabled={disableDelete || disableEdit}
                onClick={disableDelete ? () => null : onDelete}
              />
            )}
          </div>
        </Paper>
      </Collapse>
    </>
  );
}
