import {API} from "aws-amplify";
import qs from "qs";

export const getListingResourcePresets = async (params) => {
  try {
    const response = await API.get(
      "ensoAPI",
      `/listing/resource/presets?${params}`,
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getHouses = async (params) => {
  try {
    const response = await API.get("ensoAPI", `/listings?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getHouseContent = async (params) => {
  try {
    const response = await API.get("ensoAPI", `/listings/content?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const patchHouse = async (body) => {
  try {
    const response = await API.patch("ensoAPI", "/listings", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const postHouse = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/listings", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const postHouseRanges = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/listings/ranges", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const delHouse = async (params) => {
  try {
    const response = await API.del("ensoAPI", `/listings?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getHouseGroups = async () => {
  try {
    const response = await API.get("ensoAPI", `/listings/groups`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getHouseGroup = async (groupId, section, card) => {
  try {
    const params = qs.stringify(
      {section, card},
      {addQueryPrefix: true, skipNulls: true},
    );
    const response = await API.get(
      "ensoAPI",
      `/listings/groups/${groupId}${params}`,
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const postHouseGroup = async (body, groupId) => {
  const path = !!groupId ? `/listings/groups/${groupId}` : "/listings/groups";
  try {
    const response = await API.post("ensoAPI", path, body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const delHouseGroup = async (groupId, updateId) => {
  try {
    const response = await API.del(
      "ensoAPI",
      `/listings/groups/${groupId}${!!updateId ? `?update_id=${updateId}` : ""}`,
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const postListingResource = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/listings/resource", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const patchListingResource = async (body) => {
  try {
    const response = await API.patch("ensoAPI", "/listings/resource", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const delListingResource = async (resource, resource_id) => {
  try {
    console.log("IS");
    const response = await API.del(
      "ensoAPI",
      `/listings/resource?resource_id=${resource_id}&resource=${resource}`,
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const postListingResourceReorder = async (body) => {
  try {
    const response = await API.post(
      "ensoAPI",
      "/listings/resource/reorder",
      body,
    );
    return response;
  } catch (err) {
    throw err;
  }
};
