import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
// UI
import {makeStyles} from "@material-ui/core/styles";
import {Box, Divider, Slide, Typography} from "@material-ui/core";
// Custom
import CheckinStepList from "components/Lists/CheckinStepList";
import LanguageSelector from "components/MultiOption/LanguageSelector";
import CustomCardHeader from "core/cards/CustomCardHeader";
import WarningAlert from "core/alerts/WarningAlert";
import CloseIconButton from "core/buttons/CloseIconButton";
import CollapsibleListingsList from "../../Lists/CollapsibleListingsList";
import GJEInfoCard from "components/Cards/GJEInfoCard";
import BackButton from "core/buttons/BackButton";
// Actions
import {
  editListingGroup,
  getListingGroupDetails,
} from "redux/actions/listingsActions";
// Utils
import {addArrayItemsToSet, capitalize} from "utilities/helperFunctions";
import {FIXED_SIZES} from "configuration/settings";
import clsx from "clsx";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: "1px solid #0D568C0D",
  },
  content: {
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  settingsContainer: {padding: theme.spacing(0, 4)},
  selectorContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(4),
    gap: theme.spacing(2),
    "& > .listingName": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      textWrap: "nowrap",
      flex: 1,
    },
  },
  stepsContainer: {padding: theme.spacing(4)},
  mainContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  listingList: {flex: 1},
  divider: {backgroundColor: "rgba(13, 86, 140, 0.05)"},
}));

export default function GJECheckinSteps({onClose, disableEdit}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stopLoadingState = React.useRef({
    checkin_steps: false,
    checkout_steps: false,
  });
  const location = useLocation();
  const history = useHistory();
  const matchCardSection = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card",
  );
  const loadingGroupDetails = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_group_details;
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const defaultLang = useSelector((state) => state.defaultReducer.language);
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  const [steps, setSteps] = React.useState([]);
  const [availableLangs, setAvailableLangs] = React.useState([]);
  const [selectedLang, setSelectedLang] = React.useState(defaultLang);
  const [multiLangValues, setMultiLangValues] = React.useState({
    languages: [],
    steps: [],
  });
  const [selectedListing, setSelectedListing] = React.useState(null);
  // General
  const {groupId, section, card} = React.useMemo(() => {
    setSelectedListing((prev) => null);
    if (!matchCardSection?.isExact) {
      return {groupId: null, section: null, card: null};
    }
    return {
      groupId: matchCardSection.params.group_id,
      section: matchCardSection.params.step,
      card: matchCardSection.params.card,
    };
  }, [location]);
  const type = card === "checkin_steps" ? "check-in" : "check-out";
  let connectedListings = listingGroups[groupId]?.connected_to?.ids ?? [];
  let guestJourney = listingGroups[groupId]?.guest_journey ?? {};
  let data = guestJourney.sections?.[section]?.cards[card]?.data ?? [];
  const allListings = React.useMemo(() => {
    const allKeys = data.map((item, ind) => {
      return {
        index: ind,
        listing_id: item.listing_id,
        name: item.hasOwnProperty("name") ? item.name : null,
        hasSteps: !!item?.data?.length,
        hideIcon: !!item?.data?.length,
      };
    });

    return allKeys;
  }, [listingGroups, card]);

  const currSteps = React.useMemo(() => {
    if (!selectedListing) {
      return [];
    } else {
      let index = data.findIndex(
        (d) => d.listing_id === selectedListing.listing_id,
      );
      const newSteps =
        data[index]?.data?.map((s, i) => ({...s, index: String(i)})) ?? [];
      return newSteps;
    }
  }, [selectedListing, listingGroups, card]);

  React.useEffect(() => {
    if (!!data?.length && !loadingGroupDetails) {
      stopLoadingState.current = {...stopLoadingState.current, [card]: true};
    }
  }, [loadingGroupDetails]);

  React.useEffect(() => {
    // Get all languages from all step descriptions
    const allStepsLangs = new Set();
    _.each(currSteps, (s) => {
      _.each(s.properties.description || [], (desc) => {
        allStepsLangs.add(desc.language);
      });
    });
    setMultiLangValues((prev) => ({
      languages: [...allStepsLangs],
      steps: currSteps,
    }));
  }, [currSteps]);

  React.useEffect(() => {
    const allAvalLangs = new Set();
    addArrayItemsToSet(multiLangValues.languages, allAvalLangs);
    const newAvalLangs = [...allAvalLangs];
    setSteps((prev) => multiLangValues.steps);
    setAvailableLangs((prev) => newAvalLangs);
  }, [selectedLang, multiLangValues, currSteps]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setMultiLangValues((prev) => ({...prev, steps: steps ?? []}));
    }, 200);

    return () => clearTimeout(timer);
  }, [steps]);

  const getListingItemDescription = (item) => {
    if (loadingGroupDetails & !stopLoadingState.current[card]) {
      return "";
    }
    const stepsNumber = (data[item.index]?.data ?? []).length;
    return !item.hasSteps
      ? `Missing ${type} instructions`
      : `${stepsNumber} steps`;
  };

  const onItemClick = (newItem) => {
    setSelectedLang((prev) => defaultLang);
    setSelectedListing((prev) =>
      newItem.listing_id === prev?.listing_id ? null : newItem,
    );
  };

  const addLang = (lang) => {
    addLangToSteps(lang);
    setAvailableLangs((prev) => [...prev, lang]);
    setSelectedLang((prev) => lang);
  };

  const addLangToSteps = (lang) => {
    const newSteps = {...multiLangValues};
    const newLangSteps = multiLangValues.steps.map((s) => {
      const langExists = s.properties.description.find(
        (d) => d.language === lang && !!d.value?.trim(),
      );
      if (langExists) {
        return s;
      } else {
        return {
          ...s,
          properties: {
            ...s.properties,
            description: [
              ...s.properties.description,
              {language: lang, value: ""},
            ],
          },
        };
      }
    });
    newSteps.steps = newLangSteps;
    if (!!newSteps.languages.length && !newSteps.languages.includes(lang)) {
      newSteps.languages.push(lang);
    }
    setMultiLangValues((prev) => newSteps);
  };

  const handleStepsChange = (
    newSteps,
    updateProps = {updateAllListings: false, step: null, action: null},
  ) => {
    const newListingGroup = Object.assign({}, listingGroups[groupId]);
    let newIndex = data.findIndex(
      (d) => d.listing_id === selectedListing.listing_id,
    );
    const newData = newListingGroup.guest_journey.sections[section].cards[
      card
    ].data.map((item, index) => {
      if (newIndex === index) {
        return {...item, data: newSteps};
      } else if (updateProps.updateAllListings) {
        if (updateProps.action === "add") {
          let newItemData = [...item.data];
          if (!!updateProps.step.resource_id) {
            newItemData = newItemData.map((i) =>
              i.index === updateProps.step.index && !i.resource_id
                ? updateProps.step
                : i,
            );
            return {...item, data: newItemData};
          } else {
            return {...item, data: newItemData.concat(updateProps.step)};
          }
        } else if (updateProps.action === "update") {
          return {
            ...item,
            data: item.data.map((itemData) =>
              itemData.resource_id === updateProps.step.resource_id
                ? updateProps.step
                : itemData,
            ),
          };
        } else if (updateProps.action === "delete") {
          return {
            ...item,
            data: item.data.filter(
              (itemData) =>
                itemData.resource_id !== updateProps.step.resource_id,
            ),
          };
        }
      } else {
        return item;
      }
    });
    newListingGroup.guest_journey.sections[section].cards[card].data = newData;
    dispatch(editListingGroup(newListingGroup));
  };

  const handleUpdateGJE = () => {
    dispatch(getListingGroupDetails({groupId, section, card}));
  };

  const languageSelector = React.useMemo(() => {
    return (
      <LanguageSelector
        preventOverflow
        selectedLangs={availableLangs}
        selectedLanguage={selectedLang}
        addLang={addLang}
        selectLang={setSelectedLang}
      />
    );
  }, [currSteps, selectedLang, availableLangs]);

  function getList() {
    return (
      <CollapsibleListingsList
        rightArrow
        content={null}
        refreshDependency={steps}
        allListings={allListings}
        fixedRowHeight={FIXED_SIZES.listings_dense}
        loadingItems={loadingGroupDetails && !stopLoadingState.current[card]}
        selectedListing={selectedListing}
        connectedListings={connectedListings}
        onItemClick={onItemClick}
        getDescription={getListingItemDescription}
      />
    );
  }

  return (
    <div className={classes.container}>
      <CustomCardHeader
        title={
          !!selectedListing ? (
            <BackButton
              header={!isMobileView}
              color={!!isMobileView ? "secondary" : "primary"}
              goBack={() => setSelectedListing((prev) => null)}
            />
          ) : (
            `${capitalize(type)} instructions`
          )
        }
        className={classes.header}
        action={<CloseIconButton sm disablePadding onClick={onClose} />}
      />
      <div className={clsx(classes.container, classes.content)}>
        {!!selectedListing ? (
          <div className={classes.stepsContainer}>
            <div className={classes.selectorContainer}>
              <Typography
                variant="h2"
                className="listingName"
                style={{fontWeight: 500}}
              >
                {selectedListing?.nickname ?? selectedListing?.name}
              </Typography>
              {languageSelector}
            </div>
            <CheckinStepList
              isGroupView
              edit={!disableEdit}
              listingGroupID={groupId}
              isCheckout={card === "checkout_steps"}
              lang={selectedLang}
              data={steps}
              updateGJE={handleUpdateGJE}
              listing={houses[selectedListing?.listing_id]}
              onChange={handleStepsChange}
            />
          </div>
        ) : (
          <div className={classes.mainContent}>
            <Box>
              <GJEInfoCard
                banner
                type={type === "check-in" ? "checkin_steps" : "checkout_steps"}
              />
            </Box>
            {type === "check-in" && (
              <Box px={4} pb={4}>
                <WarningAlert
                  hide={false}
                  disableMargin
                  title="Connect smart locks"
                  subtitle="Grant access to lock codes & instant lock/unlock to your guests"
                  customAction={{
                    label: "Connect",
                    action: () => history.push("/admin/adddevices"),
                  }}
                />
              </Box>
            )}
            {type === "check-in" && <Divider className={classes.divider} />}
            <div className={classes.listingList}>{getList()}</div>
          </div>
        )}
      </div>
    </div>
  );
}
