import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isMobile} from "react-device-detect";
// UI
import {Box, Typography} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import useDashboardStyles from "styles/useDashboardStyles";
// Custom
import TimePeriodModal from "components/Dialogs/TimePeriodModal";
import SankeyChart from "components/Charts/Sankeychart";
import PrimaryButton from "core/buttons/PrimaryButton";
import InfoText from "components/Misc/InfoText";
// Redux
import {getDashboardBPStats} from "redux/actions/dashboardActions";
// Utils
import {endOfDay, startOfDay, subDays} from "date-fns";
import {onboardingContent} from "configuration/specs";
import {FIXED_SIZES} from "configuration/settings";
import infoTexts from "assets/infoTexts";
import clsx from "clsx";
const now = new Date();

const BoardingPassStatsCard = ({
  loading,
  selectedGroups = [],
  refreshDasboard,
  onRefreshCompleted,
  mobile,
  saveSelectedRange = () => {},
}) => {
  const classes = useDashboardStyles();
  const dispatch = useDispatch();
  const didMount = useRef(false);
  const didRefresh = useRef(false);
  const selectedViewItems = useSelector(
    (state) => state.defaultReducer.selected_view_items,
  );
  let savedProps = selectedViewItems.dashboard.props;
  const [localLoading, setLocalLoading] = useState(false);
  const [dateRange, setDateRange] = useState(
    !!savedProps.BPStatsRange
      ? savedProps.BPStatsRange
      : [
          new Date(startOfDay(subDays(now, 29))).getTime(),
          new Date(endOfDay(now)).getTime(),
        ],
  );
  const data = useSelector((state) => state.dashboardReducer.bpData);
  let chartData = data?.flow_pairs ?? [];
  let emptyData = !chartData.length || chartData.every((fp) => !fp.count);
  let revokedData = chartData.find((cd) => cd.to === "Revoked" && !!cd.count);

  function getData(range) {
    dispatch(
      getDashboardBPStats({
        start_date: range[0],
        end_date: range[1],
        listing_group_ids: !!selectedGroups.length ? selectedGroups : null,
        onSuccess: (res) => {
          if (!!didRefresh.current) {
            didRefresh.current = false;
            onRefreshCompleted();
          }
          setLocalLoading((prev) => false);
        },
        onError: () => {
          setLocalLoading((prev) => false);
        },
      }),
    );
  }

  useEffect(() => {
    if (!!refreshDasboard && !didRefresh.current) {
      didRefresh.current = true;
      getData(dateRange);
    }
  }, [refreshDasboard]);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      if (!!savedProps.BPStatsRange) {
        didRefresh.current = true;
        getData(dateRange);
      }
      return;
    }
    setLocalLoading((prev) => true);
    getData(dateRange);
  }, [selectedGroups]);

  const handleTimePeriodChange = (newRange, isRangeComplete) => {
    setDateRange((prev) => newRange);
    if (isRangeComplete) {
      saveSelectedRange(newRange);
      setLocalLoading((prev) => true);
      getData(newRange);
    }
  };

  const openGJEGuide = () => {
    let btn = onboardingContent.gje.buttons.find((b) => b.text.includes("use"));
    if (!btn) {
      return;
    } else {
      if (!!window.Intercom && !isMobile) {
        window.Intercom("showArticle", btn.article_id);
      }
    }
  };

  const getColor = (color, opacity = "") => `${color}${opacity}`;

  return (
    <Box
      className={clsx(classes.card, "padd", "bp", classes.flex1, {
        extraBottomSpace: !!revokedData && !!revokedData.count,
        mobile: !!mobile,
      })}
      minHeight={FIXED_SIZES.dashboard_bp_card}
      flex="auto !important"
    >
      <div className={clsx(classes.row, classes.cardHeader)}>
        <Box className={clsx(classes.row, classes.gap12)} alignItems="center">
          <Typography variant="h2" className={classes.cardTitle}>
            {"Boarding Pass Verification"}
          </Typography>
          <InfoText disableGutters text={infoTexts.bpProgress} subdued />
        </Box>
        <TimePeriodModal
          past
          disableBtn={loading || localLoading}
          selectedRange={dateRange}
          onChange={handleTimePeriodChange}
        />
      </div>
      {loading || localLoading ? (
        <Box className={clsx(classes.fullHeight, classes.fullWidth)} mt={2}>
          <Skeleton
            animation="wave"
            width="100%"
            height={!!mobile ? 450 : "100%"}
            className={classes.skeleton}
          />
        </Box>
      ) : (
        <Box
          flex={!!mobile ? "auto" : 1}
          mt={!!mobile ? 0 : 1}
          mr={!mobile ? 0 : 1}
          className={clsx({[classes.verticalChart]: !!mobile && !emptyData})}
          style={{overflow: "hidden"}}
        >
          {!emptyData ? (
            <SankeyChart
              data={chartData}
              getColor={getColor}
              vertical={!!mobile}
              displayingRevoked={
                !emptyData && !!revokedData && !!revokedData.count
              }
            />
          ) : (
            <Box
              width={"100%"}
              height={"100%"}
              className={classes.emptyDataContent}
            >
              <Typography variant="h2" className={classes.noDataTitle}>
                {"No Boarding Pass data"}
              </Typography>
              <Typography className={classes.noDataInfo}>
                {
                  "In order to collect Boarding pass data you must have a Boarding Pass set up and active for a listing."
                }
              </Typography>
              {!isMobile && (
                <PrimaryButton
                  color="secondary"
                  label={"Create your Guest Journey"}
                  onClick={openGJEGuide}
                />
              )}
            </Box>
          )}
          {!emptyData && !!revokedData && !!revokedData.count && (
            <Box
              height={!!mobile ? 120 : 18}
              pl={!!mobile ? 1 : "10px"}
              pt={!!mobile ? "10px" : 0}
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
            >
              <Box className={clsx(classes.revokedContainer, {mobile: mobile})}>
                <Typography variant="caption" className={classes.revokedLabel}>
                  {`${!!mobile ? "" : "Revoked"} ${revokedData.count}`}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default BoardingPassStatsCard;
