import React from "react";
import {CardHeader} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    padding: theme.spacing(4),
    "&.empty": {padding: 0},
  },
  actions: {
    alignSelf: "flex-end",
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: 0,
    marginRight: 0,
    gap: "10px",
  },

  bold: {fontWeight: 500},
}));

/**
 *
 * @param {string} type "header" | "title" | "text". "header" for variant h6.
 * "title" for variant h1. "text" for variant body1
 * @param {string} variant Specific variant
 * @returns
 */
export default function CustomCardHeader({
  title,
  subheader,
  className,
  classes,
  action,
  type = "header",
  variant,
}) {
  const styles = useStyles();
  const propValues = getProps();

  function getProps() {
    switch (type) {
      case "header":
        return {
          variant: variant ?? "h6",
          root: styles.cardHeader,
        };
      case "title":
        return {
          variant: variant ?? "h2",
          color: "primary",
        };
      case "text":
        return {
          variant: variant ?? "body1",
          color: "secondary",
          titleStyle: styles.bold,
        };
      default:
        return {};
    }
  }

  return (
    <CardHeader
      id="list-header-text"
      title={title}
      titleTypographyProps={{
        variant: propValues.variant,
        color: propValues.color,
        className: propValues.titleStyle,
      }}
      subheader={subheader}
      action={action}
      className={className}
      classes={{
        root: clsx(propValues.root, {empty: !title}),
        action: !!action ? styles.actions : undefined,
        ...classes,
      }}
    />
  );
}
