import React from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
import mapBg from "assets/img/bgMap.png";
import {SUPPORT_EMAIL} from "configuration/settings";
import {
  setErrorPathBlocked,
  setNavbarVisibility,
} from "redux/actions/settingsActions";

const redirectPaths = [
  {label: "Dashboard", path: "/admin/dashboard"},
  {label: "Listings", path: "/admin/listings"},
  {label: "Messages", path: "/admin/messages"},
  {label: "CRM", path: "/admin/guests"},
  {label: "Business settings", path: "/admin/settings"},
];

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    backgroundImage: `url(${mapBg})`,
    backgroundSize: "cover",
    backgroundColor: "#F0F1EA",
  },
  card: {
    minWidth: 250,
    maxWidth: "80%",
    maxHeight: "80%",
    backgroundColor: "#FFF",
    boxShadow: "0px 2px 4px rgba(28, 41, 90, 0.0392157)",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
  },
  cardContent: {
    flexGrow: 1,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    padding: "0 40px 40px !important",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 96,
    lineHeight: "130px",
    fontWeight: 700,
    marginTop: -10,
    marginBottom: 30,
  },
  grid: {padding: theme.spacing(5, 0)},
  gridItem: {display: "inline-flex"},
  link: {
    fontWeight: 600,
    textAlign: "left",
    color: theme.palette.secondary.main + "!important",
    marginBottom: theme.spacing(2),
    cursor: "pointer",
  },
  btn: {
    fontSize: 19,
    backgroundColor: "#FFF !important",
    textTransform: "none",
    padding: theme.spacing(0, 1),
    marginTop: -5,
    "&:hover": {textDecoration: "underline"},
  },
  supportEmail: {color: theme.palette.secondary.main + "!important"},
}));

export default function ErrorPage({}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const hideNavbar = useSelector((state) => state.defaultReducer.hideNavbar);

  React.useEffect(() => {
    dispatch(setErrorPathBlocked(true));
    return () => {
      dispatch(setNavbarVisibility(true));
    };
  }, []);

  React.useEffect(() => {
    if (!hideNavbar) {
      dispatch(setNavbarVisibility(false));
    }
  }, [hideNavbar]);

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography align="center" className={classes.title} component="h1">
            {"Oops!"}
          </Typography>
          <Typography variant="h2" component="span">
            {"Something went wrong, you can "}
            <Button
              variant="text"
              color="secondary"
              disableElevation
              disableRipple
              className={classes.btn}
              onClick={() => history.goBack()}
            >
              refresh
            </Button>
            {" the page or navigate to the following sections:"}
          </Typography>
          <Grid container className={classes.grid}>
            {redirectPaths.map((rp) => (
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.gridItem}
                key={rp.path}
              >
                <Link
                  variant="h1"
                  className={classes.link}
                  onClick={() => history.replace(rp.path)}
                >
                  • {rp.label}
                </Link>
              </Grid>
            ))}
          </Grid>
          <Typography variant="h2">
            {"If the problem persists please contact "}
            <a
              href={`mailto:${SUPPORT_EMAIL}`}
              className={classes.supportEmail}
            >
              {SUPPORT_EMAIL}
            </a>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
