import React, {useEffect, useState} from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Grid,
} from "@material-ui/core";
import {setUserType} from "utilities/userScopeUtilities";
import {THEME} from "configuration/settings";
import {userTypes} from "configuration/constants";

export default function UserTypePanel({
  setUserTypeSelected,
  setScopedGroupsEnabled,
  setSelectedTab,
  setUserInfo,
}) {
  // assign proper permissions based on what is clicked
  const handleOnClick = (tileKey) => {
    if (tileKey === "cohost") {
      // if not scoped to a listing group, switch to that tab and enable scoped
      setScopedGroupsEnabled(true);
      setSelectedTab(2);
    }

    setUserType(tileKey, setUserInfo);

    setUserTypeSelected(true);
  };

  const tileStyle = () => ({
    width: "100%",
    height: "150px",
    backgroundColor: "#f5f5f5", // Change background color here
    textAlign: "center",
    border: "none", // Remove the border
  });

  const textStyle = {
    paddingTop: THEME.spacing.sm, // Adjust spacing between circle and text
  };

  return (
    <div style={{paddingLeft: THEME.spacing.lg}}>
      {" "}
      {/* Add padding here */}
      <Typography variant="h1">User Type</Typography>
      <Grid
        container
        spacing={2}
        style={{marginTop: THEME.spacing.xs, marginBottom: THEME.spacing.xs}}
      >
        {Object.keys(userTypes).map((ut) => (
          <Grid
            key={ut}
            item
            xs={12}
            sm={6}
            style={{marginBottom: THEME.spacing.xs}}
          >
            <Card style={tileStyle(1)} onClick={() => handleOnClick(ut)}>
              <CardActionArea style={{height: "100%", width: "100%"}}>
                <CardContent>
                  <img
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(userTypes[ut].icon)}`}
                  />
                  <div style={textStyle}>
                    <Typography variant="h1">
                      {userTypes[ut].displayName}
                    </Typography>
                    <Typography>{userTypes[ut].description}</Typography>
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
