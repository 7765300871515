import React from "react";
import Box from "@material-ui/core/Box";
import useThreePanelStyles from "styles/useThreePanelStyles";
import clsx from "clsx";

export default function Panel1({
  content,
  hasContentAbove,
  allRounded,
  bg = "#FFFFFF",
}) {
  const classes = useThreePanelStyles({bg});

  return (
    <Box
      className={clsx(classes.basePanel, classes.panel1, {
        "-rounded": !!allRounded,
      })}
      style={{borderTopLeftRadius: hasContentAbove ? 0 : 10}}
    >
      {content}
    </Box>
  );
}
