import React from "react";
import {Divider, Tab, Tabs, makeStyles} from "@material-ui/core";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    marginBottom: (props) =>
      !!props.disableBottomSpacing ? 0 : theme.spacing(2),
  },
  tabs: {
    minHeight: 30,
    padding: (props) => (!!props.disableGutters ? 0 : theme.spacing(0, 5)),
  },
  tab: {
    minWidth: 100,
    minHeight: 30,
    padding: 0,
    marginRight: theme.spacing(3),
  },
  labelContainer: {
    padding: 0,
    textTransform: "uppercase",
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 600,
  },
  divider: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    backgroundColor: "rgba(13, 86, 140, 0.05)",
    height: 2,
    marginLeft: (props) => (!!props.disableGutters ? 0 : theme.spacing(5)),
    width: (props) =>
      `calc(100% - ${!!props.disableGutters ? 0 : theme.spacing(6)}px)`,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  gap8: {gap: theme.spacing(2)},
  num: {
    width: 20,
    height: 20,
    borderRadius: 360,
    padding: theme.spacing(2),
    backgroundColor: THEME.subdued,
    color: theme.palette.common.white,
  },
  numActive: {backgroundColor: theme.palette.secondary.main},
}));

export default function CustomTabs({
  selectedTab,
  onChange,
  tabs = [],
  disabled,
  disableGutters,
  disableBottomSpacing,
}) {
  const classes = useStyles({disableGutters, disableBottomSpacing});

  const getLabel = (text, i) => {
    if (!text.includes("-")) {
      return text;
    } else {
      let itemArr = text.split("-");
      let label = itemArr[0];
      let num = itemArr[1];
      return (
        <div className={clsx(classes.row, classes.gap8)}>
          {label}
          <div
            className={clsx(classes.row, classes.num, {
              [classes.numActive]: selectedTab == i,
            })}
          >
            {num}
          </div>
        </div>
      );
    }
  };

  return (
    <div className={classes.container}>
      <Tabs
        value={selectedTab}
        indicatorColor="secondary"
        textColor="secondary"
        variant="scrollable"
        scrollButtons="off"
        classes={{root: classes.tabs}}
        onChange={(e, newval) => onChange(newval)}
      >
        {tabs.map((item, i) => {
          return (
            <Tab
              key={item}
              disabled={disabled}
              classes={{
                root: classes.tab,
                wrapper: classes.labelContainer,
              }}
              label={getLabel(item, i)}
              id={`btn-${item}`}
            />
          );
        })}
      </Tabs>
      <Divider flexItem orientation="horizontal" className={classes.divider} />
    </div>
  );
}
