import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {
  makeStyles,
  Typography,
  Divider,
  ListItemText,
  List,
  ListItem,
  ListItemIcon,
  Avatar,
  Chip,
  Box,
  Tooltip,
} from "@material-ui/core";
// Actions
import {openGeneralError} from "redux/actions/settingsActions";
import {createAction} from "redux/actions/experiencesActions";
import {editHouse} from "redux/actions/listingsActions";
import {getHouseContent} from "redux/api/listingsAPI";
// Custom
import ListingList from "components/Lists/ListingList";
import CustomDialog from "core/dialogs/CustomDialog";
import SearchBar from "core/bars/SearchBar";
// Utilities
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  listSections: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "space-between",
    overflow: "hidden",
  },
  list: {
    overflowY: "auto",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "48%",
  },
  divider: {backgroundColor: "rgba(13, 86, 140, 0.05)"},
  selected: {backgroundColor: theme.palette.secondary.main + "20!important"},
  btn: {
    alignSelf: "flex-end",
    marginTop: theme.spacing(2),
  },
  itemTextRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  chip: {
    maxWidth: 150,
    backgroundColor: "rgba(13, 86, 140, 0.75)",
  },
  infoText: {
    fontWeight: 400,
    marginTop: theme.spacing(4),
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
  },

  checkbox: {
    width: "fit-content",
    height: "fit-content",
    alignSelf: "center",
  },
  title: {
    WebkitLineClamp: 2,
    fontWeight: 500,
    fontSize: 15,
    lineHeight: "20px",
    color: "#000",
  },
  subtitle: {
    WebkitLineClamp: 3,
    fontSize: 13,
    color: "rgba(60, 60, 67, 0.6)",
  },
  itemIcon: {
    marginRight: theme.spacing(3),
    height: "100%",
  },
  img: {
    width: 85,
    height: "100%",
    objectFit: "cover",
    borderRadius: 7,
    backgroundColor: theme.palette.grey[300],
  },
  multiListItem: {
    height: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    cursor: (props) => (!!props.disableSelect ? "default" : "pointer"),
  },
  multiselected: {
    backgroundColor: `${theme.palette.secondary.main}0d !important`,
  },
  infoBox: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  listContainer: {
    flexGrow: 1,
    overflow: "auto",
  },
}));

export default function MatchListingsListings({account, open, onClose}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedPMSListing, setSelectedPMSListing] = useState(null);
  const [selectedThirdPartyListing, setSelectedThirdPartyListing] =
    useState(null);

  const houseArray = useSelector((state) => state.defaultReducer.house_data);
  const [listingMap, setListingMap] = useState({});
  const [loadingPairedState, setLoadingPairedState] = useState(false);
  const [loadedPairedMap, setLoadedPairedMap] = useState({});
  const [listingMapOutdated, setListingMapOutdated] = useState(false);

  const syncListingPairedState = async () => {
    setLoadingPairedState(true);
    for (let listing of houseArray) {
      let listing_content = listing.listing_content;

      if (!listing_content) {
        let response = await getHouseContent(
          `listing_id=${listing.listing_id}`,
        );
        listing_content = response.listing_content;

        listing.listing_content = response.listing_content;
        dispatch(editHouse(listing));
      }

      let ab_id = listing_content?.custom?.find(
        (cf) => cf?.properties?.name == "airbnb_listing_id",
      )?.properties?.value;

      if (!!ab_id) {
        setListingMap(
          Object.assign(listingMap, {
            [listing.listing_id]: ab_id,
          }),
        );
      }

      setLoadedPairedMap(
        Object.assign(loadedPairedMap, {
          [listing.listing_id]: true,
        }),
      );
    }
    setLoadingPairedState(false);
  };

  useEffect(() => {
    if (account.integration_type == "abnb") {
      if (!loadingPairedState) {
        syncListingPairedState();
      } else {
        setListingMapOutdated(true);
      }
    }
  }, [houseArray]);

  useEffect(() => {
    if (!loadingPairedState && listingMapOutdated) {
      syncListingPairedState();
      setListingMapOutdated(false);
    }
  }, [loadingPairedState]);

  useEffect(() => {
    setSelectedThirdPartyListing(listingMap?.[selectedPMSListing?.listing_id]);
  }, [selectedPMSListing, listingMap]);

  useEffect(() => {
    if (!!selectedPMSListing && !!selectedThirdPartyListing) {
      setListingMap((lm) => ({
        ...Object.fromEntries(
          Object.entries(lm).filter(
            ([_, ab_id]) => ab_id != selectedThirdPartyListing,
          ),
        ),
        [selectedPMSListing?.listing_id]: selectedThirdPartyListing,
      }));
    }
  }, [selectedThirdPartyListing]);

  const PMSListingsList = (
    <ListingList
      singleSelect
      disableGutters
      allowLongNames
      isInsideModal
      selectHouse={setSelectedPMSListing}
      selected={selectedPMSListing?.listing_id}
      customActions={<></>}
      listingMap={listingMap}
      account={account}
      hideTitle
      loadedPairedMap={loadedPairedMap}
      getDisabled={(house) => !loadedPairedMap?.[house.listing_id]}
    />
  );

  const handlePair = (ab_id, status) => {
    setLoading(true);
    let newPairMap = {
      [selectedPMSListing?.listing_id]: status === "Pair" ? ab_id : "",
    };

    dispatch(
      createAction({
        action: {
          action_id: "pms_map_listings",
          listing_id_map: newPairMap,
        },
        integrationHostId: account?.integration_host_id,
        integrationType: account?.integration_type,
        onSuccess: () => {
          if (status === "Pair") {
            setSelectedThirdPartyListing(ab_id);
          } else if (status === "Unpair") {
            setListingMap((lm) => ({
              ...Object.fromEntries(
                Object.entries(lm).filter(([_, id]) => id !== ab_id),
              ),
            }));
            setSelectedThirdPartyListing(null);
          }
          setLoading(false);
        },
        onError: () => {
          dispatch(openGeneralError("FAILED TO PAIR LISTINGS"));
          setLoading(false);
        },
      }),
    );
  };

  const thirdPartyListingsList = (
    <Box
      height={"100%"}
      overflow={"hidden"}
      display={"flex"}
      flexDirection={"column"}
    >
      <SearchBar
        disableGutters
        searchInput={searchText}
        handleSearchInput={(val) => setSearchText((prev) => val)}
      />
      <Box flex={1} overflow={"auto"}>
        <List className={classes.list}>
          {houseArray
            .filter((house) => {
              const has_id = !!house?.listing_content?.custom?.find(
                (cf) => cf?.properties?.name == "airbnb_listing_id",
              );
              const ab_host_id = house?.listing_content?.custom?.find(
                (cf) => cf?.properties?.name == "Airbnb integration host id",
              )?.properties?.value;
              const houseName = (
                house?.listing_content?.custom?.find(
                  (cf) => cf?.properties?.name === "Airbnb listing name",
                )?.properties?.value ??
                house?.name ??
                ""
              ).toLowerCase();
              const matchesSearch = !searchText.trim()
                ? true
                : houseName.includes(searchText.toLowerCase());
              return (
                matchesSearch &&
                has_id &&
                ab_host_id === account?.integration_host_id
              );
            })
            .map((listing_data) => {
              const cfs = listing_data?.listing_content?.custom;
              const ab_id = cfs?.find(
                (cf) => cf?.properties?.name === "airbnb_listing_id",
              )?.properties?.value;
              const ab_name = cfs?.find(
                (cf) => cf?.properties?.name === "Airbnb listing name",
              )?.properties?.value;
              const ab_pic = cfs?.find(
                (cf) => cf?.properties?.name === "Airbnb listing picture",
              )?.properties?.value;
              const ab_addr = cfs?.find(
                (cf) => cf?.properties?.name === "Airbnb listing address",
              )?.properties?.value;
              return (
                <ListItem
                  button
                  disableRipple
                  dense={false}
                  divider
                  className={classes.multiListItem}
                  onClick={() =>
                    !!ab_id && selectedThirdPartyListing === ab_id
                      ? handlePair(ab_id, "Unpair")
                      : handlePair(ab_id, "Pair")
                  }
                  disabled={loading}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <Avatar className={classes.img} src={ab_pic}>
                      {""}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title={ab_name}>
                        <Typography
                          variant="h1"
                          className={clsx(classes.ellipsis, classes.title)}
                        >
                          {ab_name}
                        </Typography>
                      </Tooltip>
                    }
                    disableTypography
                    secondary={
                      <Typography
                        className={clsx(classes.ellipsis, classes.subtitle)}
                      >
                        {ab_addr}
                      </Typography>
                    }
                  />
                  <Chip
                    size="small"
                    color="primary"
                    label={
                      !!ab_id && selectedThirdPartyListing === ab_id
                        ? "Unpair"
                        : "Pair"
                    }
                    className={classes.chip}
                    onClick={() => {}}
                  />
                </ListItem>
              );
            })}
          {account?.unmapped_listings?.map((unmapped_listing_data) => {
            const matchesSearch = !searchText.trim()
              ? true
              : (unmapped_listing_data?.name ?? "")
                  .toLowerCase()
                  .includes(searchText.toLowerCase());
            if (!matchesSearch) {
              return null;
            }
            return (
              <ListItem
                button
                disableRipple
                dense={false}
                divider
                className={classes.multiListItem}
                onClick={() =>
                  !!selectedThirdPartyListing &&
                  selectedThirdPartyListing === unmapped_listing_data?.id
                    ? handlePair(unmapped_listing_data?.id, "Unpair")
                    : handlePair(unmapped_listing_data?.id, "Pair")
                }
                disabled={loading}
              >
                <ListItemIcon className={classes.itemIcon}>
                  <Avatar
                    className={classes.img}
                    src={unmapped_listing_data?.picture}
                  >
                    {""}
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Tooltip title={unmapped_listing_data?.name}>
                      <Typography
                        variant="h1"
                        className={clsx(classes.ellipsis, classes.title)}
                      >
                        {unmapped_listing_data?.name}
                      </Typography>
                    </Tooltip>
                  }
                  disableTypography
                  secondary={
                    <Typography
                      className={clsx(classes.ellipsis, classes.subtitle)}
                    >
                      {unmapped_listing_data?.address}
                    </Typography>
                  }
                />
                <Chip
                  size="small"
                  color="primary"
                  label={
                    !!selectedThirdPartyListing &&
                    selectedThirdPartyListing === unmapped_listing_data?.id
                      ? "Unpair"
                      : "Pair"
                  }
                  disabled={loading}
                  className={classes.chip}
                  onClick={() => {}}
                />
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  );

  const content = (
    <div className={classes.root}>
      <Typography className={classes.infoBox}>
        Please associate any unpaired listings with your Airbnb account in order
        to access Airbnb functionality.
      </Typography>
      <div className={classes.listSections}>
        <div className={classes.column}>
          <Typography variant="h2" color="primary">
            Your Listings
          </Typography>
          <div className={classes.listContainer}>{PMSListingsList}</div>
        </div>
        <Divider orientation="vertical" className={classes.divider} />
        <div className={classes.column}>
          <Typography variant="h2" color="primary">
            Airbnb Listings
          </Typography>
          <div className={classes.listContainer}>
            {!!selectedPMSListing ? (
              thirdPartyListingsList
            ) : (
              <Typography
                variant="h1"
                align="center"
                color="textSecondary"
                className={classes.infoText}
              >
                Select a listing to get started
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <CustomDialog
      maxWidth="sm"
      customHeight="100%"
      open={open}
      title="Pair Listings"
      onClose={onClose}
      content={content}
      fullWidth
    />
  );
}
