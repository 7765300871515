import React from "react";
// UI
import {makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  statusRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "fit-content",
    position: "absolute",
    top: theme.spacing(4) + theme.spacing(3),
    right: theme.spacing(4) + theme.spacing(3),
    "&.-right": {justifyContent: "flex-end"},
  },
  statusDot: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: theme.palette.grey[400],
    marginRight: theme.spacing(2),
    "&.-connected": {backgroundColor: "#00A740"},
    "&.-disconnected": {backgroundColor: "#fc0303"},
    "&.-unpaired": {backgroundColor: "#fc6f03"},
    "&.-paired": {backgroundColor: theme.palette.secondary.main},
  },
  statusText: {
    color: theme.palette.grey[500],
    "&.-connected": {color: "#00A740"},
    "&.-disconnected": {color: "#fc0303"},
    "&.-unpaired": {color: "#fc6f03"},
    "&.-paired": {color: theme.palette.secondary.main},
  },
}));

export default function DeviceStatus({
  device,
  showFullMessage = true,
  showPairedUnpairedOnly = false,
}) {
  const classes = useStyles();
  const deviceStatus =
    showFullMessage && device?.hasOwnProperty("connected_message")
      ? device.connected_message
      : device?.connected
        ? "Device Online"
        : "Device Offline";

  if (!device) {
    return null;
  }
  return (
    <div className={clsx(classes.statusRow, {"-right": !showFullMessage})}>
      <div
        className={clsx(classes.statusDot, {
          "-connected": !!device.connected,
          "-disconnected": !device.connected,
          "-unpaired": !device.paired,
          "-paired": !!device.paired && showPairedUnpairedOnly,
        })}
      />
      <Typography
        className={clsx(classes.statusText, {
          "-connected": !!device.connected,
          "-disconnected": !device.connected,
          "-unpaired": !device.paired,
          "-paired": !!device.paired && showPairedUnpairedOnly,
        })}
      >
        {device.paired
          ? showPairedUnpairedOnly
            ? "Paired"
            : deviceStatus
          : "Not Paired"}
      </Typography>
    </div>
  );
}
