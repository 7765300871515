import React from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {THEME} from "configuration/settings";
import FilledTextField from "core/inputs/FilledTextField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "14.32px",
    letterSpacing: "0.05em",
    color: THEME.grey,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    width: "100%",
    wordBreak: "break-all",
  },
  fieldContainer: {
    width: "100%",
    height: "100%",
  },
}));

export default function NumberBookings({
  edit,
  value,
  onValueChange,
  bookings = [],
  inquiries = [],
  showEmptyInquiries,
}) {
  const classes = useStyles();

  if (edit) {
    return (
      <div className={classes.fieldContainer}>
        <FilledTextField
          fullWidth
          label="# of bookings"
          value={value}
          type="number"
          inputProps={{min: 0}}
          placeholder={"3"}
          onChange={(e) => onValueChange(e.target.value)}
        />
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <Typography className={classes.text}>
          {bookings.length} booking{bookings.length === 1 ? "" : "s"}
        </Typography>
        {(!!inquiries.length || !!showEmptyInquiries) && (
          <Typography className={classes.text}>
            {inquiries.length} inquir{inquiries.length === 1 ? "y" : "ies"}
          </Typography>
        )}
      </div>
    );
  }
}
