import BaseFieldComponent from "../BaseFieldComponent";
import {useSelector} from "react-redux";

class AssignedToField extends BaseFieldComponent {
  renderText({value}) {
    const userProfile = useSelector(
      (state) => state.defaultReducer.user_profile,
    );
    const users = userProfile.enso_users || [];

    return users.find((u) => u.enso_user_id === value)?.name || value;
  }
}

export default AssignedToField;
