import React, {useRef} from "react";
import {Box, makeStyles} from "@material-ui/core";
import HorizontalCard from "components/keycard/HorizontalCard";
import DescriptionCard from "components/keycard/DescriptionCard";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 0,
    paddingTop: 12,
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: theme.spacing(1.5),
  },
}));

const GuidebooksGrid = ({guides, onClick}) => {
  const classes = useStyles();
  const nextLargeCardIndex = useRef(0);

  const openGuide = (guideIndex) => {
    onClick(guideIndex);
  };

  return (
    <div className={classes.container}>
      <Box className={classes.grid}>
        {guides.map((gb, i) => {
          if (
            i === 0 ||
            i === nextLargeCardIndex.current ||
            (i === guides.length - 1 &&
              (nextLargeCardIndex.current - (guides.length - 1)) % 2 === 0)
          ) {
            nextLargeCardIndex.current = i + 5;
            return (
              <HorizontalCard
                key={`gb-${i}`}
                resource={gb}
                onClick={() => openGuide(gb.id)}
              />
            );
          } else {
            return (
              <DescriptionCard
                key={`gb-${i}`}
                resource={gb}
                onClick={() => openGuide(gb.id)}
              />
            );
          }
        })}
      </Box>
    </div>
  );
};

export default GuidebooksGrid;
