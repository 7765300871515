import React from "react";
import {useDispatch} from "react-redux";
import {isFirefox} from "react-device-detect";
// UI
import {
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  lighten,
  makeStyles,
  OutlinedInput,
  TextField,
  Tooltip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import FilterIcon from "@material-ui/icons/FilterList";
import DeleteIcon from "@material-ui/icons/Close";
// Custom
import FilterMenus from "components/Dialogs/FilterMenus";
import {SelectedFilterList} from "components/Helpers/filterHelpers";
// Helpers & Utilities
import {specialFilters} from "configuration/constants";
import {fieldParams} from "configuration/specs";
import {isSameDay} from "date-fns";
import clsx from "clsx";
// Actions
import {getCustomFields} from "redux/actions/settingsActions";

const useStyles = makeStyles((theme) => ({
  input: {
    "&::placeholder": {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "14px",
      color: "rgba(60,60,67,0.6)",
      opacity: 1,
      paddingTop: 2,
    },
  },
  searchContainer: {
    display: "flex",
    paddingLeft: (props) => (props.disableGutters ? 0 : theme.spacing(4)),
    paddingRight: (props) => (props.disableGutters ? 0 : theme.spacing(4)),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  outlineInput: {
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(3),
    "&:hover": {backgroundColor: theme.palette.grey[100]},
  },
  outlineInputFilters: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  searchInput: {
    padding: theme.spacing(1, 0),
    height: "auto !important",
  },
  clearIcon: {
    cursor: "pointer",
    fontSize: theme.typography.body1.fontSize,
  },
  outline: {
    borderColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius * 2,
    border: "none!important",
  },
  filtersContainer: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    lineHeight: "25px",
  },
  rounded: {borderRadius: 999},
  chip: {
    backgroundColor: `${lighten(theme.palette.primary.light, 0.7)}!important`,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    margin: 0,
    marginLeft: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
    height: "auto",
    maxWidth: isFirefox ? "-moz-available" : "-webkit-fill-available",
  },
  noBottomPadding: {paddingBottom: 0},
  chipLabel: {
    display: "flex",
    alignItems: "baseline",
  },
  chipDeleteIcon: {
    color: theme.palette.primary.main,
    "&:hover": {color: lighten(theme.palette.primary.main, 0.4)},
  },
  chipInput: {
    marginLeft: theme.spacing(1),
    "& > div": {
      "&:before": {border: "none!important"},
      "&:after": {border: "none!important"},
      "& > input": {
        color: theme.palette.primary.main,
        fontSize: theme.typography.body1.fontSize,
        padding: 0,
        height: "100%",
      },
    },
  },
  greyColor: {color: "rgba(60,60,67,0.6)"},
  shrink: {width: 130},
  short: {width: 235},
}));

export default function SearchBar({
  searchInput,
  enableFilters,
  filters = [],
  filterKeys,
  handleSearchInput,
  setFilters,
  handleDeleteFilter,
  disabled = false,
  disableGutters,
  rounded,
  disableSelectedFilters,
  showWriteFilter,
  shrink,
  short,
  onNewWriteFilter,
  placeholder = "Search",
  preferredFilters,
  hiddenFilters = [],
  stopPropagation,
  noChipMargin,
  isInsideModal,
}) {
  const dispatch = useDispatch();
  // Ref
  const filtersAnchorRef = React.useRef(null);
  // Styles
  const styleProps = {disableGutters};
  const classes = useStyles(styleProps);
  const searchBarWidth = filtersAnchorRef.current?.clientWidth;
  // State
  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null);
  const [showOptionsMenu, setShowOptionsMenu] = React.useState(false);
  const [newWriteFilter, setNewWriteFilter] = React.useState({
    show: false,
    val: "",
  });
  const [searchText, setSearchText] = React.useState(searchInput || "");
  const [enterKeyPressed, setEnterKeyPressed] = React.useState(false);
  const [filtersAnchorEl, setFiltersAnchorEl] = React.useState(null);
  const [selectedFilter, setSelectedFilter] = React.useState(null);
  const [focus, setFocus] = React.useState(false);
  const [customFields, setCustomFields] = React.useState({});
  // General
  const filtersFixedWidth = searchBarWidth - 34;
  let filtersToShow = filters.filter((f) => !hiddenFilters.includes(f.path));
  let isShowingFilters =
    !!enableFilters &&
    !disableSelectedFilters &&
    (filtersToShow.length > 0 || newWriteFilter.show);

  React.useEffect(() => {
    if (searchInput === searchText) return;
    setSearchText((prev) => searchInput);
  }, [searchInput]);

  React.useEffect(() => {
    if (!optionsAnchorEl) return;
    const timer = setTimeout(() => {
      setShowOptionsMenu(true);
    }, 300);
    return () => clearTimeout(timer);
  }, [optionsAnchorEl]);

  React.useEffect(() => {
    if (showOptionsMenu) return;
    const timer = setTimeout(() => {
      handleOptionsClose();
    }, 500);
    return () => clearTimeout(timer);
  }, [showOptionsMenu]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (searchInput !== searchText) {
        handleSearchInput(searchText);
      }
    }, 150);
    return () => clearTimeout(timer);
  }, [searchText]);

  React.useEffect(() => {
    if (!!newWriteFilter.show && !!selectedFilter) {
      !!showWriteFilter && showWriteFilter(selectedFilter);
    }
  }, [newWriteFilter, selectedFilter]);

  const openFiltersMenu = (e) => setFiltersAnchorEl(e.currentTarget);
  const closeFiltersMenu = () => setFiltersAnchorEl(null);
  const onFilterSelect = (selectedFilter, isDateRange) => {
    closeFiltersMenu();
    if (selectedFilter.type === "select") {
      if (isDateRange) {
        const newRangeFilter = {
          id: selectedFilter.id,
          path: selectedFilter.path,
          type: "range",
          value: isSameDay(selectedFilter.value[0], selectedFilter.value[1])
            ? selectedFilter.value[0]
            : [
                new Date(selectedFilter.value[0]).setHours(0, 0, 0, 0),
                new Date(selectedFilter.value[1]).setHours(23, 59, 59, 999),
              ],
        };

        const newfilters = filters.filter(
          (f) => f.path !== selectedFilter.path,
        );
        newfilters.push(newRangeFilter);
        setFilters(newfilters);
      } else {
        const existingFilter = filters.find(
          (f) =>
            f.path === selectedFilter.path && f.value === selectedFilter.value,
        );
        if (!existingFilter) {
          setFilters([...filters, selectedFilter]);
        }
      }
    } else if (selectedFilter.type === "value") {
      let filterPaths = specialFilters[selectedFilter.id].filters.map(
        (f) => f.path,
      );
      const newfilters = filters.filter((f) => !filterPaths.includes(f.path));
      newfilters.push(...specialFilters[selectedFilter.id].filters);
      setFilters(newfilters);
    } else {
      onNewWriteFilter && onNewWriteFilter(selectedFilter);
      if (newWriteFilter.show) {
        return;
      }
      setSelectedFilter(selectedFilter.id);
      setNewWriteFilter({show: true, val: ""});
    }
  };

  const handleOnFocus = () => {
    !!shrink && setFocus((prev) => true);
  };
  const handleOnBlur = () => {
    !!shrink && setFocus((prev) => false);
  };

  const handleKeyDown = (e) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (e.key === "Enter") setEnterKeyPressed(() => true);
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter" && enterKeyPressed) setEnterKeyPressed(() => false);
  };

  const handleSearchChange = (e) => {
    if (enterKeyPressed) return;
    let val = e.target.value;
    setSearchText((prev) => val);
  };

  const onWriteFilterKeyDown = (event) => {
    if (event.key === "Escape") {
      closeWriteFilter();
      return false;
    } else if (!["Enter", "Tab"].includes(event.key)) {
      return false;
    }
    saveWriteFilter();
  };

  const onWriteFilterChange = (e) => {
    e.persist();
    setNewWriteFilter((prev) => ({...prev, val: e.target.value}));
  };

  const saveWriteFilter = () => {
    if (!newWriteFilter.val.trim()) {
      closeWriteFilter();
    }
    const existingFilter = filters.find(
      (f) => f.path === selectedFilter && f.value === newWriteFilter.val,
    );
    if (!existingFilter) {
      setFilters([
        ...filters,
        {
          id: selectedFilter,
          path: selectedFilter,
          value: newWriteFilter.val.trim(),
        },
      ]);
    }
    closeWriteFilter();
  };

  const closeWriteFilter = () => setNewWriteFilter({show: false, val: ""});
  const handleOptionsClose = () => setOptionsAnchorEl(null);

  const updateCustomFields = () => {
    let isMounted = true;
    dispatch(
      getCustomFields({
        onSuccess: (response) =>
          !isMounted ? null : setCustomFields((prev) => response ?? {}),
      }),
    );
    return () => {
      isMounted = false;
    };
  };
  React.useEffect(updateCustomFields, []);

  return (
    <>
      <div className={clsx("searchContainer", classes.searchContainer)}>
        <FormControl
          fullWidth={!shrink && !short}
          onClick={(e) => updateCustomFields()}
        >
          <OutlinedInput
            id="search-field"
            value={searchText}
            placeholder={placeholder}
            multiline
            disabled={disabled}
            onChange={handleSearchChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            inputProps={{className: classes.input}}
            classes={{
              root: clsx(classes.outlineInput, {
                [classes.outlineInputFilters]: !!filtersToShow.length,
                [classes.rounded]: !!rounded,
                [classes.short]: !!short,
                [classes.noBottomPadding]: !!isShowingFilters,
                [classes.shrink]: !!shrink && !searchText.trim() && !focus,
              }),
              input: classes.searchInput,
              notchedOutline: classes.outline,
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon fontSize="small" className={classes.greyColor} />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                {!!searchText && (
                  <Tooltip title="Clear">
                    <DeleteIcon
                      color="action"
                      className={classes.clearIcon}
                      onClick={() => handleSearchChange({target: {value: ""}})}
                    />
                  </Tooltip>
                )}
                {enableFilters && (
                  <Tooltip title="Add Filter" placement="bottom-end">
                    <IconButton size="small" onClick={openFiltersMenu}>
                      <FilterIcon
                        fontSize="small"
                        className={classes.greyColor}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </InputAdornment>
            }
          />
          {isShowingFilters && (
            <div className={classes.filtersContainer}>
              <SelectedFilterList
                filters={filters}
                hiddenFilters={hiddenFilters}
                onDelete={handleDeleteFilter}
              />
              {newWriteFilter.show && !!selectedFilter && (
                <Chip
                  label={
                    <>
                      {fieldParams[selectedFilter]?.name ??
                        specialFilters[selectedFilter]?.name}
                      :
                      <TextField
                        autoFocus
                        placeholder="Type here"
                        className={classes.chipInput}
                        value={newWriteFilter.val}
                        onChange={onWriteFilterChange}
                        onKeyDown={onWriteFilterKeyDown}
                      />
                    </>
                  }
                  onDelete={closeWriteFilter}
                  deleteIcon={<DeleteIcon />}
                  color="primary"
                  size="small"
                  className={clsx(classes.chip, {noMargin: !!noChipMargin})}
                  classes={{
                    deleteIcon: classes.chipDeleteIcon,
                    label: classes.chipLabel,
                  }}
                />
              )}
            </div>
          )}
        </FormControl>
      </div>
      {enableFilters && (
        <>
          <div ref={filtersAnchorRef} />
          <FilterMenus
            isInsideModal={isInsideModal}
            fixedWidth={filtersFixedWidth}
            filterList={filterKeys}
            preferredFilters={preferredFilters}
            anchorEl={filtersAnchorEl}
            onClose={closeFiltersMenu}
            onFilterSelect={onFilterSelect}
            customFieldData={customFields}
          />
        </>
      )}
    </>
  );
}
