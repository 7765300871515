import React, {useState, useEffect} from "react";
// UI
import {
  TuroIcon,
  UberIcon,
  MountIcon,
  OpenTableIcon,
  ConciergeIcon,
  SilofitIcon,
  NinaIcon,
} from "assets/icons/BrandIcons.jsx";
import SvgIcon from "@material-ui/core/SvgIcon";
import axios from "axios";
import {Checkbox, Typography} from "@material-ui/core";
import {lighten, makeStyles} from "@material-ui/core/styles";
import DeleteIconButton from "core/buttons/DeleteIconButton";
// Utilities
import {THEME} from "configuration/settings";
import clsx from "clsx";

const iconmap = {
  turo: <TuroIcon style={{fontSize: 60, color: "#435460"}} />,
  checkin: <ConciergeIcon style={{fontSize: 30, color: "#435460"}} />,
  checkout: <ConciergeIcon style={{fontSize: 30, color: "#435460"}} />,
  uber: <UberIcon style={{fontSize: 60, color: "#435460"}} />,
  opentable: <OpenTableIcon style={{fontSize: 43, color: "#435460"}} />,
  mount: <MountIcon style={{fontSize: 85, color: "#435460"}} />,
  silofit: (
    <SilofitIcon style={{fontSize: 38, color: lighten("#435460", 0.85)}} />
  ),
  drinknina: <NinaIcon style={{fontSize: 80, color: "#435460"}} />,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    transition: "0.15s",
    width: (props) => (!!props.fullWidth ? "100%" : props.width),
    minWidth: 120,
    cursor: "pointer",
    "&:hover": {opacity: (props) => (!!props.color ? 0.9 : 0.6)},
    "&.disabled": {
      opacity: (props) => (!!props.color ? 0.9 : 0.6),
      cursor: "not-allowed",
    },
  },
  card: {
    width: "100%",
    height: (props) => props.height,
    borderRadius: 10,
    padding: theme.spacing(3),
    backgroundColor: (props) => props.color ?? lighten("#E5E5EA", 0.75),
    overflow: "hidden",
    transition: "0.15s",
    display: "flex",
    flexDirection: "column",
    "&.-active": {opacity: (props) => (!!props.color ? 0.8 : 0.5)},
  },
  checkbox: {
    position: "absolute",
    top: 7,
    right: 7,
    padding: theme.spacing(1),
    zIndex: 1,
    "& svg": {fontSize: 15},
  },
  logo: {
    height: 45,
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
    "&.-sm": {
      height: "auto",
      alignItems: "flex-start",
      "& > svg": {
        color: "#FFF !important",
        fontSize: "35px !important",
      },
    },
  },
  name: {
    lineHeight: "21px",
    paddingBottom: theme.spacing(2),
    maxHeight: 58,
    color: (props) => (!!props.color ? "#FFF" : THEME.grey),
    "&.-extra-padding": {paddingTop: theme.spacing(3)},
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
    lineClamp: 2,
  },
  description: {
    fontSize: 13,
    color: (props) => (!!props.color ? "#FFF" : "#6D7175"),
  },
  deleteIcon: {
    position: "absolute",
    top: 7,
    right: 7,
    zIndex: 1,
    display: "flex",
    padding: theme.spacing(1),
  },
}));

export default function LocalServiceCard({
  upsellId,
  name,
  isEnabled = false,
  onEdit,
  width = "calc(50% - 4px)",
  height,
  fullWidth,
  description,
  editable,
  onCheckedChange,
  color,
  sm,
  disabled,
}) {
  const classes = useStyles({
    width,
    fullWidth,
    color,
    sm,
    height: height ?? (!!sm ? 132 : 150),
  });
  const [product, setProduct] = useState(null);
  let icon = !!upsellId ? iconmap[upsellId] : null;

  const handleCheck = (val) => {
    onCheckedChange(val);
  };

  useEffect(() => {
    let isMounted = true;
    if (!upsellId) {
      return;
    }
    if (!product && !icon) {
      axios
        .get(
          `https://enso-image-assets.s3.amazonaws.com/products/${upsellId}_product.json`,
        )
        .then((r) => {
          if (isMounted) {
            setProduct(r.data);
          }
        })
        .catch((e) => console.log(e));
    }

    return () => {
      isMounted = false;
    };
  }, [upsellId]);

  let custom_icon = product && (
    <SvgIcon
      viewBox={product.viewBox}
      style={{fontSize: product.fontSize || 60, color: "#435460"}}
    >
      <path d={product.path} />
    </SvgIcon>
  );

  return (
    <div className={clsx(classes.root, {disabled: !!disabled})}>
      {!!sm ? (
        <Checkbox
          className={classes.checkbox}
          checked={isEnabled}
          disabled={disabled}
          onChange={(e) => handleCheck(e.target.checked)}
          id={upsellId}
        />
      ) : (
        !!isEnabled &&
        !disabled && (
          <div className={classes.deleteIcon}>
            <DeleteIconButton light onClick={() => handleCheck(false)} />
          </div>
        )
      )}
      <div
        className={clsx(classes.card, {"-active": isEnabled})}
        onClick={() => {
          if (editable) {
            onEdit();
          } else if (!disabled) {
            handleCheck(!isEnabled);
          }
        }}
      >
        {(!!icon || custom_icon) && (
          <div className={clsx(classes.logo, {"-sm": !sm})}>
            {icon || custom_icon}
          </div>
        )}
        <Typography
          variant="h1"
          className={clsx(classes.name, {
            "-extra-padding": !icon && !custom_icon && (!!sm || !!isEnabled),
            [classes.ellipsis]: !sm,
          })}
        >
          {name}
        </Typography>
        {!!description && (
          <Typography className={clsx(classes.description, classes.ellipsis)}>
            {description}
          </Typography>
        )}
      </div>
    </div>
  );
}
