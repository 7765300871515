import React from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {THEME} from "configuration/settings";
import Rating from "./Rating";

const useStyles = makeStyles((theme) => ({
  text: {color: THEME.grey},
}));

export default function MsgTags({msg, tag}) {
  const classes = useStyles();

  if (tag === "sentiment") {
    return <Rating value={msg.tags?.sentiment ?? msg.tags?.star_rating} />;
  } else {
    return (
      <Typography className={classes.text}>{msg.tags?.[tag] || "-"}</Typography>
    );
  }
}
