import React from "react";
import {makeStyles, Typography} from "@material-ui/core";
import {THEME} from "configuration/settings.js";

const useStyles = makeStyles((theme) => ({
  fullLabel: {
    cursor: "pointer",
    fontWeight: (props) => (!props.who ? 400 : 500),
    color: (props) =>
      props.who === "them"
        ? "#FFF"
        : props.who === "me"
          ? THEME.grey
          : theme.palette.secondary.main,
  },
  bgLayer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    background: (props) =>
      props.who === "them"
        ? `linear-gradient(to bottom, ${THEME.grey}00 10%, ${THEME.grey} 80%)`
        : props.who === "me"
          ? `linear-gradient(to bottom, ${THEME.messageMe}00 10%, ${THEME.messageMe} 80%)`
          : `linear-gradient(to bottom, #FFFFFF00 10%, #FFF 80%)`,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    paddingBottom: theme.spacing(4),
    borderRadius: 15,
    zIndex: 1,
  },
}));

export default function PartialComponentLayer({
  onExpand,
  who,
  label = "Show Full Content",
}) {
  const classes = useStyles({who});

  return (
    <div className={classes.bgLayer}>
      <Typography variant="h1" className={classes.fullLabel} onClick={onExpand}>
        {label}
      </Typography>
    </div>
  );
}
