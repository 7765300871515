import React from "react";
import FilledTextField from "core/inputs/FilledTextField";
import {usePlacesWidget} from "react-google-autocomplete";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const GooglePlaceAutocomplete = ({placeName, onPlaceChange}) => {
  const [currentPlaceName, setCurrentPlaceName] = React.useState(
    placeName || "",
  );

  const {ref} = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: (e) => handlePlaceChanged(e),
    options: {
      types: ["establishment"],
      fields: [
        "name",
        "formatted_address",
        "photos",
        "rating",
        "website",
        "geometry",
        "reviews",
        "price_level",
      ],
    },
  });

  const handlePlaceChanged = (place) => {
    const addressComponents = place.address_components || [];
    const getAddressComponent = (type) => {
      const component = addressComponents.find((component) =>
        component.types.includes(type),
      );
      return component ? component.long_name : "";
    };
    const newPlace = {
      address: {
        apt_number: getAddressComponent("subpremise"),
        street: `${getAddressComponent("street_number")} ${getAddressComponent(
          "route",
        )}`,
        city: getAddressComponent("locality"),
        state: getAddressComponent("administrative_area_level_1"),
        country: getAddressComponent("country"),
        zip_code: getAddressComponent("postal_code"),
        lat: place.geometry?.location?.lat().toString() ?? null,
        lng: place.geometry?.location?.lng().toString() ?? null,
        formatted: place.formatted_address,
      },
      image:
        place.photos && place.photos.length > 0 ? place.photos[0].getUrl() : "",
      rating: place.rating,
      website: place.website,
      display_name: place.name ?? place.formatted_address,
      reviews: place.reviews
        ? place.reviews.filter((review) => review.rating >= 4)
        : [],
      price_level: place.price_level,
      star_rating: place.rating,
    };
    onPlaceChange(newPlace);
  };

  return (
    <FilledTextField
      fullWidth
      placeholder="Search restaurants, shopping, attractions & more.."
      value={currentPlaceName}
      onChange={(e) => setCurrentPlaceName(e.target.value)}
      inputRef={ref}
    />
  );
};

export default GooglePlaceAutocomplete;
