import React from "react";
// UI
import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ImageIcon from "@material-ui/icons/Image";
import RightArrowIcon from "@material-ui/icons/NavigateNext";
import LeftArrowIcon from "@material-ui/icons/NavigateBefore";
// Custom
import CloseIconButton from "core/buttons/CloseIconButton";
// Utils
import {THEME} from "configuration/settings";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(5),
  },
  dialogContent: {
    padding: theme.spacing(5, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {padding: theme.spacing(4, 0)},
  },
  dialogPaper: {
    height: "70%",
    minHeight: 200,
    maxWidth: 1000,
    minWidth: 250,
    minHeight: 200,
    margin: THEME.spacing.xl,
    boxShadow:
      "0px 10px 20px rgba(32, 37, 38, 0.1), 0px 20px 50px rgba(32, 37, 38, 0.1)",
    borderRadius: 6,
  },
  header: {...THEME.customTypography.dialogHeader},
  title: {
    fontSize: 15,
    fontWeight: 700,
    lineHeight: "28px",
    width: "100%",
    paddingLeft: theme.spacing(5),
    minHeight: 30,
    color: "#43535F",
    paddingBottom: THEME.spacing.md,
  },
  description: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: "20px",
    width: "100%",
    paddingLeft: "10%",
    minHeight: 30,
    color: "#43535F",
    padding: theme.spacing(5, 0, 4),
  },
  paper: {
    height: "100%",
    width: "100%",
    position: "relative",
  },
  avatar: {
    borderRadius: 3,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(190,190,190,0.25)",
    color: "rgba(0,0,0,0.4)",
    "& > img": {objectFit: "contain"},
  },
  imageIcon: {
    fontSize: 100,
    [theme.breakpoints.down("sm")]: {fontSize: 80},
  },
  arrowIcon: {fontSize: 40},
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    width: "100%",
    overflow: "hidden",
  },
  iconBtn: {
    borderRadius: 0,
    height: "100%",
    width: "15%",
    color: "rgba(60, 60, 67, 0.301961)",
    backgroundColor: "#FFF",
    zIndex: 2,
    "&:hover": {backgroundColor: "#FFF"},
    [theme.breakpoints.up("sm")]: {width: "10%"},
  },
}));

export default function CarouselDialog({
  open = false,
  header = "",
  images = [],
  onClose = () => null,
}) {
  const classes = useStyles();
  const direction = React.useRef("left");
  const [selected, setSelected] = React.useState(0);

  const handlePrev = () => {
    direction.current = "right";
    setSelected((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const handleNext = () => {
    direction.current = "left";
    setSelected((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  return (
    <Dialog
      fullWidth
      fullScreen
      open={open}
      maxWidth="lg"
      onClose={onClose}
      classes={{paper: classes.dialogPaper}}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography className={classes.header}>{header}</Typography>
        <CloseIconButton onClick={onClose} />
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Typography className={classes.title}>
          {images[selected]?.title || ""}
        </Typography>
        <div className={classes.row}>
          {images.length > 1 && (
            <IconButton className={classes.iconBtn} onClick={handlePrev}>
              <LeftArrowIcon className={classes.arrowIcon} />
            </IconButton>
          )}
          {images.map((img, index) => (
            <Slide
              key={img.id || index}
              in={selected === index}
              direction={direction.current}
              unmountOnExit
              mountOnEnter
              timeout={{enter: images.length > 1 ? 225 : 0, exit: 0}}
            >
              <Paper elevation={0} className={classes.paper}>
                <Avatar className={classes.avatar} src={img?.img}>
                  <ImageIcon className={classes.imageIcon} />
                </Avatar>
              </Paper>
            </Slide>
          ))}
          {images.length > 1 && (
            <IconButton className={classes.iconBtn} onClick={handleNext}>
              <RightArrowIcon className={classes.arrowIcon} />
            </IconButton>
          )}
        </div>
        <Typography className={classes.description}>
          {images[selected]?.description || ""}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
