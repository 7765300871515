import React from "react";
import {useDispatch} from "react-redux";
// UI
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  Paper,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import {ReactComponent as UnlockIcon} from "assets/icons/Icon_Unlock.svg";
import {ReactComponent as KeyIcon} from "assets/icons/key.svg";
// Custom
import DeviceAccessCodeItem from "core/listItems/DeviceAccessCodeItem";
import EmptyContentText from "components/Misc/EmptyContentText";
import ButtonLabelLoader from "core/loaders/ButtonLabelLoader";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import GenerateRecoveryCode from "./GenerateRecoveryCode";
import PrimaryButton from "core/buttons/PrimaryButton";
import CustomDialog from "core/dialogs/CustomDialog";
// REDUX
import {postAccessCode} from "redux/actions/experiencesActions";
import {setDevices} from "redux/actions/devicesActions";
import {getDevices} from "redux/api/devicesAPI";

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(3, 0),
  },
  keyIcon: {
    fontSize: 16,
    marginRight: theme.spacing(2),
  },
  arrowContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "right",
    gap: theme.spacing(3),
  },
  listItemContainer: {listStyleType: "none"},
  listItemSecondaryAction: {paddingRight: theme.spacing(3)},
  itemSecondaryAction: {padding: theme.spacing(3, 0)},
}));

export default function DeviceAccessCodes({
  device,
  deviceOptions = {},
  visibilities,
  disableEdit,
  setVisibilities,
  setDeviceOptions,
  editDevice,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [generatingNewRecoveryCode, setGeneratingNewRecoveryCode] =
    React.useState(false);
  const [codeToBeRevoked, setCodeToBeRevoked] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [recoveryData, setRecoveryData] = React.useState(null);
  const [openCodes, setOpenCodes] = React.useState({
    booking: true,
    backup: true,
    manual: true,
  });
  const {backupCodes, manualCodes} = React.useMemo(() => {
    let newBackupCodes = [];
    let newManualCodes = [];
    let arr = deviceOptions.recovery_codes ?? [];
    arr.forEach((rc) => {
      if (rc.role === "guest") {
        newBackupCodes.push(rc);
      } else if (rc.role === "host") {
        newManualCodes.push(rc);
      }
    });
    return {backupCodes: newBackupCodes, manualCodes: newManualCodes};
  }, [deviceOptions]);
  let missingInfo = !recoveryData?.name?.trim();

  const handleRevokeRecoveryCode = () => {
    setLoading((prev) => true);
    dispatch(
      postAccessCode({
        action: {
          action_id: "delete_permanent_code",
          code: codeToBeRevoked,
        },
        deviceID: device.device_id,
        onSuccess: () => {
          let index = deviceOptions.recovery_codes.findIndex(
            (rc) => rc.access_code == codeToBeRevoked,
          );
          deviceOptions.recovery_codes.splice(index, 1);
          setDeviceOptions((prev) => deviceOptions);
          setCodeToBeRevoked(null);
          setLoading((prev) => false);
          editDevice({...device, options: deviceOptions});
        },
        onError: () => {
          setLoading((prev) => false);
        },
      }),
    );
  };

  const toggleLock = (option) => {
    dispatch(
      postAccessCode({
        action: {action_id: option ? "lock_door" : "unlock_door"},
        deviceID: device.device_id,
      }),
    );
    setDeviceOptions((prev) => ({
      ...prev,
      locked: option,
    }));
  };

  const handleGenerate = () => {
    setLoading(true);
    let action = {
      action_id: "generate_permanent_codes",
      role: "host",
      name: recoveryData.name,
      device_ids: recoveryData?.devicesList ?? [device.device_id],
    };
    if (recoveryData.code !== null && recoveryData.code !== "") {
      action["code"] = recoveryData.code;
    }
    if (!recoveryData.permanent) {
      Object.assign(action, {
        start: recoveryData.startDate.getTime(),
        end: recoveryData.endDate.getTime(),
      });
    }
    dispatch(
      postAccessCode({
        action,
        deviceID: device.device_id,
        deviceList: action.device_ids,
        onSuccess: () => {
          getDevices(`enso_key=${device.enso_key}`).then((devices) => {
            let newDevice = devices.find(
              (d) => d.device_id == device.device_id,
            );
            setDeviceOptions((prev) => ({...prev, ...newDevice.options}));
            dispatch(setDevices(devices));
            editDevice(newDevice);
          });
          setLoading(false);
          setGeneratingNewRecoveryCode((prev) => false);
        },
        onError: () => setLoading(false),
      }),
    );
  };

  const toggleVisibility = (accessCode) => {
    setVisibilities((prev) => ({...prev, [accessCode]: !prev[accessCode]}));
  };

  function getSectionContent(data = [], disabled) {
    if (!data.length) {
      return (
        <EmptyContentText
          label={`No ${disabled ? "backup" : "manual"} acess codes`}
        />
      );
    }
    return (
      <List>
        {data.map((rc) => (
          <DeviceAccessCodeItem
            editable
            key={rc.access_code}
            deviceCode={rc}
            disabled={disabled}
            visible={visibilities[rc.access_code]}
            toggleVisibility={toggleVisibility}
            onDelete={setCodeToBeRevoked}
          />
        ))}
      </List>
    );
  }

  const lockIcon = (
    <SvgIcon
      viewBox="0 0 16 22"
      className="-button-icon"
      component={LockIcon}
    />
  );
  const unlockIcon = (
    <SvgIcon
      viewBox="0 0 22 22"
      className="-button-icon"
      component={UnlockIcon}
    />
  );

  return (
    <div>
      <CustomDialog
        fullWidth
        maxWidth="sm"
        titleVariant="header"
        title={"Generate access code"}
        open={generatingNewRecoveryCode}
        onClose={() => setGeneratingNewRecoveryCode(false)}
        content={
          <GenerateRecoveryCode
            device={device}
            loading={loading}
            disableEdit={disableEdit}
            setData={setRecoveryData}
          />
        }
        labelCancel={"Cancel"}
        disableConfirm={disableEdit || loading || missingInfo}
        loadingAction={loading}
        labelConfirm={"Generate"}
        actionConfirm={handleGenerate}
        actionCancel={() => setGeneratingNewRecoveryCode(false)}
      />
      <ConfirmDialog
        open={codeToBeRevoked !== null}
        title="Revoke Access Code?"
        message="Any guests with this access code will lose access to this lock."
        confirmLabel={
          loading ? <ButtonLabelLoader variant="white" /> : "Revoke"
        }
        cancelLabel="Cancel"
        confirmDisabled={loading}
        confirmAction={handleRevokeRecoveryCode}
        cancelAction={() => {
          setCodeToBeRevoked(null);
        }}
        onClose={() => {
          setCodeToBeRevoked(null);
        }}
      />
      {/* Booking */}
      <ListItem
        button
        disableRipple
        disableGutters
        className={classes.listItem}
        onClick={() =>
          setOpenCodes((prev) => ({...prev, booking: !prev.booking}))
        }
      >
        <SvgIcon
          component={KeyIcon}
          viewBox="0 0 16 16"
          className={classes.keyIcon}
        />
        <Typography variant="h1">{"Booking access codes"}</Typography>
        <div className={classes.arrowContainer}>
          <ExpandIcon />
        </div>
      </ListItem>
      <Collapse in={openCodes.booking}>
        <Paper elevation={0}>
          <Box pt={2} pb={3}>
            <Typography>
              {
                "Booking access codes are automatically generated on booking confirmation. Codes are activated 2 hours before check-in and are deactivated 2 hour after check-out or when the guest cancels. Please check the booking panel to view a guest's booking access codes."
              }
            </Typography>
          </Box>
        </Paper>
      </Collapse>
      <Divider color="#F0F0F0" />
      {/* Backup */}
      <ListItem
        button
        disableRipple
        disableGutters
        className={classes.listItem}
        onClick={() =>
          setOpenCodes((prev) => ({...prev, backup: !prev.backup}))
        }
      >
        <SvgIcon
          component={KeyIcon}
          viewBox="0 0 16 16"
          className={classes.keyIcon}
        />
        <Typography variant="h1">{"Backup access codes"}</Typography>
        <div className={classes.arrowContainer}>
          <ExpandIcon />
        </div>
      </ListItem>
      <Collapse in={openCodes.backup}>
        <Paper elevation={0}>
          <Box pt={2} pb={3}>
            {!device?.options?.online_access_codes_supported ? (
              <EmptyContentText label="This is an offline device which does not support backup access codes" />
            ) : (
              getSectionContent(backupCodes, true)
            )}
          </Box>
        </Paper>
      </Collapse>
      <Divider color="#F0F0F0" />
      {/* Manual */}
      <ListItem
        button
        disableRipple
        disableGutters
        className={classes.listItem}
        classes={{
          container: classes.listItemContainer,
          secondaryAction: classes.itemSecondaryAction,
        }}
        onClick={() =>
          setOpenCodes((prev) => ({...prev, manual: !prev.manual}))
        }
      >
        <SvgIcon
          component={KeyIcon}
          viewBox="0 0 16 16"
          className={classes.keyIcon}
        />
        <Typography variant="h1">{"Manual access codes"}</Typography>
        <div className={classes.arrowContainer}>
          <ExpandIcon />
        </div>
        {openCodes.manual && (
          <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
            <PrimaryButton
              label="Generate access code"
              disabled={disableEdit}
              onClick={() =>
                setGeneratingNewRecoveryCode(!generatingNewRecoveryCode)
              }
            />
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <Collapse in={openCodes.manual}>
        <Paper elevation={0}>
          <Box pt={2} pb={3}>
            {getSectionContent(manualCodes)}
          </Box>
        </Paper>
      </Collapse>
    </div>
  );
}
