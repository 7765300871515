import React from "react";
import {useSelector} from "react-redux";
// UI
import {
  Box,
  Checkbox,
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {darken, makeStyles} from "@material-ui/core/styles";
// Custom
import CloseIconButton from "core/buttons/CloseIconButton";
import VirtualizedList from "./VirtualizedList";
// Utils
import {FIXED_SIZES, THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  item: {
    display: "flex",
    width: "100%",
    height: "100%",
    padding: theme.spacing(2, 4),
  },
  itemIcon: {
    minWidth: 0,
    paddingLeft: theme.spacing(1),
  },
  itemText: {
    margin: 0,
    paddingLeft: theme.spacing(1),
  },
  itemSubheader: {
    fontWeight: 400,
    lineHeight: "22px",
  },
  checkbox: {padding: theme.spacing(2)},
  chipContainer: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(3),
  },
  chip: {
    padding: theme.spacing(1, 3),
    "&.primary": {
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {backgroundColor: theme.palette.secondary.dark},
    },
    "&.secondary": {
      backgroundColor: THEME.subdued,
      "&:hover": {backgroundColor: darken(THEME.subdued, 0.4)},
    },
  },
  chipLabel: {
    ...theme.typography.body1,
    fontWeight: 500,
    padding: 0,
    color: theme.palette.common.white,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const GroupsList = ({selectedListings, setSelectedListings, onClose}) => {
  const classes = useStyles();
  const listing_groups = useSelector(
    (state) => state.defaultReducer.listing_groups,
  );
  const groups = listing_groups.filter((g) => g.group_id !== "ALL");

  const handleRowClick = (rowItem) => () => {
    let newListings = [...selectedListings];
    if (selectedListings.includes(rowItem.group_id)) {
      newListings = newListings.filter((item) => item !== rowItem.group_id);
    } else {
      newListings.push(rowItem.group_id);
    }
    setSelectedListings(newListings);
  };

  function displayRowItem({index}) {
    const rowItem = groups[index];

    return (
      <ListItem
        disableRipple
        divider
        dense
        className={classes.item}
        button
        ContainerComponent="div"
        selected={selectedListings.includes(rowItem.group_id)}
        onClick={handleRowClick(rowItem)}
      >
        <ListItemIcon className={classes.itemIcon}>
          <Checkbox
            edge="start"
            size="small"
            checked={selectedListings.includes(rowItem.group_id)}
            disableRipple
            className={classes.checkbox}
          />
        </ListItemIcon>
        <ListItemText
          primary={rowItem.name}
          primaryTypographyProps={{variant: "h1", className: classes.itemText}}
          secondary={
            rowItem.connected_to.ids.length === 1
              ? rowItem.connected_to.ids.length + " Listing included"
              : rowItem.connected_to.ids.length + " Listings included"
          }
          secondaryTypographyProps={{
            variant: "subtitle2",
            className: classes.itemSubheader,
          }}
        />
      </ListItem>
    );
  }

  return (
    <div className={classes.container}>
      <Box className={classes.row} px={4}>
        <Typography variant="subtitle2">{"Select listing groups"}</Typography>
        <CloseIconButton sm disablePadding onClick={onClose} />
      </Box>
      <Box className={classes.chipContainer} my={2} px={4}>
        <Chip
          size="small"
          label={"Select all"}
          disableRipple
          classes={{
            root: clsx(classes.chip, "primary"),
            label: classes.chipLabel,
          }}
          onClick={() => setSelectedListings(groups.map((g) => g.group_id))}
        />
        <Chip
          size="small"
          label={"Deselect all"}
          disableRipple
          classes={{
            root: clsx(classes.chip, "secondary"),
            label: classes.chipLabel,
          }}
          onClick={() => setSelectedListings([])}
        />
      </Box>
      <Box flex={1}>
        <VirtualizedList
          hideScrollbar
          getRowItem={displayRowItem}
          rowHeight={FIXED_SIZES.listings_dense}
          totalRows={groups.length}
        />
      </Box>
    </div>
  );
};

export default GroupsList;
