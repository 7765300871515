import React from "react";
import {Box, Card, makeStyles, Typography} from "@material-ui/core";
import {THEME} from "configuration/settings";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: THEME.spacing.sm,
    backgroundColor: "#f5f5f5",
    border: "none",
    padding: THEME.spacing.lg,
    cursor: "pointer",
    width: "100%",
    height: "100%",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    },
  },
  iconBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: THEME.spacing.md,
    height: 60,
    width: 60,
  },
  title: {
    cursor: "pointer",
    ...THEME.customTypography.dialogHeader,
  },
  description: {
    cursor: "pointer",
    ...THEME.customTypography.title2,
  },
}));

export default function IconCard({
  icon,
  title,
  description,
  onClick = () => null,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.card} onClick={onClick}>
      <Box className={classes.iconBox}>{icon}</Box>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>

      <Typography className={classes.description} variant="body2">
        {description}
      </Typography>
    </Card>
  );
}
