import React from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InfoText from "components/Misc/InfoText";
import SimpleField from "./SimpleField";
import {THEME} from "configuration/settings";
import infoTexts from "assets/infoTexts";
import {format} from "date-fns";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  date: {color: THEME.grey},
  caps: {textTransform: "uppercase"},
}));

export default function Date({
  date,
  uppercase,
  showInfoText,
  edit,
  onValueChange,
  value,
  time,
  simpleDate,
}) {
  const classes = useStyles();
  // this state is for KeyboardTimePicker which must have a parseable Date value
  const [selectedDate, setSelectedDate] = React.useState(value);

  if (edit) {
    if (!!time) {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            autoOk
            variant="inline"
            placeholder="08:00 AM"
            mask="__:__ _M"
            format="hh:mm a"
            margin="dense"
            id={"time-picker"}
            value={selectedDate}
            onChange={(time) => {
              const hours = time.getHours().toString().padStart(2, "0");
              const minutes = time.getMinutes().toString().padStart(2, "0");
              onValueChange(`${hours}:${minutes}`);
              setSelectedDate(time);
            }}
            fullWidth
            KeyboardButtonProps={{"aria-label": "change time"}}
          />
        </MuiPickersUtilsProvider>
      );
    } else {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            placeholder="MM/DD/YYYY"
            variant="inline"
            mask="__/__/____"
            format="MM/dd/yyyy"
            margin="normal"
            id={`date-picker`}
            value={value}
            onChange={(date) => onValueChange(date?.getTime())}
            fullWidth
            KeyboardButtonProps={{"aria-label": "change date"}}
          />
        </MuiPickersUtilsProvider>
      );
    }
  }

  if (!date) return <SimpleField value="-" />;
  return (
    <Typography
      variant="caption"
      className={clsx(classes.date, {[classes.caps]: !!uppercase})}
    >
      {format(date, !!simpleDate ? "MMM d, y" : "MMM d, h:mmaaa")}
      {!!showInfoText && <InfoText text={infoTexts.listingtimezone} />}
    </Typography>
  );
}
