import React from "react";
import {Box, makeStyles, Typography} from "@material-ui/core";
import {guideBookIcons} from "configuration/constants";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    gap: theme.spacing(2),
    justifyContent: "center",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  iconItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    textAlign: "center",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    transition: "background-color 0.15s, transform 0.15s",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      transform: "scale(1.05)",
    },
  },
  icon: {
    fontSize: 32,
    marginBottom: theme.spacing(1),
  },
  iconLabel: {
    fontSize: "0.8rem",
    color: theme.palette.text.secondary,
  },
}));

const IconImageDisplay = ({onSelectIcon}) => {
  const classes = useStyles();

  return (
    <Box className={classes.iconContainer}>
      {guideBookIcons.map((iconObj, i) => (
        <div
          key={i}
          className={classes.iconItem}
          onClick={() => onSelectIcon(iconObj.label)}
        >
          <div className={classes.icon}>{iconObj.icon}</div>
          <Typography variant="caption" className={classes.iconLabel}>
            {iconObj.label}
          </Typography>
        </div>
      ))}
    </Box>
  );
};

export default IconImageDisplay;
