import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {Row, Col} from "reactstrap";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import {formatPhoneNumber} from "utilities/helperFunctions.js";
import {defaultImage} from "configuration/constants.js";

const useStyles = makeStyles((theme) => ({
  media: {
    height: "13rem",
    backgroundSize: "cover",
  },
  label: {
    fontSize: 12,
    color: "#707070",
  },
  small: {
    fontSize: 12,
  },
  demo: {
    background: "#e0e0e0",
    borderRadius: "10px",
    padding: "10px",
  },
  textareaStyle: {
    resize: "none",
    width: "100%",

    boxSizing: "border-box",
  },
  avatar_button: {
    textAlign: "center",
    width: "100%",
    height: 20,
    backgroundColor: "grey",
    zIndex: 500,
    position: "absolute",
    left: 0,
    right: 0,
    top: 20,
    opacity: 0.5,
    bottom: 0,
    borderRadius: "0px 0px 20px 20px",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 200,
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    "& input": {
      fontSize: 21,
      fontWeight: "500",
    },
  },
}));

export default function StyledEmailCard({
  edit,
  email_header,
  email_body,
  image,
  variant,
  setHeader,
  setBody,
  contact_picture,
  contact_email,
  contact_name,
  contact_phone,
}) {
  const classes = useStyles();

  return (
    <Card>
      <CardMedia className={classes.media} image={image || defaultImage} />
      <CardContent style={{wordWrap: "break-word"}}>
        <Row>
          <Col>
            <Typography gutterBottom variant="h1" component="h2">
              {edit ? (
                <TextField
                  classes={{root: classes.header}}
                  fullWidth
                  value={email_header}
                  placeholder="Enter Header..."
                  onChange={(e) => setHeader(e.target.value)}
                />
              ) : (
                email_header
              )}
            </Typography>
            <Typography style={{whiteSpace: "break-spaces"}}>
              {edit ? (
                <TextField
                  multiline
                  fullWidth
                  value={email_body}
                  placeholder="Enter Body..."
                  onChange={(e) => setBody(e.target.value)}
                />
              ) : (
                email_body
              )}
            </Typography>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button
              size="small"
              className="mr-2"
              variant="contained"
              color="primary"
            >
              View Keycard
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Grid container spacing={2}>
              <Grid item className={classes.avatarContainer}>
                <Avatar src={contact_picture} />
              </Grid>
              <Grid item xs={12} sm>
                <div>
                  <span className={classes.label}>
                    name: <span className={classes.small}>{contact_name}</span>
                  </span>
                </div>
                <div>
                  <span className={classes.label}>
                    email:{" "}
                    <span className={classes.small}>
                      {contact_email || "No Email"}
                    </span>
                  </span>
                </div>
                <div>
                  <span className={classes.label}>
                    phone:{" "}
                    <span className={classes.small}>
                      {contact_phone
                        ? formatPhoneNumber(contact_phone)
                        : "No Phone"}
                    </span>
                  </span>
                </div>
              </Grid>
            </Grid>
          </Col>
        </Row>
      </CardContent>
    </Card>
  );
}
