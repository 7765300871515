import React from "react";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {Auth} from "aws-amplify";
import {Row, Col, Container} from "reactstrap";

import ResetView from "./ResetView";
import Loading from "components/Misc/Loading.jsx";
import Verification from "./Verification";

import {setUser} from "redux/actions/accountsActions";
import {withStyles} from "@material-ui/core";

const styles = (theme) => ({
  img_col: {
    padding: 0,
    maxHeight: "100vh",
    overflow: "hidden",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  form_col: {
    display: "grid",
    maxHeight: "100vh",
    overflow: "auto",
  },
});

class Reset extends React.Component {
  state = {
    step: 1,
    loading: false,
    redirect_page: false,
    error: false,
    // form data
    name: "",
    verification: false,
    code: "",
    verification_error: false,
    password: "",
  };

  verifyAccount() {
    let {name} = this.state;
    console.log(name, "name");
    Auth.forgotPassword(name)
      .then((data) =>
        this.setState({error: false, verification: true, step: 2}),
      )
      .catch((err) => {
        console.log(err);
        this.setState({error: true, verification: false});
      });
  }
  verifyCode() {
    let {code, name, password} = this.state;
    Auth.forgotPasswordSubmit(name, code, password)
      .then((data) => {
        console.log(data);
        this.setState({step: 3, redirect_page: "listings"});
      })
      .catch((err) => {
        console.log(err);
        this.setState({verification_error: err.message});
      });
  }

  render() {
    let {
      email,
      password,
      confirm_password,
      account_name,
      name,
      error,
      redirect_page,
      phone,
      loading,
      profile_picture,
      step,
      verification,
      code,
      verification_error,
    } = this.state;
    let {classes} = this.props;

    if (redirect_page) {
      return <Redirect push to={`/admin/${redirect_page}`} />;
    }

    return (
      <Container fluid>
        <Row style={{maxHeight: "100vh"}}>
          <Col className={classes.img_col}>
            <img
              src={require("assets/img/airbnb_register_photo.jpg")}
              alt="Login Image"
              style={{width: "100%", height: "100vh"}}
            />
          </Col>
          <Col className={classes.form_col}>
            {
              {
                1: (
                  <ResetView
                    loading={loading}
                    name={name}
                    error={error}
                    handleChange={(e) => {
                      if (e.target.id === "name") {
                        e.target.value = e.target.value.toLowerCase();
                      }
                      this.setState({[e.target.id]: e.target.value});
                    }}
                    handleSubmit={() => this.verifyAccount()}
                  />
                ),
                2: (
                  <Verification
                    error={verification_error}
                    signup={false}
                    code={code}
                    password={password}
                    loading={loading}
                    handleSubmit={() => this.verifyCode()}
                    handleChange={(e) =>
                      this.setState({[e.target.id]: e.target.value})
                    }
                  />
                ),
                3: <Loading text={loading} />,
              }[step]
            }
          </Col>
        </Row>
      </Container>
    );
  }
}

Reset.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (enso_key) => {
    dispatch(setUser(enso_key));
  },
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(Reset));
