import {API} from "aws-amplify";

export const getCrmPreviewGuests = async (params) => {
  try {
    const response = await API.post("ensoAPI", `/crm/preview/guests`, {
      body: params,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getCrmExportGuests = async (params) => {
  try {
    const response = await API.post("ensoAPI", `/crm/export/guests`, {
      body: params,
    });
    return response;
  } catch (err) {
    throw err;
  }
};
