import {
  // Messages
  ADD_MESSAGES,
  APPEND_MESSAGE,
  ADD_HTML_CONTENT,
  EDIT_MESSAGE,
  SET_CHAT_OPTIONS,

  // Automessages
  ADD_AUTOMESSAGES,
  EDIT_AUTOMESSAGE,
} from "../actionTypes";
import {
  stateCollectionAdd,
  stateCollectionEdit,
} from "../../utilities/reducerUtilities";

const initial_state = {
  messages: {},
  html_content: {},
  automessages: [],
  automessages_dict: {},
  guest_chat_options: {},
};

export default function messagesReducer(state = initial_state, action) {
  switch (action.type) {
    // Messages
    case ADD_MESSAGES:
      const newGuestMsgs = {
        start_key: {guest_id: action.guestId, sent: null},
        messages: [],
      };
      let startReached = null;
      let newMsgs = [];
      let newStartKey = {guest_id: action.guestId, sent: null};
      if (!("start_key" in action.messages)) {
        startReached = action.messages;
      } else {
        startReached = action.messages.messages.splice(0, 1);
        newMsgs = [...action.messages.messages];
        newStartKey = {
          ...action.messages.start_key,
          sent:
            action.messages.messages[0]?.sent ??
            action.messages.start_key?.sent,
        };
      }

      const currMsgs = !!action.sync
        ? []
        : state.messages[action.guestId]?.messages.slice(1) || [];
      newGuestMsgs.start_key = newStartKey;
      newGuestMsgs.messages = [...startReached, ...newMsgs, ...currMsgs];
      return {
        ...state,
        messages: {...state.messages, [action.guestId]: newGuestMsgs},
      };

    case APPEND_MESSAGE:
      const guestId = action.guest_id;
      const currGuestMsgs = state.messages[guestId];
      const guestMsgsObj = currGuestMsgs ?? {
        start_key: {guest_id: guestId, sent: action.message.sent},
        messages: [],
      };
      return {
        ...state,
        messages: {
          ...state.messages,
          [guestId]: {
            ...currGuestMsgs,
            messages: [...guestMsgsObj.messages, action.message],
          },
        },
      };

    case ADD_HTML_CONTENT:
      return {
        ...state,
        html_content: {
          ...state.html_content,
          [action.guestId]: {
            ...state.html_content[action.guestId],
            [action.htmlId]: action.body,
          },
        },
      };

    case EDIT_MESSAGE:
      const msgIndex = state.messages[
        action.message.guest_id
      ].messages.findIndex(
        (m) => m.id === (action.previousID ?? action.message.id),
      );
      let updatedMsgs = [...state.messages[action.message.guest_id].messages];
      if (msgIndex !== -1) {
        updatedMsgs[msgIndex] = action.message;
      }
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.message.guest_id]: {
            ...state.messages[action.message.guest_id],
            messages: updatedMsgs,
          },
        },
      };

    case SET_CHAT_OPTIONS:
      return {
        ...state,
        guest_chat_options: {
          ...state.guest_chat_options,
          [action.guestId]: action.chatOptions,
        },
      };

    // Automessages
    case ADD_AUTOMESSAGES:
      return stateCollectionAdd(
        state,
        action.automessages,
        "automessages",
        "id",
        false,
        "guest_id",
      );
    case EDIT_AUTOMESSAGE:
      return stateCollectionEdit(
        state,
        action.automessage,
        "automessages",
        "id",
        false,
        "guest_id",
      );

    default:
      return state;
  }
}
