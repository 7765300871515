import React from "react";
import {SvgIcon} from "@material-ui/core";
import {ReactComponent as InterphoneIcon} from "assets/icons/Icon_Interphone.svg";
import {ReactComponent as NoiseSensorIcon} from "assets/icons/Icon_NoiseSensor.svg";
import LockBoxIcon from "@material-ui/icons/Lock";
import SmartLockIcon from "@material-ui/icons/Dialpad";
import SensorIcon from "@material-ui/icons/SettingsRemote";
import LightIcon from "@material-ui/icons/EmojiObjects";

export default function DeviceTypeIcon({type, small, className}) {
  function getIcon() {
    const iconProps = {
      fontSize: small ? "small" : "medium",
      className: className,
    };
    switch (type) {
      case "lb":
        return <LockBoxIcon {...iconProps} />;
      case "sl":
        return <SmartLockIcon {...iconProps} />;
      case "sn":
        return <SensorIcon {...iconProps} />;
      case "li":
        return <LightIcon {...iconProps} />;
      case "ip":
      case "in":
        return (
          <SvgIcon
            {...iconProps}
            viewBox="0 0 17 19"
            component={InterphoneIcon}
          />
        );
      case "ns":
        return (
          <SvgIcon
            {...iconProps}
            viewBox="0 0 16 13"
            component={NoiseSensorIcon}
          />
        );
      default:
        return null;
    }
  }

  return getIcon();
}
