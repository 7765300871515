import React from "react";
import NumberFormat from "react-number-format";
// UI
import {makeStyles} from "@material-ui/core/styles";

import {THEME} from "configuration/settings.js";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  text: {
    color: THEME.text,
    lineHeight: "16px",
  },
  bold: {fontWeight: 700},
  sm: {fontSize: 12},
}));

const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    default:
      return "$";
  }
};

export default function CurrencyTex({
  value,
  currency,
  bold,
  sm,
  noSuffix,
  hideSymbol,
  disableFixedDecimal,
  customClass,
}) {
  const classes = useStyles();

  return (
    <NumberFormat
      value={value}
      decimalScale={2}
      fixedDecimalScale={!disableFixedDecimal}
      thousandSeparator
      className={clsx(
        classes.text,
        {
          [classes.bold]: bold,
          [classes.sm]: sm,
        },
        customClass,
      )}
      displayType="text"
      prefix={!hideSymbol && getCurrencySymbol(currency)}
      suffix={!noSuffix && (currency === "EUR" ? "" : ` ${currency}`)}
    />
  );
}
