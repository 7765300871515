import React, {useMemo} from "react";
// UI
import {Box, ButtonBase, makeStyles} from "@material-ui/core";
// Custom
import SectionHeaderCard from "./SectionHeaderCard";
import GuidebooksGrid from "./GuidebooksGrid";
import BrandingFooter from "./BrandingFooter";
import {AppleWalletIcon} from "assets/icons/Icons.jsx";
import UpgradesGrid from "./UpgradesGrid";
import {capitalize} from "utilities/helperFunctions";

const defaultBGImg =
  "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Inside+Local+Resturant.webp";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    paddingBottom: theme.spacing(6),
  },
  content: {
    flex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    maxWidth: 800,
  },
}));

const PreviewContentWrapper = ({type, components, onItemClick}) => {
  const classes = useStyles();
  const {guides, upgrades} = useMemo(() => {
    let newUpsells = [];
    let data = components?.upsells || [];
    data.forEach((up) => {
      if (up.url === "payment_link") {
        newUpsells.push(up);
      }
    });
    return {
      guides: components?.guidebooks ?? [],
      upgrades: newUpsells.filter((nls) => !nls.purchased),
    };
  }, [components]);

  function getSectionHeaderImg() {
    switch (type) {
      case "guides":
        return components.guidebooks?.[0]?.header_image ?? defaultBGImg;
      case "upgrades":
        return components.upsells?.[0]?.header_image ?? defaultBGImg;

      default:
        return null;
    }
  }

  function getGrid() {
    switch (type) {
      case "guides":
        return <GuidebooksGrid guides={guides} onClick={onItemClick} />;
      case "upgrades":
        return <UpgradesGrid upgrades={upgrades} onClick={onItemClick} />;

      default:
        return null;
    }
  }

  return (
    <div className={classes.container}>
      <SectionHeaderCard title={capitalize(type)} img={getSectionHeaderImg()} />
      <div className={classes.content}>
        <Box mt={5} px={2.5}>
          {getGrid()}
        </Box>
      </div>
      <Box mt={7.5} display="flex" flexDirection="column" alignItems="center">
        <BrandingFooter
          metadata={components?.metadata}
          extras={
            components.view !== "revoked" ? (
              <ButtonBase
                style={{marginLeft: 10, marginRight: 10}}
                onClick={() => {}}
              >
                <AppleWalletIcon />
              </ButtonBase>
            ) : null
          }
        />
      </Box>
    </div>
  );
};

export default PreviewContentWrapper;
