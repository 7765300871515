import {objectMapper} from "configuration/constants.js";
import store from "../redux/index";
import _ from "lodash";

import {
  addAttachedObject,
  setTotals,
  updateAttachedObject,
} from "redux/actions/settingsActions";
import {addGuests, editGuest} from "redux/actions/guestsActions";
import {addExperience} from "redux/actions/experiencesActions";
import {appendMessage, editMessages} from "redux/actions/messagesActions";
import {triggerDashboardRefresh} from "redux/actions/dashboardActions";

export default function handleAttachedObjects(objects) {
  const dispatch = (action) => store.dispatch(action);
  const state = {
    bookings: store.getState().defaultReducer.bookings_dict,
    guests: store.getState().defaultReducer.guests_dict,
    messages: store.getState().messagesReducer.messages,
    house_data: store.getState().defaultReducer.house_data_dict,
    automessages: store.getState().messagesReducer.automessages_dict,
    templates: store.getState().defaultReducer.templates_dict,
    totals: store.getState().defaultReducer.totals,
  };
  let notif = objects.notification ?? {};

  _.each(Object.entries(objects), (obj) => {
    const key = obj[0];
    const value = obj[1];

    if (key == "device") {
      return null;
    }
    if (key == "notification") {
      if (value.notification_type == "message_error") {
        dispatch(appendMessage(value.guest_id, value));
        return null;
      } else {
        return null;
      }
    }
    if (key === "message") {
      dispatch(triggerDashboardRefresh(true));
      if (!!state.messages[value.guest_id]) {
        if (
          state.messages[value.guest_id].messages
            .map((m) => m.sent)
            .includes(value.sent)
        ) {
          dispatch(editMessages(value));
        } else {
          dispatch(appendMessage(value.guest_id, value));
        }
      }
    } else if (key === "action") {
    } else if (key === "experience") {
      dispatch(addExperience(value.experience_id, value));
    } else if (Object.keys(value).length <= 2) {
      return null;
    } else {
      const mapperObj = objectMapper[key];
      if (!mapperObj) {
        return null;
      }
      const hasOptions = typeof mapperObj[1] !== "string";
      const keyFieldIsArray = _.isArray(mapperObj[1].keyField);
      const nestedFields = mapperObj[0].split(".");
      const elementExists = hasOptions
        ? keyFieldIsArray
          ? !!state[nestedFields[0]][mapperObj[1].keyField[0]][
              value[mapperObj[1].keyToGroupBy]
            ]
          : !!state[nestedFields[0]][value[mapperObj[1].keyToGroupBy]][
              value[mapperObj[1].keyField]
            ]
        : !!state[nestedFields[0]][value[mapperObj[1]]];

      if (key === "booking" && notif.notification_type === "create_booking") {
        dispatch(setTotals("bookings", Number(state.totals.bookings) + 1));
      }
      if (key === "guest" && notif.notification_type === "create_guest") {
        dispatch(setTotals("guests", Number(state.totals.guests) + 1));
      }
      if (key === "listing" && notif.notification_type === "create_listing") {
        dispatch(setTotals("listings", Number(state.totals.listings) + 1));
      }

      switch (key) {
        case "listing":
        case "listing_content":
        case "listing_ranges":
        case "listing_chatbot":
        case "device":
        case "template":
          if (elementExists) {
            dispatch(updateAttachedObject(value, mapperObj, nestedFields[1]));
          } else if (nestedFields.length === 1) {
            dispatch(addAttachedObject(value, mapperObj));
          }
          break;
        case "guest":
          dispatch(triggerDashboardRefresh(true));
          const orderOptions = {fields: "updated_at", order: "desc"};
          const useMerge = true;
          if (elementExists) {
            dispatch(
              editGuest(
                {...state.guests[value.guest_id], ...value},
                orderOptions,
                useMerge,
              ),
            );
          } else {
            dispatch(addGuests(value, orderOptions));
          }
          break;
        case "booking":
          dispatch(triggerDashboardRefresh(true));
          if (!elementExists && nestedFields.length === 1) {
            dispatch(addAttachedObject(value, mapperObj));
          }
          break;
        default:
          break;
      }
    }
  });
}
