import {
  closeGeneralSuccessAlert,
  openGeneralSuccessAlert,
  setApiError,
  setApiStart,
  setApiSuccess,
  errorResponseAlert,
} from "./settingsActions";
import * as types from "../actionTypes";
import * as API from "../api/devicesAPI";
import qs from "qs";

export function setDevices(devices) {
  return {type: types.SET_DEVICES, devices: devices};
}
export function editDevice(device) {
  return {type: types.EDIT_DEVICE, device};
}

export function getDevices(params, onSuccess, disableStoreUpdate = false) {
  return async (dispatch) => {
    dispatch(setApiStart("devices"));
    try {
      const devices = await API.getDevices(params);
      console.log("GOT DEVICES", devices);
      onSuccess && onSuccess(devices);
      if (!disableStoreUpdate) {
        dispatch(setDevices(devices));
      }
      dispatch(setApiSuccess("devices"));
    } catch (error) {
      console.log("ERROR - GET DEVICES", error);
      dispatch(setApiError("devices"));
      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

export function deleteDevice(device, onSuccess) {
  return async (dispatch) => {
    let params = qs.stringify({
      enso_key: device.enso_key,
      device_id: device.device_id,
    });
    try {
      const response = await API.deleteDevice(params);
      if (!!response.success) {
        onSuccess && onSuccess();
      }
    } catch (error) {
      console.log("ERROR - GET DEVICES", error);
      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

export function saveDevice(device, onSuccess, onError) {
  return async (dispatch, getState) => {
    const current_user = getState().defaultReducer.current_user;
    const newBody = {
      enso_key: current_user,
      ...device,
    };
    try {
      const response = await API.patchDevice({body: newBody});
      console.log("GOT UPDATED DEVICE", response);
      successResponseAlert(
        dispatch,
        "Changes saved",
        "Device updated correctly",
      );
      dispatch(editDevice(response.device));
      onSuccess && onSuccess(response);
    } catch (error) {
      console.log("ERROR - SAVE DEVICE", error);
      errorResponseAlert(
        dispatch,
        "Failed to save changes",
        "Device could not be updated, please try again later",
        error,
      );
      onError && onError();
    }
  };
}

// HELPER FUNCTIONS
function handleSuccessAlertClose(dispatch) {
  dispatch(closeGeneralSuccessAlert());
}

function successResponseAlert(dispatch, message = "Success!", subtitle = null) {
  dispatch(
    openGeneralSuccessAlert({
      message,
      subtitle,
      open: true,
      onClose: () => handleSuccessAlertClose(dispatch),
    }),
  );
}
