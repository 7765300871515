import {allowedImagesRange} from "configuration/constants";
import {getEmailContentWrapper} from "./helperFunctions";
import _ from "lodash";

export function highlightCustomFields({
  text = "",
  format = "text",
  validFields = [],
  invalidFields = [],
}) {
  let newText = "";
  let cursorIndex = 0;
  let currentTag = "";
  let unifiedFields = [
    ...validFields.map((vf) => ({position: vf, valid: true})),
    ...invalidFields.map((invF) => ({position: invF, valid: false})),
  ];
  unifiedFields = _.sortedUniqBy(
    _.sortBy(unifiedFields, function (item) {
      return item.position[0];
    }),
    function (i) {
      return i.position[0];
    },
  );
  const textAsArr = [...text];
  for (const f of unifiedFields) {
    const isSpecialChar =
      f.position[1] - f.position[0] === 1 &&
      ["&lt;", "&gt;"].includes(
        textAsArr.slice(f.position[0], f.position[0] + 4).join(""),
      ); // Detect "<" and ">" marks
    currentTag = textAsArr
      .slice(f.position[0], isSpecialChar ? f.position[0] + 4 : f.position[1])
      .join("");
    if (format === "html") {
      newText += `${textAsArr.slice(cursorIndex, f.position[0]).join("")}<span style="background-color: ${f.valid ? "rgb(213, 228, 254)" : "rgb(244, 210, 214)"};">${currentTag}</span>`;
    } else {
      newText += `${textAsArr.slice(cursorIndex, f.position[0]).join("")}<mark class="${f.valid ? "tag" : "error"}">${currentTag}</mark>`;
    }
    cursorIndex = isSpecialChar ? f.position[0] + 4 : f.position[1];
  }
  newText += `${textAsArr.slice(cursorIndex).join("")}`;

  if (format === "text") {
    newText = newText
      .replace(/\n$/g, "\n\n")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/&lt;mark class="tag"&gt;/g, '<mark class="tag">')
      .replace(/&lt;mark class="error"&gt;/g, '<mark class="error">')
      .replace(/&lt;\/mark&gt;/g, "</mark>");
  }

  return newText;
}

export function removeHighlightedTags(text) {
  const tagRegExp =
    /<span style="background-color: rgb\(213, 228, 254\);">[^>]*>/g;
  const errorTagRegExp =
    /<span style="background-color: rgb\(244, 210, 214\);">[^>]*>/g;
  const tagMatches = [...text.matchAll(tagRegExp)];
  const errorMatches = [...text.matchAll(errorTagRegExp)];
  let newText = "";
  let currIndex = 0;
  let allMatches = [
    ...tagMatches.map((tm) => ({tag: tm[0], index: tm.index})),
    ...errorMatches.map((em) => ({tag: em[0], index: em.index})),
  ];
  allMatches = _.sortBy(allMatches, function (item) {
    return item.index;
  });

  for (const match of allMatches) {
    newText += `${text.substring(currIndex, match.index)}${match.tag.substring(52, match.tag.length - 7)}`;
    currIndex = match.index + match.tag.length;
  }
  newText += `${text.substring(currIndex)}`;

  return newText;
}

export function getConnectionLabel(item, houses, groups) {
  let connectionLabel = "none";
  switch (item.connection_type) {
    case "all":
      connectionLabel = "all listings";
      break;
    case "group":
      connectionLabel = groups[item.group_ids[0]]?.name ?? "this group";
      break;
    case "ids":
      if (item.connected_ids?.listing?.length === 1) {
        let l_id = item.connected_ids.listing[0].replace("l#", "");
        connectionLabel =
          (houses[l_id]?.nickname || houses[l_id]?.name) ??
          "a specific listing";
      } else if (!!item.connected_ids?.listing?.length) {
        connectionLabel = `${item.connected_ids.listing.length} listings`;
      } else {
        connectionLabel = "specific listings";
      }
      break;
  }
  return `Connected to ${connectionLabel}`;
}

export function getExperienceConnectionLabel(exp, houses, groups) {
  let connectionLabel = "none";
  if (exp.connected_to?.ids) {
    if (exp.connected_to?.ids?.length === 1) {
      let l_id = exp.connected_to?.ids[0];
      connectionLabel =
        (houses[l_id]?.nickname || houses[l_id]?.name) ?? "a specific listing";
    } else if (!!exp.connected_to?.ids?.length) {
      connectionLabel = `${exp.connected_to?.ids.length} listings`;
    } else {
      connectionLabel = "specific listings";
    }
  } else if (exp.connected_to === "ALL") {
    connectionLabel = "all listings";
  } else {
    connectionLabel = groups[exp.connected_to]?.name ?? "this group";
  }

  return `Connected to ${connectionLabel}`;
}

export const getFileErrorMessage = ({picture, errorType, customMessage}) => {
  let message = "There was an error, please try again again later.";
  switch (errorType) {
    case "info":
      message = `The file ${!!picture ? picture.name + " " : ""}could not be uploaded. Make sure to upload a valid image file and try again.`;
      break;
    case "s3":
      message = "An error occurred while uploading the file. Try again.";
      break;
    case "delete":
      message =
        "An error occurred while deleting this picture. Try again later.";
      break;
    case "multiple_uploads":
      message =
        "Some files could not be uploaded. Make sure to upload valid files and try again.";
      break;
    case "exceeded":
      message =
        "Some files were not uploaded because the maximum number of images allowed was exceeded.";
      break;
    case "images_minimum_limit":
      message = `Picture cannot be removed because a minimum of ${allowedImagesRange[0]} pictures is required`;
      break;
    case "custom":
      message = customMessage || message;
      break;

    default:
      message =
        "Something went wrong while uploading this file. There was an internal error, please try again later.";
      break;
  }
  return message;
};

export const compareObjectsByName = (a, b) => {
  const nameA = (a.nickname || a.name).toLowerCase();
  const nameB = (b.nickname || b.name).toLowerCase();
  if (nameA === nameB) {
    return 0;
  } else {
    return nameA < nameB ? -1 : 1;
  }
};

export const compareTexts = (a, b) => {
  const textA = a.toLowerCase();
  const textB = b.toLowerCase();
  if (textA === textB) {
    return 0;
  } else {
    return textA < textB ? -1 : 1;
  }
};

export const formatScheduledMessageTitle = (message) => {
  const re =
    /( - Email| - SMS Message| - Airbnb Message| - Whatsapp Message| - Webhook Message)$/;
  if (!re.test(message)) {
    return message;
  } else {
    return message.replace(re, "");
  }
};

export const getObjectValueFromStringPath = (obj, path = "") => {
  if (!path || !obj) {
    return null;
  } else {
    for (
      let i = 0, newPath = path.split("."), len = newPath.length;
      i < len;
      i++
    ) {
      obj = obj[newPath[i]] ?? null;
      if (obj === null) {
        break;
      }
    }
    return obj;
  }
};

export const getActionMessagePreview = (action) => {
  if (["SMS", "email", "airbnb"].includes(action.action_id)) {
    return action.body[0].value;
  } else if (action.action_id === "webhook") {
    return `Webhook body: ${JSON.stringify(action.body)}`;
  } else {
    return null;
  }
};

export const toSnakeCase = (text = "") => {
  return text.toLowerCase().replace("&", "").split(" ").join("_");
};

const getValidLanguageTextArray = (field) => {
  if (Array.isArray(field)) {
    return field;
  } else {
    return [{language: "en", value: field}];
  }
};

const validateLanguageContent = (langs, content, fields) => {
  let contentKeys = Object.keys(content);
  let newContent = {};
  _.each(contentKeys, (ck) => {
    newContent[ck] = {};
    let selectedField = fields.find((f) => f.newKey === ck);
    if (ck === selectedField.newKey && !!selectedField.subfieldKeys?.length) {
      let subfieldContentKeys = Object.keys(content[ck]);
      _.each(subfieldContentKeys, (sfck) => {
        newContent[ck][sfck] = {};
        let subfieldFieldKeys = Object.keys(content[ck][sfck]);
        _.each(subfieldFieldKeys, (sffk) => {
          newContent[ck][sfck][sffk] = {};
          _.each(langs, (l) => {
            if (!content[ck][sfck][sffk][l]) {
              newContent[ck][sfck][sffk][l] = "";
            } else {
              newContent[ck][sfck][sffk][l] = content[ck][sfck][sffk][l];
            }
          });
        });
      });
    } else {
      _.each(langs, (l) => {
        if (!content[ck][l]) {
          newContent[ck][l] = "";
        } else {
          newContent[ck][l] = content[ck][l];
        }
      });
    }
  });
  return newContent;
};

const getLanguageTextContent = (array, languages, contentField) => {
  _.each(array, (languageText) => {
    let lang = languageText.language;
    let val = languageText.value ?? "";
    languages.add(lang);
    contentField[lang] = val;
  });
};

/**
 *
 * @param {[]} fields [{
 * key [string]: <array_key_name>,
 * newKey [string]: <new_content_key_name>,
 * content [dict]: object that contains the language text array },
 * subfieldKeys [array]: This option is for fields that are arrays of objects.
 * Here you define the fields that have to be considered because they contain
 * text in different languages, for example: skus.
 * The format is: [<subfield_key>, ...].
 * }]
 * @returns LanguageText arrays ([{language: '', value: ''}]) as dict
 */
export const multiLangArrayToObj = (fields = []) => {
  let allLangs = new Set();
  let newContent = {};
  _.each(fields, (f) => {
    newContent[f.newKey] = {};
    let fieldContent = getValidLanguageTextArray(f.content[f.key]);
    if (!!f.subfieldKeys?.length) {
      let subfieldContent = {};
      _.each(fieldContent, (fc) => {
        subfieldContent[fc.sku_id] = {};
        _.each(f.subfieldKeys, (sfk) => {
          subfieldContent[fc.sku_id][sfk] = {};
          let subfieldArray = getValidLanguageTextArray(fc[sfk]);
          getLanguageTextContent(
            subfieldArray,
            allLangs,
            subfieldContent[fc.sku_id][sfk],
          );
        });
      });
      newContent[f.newKey] = subfieldContent;
    } else {
      getLanguageTextContent(fieldContent, allLangs, newContent[f.newKey]);
    }
  });
  let newLangs = [...allLangs];
  newContent = validateLanguageContent(newLangs, newContent, fields);
  return {newContent, newLangs};
};

/**
 *
 * @param {{}} object A multilanguage dict
 * @param {[]} languages Array of all languages added
 * @param {[]} fields [{
 * key [string]: <object_key_name>,
 * requestKey [string]: <request_object_key_name>,
 * isHTML [bool](optional): whether the value is an HTML string or not,
 * subfieldProps [dict](optional): Prop used to format a second-level multilanguage object
 * such as skus. The format is: {
 * -keys [array]: keys of the fields that use multilanguage text,
 * -object [dict]: This is the object that contains the main item data and is used as
 * the basis for merging the multilanguage text fields
 * }
 * }]
 * @returns a multilanguage dict as LanguageText arrays [ { language: '', value: '' } ]
 */
export const multiLangObjToArray = (
  object = {},
  languages = [],
  fields = [],
) => {
  let newObj = {};
  _.each(fields, (f) => {
    newObj[f.requestKey] = [];
    if (!!f.subfieldProps) {
      let objKeys = Object.keys(f.subfieldProps.object);
      _.each(objKeys, (objK) => {
        let newSubfield = {...f.subfieldProps.object[objK]}; // For example {sku_id: ..., name: [...], value: 1,... }
        _.each(f.subfieldProps.keys, (sfK) => {
          newSubfield[sfK] = []; // For example sku[name] = []
          _.each(languages, (l) => {
            if (!!object[f.key][objK][sfK][l]?.trim()) {
              // If the value of this language is not empty
              if (!!f.isHTML) {
                newSubfield[sfK].push({
                  language: l,
                  value: getEmailContentWrapper(object[f.key][objK][sfK][l]),
                });
              } else {
                newSubfield[sfK].push({
                  language: l,
                  value: object[f.key][objK][sfK][l],
                });
              }
            }
          });
        });
        newObj[f.requestKey].push(newSubfield);
      });
    } else {
      _.each(languages, (l) => {
        if (!!object[f.key][l]?.trim()) {
          // If the value of this language is not empty
          if (!!f.isHTML) {
            newObj[f.requestKey].push({
              language: l,
              value: getEmailContentWrapper(object[f.key][l]),
            });
          } else {
            newObj[f.requestKey].push({language: l, value: object[f.key][l]});
          }
        }
      });
    }
  });
  return newObj;
};
