import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {makeStyles} from "@material-ui/core/styles";
import {MenuItem, MenuList} from "@material-ui/core";
// Custom
import FullLoader from "components/Dialogs/FullLoader";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import UpdateSession from "components/Dialogs/UpdateSession";
import ExpandButton from "core/buttons/ExpandButton";
import CustomMenu from "core/menus/CustomMenu";
// Actions
import {
  editHouse,
  editListingGroup,
  getHouseContent,
  saveListingGroup,
} from "redux/actions/listingsActions";
// Utils
import usePrevious from "hooks/usePrevious";

const useStyles = makeStyles((theme) => ({
  listingGroup: {
    backgroundColor: "rgba(255, 255, 255, 0.1)!important",
    borderRadius: 5,
    padding: theme.spacing(1, 2),
    fontWeight: 700,
    fontSize: 12,
    lineHeight: "16px",
    color: "#FFF",
    opacity: 0.7,
    alignSelf: "self-start",
    "&:hover": {backgroundColor: "rgba(255, 255, 255, 0.2)!important"},
  },
  search: {
    zIndex: 2,
    position: "sticky",
    top: 0,
    backgroundColor: "#FFF !important",
    padding: theme.spacing(4, 3, 2),
  },
  searchInput: {
    border: "1px solid rgba(200, 200, 204, 0.501961)",
    borderRadius: 5,
    fontSize: 15,
    lineHeight: "15px",
    padding: "3px 8px 5px",
    outline: "none",
    width: "100%",
  },
  noResultsMessage: {padding: theme.spacing(2, 3)},
  menuItem: {minWidth: 130},
  arrowIcon: {marginTop: 2},
  disabled: {
    color: `#BCBEBF !important`,
    opacity: 0.5 + "!important",
    cursor: "not-allowed !important",
    pointerEvents: "all !important",
  },
}));

export default function ListingGroupSelector({
  house = {},
  disabled,
  isListingView = false,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const prevGroup = React.useRef(null);
  const prevHouse = React.useRef(null);
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const [remainingOptions, setRemainingOptions] = React.useState([]);
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [updateSession, setUpdateSession] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const prevSearchText = usePrevious(searchText);
  const groupKeys = React.useMemo(() => {
    return Object.keys(listingGroups).filter((lg) => lg !== "ALL");
  }, [listingGroups]);

  React.useEffect(() => {
    setSelectedGroup((prev) => listingGroups[house.group_id] ?? null);
    setRemainingOptions((prev) => groupKeys);
  }, [listingGroups, house]);

  React.useEffect(() => {
    let timer = null;
    if (!!prevSearchText && !searchText) {
      setRemainingOptions((prev) => groupKeys);
    } else if (!!searchText) {
      timer = setTimeout(() => {
        setRemainingOptions((prev) => filterGroups());
      }, 50);
    }

    return () => clearTimeout(timer);
  }, [searchText]);

  const handleLoadingDialogClose = () => {
    setLoading((prev) => null);
  };

  const handleCancelUpdates = () => {
    setUpdateSession((prev) => null);
    setSelectedGroup((prev) => listingGroups[house.group_id] ?? null);
  };

  const handleSearchChange = (e) => {
    const val = e.target.value;
    setSearchText((prev) => val);
  };

  const handleSuccess = () => {
    setLoading((prev) => false);
    setUpdateSession((prev) => null);
    if (isListingView) {
      dispatch(getHouseContent(house.listing_id));
    }
  };

  const handleError = ({isUpdate = false, prevHouseGroup = null}) => {
    setLoading((prev) => false);
    if (isUpdate) {
      dispatch(editListingGroup(prevGroup.current));
      dispatch(editHouse(prevHouse.current));
      setSelectedGroup(
        (prev) => listingGroups[prevHouse.current.group_id] ?? null,
      );
      if (!!prevHouseGroup) {
        dispatch(editListingGroup(prevHouseGroup));
      }
    } else {
      setSelectedGroup((prev) => listingGroups[house.group_id] ?? null);
    }
  };

  const handleOptionChange = (opt, isNone) => {
    const newGroup = listingGroups[opt];
    prevGroup.current = {...newGroup};
    setLoading((prev) => "Changing group...");
    setAnchorEl((prev) => null);
    let newData = {
      group_id: opt,
      name: newGroup.name,
      connected_to: {
        object: "listing",
        ids: isNone
          ? newGroup.connected_to.ids.filter((id) => id !== house.listing_id)
          : [...newGroup.connected_to.ids, house.listing_id],
      },
    };
    setSelectedGroup((prev) => (isNone ? null : {...newGroup, ...newData}));

    dispatch(
      saveListingGroup({
        body: newData,
        groupId: opt,
        onUpdate: (update) => {
          setUpdateSession((prev) => update);
          setLoading((prev) => false);
        },
        onError: () => handleError(),
      }),
    );
  };

  const confirmUpdates = () => {
    setLoading((prev) => "Saving listing...");
    let newGroup = null;
    let prevHouseGroup = null;
    prevHouse.current = house;

    if (!selectedGroup) {
      // If previous group_id was NOT "null" and new group is "None"
      newGroup = {
        ...listingGroups[house.group_id],
        connected_to: {
          object: "listing",
          ids: listingGroups[house.group_id].connected_to.ids.filter(
            (id) => id !== house.listing_id,
          ),
        },
      };
    } else if (!house.group_id) {
      // If previous group_id was "null" and now it has a valid group value
      newGroup = selectedGroup;
    } else {
      // If current group is replaced by another group
      newGroup = selectedGroup;
      prevHouseGroup = {...listingGroups[house.group_id]};
      const updatedPrevGroup = {
        ...listingGroups[house.group_id],
        connected_to: {
          object: "listing",
          ids: listingGroups[house.group_id].connected_to.ids.filter(
            (id) => id !== house.listing_id,
          ),
        },
      };
      dispatch(editListingGroup(updatedPrevGroup));
    }

    dispatch(editListingGroup(newGroup));
    dispatch(
      editHouse({
        ...house,
        group_id: selectedGroup ? selectedGroup.group_id : null,
      }),
    );
    dispatch(
      saveListingGroup({
        body: {update_id: updateSession.update_session.update_id},
        groupId: prevHouse.current.group_id,
        onSuccess: handleSuccess,
        onError: () => handleError({isUpdate: true, prevHouseGroup}),
      }),
    );
  };

  const filterGroups = () => {
    return groupKeys.filter((l) => {
      if (
        listingGroups[l].name
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1
      ) {
        return true;
      } else {
        return false;
      }
    });
  };

  const searchItem = (value, onChange) => {
    return (
      <MenuItem
        disableRipple
        onKeyDown={(e) => e.stopPropagation()}
        className={classes.search}
      >
        <input
          className={classes.searchInput}
          autoFocus
          value={value}
          type="search"
          placeholder="search"
          autoComplete="off"
          onChange={onChange}
        />
      </MenuItem>
    );
  };

  const noResultsItem = () => {
    return (
      <MenuItem disabled className={classes.noResultsMessage}>
        {"No search results"}
      </MenuItem>
    );
  };

  function getMenuContent() {
    return (
      <MenuList>
        {searchItem(searchText, handleSearchChange)}
        {!!searchText && !remainingOptions.length && noResultsItem()}
        {!searchText && !!remainingOptions.length && (
          <MenuItem
            disableRipple
            className={classes.menuItem}
            onClick={() => handleOptionChange(house.group_id, true)}
          >
            {"None"}
          </MenuItem>
        )}
        {remainingOptions.map((opt) => (
          <MenuItem
            key={opt}
            disableRipple
            className={classes.menuItem}
            onClick={() => handleOptionChange(opt)}
          >
            {listingGroups[opt]?.name ?? ""}
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  const loadingModal = !!loading && (
    <FullLoader
      open
      disableDismiss
      onClose={handleLoadingDialogClose}
      loadingText={loading}
    />
  );

  const confirmModal = !!updateSession && (
    <ConfirmDialog
      disableDismiss
      open={!loading}
      onClose={handleCancelUpdates}
      title="Confirm updates?"
      message={
        <>
          {"Changing the group of this listing will affect the following:"}
          <UpdateSession updates={updateSession.update_session.payload} />
        </>
      }
      confirmLabel="Confirm"
      confirmAction={() => confirmUpdates()}
      cancelLabel="Cancel"
      cancelAction={handleCancelUpdates}
    />
  );

  const groupsModal = (
    <CustomMenu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      timeout={0}
      zIndex={4}
      overflowAuto
      hideScrollbar
      fitAnchorElHeight
      placement="top-start"
      bottomPadding={14}
      onClose={() => setAnchorEl((prev) => null)}
      content={getMenuContent()}
    />
  );

  return (
    <>
      {loadingModal}
      {groupsModal}
      {confirmModal}
      <ExpandButton
        hasPopup
        variant="text"
        disabled={disabled}
        buttonClasses={{disabled: classes.disabled}}
        iconClassName={classes.arrowIcon}
        className={classes.listingGroup}
        label={`Listing group: ${selectedGroup?.name ?? "None"}`}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
    </>
  );
}
