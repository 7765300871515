import React from "react";
import {IconButton, Snackbar} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export default function NotificationAlert({
  open,
  autoHideDuration = 4000,
  onClose,
  message,
  vertical = "top",
  horizontal = "right",
}) {
  const otherProps = vertical === "bottom" ? {style: {top: "auto"}} : {};

  return (
    <Snackbar
      anchorOrigin={{
        vertical: vertical,
        horizontal: horizontal,
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      message={message}
      color="primary"
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
      {...otherProps}
    />
  );
}
