import React from "react";
import {Button, makeStyles} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    transition: "0.15s",
    textTransform: "none",
  },
  secondary: {
    fontWeight: 400,
    backgroundColor: "transparent !important",
    padding: theme.spacing(2, 0),
  },
  disabled: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.common.white} !important`,
    opacity: 0.5,
  },
}));

const ExpandButton = ({
  label,
  onClick,
  variant = "outlined",
  color = "primary",
  size = "medium",
  hasPopup,
  disabled,
  className,
  iconClassName,
  buttonClasses,
  hideIcon,
  ...other
}) => {
  const classes = useStyles();
  return (
    <Button
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      disableElevation
      aria-haspopup={hasPopup}
      endIcon={!hideIcon && <ExpandMoreIcon className={iconClassName} />}
      onClick={onClick}
      classes={{
        disabled: clsx({[classes.disabled]: variant === "contained"}),
        ...buttonClasses,
      }}
      className={clsx(
        classes.root,
        {[classes.secondary]: variant === "text"},
        className,
      )}
      {...other}
    >
      {label}
    </Button>
  );
};

export default ExpandButton;
