import React from "react";
import {
  InstagramIcon,
  FacebookIcon,
  TwitterIcon,
  YoutubeIcon,
  ThreadsIcon,
  TikTokIcon,
  SnapchatIcon,
  LinkedInIcon,
  PinterestIcon,
} from "assets/icons/BrandIcons";
import {IconButton, useTheme} from "@material-ui/core";

export default function BrandingFooter({metadata, extras = <></>}) {
  const theme = useTheme();

  function getIcon(icon) {
    let style = {color: theme.palette.primary.main, height: 35, width: 35};
    switch (icon) {
      case "instagram":
        return <InstagramIcon style={style} />;
      case "twitter":
        return <TwitterIcon style={style} />;
      case "facebook":
        return <FacebookIcon style={style} />;
      case "youtube":
        return <YoutubeIcon style={style} />;
      case "tiktok":
        return <TikTokIcon style={style} />;
      case "linkedin":
        return <LinkedInIcon style={style} />;
      case "threads":
        return <ThreadsIcon style={style} />;
      case "pinterest":
        return <PinterestIcon style={style} />;
      case "snapchat":
        return <SnapchatIcon style={style} />;
      default:
        return null;
    }
  }

  return (
    <>
      {!!metadata?.logo && (
        <img style={{width: 150, alignSelf: "center"}} src={metadata?.logo} />
      )}
      <div
        style={{
          marginBottom: 30,
          marginTop: 30,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexFlow: "wrap",
        }}
      >
        {metadata?.social_profiles.map((i) => (
          <IconButton key={i.account_type} onClick={() => window.open(i.url)}>
            {getIcon(i.account_type)}
          </IconButton>
        ))}
        {extras}
      </div>
    </>
  );
}
