import React from "react";
import {Storage} from "aws-amplify";
import {InputAdornment, MenuItem, MenuList} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import FilledTextField from "core/inputs/FilledTextField";
import PrimaryButton from "core/buttons/PrimaryButton";
import CustomMenu from "core/menus/CustomMenu";
import ExpandButton from "core/buttons/ExpandButton";
import FullLoader from "../Dialogs/FullLoader";
import {useImageCompressor} from "utilities/fileUtilities";
import {newUuid, sleep} from "utilities/helperFunctions";
import {validImageTypes} from "configuration/constants";
import config from "configuration/settings.js";
import ImageDisplayDialog from "../../ui/base/dialog/image/ImageDisplayDialog";
import PexelsImageDisplay from "../../ui/base/dialog/image/PexelsImageDisplay";
import IconImageDisplay from "../../ui/base/dialog/image/IconImageDisplay";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 5),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "80%",
    width: "80%",
    overflow: "scroll",
  },
  imageContainer: {
    position: "relative",
    display: "inline-block",
    margin: 10,
  },
  image: {
    width: 200,
    transition: "opacity 0.3s",
  },
  hoverText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    padding: "5px 10px",
    borderRadius: 5,
    opacity: 0,
    transition: "opacity 0.3s",
  },
  imageContainerHover: {
    "&:hover $hoverText": {
      opacity: 1,
    },
    "&:hover $image": {
      opacity: 0.7,
    },
  },
  iconLabel: {
    marginTop: 5,
    textAlign: "center",
  },
}));

export default function ImageTextField({
  picture,
  setPicture,
  label,
  available_pictures,
  imgPrefix = "img-",
  savePath = "",
  uploadOnly,
  assetType,
  setAssetType,
  asset_types = ["image", "image_url", "gallery"],
}) {
  const imgInputRef = React.useRef();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [progressObj, setProgressObj] = React.useState({loaded: 0, total: 100});
  const [loaderOpen, setLoaderOpen] = React.useState(false);
  const [imgToUpload, setImgToUpload] = React.useState(null);
  const [compressEnabled, setCompressEnabled] = React.useState(false);

  const editable = !!assetType && ["picture_url", "video"].includes(assetType);

  useImageCompressor({
    srcImgs: !!compressEnabled && !!imgToUpload ? [imgToUpload] : [],
    onSuccess: (outputImgs) => onCompressSuccess(outputImgs),
  });

  const imageName = React.useMemo(() => {
    if (validImageTypes.some((type) => picture?.includes(type))) {
      return picture.split("/").pop().split("#")[0].split("?")[0];
    } else {
      return picture;
    }
  }, [picture]);

  const browseFile = () => {
    setAssetType && setAssetType("picture");
    if (imgInputRef.current) {
      imgInputRef.current.click();
    }
  };

  const handleLoaderClose = () => setLoaderOpen(false);

  const onCompressSuccess = (outputImgs) => {
    setCompressEnabled(false);
    setImgToUpload(null);
    handleUploadImage(outputImgs[0]);
  };

  const handleUploadImage = async (file) => {
    const imageName = `${savePath}${imgPrefix}${newUuid()}`;
    await Storage.put(imageName, file, {
      contentType: file.type,
      progressCallback(progress) {
        setProgressObj({
          loaded: progress.loaded,
          total: progress.total,
        });
      },
    });
    const link = `${config.s3.URL}${imageName}`;
    if (assetType === "pdf") {
      setPicture({content_url: link, content_name: file.name});
    } else {
      setPicture(link);
    }
    setLoaderOpen(false);
  };

  const handleSelectImage = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setProgressObj({loaded: 0, total: 100});
    setLoaderOpen(true);
    setImgToUpload(file);
    if (assetType === "pdf" || file.type === "application/pdf") {
      handleUploadImage(file);
    } else {
      sleep(250).then(() => setCompressEnabled(true));
    }
  };

  const handleURLOptionSelected = (asset) => {
    setAssetType(asset);
    setMenuAnchorEl(null);
  };

  const handleSelectPhoto = () => {
    setModalOpen(true);
    setAssetType("picture");
  };

  const getAssetTypeHandler = (type) => {
    switch (type) {
      case "image":
        return {label: "Upload Photo", onClick: browseFile};
      case "gallery":
        return {label: "Select Photo", onClick: handleSelectPhoto};
      case "icon_gallery":
        return {label: "Select Icon", onClick: () => setModalOpen(true)};
      case "image_url":
        return {
          label: "Photo URL",
          onClick: () => handleURLOptionSelected("picture_url"),
        };
      case "video":
        return {
          label: "Video URL",
          onClick: () => handleURLOptionSelected("video"),
        };
      case "pdf":
        return {label: "Upload PDF", onClick: browseFile};
      default:
        return {label: "", onClick: null};
    }
  };

  let adornment;
  if (asset_types.length > 1) {
    adornment = (
      <>
        <ExpandButton
          label="Browse"
          size="small"
          variant="outlined"
          onClick={(e) => setMenuAnchorEl(e.currentTarget)}
        />
        <CustomMenu
          overflowAuto
          zIndex={1300}
          open={Boolean(menuAnchorEl)}
          anchorEl={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
          timeout={100}
          content={
            <MenuList id="saving-options-menu">
              {asset_types.map((type) => {
                const {label, onClick} = getAssetTypeHandler(type);
                return (
                  <MenuItem key={type} button disableRipple onClick={onClick}>
                    {label}
                  </MenuItem>
                );
              })}
            </MenuList>
          }
        />
      </>
    );
  } else if (asset_types.length === 1) {
    const {label, onClick} = getAssetTypeHandler(asset_types[0]);
    adornment = (
      <PrimaryButton
        variant="outlined"
        size="small"
        label={label}
        onClick={onClick}
      />
    );
  }

  return (
    <div>
      <ImageDisplayDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title={
          !asset_types.includes("icon_gallery")
            ? "Select an Image"
            : "Select an Icon"
        }
      >
        {!asset_types.includes("icon_gallery") ? (
          <PexelsImageDisplay
            defaultQuery="House"
            default_images={available_pictures}
            onSelectImage={(img) => {
              setModalOpen(false);
              setPicture(img);
            }}
          />
        ) : (
          <IconImageDisplay
            onSelectIcon={(icon) => {
              setModalOpen(false);
              setPicture(icon);
            }}
          />
        )}
      </ImageDisplayDialog>
      <FullLoader
        open={loaderOpen}
        loadingText={
          assetType === "pdf" ? "Uploading PDF..." : "Uploading Image..."
        }
        disableDismiss
        onClose={handleLoaderClose}
        progress={progressObj}
      />
      <input
        id="imageAttachmentInput"
        ref={imgInputRef}
        style={{display: "none"}}
        type="file"
        onChange={handleSelectImage}
      />
      <FilledTextField
        disabled={!editable}
        fullWidth
        id="picture"
        label={label}
        autoFocus
        value={!editable ? imageName : picture}
        onChange={!editable ? undefined : (e) => setPicture(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{adornment}</InputAdornment>
          ),
        }}
      />
    </div>
  );
}
