import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
// UI
import {makeStyles} from "@material-ui/core/styles";
// Custom
import GJEUpsellsPanel from "ui/component/listings/panel/GJEUpsellsPanel";
import ExperienceList from "components/Lists/ExperienceList";
import ExperiencePanel from "../Experience/ExperiencePanel";
import CustomCardHeader from "core/cards/CustomCardHeader";
import CloseIconButton from "core/buttons/CloseIconButton";
import ListingProductsPanel from "./ListingProductsPanel";
import GJEListingContent from "../GJE/GJEListingContent";
import BoardingPassPanel from "../BP/BoardingPassPanel";
import LocalServicesPanel from "../LocalServicesPanel";
import StepSectionPanel from "../GJE/StepSectionPanel";
import GJECheckinSteps from "../GJE/GJECheckinSteps";
import GuidebooksPanel from "./GuidebooksPanel";
// Redux
import {getListingGroupDetails} from "redux/actions/listingsActions";

const useStyles = makeStyles((theme) => ({
  timelineContainer: {
    padding: theme.spacing(4, 4, 4),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto",
  },
}));

const GJEDetailsPanel = ({
  selectedExperience,
  goBack,
  selectExperience,
  groupId,
  setLoading,
  createExperience,
  isLive = true,
  closeDetailsPanel,
  onExperienceClose,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const matchCardSection = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card",
  );
  const matchItemCardSection = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card/:item",
  );
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const listings = useSelector((state) => state.defaultReducer.house_data_dict);
  const {step, card} = matchCardSection?.params ?? {};
  const selectedView = matchItemCardSection?.isExact
    ? matchItemCardSection.params.item
    : card;
  const selectedGroup = listingGroups[groupId];

  const selectExperienceSection = (cardKey, sectionKey) => {
    let sections = listingGroups[groupId]?.guest_journey.sections;
    const experiencesLength =
      sections[sectionKey]?.cards[cardKey]?.data?.length ?? 0;
    if (!!experiencesLength) {
      selectExperience(null);
    }
    history.replace(
      `/admin/listings/groups/${groupId}/${sectionKey}/${cardKey}`,
    );
  };

  const getUpdatedGuestJouney = (section, card, onSuccess) => {
    dispatch(getListingGroupDetails({groupId, section, card, onSuccess}));
  };

  const firstListing =
    !!matchCardSection?.isExact &&
    listings[selectedGroup?.connected_to?.ids?.[0]];
  const handleExperienceClose = (refresh) => {
    if (
      !!refresh &&
      !!groupId &&
      (!!matchCardSection?.isExact || !!matchItemCardSection?.isExact)
    ) {
      getUpdatedGuestJouney(
        matchCardSection.params.step,
        matchCardSection.params.card,
      );
    }
    onExperienceClose();
  };

  const handleExistingExperienceSelect = (exp) => {
    selectExperience(exp);
    history.replace(
      `/admin/listings/groups/${groupId}/${step}/${card}/config_exp`,
    );
  };

  const handleExperiencePresetSelect = (experience, contentId) => {
    delete experience.id;
    delete experience.experience_id;
    selectExperience({
      ...experience,
      connected_to: `${groupId}`,
    });
    history.replace(
      `/admin/listings/groups/${groupId}/${step}/${card}/${contentId}`,
    );
  };

  const handleEditExperience = (experience) => {
    selectExperience(experience);
    history.replace(
      `/admin/listings/groups/${groupId}/${step}/${card}/config_exp`,
    );
  };

  const getPanelContent = () => {
    if (!selectedView) {
      return null;
    }

    switch (selectedView) {
      case "timeline":
        let sections = listingGroups[groupId]?.guest_journey.sections;
        return (
          <>
            <CustomCardHeader
              title="Messaging Timeline"
              action={
                <CloseIconButton sm onClick={() => closeDetailsPanel(true)} />
              }
            />
            <div className={classes.timelineContainer}>
              {["cnf", "pci", "ci", "cu", "co", "ps"].map((k) => (
                <StepSectionPanel
                  id={k}
                  key={k}
                  label={sections[k].title}
                  handleSetupAction={(cardKey, item) =>
                    selectExperienceSection(cardKey, k)
                  }
                />
              ))}
            </div>
          </>
        );
      case "guidebooks": // GJE
        return (
          <GuidebooksPanel
            goBack={goBack}
            listing={firstListing}
            updateGJ={getUpdatedGuestJouney}
            onClose={() => closeDetailsPanel(true)}
            disableReorder={true}
          />
        );
      case "fees": // GJE
        return (
          <ListingProductsPanel
            type={selectedView}
            goBack={goBack}
            onClose={() => closeDetailsPanel(true)}
          />
        );
      case "upsells": // GJE
        return (
          <GJEUpsellsPanel
            goBack={goBack}
            onClose={() => closeDetailsPanel(true)}
          />
        );
      case "config_exp":
      case "copy_existing_exp":
        return (
          <ExperiencePanel
            groupId={groupId}
            closeDetails={handleExperienceClose}
            experience={selectedExperience}
            selectExperience={handleExistingExperienceSelect}
            copyExisting={selectedView === "copy_existing_exp"}
          />
        );
      case "checkin_steps": // GJE
      case "checkout_steps": // GJE
        return <GJECheckinSteps onClose={() => closeDetailsPanel(true)} />;
      case "local_services": // GJE
        return <LocalServicesPanel onClose={() => closeDetailsPanel(true)} />;
      case "wifi": // GJE
        return <GJEListingContent onClose={() => closeDetailsPanel(true)} />;
      case "experiences":
        let experiencesInSection = !!selectedGroup?.guest_journey
          ? selectedGroup.guest_journey.sections[step].cards.experiences.data ||
            []
          : [];
        return (
          <ExperienceList
            isGroupView
            isLive={isLive}
            layoutType="list"
            customList={experiencesInSection}
            updateGJ={() =>
              getUpdatedGuestJouney(
                matchCardSection.params.step,
                matchCardSection.params.card,
              )
            }
            closeOpenExperience={() => handleExperienceClose(true)}
            experienceType={matchCardSection.params.step}
            createExperience={createExperience}
            selectedExperience={selectedExperience}
            selectTemplate={handleExperiencePresetSelect}
            setSelectedExperience={handleEditExperience}
          />
        );

      default:
        if (card === "boarding_pass") {
          return (
            <BoardingPassPanel
              onClose={() => closeDetailsPanel(true)}
              updateGJ={(onSuccess) =>
                getUpdatedGuestJouney("cnf", "boarding_pass", onSuccess)
              }
              setLoading={setLoading}
            />
          );
        } else {
          return null;
        }
    }
  };

  return getPanelContent();
};

export default GJEDetailsPanel;
