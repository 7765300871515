import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import TagList from "components/Lists/TagList";
import {THEME} from "configuration/settings";

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  field: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F8F8F9",
    borderRadius: 5,
    gap: theme.spacing(1),
  },
  tagsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
    alignItems: "center",
    flexWrap: "wrap",
  },
  inputTitle: {
    color: THEME.subdued,
    fontSize: 11,
  },
}));

const SendToOptions = ({
  type,
  defaultDestinations,
  useCustomDestinations,
  setDefaultDestinations,
  setUseCustomDestinations,
}) => {
  const classes = useStyles();
  const [specificDestinations, setSpecificDestinations] = useState(false);
  const [customDestinations, setCustomDestinations] =
    useState(defaultDestinations);
  let sendToLabel = type === "SMS" ? "phone numbers" : "email addresses";
  let filteredDestinations = customDestinations.filter((cd) => cd !== "guest");

  useEffect(() => {
    setCustomDestinations((prev) => defaultDestinations);
    setSpecificDestinations((prev) => useCustomDestinations);
  }, [type, useCustomDestinations]);

  useEffect(() => {
    setDefaultDestinations(customDestinations);
  }, [customDestinations]);

  const onSendToGuestChange = (e) => {
    let val = e.target.checked;
    setCustomDestinations((prev) =>
      !!val ? [...prev, "guest"] : prev.filter((d) => d !== "guest"),
    );
  };

  const onSendToSpecificDestinationsChange = (e) => {
    let val = e.target.checked;
    setSpecificDestinations((prev) => val);
    setUseCustomDestinations(val);
  };

  const handleChipsChange = (newChips) => {
    let isGuestIncluded = customDestinations.includes("guest");
    let newDestinations = isGuestIncluded ? ["guest", ...newChips] : newChips;
    setCustomDestinations((prev) => newDestinations);
  };

  if (!type) {
    return null;
  }
  return (
    <Box pt={2}>
      <Typography className={classes.label}>Send to:</Typography>
      <FormControl component={"fieldset"}>
        <FormGroup>
          <FormControlLabel
            style={{marginBottom: 0}}
            control={
              <Checkbox
                className={classes.checkbox}
                checked={customDestinations.includes("guest")}
                onChange={onSendToGuestChange}
              />
            }
            label={"Guest"}
          />
          <FormControlLabel
            style={{marginBottom: 0}}
            control={
              <Checkbox
                className={classes.checkbox}
                checked={specificDestinations}
                onChange={onSendToSpecificDestinationsChange}
              />
            }
            label={`Specific ${sendToLabel}`}
          />
        </FormGroup>
      </FormControl>
      {specificDestinations && (
        <Box className={classes.field} mt={1}>
          <Typography variant="caption" className={classes.inputTitle}>
            {`Custom ${type === "SMS" ? "phone numbers" : "email addresses"}`}
          </Typography>
          <Box className={classes.tagsContainer}>
            <TagList
              objectId={type}
              flexItem
              color={"secondary"}
              type={type}
              label={`Add ${type === "SMS" ? "phone number" : "email address"}`}
              tags={filteredDestinations}
              onChange={handleChipsChange}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SendToOptions;
