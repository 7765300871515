import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  Card,
  CardContent,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Row, Col} from "reactstrap";
import PrimaryButton from "core/buttons/PrimaryButton.jsx";
import MuiSkeleton from "@material-ui/lab/Skeleton";
import {useParams} from "react-router";
import {getBilling, postBilling} from "redux/api/accountsAPI";
import {setForceBilling, setUserProfile} from "redux/actions/accountsActions";
import Panel2 from "core/panels/Panel2.jsx";
import {useSelector, useDispatch} from "react-redux";
import PaymentMessage from "components/TextFields/PaymentMessage";
import CustomDialog from "core/dialogs/CustomDialog";
import Panel3 from "core/panels/Panel3";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  root: {
    padding: theme.spacing(0, 4, 5),
    overflow: "auto",
    flex: 1,
  },
  header: {padding: theme.spacing(4, 0)},
  title: {
    margin: theme.spacing(2),
  },
  package_card: {
    marginTop: theme.spacing(2),
  },
  selected_package_card: {
    outline: `1px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(2),
  },
  no_bills: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    color: "lightgrey",
  },
  accordion: {
    margin: 0,
    padding: 0,
  },
}));

const UNIT_LABELS = {
  per_listing: "listing",
};

export default function BillingPanel() {
  const [bills, setBills] = React.useState(null);
  const [billing, setBilling] = React.useState(null);
  const [rootBilling, setRootBilling] = React.useState(null);
  const [showPackages, setShowPackages] = React.useState(false);
  const [addonPlans, setAddonPlans] = React.useState(null);
  const [redirectResolved, setredirectResolved] = React.useState(false);
  let dispatch = useDispatch();
  const [selectedPackage, setSelectedPackage] = React.useState(null);
  const {redirect} = useParams();
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const isTabletView =
    useSelector((state) => state.defaultReducer.deviceType) === "tablet";
  const classes = useStyles();
  const [selectedBillIndex, setSelectedBillIndex] = React.useState(null);
  const selectedBill = bills?.[selectedBillIndex];
  let loaded = !!billing;

  const currencyCode = rootBilling?.currency || "USD";

  React.useEffect(() => {
    if (!billing) {
      getBilling().then((r) => {
        setAddonPlans(r.addon_plans);
        setShowPackages(!r?.enso_plan?.active);
        setBilling(r.enso_plan);
        setBills(r.bills);
        setRootBilling(r.root_billing);
      });
    }
  }, [billing]);

  React.useEffect(() => {
    console.log("GOT REDIRECT", redirect);
    if (redirect === "success" && !redirectResolved) {
      let params = new URLSearchParams(window.location.search);
      console.log("POSTING BILLING");
      postBilling({
        session_id: params.get("session_id"),
        subscription_id: params.get("package_id"),
      }).then((r) => {
        setShowPackages(false);
        setBilling(r.enso_plan);
        dispatch(setUserProfile({...user_profile, plan: "paid"}));
        dispatch(setForceBilling(false));
      });
    }
  }, [redirect]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  let unit_label = UNIT_LABELS[billing?.billing_type];

  function savePlan() {
    const request = {subscription_id: billing.subscription_id};
    postBilling(request).then((r) => {
      if (r.success) {
        setShowPackages(false);
      } else {
        window.open(r.url);
      }
    });
  }

  let payment_message = PaymentMessage();

  function formattedDateRange(start, end) {
    if (!start && !end) {
      return "-";
    }
    const options = {year: "numeric", month: "long", day: "numeric"};
    const dateTimeFormat = new Intl.DateTimeFormat("en", options);
    if (!start) {
      return `Start - ${dateTimeFormat.format(end)}`;
    } else {
      return dateTimeFormat.formatRange(new Date(start), new Date(end));
    }
  }

  const package_select = (
    <>
      <Card className={classes.package_card}>
        <CardContent>
          {GetPlanInfo(billing, user_profile.plan === "free")}
          <Row>
            <Col></Col>
          </Row>
        </CardContent>
      </Card>
    </>
  );

  function GetPlanInfo(packg, hide_prices = false, current = true) {
    const metered = !["root", "listing"].includes(packg?.unit);
    let curr_tier = packg?.tiers?.[packg.properties.current_tier];
    const label = metered ? "custom pricing" : `per ${packg?.unit}`;
    return (
      <>
        <Row>
          <Col>
            <Typography>Plan</Typography>
            {!!packg?.name ? (
              <Typography>{packg?.name}</Typography>
            ) : (
              <MuiSkeleton animation="wave" width="100%" height={24} />
            )}
          </Col>
          <Col>
            <Typography>Pricing</Typography>
            {curr_tier?.value != undefined ? (
              <Typography>
                {hide_prices
                  ? "CUSTOM"
                  : `${metered ? "" : `${curr_tier?.value} ${currencyCode}`} ${label}`}
              </Typography>
            ) : (
              <MuiSkeleton animation="wave" width="100%" height={24} />
            )}
          </Col>
          <Col>
            <Typography>Usage</Typography>
            {curr_tier?.value != undefined ? (
              <>
                <Typography>
                  {hide_prices
                    ? "CUSTOM"
                    : `${metered ? `${packg.properties.total_value} ${currencyCode}` : `${packg?.quantity} ${packg.unit}s`}`}

                  {packg?.minimum_quantity && (
                    <span
                      style={{
                        backgroundColor: "rgba(248, 248, 249, 1)",
                        borderRadius: "360px",
                        padding: "4px 8px",
                        marginLeft: 10,
                        fontSize: 12,
                        fontWeight: 400,
                        lineHeight: "16px",
                        color: "var(--Subdued, #6D7175)",
                      }}
                    >
                      Fixed Min *
                    </span>
                  )}
                </Typography>
              </>
            ) : (
              <MuiSkeleton animation="wave" width="100%" height={24} />
            )}
          </Col>
        </Row>
        {packg?.minimum_quantity && (
          <div
            style={{
              backgroundColor: "rgba(248, 248, 249, 1)",
              borderRadius: 10,
              padding: 10,
              marginTop: 10,
              fontSize: 12,
              fontWeight: 400,
              lineHeight: "16px",
              color: "var(--Subdued, #6D7175)",
            }}
          >
            * Your plan has a{" "}
            <b>fixed minimum count of {packg?.minimum_quantity}</b> set at the
            time your account was created. Please contact client success for
            more info.
          </div>
        )}
      </>
    );
  }

  function planProration(
    plan,
    previous_period_start,
    period_start,
    period_end,
  ) {
    const metered = !["root", "listing"].includes(plan?.unit);
    return (
      <div>
        <Typography className={classes.title} variant="h1">
          {plan.name}
        </Typography>
        <Card style={{minWidth: 600}}>
          <Table>
            <TableHead>
              <TableCell>Description</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography style={{marginLeft: 12}}>
                    {metered
                      ? formattedDateRange(previous_period_start, period_start)
                      : formattedDateRange(period_start, period_end)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {plan.quantity} {plan.unit}s
                </TableCell>
                <TableCell align="right">
                  {plan.properties.current_tier_value} {rootBilling?.currency}
                </TableCell>
              </TableRow>

              {!!plan.properties.discount && (
                <TableRow>
                  <TableCell>
                    <Typography>{plan.properties.discount.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="right" style={{marginLeft: 12}}>
                      -{plan.properties.discount.value} {rootBilling?.currency}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}

              {plan.properties.proration_data?.length > 0 && (
                <TableRow>
                  <TableCell>
                    <Typography style={{marginLeft: 12}}>
                      {formattedDateRange(previous_period_start, period_start)}{" "}
                      (Adjustements)
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {plan.properties.proration_data?.map((proration_row) => (
                <TableRow>
                  <TableCell>
                    <Accordion elevation="0">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        className={classes.accordion}
                      >
                        {proration_row.listings_added.length > 0 &&
                          `${proration_row.listings_added.length} Listings enabled on `}
                        {proration_row.listings_removed.length > 0 &&
                          `${proration_row.listings_removed.length} Listings disabled on `}
                        {new Date(proration_row.time).toLocaleDateString(
                          "en-us",
                          {month: "short", day: "numeric"},
                        )}
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordion}>
                        <List className={classes.accordion}>
                          {proration_row.listings_added.map((lid) => (
                            <ListItem className={classes.accordion}>
                              {houses?.[lid]?.nickname || houses?.[lid]?.name}{" "}
                              Added
                            </ListItem>
                          ))}
                          {proration_row.listings_removed.map((lid) => (
                            <ListItem className={classes.accordion}>
                              {houses?.[lid]?.nickname || houses?.[lid]?.name}{" "}
                              Removed
                            </ListItem>
                          ))}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                  <TableCell align="right">
                    {proration_row.amount_change / 100} {rootBilling?.currency}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Typography align="right" style={{marginLeft: 12}}>
                    <strong>
                      Total Due: {plan.properties.total_value}{" "}
                      {rootBilling?.currency}
                    </strong>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </div>
    );
  }

  function prorationModal() {
    if (selectedBillIndex == null) return;

    let start_date = null;
    let end_date = null;
    let previous_start_date = null;
    let plans = [];

    if (selectedBillIndex > -1) {
      start_date = selectedBill?.period_start;
      end_date = selectedBill?.period_end;
      previous_start_date = selectedBill.previous_period_start;
      plans = [selectedBill.enso_plan, ...selectedBill.addon_plans];
    } else {
      start_date = rootBilling.period_end;
      end_date = rootBilling.next_period_end;
      previous_start_date = rootBilling.period_start;
      plans = [billing, ...addonPlans];
    }

    return (
      <CustomDialog
        open={selectedBillIndex != null}
        title={"Bill Info"}
        onClose={() => setSelectedBillIndex(null)}
        disableActions
        maxWidth="100%"
        content={
          <div>
            {plans.map((p) =>
              planProration(p, previous_start_date, start_date, end_date),
            )}
          </div>
        }
      />
    );
  }

  const panel2 = (
    <div className={classes.root}>
      {prorationModal()}
      <div style={{display: "flex"}}>
        <Typography style={{flex: 1}} variant="h6" className={classes.header}>
          Billing
        </Typography>
      </div>
      <Typography style={{marginTop: 20}} variant="h2">
        Subscriptions
      </Typography>
      {package_select}
      {addonPlans?.map((p) => (
        <Card style={{marginTop: 10}}>
          <CardContent>{GetPlanInfo(p)}</CardContent>
        </Card>
      ))}
      {!loaded ? (
        <MuiSkeleton
          animation="wave"
          width="100%"
          height={24}
          style={{marginTop: 5}}
        />
      ) : (
        <div
          style={{
            backgroundColor: "lightgrey",
            borderRadius: 10,
            padding: 10,
            marginTop: 10,
          }}
        >
          {user_profile?.plan === "paid" ? (
            <Typography>
              {`All subscriptions are billed ${billing?.interval}ly. Your next bill is on `}
              {new Date(rootBilling?.period_end).toLocaleDateString("en-us", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
              {". "}
            </Typography>
          ) : (
            <Typography>{payment_message}</Typography>
          )}
          <div style={{display: "flex", marginTop: 5}}>
            <div style={{flex: 1}} />
            {user_profile?.plan === "paid" && (
              <PrimaryButton
                size="small"
                label="View Invoice"
                style={{marginRight: 5}}
                disabled={selectedPackage === billing?.package_id}
                onClick={() => setSelectedBillIndex(-1)}
              />
            )}
            <PrimaryButton
              size="small"
              label={
                user_profile?.plan === "new"
                  ? "Add payment method"
                  : "Update payment method"
              }
              style={{marginRight: 5}}
              disabled={selectedPackage === billing?.package_id}
              onClick={() => savePlan()}
            />
          </div>
        </div>
      )}
      <Typography style={{marginTop: 20}} variant="h2">
        Billing History
      </Typography>
      {!!bills?.length ? (
        <TableContainer>
          <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bills.map((row, index) => {
                return (
                  <TableRow
                    key={row.id}
                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                  >
                    <TableCell component="th" scope="row">
                      {formattedDateRange(row.period_start, row.period_end)}
                    </TableCell>
                    <TableCell align="right">
                      {row.total_paid / 100} {currencyCode} - {row?.status}{" "}
                    </TableCell>
                    <TableCell>
                      <Link onClick={() => setSelectedBillIndex(index)}>
                        View Invoice
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className={classes.no_bills}> No Billing History </div>
      )}
    </div>
  );

  return (
    <div className={classes.container}>
      <Panel2 content={panel2} flexAuto />
      <Panel3
        hidden
        content={<></>}
        maintainWidth={!isTabletView}
        disableMaxWidth={isTabletView}
      />
    </div>
  );
}
