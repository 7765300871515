import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {
  Box,
  List,
  ListItem,
  MenuList,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import useDashboardStyles from "styles/useDashboardStyles";
// Custom
import TimePeriodModal from "components/Dialogs/TimePeriodModal";
import EnsoAILabel from "components/Misc/EnsoAILabel";
import CustomMenu from "core/menus/CustomMenu";
import {buildGuestFilterUrl, stringToHslColor} from "utilities/helperFunctions";
// Redux
import {getDashboardMessageAnalytics} from "redux/actions/dashboardActions";
// Utils
import {endOfDay, startOfDay, subDays} from "date-fns";
import {capitalize} from "utilities/helperFunctions";
import {FIXED_SIZES} from "configuration/settings";
import clsx from "clsx";
import {guestViewsUrlMap} from "../../../configuration/constants";
import {useFlags} from "launchdarkly-react-client-sdk";

const now = new Date();

const AIStatsCard = ({
  loading,
  selectedGroups = [],
  row,
  refreshDasboard,
  onRefreshCompleted,
  mobile,
}) => {
  const classes = useDashboardStyles();
  const dispatch = useDispatch();
  const didMount = useRef(false);
  const didRefresh = useRef(false);
  const data = useSelector((state) => state.dashboardReducer.messageAnalytics);
  const [property, setProperty] = useState("channel");
  const [propertyMenuAnchorEl, setPropertyMenuAnchorEl] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [dateRange, setDateRange] = useState([
    new Date(startOfDay(subDays(now, 6))).getTime(),
    new Date(endOfDay(now)).getTime(),
  ]);
  let stats = data?.question_type || [];
  const flag = useFlags();

  function getData(range, stat) {
    dispatch(
      getDashboardMessageAnalytics({
        start_date: range[0],
        end_date: range[1],
        listing_group_ids: !!selectedGroups.length ? selectedGroups : null,
        stat: stat,
        onSuccess: (res) => {
          if (!!didRefresh.current) {
            didRefresh.current = false;
            onRefreshCompleted();
          }
          setLocalLoading((prev) => false);
        },
        onError: () => {
          setLocalLoading((prev) => false);
        },
      }),
    );
  }

  useEffect(() => {
    if (!!refreshDasboard && !didRefresh.current) {
      didRefresh.current = true;
      getData(dateRange, property);
    }
  }, [refreshDasboard]);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    setLocalLoading((prev) => true);
    getData(dateRange, property);
  }, [selectedGroups]);

  const closePropertyMenu = (e) => setPropertyMenuAnchorEl(null);

  const handleItemClick = (questionType) => {
    if (flag.enableCrmViews) {
      const url = buildGuestFilterUrl(
        [{id: "message_topics", value: questionType}],
        [
          "guest_name",
          "guest_email",
          "guest_phone",
          "guest_destination",
          "message_topics",
          "guest_ai_summary",
        ],
      );
      window.location.href = "/admin/guests" + url;
    }
  };

  const handlePropertyChange = (newProperty) => {
    setProperty(newProperty);
    setLocalLoading((prev) => true);
    getData(dateRange, newProperty);
    closePropertyMenu();
  };

  const handleTimePeriodChange = (newRange, isRangeComplete) => {
    setDateRange((prev) => newRange);
    if (isRangeComplete) {
      setLocalLoading((prev) => true);
      getData(newRange, property);
    }
  };

  const propertyMenu = (
    <CustomMenu
      open={!!propertyMenuAnchorEl}
      anchorEl={propertyMenuAnchorEl}
      onClose={closePropertyMenu}
      content={
        <MenuList>
          <ListItem
            button
            disableRipple
            className={clsx(classes.menuItemText, {
              selected: property === "channel",
            })}
            onClick={() => {
              handlePropertyChange("channel");
            }}
          >
            {"Channel"}
          </ListItem>
          <ListItem
            button
            disableRipple
            className={clsx(classes.menuItemText, {
              selected: property === "status",
            })}
            onClick={() => {
              handlePropertyChange("status");
            }}
          >
            {"Status"}
          </ListItem>
        </MenuList>
      }
    />
  );

  const timePeriodModal = (
    <TimePeriodModal
      past
      disableDateRestriction
      disableBtn={loading || localLoading}
      selectedRange={dateRange}
      onChange={handleTimePeriodChange}
    />
  );

  const totalInfo = (
    <Box className={clsx(classes.row, classes.gap8)} alignItems="center">
      {!mobile && timePeriodModal}
    </Box>
  );

  console.log("stats", stats);
  return (
    <div
      className={clsx(classes.card, "padd", classes.flex1, "noPaddBottom", {
        lg: !!row,
        mobile: !!mobile,
      })}
      style={{height: FIXED_SIZES.dashboard_AI_card}}
    >
      {propertyMenu}
      <div
        className={clsx(classes.cardHeader, classes.gap8, {
          [classes.row]: !mobile,
          [classes.col]: !!mobile,
          middle: mobile,
        })}
      >
        <Box className={clsx(classes.row, classes.gap8)} alignItems="center">
          <Typography variant="h2" className={classes.cardTitle}>
            {"Message Topics"}
          </Typography>
          <EnsoAILabel text="Message Topics are powered by EnsoAI" />
          {!!mobile && totalInfo}
        </Box>
        {!mobile && totalInfo}
        {!!mobile && (
          <Box
            width={"100%"}
            className={classes.row}
            justifyContent="space-between"
            mt={2}
          >
            {timePeriodModal}
          </Box>
        )}
      </div>
      {loading || localLoading ? (
        <Box mt={2} pb={4} flex={1}>
          <Skeleton
            animation="wave"
            width="100%"
            height={"100%"}
            className={classes.skeleton}
          />
        </Box>
      ) : (
        <Box overflow={"auto"} className={classes.hideScrollbar}>
          <Box
            pt={3}
            pb={4}
            className={classes.cardListContainer}
            overflow={"auto"}
          >
            <List>
              {stats.map((s, ind) => {
                return (
                  <Tooltip title="Open in CRM" arrow>
                    <ListItem
                      key={`rating-${ind}-stars`}
                      className={classes.cardListItem}
                      onClick={() => handleItemClick(s.question_type)}
                    >
                      <Box
                        width={20}
                        height={20}
                        borderRadius={5}
                        style={{
                          backgroundColor: stringToHslColor(
                            s.question_type,
                            30,
                            50,
                          ),
                        }}
                      />
                      <Typography variant="h1" style={{flex: 1}}>
                        {capitalize(s.question_type, "_")}
                      </Typography>
                      <Typography variant="h1">{s.num_of_messages}</Typography>
                    </ListItem>
                  </Tooltip>
                );
              })}
            </List>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default AIStatsCard;
