import {makeStyles} from "@material-ui/core";
import {THEME} from "configuration/settings";

const useHouseDashOverviewStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    "&.-center": {alignItems: "center"},
  },
  title: {
    fontWeight: 500,
    color: "#FFF",
    "&.-cut": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      wordBreak: "break-word",
      WebkitLineClamp: 2,
    },
  },
  subtitle: {
    color: "#FFF",
    fontWeight: 400,
  },
  address: {color: "#FFF"},
  button: {
    backgroundColor: "transparent",
    border: "none",
    padding: 0,
    marginLeft: theme.spacing(4),
  },
  integrations: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  icon: {
    width: 20,
    height: 20,
    color: "#FFF",
  },
  dataBold: {
    fontWeight: 700,
    fontSize: 13,
    color: "#FFF",
  },
  infoIcon: {
    fontSize: 20,
    color: "#FFF",
    marginRight: theme.spacing(1),
  },
  container: {
    margin: theme.spacing(5),
    borderRadius: 10,
    background: (props) =>
      !!props.disabled
        ? `linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%), url(${props.picture})`
        : `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%), url(${props.picture})`,
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "&.-card-header": {
      borderRadius: "10px 10px 0px 0px",
      margin: 0,
    },
    "&.-switch-row": {paddingTop: 80},
  },
  statusRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: THEME.grey,
    padding: theme.spacing(2, 3),
  },
  statusLabel: {
    color: theme.palette.common.white,
    fontSize: 13,
  },
  switchRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "calc(100% - 40px)",
    position: "absolute",
    top: theme.spacing(3),
    borderRadius: 10,
  },
  listingInfoRow: {
    padding: theme.spacing(0, 4, 4),
    flex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  redirectButton: {
    padding: 0,
    userSelect: "text",
    backgroundColor: "transparent !important",
    "&.-disabled": {cursor: "text"},
  },
  redirectButtonLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  link: {
    padding: 0,
    marginRight: theme.spacing(2),
  },
  errorContainer: {
    display: "flex",
    margin: theme.spacing(0, 5, 5),
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

export default useHouseDashOverviewStyles;
