import React from "react";
// UI
import {Button, MenuItem, SvgIcon, Typography} from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Call";
import LockIcon from "@material-ui/icons/Lock";
import {ReactComponent as ChatbotIcon} from "assets/icons/robot.svg";
import {ReactComponent as UnlockIcon} from "assets/icons/Icon_Unlock.svg";
import {ReactComponent as MessagesIcon} from "assets/icons/Icon_Messages.svg";
// Custom
import PrimaryButton from "core/buttons/PrimaryButton";
import FilledSelect from "core/selects/FilledSelect";
import PhoneCallAction from "./PhoneCallAction";
import WebhookAction from "./WebhookAction";
import MessageAction from "./MessageAction";
import AccessAction from "./AccessAction";
import WhatsappAction from "./WhatsappAction";
import MultiButtonBar from "core/buttons/MultiButtonBar";
import useExperienceActionsStyles from "styles/useExperienceActionsStyles";
// Utilities
import {
  serviceActions,
  serviceLabels,
  triggerConfig,
  experienceEditorActions,
} from "configuration/constants";
import {actionParams} from "configuration/specs";
import clsx from "clsx";
import _ from "lodash";

export default function Action({
  trigger,
  newService,
  data,
  disableEdit,
  hideEdit,
  setData,
  allowAll,
  removeNewService,
  setActionEdit,
  disableDelete,
  variant = "filled",
  handleDelete,
  additionalObjectsVariables,
}) {
  const classes = useExperienceActionsStyles();
  const [edit, setEdit] = React.useState({new: false});
  const [selectedActionTypes, setSelectedActionTypes] = React.useState({
    new: "",
  });
  const [allowedActions, setAllowedActions] = React.useState([]);
  const [actionOptions, setActionOptions] = React.useState({new: {}});
  const actionTypesValues = Object.values(selectedActionTypes);
  const [isContentValid, setIsContentValid] = React.useState(true);

  const actionIcons = {
    cb: (
      <SvgIcon
        className={clsx(classes.iconPreview, "-icon")}
        viewBox="0 0 22 19"
        component={ChatbotIcon}
      />
    ),
    msg: (
      <SvgIcon
        className={clsx(classes.iconPreview, "-iconSmall")}
        viewBox="0 0 35 35"
        component={MessagesIcon}
      />
    ),
    phn: <PhoneIcon className={clsx(classes.iconPreview, "-icon")} />,
    skb: <LockIcon className={clsx(classes.iconPreview, "-icon")} />,
    sl: <LockIcon className={clsx(classes.iconPreview, "-icon")} />,
  };

  React.useEffect(() => {
    const editMode = Object.entries(edit).some((e) => !!e[1]);
    setActionEdit(editMode);
  }, [edit]);

  React.useEffect(() => {
    if (allowAll) {
      const newAllowedActions = new Set();
      _.each(Object.keys(triggerConfig), (k) => {
        _.each(triggerConfig[k].actions, (act) => newAllowedActions.add(act));
      });
      setAllowedActions((prev) => [...newAllowedActions]);

      return;
    }

    if (!trigger) {
      return;
    }
    const newAllowedActions =
      trigger === "guest_event" ? [] : triggerConfig[trigger]?.actions || [];
    setAllowedActions((prev) => newAllowedActions);
  }, [trigger]);

  React.useEffect(() => {
    if (!!newService) {
      const newActionType =
        newService === "msg"
          ? ""
          : serviceActions[newService].filter((actionKey) =>
              allowedActions.includes(actionKey),
            )[0];
      setSelectedActionTypes((prev) => ({...prev, new: newActionType}));
      setEdit((prev) => ({...prev, new: true}));
    }
  }, [newService]);

  React.useEffect(() => {
    resetData();
  }, [data]);

  const resetData = () => {
    if (!data) {
      setEdit((prev) => ({new: false}));
      setSelectedActionTypes((prev) => ({new: ""}));
      setActionOptions((prev) => ({new: {}}));
      return;
    }
    let newEditStates = {new: false};
    let newActionIDs = {new: ""};
    let newActionOptions = {new: {}};
    let newServices = {new: ""};
    for (let i = 0; i < data.length; i++) {
      const el = data[i];
      newEditStates[el.action_id] = edit[el.action_id] || false;
      newActionIDs[el.action_id] = el.action_id;
      newActionOptions[el.action_id] = el ?? {};
      newServices[el.action_id] =
        el.service ?? actionParams[el.action_id]?.service_account;
    }
    setEdit((prev) => newEditStates);
    setSelectedActionTypes((prev) => newActionIDs);
    setActionOptions((prev) => newActionOptions);
  };

  const onEdit = (actionId) => () =>
    setEdit((prev) => ({...prev, [actionId]: true}));

  const onCancel = (actionId) => () => {
    removeNewService();
    resetData();
    setEdit((prev) => ({...prev, [actionId]: false}));
  };

  const onDelete = (actionId) => () => {
    if (!!handleDelete) {
      handleDelete(actionId);
    } else {
      const newActions = data.filter((act) => !(act.action_id === actionId));
      setData(newActions);
    }
  };

  const onSave = (actionId, isNew, objToUpdateByForce) => {
    if (objToUpdateByForce) {
      const newObj = [...data];
      const index = newObj.findIndex((i) => i.action_id === actionId);
      if (index !== -1) {
        newObj[index] = objToUpdateByForce;
      }
      setData(newObj);
      return;
    }

    removeNewService();
    setEdit((prev) => ({...prev, [isNew ? "new" : actionId]: false}));
    const newActions = [];
    _.each(Object.entries(selectedActionTypes), (at) => {
      if (at[0] !== "new") {
        newActions.push({...actionOptions[at[0]], action_id: at[1]});
      } else if (isNew) {
        newActions.push({
          ...actionOptions.new,
          action_id: selectedActionTypes.new,
        });
      }
    });
    setData(newActions);
  };

  const handleActionTypeChange = (action, value) => {
    const val = value;
    setSelectedActionTypes((prev) => ({...prev, [action.action_id]: val}));
  };

  const handleActionContentChange = (actionId) => (newContent, forceUpdate) => {
    if (forceUpdate) {
      onSave(actionId, false, newContent);
    } else {
      setActionOptions((prev) => ({...prev, [actionId]: newContent}));
    }
  };

  function getActionComponent(action, edit) {
    const selectedAction = selectedActionTypes[action.action_id];
    const props = {
      key: action.action_id,
      edit: edit,
      data: action,
      selectedAction,
      disableEdit,
      hideEdit,
      disableDelete,
      variant,
      editData: handleActionContentChange(action.action_id),
      onEdit: disableEdit ? () => null : onEdit(action.action_id),
      onDelete: disableEdit ? () => null : onDelete(action.action_id),
      additionalObjectsVariables,
    };
    switch (selectedAction?.toLowerCase()) {
      case "sms":
      case "airbnb":
      case "email":
      case "ota_email":
        return (
          <MessageAction setIsContentValid={setIsContentValid} {...props} />
        );
      case "webhook":
        return (
          <WebhookAction setIsContentValid={setIsContentValid} {...props} />
        );
      case "respond":
      case "call":
        return <PhoneCallAction {...props} />;
      case "provide_access":
      case "revoke_access":
      case "unlock_box":
      case "lock_box":
      case "unlock_door":
      case "lock_door":
        return <AccessAction {...props} />;
      case "whatsapp":
        return <WhatsappAction {...props} />;

      default:
        return null;
    }
  }

  function getActionSelector(action) {
    const service =
      action.service ?? actionParams[action.action_id].service_account;
    if (service === "skb") {
      const labels = serviceActions[service].map(
        (actKey) => actionParams[actKey].name,
      );
      const lockIcon = (
        <SvgIcon
          viewBox="0 0 16 22"
          className="-button-icon"
          component={LockIcon}
        />
      );
      const unlockIcon = (
        <SvgIcon
          viewBox="0 0 22 22"
          className="-button-icon"
          component={UnlockIcon}
        />
      );
      return (
        <MultiButtonBar
          dividers
          value={selectedActionTypes[action.action_id]}
          options={serviceActions[service]}
          icons={[lockIcon, unlockIcon]}
          labels={labels}
          onChange={(option) => handleActionTypeChange(action, option)}
        />
      );
    } else {
      return (
        <FilledSelect
          fullWidth
          value={selectedActionTypes[action.action_id]}
          label={
            service === "msg"
              ? "How should the message be sent?"
              : "Action Type"
          }
          onChange={(e) => handleActionTypeChange(action, e.target.value)}
        >
          <MenuItem value="" disabled>
            Select a channel
          </MenuItem>
          {experienceEditorActions.map((actKey) => {
            if (allowedActions.length > 0 && !allowedActions.includes(actKey)) {
              return null;
            }
            const actionExists = actionTypesValues.includes(actKey);
            return (
              <MenuItem
                key={actKey}
                value={actKey}
                selected={actionExists}
                disabled={actionExists}
                classes={{selected: classes.itemSelected}}
              >
                {actionParams[actKey].name}
              </MenuItem>
            );
          })}
        </FilledSelect>
      );
    }
  }

  function getActionContent(edit, action) {
    const service =
      action.service ?? actionParams[action.action_id].service_account;
    const saveDisabled = !selectedActionTypes[action.action_id];
    const actionComponent = getActionComponent(action, edit);
    const isNew = action.action_id === "new";
    return edit ? (
      <div key={action.action_id} className={classes.card}>
        <div className={clsx(classes.titleRow)}>
          {actionIcons[service]}
          <Typography className={classes.title}>
            {serviceLabels[service]}
          </Typography>
        </div>
        {getActionSelector(action)}
        {actionComponent}
        <div className={classes.actions}>
          <Button
            className="mr-2"
            classes={{label: classes.cancelBtn}}
            size="small"
            onClick={onCancel(action.action_id)}
          >
            Cancel
          </Button>
          <PrimaryButton
            size="small"
            label="Done"
            className="mr-2"
            disabled={saveDisabled || !isContentValid}
            onClick={() => onSave(selectedActionTypes[action.action_id], isNew)}
          />
        </div>
      </div>
    ) : (
      actionComponent
    );
  }

  return (
    <>
      {data?.map((action) => getActionContent(edit[action.action_id], action))}
      {!!newService &&
        getActionContent(edit.new, {
          ...actionOptions.new,
          action_id: "new",
          service: newService,
        })}
    </>
  );
}
