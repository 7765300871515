import React from "react";
import {useSelector} from "react-redux";
// UI
import {SvgIcon, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as BusinessIcon} from "assets/icons/Icon_Buildings.svg";
import {ReactComponent as ListingIcon} from "assets/icons/Icon_House.svg";
import HouseGroupIcon from "@material-ui/icons/HomeWork";
import {ReactComponent as HomeIcon} from "assets/icons/home.svg";
// Custom
import ListingConnection from "components/MultiOption/ListingConnection";
import MultiButtonBar from "core/buttons/MultiButtonBar";
// Utils
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  listingsContainer: {
    display: "flex",
    "&.-open": {height: "100%"},
  },
  section: {
    "&.fullHeight": {height: "100%"},
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  sectionTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
    padding: theme.spacing(1, 0),
    "& > .icon": {
      width: 16,
      height: 16,
    },
  },
}));

export const icons = {
  all: (
    <SvgIcon
      viewBox="0 0 18 16"
      className="-button-icon"
      component={BusinessIcon}
    />
  ),
  listing_group: <HouseGroupIcon />,
  listing: (
    <SvgIcon
      viewBox="0 0 18 16"
      className="-button-icon"
      component={ListingIcon}
    />
  ),
};

export default function ConnectedToButton({
  listing_id,
  group_id,
  data,
  editData,
  disabled = false,
  useThisListingOnly,
  disabledOptions = [],
  listingsOpen,
  setListingsOpen = () => null,
  onlyOneSelection,
  setEmptyListings = () => null,
  showSectionTitle = false,
  type = null,
  title = "Connected Listings",
  isGroupView = false,
  listing,
}) {
  const classes = useStyles({});
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const {buttonOptions, buttonLabels} = React.useMemo(() => {
    let buttons = ["all"];
    let labels = disabledOptions.includes("all") ? [] : ["All listings"];
    if (!!group_id && !disabledOptions.includes("group")) {
      buttons.push("group");
      labels.push("Current listing group");
    }
    if (!disabledOptions.includes("ids")) {
      buttons.push("ids");
      if (useThisListingOnly) {
        labels.push("This listing");
      } else {
        labels.push("Specific Listings");
      }
    }
    return {
      buttonOptions: buttons.filter((b) => !disabledOptions.includes(b)),
      buttonLabels: labels,
    };
  }, [group_id]);

  const [listingConnectionHelper, setListingConnectionHelper] = React.useState({
    listings: [],
    groupId: null,
    connection_type: data?.connection_type ?? "all",
  });

  const [connectionData, setConnectionData] = React.useState({
    connected_ids: {},
    group_ids: [],
    connection_type: "all",
    connected_object: "listing",
  });

  const resetConnectionData = () => {
    let newConnectionData = {
      connected_ids:
        data?.connected_ids ?? (isGroupView ? {} : {listing: [listing?.id]}),
      group_ids: data?.group_ids ?? (isGroupView ? [group_id] : []),
      connection_type: data?.connection_type ?? (isGroupView ? "group" : "ids"),
      connected_object: "listing",
    };
    setConnectionData((prev) => newConnectionData);
    if (!data?.connection_type) {
      editData({...data, ...newConnectionData});
    }
  };

  React.useEffect(() => {
    if (connectionData.connection_type === "ids") {
      setEmptyListings(!connectionData.connected_ids.listing?.length);
    } else {
      setEmptyListings(false);
    }
    setListingConnectionHelper((prev) => ({
      ...prev,
      connection_type: connectionData.connection_type,
    }));
  }, [connectionData]);

  React.useEffect(() => {
    let initialConnectionType =
      data?.connection_type ?? (isGroupView ? "group" : "ids");
    resetConnectionData();
    if (
      !!data?.connected_ids?.listing?.length &&
      initialConnectionType === "ids"
    ) {
      setListingConnectionHelper((prev) => ({
        ...prev,
        listings: data.connected_ids.listing,
      }));
    } else if (!!listing_id) {
      setListingConnectionHelper((prev) => ({
        ...prev,
        listings: [`l#${listing_id}`],
      }));
    }
  }, []);

  const handleConnectionChange = (option) => {
    let newConnectionData = {...connectionData};
    if (option === "ids") {
      // Specific listings
      const listingSelected = listing_id ?? null;

      newConnectionData.connection_type = "ids";
      newConnectionData.connected_ids = !!listingSelected
        ? {
            listing: listingConnectionHelper.listings.map((l) =>
              l.startsWith("l#") ? l : `l#${l}`,
            ),
          }
        : !!listing_id
          ? {listing: [houses[listing_id]?.id]}
          : {};
      newConnectionData.group_ids = [];
    } else if (option === "group") {
      newConnectionData.connection_type = "group";
      newConnectionData.connected_ids = {};
      newConnectionData.group_ids = [group_id];
      setListingsOpen(false);
    } else {
      // All listings
      newConnectionData.connection_type = "all";
      newConnectionData.connected_ids = {};
      newConnectionData.group_ids = [];
      setListingsOpen(false);
    }
    editData({...data, ...newConnectionData});
    setConnectionData((prev) => newConnectionData);
    setListingConnectionHelper((prev) => ({
      ...prev,
      connection_type: newConnectionData.connection_type,
    }));
  };

  const handleDataChange = (newData) => {
    let newConnectionData = {
      ...connectionData,
      connection_type: "ids",
      connected_ids: {
        listing: newData.listings.map((l) =>
          l.startsWith("l#") ? l : `l#${l}`,
        ),
      },
      group_ids: [],
    };
    setListingConnectionHelper((prev) => newData);
    setConnectionData((prev) => newConnectionData);
    editData({...data, ...newConnectionData});
  };

  return (
    <div className={clsx(classes.section, {fullHeight: listingsOpen})}>
      {!!showSectionTitle && (
        <>
          <div className={classes.sectionTitle}>
            <SvgIcon
              className="icon"
              viewBox="0 0 18 17"
              component={HomeIcon}
            />
            <Typography className="title" variant="h1">
              {title}
            </Typography>
          </div>
          <Typography>
            {`Choose whether to add your ${type} to all your listings${
              !!group_id ? ", your current listing group," : ""
            }${
              !useThisListingOnly
                ? " or specific listings."
                : " or this current listing."
            }`}
          </Typography>
        </>
      )}
      <MultiButtonBar
        thin
        dividers
        disabled={disabled}
        value={connectionData.connection_type}
        onChange={handleConnectionChange}
        options={buttonOptions}
        icons={[icons.all, icons.listing_group, icons.listing]}
        labels={buttonLabels}
      />
      {!useThisListingOnly && connectionData.connection_type === "ids" && (
        <div
          className={clsx(classes.listingsContainer, {"-open": listingsOpen})}
        >
          <ListingConnection
            data={listingConnectionHelper}
            type={type}
            listingKey="id"
            disableConnectionTypes
            disableEdit={disabled}
            onlyOneSelection={onlyOneSelection}
            showHouseList={listingsOpen}
            setShowHouseList={setListingsOpen}
            setData={handleDataChange}
          />
        </div>
      )}
    </div>
  );
}
