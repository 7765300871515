import {all as mergeObjects} from "deepmerge";
import _ from "lodash";

function replaceObj(rootArr, newObj, idKey) {
  return rootArr.map((obj) => {
    if (obj[idKey] === newObj[idKey]) {
      return newObj;
    } else {
      return obj;
    }
  });
}

function arrayToDictByKey(
  arr,
  key,
  multipleKeys = false,
  keyToGroupBy = false,
) {
  let dict = {};
  if (arr) {
    if (multipleKeys) {
      key.forEach((k) => {
        dict[k] = {};
        arr.forEach((el) => {
          let elKey = el[k];
          let elDict = dict[k][elKey] || {};
          if (k === keyToGroupBy) {
            dict[k] = {...dict[k], [elKey]: el};
            return;
          }
          elDict = {...elDict, [el[keyToGroupBy]]: el};
          dict[k] = {...dict[k], [elKey]: elDict};
        });
      });
    } else {
      arr.forEach((el) => {
        if (keyToGroupBy) {
          let elKey = el[keyToGroupBy];
          dict[elKey] = {...dict[elKey], [el[key]]: el};
        } else {
          dict[el[key]] = el;
        }
      });
    }
  }
  return dict;
}

export function stateCollectionSet({
  state,
  actionData,
  name,
  keyField,
  multipleKeys = false,
  keyToGroupBy = false,
}) {
  let modification = {};
  modification[name] = actionData;
  modification[name + "_dict"] = arrayToDictByKey(
    actionData,
    keyField,
    multipleKeys,
    keyToGroupBy,
  );
  return {
    ...state,
    ...modification,
  };
}

export function stateCollectionAdd({
  state,
  actionData,
  name,
  keyField,
  multipleKeys = false,
  keyToGroupBy = false,
  orderOptions = null,
}) {
  let modification = {};
  if (!Array.isArray(actionData)) {
    actionData = [actionData];
  }
  let responseDict = arrayToDictByKey(
    actionData,
    keyField,
    multipleKeys,
    keyToGroupBy,
  );
  modification[name] = _.unionBy(
    state[name],
    actionData,
    multipleKeys ? keyToGroupBy : keyField,
  );
  if (!!orderOptions) {
    modification[name] = _.orderBy(
      modification[name],
      orderOptions.fields,
      orderOptions.order,
    );
  }
  let responseKeys = new Set(_.map(actionData, (x) => x[keyToGroupBy]));
  let nameDict = `${name}_dict`;
  modification[nameDict] = mergeObjects([state[nameDict], responseDict], {
    isMergeableObject: (x) => {
      if (_.isArray(x)) {
        return false;
      }
      return (
        _.isObject(x) &&
        !(x.hasOwnProperty(keyToGroupBy) && responseKeys.has(x[keyToGroupBy]))
      );
    },
  });
  return {
    ...state,
    ...modification,
  };
}

export function stateCollectionEdit({
  state,
  actionData,
  name,
  keyField,
  multipleKeys = false,
  keyToGroupBy = false,
  orderOptions = null,
}) {
  let modification = {};
  let newDict = {...state[name + "_dict"]};
  if (multipleKeys) {
    const newArr = state[name].map((el) => {
      if (el[keyToGroupBy] === actionData[keyToGroupBy]) {
        return actionData;
      } else return el;
    });
    modification[name] = !!orderOptions
      ? _.orderBy(newArr, orderOptions.fields, orderOptions.order)
      : newArr;
    keyField.forEach((k) => {
      if (k === keyToGroupBy) newDict[k][actionData[keyToGroupBy]] = actionData;
      else newDict[k][actionData[k]][actionData[keyToGroupBy]] = actionData;
    });
  } else {
    modification[name] = replaceObj(state[name], actionData, keyField);
    if (!!orderOptions) {
      modification[name] = _.orderBy(
        modification[name],
        orderOptions.fields,
        orderOptions.order,
      );
    }
    if (keyToGroupBy)
      newDict[actionData[keyToGroupBy]][actionData[keyField]] = actionData;
    else newDict[actionData[keyField]] = actionData;
  }
  modification[name + "_dict"] = newDict;
  return {
    ...state,
    ...modification,
  };
}

export function stateCollectionDelete({
  state,
  keyValue,
  name,
  keyField,
  isGrouped = false,
  IDKey = false,
}) {
  let modification = {};
  let objectDictPruned = {...state[name + "_dict"]};
  if (!isGrouped) {
    delete objectDictPruned[keyValue];
    modification[name] = state[name].filter(
      (element) => element[keyField] !== keyValue,
    );
  } else {
    const newObjectArray = state[name].filter(
      (element) => element[IDKey] !== keyValue,
    );
    modification[name] = newObjectArray;
    objectDictPruned = {};
    _.each(keyField, (key) => {
      _.each(newObjectArray, (newArrEl) => {
        objectDictPruned[key] = {
          ...objectDictPruned[key],
          [newArrEl[IDKey]]: newArrEl,
        };
      });
    });
  }
  modification[name + "_dict"] = objectDictPruned;
  return {
    ...state,
    ...modification,
  };
}
