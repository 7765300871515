import React, {useState} from "react";
import {Checkbox, MenuItem, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import SearchBar from "../../../../core/bars/SearchBar";
import CustomMenu from "core/menus/CustomMenu";

const useStyles = makeStyles((theme) => ({
  columnsMenu: {
    maxHeight: 300,
    overflowY: "auto",
  },
  columnsMenuItem: {
    display: "flex",
    alignItems: "center",
  },
  stickyContainer: {
    position: "relative",
  },
  stickySearchBar: {
    position: "sticky",
    top: "7px",
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    padding: theme.spacing(1),
  },
}));

const CrmColumnsMenu = ({
  allColumns,
  selectedColumns,
  setSelectedColumns,
  fieldParams,
  columnsMenuAnchorEl,
  closeColumnsMenu,
}) => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState("");
  const filteredColumns = allColumns.filter((field) => {
    const fieldName = fieldParams[field]?.name || field;
    return fieldName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <CustomMenu
      open={Boolean(columnsMenuAnchorEl)}
      anchorEl={columnsMenuAnchorEl}
      placement="bottom"
      width="200px"
      hideScrollbar
      onClose={() => {
        setSearchQuery("");
        closeColumnsMenu();
      }}
      content={
        <div id="columns-menu" className={classes.stickyContainer}>
          <div className={classes.stickySearchBar}>
            <SearchBar
              style={{marginLeft: "0px", marginBottom: "10px"}}
              handleSearchInput={(val) => {
                setSearchQuery(val);
              }}
            />
          </div>
          <div className={classes.columnsMenu}>
            {filteredColumns.map((field) => (
              <MenuItem
                key={field}
                className={classes.columnsMenuItem}
                onClick={() => {
                  let prev = selectedColumns;
                  let cols = selectedColumns.includes(field)
                    ? prev.filter((f) => f !== field)
                    : [...new Set([...prev, field])];

                  setSelectedColumns(cols);
                }}
              >
                <Checkbox checked={selectedColumns.includes(field)} />
                <Typography
                  style={{
                    maxWidth: "300px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {fieldParams[field]?.name || field}
                </Typography>
              </MenuItem>
            ))}
          </div>
        </div>
      }
    />
  );
};

export default CrmColumnsMenu;
