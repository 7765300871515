import React from "react";
import {useDispatch} from "react-redux";
import {Chip, SvgIcon} from "@material-ui/core";
import {ReactComponent as FeeIcon} from "assets/icons/dollar-sign.svg";
import {ReactComponent as UpsellIcon} from "assets/icons/shopping-bag.svg";
import useBookingPanelStyles from "styles/useBookingPanelStyles";
// Custom
import CurrencyText from "components/TextFields/CurrencyText";
import AccordionItem from "core/listItems/AccordionItem";
import WarningAlert from "core/alerts/WarningAlert";
import Payment from "./Payment";
// Actions
import {editGuest} from "redux/actions/guestsActions";
// Utils
import {productsFees, productsUpsells} from "configuration/enums";
import {capitalize} from "utilities/helperFunctions";
import usePrevious from "hooks/usePrevious";
import clsx from "clsx";

export default function BookingFeesUpsells({
  booking,
  guest,
  handleAction,
  type = "upsells",
  loading,
}) {
  const isFee = type === "fees";
  const classes = useBookingPanelStyles();
  const dispatch = useDispatch();
  const buttonClicked = React.useRef(null);
  const [expandedSection, setExpandedSection] = React.useState(false);
  const [expandedItem, setExpandedItem] = React.useState([]);
  const [billsArray, setBillsArray] = React.useState([]);
  const actionsArray = React.useMemo(() => {
    if (isFee) {
      return booking?.actions?.bp_fees || [];
    } else {
      return booking?.actions?.bp_upsells || [];
    }
  }, [booking, guest]);
  const prevActionsArray = usePrevious(actionsArray);
  const totalPaid = React.useMemo(() => {
    if (!billsArray.length) {
      return 0;
    } else {
      return billsArray.reduce(
        (acc, curr) =>
          acc + (["paid", "on_hold"].includes(curr.status) ? 1 : 0),
        0,
      );
    }
  }, [billsArray]);
  let isActionInProgress = !!loading?.length;

  React.useEffect(() => resetBills(guest?.bills || []), [booking, guest]);

  React.useEffect(() => {
    if (!prevActionsArray?.length && !!actionsArray.length) {
      setExpandedSection((prev) => true);
    }
  }, [actionsArray]);

  const handleActionClick = (props, type) => () => {
    buttonClicked.current = type;
    handleAction(props, type);
  };

  const resetBills = (bills) => {
    let newBills = [];
    if (isFee) {
      newBills = bills.filter(
        (bill) =>
          bill.booking_id === booking.booking_id &&
          (bill.product_type === "custom_fee" ||
            !!productsFees[bill.product_type]),
      );
      setBillsArray((prev) => newBills);
      setExpandedItem((prev) => newBills.map((nb) => false));
    } else {
      newBills = bills.filter(
        (bill) =>
          bill.booking_id === booking.booking_id &&
          (bill.product_type === "custom_upsell" ||
            !!productsUpsells[bill.product_type]),
      );
      setBillsArray((prev) => newBills);
      setExpandedItem((prev) => newBills.map((nb) => false));
    }
  };

  const handleBillUpdate = (newBill) => {
    const newBills = (guest?.bills || []).map((b) =>
      b.id === newBill.id ? newBill : b,
    );
    resetBills(newBills);
    if (!!guest) {
      dispatch(editGuest({...guest, bills: newBills}));
    }
  };

  if (!actionsArray.length && !billsArray.length) {
    return null;
  }
  return (
    <>
      <AccordionItem
        divider
        disableHover
        expanded={expandedSection}
        infoText={`${totalPaid}/${billsArray.length} paid`}
        title={isFee ? "Fees" : "Upsells"}
        onClick={() => setExpandedSection((prev) => !prev)}
        icon={
          <SvgIcon
            viewBox="0 0 16 16"
            component={isFee ? FeeIcon : UpsellIcon}
          />
        }
      />
      {!!expandedSection && (
        <div className={classes.expandedSection}>
          {/* Alerts */}
          {actionsArray.map((props, index) => (
            <WarningAlert
              key={`bkg_alert_${index}`}
              hide={false}
              disableMargin
              action={handleActionClick(props, "confirm")}
              actionLoading={
                loading.includes(props?.label) &&
                buttonClicked.current === "confirm"
              }
              actionButtonLabel={props.confirm?.label}
              disableActionBtn={isActionInProgress}
              secondaryAction={handleActionClick(props, "deny")}
              secondaryActionLoading={
                loading.includes(props.label) &&
                buttonClicked.current === "deny"
              }
              secondaryActionLabel={props.deny?.label}
              disableSecondaryActionBtn={isActionInProgress}
              title={props.label}
            />
          ))}
          {/* List */}
          {billsArray.map((bill, index) => (
            <div
              key={bill.id}
              className={clsx(classes.expandedArea, {
                "-open": !!expandedItem[index],
              })}
            >
              <AccordionItem
                border
                expanded={expandedItem[index]}
                title={bill.title}
                infoText={capitalize(bill.status, "_").toUpperCase()}
                infoTextProps={{bold: true}}
                hoverInfo={
                  <Chip
                    label={
                      <CurrencyText
                        bold
                        sm
                        value={bill.total}
                        currency={bill.currency}
                      />
                    }
                    classes={{
                      root: classes.chip,
                      label: classes.chipLabel,
                    }}
                  />
                }
                onClick={() =>
                  setExpandedItem((prev) =>
                    prev.map((ei, ind) => (ind === index ? !ei : ei)),
                  )
                }
              />
              {!!expandedItem[index] && (
                <Payment
                  bill={bill}
                  metadata={guest.metadata}
                  actionDisabled={isActionInProgress}
                  updateBill={handleBillUpdate}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}
