import {makeStyles} from "@material-ui/core";
import {FIXED_SIZES} from "configuration/settings";

const useCreateFromExistingListStyles = makeStyles((theme) => ({
  container: {
    overflowX: "hidden",
    overflowY: "auto",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "&::-webkit-scrollbar": {width: "0!important"},
    "& .searchContainer": {
      padding: 0,
      marginBottom: theme.spacing(3),
    },
  },
  collapseWrapper: {
    height: "100%",
    overflow: "hidden",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  listItem: {
    padding: theme.spacing(3, 0),
    borderRadius: 5,
  },
  listItemText: {margin: 0},
  divider: {
    backgroundColor: "#F0F0F0",
    "&.mt3": {marginTop: theme.spacing(3)},
  },
  arrow: {
    transition: "0.15s",
    "&.expanded": {transform: "rotate(90deg)"},
  },
  resourcesContainer: {
    backgroundColor: "#F5F5F5",
    borderRadius: 5,
    padding: theme.spacing(4, 4, 3),
    marginTop: theme.spacing(2),
  },
  listContainer: {
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    "&::-webkit-scrollbar": {width: "0!important"},
    "& > div.listing-list": {
      flex: 1,
      minHeight: 110,
    },
  },
  innerVirtualList: {
    minHeight: FIXED_SIZES.copy_existing_resource_item,
    maxHeight: "60vh",
  },
}));

export default useCreateFromExistingListStyles;
