import {API} from "aws-amplify";

export const getDevices = async (params) => {
  try {
    let response = await API.get("ensoAPI", `/devices?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getDevicesLocked = async (enso_key, device_id) => {
  try {
    let response = await API.get(
      "ensoAPI",
      `/devices/locked?enso_key=${encodeURIComponent(enso_key)}&device_id=${encodeURIComponent(device_id)}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const patchDevice = async (body) => {
  try {
    const response = await API.patch("ensoAPI", "/devices", body);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteDevice = async (params) => {
  try {
    const response = await API.del("ensoAPI", `/devices?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};
