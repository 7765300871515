import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
// Redux
import {
  deleteListingResource,
  getListingGroupDetails,
  handleUpdateGroup,
  saveListingResource,
  updateListingResource,
} from "redux/actions/listingsActions";
import {getServiceAccounts} from "redux/actions/servicesActions";
// UI
import {Box} from "@material-ui/core";
import {alpha, makeStyles, styled} from "@material-ui/core/styles";
// Components
import BackButton from "core/buttons/BackButton";
import CustomCardHeader from "core/cards/CustomCardHeader";
import CloseIconButton from "core/buttons/CloseIconButton";
import CreationButton from "components/MultiOption/CreationButton";
import ProductModal from "components/Dialogs/ProductModal";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import GJEInfoCard from "components/Cards/GJEInfoCard";
import {EmptyHouseProducts} from "components/Helpers/EmptyPanels";
import StripeAccountCard from "components/Cards/StripeAccountCard";
import SelectExistingDialog from "../dialog/SelectExistingDialog";
import SelectTemplateDialog from "../dialog/SelectTemplateDialog";
import UpsellsList from "../list/UpsellsList";
// Utils
import {
  multiLangArrayToObj,
  multiLangObjToArray,
} from "utilities/formatUtilities";
import {getProductBGImage, newUuid} from "utilities/helperFunctions";
import {productsUpsells} from "configuration/enums";
import _ from "lodash";

const Container = styled(Box)(({theme}) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
}));

const Content = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  padding: theme.spacing(4),
  paddingTop: 0,
  "&::-webkit-scrollbar": {width: "0!important"},
}));

const Row = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(5),
}));

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.05)}`,
  },
}));

const GJEUpsellsPanel = ({goBack, onClose}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const {group_id, step, card} = useParams();
  const dispatch = useDispatch();
  const saveOption = useRef(null);
  const prevResources = useRef([]);
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  const listings = useSelector((state) => state.defaultReducer.house_data_dict);
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  const [upsells, setUpsells] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [headerImage, setHeaderImage] = useState(null);
  const [modalContentType, setModalContentType] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [productToDelete, setProductToDelete] = useState(null);
  const [skus, setSkus] = useState({});
  const [langs, setLangs] = useState(["en"]);
  const [selectedLang, setSelectedLang] = useState("en");
  const [textContent, setTextContent] = useState({
    name: {en: ""},
    desc: {en: ""},
    skus: {},
  });
  const [stripeAccount, setStripeAccount] = useState(
    serviceAccounts?.accounts?.stripe?.[0],
  );
  const selectedGroup = listingGroups[group_id];
  const selectedCurrency =
    listings[selectedGroup?.connected_to?.ids?.[0]]?.currency;
  const selectFromOptionEnabled = ["existing", "template"].includes(
    modalContentType,
  );

  useEffect(() => {
    if (!!openModal && !selectFromOptionEnabled) {
      return;
    }
    if (!serviceAccounts?.accounts) {
      setStripeAccount(false);
      dispatch(getServiceAccounts({stripeOnly: true}));
    }
    const groupData =
      selectedGroup?.guest_journey?.sections?.[step]?.cards[card]?.data ?? [];
    setUpsells(groupData);
  }, [openModal, selectFromOptionEnabled, listingGroups, group_id]);

  useEffect(() => {
    if (!!Object.keys(serviceAccounts?.accounts ?? {}).length) {
      setStripeAccount((prev) => serviceAccounts?.accounts?.stripe?.[0]);
    }
  }, [serviceAccounts]);

  const resetContent = (product) => {
    const {newContent, newLangs} = multiLangArrayToObj([
      {key: "name", newKey: "name", content: product.properties},
      {key: "description", newKey: "desc", content: product.properties},
      {
        key: "skus",
        newKey: "skus",
        content: product.properties,
        subfieldKeys: ["name"],
      },
    ]);
    let newSkus = {};
    _.each(product.properties?.skus || [], (s) => {
      newSkus[s.sku_id] = s;
    });
    setTextContent((prev) => newContent);
    setSkus((prev) => newSkus);
    setLangs((prev) => newLangs);
    setSelectedLang((prev) => newLangs[0]);
    setHeaderImage((prev) => product.properties?.header_image ?? null);
  };

  function handleAddProduct(option) {
    saveOption.current = option;
    const newProduct = {
      ...selectedProduct,
      properties: {
        ...selectedProduct.properties,
        ...formatMultiLangTextContentForRequest(),
      },
    };

    if (!!headerImage) {
      newProduct.properties.header_image = headerImage;
    }

    prevResources.current = [...upsells];

    // Update product
    if (!!newProduct.resource_id) {
      handleUpdateGroup({
        action: "update",
        group: selectedGroup,
        section: step,
        card,
        listingResource: newProduct,
        dispatch,
      });
      setUpsells((prev) =>
        prev.map((pr) =>
          pr.resource_id === newProduct.resource_id ? newProduct : pr,
        ),
      );
      dispatch(
        updateListingResource({
          body: {...newProduct, resource: "up"},
          disableListingUpdate: true,
          onSuccess: (res) => {
            setUpsells((prev) =>
              prevResources.current.map((r) =>
                r.resource_id === res.resource_id ? res : r,
              ),
            );
            dispatch(
              getListingGroupDetails({groupId: group_id, section: step, card}),
            );
          },
          onError: () => {
            setUpsells((prev) => [...prevResources.current]);
            resourceCallback();
          },
        }),
      );

      // Create product
    } else {
      handleUpdateGroup({
        action: "add",
        group: selectedGroup,
        section: step,
        card,
        listingResource: newProduct,
        dispatch,
      });
      setUpsells((prev) => [...prev, newProduct]);
      dispatch(
        saveListingResource({
          body: {...newProduct, resource: "up"},
          type: "upsell",
          disableListingUpdate: true,
          onSuccess: (res) => {
            let updatedResources = [...prevResources.current, res];
            setUpsells((prev) => updatedResources);
            dispatch(
              getListingGroupDetails({groupId: group_id, section: step, card}),
            );
          },
          onError: () => {
            setUpsells((prev) => [...prevResources.current]);
            resourceCallback();
          },
        }),
      );
    }
    closeModal();
  }

  const addProduct = (product, type) => {
    let newSku = {
      is_fee: false,
      is_custom: !product?.requires_stripe,
      is_new: true,
      ind: null,
      resource: "up",
      properties: {
        conditions: product?.conditions || [],
        skus: product?.skus?.map((s) => ({
          ...s,
          sku_id: `new_sku_${newUuid()}`,
        })) || [{value: 10, unit: "1", sku_id: `new_sku_${newUuid()}`}],
        name: product?.name || "Custom",
        product_type: type,
        currency: selectedCurrency,
        manually_approve: true,
        header_image: product?.header_image ?? null,
        description: product?.description,
      },
    };
    resetContent(newSku);
    setSelectedProduct((prev) => newSku);
    setOpenModal(true);
  };

  const selectExistingProduct = (p) => {
    let newSku = {
      is_fee: false,
      is_custom: p.properties.product_type.startsWith("custom_"),
      is_new: true,
      ind: null,
      resource: p.resource,
      properties: {
        conditions: p.properties.conditions || [],
        skus:
          p.properties.skus?.map((s) => ({
            ...s,
            sku_id: `new_sku_${newUuid()}`,
            properties: undefined,
          })) || [{value: 10, unit: "1", sku_id: `new_sku_${newUuid()}`}] ||
          [],
        name: p.properties.name || "Custom",
        product_type: p.properties.product_type,
        currency: selectedCurrency,
        manually_approve: p.properties.manually_approve,
        description: p.properties.description || [{language: "en", value: ""}],
      },
    };
    resetContent(newSku);
    setSelectedProduct((prev) => newSku);
    setModalContentType((prev) => null);
    setHeaderImage((prev) => p.properties?.header_image ?? null);
  };

  const deleteProduct = () => {
    handleUpdateGroup({
      action: "delete",
      group: selectedGroup,
      section: step,
      card,
      listingResource: productToDelete,
      dispatch,
    });
    dispatch(
      deleteListingResource({
        resource: "up",
        type: "upsell",
        resource_id: productToDelete.resource_id,
        disableListingUpdate: true,
        onSuccess: resourceCallback,
        onError: resourceCallback,
      }),
    );
  };

  const resourceCallback = () => {
    saveOption.current = null;
    setProductToDelete((prev) => null);
    dispatch(getListingGroupDetails({groupId: group_id, section: step, card}));
  };

  const handleRemoveProduct = () => {
    setUpsells(upsells.filter((_, i) => i === productToDelete.index));
    setProductToDelete((prev) => null);
    deleteProduct();
  };

  const handleMenuOptionClick = (actionType, type) => () => {
    setModalContentType((prev) => actionType);
    if (["existing", "template"].includes(actionType)) {
      setOpenModal((prev) => true);
    } else {
      addProduct(null, type);
    }
  };

  const selectProductTemplate = (template, k) => {
    addProduct(template, template.product_type);
    setModalContentType((prev) => null);
    setHeaderImage((prev) => getProductBGImage("upsells", k));
  };

  const closeModal = () => {
    setOpenModal(false);
    setModalContentType(null);
    setSelectedProduct({});
    setHeaderImage(null);
  };

  const openProductModal = (product, key) => {
    resetContent(product);
    setSelectedProduct({
      ...product,
      is_custom: product.properties.product_type.startsWith("custom_"),
      ind: key,
      is_fee: false,
    });
    setOpenModal(true);
  };

  const formatSkus = (newSkus) => {
    let newFormattedSkus = [];
    _.each(newSkus, (s) => {
      let ns = {...s, value: Number(s.value)};
      if (newSkus.length === 1) {
        delete ns.name;
      }
      if (s.sku_id.includes("new_sku_")) {
        delete ns.sku_id;
      }
      if (s.unit.includes("{input_value}")) {
        ns.unit = ns.unit.replace("{input_value}", ns.value / 100);
        ns.value = "1";
        if (s.hasOwnProperty("value2")) {
          ns.unit = ns.unit.replace("{input_value_2}", ns.value2);
          delete ns.value2;
        }
      }
      newFormattedSkus.push(ns);
    });
    return newFormattedSkus;
  };

  const formatMultiLangTextContentForRequest = () => {
    let newContent = multiLangObjToArray(textContent, langs, [
      {key: "name", requestKey: "name"},
      {key: "desc", requestKey: "description", isHTML: true},
      {
        key: "skus",
        requestKey: "skus",
        subfieldProps: {
          keys: ["name"],
          object: skus,
        },
      },
    ]);

    return {...newContent, skus: formatSkus(newContent.skus)};
  };

  const getUpdatedContent = (addResource) => {
    const newProduct = {
      ...selectedProduct,
      properties: {
        ...selectedProduct.properties,
        ...formatMultiLangTextContentForRequest(),
      },
    };

    if (!!headerImage && !newProduct.is_fee) {
      newProduct.properties.header_image = headerImage;
    }

    return addResource ? {...newProduct, resource: "up"} : newProduct;
  };

  const title = (
    <CustomCardHeader
      title={
        !!goBack ? (
          <Row>
            <BackButton
              label=""
              header={!isMobileView}
              color={!!isMobileView ? "secondary" : "primary"}
              goBack={goBack}
            />
            {t("header-gje-upsells")}
          </Row>
        ) : (
          t("header-gje-upsells")
        )
      }
      className={classes.header}
      action={
        <>
          <Box mr={3}>
            <CreationButton
              type={"upsell"}
              onScratch={handleMenuOptionClick("scratch", "custom_upsell")}
              onExisting={handleMenuOptionClick("existing")}
              onTemplate={handleMenuOptionClick("template")}
            />
          </Box>
          {!goBack && <CloseIconButton sm disablePadding onClick={onClose} />}
        </>
      }
    />
  );

  const productModal = !!openModal && !selectFromOptionEnabled && (
    <ProductModal
      open
      langs={langs}
      content={textContent}
      selectedLang={selectedLang}
      isGroupView
      listing_id={selectedProduct?.listing_id}
      group_id={group_id}
      stripeAccount={stripeAccount}
      selectedGroup={selectedGroup}
      selected={selectedProduct}
      headerImage={headerImage}
      getUpdatedContent={getUpdatedContent}
      skus={skus}
      setSkus={setSkus}
      setLangs={setLangs}
      setContent={setTextContent}
      handleClose={closeModal}
      handleSave={handleAddProduct}
      editData={setSelectedProduct}
      setHeaderImage={setHeaderImage}
      setSelectedLang={setSelectedLang}
    />
  );

  const confirmDeleteProductModal = productToDelete !== null && (
    <ConfirmDialog
      open
      maxWidth="xs"
      disableDismiss
      onClose={() => {
        saveOption.current = null;
        setProductToDelete(null);
      }}
      title={"Delete upsell"}
      message={"This upsell with all its configuration will be deleted."}
      confirmLabel="Delete"
      confirmAction={handleRemoveProduct}
      cancelLabel="Cancel"
      cancelAction={() => {
        saveOption.current = null;
        setProductToDelete(null);
      }}
    />
  );

  const selectExistingModal = (
    <SelectExistingDialog
      open={!!openModal && modalContentType === "existing"}
      title={"New upsell"}
      resource={"upsell"}
      handleItemSelect={selectExistingProduct}
      onClose={() => {
        setOpenModal(false);
        setModalContentType(null);
      }}
    />
  );

  const selectTemplateModal = (
    <SelectTemplateDialog
      open={!!openModal && modalContentType === "template"}
      title="New upsell"
      obj={productsUpsells}
      resource="upsells"
      templateKeyName="upsellId"
      currency={selectedCurrency}
      handleItemSelect={selectProductTemplate}
      onClose={() => {
        setOpenModal(false);
        setModalContentType(null);
      }}
    />
  );

  const upsellSection = (
    <UpsellsList
      upsells={upsells}
      currency={selectedCurrency}
      handleItemSelect={selectProductTemplate}
      onItemClick={openProductModal}
      addUpsell={(p, isTemplate) =>
        addProduct(
          {
            ...p,
            header_image: isTemplate
              ? getProductBGImage("upsells", p.upsellId)
              : null,
          },
          p.product_type,
        )
      }
      deleteUpsell={(p, i) =>
        setProductToDelete({index: i, resource_id: p.resource_id})
      }
    />
  );

  return (
    <Container>
      {title}
      {selectExistingModal}
      {selectTemplateModal}
      {productModal}
      {confirmDeleteProductModal}
      <Content>
        <Box textAlign="left" mx={-4}>
          <GJEInfoCard banner type="upsells" />
        </Box>
        {stripeAccount === false ? (
          <EmptyHouseProducts type={"upsells"} loading />
        ) : stripeAccount === null || !stripeAccount?.properties?.enabled ? (
          <Content>
            <StripeAccountCard account={stripeAccount} />
          </Content>
        ) : (
          upsellSection
        )}
      </Content>
    </Container>
  );
};

export default GJEUpsellsPanel;
