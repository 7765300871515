import {darken, makeStyles} from "@material-ui/core";
import {THEME} from "configuration/settings.js";

const useRegisterStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    backgroundColor: "#F0F1EA",
  },
  formRoot: {width: "100%"},
  quotesPanel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
    height: "100%",
    width: 230,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {display: "none"},
    color: theme.palette.common.white,
    padding: theme.spacing(5),
  },
  quotes: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  ensoLogo: {
    color: theme.palette.common.white,
    width: "100%",
    height: 60,
    marginBottom: theme.spacing(5),
  },
  content: {
    flexGrow: 1,
    display: "grid",
    placeContent: "center",
  },
  card: {
    border: "none",
    boxShadow: "0px 2px 4px rgba(28, 41, 90, 0.0392157)",
    padding: theme.spacing(6, 5),
    margin: theme.spacing(4),
    maxWidth: 900,
  },
  cardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: 36,
    fontWeight: 400,
    marginBottom: theme.spacing(4),
  },
  subheader: {color: theme.palette.primary.main},
  link: {
    textDecoration: "underline",
    color: theme.palette.primary.main,
    "&:hover": {color: theme.palette.secondary.main},
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  centered: {
    alignItems: "center",
    justifyContent: "center",
  },
  buttons: {
    alignItems: "center",
    gap: theme.spacing(4),
    marginTop: theme.spacing(5),
  },
  btn: {
    borderRadius: 999,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    color: theme.palette.common.white,
    backgroundColor: (props) => props.backgroundColor || "#FFF",
    "&:hover": {
      backgroundColor: (props) => darken(props.backgroundColor || "#FFF", 0.15),
    },
    "&.Mui-disabled": {
      color: theme.palette.common.white,
      backgroundColor: (props) => props.backgroundColor || "#FFF",
      opacity: 0.35,
    },
  },
  pmsSelectionBtn: {
    width: "100%",
    maxWidth: 360,
    borderRadius: 999,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    textTransform: "none",
  },
  btnIcon: {
    fontSize: "17px!important",
    marginRight: 4,
  },
  greyLink: {
    width: "fit-content",
    backgroundColor: "transparent",
    color: "#707070",
    textDecoration: "underline",
    textAlign: "center",
    cursor: "pointer",
    marginTop: theme.spacing(5),
  },
  code: {
    color: theme.palette.primary.main,
    backgroundColor: THEME.inputBg,
    padding: 3,
    borderRadius: 4,
  },
  avatar: {
    width: 120,
    height: 120,
    cursor: "pointer",
  },
  editText: {
    position: "absolute",
    left: 0,
    bottom: 0,
    fontSize: 15,
    width: "100%",
    padding: "3px 0",
    color: theme.palette.common.white,
    zIndex: theme.zIndex.mobileStepper,
    backgroundColor: "rgba(0,0,0,0.2)",
    textAlign: "center",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    cursor: "pointer",
  },
  inputLabel: {
    position: "absolute",
    top: 8,
    left: 12,
    zIndex: 1,
  },
  policySection: {
    display: "flex",
    alignItems: "center",
    marginTop: 4,
  },
  policyDot: {fontSize: 12},
  policyText: {
    fontSize: 11,
    marginLeft: 5,
  },
  policyCol: {justifyContent: "center"},
}));

export default useRegisterStyles;
