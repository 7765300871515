import React from "react";
import Link from "@material-ui/core/Link";
import {replaceURLs} from "utilities/helperFunctions";
import {makeStyles} from "@material-ui/core";
import {isMobile} from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none !important",
    color: theme.palette.primary.main,
  },
}));

export default function FormattedMessage({message}) {
  const classes = useStyles();
  let text = "";

  const openMobileLink = (url) => () => {
    window.open(url, "_system", "location=yes");
  };

  if (message !== null) {
    text = replaceURLs(message).map((item, index) => {
      if (item.type === "link") {
        return isMobile ? (
          <a
            href="#"
            onClick={openMobileLink(item.text)}
            className={classes.link}
          >
            {item.text}
          </a>
        ) : (
          <Link
            key={index}
            style={{textDecoration: "none"}}
            target="_blank"
            rel="noopener"
            href={item.text}
          >
            {item.text}
          </Link>
        );
      } else {
        // Detects line breaks
        let lines = item.text.split("\n");
        let msg = lines.map((line, ind) => (
          <span key={ind}>
            {line}
            <br />
          </span>
        ));
        return <span key={index}>{msg}</span>;
      }
    });
  }
  return <span>{text}</span>;
}
