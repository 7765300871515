import React from "react";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import CheckIcon from "@material-ui/icons/Check";

export default function SuccessSnackbar({label = "", open, onClose}) {
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={2300}
      onClose={handleAlertClose}
      style={{alignItems: "flex-end"}}
      anchorOrigin={{vertical: "bottom", horizontal: "center"}}
      disableWindowBlurListener
    >
      <Alert
        onClose={handleAlertClose}
        severity="success"
        elevation={6}
        variant="filled"
        icon={<CheckIcon />}
      >
        {label}
      </Alert>
    </Snackbar>
  );
}
