import {API} from "aws-amplify";

// ACCOUNT
export const getUser = async (params) => {
  try {
    let response = await API.get("ensoAPI", `/users?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const postUser = async (body) => {
  try {
    const response = await API.post("ensoAPI", "/users", body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const patchUser = async (body) => {
  try {
    const response = await API.patch("ensoAPI", "/users", body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const delUser = async (enso_key, enso_user_id) => {
  try {
    const response = await API.del(
      "ensoAPI",
      `/users?enso_key=${enso_key}&enso_user_id=${enso_user_id}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};
