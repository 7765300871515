import React from "react";
// UI
import {Typography, IconButton, Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import CustomSwitch from "core/switches/CustomSwitch";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
// Utilities
import clsx from "clsx";
import {useDispatch} from "react-redux";
import {deleteHouse} from "redux/actions/listingsActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(1, 3),
    borderRadius: 10,
    position: "relative",
    "&.-disabled": {backgroundColor: "#FF4A4A"},
  },
  disclaimer: {
    color: "#FFF",
    lineHeight: "20px",
    paddingRight: theme.spacing(4),
  },
  button: {
    color: "white",
  },
}));

export default function ListingActive({
  listing,
  disableEdit,
  onValueChange,
  onClose,
}) {
  const classes = useStyles();
  const [active, setActive] = React.useState(!!listing?.active);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  let dispatch = useDispatch();

  React.useEffect(() => {
    if (listing?.active !== active) {
      setActive((prev) => !!listing?.active);
    }
  }, [listing]);

  const toggleListing = (newValue) => {
    setActive(newValue);
    onValueChange(newValue);
  };

  function handleDeleteListing() {
    dispatch(
      deleteHouse(
        `listing_id=${listing.listing_id}`,
        listing.listing_id,
        () => {
          setDeleteModalOpen(false);
          onClose();
        },
        () => console.log("DELETE LISTING FAILED"),
      ),
    );
  }

  return (
    <div className={clsx(classes.root, {"-disabled": !active})}>
      <ConfirmDialog
        open={deleteModalOpen}
        dialo
        onClose={() => setDeleteModalOpen()}
        title="Delete this listing?"
        message={
          "Deleting this listing will remove any content connected to this listing."
        }
        confirmLabel="Confirm"
        confirmAction={() => handleDeleteListing()}
        cancelLabel="Cancel"
        cancelAction={() => setDeleteModalOpen(false)}
      />
      {!active && (
        <>
          <Typography variant="caption" className={classes.disclaimer}>
            {
              "This property will not receive automated messages & guest boarding passes."
            }
          </Typography>
          <Tooltip arrow key={"delete listing"} title={`Delete listing`}>
            <IconButton
              size="small"
              color="white"
              onClick={() => setDeleteModalOpen(true)}
            >
              <DeleteIcon className={classes.button} />
            </IconButton>
          </Tooltip>
        </>
      )}
      <CustomSwitch
        large
        checked={active}
        disabled={disableEdit}
        onChange={() => toggleListing(!active)}
      />
    </div>
  );
}
