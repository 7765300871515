import React, {useEffect, useState} from "react";
import {withLDConsumer} from "launchdarkly-react-client-sdk";
// UI
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as GuestIcon} from "assets/icons/guest.svg";
import {ReactComponent as OwnerIcon} from "assets/icons/owner.svg";
import {ReactComponent as MaintenanceIcon} from "assets/icons/maintenance.svg";
import {ReactComponent as CleanerIcon} from "assets/icons/cleaner.svg";
// Custom
import CustomMenu from "core/menus/CustomMenu";
import ExpandButton from "core/buttons/ExpandButton";
// Utils
import {guestTypes} from "configuration/constants";
import {capitalize} from "utilities/helperFunctions";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 20,
    background: "#FFFFFF",
    boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.16)",
    padding: theme.spacing(3),
  },
  btn: {padding: theme.spacing(1, 3)},
  btnLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    background: "#FAFAFA",
    padding: theme.spacing(1, 2),
    gap: theme.spacing(1),
    "& .icon": {color: theme.palette.common.black},
    "& .text": {
      color: theme.palette.common.black,
      fontWeight: 500,
    },
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  listItem: {
    borderRadius: 10,
    padding: theme.spacing(2),
  },
  itemText: {margin: 0},
  itemSelected: {
    backgroundColor: theme.palette.secondary.main + "!important",
    color: theme.palette.common.white,
  },
  itemIcon: {
    minWidth: 0,
    borderRadius: 5,
    marginRight: theme.spacing(3),
    padding: theme.spacing(1),
    background: "rgba(32, 34, 35, 0.10)",
  },
  itemTitle: {
    fontSize: 14,
    fontWeight: 500,
  },
  dropdownArrow: {color: theme.palette.common.black},
}));

const iconMap = {
  guest: {icon: GuestIcon, viewBox: "0 0 24 24"},
  owner: {icon: OwnerIcon, viewBox: "0 0 24 24"},
  maintenance: {icon: MaintenanceIcon, viewBox: "0 0 24 24"},
  cleaner: {icon: CleanerIcon, viewBox: "0 0 24 24"},
};

const GuestTypeSelector = ({selected, flags, onClick}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [guestType, setGuestType] = useState(selected);
  const availableGuestTypes = guestTypes.filter(
    (gt) => !flags.inboxes.includes(gt),
  );

  useEffect(() => {
    if (selected !== guestType) {
      setGuestType((prev) => selected);
    }
  }, [selected]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (newOpt) => {
    setGuestType((prev) => newOpt);
    handleCloseMenu();
    onClick(newOpt);
  };

  function getMenuContent() {
    return (
      <List className={classes.list}>
        {availableGuestTypes.map((gt) => {
          return (
            <ListItem
              button
              key={gt}
              disableRipple
              selected={guestType === gt}
              className={classes.listItem}
              classes={{selected: classes.itemSelected}}
              onClick={() => handleOptionClick(gt)}
            >
              <ListItemAvatar className={classes.itemIcon}>
                <SvgIcon
                  component={iconMap[gt].icon}
                  viewBox={iconMap[gt].viewBox}
                />
              </ListItemAvatar>
              <ListItemText
                className={classes.itemText}
                primary={capitalize(gt)}
                primaryTypographyProps={{className: classes.itemTitle}}
              />
            </ListItem>
          );
        })}
      </List>
    );
  }

  return (
    <>
      <CustomMenu
        onClose={handleCloseMenu}
        content={getMenuContent()}
        open={!!anchorEl}
        anchorEl={anchorEl}
        customPaperClass={classes.paper}
      />
      <ExpandButton
        size="small"
        variant="text"
        className={classes.btn}
        iconClassName={classes.dropdownArrow}
        label={
          <Box className={classes.btnLabel}>
            <SvgIcon
              className="icon"
              component={iconMap[guestType].icon}
              viewBox={iconMap[guestType].viewBox}
            />
            <Typography className="text">{capitalize(guestType)}</Typography>
          </Box>
        }
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
    </>
  );
};

export default withLDConsumer()(GuestTypeSelector);
