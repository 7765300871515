import React from "react";
import {Box} from "@material-ui/core";

const BlurOverlay = ({children, open = false, renderDialog}) => {
  return (
    <Box position="relative" width="100%" height="100%">
      <Box
        style={{
          filter: open ? "blur(8px)" : "none",
          width: "100%",
          height: "100%",
        }}
      >
        {children}
      </Box>

      {open && (
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {renderDialog && renderDialog()}
        </Box>
      )}
    </Box>
  );
};

export default BlurOverlay;
