import React from "react";
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ArrowIcon from "@material-ui/icons/NavigateNext";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  listItemContainer: {listStyle: "none"},
  container: {
    borderRadius: 5,
    transition: "0.15s",
    padding: (props) => (!!props.divider ? 0 : theme.spacing(2)),
    border: (props) =>
      !!props.border ? "1.5px solid #F0F0F0" : "1px solid transparent",
    "& .-hover-info": {opacity: 0},
    "&:hover": {
      border: (props) =>
        `1px solid ${!props.disableHover ? theme.palette.secondary.main : "transparent"}`,
      backgroundColor: "#FFF !important",
      "& .-hover-info": {opacity: 1},
    },
    "&.-hover-disabled": {
      border: "1px solid transparent !important",
      "&:hover": {
        backgroundColor: (props) =>
          !!props.noHoverBG
            ? "transparent !important"
            : "rgba(67,84,96,0.04)!important",
      },
    },
  },
  outlinedContainer: {
    border: "1px solid #F0F0F0",
    borderRadius: 10,
    padding: theme.spacing(4),
    paddingRight: (props) => (!!props.hasActions ? 52 : theme.spacing(4)),
    "&.-expanded": {
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottom: "none",
    },
  },
  filledContainer: {
    backgroundColor: "#FBFBFC",
    borderRadius: 10,
    padding: theme.spacing(4),
    "&.-expanded": {
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  listItemText: {
    margin: 0,
    "&.-placeholder": {opacity: 0.3},
  },
  listItemIcon: {
    minWidth: 0,
    marginRight: theme.spacing(2),
    color: theme.palette.text.primary,
    "& > svg": {
      width: 16,
      height: 16,
    },
  },
  rightSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
    alignSelf: "stretch",
    height: "fit-content",
  },
  arrow: {
    color: theme.palette.text.primary,
    marginRight: -theme.spacing(2),
    transition: "0.15s",
    width: 20,
    height: 20,
    "&.-expanded": {transform: "rotate(90deg)"},
  },
  text: {
    color: THEME.subdued,
    "&.-bold": {
      lineHeight: "14px",
      fontWeight: 700,
      color: theme.palette.text.primary,
    },
    "&.-filled": {
      lineHeight: "16px",
      backgroundColor: "rgba(32, 34, 35, 0.1)",
      padding: theme.spacing(1, 2),
      borderRadius: 5,
    },
  },
  secondaryText: {
    color: theme.palette.text.primary,
    paddingTop: theme.spacing(2),
  },
  divider: {backgroundColor: "#F0F0F0"},
  actions: {
    right: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  content: {
    marginTop: (props) => -props.contentTopMargin,
    borderTop: "none !important",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    padding: (props) =>
      theme.spacing(!!props.disableContentTopPadding ? 0 : 3, 4, 4),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    "&.-outlined": {border: "solid 1px #F0F0F0"},
    "&.-filled": {backgroundColor: "#FBFBFC"},
  },
}));

export default function AccordionItem({
  icon,
  title,
  infoText,
  onClick = null,
  infoTextProps = {},
  description,
  expanded = false,
  disableHover = false,
  divider = false,
  hoverInfo,
  firstItem,
  actions = null,
  outlined = false,
  filled = false,
  disableContentTopPadding,
  content = null,
  contentTopMargin = 0,
  titlePlaceholder = null,
  border = false,
  customInfoItem = null,
}) {
  const classes = useStyles({
    noHoverBG: !onClick || !!disableHover,
    disableHover,
    divider,
    border,
    contentTopMargin,
    disableContentTopPadding,
    hasActions: !!actions,
  });
  const listItemProps = !!onClick ? {button: true, disableRipple: true} : {};

  return (
    <>
      {!!divider && !firstItem && <Divider className={classes.divider} />}
      <ListItem
        {...listItemProps}
        className={clsx({
          [classes.container]: !outlined && !filled,
          [classes.outlinedContainer]: !!outlined,
          [classes.filledContainer]: !!filled,
          "-expanded": !!expanded,
          "-hover-disabled": !onClick || !!expanded,
        })}
        classes={{container: clsx({[classes.listItemContainer]: !!outlined})}}
        onClick={onClick}
      >
        {!!icon && (
          <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
        )}
        <ListItemText
          primary={title || titlePlaceholder}
          secondary={description}
          className={clsx(classes.listItemText, {
            "-placeholder": !title && !!titlePlaceholder,
          })}
          primaryTypographyProps={{variant: "h1"}}
          secondaryTypographyProps={{
            variant: "body1",
            className: classes.secondaryText,
          }}
        />
        {!!actions ? (
          <ListItemSecondaryAction className={classes.actions}>
            {actions}
            {!!onClick && (
              <ArrowIcon
                className={clsx(classes.arrow, {"-expanded": !!expanded})}
              />
            )}
          </ListItemSecondaryAction>
        ) : (
          <div className={classes.rightSection}>
            {!!hoverInfo && !expanded && (
              <div className="-hover-info">{hoverInfo}</div>
            )}
            {customInfoItem}
            {(!expanded || !!infoTextProps.persistent) && !!infoText && (
              <Typography
                variant="caption"
                className={clsx(classes.text, {
                  "-bold": !!infoTextProps.bold,
                  "-filled": !!infoTextProps.filled,
                })}
              >
                {infoText}
              </Typography>
            )}
            {!!onClick && (
              <ArrowIcon
                className={clsx(classes.arrow, {"-expanded": !!expanded})}
              />
            )}
          </div>
        )}
      </ListItem>
      {!!content && !!expanded && (
        <div
          className={clsx(classes.content, {
            "-outlined": !!outlined,
            "-filled": !!filled,
          })}
        >
          {content}
        </div>
      )}
    </>
  );
}
