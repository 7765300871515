import React, {useState} from "react";
import {List, ListItem, ListItemText} from "@material-ui/core";
import TemplateStatus from "../TextFields/TemplateStatus";

import {TemplateComponentsDialog} from "../Dialogs/TemplateComponentsDialog";

export default function WhatsappTemplateList({account}) {
  const templates = account?.properties?.templates;

  const [open, setOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  return (
    <div>
      <TemplateComponentsDialog
        template={selectedTemplate}
        serviceId={account.service_id}
        open={open}
        setOpen={setOpen}
      />
      <List>
        {templates?.map((template) => (
          <ListItem
            key={template.integration_template_id}
            button
            onClick={() => {
              setSelectedTemplate(template);
              setOpen(true);
            }}
          >
            <div style={{width: "75%"}}>
              <ListItemText>
                {template.name} ({template.language})
              </ListItemText>
            </div>
            <div style={{width: "25%"}}>
              <ListItemText>
                <TemplateStatus template={template} />
              </ListItemText>
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
