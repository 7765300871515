import React, {useRef, useEffect} from "react";
import PropTypes from "prop-types";

function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback && callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function OutsideAlerter(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.onClickOutside);

  return (
    <div ref={props.disableListener ? null : wrapperRef}>{props.children}</div>
  );
}

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired,
  disableListener: PropTypes.bool,
  onClickOutside: PropTypes.func,
};

export default OutsideAlerter;
