import React from "react";
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@material-ui/core";
import {alpha, makeStyles} from "@material-ui/core/styles";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const colors = {
  active: "#1EC386",
  complete: THEME.secondary,
  incomplete: THEME.primary,
  disabled: "#5F6682",
  bypassed: THEME.secondary,
};

const useStyles = makeStyles((theme) => ({
  tag: {
    padding: theme.spacing(0, 2),
    borderRadius: 360,
    width: "fit-content",
  },
  label: {
    fontSize: 10,
    fontWeight: 500,
    textTransform: "uppercase",
  },
  activeBG: {backgroundColor: alpha(colors.active, 0.1)},
  completeBG: {backgroundColor: alpha(colors.complete, 0.1)},
  incompleteBG: {backgroundColor: alpha(colors.incomplete, 0.1)},
  disabledBG: {backgroundColor: alpha(colors.disabled, 0.1)},
  bypassedBG: {backgroundColor: alpha(colors.bypassed, 0.1)},
  active: {color: colors.active},
  complete: {color: colors.complete},
  incomplete: {color: colors.incomplete},
  disabled: {color: colors.disabled},
  bypassed: {color: colors.bypassed},
}));

const BPStepStatusTag = ({status}) => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Box className={clsx(classes.tag, classes[`${status}BG`])}>
      <Typography className={clsx(classes.label, classes[`${status}`])}>
        {t(`${status}-bp-step-tag`)}
      </Typography>
    </Box>
  );
};

export default BPStepStatusTag;
