import React, {useState} from "react";
import {
  Typography,
  Card,
  CardContent,
  makeStyles,
  CircularProgress,
  Chip,
  Box,
} from "@material-ui/core";
import PrimaryButton from "core/buttons/PrimaryButton";
import {channelIntegrationTypeMap, addonServices} from "configuration/enums";
import {THEME} from "configuration/settings";
import {signUpUrl} from "redux/actions/accountsActions";
import {useDispatch} from "react-redux";
import {openGeneralError} from "redux/actions/settingsActions";
import ServiceInfoDialog from "components/Dialogs/ServiceInfoContent";

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 50,
    padding: theme.spacing(3),
  },
}));

export default function AirbnbAccountCard({loading}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [loadingDialogAction, setLoadingDialogAction] = useState(false);

  function handleSubmit() {
    setLoadingDialogAction(true);
    dispatch(
      signUpUrl(
        {integration_type: "abnb"},
        (response) => {
          window.open(response.redirect_url);
          setLoadingDialogAction(false);
        },
        () => {
          dispatch(openGeneralError("Failed to get Airbnb Redirect Url"));
          setLoadingDialogAction(false);
        },
      ),
    );
  }

  const info = addonServices.airbnb[0];

  return (
    <>
      <ServiceInfoDialog
        open={modalOpen}
        service={info}
        headerIcon={info.icon_url}
        dialogBGImage={info.cover_photo_url}
        loading={loadingDialogAction}
        handleEnable={handleSubmit}
        onClose={() => setModalOpen(false)}
      />
      <Card style={{marginTop: THEME.spacing.md}}>
        <CardContent>
          <Typography variant="h2">
            <img
              src={channelIntegrationTypeMap["abnb"]?.icon}
              className={classes.icon}
            />
            {/*<Chip color='primary' label={<Box sx={{ marginTop:4 }}>{'BETA'}</Box>}/>*/}
          </Typography>
          <div style={{display: "flex", marginTop: THEME.spacing.xs}}>
            <Typography>Connect your Airbnb Account</Typography>
          </div>
          <div style={{display: "flex", marginTop: THEME.spacing.xs}}>
            <div style={{flex: 1, display: "flex", justifyContent: "right"}}>
              {loading ? (
                <CircularProgress />
              ) : (
                <PrimaryButton
                  size="small"
                  variant="text"
                  label={"Connect account"}
                  color="primary"
                  onClick={() => setModalOpen(true)}
                />
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
