import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  bullet: {
    width: 4,
    height: 4,
    borderRadius: 4,
    backgroundColor: "#000",
  },
}));

export default function DotIcon({disableGutters = false}) {
  const classes = useStyles({disableGutters});

  return <div className={classes.bullet} />;
}
