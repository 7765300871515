/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {Button, Card, CardBody, CardFooter, Form, Col} from "reactstrap";

class Register extends React.Component {
  componentDidMount() {
    var element = document.getElementById("root");
    element.style.zoom = "100%";
  }

  componentWillUnmount() {
    var element = document.getElementById("root");
    element.style.zoom = "80%";
  }

  render() {
    return (
      <Col className="ml-auto mr-auto" lg="8" md="8" sm="12">
        <Form className="form login-form">
          <Card className="card-login card-plain card-white">
            <CardBody>
              <div className="airbnb_signup_card mb-4">
                <img
                  className="ml-3 mt-3"
                  style={{
                    height: "75px",
                  }}
                  alt="..."
                  src="https://enso-image-assets.s3.amazonaws.com/enso_logo_white.png"
                />
                <h2 style={{color: "#357DF9"}} className="ml-3 mt-2">
                  Reset Password
                </h2>
              </div>
              {this.props.email_state === true ? (
                <span className="mt-5"> Email </span>
              ) : (
                <span className="mt-5 text-danger"> Invalid Email </span>
              )}
              <input
                id="email_input"
                className={
                  this.props.email_state === true
                    ? "login_input"
                    : "login_input login_input_danger"
                }
                onChange={this.props.handleChange("email")}
              />
            </CardBody>
            <CardFooter>
              <Button
                block
                className="mb-3"
                color="info"
                href="#pablo"
                onClick={() => this.props.requestReset()}
                size="lg"
              >
                Reset Password
              </Button>
              <div className="pull-left">
                <h6>
                  <a
                    className="link footer-link text-info"
                    href="#pablo"
                    onClick={() => this.props.selectPage("login")}
                  >
                    Back
                  </a>
                </h6>
              </div>
            </CardFooter>
          </Card>
        </Form>
      </Col>
    );
  }
}

export default Register;
