import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputBase,
  Typography,
  makeStyles,
} from "@material-ui/core";
// custom
import DeleteIconButton from "core/buttons/DeleteIconButton";
import FieldTypeIcon from "core/icons/FieldTypeIcon";
// Utils
import {collectFieldTypeEnum} from "configuration/enums";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    alignItems: "center",
    gap: theme.spacing(3),
    borderRadius: 10,
    backgroundColor: "#EDEDED",
  },
  inputContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
    "&.-container": {
      gap: theme.spacing(2),
      width: "100%",
    },
  },
  formControlLabel: {margin: 0},
  checkbox: {
    padding: 0,
    marginRight: theme.spacing(2),
    "& svg": {
      width: 20,
      height: 20,
    },
  },
  input: {
    padding: theme.spacing(2),
    backgroundColor: "#F8F8F9",
    lineHeight: "20px",
    borderRadius: 5,
    "& > input": {
      padding: 0,
      height: "fit-content",
    },
    "&.disabled": {color: "#8C9196"},
  },
  bottomContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  optionsContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gap: theme.spacing(2),
  },
  option: {
    padding: theme.spacing(1, 2),
    backgroundColor: "#F8F8F9",
    borderRadius: 5,
    "& > input": {padding: theme.spacing(1, 0)},
    flexBasis: `calc(50% - ${theme.spacing(1)}px)`,
  },
  optionBtn: {
    borderRadius: 5,
    border: "1px dashed #C2C5C7",
    backgroundColor: "rgba(248, 248, 249, 0.50) !important",
    fontWeight: 400,
    justifyContent: "flex-start",
    color: "rgba(32,34,35,0.5)",
    flexBasis: `calc(50% - ${theme.spacing(1)}px)`,
  },
  divider: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
    "& .label": {color: THEME.subdued},
    "& .bold": {fontWeight: 700},
    "& .hr": {
      flex: 1,
      borderTop: "1px solid #D9D9D9",
    },
  },
  disclaimer: {
    color: THEME.subdued,
    width: "100%",
    "& .bold": {
      display: "inline",
      fontWeight: 700,
    },
  },
}));

export default function FieldTypeInput({
  field,
  value,
  onValueChange,
  onRequiredChange,
  onDelete,
  onOptionsChange,
}) {
  const classes = useStyles();
  const newField = React.useRef(false);
  const [optnsTotal, setOptnsTotal] = React.useState(
    (field.field_options ?? []).length,
  );
  let fieldOptns = field.field_options ?? [];

  React.useEffect(() => {
    let newTotal = fieldOptns.length;
    if (newTotal !== optnsTotal) {
      setOptnsTotal((prev) => newTotal);
    }
  }, [field]);

  const handleRequiredChange = () => {
    onRequiredChange(!!field.required ? false : true);
  };
  const handleOptionFocus = () => {
    newField.current = false;
  };

  const handleAddOption = () => {
    newField.current = fieldOptns.length;
    onOptionsChange(fieldOptns.concat([""]));
  };

  const handleEditOption = (ind) => (e) => {
    let newVal = e.target.value;
    let newOptions = [...fieldOptns];
    newOptions[ind] = newVal;
    onOptionsChange(newOptions);
  };

  const handleDeleteOption = (ind) => () => {
    onOptionsChange(field.field_options.filter((el, i) => i !== ind));
  };

  return (
    <div className={classes.container}>
      <div className={clsx(classes.row, "-container")}>
        <FieldTypeIcon
          type={
            field.field === "guest_language"
              ? "single_select"
              : (field.field_category ?? field.field_type)
          }
        />
        <div className={classes.inputContent}>
          <div className={classes.row}>
            <InputBase
              fullWidth
              disabled={!field.edit}
              value={value}
              onChange={onValueChange}
              classes={{
                root: classes.input,
                disabled: "disabled",
              }}
            />
          </div>
        </div>
        <FormControlLabel
          label="Required"
          classes={{root: classes.formControlLabel}}
          control={
            <Checkbox
              name="required"
              checked={!!field.required}
              className={classes.checkbox}
              onChange={handleRequiredChange}
            />
          }
        />
        <DeleteIconButton disablePadding onClick={onDelete} />
      </div>
      {[
        collectFieldTypeEnum.single_select,
        collectFieldTypeEnum.multi_select,
      ].includes(field.field_type) && (
        <div className={classes.bottomContainer}>
          <div className={classes.divider}>
            <Typography variant="caption" className="label">
              {"Options"}
            </Typography>
            <div className="hr" />
            <Typography variant="caption" className="label bold">
              {`${fieldOptns.length}/10`}
            </Typography>
          </div>
          <div className={classes.optionsContainer}>
            {fieldOptns.map((fo, optInd) => (
              <InputBase
                key={optInd}
                className={classes.option}
                value={fo}
                autoFocus={optInd === newField.current}
                onFocus={handleOptionFocus}
                endAdornment={
                  <DeleteIconButton
                    disablePadding
                    marginLeft={2}
                    onClick={handleDeleteOption(optInd)}
                  />
                }
                onChange={handleEditOption(optInd)}
              />
            ))}
            {fieldOptns.length < 10 && (
              <Button
                disableRipple
                className={classes.optionBtn}
                onClick={handleAddOption}
              >
                {"Add option"}
              </Button>
            )}
          </div>
        </div>
      )}
      {field.field_type === "image" && (
        <Typography
          className={classes.disclaimer}
          component="div"
          variant="caption"
        >
          <Typography className="bold">{"Disclaimer: "}</Typography>
          {
            "Images uploaded to this input are not encrypted. Do not ask for sensitive data."
          }
        </Typography>
      )}
    </div>
  );
}
