import React from "react";
import ReactQuill from "react-quill";
import {makeStyles} from "@material-ui/core";
import {THEME} from "configuration/settings";
import "react-quill/dist/quill.snow.css";
import EmptyContentText from "components/Misc/EmptyContentText";
import {isHTMLEmpty} from "utilities/helperFunctions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > .quill": {
      "& > .ql-container": {
        border: "none",
        "& > .ql-editor": {
          padding: 0,
          maxHeight: 20,
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: (props) => (!!props.inline ? "inline" : "-webkit-box"),
          WebkitBoxOrient: "vertical",
          wordBreak: (props) =>
            !!props.multiline ? "break-word" : "break-all",
          WebkitLineClamp: (props) => (!!props.multiline ? "unset" : 1),
          marginTop: theme.spacing(1),
          ...theme.typography.body1,
          color: (props) =>
            !!props.multiline ? theme.palette.text.primary : THEME.subdued,
          "& ul": {padding: theme.spacing(1)},
        },
      },
    },
  },
}));

export default function HTMLField({
  content,
  multiline = false,
  inline = false,
  hideMarkup,
  formatAmp,
}) {
  const classes = useStyles({multiline, inline});
  const isContentEmpty = React.useMemo(() => isHTMLEmpty(content), [content]);

  function getHTMLText() {
    let text = content.replace(/<style>.*<\/style>|<[^>]*>|&nbsp;/g, " ");
    if (!!formatAmp) {
      return text.replace("&amp;", "&");
    } else {
      return text;
    }
  }

  return (
    <div className={classes.root}>
      {isContentEmpty ? (
        <EmptyContentText basic label="No content" />
      ) : hideMarkup ? (
        getHTMLText()
      ) : (
        <ReactQuill
          readOnly
          value={content}
          theme="bubble"
          modules={{toolbar: false}}
        />
      )}
    </div>
  );
}
