import React from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Box, styled, Typography} from "@material-ui/core";
import EnsoExperiencesLogo from "assets/img/EnsoExperiences.png";
import CustomDialog from "core/dialogs/CustomDialog";
import PreviewBPPanel from "components/Panels/PreviewBPPanel";

const colStyle = {display: "flex", flexDirection: "column"};

const TextContent = styled(Box)(({theme}) => ({
  ...colStyle,
  padding: theme.spacing(5),
  gap: theme.spacing(4),
}));

const TextLine = styled(Box)(({theme}) => ({
  ...colStyle,
  gap: theme.spacing(2),
}));

const ProviderContainer = styled(Box)(({theme}) => ({
  ...colStyle,
  width: 200,
  height: 100,
  gap: theme.spacing(1),
  padding: theme.spacing(4),
  borderRadius: "10px",
  border: "2px solid #E5E5E5",
  alignItems: "center",
  float: "right",
  marginLeft: theme.spacing(2),
}));

const ProviderIcon = styled("img")(({theme}) => ({
  width: "100%",
  height: "100%",
  objectFit: "contain",
}));

const PayoutItem = styled(Box)(({theme}) => ({
  padding: theme.spacing(3, 4),
  gap: theme.spacing(3),
  display: "flex",
  flexDirection: "row",
  backgroundColor: "#F7F7F7",
  borderRadius: "10px",
}));

const EnsoExperiencesDialog = ({open, onClose}) => {
  const {t} = useTranslation();
  const {group_id} = useParams();
  const groups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const selectedGroup = groups[group_id];

  const getDialogContent = () => {
    return (
      <div>
        <TextContent>
          <div>
            <ProviderContainer>
              <ProviderIcon
                alt="EnsoExperiences logo"
                src={EnsoExperiencesLogo}
              />
            </ProviderContainer>
            <Box mb={2}>
              <Typography variant="h1">{t("details-section-title")}</Typography>
            </Box>
            <Typography>{t("details-section-text")}</Typography>
          </div>
          <TextLine>
            <Typography variant="h1">
              {t("explanation-section-title")}
            </Typography>
            <Typography>{t("explanation-section-text")}</Typography>
          </TextLine>
          <TextLine>
            <Typography variant="h1">{t("payout-section-title")}</Typography>
            <PayoutItem>
              <Typography style={{flex: 1}}>
                {t("payout-section-text")}
              </Typography>
              <Typography>{t("payout-section-total")}</Typography>
            </PayoutItem>
          </TextLine>
        </TextContent>
      </div>
    );
  };

  const getPreviewContent = () => {
    return (
      <PreviewBPPanel
        type={"viator"}
        lang={"en"}
        useEmptyContent
        listingId={selectedGroup?.connected_to?.ids?.[0]}
      />
    );
  };

  return (
    <CustomDialog
      open={open}
      fullWidth
      maxWidth="md"
      titleVariant="header"
      disableActions
      customHeight={"85vh"}
      disableContentPadding
      title={t("enso-experiences-dialog-title")}
      content={getDialogContent()}
      previewContent={getPreviewContent()}
      onClose={onClose}
    />
  );
};

export default EnsoExperiencesDialog;
