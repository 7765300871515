import React from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
// UI
import {Box, SvgIcon, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as GuidebookIcon} from "assets/icons/Icon_BookOpen.svg";
import {ReactComponent as MessagesIcon} from "assets/icons/Icon_Messages.svg";
import {ReactComponent as BoardingPassIcon} from "assets/icons/Icon_ID.svg";
import {ReactComponent as MoneyIcon} from "assets/icons/Icon_Money.svg";
import {ReactComponent as DoorIcon} from "assets/icons/Icon_Door.svg";
import {ReactComponent as InfoIcon} from "assets/icons/Icon_Info.svg";
import {ReactComponent as PinIcon} from "assets/icons/Icon_Pin.svg";
// Custom
import {LoadingVerificationSettings} from "components/Helpers/EmptyPanels";
import CardItem from "core/listItems/CardItem";
import AddItemCard from "core/cards/AddItemCard";
// Utils
import {toSnakeCase} from "utilities/formatUtilities";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
  },
  section: {
    backgroundColor: "#F1F3F9",
    padding: theme.spacing(4),
    borderRadius: 10,
    flexGrow: 1,
    marginBottom: theme.spacing(4),
    zIndex: 1,
  },
  line: {
    backgroundColor: "#F1F3F9",
    alignSelf: "stretch",
    width: 1,
    margin: theme.spacing(0, 4, 0, 1),
  },
  connector: {
    position: "absolute",
    top: 27,
    left: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > #circle": {
      width: 9,
      height: 9,
      backgroundColor: "#F1F3F9",
      borderRadius: "50%",
    },
    "& > #line": {
      width: 30,
      height: 1,
      backgroundColor: "#F1F3F9",
    },
  },
  cardTitleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {color: "#5F6682"},
  cardTitle: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: "22px",
    color: "#111421",
  },
  cardIcon: {
    display: "flex",
    "& > svg": {
      fontSize: 19,
      marginRight: theme.spacing(2),
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(4),
    gap: theme.spacing(3),
  },
}));

export default function StepSectionPanel({
  id,
  label,
  handleDismiss,
  handleSetupAction,
  missingCardActive,
  addKeycard,
  deleteKeycard,
  refFunction = () => null,
  disableEdit,
}) {
  const classes = useStyles();
  const {group_id} = useParams();
  const verificationSettings = useSelector(
    (state) => state.experiencesReducer.verification_settings,
  );
  const groups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  let selectedGroup = groups[group_id] ?? {};
  let cards = selectedGroup?.guest_journey?.sections?.[id]?.cards;
  const keys = Object.keys(cards || {});

  const getIcon = (component) => {
    switch (component) {
      case "experiences":
        return <SvgIcon viewBox="0 0 35 35" component={MessagesIcon} />;
      case "boarding_pass":
        return <SvgIcon viewBox="0 0 16 10" component={BoardingPassIcon} />;
      case "fees":
      case "upsells":
        return <SvgIcon viewBox="0 0 14 16" component={MoneyIcon} />;
      case "local_services":
        return <SvgIcon viewBox="0 -0.2 14 19.25" component={PinIcon} />;
      case "checkin_steps":
      case "checkout_steps":
        return <SvgIcon viewBox="0 -0.5 14 17" component={DoorIcon} />;
      case "wifi":
        return <SvgIcon viewBox="0 -1 15 17" component={InfoIcon} />;
      case "guidebooks":
        return <SvgIcon viewBox="0 -1 16 18" component={GuidebookIcon} />;

      default:
        return null;
    }
  };

  const handleEdit = (key, card) => () => {
    handleSetupAction(key, card);
  };

  function getSingleCard(k, actions, isBP) {
    return (
      <CardItem
        key={k}
        type={k}
        highlighted={missingCardActive === k && !!actions.length}
        disabled={!cards[k].enabled}
        title={cards[k].label}
        icon={getIcon(k)}
        description={cards[k].description}
        editAction={!actions.length ? handleEdit(k) : null}
        disableEdit={disableEdit}
        hideRemove={!cards[k].data.length && !cards[k].setup_label}
        actions={actions}
      />
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.connector}>
        <div id="circle" />
        <div id="line" />
      </div>
      <div className={classes.line} />
      <div className={classes.section} ref={refFunction}>
        <Typography
          id={`gje-steps-${toSnakeCase(label)}`}
          className={classes.title}
          variant="h1"
        >
          {label}
        </Typography>
        <div className={classes.cardContainer}>
          {keys.map((k) => {
            if (k !== "experiences") {
              return null;
            }
            const actions = [];
            const isBP = k === "boarding_pass";
            if (!!cards[k].setup_label) {
              actions.push({
                label: cards[k].setup_label,
                type: "primary",
                onClick: () => {
                  isBP ? addKeycard() : handleSetupAction(k);
                },
              });
            }
            if (!!cards[k].setup_label) {
              actions.push({
                label: cards[k].dismiss_label,
                type: "text",
                onClick: () => handleDismiss(k),
              });
            }
            return (
              <React.Fragment key={k}>
                {getSingleCard(k, actions, isBP)}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}
