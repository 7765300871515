import {lighten, makeStyles} from "@material-ui/core/styles";
import {THEME} from "configuration/settings.js";

const useSendInputStyles = makeStyles((theme) => ({
  menuPaper: {
    maxWidth: 100,
    maxHeight: 200,
  },
  iconButton: {
    padding: 0,
    paddingRight: theme.spacing(2),
    transition: ".15s",
  },
  formControl: {
    flexGrow: 1,
    border: `1px solid ${theme.palette.action.focus}`,
    borderRadius: 10,
    backgroundColor: "rgba(255, 255, 255, 0.501961)",
  },
  formControlSelect: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  inputContainer: {
    display: "flex",
    alignItems: "flex-start",
    padding: (props) => (!!props.disableGutters ? 0 : theme.spacing(0, 4)),
    overflowY: "scroll",
    overflowX: "hidden",
    maxHeight: "75vh",
    "&::-webkit-scrollbar": {width: "0!important"},
    [theme.breakpoints.down("sm")]: {
      padding: (props) => (!!props.disableGutters ? 0 : theme.spacing(0, 3)),
    },
  },
  select: {
    padding: theme.spacing(2, 0),
    paddingLeft: theme.spacing(3),
    borderRadius: 10,
    flexGrow: 1,
    minHeight: 34,
    "&.Mui-focused > .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.action.disabled}`,
    },
    "&:hover > .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.action.disabled,
    },
    "& > .MuiOutlinedInput-notchedOutline": {
      paddingRight: theme.spacing(6),
      borderColor: theme.palette.action.focus,
    },
  },
  outlined: {
    padding: 0,
    paddingRight: "96px!important",
    whiteSpace: "break-spaces",
    backgroundColor: "transparent!important",
  },
  iconOutlined: {right: theme.spacing(6) + 7},
  nativeInput: {paddingRight: "96px!important"},
  input: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    borderRadius: 10,
    minHeight: 34,
    alignItems: "center",
    "&.Mui-focused > .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.action.disabled}`,
    },
    "&:hover > .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.action.disabled,
    },
  },
  autocompleteInput: {
    padding: theme.spacing(2),
    borderRadius: 10,
    minHeight: 34,
    alignItems: "center",
    "&.Mui-focused > .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.action.disabled}`,
    },
    "&:hover > .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.action.disabled,
    },
  },
  saveBtn: {
    padding: theme.spacing(1, 3),
    height: "100%",
    marginRight: 3,
    transition: "0.15s",
  },
  saveBtnSelect: {
    position: "absolute",
    top: "calc(50% - 14px)",
    right: 3,
    height: 28,
    backgroundColor: `${THEME.whatsapp}!important`,
    color: `${theme.palette.common.white}!important`,
    padding: theme.spacing(1, 3),
    "&.Mui-disabled": {opacity: 0.5},
  },
  inputAdornment: {
    height: 28,
    alignSelf: "flex-end",
    marginLeft: 0,
  },
  mb2: {marginBottom: theme.spacing(1)},
  mb4: {marginBottom: theme.spacing(1)},
  outlinedInput: {border: "none !important"},
  emailView: {
    overflow: "auto",
    flexGrow: 1,
    backgroundColor: theme.palette.grey[50],
    display: "flex",
    flexDirection: "column",
  },
  email: {
    backgroundColor: `${THEME.email}!important`,
    color: `${theme.palette.common.white}!important`,
    "&.Mui-disabled": {opacity: 0.5},
  },
  sms: {
    backgroundColor: `${THEME.sms}!important`,
    color: `${theme.palette.common.white}!important`,
    "&.Mui-disabled": {opacity: 0.5},
  },
  airbnb: {
    backgroundColor: `${THEME.airbnb}!important`,
    color: `${theme.palette.common.white}!important`,
    "&.Mui-disabled": {opacity: 0.5},
  },
  whatsapp: {
    backgroundColor: `${THEME.whatsapp}!important`,
    color: `${theme.palette.common.white}!important`,
    "&.Mui-disabled": {opacity: 0.5},
  },
  svgIcon: {
    width: 24,
    height: 24,
  },
  listItemIcon: {minWidth: 30},
  attachments: {
    padding: theme.spacing(3, 4),
    display: "flex",
    flexDirection: "row",
    overflow: "auto",
    gap: theme.spacing(4),
    "& .attached-image": {
      width: 38,
      height: 38,
    },
  },
  badge: {
    padding: 3,
    backgroundColor: THEME.grey,
    "&:hover": {backgroundColor: lighten(THEME.grey, 0.3)},
    "& .badge-icon": {
      fontSize: 12,
      color: theme.palette.common.white,
    },
  },
}));

export default useSendInputStyles;
