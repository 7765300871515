import React from "react";
import {Storage} from "aws-amplify";
// UI
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import PhotoIcon from "@material-ui/icons/InsertPhoto";

import config from "configuration/settings.js";
import {sleep, validateFile} from "utilities/helperFunctions";
import {useImageCompressor} from "utilities/fileUtilities";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(4),
    paddingBottom: 5,
  },
  saveButton: {
    position: "absolute",
    top: 14,
    right: 60,
  },
  editButton: {
    position: "absolute",
    right: 64,
    top: 8,
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
  image: {
    objectFit: "contain",
    height: "auto",
    borderRadius: 5,
    maxHeight: 400,
  },
  card: {
    border: "none",
    boxShadow: "none",
  },
  label: {
    fontSize: 14,
  },
  optional: {
    fontSize: 12,
    marginLeft: 8,
  },
  emptyPhoto: {
    border: "1px solid rgba(0,0,0,0.2)",
    borderRadius: 10,
    height: 300,
    display: "grid",
    placeContent: "center",
  },
  icon: {
    fontSize: 60,
    justifySelf: "center",
  },
  loading: {
    display: "grid",
    placeContent: "center",
    marginTop: theme.spacing(4),
  },
  loadingDialog: {
    display: "grid",
    placeContent: "center",
    padding: "30px 10px",
  },
}));

const DialogTitle = (props) => {
  const {
    edit,
    editHidden,
    saveDisabled,
    children,
    onEdit,
    onSave,
    onClose,
    ...other
  } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.dialogTitle}
      {...other}
    >
      <Typography variant="h1">{children}</Typography>
      {edit ? (
        <Button
          variant="contained"
          disabled={saveDisabled}
          color="primary"
          className={classes.saveButton}
          onClick={onSave}
        >
          Save
        </Button>
      ) : !editHidden ? (
        <IconButton
          aria-label="edit"
          className={classes.editButton}
          onClick={onEdit}
          title="Edit"
        >
          <EditIcon />
        </IconButton>
      ) : null}
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
        title="Close"
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
};

const EditImage = (props) => {
  const {selectedImage, setImage} = props;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const classes = useStyles();
  const inputRef = React.useRef(null);
  const hasDesc =
    !!selectedImage?.description.length && !!selectedImage.description[0].value;
  const [imgToUpload, setImgToUpload] = React.useState(null);
  const [compressEnabled, setCompressEnabled] = React.useState(false);
  const [picture, setPicture] = React.useState({
    file: null,
    image: selectedImage?.picture || "",
    description: [
      {
        language: "en",
        value: hasDesc ? selectedImage.description[0].value : "",
      },
    ],
  });

  React.useEffect(() => {
    setImage(picture);
  }, [picture]);

  useImageCompressor({
    srcImgs: !!compressEnabled && !!imgToUpload ? [imgToUpload] : [],
    onSuccess: (outputImgs) => onCompressSuccess(outputImgs),
    fileInObject: true,
  });

  const onCompressSuccess = (outputImgs) => {
    setCompressEnabled((prev) => false);
    setImgToUpload((prev) => null);
    setPicture((prev) => ({
      ...prev,
      file: outputImgs[0].file,
      image: outputImgs[0].picture,
    }));
  };

  const handleImage = (e) => {
    const image = e.target.files[0];
    if (!image) {
      return;
    }
    let reader = new FileReader();
    reader.onloadstart = () => {
      setError("");
      setLoading(true);
    };
    reader.onloadend = () => {
      const {isValid, error} = validateFile(image);
      if (isValid) {
        !!error && setError("");
        setImgToUpload({...picture, file: image, image: reader.result});
        setLoading(false);
        sleep(250).then(() => setCompressEnabled((prev) => true));
      } else {
        setError(error);
        setLoading(false);
      }
    };
    !!image && reader.readAsDataURL(image);
  };

  const handleDescriptionChange = (e) => {
    const val = e.target.value;
    setPicture((prev) => ({
      ...picture,
      description: [{language: "en", value: val}],
    }));
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent>
          {!loading && !picture.image && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography color={!!error ? "error" : "textSecondary"}>
                {error || "Max. upload file size: 10MB"}
              </Typography>
            </div>
          )}
          <input
            id="pictureInput"
            ref={inputRef}
            style={{display: "none"}}
            type="file"
            accept="image/*"
            onChange={handleImage}
          />
          {loading ? (
            <div className={classes.emptyPhoto}>
              <Typography variant="h1" color="textSecondary">
                Loading...
              </Typography>
            </div>
          ) : !!picture.image ? (
            <CardMedia
              className={classes.image}
              component="img"
              alt="New Photo"
              height="400"
              image={picture.image}
            />
          ) : (
            <div className={classes.emptyPhoto}>
              <PhotoIcon color="disabled" className={classes.icon} />
              <Button variant="text" color="primary" onClick={handleClick}>
                Browse Files
              </Button>
            </div>
          )}
          <Typography className={`mt-3 ${classes.label}`} color="textSecondary">
            Description<span className={classes.optional}>(Optional)</span>
          </Typography>
          <TextField
            value={picture.description[0].value}
            autoComplete="off"
            fullWidth
            multiline
            placeholder="Enter the image description..."
            onChange={handleDescriptionChange}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

const ViewImage = (props) => {
  const {image} = props;
  const classes = useStyles();
  const description =
    !!image?.description?.length && !!image.description[0].value
      ? image.description[0].value
      : "";

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardMedia
          className={classes.image}
          component="img"
          alt={description}
          height="400"
          image={image.picture}
          title={description}
        />
        <CardContent style={{paddingLeft: 3}}>
          <Typography className={classes.label} color="textSecondary">
            Description
          </Typography>
          <Typography variant="body1" color="textPrimary" component="p">
            {description || "No Description"}
          </Typography>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default function ImageDialog({
  open,
  simpleEditView,
  uploadingText,
  pictures,
  selectedImage,
  detailsView,
  updateContent,
  handleClose,
  displayError,
  onSave,
  currentUser,
}) {
  const classes = useStyles();
  const [edit, setEdit] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [showTimeWarning, setShowTimeWarning] = React.useState(false);
  const [image, setImage] = React.useState(null);

  React.useEffect(() => {
    if (!open) {
      !!edit && sleep(250).then(() => setEdit(false));
      !!uploading && sleep(250).then(() => setUploading(false));
    } else if (simpleEditView) setEdit(true);
  }, [open]);

  React.useEffect(() => {
    if (!uploading) {
      setShowTimeWarning(false);
      return;
    }
    const timer = setTimeout(() => {
      setShowTimeWarning(uploading === "Uploading photo...");
    }, 3000);
    return () => clearTimeout(timer);
  }, [uploading]);

  const handleSavePicture = async () => {
    const selectedHasFile = !!selectedImage?.file;
    const hasFile = !!image && !!image.file && !!image.file.name;
    const hasImage = !!image && !!image.image;
    const imageValid = hasFile || hasImage;
    if (!imageValid || !currentUser) {
      displayError(image.file, "info");
      return;
    }
    if (onSave) {
      const pic = {
        file: hasFile
          ? image.file
          : selectedHasFile
            ? selectedImage.file
            : null,
        picture: image.image,
        description: image.description,
        key: selectedImage.key,
      };
      onSave(pic);
      handleClose();
    } else if (hasFile) {
      const imageName = `${currentUser}/${image.file.name}`;
      setUploading("Uploading photo...");
      const response = await Storage.put(imageName, image.file, {
        contentType: image.file.type,
      });
      const imageKey = response.key;
      if (imageKey) {
        setUploading("Saving changes...");
        updatePictures(!!selectedImage, `${config.s3.URL}${imageName}`);
      } else {
        setUploading(false);
        displayError(image.file, "s3");
      }
    } else if (hasImage) {
      setUploading("Saving changes...");
      updatePictures(true, image.image);
    }
  };

  const updatePictures = (edit, imageName) => {
    const newPictures = [...pictures];
    if (edit) {
      const imgIndex = newPictures.findIndex(
        (i) => i.key === selectedImage.key,
      );
      if (imgIndex > -1)
        newPictures[imgIndex] = {
          description: image.description,
          picture: imageName,
        };
    } else if (imageName) {
      newPictures.push({description: image.description, picture: imageName});
    }
    const data = {pictures: newPictures};
    updateContent(data, handleClose, handleOnError);
  };

  const handleOnError = () => {
    setUploading(false);
    displayError(null, "");
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleDialogClose = (event, reason) => {
    if (!!uploading && reason === "backdropClick") {
      return;
    }
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="image-dialog-title"
        disableEscapeKeyDown={!!uploading}
      >
        {!!uploading || !!uploadingText ? (
          <DialogContent className={classes.loadingDialog}>
            <Typography variant="h1" color="primary">
              {uploadingText || uploading}
            </Typography>
            {showTimeWarning && (
              <Typography
                align="center"
                color="textSecondary"
                style={{fontWeight: 500}}
              >
                This could take a while...
              </Typography>
            )}
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          </DialogContent>
        ) : (
          <React.Fragment>
            <DialogTitle
              id="image-dialog-title"
              edit={!detailsView || edit}
              editHidden={!detailsView}
              saveDisabled={!image || !image.image || uploading}
              onEdit={handleEdit}
              onSave={handleSavePicture}
              onClose={handleClose}
            >
              Picture Details
            </DialogTitle>
            <DialogContent>
              {detailsView && !edit ? (
                <ViewImage image={selectedImage} />
              ) : (
                <EditImage
                  selectedImage={selectedImage}
                  setImage={setImage}
                  handleSavePicture={handleSavePicture}
                  handleClose={handleClose}
                  uploading={uploading}
                />
              )}
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}
