import React, {useState} from "react";
import {Typography} from "@material-ui/core";
import FilledTextField from "core/inputs/FilledTextField";
import {updateService} from "redux/actions/servicesActions";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import CustomDialog from "core/dialogs/CustomDialog";
import {CircularProgress} from "@material-ui/core";
import {
  closeGeneralSuccessAlert,
  openGeneralSuccessAlert,
} from "redux/actions/settingsActions";

export function TemplateComponentsDialog({
  template,
  serviceId,
  open,
  setOpen,
  setInProgress,
  disableClose,
}) {
  const dispatch = useDispatch();
  const [componentMap, setComponentMap] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [isContentValid, setIsContentValid] = React.useState(true);

  useEffect(() => {
    if (template) {
      setComponentMap(
        Object.fromEntries(template.components.map((c, i) => [i, c.fields])),
      );
    }
  }, [template]);

  const handleContentValidity = (value) => {
    setIsContentValid((prev) => value);
  };

  return (
    <CustomDialog
      open={open}
      fullWidth
      maxWidth="sm"
      disableEnforceFocus
      title={template?.paired ? "Template" : "Unmapped Template"}
      disableConfirm={!hasChanges || !isContentValid}
      content={
        <>
          In order to use this Whatsapp messaging template, please input
          personalized text for each field. These will be replaced with real
          guest information when you send the message.
          {template?.components.map((component, index) => {
            const vars = [];
            if (component.text.length > 0) {
              for (let i = 0; i < component?.num_vars; i++) {
                vars.push(
                  <div>
                    <FilledTextField
                      defaultValue={
                        componentMap?.[index]?.[i] ||
                        template.components[index].fields[i]
                      }
                      value={componentMap?.[index]?.[i]}
                      onChange={(e) => {
                        setComponentMap((prev) => {
                          let prevComponent = prev?.[index] || {};
                          return {
                            ...prev,
                            [index]: {
                              ...prevComponent,
                              [i]: e.target.value,
                            },
                          };
                        });
                        setHasChanges(true);
                      }}
                      label={`Personalized Text Field ${i + 1}`}
                      useCustomFields
                      multiline
                      fullWidth
                      onValidityCheck={handleContentValidity}
                    />
                  </div>,
                );
              }
            }

            return (
              <div>
                <br />
                <Typography variant="h2">
                  Component {index + 1} ({component.type})
                </Typography>
                <Typography>{component.text}</Typography>
                <br />
                {(template.paired || componentMap) && vars}
              </div>
            );
          })}
        </>
      }
      onClose={disableClose ? () => null : () => setOpen(false)}
      disableXClose={disableClose}
      labelConfirm={"Save"}
      actionConfirm={() => {
        setOpen(false);
        setInProgress && setInProgress(true);
        dispatch(
          updateService({
            service_id: serviceId,
            options: {
              provider: "whatsapp",
              action_id: "pair_whatsapp_template",
              integration_data: {
                integration_template_id: template.integration_template_id,
                component_map: componentMap,
              },
            },
            onSuccess: () => {
              setInProgress && setInProgress(false);
              dispatch(
                openGeneralSuccessAlert({
                  message: "Success!",
                  subtitle: "Template Paired",
                  onClose: () => dispatch(closeGeneralSuccessAlert()),
                }),
              );
              window.location.reload(); //  TODO DEPRECATE WHEN SERVICE ACCOUNTS ADDED TO REDUX STATE
            },
          }),
        );
      }}
    />
  );
}
