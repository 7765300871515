import * as types from "../actionTypes";
import * as API from "../api/notificationsAPI";
import {setApiError, setApiStart, setApiSuccess} from "./settingsActions";

export function setNotifications(data) {
  return {type: types.SET_NOTIFICATIONS, notifications: data};
}
export function addNotifications(notifications) {
  return {type: types.ADD_NOTIFICATION, notifications: notifications};
}
export function deleteNotification(notification) {
  return {type: types.DELETE_NOTIFICATION, notification};
}

export function getNotifications(params, add, onSuccess = () => null) {
  return async (dispatch) => {
    try {
      dispatch(setApiStart("guest_notifications"));
      const response = await API.getNotifications(params);
      console.log("GOT NOTIFICATIONS", response);
      if (!!response.hits) {
        dispatch(
          add
            ? addNotifications(response.hits)
            : setNotifications(response.hits),
        );
        onSuccess(response.hits);
        dispatch(setApiSuccess("guest_notifications"));
      }
      dispatch(setApiError("guest_notifications"));
    } catch (error) {
      console.log("ERROR - GET NOTIFICATIONS", error);
      dispatch(setApiError("guest_notifications"));
    }
  };
}
