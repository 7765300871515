import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Typography,
} from "@material-ui/core";
import useScheduledMessagePanelStyles from "styles/useScheduledMessagePanelStyles";
// Custom
import NotificationPanel from "components/Panels/NotificationPanel";
import ScheduledMessageList from "components/Lists/ScheduledMessageList";
import MessageSelector from "components/MultiOption/MessageSelector";
import FilledTextField from "core/inputs/FilledTextField";
import PrimaryButton from "core/buttons/PrimaryButton";
import FullLoader from "components/Dialogs/FullLoader";
import SendInput from "components/TextFields/SendInput";
import FilledSelect from "core/selects/FilledSelect";
import CloseIconButton from "core/buttons/CloseIconButton";
// Actions
import {addNotifications} from "redux/actions/notificationsActions";
import {createAction} from "redux/actions/experiencesActions";
// Utilities
import {
  getGuestContactInfo,
  getDateFromInterval,
  sleep,
  selectDefaultChannel,
} from "utilities/helperFunctions";
import {THEME} from "configuration/settings";
import {format} from "date-fns";
import clsx from "clsx";

const channels = {
  0: "SMS",
  1: "airbnb",
  2: "email",
  3: "whatsapp",
  4: "vrbo",
  5: "homeaway",
  6: "booking_com",
};

export default function ScheduledMessagePanel({
  edit,
  messages,
  guestId,
  replaceFields,
  onClose,
  onCreate,
  templates,
  whatsappAccountId,
  selectedTemplateId,
  setSelectedTemplateId,
  airbnbHidden,
}) {
  const classes = useScheduledMessagePanelStyles();
  const dispatch = useDispatch();
  // Selectors
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const guests = useSelector((state) => state.defaultReducer.guests_dict);
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  // General
  const selectedGuest = React.useMemo(
    () => guests[guestId] ?? {},
    [guests, guestId],
  );
  const is_inquiry = selectedGuest.stage === "lead";
  const {guest_email, guest_phone} = React.useMemo(
    () => getGuestContactInfo(selectedGuest),
    [selectedGuest],
  );
  // State
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [cancelActive, setCancelActive] = React.useState(is_inquiry);
  const [value, setValue] = React.useState(3);
  const [unit, setUnit] = React.useState("hours");
  const [channel, setChannel] = React.useState(-1);
  const [loading, setLoading] = React.useState(null);
  const [showAll, setShowAll] = React.useState(false);

  const cancelDate = React.useMemo(
    () => getDateFromInterval(value, unit),
    [value, unit],
  );
  const totalUpcomingMsgs = React.useMemo(() => {
    return messages.filter((m) => !!m.meets_conditions && !m.sent).length;
  }, [messages]);

  React.useEffect(() => {
    setChannel((prev) => getInitialChannel());
  }, [guestId]);

  const resetChannel = () => setChannel((prev) => getInitialChannel());

  const handleNotifDrawerClose = () => {
    setSelectedItem(null);
    setLoading((prev) => null);
  };

  const getInitialChannel = () => {
    return selectDefaultChannel({
      guest: selectedGuest,
      userProfile: user_profile,
      guestPhone: guest_phone,
      guestEmail: guest_email,
      airbnbHidden,
    });
  };

  const handleSchedule = (message) => {
    let trigger = {};
    let action = null;
    const timestamp = getDateFromInterval(value, unit)?.getTime();

    if (!!Number(value)) {
      if (!!timestamp) {
        trigger.trigger = "specific_time";
        trigger.trigger_value = timestamp;
      }
      if (cancelActive) {
        trigger.conditions = [
          {
            c_type: "bool",
            field: "guest_updated_at",
            operator: "<",
            val: new Date().getTime(),
          },
          {
            c_type: "bool",
            field: "guest_stage",
            val: "lead",
          },
        ];
      }
    } else {
      trigger.trigger = "now";
    }

    if (channel === 2) {
      // if channel === 'email'
      action = {
        action_id: "email",
        auto_translate: true,
        subject: [{language: "en", value: message.subject}],
        body: [{language: "en", value: message.html}],
        header: [],
        template: null,
      };
    } else if (channel == 3) {
      action = {
        action_id: "whatsapp",
        integration_template_id: selectedTemplateId,
        service_id: whatsappAccountId,
      };
    } else if (channel > 3 && channel < 7) {
      action = {
        action_id: "ota_email",
        auto_translate: true,
        body: [{language: "en", value: message}],
        template: null,
        channel: channels[channel],
      };
    } else {
      action = {
        action_id: channels[channel],
        auto_translate: true,
        body: [{language: "en", value: message}],
      };
    }

    dispatch(
      createAction({
        trigger: !!Object.keys(trigger).length ? trigger : null,
        guestId,
        action,
        onSuccess: (response) => {
          setLoading((prev) => null);
          dispatch(addNotifications(response.action));
        },
      }),
    );
    onClose();
  };

  const onSchedule = (message) => {
    setLoading((prev) => "Creating scheduled message...");
    sleep(900).then(() => handleSchedule(message));
  };

  function getNewMessageContent() {
    return (
      <div>
        <SendInput
          schedule
          hideChatbot
          disableGutters
          defaultBody={
            is_inquiry
              ? `Hi ${selectedGuest.name}, \nAre you still interested in booking? Please let us know if you have any questions about the listing or your stay.`
              : ""
          }
          guest={selectedGuest}
          guest_email={guest_email}
          current_user={current_user}
          user_profile={user_profile}
          replaceFields={replaceFields}
          slc_channel={channel}
          resetSelectedChannel={resetChannel}
          handleSaveScheduled={onSchedule}
          templates={templates}
          whatsappAccountId={whatsappAccountId}
          selectedTemplateId={selectedTemplateId}
          setSelectedTemplateId={setSelectedTemplateId}
        />
        <div className={classes.mt}>
          <FilledTextField
            placeholder="0"
            value={value}
            type="number"
            disableRadius
            inputProps={{min: 0, max: 99999}}
            className={classes.leftInput}
            onChange={(e) => setValue(e.target.value)}
          />
          <FilledSelect
            value={unit}
            className={classes.rightInput}
            onChange={(e) => setUnit(e.target.value)}
          >
            <MenuItem selected={unit === "seconds"} value={"seconds"}>
              {"seconds"}
            </MenuItem>
            <MenuItem selected={unit === "minutes"} value={"minutes"}>
              {"minutes"}
            </MenuItem>
            <MenuItem selected={unit === "hours"} value={"hours"}>
              {"hours"}
            </MenuItem>
            <MenuItem selected={unit === "days"} value={"days"}>
              {"days"}
            </MenuItem>
          </FilledSelect>
        </div>
        {!!Number(value) && (
          <div className={clsx(classes.cancelOption, classes.mt)}>
            <FormControlLabel
              style={{marginLeft: 0}}
              className={classes.formControlLabel}
              control={
                <Checkbox
                  size="small"
                  checked={cancelActive}
                  className={classes.checkbox}
                  onChange={(e) => setCancelActive(e.target.checked)}
                />
              }
              label={
                <Typography variant="button" className={classes.text}>
                  {`Cancel this message if guest books or replies before ${format(cancelDate, "MMMM d")} at ${format(cancelDate, "h:mmaaa")}`}
                </Typography>
              }
            />
          </div>
        )}
      </div>
    );
  }

  const loadingModal = (
    <FullLoader
      open={!!loading}
      disableDismiss
      onClose={() => setLoading((prev) => null)}
      loadingText={loading}
    />
  );

  return (
    <>
      {loadingModal}
      <NotificationPanel
        selectedNotification={selectedItem}
        guestId={guestId}
        setLoading={setLoading}
        onClose={handleNotifDrawerClose}
      />
      <div className={classes.root}>
        <Card id="scheduled-messages-panel" className={classes.card}>
          <CardHeader
            title={
              !edit && isMobileView ? (
                <div className={classes.titleRow}>
                  <Typography variant="h1">{"Scheduled Messages"}</Typography>
                  <PrimaryButton
                    size="small"
                    variant="text"
                    color="secondary"
                    onClick={onCreate}
                    label="Create message"
                    className={classes.createBtn}
                  />
                </div>
              ) : (
                <div className={classes.titleRow}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    flexWrap="wrap"
                    style={{gap: THEME.spacing.sm}}
                  >
                    <Typography variant="h1" className={classes.header}>
                      {edit ? "Schedule Message" : "Scheduled Messages"}
                    </Typography>
                    {!!edit ? (
                      <MessageSelector
                        variant="secondary"
                        slc_channel={channel}
                        selectChannel={setChannel}
                        email={!!selectedGuest.email_destination}
                        SMS={
                          !!user_profile?.connected_services?.includes("sms") &&
                          !!selectedGuest.phone
                        }
                        airbnb={
                          !airbnbHidden &&
                          !!selectedGuest.channels?.includes("Airbnb")
                        }
                        vrbo={!!selectedGuest.channels?.includes("VRBO")}
                        homeaway={
                          !!selectedGuest.channels?.includes("Home Away")
                        }
                        bookingcom={
                          !!selectedGuest.channels?.includes("Booking.com")
                        }
                      />
                    ) : (
                      totalUpcomingMsgs !== messages.length && (
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          style={{gap: THEME.spacing.sm}}
                        >
                          <PrimaryButton
                            label="Upcoming"
                            variant={showAll ? "outlined" : "contained"}
                            color="primary"
                            className={classes.btnSelector}
                            onClick={() => setShowAll((prev) => false)}
                          />
                          <PrimaryButton
                            label="All Messages"
                            variant={!showAll ? "outlined" : "contained"}
                            color="primary"
                            style={{minWidth: 116}}
                            className={classes.btnSelector}
                            onClick={() => setShowAll((prev) => true)}
                          />
                        </Box>
                      )
                    )}
                  </Box>
                  <CloseIconButton sm onClick={() => onClose()} />
                </div>
              )
            }
            titleTypographyProps={{
              variant: "caption",
              className: classes.title,
            }}
            className={classes.cardHeader}
          />
          <CardContent className={classes.cardContent}>
            {edit ? (
              getNewMessageContent()
            ) : (
              <ScheduledMessageList
                messages={messages}
                guestId={guestId}
                showAll={showAll}
                onClick={setSelectedItem}
              />
            )}
          </CardContent>
        </Card>
      </div>
    </>
  );
}
