import React, {useEffect, useMemo, useRef, useState} from "react";
import usePrevious from "hooks/usePrevious";
import {useTranslation} from "react-i18next";
import {isMobile} from "react-device-detect";
// UI
import {Box, CircularProgress, SvgIcon, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import SkipIcon from "@material-ui/icons/SkipNext";
import CircleIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import {ReactComponent as BoardingPassIcon} from "assets/icons/boarding-pass.svg";
import {ReactComponent as CheckCircleIcon} from "assets/icons/check-blue-circle.svg";
import {ReactComponent as GuestCircleIcon} from "assets/icons/guest-green-circle.svg";
// Components
import ActionsMenu from "ui/base/menu/ActionsMenu";
import WarningAlert from "core/alerts/WarningAlert";
import AccordionItem from "core/listItems/AccordionItem";
import MoreIconButton from "ui/base/button/MoreIconButton";
import EmptyContentText from "components/Misc/EmptyContentText";
import BoardingPassStepper from "ui/component/messaging/list/BoardingPassStepper";
import BPAccessCodesWarningCard from "../card/BPAccessCodesWarningCard";

const useStyles = makeStyles((theme) => ({
  expandedSection: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  infoItem: {
    backgroundColor: "rgba(95, 102, 130, 0.1)",
    color: "#5F6682",
    padding: theme.spacing(1, 2),
    borderRadius: 360,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

const BoardingPassInfo = ({
  booking,
  handleAction,
  loading,
  guestMetadata,
  openAccessCodes,
}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const buttonClicked = useRef(null);
  const bpTitleActionClicked = useRef(false);
  const bookingActionsLoaded = useRef(true);
  const [expanded, setExpanded] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [BPActions, setBPActions] = useState([]);
  const [vfActions, setVfActions] = useState([]);
  const [BPOptionsAnchorEl, setBPOptionsAnchorEl] = useState(null);
  const [stepsAnchorEl, setStepsAnchorEl] = useState(null);
  const [selectedActions, setSelectedActions] = useState([]);
  const [selectedStepIndex, setSelectedStepIndex] = useState(null);
  const isVerified = !!booking.tags?.includes("Verified");
  const isRevoked = !!booking.tags?.includes("Revoked");
  const bpAlerts = booking?.actions?.bp_actions || [];
  const bookingStatus = booking?.status || "inquiry";
  const prevBpAlerts = usePrevious(bpAlerts);
  const prevBooking = usePrevious(booking);
  const isActionInProgress = !!loading?.length;
  const guestBPStatus = isRevoked
    ? "Revoked"
    : isVerified
      ? "Verified"
      : "Unverified";
  const noVerificationData =
    !guestMetadata?.identity_docs?.length && !booking.signed_agreements?.length;
  const hasAccess = useMemo(() => {
    let newActions = (booking?.actions?.bp_dropdown || []).filter(
      (bpAction) =>
        ["verify_guest", "unrevoke_guest"].includes(
          bpAction.action_options.action_id,
        ) && bpAction.action_options.confirm === false,
    );
    return !!newActions.length;
  }, [booking]);

  const icons = {
    bypassed: (
      <SkipIcon
        color="secondary"
        style={{border: "2px solid", borderRadius: "50%"}}
      />
    ),
    disabled: <CircleIcon style={{color: "#F1F3F9"}} />,
    incomplete: <CircleIcon style={{color: "#5F6682"}} />,
    complete: <SvgIcon component={CheckCircleIcon} viewBox="0 0 20 20" />,
    active: (
      <SvgIcon
        component={GuestCircleIcon}
        viewBox="0 0 20 20"
        style={{color: "white"}}
      />
    ),
  };

  useEffect(() => {
    if (prevBooking?.booking_id !== booking.booking_id) {
      bookingActionsLoaded.current = false;
    }

    let vfSteps = booking?.actions?.vf_steps || [];
    setVfActions(vfSteps);
    setActiveStep(
      vfSteps.findIndex((vfStp) => vfStp.status_label === "incomplete"),
    );
    setBPActions(
      (booking?.actions?.bp_dropdown || []).map((bpAction) => {
        let actionID = bpAction.action_options.action_id;
        if (
          ["verify_guest", "unrevoke_guest"].includes(
            bpAction.action_options.action_id,
          )
        ) {
          return {
            ...bpAction,
            title: t(`${actionID}_${hasAccess ? "revoke" : "grant"}_title`),
            description: t(
              `${actionID}_${hasAccess ? "revoke" : "grant"}_description`,
            ),
            disabled: isActionInProgress,
            onClick: handleActionClick(bpAction),
          };
        } else if (actionID === "verification_report") {
          return {
            ...bpAction,
            title: t(`${actionID}_title`),
            description: t(`${actionID}_description`),
            disabled: isActionInProgress || noVerificationData || isMobile,
            onClick: handleActionClick(bpAction),
          };
        }
      }),
    );
  }, [booking, loading]);

  useEffect(() => {
    if (!prevBpAlerts?.length && !!bpAlerts.length) {
      setExpanded((prev) => true);
      bookingActionsLoaded.current = true;
    } else if (!bookingActionsLoaded.current) {
      bookingActionsLoaded.current = true;
      setExpanded(
        (prev) =>
          !booking?.actions?.bp_fees?.length &&
          !booking?.actions?.bp_upsells?.length,
      );
    }
  }, [bpAlerts]);

  const handleActionClick = (props, type, ignoreType) => () => {
    buttonClicked.current = type;
    if (!ignoreType) {
      handleAction(props, type);
    } else {
      handleAction(props);
    }
  };

  function getActionTranslations(label) {
    switch (label) {
      case "Skip":
      case "Reset":
      case "Cancel":
      case "Release":
      case "Refund":
        return {
          title: t(`${label}-menu-item-title`),
          description: t(`${label}-menu-item-description`),
        };

      default:
        return {
          title: t(label),
          description: "",
        };
    }
  }

  const getStepProps = ({action, index}) => {
    let actionsArray = [];
    let title = t(action.label);
    if (!!action.detailed_statuses_labels) {
      actionsArray = action?.actions?.map((act) => {
        let optionNames = act.options.map((opt) => opt.label);
        let translations = getActionTranslations(act.label);
        return {
          variant: act.variant,
          title: translations.title,
          description: translations.description,
          label: act.label,
          disabled: isActionInProgress,
          loading: loading.some((l) => optionNames.includes(l)),
          options: act.options.map((opt) => ({
            label: opt.label,
            title: opt.label,
            onClick: handleActionClick(opt, act.label, true),
          })),
        };
      });
    } else {
      actionsArray = action?.actions?.map((act) => {
        let translations = getActionTranslations(act.label);
        return {
          variant: act.variant,
          title: translations.title,
          description: translations.description,
          label: act.label,
          disabled: isActionInProgress,
          loading: loading.includes(act?.label),
          onClick: handleActionClick(act),
        };
      });
    }

    return {
      title,
      status: index === activeStep ? "active" : action.status_label,
      description: t(`${action.status_label}-${action.label}-label`),
      outlined: ["disabled", "bypassed"].includes(action.status_label),
      disabled: isActionInProgress,
      actions: actionsArray,
      icon:
        isActionInProgress && selectedStepIndex === index ? (
          <CircularProgress color="secondary" size={20} />
        ) : index === activeStep ? (
          icons.active
        ) : (
          icons[action.status_label]
        ),
      onClick: (iconRef) => {
        if (!stepsAnchorEl) {
          setSelectedActions(actionsArray);
          setSelectedStepIndex(index);
          setStepsAnchorEl(iconRef);
        }
      },
    };
  };

  return (
    <>
      <ActionsMenu
        open={!!BPOptionsAnchorEl}
        anchorEl={BPOptionsAnchorEl}
        setAnchorEl={(anchEl) => {
          bpTitleActionClicked.current = false;
          setBPOptionsAnchorEl(anchEl);
        }}
        items={BPActions}
      />
      <ActionsMenu
        open={!!stepsAnchorEl}
        anchorEl={stepsAnchorEl}
        setAnchorEl={setStepsAnchorEl}
        items={selectedActions}
      />
      <AccordionItem
        divider
        firstItem
        disableHover
        customInfoItem={
          <Box display="flex" flexDirection="row" alignItems="center">
            {isActionInProgress && selectedStepIndex === null ? (
              <Box className={classes.infoItem}>
                <CircularProgress color="secondary" size={16} />
              </Box>
            ) : (
              !!guestBPStatus && (
                <Box className={classes.infoItem}>
                  <Typography component="span" style={{fontWeight: 700}}>
                    {guestBPStatus}
                  </Typography>
                </Box>
              )
            )}
            <Box ml={2}>
              <MoreIconButton
                onClick={(e) => {
                  bpTitleActionClicked.current = true;
                  setBPOptionsAnchorEl(e.currentTarget);
                }}
              />
            </Box>
          </Box>
        }
        expanded={expanded}
        title={"Boarding Pass"}
        onClick={() => {
          if (!bpTitleActionClicked.current) {
            setExpanded((prev) => !prev);
          }
        }}
        icon={<SvgIcon viewBox="0 0 21 14" component={BoardingPassIcon} />}
      />
      {!!expanded &&
        (bookingStatus.includes("inquiry") ? (
          <EmptyContentText label="The boarding pass is not available for inquiries" />
        ) : (
          <div className={classes.expandedSection}>
            {bpAlerts.map((props, index) => (
              <WarningAlert
                key={`bkg_alert_${index}`}
                hide={false}
                disableMargin
                action={handleActionClick(props, "confirm")}
                actionLoading={
                  loading.includes(props?.label) &&
                  buttonClicked.current === "confirm"
                }
                actionButtonLabel={props.confirm?.label}
                disableActionBtn={isActionInProgress}
                secondaryAction={handleActionClick(props, "deny")}
                secondaryActionLoading={
                  loading.includes(props?.label) &&
                  buttonClicked.current === "deny"
                }
                secondaryActionLabel={props.deny?.label}
                disableSecondaryActionBtn={isActionInProgress}
                title={props.label}
              />
            ))}
            {!!booking?.lock_codes?.length && !hasAccess && (
              <BPAccessCodesWarningCard onClick={() => openAccessCodes()} />
            )}
            <BoardingPassStepper
              steps={vfActions}
              getStepProps={getStepProps}
            />
          </div>
        ))}
    </>
  );
};

export default BoardingPassInfo;
