import React from "react";
import {MenuItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import FilledSelect from "core/selects/FilledSelect";
import {PaymentStatusEnum} from "configuration/enums";

const options = PaymentStatusEnum;

const useStyles = makeStyles((theme) => ({
  selectorContainer: {
    width: "100%",
    height: "100%",
    "& > *": {height: "100%"},
  },
}));

export default function BookingPaymentStatus({edit, value, onValueChange}) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(value);

  React.useEffect(() => {
    if (edit && value !== selected) {
      setSelected((prev) => value);
    }
  }, [value]);

  React.useEffect(() => {
    if (edit) {
      onValueChange(selected);
    }
  }, [selected]);

  const handleChange = (e) => {
    const val = e.target.value;
    setSelected((prev) => val);
  };
  if (edit) {
    return (
      <div className={classes.selectorContainer}>
        <FilledSelect
          fullWidth
          label="Payment status"
          value={selected}
          onChange={handleChange}
        >
          <MenuItem value="" disabled>
            Select an option
          </MenuItem>
          {Object.keys(options).map((op) => (
            <MenuItem value={op} key={op}>
              {options[op]}
            </MenuItem>
          ))}
        </FilledSelect>
      </div>
    );
  } else {
    return null;
  }
}
