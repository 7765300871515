import React from "react";
// UI
import {Divider, IconButton, Typography} from "@material-ui/core";
import {lighten, makeStyles} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
// Custom
import PrimaryButton from "core/buttons/PrimaryButton";
import Conditions from "./Conditions/Conditions";
import Trigger from "./Trigger";
import Action from "./Actions/Action";
// Helpers & Utilities
import {allTriggers} from "configuration/constants";
import {THEME} from "configuration/settings";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    backgroundColor: lighten(THEME.mutedGreen, 0.9),
    border: `1px solid ${THEME.mutedGreen}`,
    borderRadius: 10,
    padding: theme.spacing(3, 4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: 13,
    color: THEME.grey,
  },
  description: {
    color: "#979797",
    paddingBottom: theme.spacing(1),
  },
  btn: {height: "fit-content"},
  divider: {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.15,
    margin: theme.spacing(4, 0),
  },
  icon: {
    fontSize: 18,
    color: "#43535F",
  },
  iconBtn: {
    marginTop: -theme.spacing(2),
    marginRight: -theme.spacing(3),
    marginBottom: theme.spacing(2),
    width: "fit-content",
    alignSelf: "flex-end",
    padding: theme.spacing(1),
  },
}));

export default function ExperienceStep({
  expType,
  experience = {},
  data,
  setAction,
  setCondition,
  setTrigger,
  hideTriggers,
  removeStep,
  viewOnly,
  setIsEditing,
  listing,
}) {
  const classes = useStyles();
  const [triggerSelected, setTriggerSelected] = React.useState(null);
  const [actionsSelected, setActionsSelected] = React.useState(null);
  const [edit, setEdit] = React.useState({
    trigger: false,
    condition: false,
    actions: false,
  });
  const [additionalObjectsVariables, setAdditionalObjectsVariables] =
    React.useState([]);

  const editMode = React.useMemo(() => {
    return Object.values(edit).some((val) => !!val);
  }, [edit]);

  React.useEffect(() => {
    if (!data) {
      return;
    }
    resetSelectedTrigger();
    setActionsSelected((prev) => null);
  }, [data]);

  React.useEffect(() => {
    setIsEditing(editMode);
  }, [editMode]);

  const handleEditChange = (type) => (val) =>
    setEdit((prev) => ({...prev, [type]: val}));
  const handleTriggerChange = (newTrigger) => {
    setTrigger(newTrigger);
    if (newTrigger && newTrigger?.trigger?.startsWith("pr_")) {
      setAdditionalObjectsVariables((prev) => [...prev, "guest_bill"]);
    }
  };
  const handleConditionChange = (newConditions) => setCondition(newConditions);
  const handleActionsChange = (newActions) => setAction(newActions);
  const onTriggerChange = (newOption) =>
    setTriggerSelected((prev) => newOption);
  const onActionSelect = (newAction) => setActionsSelected((prev) => newAction);

  const resetSelectedTrigger = () => {
    if (data.trigger === null) {
      setTriggerSelected((prev) => null);
      setAdditionalObjectsVariables((prev) => []);
    } else {
      setTriggerSelected(
        (prev) =>
          allTriggers[data.trigger]?.type ??
          (expType === "instant" ? "guest" : null),
      );
    }
  };

  function getDescription(type) {
    switch (type) {
      case "trigger":
        return "Define what starts this automated workflow.";
      case "actions":
        return "Define what this workflow does.";
      default:
        return "";
    }
  }

  function getContent(type) {
    switch (type) {
      case "trigger":
        return (
          <Trigger
            disableEdit={editMode}
            data={data}
            viewOnly={viewOnly}
            experience={experience}
            triggerType={triggerSelected}
            setTriggerType={onTriggerChange}
            setData={handleTriggerChange}
            setTriggerEdit={handleEditChange("trigger")}
            resetSelectedTrigger={resetSelectedTrigger}
            listing={listing}
          />
        );
      case "condition":
        return (
          <Conditions
            disableAddCondition={editMode}
            title="Only If"
            viewOnly={viewOnly}
            data={data.conditions}
            setData={handleConditionChange}
            setConditionEdit={handleEditChange("conditions")}
            additionalObjectsVariables={additionalObjectsVariables}
          />
        );
      case "actions":
        return (
          <Action
            disableEdit={editMode}
            data={data.actions}
            hideEdit={viewOnly}
            disableDelete={viewOnly}
            allowAll={!hideTriggers}
            trigger={data.trigger}
            newService={actionsSelected}
            setData={handleActionsChange}
            setActionEdit={handleEditChange("actions")}
            removeNewService={() => setActionsSelected((prev) => null)}
            additionalObjectsVariables={additionalObjectsVariables}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div className={classes.root}>
      {!!removeStep && !viewOnly && (
        <IconButton className={classes.iconBtn} onClick={() => removeStep()}>
          <DeleteIcon className={classes.icon} />
        </IconButton>
      )}
      {!hideTriggers && (
        <>
          <div className={classes.row}>
            <div style={{flexGrow: 1}}>
              <Typography variant="subtitle1" className={classes.title}>
                {"When"}
              </Typography>
              <Typography className={classes.description}>
                {getDescription("trigger")}
              </Typography>
            </div>
          </div>
          {getContent("trigger")}
          <Divider className={classes.divider} />
        </>
      )}
      {getContent("condition")}
      <Divider className={classes.divider} />
      <div className={classes.row}>
        <div style={{flexGrow: 1}}>
          <Typography variant="subtitle1" className={classes.title}>
            {"Then"}
          </Typography>
          <Typography className={classes.description}>
            {getDescription("actions")}
          </Typography>
        </div>
        {!viewOnly && (
          <PrimaryButton
            label="Add action"
            disabled={editMode || (!hideTriggers && !triggerSelected)}
            className={classes.btn}
            onClick={(e) => onActionSelect("msg")}
          />
        )}
      </div>
      {getContent("actions")}
    </div>
  );
}
