import React from "react";

import {Col, Row, Container} from "reactstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

export default function Loading({text}) {
  return (
    <Container fluid>
      <Row align="center" style={{marginTop: "80px"}}>
        <Col>
          <CircularProgress />
        </Col>
      </Row>
      <Row align="center" style={{marginTop: "10px"}}>
        <Col>
          <Typography color="primary">{text}</Typography>
        </Col>
      </Row>
    </Container>
  );
}
