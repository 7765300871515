import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import FilledTextField from "core/inputs/FilledTextField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
}));

export default function NumberField({edit, value, onValueChange}) {
  const classes = useStyles();
  const [val, setVal] = React.useState(value);

  React.useEffect(() => {
    if (edit && value !== val) {
      setVal((prev) => value);
    }
  }, [value]);

  React.useEffect(() => {
    if (edit) {
      onValueChange(val);
    }
  }, [val]);

  const handleNumberChange = (e) => {
    const newVal = e.target.value;
    setVal((prev) => newVal);
  };

  if (!edit) {
    return value ?? "-";
  }
  return (
    <div className={classes.root}>
      <FilledTextField
        fullWidth
        label="Value"
        type="number"
        inputProps={{min: 0, max: 100000}}
        value={val}
        onChange={handleNumberChange}
      />
    </div>
  );
}
