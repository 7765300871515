import React, {useState} from "react";

// Returns true if the component height is greater than maxHeight, false otherwise
const useComponentHeightObserver = ({
  ref,
  expanded,
  minHeight = 180,
  maxHeight = 500,
}) => {
  const [isLarge, setIsLarge] = useState(false);

  React.useLayoutEffect(() => {
    if (ref.current?.clientHeight > maxHeight) {
      !isLarge && setIsLarge(true);
    } else if (isLarge && !expanded && ref.current?.clientHeight < minHeight) {
      setIsLarge(false);
    }
  });

  return isLarge;
};

export default useComponentHeightObserver;
