import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import clsx from "clsx";
import qs from "qs";
// UI
import {Container} from "reactstrap";
import Typography from "@material-ui/core/Typography";
import {Button, CircularProgress, makeStyles} from "@material-ui/core";
// Custom
import FullSizeCard from "core/cards/FullSizeCard";
// Actions
import {integrationSpecs} from "configuration/enums.js";
import {signUpChannel, postChannels} from "redux/actions/accountsActions";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
  },
  btn: {
    borderRadius: 999,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: "fit-content",
    alignSelf: "center",
    marginTop: theme.spacing(5),
  },
}));

export default function ChannelAuth(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    match: {params},
  } = props;
  const {channel_confirmation} = params;
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const [status, setStatus] = useState("loading");
  const [data, setData] = useState({});

  useEffect(() => {
    createConnectedAccount();
  }, [current_user]);

  const createConnectedAccount = () => {
    if (!current_user) return;
    let searchParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    if (searchParams) {
      let body = {
        integration_type: channel_confirmation,
        signup_fields: searchParams,
      };
      dispatch(
        signUpChannel(
          body,
          (response) => {
            dispatch(
              postChannels(
                {data: response.create_pms_account},
                (response) => setStatus("connected"),
                (response) => setStatus("error"),
              ),
            );
          },
          () => setStatus("error"),
        ),
      );
    } else {
      setStatus("error");
    }
  };

  const onConnectAccountSuccess = (res) => {
    if (res && res.success) {
      setStatus("connected");
    } else {
      onConnectAccountError();
    }
  };

  const onConnectAccountError = (err) => {
    setStatus("error");
  };

  return (
    <FullSizeCard>
      <Container className={classes.container}>
        <Typography variant="h6">Account Connection</Typography>
        <div
          className={clsx(classes.content, {
            [classes.center]: status === "loading",
          })}
        >
          {
            {
              loading: <CircularProgress />,
              connected: (
                <>
                  <Typography variant="h2" className="mt-4">
                    Your account is now connected to Enso Connect
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btn}
                    onClick={() => props.history.replace(`/admin/listings`)}
                  >
                    Go To Listings
                  </Button>
                </>
              ),
              error: (
                <>
                  <Typography variant="h2" className="mt-4">
                    There was an error connecting your account. Please try again
                    or contact our client success team for assistance.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btn}
                    onClick={() => props.history.replace(`/admin/createhouse`)}
                  >
                    Go Back
                  </Button>
                </>
              ),
            }[status]
          }
        </div>
      </Container>
    </FullSizeCard>
  );
}
