import React, {Fragment, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
// Custom
import NotificationList from "components/Lists/NotificationList";
import DeviceStatus from "components/TextFields/DeviceStatus";
import CustomCardHeader from "core/cards/CustomCardHeader";
import FilledTextField from "core/inputs/FilledTextField";
import CloseIconButton from "core/buttons/CloseIconButton";
import PairedHousesList from "../../Lists/PairedHousesList";
import DeviceAccessCodes from "./DeviceAccessCodes";
import WarningAlert from "core/alerts/WarningAlert";
import BackButton from "core/buttons/BackButton";
// Actions
import {getNotifications} from "redux/actions/notificationsActions";
import {getDevicesLocked} from "redux/api/devicesAPI";
// Utilities
import {saveDevice, deleteDevice} from "redux/actions/devicesActions";
import usePrevious from "hooks/usePrevious";
import _ from "lodash";
import qs from "qs";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: "1px solid #0D568C0D",
  },
  cancelBtn: {
    color: "#A8A8A8",
    fontSize: 14,
  },
  delete: {
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {color: theme.palette.error.dark},
  },
  form: {
    padding: theme.spacing(4),
    flexGrow: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    gap: theme.spacing(3),
    "& .seam-components": {
      "& .seam-content-header": {display: "none"},
      "& .seam-body": {
        margin: "0px !important",
        "& > *:nth-child(2)": {display: "none"},
      },
    },
  },
  sliderContainer: {width: 200},
  nameField: {
    border: "1px solid #d5d8dc",
    borderRadius: 8,
    "& label": {
      transform: "none !important",
      lineHeight: "134%",
      color: "#6e7179",
      top: 12,
      left: 14,
      fontFamily:
        '"Source Sans Pro",system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif',
    },
    "& .MuiFilledInput-root": {
      borderRadius: "8px !important",
      height: 70,
      display: "flex",
      alignItems: "flex-end",
      backgroundColor: "transparent !important",
      "& input": {
        fontFamily:
          '"Source Sans Pro",system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif',
        padding: "12px 12px 16px 14px",
        fontWeight: 600,
        fontSize: 16,
      },
    },
  },
}));

export default function DevicePanel({
  selectedDevice,
  showBack,
  handleClose,
  onDeviceEdit,
  removeDevice,
  disableEdit,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const backupDevice = React.useRef(null);
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  const deviceArray = useSelector((state) => state.defaultReducer.devices);
  const [disabledSave, setDisabledSave] = React.useState(true);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [deviceOptions, setDeviceOptions] = React.useState({});
  const [notifications, setNotifications] = React.useState([]);
  const [visibilities, setVisibilities] = React.useState({});
  const [connectedHouses, setConnectedHouses] = React.useState([]);
  const device = useMemo(() => {
    return (
      deviceArray.find(
        (d) => d.device_id.split("#")[1] === selectedDevice?.device_id,
      ) ?? {}
    );
  }, [deviceArray, selectedDevice]);
  const prevDevice = usePrevious(device);
  let noBackupCodes =
    !!device?.options?.access_codes_supported &&
    !device?.options?.recovery_codes?.find((rc) => rc.role === "guest");
  let onlineAccCodesSupported =
    !!device?.options?.online_access_codes_supported;

  React.useEffect(() => {
    if (!device || _.isEqual(prevDevice, device)) {
      return;
    }
    const params = {enso_key: current_user, device_id: device.device_id};
    dispatch(getNotifications(qs.stringify(params), true, onSuccess));
    resetInfo();

    if (device.device_type === "sl") {
      getDevicesLocked(device.enso_key, device.device_id).then((resp) => {
        setDeviceOptions((prev) => ({
          ...prev,
          locked: resp.locked,
          recovery_codes: resp.codes,
        }));
      });
    }
  }, [device]);

  React.useEffect(() => {
    const validName = !!name.trim() && name.trim() !== device.name;
    const validFields = validName;
    if (validFields && disabledSave) setDisabledSave(false);
    else if (!validFields && !disabledSave) setDisabledSave(true);
  }, [name]);

  React.useEffect(() => {
    setDisabledSave(false);
  }, [deviceOptions]);

  const resetInfo = () => {
    setName((prev) => device.name || "");
    setDeviceOptions((prev) => device.options);
    const newVisibilities = {};
    if (device?.device_type === "sl") {
      _.each(
        device?.options?.recovery_codes ?? [],
        (rc) => (newVisibilities[rc.access_code] = false),
      );
    }
    setVisibilities((prev) => newVisibilities);
    setConnectedHouses((prev) => device.listing_ids || []);
  };

  const onSuccess = (hits) => {
    setNotifications((prev) => hits);
  };

  const handleDialogClose = () => setDialogOpen(false);

  const handleNameChange = (e) => {
    const val = e.target.value;
    setName((prev) => val);
  };


  const handleSave = () => {
    backupDevice.current = {...device};
    const newDevice = {
      ...device,
      name: name,
      options: deviceOptions,
      listing_ids: connectedHouses,
    };
    setDisabledSave((prev) => true);
    dispatch(
      saveDevice(
        newDevice,
        (response) => onDeviceEdit(newDevice),
        () => onDeviceEdit(backupDevice.current),
      ),
    );
  };

  const handleDelete = () => {
    dispatch(deleteDevice(device, () => removeDevice(device)));
    handleClose();
  };

  const handlePairedHouses = (newPairedHouses) => {
    setConnectedHouses((prev) => newPairedHouses);
  };

  const getDeviceDetails = () => {
    if (!device) {
      return null;
    }
    switch (device.device_type) {
      case "ns":
        return (
          <Box mt={2} className={classes.sliderContainer}>
            {/* <Typography id="threshold-slider" gutterBottom color="textSecondary" variant="caption">Threshold</Typography>
        <Grid container spacing={2}>
          <Grid item><VolumeDown color="disabled" /></Grid>
          <Grid item xs>
            <Slider
              value={deviceOptions?.thresholds?.[0]?.value || 0}
              valueLabelDisplay="auto"
              min={0}
              max={100}
              step={1}
              disabled={disableEdit}
              onChange={handleThresholdChange}
              aria-labelledby="threshold-slider"
            />
          </Grid>
          <Grid item><VolumeUp color="disabled" /></Grid>
        </Grid> */}

            <Typography
              id="threshold-info"
              gutterBottom
              color="textSecondary"
              variant="caption"
            >
              Thresholds
            </Typography>
            {deviceOptions?.thresholds?.length > 0 ? (
              deviceOptions?.thresholds?.map((t) => {
                return (
                  <Grid container spacing={2}>
                    <Grid item>
                      {" "}
                      <Typography>
                        {" "}
                        {t.name || t.threshold_id}:{" "}
                      </Typography>{" "}
                    </Grid>
                    <Grid item>
                      {" "}
                      <Typography> {t.value} dB</Typography>{" "}
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Typography>No thresholds configured</Typography>
            )}
            {deviceOptions?.noise_level != undefined && (
              <div>
                <Typography
                  id="battery-info"
                  gutterBottom
                  color="textSecondary"
                  variant="caption"
                >
                  Noise Level
                </Typography>
                <Typography> {deviceOptions?.noise_level} dB </Typography>
              </div>
            )}
            {device.battery_level != undefined && (
              <div>
                <Typography
                  id="battery-info"
                  gutterBottom
                  color="textSecondary"
                  variant="caption"
                >
                  Battery Level
                </Typography>
                <Typography> {device.battery_level * 100}% </Typography>
              </div>
            )}
            {deviceOptions.temperature != undefined && (
              <div>
                <Typography
                  id="temperature-info"
                  gutterBottom
                  color="textSecondary"
                  variant="caption"
                >
                  Temperature
                </Typography>
                <Typography> {deviceOptions.temperature}°C </Typography>
              </div>
            )}
            {deviceOptions.humidity && (
              <div>
                <Typography
                  id="humidity-info"
                  gutterBottom
                  color="textSecondary"
                  variant="caption"
                >
                  Humidity
                </Typography>
                <Typography> {deviceOptions.humidty}% </Typography>
              </div>
            )}
          </Box>
        );
      case "ip":
        return (
          <>
            <FilledTextField
              id="phn_code"
              label="Phone Code"
              disabled={disableEdit}
              placeholder="e.g: Bedroom Light"
              value={deviceOptions.phone_code || ""}
              onChange={(e) =>
                setDeviceOptions({...deviceOptions, phone_code: e.target.value})
              }
              fullWidth
            />
            <FilledTextField
              id="voice_msg"
              label="Voice Message"
              disabled={disableEdit}
              placeholder="e.g: Bedroom Light"
              onChange={(e) =>
                setDeviceOptions({
                  ...deviceOptions,
                  voice_message: e.target.value,
                })
              }
              value={deviceOptions.voice_message || ""}
              fullWidth
            />
            <Typography>
              The number your interphone should call is{" "}
              <strong>{deviceOptions.enso_number}</strong>
            </Typography>
          </>
        );
      case "sl":
        return (
          <DeviceAccessCodes
            device={device}
            disableEdit={disableEdit}
            visibilities={visibilities}
            deviceOptions={deviceOptions}
            editDevice={onDeviceEdit}
            setDeviceOptions={setDeviceOptions}
            setVisibilities={setVisibilities}
          />
        );
      default:
        return <></>;
    }
  };

  const confirmDialog = (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="dialog-title"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="dialog-title" style={{paddingBottom: "0"}}>
        Delete Device?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {"This device and its information will be permanently deleted"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="primary">
          {"Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div className={classes.root}>
      {confirmDialog}
      <CustomCardHeader
        title={
          showBack ? (
            <BackButton header goBack={handleClose} />
          ) : (
            "Device Details"
          )
        }
        type="title"
        className={classes.header}
        action={
          disableEdit ? (
            !showBack && <CloseIconButton sm onClick={handleClose} />
          ) : (
            <Fragment>
              <Button
                className="mr-2"
                classes={{label: classes.cancelBtn}}
                size="small"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                disableElevation
                disabled={disabledSave}
                onClick={handleSave}
              >
                Save
              </Button>
            </Fragment>
          )
        }
      />
      {noBackupCodes && onlineAccCodesSupported && (
        <Box mx={3}>
          <WarningAlert
            sync
            small
            disableMargin
            title={
              "Backup codes are generating, please wait for them to set before pairing your device"
            }
          />
        </Box>
      )}
      <form className={classes.form}>
        <seam-device-details
          disable-css-injection="true"
          client-session-token={serviceAccounts.seam_client_session_id}
          device-id={`${selectedDevice?.device_id}`}
        />
        <DeviceStatus device={device} showPairedUnpairedOnly />
        <FilledTextField
          label="Display name"
          disabled={disableEdit}
          placeholder="e.g: Bedroom Light"
          value={name}
          className={classes.nameField}
          onChange={handleNameChange}
          fullWidth
        />
        {getDeviceDetails()}
        <PairedHousesList
          device={device}
          disabled={disableEdit}
          editData={handlePairedHouses}
        />
        {!!notifications.length && (
          <Box mt={2} flexGrow={1} minHeight={200}>
            <Typography gutterBottom color="textSecondary" variant="h1">
              Device Notifications
            </Typography>
            <NotificationList
              type="devices"
              selected={device?.device_id}
              deviceNotifications={notifications}
            />
          </Box>
        )}
        {!disableEdit && (
          <Box>
            <Typography
              variant="subtitle1"
              color="error"
              className={classes.delete}
              onClick={() => setDialogOpen(true)}
            >
              {"Delete Device"}
            </Typography>
          </Box>
        )}
      </form>
    </div>
  );
}
