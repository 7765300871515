import React from "react";
import {
  Divider,
  IconButton,
  InputBase,
  Typography,
  makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Close";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  tag: {
    overflow: "hidden",
    minHeight: 24,
    borderRadius: 15,
    width: "fit-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(0, 2),
    paddingTop: 2,
    paddingBottom: 2,
    gap: theme.spacing(2),
    border: (props) =>
      `1px solid ${!!props.light ? theme.palette.common.white : theme.palette.primary.main}`,
    color: (props) =>
      !!props.error
        ? THEME.satisfaction.unsatisfied.color
        : !!props.light
          ? theme.palette.common.white
          : theme.palette.primary.main,
    "&.error": {border: `1px solid ${theme.palette.error.main}`},
    "&.md": {minHeight: 25},
  },
  square: {borderRadius: 5},
  readOnly: {
    border: "none !important",
    padding: theme.spacing(1, 2),
    background: (props) =>
      !!props.error
        ? THEME.satisfaction.unsatisfied.bg
        : "rgba(13, 86, 140, 0.10)",
  },
  label: {
    lineHeight: "16px",
    height: "100%",
    padding: 0,
    fontSize: 12,
    "&.md": {
      ...theme.typography.body1,
      fontSize: 13,
    },
  },
  divider: {
    opacity: 0.3,
    margin: "-2px 0",
    backgroundColor: (props) =>
      !!props.light ? theme.palette.common.white : theme.palette.primary.main,
    "&.error": {backgroundColor: theme.palette.error.main},
  },
  iconBtn: {
    padding: 0,
    marginRight: -3,
  },
  deleteIcon: {
    width: 12,
    height: 12,
    color: (props) =>
      !!props.light ? theme.palette.common.white : theme.palette.primary.main,
    "&.error": {color: theme.palette.error.main},
  },
  input: {
    lineHeight: "16px",
    fontSize: 12,
    height: "100%",
    padding: 0,
    width: (props) => (!!props.large ? 150 : 80),
    color: (props) =>
      !!props.light ? theme.palette.common.white : theme.palette.primary.main,
    "&.error": {color: theme.palette.error.main},
    "&.md": {
      ...theme.typography.body1,
      fontSize: 13,
    },
  },
}));

export default function Tag({
  label,
  light,
  edit,
  square,
  readOnly,
  tagRef,
  error,
  handleSaveTag,
  onDelete,
  onTextChange,
  onBlur,
  onFocus,
  onKeyDown,
  large = false,
  md,
}) {
  const classes = useStyles({light, error, square, readOnly, large});
  const [tagName, setTagName] = React.useState("");

  const handleNameChange = (e) => {
    let val = e.target.value;
    !!onTextChange && onTextChange(val);
    setTagName((prev) => val);
  };

  const saveTag = () => {
    if (!!tagName.trim()) {
      handleSaveTag(tagName.trim());
    }
  };
  const handleOnBlur = () => {
    !!onBlur && onBlur();
  };
  const handleOnFocus = (e) => {
    !!onFocus && onFocus(e.currentTarget);
  };

  const handleOnKeyDown = (e) => {
    if (!!onKeyDown) {
      onKeyDown(e);
    }
    if (e.key === "Enter") {
      saveTag();
    } else if (e.key === "Escape") {
      onDelete();
    }
  };

  return (
    <div
      ref={tagRef}
      className={clsx(classes.tag, {
        [classes.readOnly]: !!readOnly,
        [classes.square]: !!square,
        error: !!error,
        md: !!md,
      })}
    >
      {!!edit ? (
        <InputBase
          autoFocus
          value={tagName}
          classes={{input: clsx(classes.input, {error: !!error, md: !!md})}}
          onChange={handleNameChange}
          onKeyDown={handleOnKeyDown}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
        />
      ) : (
        <Typography className={clsx(classes.label, {md: md})}>
          {label}
        </Typography>
      )}
      {!!onDelete && (
        <>
          <Divider
            orientation="vertical"
            flexItem
            className={clsx(classes.divider, {error: !!error})}
          />
          <IconButton className={classes.iconBtn} onClick={onDelete}>
            <DeleteIcon
              className={clsx(classes.deleteIcon, {error: !!error})}
            />
          </IconButton>
        </>
      )}
    </div>
  );
}
