import React, {useEffect, useState} from "react";
import {Box, CircularProgress, makeStyles, Typography} from "@material-ui/core";
import PrimaryButton from "core/buttons/PrimaryButton";
import SearchBar from "core/bars/SearchBar";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  searchSection: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(3),
    marginBottom: theme.spacing(2),
    padding: "0px",
  },
  searchInput: {
    maxWidth: 200,
    flexGrow: 1,
  },
  imageGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: theme.spacing(2),
    flexGrow: 1,
    overflowY: "auto",
    padding: theme.spacing(1),
  },
  img: {
    width: "100%",
    height: 175,
    objectFit: "cover",
    cursor: "pointer",
    borderRadius: 5,
    transition: "transform 0.15s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  skeleton: {
    width: "100%",
    height: 175,
    borderRadius: 5,
  },
}));

const PexelsImageDisplay = ({
  onSelectImage,
  defaultQuery = "House",
  default_images = [],
}) => {
  const classes = useStyles();
  const [pexelsImages, setPexelsImages] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchImages = async (query) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.pexels.com/v1/search?query=${query}`,
        {
          headers: {
            Authorization:
              "FZsKkYWaigTHV4wWiwLzXyUM9nX2ry4lU7mxCLIaNUI3nyFZ0xiNqZ0J",
          },
        },
      );
      const data = await response.json();
      setPexelsImages(data.photos || []);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    if (searchText) {
      fetchImages(searchText);
    }
  };

  useEffect(() => {
    fetchImages(defaultQuery);
  }, [defaultQuery]);

  const combinedImages =
    searchText === ""
      ? [...default_images.map((img) => ({src: img.picture})), ...pexelsImages]
      : pexelsImages;

  return (
    <>
      <Box className={classes.searchSection}>
        <SearchBar
          disableGutters={true}
          className={classes.searchInput}
          searchInput={searchText}
          handleSearchInput={setSearchText}
          placeholder="Search Images"
        />
        <PrimaryButton
          color={"secondary"}
          rounded={true}
          label={"Search"}
          onClick={handleSearch}
          disabled={loading}
        />
      </Box>

      {loading ? (
        <Box className={classes.imageGrid}>
          {Array.from(new Array(12)).map((_, index) => (
            <Skeleton variant="rect" className={classes.skeleton} key={index} />
          ))}
        </Box>
      ) : (
        <Box className={classes.imageGrid}>
          {combinedImages.map((img, index) => (
            <img
              key={img.id || index}
              src={img.src?.medium || img.src}
              alt="Image"
              className={classes.img}
              onClick={() => onSelectImage(img.src?.medium || img.src)}
            />
          ))}
        </Box>
      )}

      {!loading && combinedImages.length === 0 && (
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          style={{marginTop: 16}}
        >
          No images found
        </Typography>
      )}
    </>
  );
};

export default PexelsImageDisplay;
