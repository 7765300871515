import React from "react";
import {useLocation, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useFlags, withLDConsumer} from "launchdarkly-react-client-sdk";
// UI
import {Box, Divider, SvgIcon, Chip, Typography} from "@material-ui/core";
import {ReactComponent as DocIcon} from "assets/icons/Icon_Document.svg";
import {ReactComponent as DescriptionIcon} from "assets/icons/description.svg";
import useGuidebooksStyles from "styles/useGuidebooksStyles";
import "react-quill/dist/quill.snow.css";
// Custom
import Conditions from "components/Panels/Experience/ExperienceContent/Conditions/Conditions";
import LanguageSelector from "components/MultiOption/LanguageSelector";
import DraggableLayout from "components/Grids/DraggableLayout";
import {EmptyGuidebooks} from "components/Helpers/EmptyPanels";
import ConnectedToButton from "core/buttons/ConnectedToButton";
import CustomCardHeader from "core/cards/CustomCardHeader";
import FilledTextField from "core/inputs/FilledTextField";
import EmailEditor from "components/Misc/EmailEditor";
import DraggableItem from "core/listItems/DraggableItem";
import CreationButton from "components/MultiOption/CreationButton";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import CustomDialog from "core/dialogs/CustomDialog";
import ListingContentList from "../../Lists/ListingContentList";
import BackButton from "core/buttons/BackButton";
import HTMLField from "components/TextFields/HTMLField";
import TemplateCard from "components/Cards/TemplateCard";
import CloseIconButton from "core/buttons/CloseIconButton";
import VirtualizedList from "components/Lists/VirtualizedList";
import CreateFromExistingList from "components/Lists/CreateFromExistingList";
import PreviewBPPanel from "components/Panels/PreviewBPPanel";
import GJEInfoCard from "components/Cards/GJEInfoCard";
import GuidebookEditor from "./GuidebookEditor";
import EnsoAIPanel from "components/Panels/EnsoAIPanel";
// Actions
import {
  deleteListingResource,
  editHouse,
  handleUpdateGroup,
  saveListingResource,
  updateListingResource,
  updateListingResourceOrder,
  getListingResourcePresets,
} from "redux/actions/listingsActions";
import {createAction} from "redux/actions/experiencesActions";
// Utilities
import {
  getEmailContentWrapper,
  getProductBGImage,
  newUuid,
} from "utilities/helperFunctions";
import {
  contentTypeEnum,
  templateImages,
  guidebookTypeEnum,
} from "configuration/enums";
import {
  getConnectionLabel,
  multiLangArrayToObj,
  multiLangObjToArray,
} from "utilities/formatUtilities";
import {FIXED_SIZES} from "configuration/settings";
import clsx from "clsx";
import _ from "lodash";
import {guidebookTemplateConfig} from "configuration/guidebookTemplates";

const GuidebooksPanel = ({
  listing,
  outsideView,
  isParentLoading,
  onClose,
  disableEdit,
  goBack,
  updateGJ,
  disableReorder,
}) => {
  const dispatch = useDispatch();
  let flags = useFlags();
  const guidebookToMove = React.useRef(null);
  const prevGuidebooksOrder = React.useRef(null);
  const prevGuidebooks = React.useRef([]);
  const location = useLocation();
  const [guidebookTemplates, setGuidebookTemplates] = React.useState([]);
  const matchCardSection = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card",
  );
  const loadingContent = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_content;
  const loadingResource = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_resource_action;
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  //State
  const [openModal, setOpenModal] = React.useState(false);
  const [content, setContent] = React.useState({
    name: {en: ""},
    desc: {en: ""},
    components: flags.guidebooksV2
      ? [
          {
            id: newUuid(),
            type: "text",
            text: [{language: "en", value: ""}],
          },
        ]
      : [],
    is_template: true,
  });
  const [conditions, setConditions] = React.useState([]);
  const [guidebooks, setGuidebooks] = React.useState([]);
  const [selectedGuidebookIndex, setSelectedGuidebookIndex] =
    React.useState(null);
  const [guidebookTypeFilter, setGuidebookTypeFilter] = React.useState(
    flags.guidebooksV2 ? "top" : "all",
  );
  const [selectedGuidebook, setSelectedGuidebook] = React.useState(null);
  const [langs, setLangs] = React.useState(["en"]);
  const [selectedLang, setSelectedLang] = React.useState("en");
  const [guidebookToDelete, setGuidebookToDelete] = React.useState(null);
  const [listingsOpen, setListingsOpen] = React.useState(false);
  const [emptyListings, setEmptyListings] = React.useState(false);
  const [modalContentType, setModalContentType] = React.useState(null);
  const [expanded, setExpanded] = React.useState(null);
  const [isContentValid, setIsContentValid] = React.useState(true);
  const [headerImage, setHeaderImage] = React.useState(null);
  // General
  const classes = useGuidebooksStyles({dialogBGImage: headerImage});
  const gbSortAlph = (gb1, gb2) => {
    if (
      gb1.properties.main_page.name?.[0]?.value >
      gb2.properties.main_page.name?.[0]?.value
    ) {
      return 1;
    }
    return -1;
  };
  const guidebookKeys = React.useMemo(
    () => guidebooks.map((g, i) => g.resource_id ?? i),
    [guidebooks],
  );
  const listingContent = React.useMemo(
    () => listing?.listing_content || {},
    [listing],
  );
  const saveDisabled = React.useMemo(() => {
    return (
      !!loadingResource ||
      !content.name[selectedLang].trim() ||
      (selectedGuidebook?.connection_type === "ids" &&
        !selectedGuidebook?.connected_ids?.listing?.length) ||
      !!emptyListings ||
      !isContentValid
    );
  }, [
    content,
    selectedGuidebook,
    emptyListings,
    loadingResource,
    isContentValid,
  ]);
  const isGroupView = !!matchCardSection?.isExact;
  const {groupId, section, card} = React.useMemo(() => {
    if (!isGroupView) {
      return {groupId: listing?.group_id, section: null, card: null};
    }
    return {
      groupId: matchCardSection.params.group_id,
      section: matchCardSection.params.step,
      card: matchCardSection.params.card,
    };
  }, [location, listing]);
  const selectedGroup = listingGroups[groupId];
  const guestJourney = selectedGroup?.guest_journey ?? {};
  const groupData = guestJourney.sections?.[section]?.cards[card]?.data ?? [];
  const AIListingData =
    listing ?? houses[selectedGroup?.connected_to?.ids?.[0]];

  React.useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!document.querySelector("[src*='maps.googleapis.com']")) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
        //script.async = true;
        //script.defer = true;
        script.onload = () => {
          // Script loaded
        };
        document.body.appendChild(script);
      }
    };
    loadGoogleMapsScript();
  }, []);

  React.useEffect(() => {
    if (!!openModal) {
      return;
    }
    if (isGroupView) {
      setData(groupData.sort(gbSortAlph));
    } else {
      setData(listingContent.guidebooks || []);
    }
    if (flags.guidebooksV2) {
      setGuidebookTemplates(guidebookTemplateConfig.templates);
    } else {
      dispatch(
        getListingResourcePresets(
          !!isGroupView
            ? selectedGroup?.guest_journey?.locations
            : [listing?.address?.city],
          (d) => setGuidebookTemplates(d),
        ),
      );
    }
  }, [openModal, listing, listingGroups, location]);

  const setData = (newGuidebks) => {
    let newData = [...newGuidebks];
    setGuidebooks((prev) => (isGroupView ? newData.sort(gbSortAlph) : newData));
  };

  const getUpdatedListing = (guidebook, type) => {
    let newGuidebooks = [...listing?.listing_content.guidebooks];
    switch (type) {
      case "add":
        newGuidebooks.push({...guidebook, id: "new_guidebook"});
        break;
      case "reorder":
        newGuidebooks = guidebook;
        break;
    }

    return {
      ...listing,
      listing_content: {...listing?.listing_content, guidebooks: newGuidebooks},
    };
  };

  const handleNameChange = (e) => {
    const val = e.target.value;
    setContent((prev) => ({
      ...prev,
      name: {...prev.name, [selectedLang]: val},
    }));
  };

  const handleContentChange = (value) => {
    setContent((prev) => ({
      ...prev,
      desc: {...prev.desc, [selectedLang]: value},
    }));
  };

  const handleContentValidity = (value) => {
    setIsContentValid((prev) => value);
  };

  const resetGuidebookContent = (guidebook, isTemplate = false) => {
    setConditions((prev) => guidebook.properties?.conditions ?? []);
    let {newContent, newLangs} = multiLangArrayToObj([
      {
        key: "name",
        newKey: "name",
        content: guidebook.properties?.main_page ?? guidebook,
      },
      {
        key: isTemplate ? "description" : "text",
        newKey: "desc",
        content: guidebook.properties?.main_page ?? guidebook,
      },
    ]);
    newContent.components =
      guidebook.properties?.components?.length > 0
        ? guidebook.properties?.components
        : [
            {
              id: newUuid(),
              type: "text",
              text: guidebook.properties?.main_page?.text || [
                {language: "en", value: ""},
              ],
            },
          ];
    console.log("GOT COMPONENTS", guidebook, newContent.components);
    newContent.guidebook_type = guidebook.guidebook_type;
    newContent.template_spec = guidebook.template_spec;
    newContent.is_template = isTemplate || newContent.components.length === 0;
    setContent((prev) => newContent);
    setLangs((prev) => newLangs);
    setSelectedLang((prev) => newLangs[0]);
    setHeaderImage((prev) => guidebook.properties?.header_image ?? null);
  };

  const getFormattedContentForRequest = () => {
    return multiLangObjToArray(content, langs, [
      {key: "name", requestKey: "name"},
      {key: "desc", requestKey: "text", isHTML: true},
    ]);
  };

  const openGuidebook = (index) => {
    let gb = guidebooks[index];
    setSelectedGuidebookIndex((prev) => index);
    setSelectedGuidebook((prev) => gb);
    resetGuidebookContent(gb);
    setModalContentType((prev) => "existing");
    setOpenModal((prev) => true);
  };

  const selectExistingGuidebook = (g) => {
    resetGuidebookContent({...g, id: undefined, resource_id: undefined});
    setModalContentType((prev) => "scratch");
  };

  const selectTemplate = (t) => () => {
    resetGuidebookContent(
      {
        ...t,
        properties: {
          ...t.properties,
          components: (t.properties?.components || []).map((c) => ({
            ...c,
            id: newUuid(),
          })),
        },
      },
      true,
    );
    setModalContentType((prev) => "scratch");
    setOpenModal((prev) => true);
    setExpanded((prev) => null);
    setHeaderImage((prev) => t.header_image);
  };

  const closeModal = () => {
    setOpenModal((prev) => false);
    setModalContentType((prev) => null);
    setSelectedGuidebookIndex((prev) => null);
    setSelectedGuidebook((prev) => null);
    setListingsOpen((prev) => false);
    setContent((prev) => ({
      name: {en: ""},
      desc: {en: ""},
      components: flags.guidebooksV2
        ? [
            {
              id: newUuid(),
              type: "text",
              text: [{language: "en", value: ""}],
            },
          ]
        : [],
    }));
    setSelectedLang((prev) => "en");
    setLangs((prev) => ["en"]);
    setConditions((prev) => []);
    setHeaderImage((prev) => null);
  };

  const closeDeleteConfirmation = () => setGuidebookToDelete((prev) => null);

  const onSuccess = () => {
    closeDeleteConfirmation();
    if (!!isGroupView) {
      updateGJ(section, card);
    }
  };

  const onError = () => {
    closeDeleteConfirmation();
  };

  const getUpdatedContent = () => {
    let newGuidebook = null;
    if (selectedGuidebookIndex === null) {
      newGuidebook = {
        ...selectedGuidebook,
        resource: "gb",
        properties: {
          conditions: conditions,
          components: content.components,
          main_page: {
            ...getFormattedContentForRequest(),
            images: [],
          },
        },
      };
    } else {
      newGuidebook = {
        ...selectedGuidebook,
        properties: {
          guidebook_id: selectedGuidebook.properties.guidebook_id,
          conditions: conditions,
          components: content.components,
          main_page: {
            images: [],
            name: Object.keys(content.name).map((l) => ({
              language: l,
              value: content.name[l],
            })),
            text: Object.keys(content.desc).map((l) => ({
              language: l,
              value: getEmailContentWrapper(content.desc[l]),
            })),
          },
        },
      };
    }

    if (!!headerImage) {
      newGuidebook.properties.header_image = headerImage;
    }

    return newGuidebook;
  };

  const handleAddGuidebook = () => {
    const newGuidebook = getUpdatedContent();
    let newListing = null;
    if (isGroupView) {
      handleUpdateGroup({
        action: "add",
        group: selectedGroup,
        section,
        card,
        listingResource: newGuidebook,
        dispatch,
      });
    } else {
      newListing = getUpdatedListing(newGuidebook, "add");
      dispatch(editHouse(newListing));
    }

    prevGuidebooks.current = [...guidebooks];

    dispatch(
      saveListingResource({
        body: newGuidebook,
        type: "guidebook",
        disableListingUpdate: true,
        listing: newListing,
        onSuccess: (r) => {
          if (!!isGroupView) {
            setData([...guidebooks, r]);
          } else {
            const newGuidebooks = newListing.listing_content.guidebooks.map(
              (g) => (g.id === "new_guidebook" ? r : g),
            );
            const updatedListing = {
              ...newListing,
              listing_content: {
                ...newListing.listing_content,
                guidebooks: newGuidebooks,
              },
            };
            dispatch(editHouse(updatedListing));
            setData(newGuidebooks);
          }
          onSuccess();
        },
        onError: () => {
          setData(prevGuidebooks.current);
          onError();
        },
      }),
    );
    closeModal();
  };

  const handleUpdateGuidebook = () => {
    const newGuidebook = getUpdatedContent();
    prevGuidebooks.current = [...guidebooks];
    let newGuidebooks = [...guidebooks];
    if (isGroupView) {
      handleUpdateGroup({
        action: "update",
        group: selectedGroup,
        section,
        card,
        listingResource: newGuidebook,
        dispatch,
      });
    }
    let listingId = isGroupView
      ? selectedGroup?.connected_to?.ids[0]
      : listing?.id;
    if (
      newGuidebook.connection_type === "ids" &&
      !newGuidebook.connected_ids.listing?.includes(listingId)
    ) {
      newGuidebooks = newGuidebooks.filter(
        (g) => g.resource_id !== newGuidebook.resource_id,
      );
    } else {
      newGuidebooks[selectedGuidebookIndex] = newGuidebook;
    }
    setData(newGuidebooks);
    dispatch(
      updateListingResource({
        body: newGuidebook,
        listing: {
          ...listing,
          listing_content: {
            ...listing?.listing_content,
            guidebooks: newGuidebooks,
          },
        },
        onSuccess: () => onSuccess(),
        onError: () => {
          setData(prevGuidebooks.current);
          onError();
        },
      }),
    );
    closeModal();
  };

  const handleRemoveGuidebook = () => {
    const newGuidebooks = guidebooks.filter(
      (g) => g.resource_id !== guidebookToDelete,
    );
    if (isGroupView) {
      handleUpdateGroup({
        action: "delete",
        group: selectedGroup,
        section,
        card,
        listingResource: {resource_id: guidebookToDelete},
        dispatch,
      });
    }
    setData(newGuidebooks);
    closeDeleteConfirmation();
    dispatch(
      deleteListingResource({
        resource: "gb",
        type: "guidebook",
        resource_id: guidebookToDelete,
        listing: {
          ...listing,
          listing_content: {
            ...listing?.listing_content,
            guidebooks: newGuidebooks,
          },
        },
        onSuccess: () => onSuccess(),
        onError: () => onError(),
      }),
    );
  };

  const handleSaveGuidebook = () => {
    if (selectedGuidebookIndex === null) {
      handleAddGuidebook();
    } else {
      handleUpdateGuidebook();
    }
  };

  const handleGuidebooksReordering = (newGuidebooks) => {
    if (selectedGuidebookIndex !== null) {
      return;
    }
    const prevOrder = guidebooks.map((g, i) => ({id: g.resource_id, order: i}));
    const newOrder = newGuidebooks.map((g, i) => ({
      id: g.resource_id,
      order: i,
    }));
    prevGuidebooksOrder.current = guidebooks;
    if (!_.isEqual(prevOrder, newOrder)) {
      confirmReordering(newGuidebooks);
    }
  };

  const confirmReordering = (newGuidebooks) => {
    const gtm = guidebookToMove.current;
    let newListing = getUpdatedListing(newGuidebooks, "reorder");
    setData(newGuidebooks);
    dispatch(
      updateListingResourceOrder({
        body: {
          resource_type: gtm.resource,
          resource_id: gtm.resource_id,
          index: newGuidebooks.findIndex(
            (g) => g.resource_id === gtm.resource_id,
          ),
          listing_id: listing.listing_id,
        },
        newListing,
        onSuccess: () => {
          guidebookToMove.current = null;
          prevGuidebooksOrder.current = null;
          onSuccess();
        },
        onError: cancelReordering,
      }),
    );
  };

  const addLang = (lang) => {
    setContent((prev) => ({
      ...prev,
      name: {...prev.name, [lang]: ""},
      desc: {...prev.desc, [lang]: ""},
    }));
    setLangs((prev) => [...prev, lang]);
    setSelectedLang((prev) => lang);
  };

  const removeLang = (lang) => {
    const newLangs = langs.filter((l) => l !== lang);
    setSelectedLang((prev) => (prev === lang ? newLangs[0] : prev));
    setLangs((prev) => newLangs);
    const newContent = {...content};
    delete newContent.desc[lang];
    delete newContent.name[lang];
    setContent((prev) => newContent);
  };

  const cancelReordering = () => {
    setData(prevGuidebooksOrder.current);
    const prevListing = getUpdatedListing(
      prevGuidebooksOrder.current,
      "reorder",
    );
    dispatch(editHouse(prevListing));
    guidebookToMove.current = null;
    prevGuidebooksOrder.current = null;
  };

  const handleCreateAction = (actionType) => () => {
    setModalContentType((prev) => actionType);
    setOpenModal((prev) => true);
  };

  const handleHeaderImgChange = (picture) => {
    setHeaderImage((prev) => picture);
  };

  const handleGenerateFromTemplate = (prompt, places) => {
    const newFields = places.map((place) => {
      return {
        ...place,
        id: newUuid(),
        loading: true,
        type: "place",
        name: [{language: selectedLang, value: place.display_name}],
        text: [{language: "en", value: ""}],
      };
    });
    setContent({
      ...content,
      is_template: false,
      components: content?.components.concat(newFields),
    });
    Promise.all(
      newFields.map(
        (newField) =>
          new Promise((resolve, reject) => {
            dispatch(
              createAction({
                customErrorMessage:
                  "EnsoAI is experiencing high traffic. Please try again later",
                action: {
                  action_id: "generate_text",
                  prompt: newField,
                  content_type: "google_guidebook",
                },
                listingId: listing.listing_id,
                onSuccess: (r) => {
                  console.log("GOT GENERATE TEXT RESPONSE", r.action.response);
                  let updatedField = {
                    ...newField,
                    text: [{language: "en", value: r.action.response}],
                    loading: false,
                  };
                  resolve(updatedField); // Resolve the promise with the updated field
                },
                onError: (err) => {
                  console.error(
                    "Sorry, something went wrong, please try again.",
                  );
                  reject(err); // Reject the promise on error
                },
              }),
            );
          }),
      ),
    )
      .then((updatedFields) => {
        // Once all promises are resolved, update the content with all updated fields
        console.log("GOT UPDATED FIELDS", updatedFields);
        setContent({
          ...content,
          is_template: false,
          components: content?.components.concat(updatedFields),
        });
      })
      .catch((error) => {
        console.error("Error processing one or more requests:", error);
      });
  };

  function getNameField() {
    return (
      <FilledTextField
        fullWidth
        label="Guidebook name"
        value={content.name[selectedLang]}
        disabled={!!disableEdit}
        className="mb-3"
        placeholder="e.g: General information"
        onChange={handleNameChange}
        onValidityCheck={handleContentValidity}
      />
    );
  }

  function getEditor() {
    return (
      <EmailEditor
        outlined
        disabled={!!disableEdit}
        editorOnly
        useCustomFields
        lang={selectedLang}
        value={content.desc[selectedLang]}
        onChange={handleContentChange}
        onValidityCheck={handleContentValidity}
        placeholder="Guidebook content"
        imagePath={`listing-${listing?.listing_id ?? `new-${newUuid()}`}/guidebooks`}
        AIprops={{
          type: contentTypeEnum.guidebook,
          objects: {listing: AIListingData},
        }}
      />
    );
  }

  function getTemplateList(filterTemplates = false) {
    let tags = [];
    if (flags.guidebooksV2) {
      tags = guidebookTemplateConfig.tags;
    } else {
      tags = Object.keys(guidebookTypeEnum).map((k) => ({
        label: guidebookTypeEnum[k],
        key: k,
      }));
    }

    return (
      <div>
        {!flags.guidebooksV2 && (
          <Chip
            color={guidebookTypeFilter === "all" ? "primary" : "default"}
            label={"All types"}
            className={classes.chip}
            onClick={() => setGuidebookTypeFilter("all")}
          />
        )}
        {tags.map((gb_type) => (
          <Chip
            key={gb_type?.key}
            color={guidebookTypeFilter === gb_type.key ? "primary" : "default"}
            label={gb_type?.label}
            onClick={() => setGuidebookTypeFilter(gb_type.key)}
            className={classes.chip}
          />
        ))}
        <div className={classes.templateContainer}>
          {guidebookTemplates.map((templ, templ_index) => {
            let templName = templ.name
              .find((t_name) => t_name.language === "en")
              ?.value?.toLowerCase();
            let templExists = !!guidebooks.find(
              (gb) =>
                gb.properties.main_page.name
                  .find((gb_name) => gb_name.language === "en")
                  ?.value?.toLowerCase() === templName,
            );
            if (guidebookTypeFilter === "top" && templ.tags[0] !== "top") {
              return null;
            }
            if (
              (filterTemplates && templExists) ||
              (guidebookTypeFilter !== "all" &&
                guidebookTypeFilter !== "top" &&
                templ.guidebook_type !== guidebookTypeFilter)
            ) {
              return null;
            }
            return (
              <TemplateCard
                key={templ_index}
                expandable
                type="guidebooks"
                currency={listing?.currency}
                subheader={templ.header}
                expanded={expanded === templ.template_id}
                expandItem={() =>
                  setExpanded((prev) =>
                    prev === templ.template_id ? null : templ.template_id,
                  )
                }
                icon={
                  !!templ.icon && (
                    <img
                      src={templ.icon}
                      alt={`${templ.template_id} icon`}
                      className={classes.templateIcon}
                    />
                  )
                }
                template={templ}
                title={templ.name[0].value}
                onClick={selectTemplate(templ)}
                bgImage={templ.header_image}
              />
            );
          })}
        </div>
      </div>
    );
  }

  const getRowItem = ({index}) => {
    let g = guidebooks[index];
    return renderItem(g, index);
  };

  const renderItem = (g, ind) => {
    const hasDescription = !!g.properties.main_page.text?.[0]?.value;
    return (
      <DraggableItem
        edit={!disableEdit}
        disabled={loadingResource}
        key={g.properties.guidebook_id}
        title={
          <div className={classes.titleContainer}>
            <span className={classes.itemTitle}>
              {g.properties.main_page.name[0].value}
            </span>
            <span className={classes.connectedText}>
              • {getConnectionLabel(g, houses, listingGroups)}
            </span>
          </div>
        }
        icon={
          !g.properties.header_image && (
            <SvgIcon
              viewBox="0 0 17 22"
              component={DocIcon}
              className={classes.icon}
            />
          )
        }
        picture={g.properties.header_image}
        onItemClick={() => {
          if (!disableEdit && !disableReorder) {
            guidebookToMove.current = g;
          } else {
            openGuidebook(ind);
          }
        }}
        text={
          hasDescription ? (
            <HTMLField
              content={g.properties.main_page.text[0].value}
              hideMarkup
            />
          ) : null
        }
        onEdit={() => openGuidebook(ind)}
        onDelete={() => setGuidebookToDelete((prev) => g.resource_id)}
        disableReorder={disableReorder}
      />
    );
  };

  function getModalContent() {
    switch (modalContentType) {
      case "existing":
      case "scratch":
        if (flags.guidebooksV2) {
          return (
            <GuidebookEditor
              disableEdit={disableEdit}
              selectedGuidebook={selectedGuidebook}
              selectedGroup={selectedGroup}
              listingsOpen={listingsOpen}
              setListingsOpen={setListingsOpen}
              setEmptyListings={setEmptyListings}
              listing={listing}
              groupId={groupId}
              isGroupView={isGroupView}
              guidebooks={guidebooks}
              setContent={setContent}
              content={content}
              AIListingData={AIListingData}
              setSelectedGuidebook={setSelectedGuidebook}
              conditions={conditions}
              setConditions={setConditions}
              selectedLang={selectedLang}
              handleNameChange={handleNameChange}
              handleContentChange={handleContentChange}
              handleContentValidity={handleContentValidity}
            />
          );
        }
        return (
          <>
            <Box height={listingsOpen ? "100%" : "fit-content"}>
              <ConnectedToButton
                disabled={!!disableEdit}
                data={selectedGuidebook}
                showSectionTitle
                type={"guidebook"}
                listingsOpen={listingsOpen}
                setListingsOpen={setListingsOpen}
                setEmptyListings={setEmptyListings}
                listing={listing}
                listing_id={
                  isGroupView
                    ? selectedGroup?.connected_to?.ids?.[0]
                    : listing?.listing_id
                }
                group_id={groupId}
                isGroupView={isGroupView}
                editData={setSelectedGuidebook}
              />
            </Box>
            {!listingsOpen && (
              <>
                <Divider className={classes.divider} />
                <Conditions
                  standalone
                  title="Conditions"
                  disableEdit={disableEdit}
                  label="Define what conditions must be met to display this guidebook"
                  data={conditions}
                  setData={setConditions}
                />
                <Divider className={classes.divider} />
                <div className={classes.editorContainer}>
                  <div className={classes.sectionTitle}>
                    <SvgIcon
                      className="icon"
                      viewBox="0 0 16 17"
                      component={DescriptionIcon}
                    />
                    <Typography className="title" variant="h1">
                      {"Description"}
                    </Typography>
                  </div>
                  {getNameField()}
                  {getEditor()}
                </div>
              </>
            )}
          </>
        );
      case "existing_new":
        return (
          <Box height="100%">
            <CreateFromExistingList
              resource="guidebook"
              onSelect={selectExistingGuidebook}
            />
          </Box>
        );
      case "template":
        return getTemplateList();
      default:
        return null;
    }
  }

  function getPreviewContent() {
    if (!selectedGuidebook) {
      return;
    }
    return (
      <PreviewBPPanel
        type={"guides"}
        lang={selectedLang}
        content={getUpdatedContent()}
        listingId={
          isGroupView ? selectedGroup.connected_to.ids[0] : listing?.listing_id
        }
      />
    );
  }

  const languageSelector = (
    <LanguageSelector
      selectedLangs={langs}
      selectedLanguage={selectedLang}
      addLang={addLang}
      removeLang={removeLang}
      selectLang={setSelectedLang}
      preventOverflow
      light={!!headerImage}
    />
  );

  const guidebookModal = (
    <CustomDialog
      fullWidth
      disableExit
      disableEnforceFocus
      maxWidth="lg"
      customHeight={"calc(100% - 20px)"}
      previewContent={getPreviewContent()}
      headerImageEditable={["existing", "scratch"].includes(modalContentType)}
      headerLight={!!headerImage}
      headerImageProps={{
        resourceType: "gb",
        recommendedPictures: templateImages.gb,
        pictures: listing?.listing_content?.pictures || [],
        onPictureChange: handleHeaderImgChange,
      }}
      headerAction={
        !disableEdit &&
        !["existing_new", "template"].includes(modalContentType) &&
        languageSelector
      }
      headerClass={clsx({[classes.dialogHeader]: !!headerImage})}
      disableActions={
        !!disableEdit || ["existing_new", "template"].includes(modalContentType)
      }
      open={!!openModal}
      titleVariant="header"
      transitionDuration={0}
      title={content?.name?.[selectedLang] || "New Guidebook"}
      onClose={closeModal}
      labelCancel="Cancel"
      actionCancel={closeModal}
      labelConfirm="Save"
      disableConfirm={saveDisabled}
      actionConfirm={handleSaveGuidebook}
      content={getModalContent()}
      overlay={
        content?.guidebook_type === "place" &&
        content?.is_template && (
          <EnsoAIPanel
            title={"Build your guidebook"}
            onClose={() => setContent({...content, is_template: false})}
            content={content}
            listing={listing}
            onGenerate={(prompt, places) =>
              handleGenerateFromTemplate(prompt, places)
            }
          />
        )
      }
    />
  );

  const titleRow = (
    <CustomCardHeader
      title={
        !!goBack && !isGroupView ? (
          <BackButton
            header={!isMobileView}
            color={!!isMobileView ? "secondary" : "primary"}
            goBack={goBack}
          />
        ) : (
          "Guidebooks"
        )
      }
      className={classes.header}
      action={
        <>
          <Box mr={3}>
            <CreationButton
              type="guidebook"
              disabled={isParentLoading || loadingContent || loadingResource}
              onScratch={handleCreateAction("scratch")}
              onExisting={handleCreateAction("existing_new")}
              onTemplate={handleCreateAction("template")}
            />
          </Box>
          <CloseIconButton sm disablePadding onClick={onClose} />
        </>
      }
    />
  );

  const outsideTitleRow = (
    <ListingContentList
      listingId={listing?.listing_id}
      onlyItems={["guidebooks"]}
      disableBackground
    />
  );

  const confirmDeleteProductModal = guidebookToDelete !== null && (
    <ConfirmDialog
      open
      maxWidth="sm"
      disableDismiss
      onClose={closeDeleteConfirmation}
      title={`Delete article?`}
      message="This guidebook will be deleted for all the listings it is connected to."
      confirmLabel="Delete"
      confirmAction={handleRemoveGuidebook}
      cancelLabel="Cancel"
      cancelAction={closeDeleteConfirmation}
    />
  );

  return (
    <div className={classes.root}>
      {titleRow}
      {!!outsideView && outsideTitleRow}
      {guidebookModal}
      {confirmDeleteProductModal}
      <div className={classes.content}>
        {isGroupView && (
          <Box mx={-4} mt={-4}>
            <GJEInfoCard banner type="guidebooks" />
          </Box>
        )}
        {!guidebooks.length ? (
          isParentLoading || loadingContent ? (
            <EmptyGuidebooks loading />
          ) : (
            <></>
          )
        ) : (
          <>
            <Typography variant="h1" className={classes.subtitle}>
              {"Active guidebooks"}
            </Typography>
            {disableReorder ? (
              !openModal ? (
                <Box
                  flex={1}
                  minHeight={
                    !isGroupView
                      ? "auto"
                      : FIXED_SIZES.resource_item * guidebooks.length
                  }
                >
                  <VirtualizedList
                    hideScrollbar
                    getRowItem={getRowItem}
                    rowHeight={FIXED_SIZES.resource_item}
                    totalRows={guidebooks.length}
                  />
                </Box>
              ) : null
            ) : !openModal ? (
              <DraggableLayout
                autoSize
                fullWidth
                virtualized
                disableDrag={disableEdit || disableReorder}
                disablePadding
                items={guidebooks}
                sort={guidebookKeys}
                itemKey="resource_id"
                renderItem={renderItem}
                setNewOrder={handleGuidebooksReordering}
              />
            ) : null}
          </>
        )}
        {!isParentLoading && !loadingContent && (
          <>
            <Box mt={!!guidebooks.length ? 4 : 0}>
              <Typography variant="h1" className={classes.subtitle}>
                {"Recommended guidebooks"}
              </Typography>
            </Box>
            {getTemplateList(!!guidebooks.length)}
          </>
        )}
      </div>
    </div>
  );
};

export default withLDConsumer()(GuidebooksPanel);
