import {makeStyles} from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "row",
    maxWidth: "95vw",
    overflow: "hidden",
  },
}));

export default function PanelContainer({children}) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}
