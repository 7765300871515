import React from "react";
import {
  Backdrop,
  CardContent,
  CardHeader,
  CircularProgress,
  Fade,
  IconButton,
  makeStyles,
  Modal,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {HTMLEmail} from "components/Helpers/messageHelpers";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "100%",
    maxWidth: 630,
    margin: "0 30px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(6, 4),
  },
}));

export default function EmailPreviewModal({
  show,
  content,
  loading,
  subject = "",
  onClose,
}) {
  const classes = useStyles();

  return (
    <Modal
      open={show}
      onClose={onClose}
      className={classes.modal}
      aria-labelledby="modal-title"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{timeout: 500}}
    >
      <Fade in={show}>
        <div className={classes.paper}>
          {loading ? (
            <>
              <CardHeader
                title="Email Preview"
                titleTypographyProps={{
                  id: "modal-title",
                  variant: "h3",
                  color: "primary",
                }}
              />
              <div className={classes.loaderContainer}>
                <CircularProgress />
              </div>
            </>
          ) : (
            <>
              <CardHeader
                title="Email Preview"
                titleTypographyProps={{
                  id: "modal-title",
                  variant: "h3",
                  color: "primary",
                }}
                subheader={`Subject: ${subject}`}
                subheaderTypographyProps={{variant: "h1"}}
                action={
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                }
              />
              <CardContent>
                <HTMLEmail preview html={content} />
              </CardContent>
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
}
