import {makeStyles} from "@material-ui/core/styles";
import {THEME} from "configuration/settings";

const useEnsoAIButtonStyles = makeStyles((theme) => ({
  btn: {
    border: `1px solid ${THEME.AI.color}80`,
    padding: theme.spacing(1, 3),
    backgroundColor: THEME.AI.bg,
    minWidth: "fit-content",
    height: "fit-content",
    "&:hover": {
      backgroundColor: THEME.AI.hoverBG,
      border: `1px solid ${THEME.AI.color}`,
    },
    "&.zindex": {zIndex: theme.zIndex.modal + 1},
  },
  btnLabel: {
    gap: theme.spacing(1),
    color: THEME.AI.color,
    textAlign: "left",
  },
  AIIcon: {
    margin: 0,
    color: THEME.AI.color,
  },
  paper: {
    borderRadius: 10,
    padding: theme.spacing(3),
    width: 500,
    backgroundColor: THEME.AI.bg,
  },
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  colored: {color: THEME.AI.color},
  titleSection: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1),
  },
  input: {
    border: "1px solid #DEDEDE",
    borderRadius: 10,
    background: "#FFF",
    padding: theme.spacing(3),
    "& > input": {paddingRight: theme.spacing(3)},
  },
  inputError: {
    border: `1px solid ${THEME.error}`,
  },
  subheader: {color: THEME.AI.color + "CC"},
  promptButton: {
    padding: theme.spacing(1, 3),
    borderRadius: 20,
    width: "fit-content",
    backgroundColor: "#FFF",
    "&:hover": {backgroundColor: "#FFF"},
  },
  generateBtnContainer: {
    alignSelf: "end",
    marginBottom: 2,
  },
}));

export default useEnsoAIButtonStyles;
