import {useEffect, useRef} from "react";
import {
  guestListExportTypes,
  crmFileContentType,
} from "configuration/constants";
import {maxCompressionImageSize} from "configuration/constants.js";
import ImageCompressor from "js-image-compressor";
import pica from "pica";
import {asyncLoop} from "./helperFunctions";
import {saveAs} from "file-saver";

function getBlob(obj, type) {
  let str = obj;
  if (type === crmFileContentType.json) {
    str = JSON.stringify(obj);
  }
  const bytes = new TextEncoder().encode(str);
  const blob = new Blob([bytes], {type});
  return blob;
}

export function saveAsFile(
  obj,
  file_name,
  file_type = guestListExportTypes.json,
) {
  const fileContentType = crmFileContentType[file_type];
  const blob = getBlob(obj, fileContentType);
  saveAs(blob, file_name);
}

export const convertToPNG = async (file) => {
  const img = new Image();
  img.src = URL.createObjectURL(file);
  await img.decode();

  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;

  const picaInstance = pica();
  await picaInstance.resize(img, canvas);

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      const newFile = new File([blob], "converted.png", {type: "image/png"});
      resolve(newFile);
    }, "image/png");
  });
};

export const useImageCompressor = ({
  srcImgs = [],
  onSuccess = () => null,
  fileInObject = false,
  convertToPng = false,
}) => {
  const compressedImgs = useRef([]);

  useEffect(() => {
    if (!srcImgs.length) {
      return;
    }

    async function compressImages(images) {
      let compressionNeeded = false;
      const filesToCompress = await Promise.all(
        images.map(async (f, i) => {
          let fileData = fileInObject ? {...f} : f;
          let file = fileInObject ? f.file : f;

          if (convertToPng && file.type !== "image/png") {
            file = await convertToPNG(file);
            fileData = fileInObject ? {...fileData, file} : file;
          }

          return {
            fileData,
            compressData: {
              file,
              redressOrientation: false,
              quality: Number(
                (
                  maxCompressionImageSize /
                  (file.size || maxCompressionImageSize)
                ).toFixed(2),
              ),
              success: (result) =>
                compressSuccess(fileData, result, i === images.length - 1),
              error: (msg) => compressError(msg),
            },
          };
        }),
      );

      await asyncLoop(filesToCompress, async (cf) => {
        if (cf.compressData.quality >= 1) {
          compressedImgs.current.push(cf.fileData);
        } else {
          compressionNeeded = true;
          await new ImageCompressor(cf.compressData);
        }
      });
      if (!compressionNeeded) {
        onSuccess(compressedImgs.current);
      }
    }

    compressedImgs.current = [];
    compressImages(srcImgs);
  }, [srcImgs, convertToPng]);

  const compressSuccess = (f, result, isLastImg) => {
    ImageCompressor.file2DataUrl(result, (url) => {
      compressedImgs.current.push(
        fileInObject ? {...f, file: result, picture: url} : result,
      );
      if (isLastImg) {
        onSuccess(compressedImgs.current);
      }
    });
  };

  const compressError = (msg) => {
    console.log("Failed to compress: ", msg);
  };

  return compressedImgs.current;
};
