import React from "react";
import BaseFieldComponent from "../BaseFieldComponent";
import {withStyles} from "@material-ui/core/styles";
import {bookingChannelLabels} from "configuration/constants";
import GenericSelectionInput from "../../input/GenericSelectionInput";
import {
  Checkbox,
  ListItemAvatar,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import {ChannelIcon} from "../../../../components/Helpers/filterHelpers";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";

const styles = (theme) => ({
  iconContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  icon: {
    width: 18,
    height: 18,
    objectFit: "contain",
    margin: 4,
    marginLeft: 0,
  },
  listItemIcon: {
    minWidth: 0,
    padding: 0,
  },
});

const availableChannels = bookingChannelLabels;

class BookingChannelField extends BaseFieldComponent {
  renderInput({fieldData, handleValueChange, options, classes}) {
    console.log("BOOKINGCHANNELFIELD", fieldData, options, classes);
    return (
      <GenericSelectionInput
        options={options}
        enableSearch={true}
        edit={true}
        value={fieldData.val}
        multiselect={fieldData?.operator !== "is_not"}
        onValueChange={(newValue) => {
          handleValueChange(
            fieldData.type,
            Array.isArray(newValue)
              ? newValue.filter((f) => f !== "")
              : newValue,
          );
        }}
        label="Choose Channel"
        customMenuItem={(option, selectedOptions) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={selectedOptions.includes(option)} />
            <ListItemAvatar className={classes.listItemIcon}>
              <ChannelIcon channel={availableChannels[option] || option} />
            </ListItemAvatar>
            <ListItemText primary={availableChannels[option] || option} />
          </MenuItem>
        )}
      />
    );
  }

  renderDisplay({options, classes}) {
    return (
      <div className={classes.iconContainer}>
        {options.map((c) => (
          <ChannelIcon key={c} channel={availableChannels[c] || c} />
        ))}
      </div>
    );
  }

  renderText({value}) {
    return getDefaultFieldLabel(value);
  }
}

export default withStyles(styles)(BookingChannelField);
