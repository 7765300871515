import React, {useState, useEffect} from "react";
// UI
import {Row} from "reactstrap";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
// Custom
import CustomCardHeader from "core/cards/CustomCardHeader";
// Helpers & Utilities
import {setEndOfDay} from "utilities/helperFunctions";
import {THEME} from "configuration/settings.js";
import _ from "lodash";

const millisecondsInADay = 86400000;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(4),
  },
  title: {...THEME.customTypography.title1},
  subtitle: {...THEME.customTypography.title2},
  sub: {fontWeight: "400"},
  addButton: {
    marginRight: -15,
    outline: "none!important",
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    wordBreak: "break-word",
  },
  miniCard: {
    display: "flex",
    maxWidth: 205,
    margin: 5,
  },
  cardContent: {
    padding: theme.spacing(1, 4),
    paddingRight: `${theme.spacing(3)}px!important`,
  },
  cardDetails: {
    width: "100%",
    cursor: "pointer",
  },
  cardAction: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 30,
    paddingRight: 5,
    cursor: "default",
  },
  cardIcon: {
    padding: 3,
    height: "min-content",
    backgroundColor: "transparent!important",
  },
  minicardContainer: {
    padding: 0,
    paddingTop: theme.spacing(2),
  },
}));

export default function AvailabilityRyles({
  listing,
  setDialog,
  updateData,
  disableEdit,
}) {
  const classes = useStyles();
  const [stays, setStays] = useState({min: [], max: []});
  const [restrictions, setRestrictions] = useState([]);

  const removeArrElement = (field, values, ind, updateFn) => () => {
    const newValues = _.filter(values, (val, index) => index !== ind);
    updateFn(newValues);
    updateData({[field]: newValues});
  };

  const getRangeFromDays = (daysInMilliseconds) => {
    const beginOffset = new Date(daysInMilliseconds.begin).getTimezoneOffset();
    const endOffset = new Date(daysInMilliseconds.end).getTimezoneOffset();
    const beginTimestamp = daysInMilliseconds.begin + beginOffset * 60000;
    const endTimestamp = daysInMilliseconds.end + endOffset * 60000;
    return [new Date(beginTimestamp), setEndOfDay(new Date(endTimestamp))];
  };

  const reduceFn = (obj) => (acc, curr) => {
    const arr = _.isArray(acc) ? acc : !obj[acc] ? [acc.substring(0, 2)] : [];
    return !obj[curr] ? [...arr, curr.substring(0, 2)] : arr;
  };

  const getRestrictedDays = (values) => {
    const checkinKeys = Object.keys(values.check_in);
    const checkoutKeys = Object.keys(values.check_out);
    const checkin = checkinKeys.reduce(reduceFn(values.check_in));
    const checkout = checkoutKeys.reduce(reduceFn(values.check_out));
    return [checkin.join(", "), checkout.join(", ")];
  };

  const handleOpenDialog = (title, view, data) => () => {
    if (disableEdit) return false;
    if (!!data) {
      const daysInMilliseconds = {
        begin: data.begin_date * millisecondsInADay,
        end: data.end_date * millisecondsInADay,
      };
      const convertedDays = getRangeFromDays(daysInMilliseconds);
      data.begin_date = convertedDays[0];
      data.end_date = convertedDays[1];
    }
    setDialog(title, view, data);
  };

  useEffect(() => {
    const rangesContent = listing.listing_ranges;
    const newMinStays = rangesContent?.min_stays || [];
    const newMaxStays = rangesContent?.max_stays || [];
    const newRestrictions = rangesContent?.restrictions || [];
    setStays({min: newMinStays, max: newMaxStays});
    setRestrictions(newRestrictions);
  }, [listing]);

  const getMiniCards = (field, values, dialogArgs, updateFn) => {
    const isStay = field.toLowerCase().startsWith("length");
    const arr = isStay ? values.min : values;
    return (
      <Row className="pr-2 pl-2">
        {arr.map((val, ind) => {
          const restrictedDays = !isStay ? getRestrictedDays(val) : null;
          const dataArgs = isStay
            ? {
                begin_date: val.begin_date,
                end_date: val.end_date,
                min: values.min[ind]?.min_stay,
                max: values.max[ind]?.max_stay,
              }
            : {...val};
          return (
            <Card key={ind} className={classes.miniCard} title={val.name}>
              <div
                className={classes.cardDetails}
                onClick={handleOpenDialog(dialogArgs.title, dialogArgs.view, {
                  ...dataArgs,
                  index: ind,
                })}
              >
                <CardContent className={classes.cardContent}>
                  {isStay ? (
                    <>
                      {!!values.min[ind]?.min_stay && (
                        <Typography
                          className={classes.sub}
                          color="textSecondary"
                        >
                          <strong>min days:</strong> {values.min[ind].min_stay}
                        </Typography>
                      )}
                      {!!values.min[ind]?.max_stay && (
                        <Typography
                          className={classes.sub}
                          color="textSecondary"
                        >
                          <strong>max days:</strong> {values.max[ind].max_stay}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography className={classes.sub} color="textSecondary">
                        <strong>Restricted Check-in:</strong>
                      </Typography>
                      <Typography className={classes.sub} color="textSecondary">
                        {restrictedDays[0]}
                      </Typography>
                      <Typography className={classes.sub} color="textSecondary">
                        <strong>Restricted Checkout:</strong>
                      </Typography>
                      <Typography className={classes.sub} color="textSecondary">
                        {restrictedDays[1]}
                      </Typography>
                    </>
                  )}
                </CardContent>
              </div>
              <div className={classes.cardAction}>
                {!disableEdit && (
                  <Tooltip title="Remove">
                    <IconButton
                      className={classes.cardIcon}
                      onClick={removeArrElement(
                        field.toLowerCase(),
                        values,
                        ind,
                        updateFn,
                      )}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </Card>
          );
        })}
      </Row>
    );
  };

  const getCardContent = (field, dialogArgs, buttonLabel, values, updateFn) => {
    const isStay = field.toLowerCase().startsWith("length");
    const arr = isStay ? values.min : values;
    return (
      <div className="mb-5">
        <Typography className={classes.title}>{field}</Typography>
        {!!values && (
          <React.Fragment>
            <CardContent className={classes.minicardContainer}>
              {getMiniCards(field, values, dialogArgs, updateFn)}
            </CardContent>
            {!disableEdit && (
              <CardActions disableSpacing style={{paddingLeft: 0}}>
                {!!arr.length ? (
                  <Button
                    className={classes.addButton}
                    color="secondary"
                    startIcon={<AddIcon />}
                    onClick={handleOpenDialog(
                      dialogArgs.title,
                      dialogArgs.view,
                    )}
                  >
                    {buttonLabel}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disableElevation
                    onClick={handleOpenDialog(
                      dialogArgs.title,
                      dialogArgs.view,
                    )}
                  >
                    {buttonLabel}
                  </Button>
                )}
              </CardActions>
            )}
          </React.Fragment>
        )}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <CustomCardHeader title="Availability Details" type="title" />
      <div className={classes.content}>
        <Typography className={classes.subtitle}>Lead Time</Typography>
        {disableEdit ? (
          <Typography style={{marginBottom: 36}}>
            {listing.lead_time || 1}
          </Typography>
        ) : (
          <TextField
            value={listing.lead_time || "1"}
            style={{marginBottom: 36}}
          />
        )}
        {getCardContent(
          "Length-of-Stay Restrictions",
          {title: "Min/Max Stays", view: "stays"},
          "Add Restriction",
          stays,
          setStays,
        )}
        {getCardContent(
          "Day-of-Week Restrictions",
          {title: "Restrictions", view: "restrictions"},
          "Add Restriction",
          restrictions,
          setRestrictions,
        )}
      </div>
    </div>
  );
}
