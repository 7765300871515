import React from "react";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
    boxShadow:
      "0px 3px 3px rgba(155, 160, 176, 0.1), 0px 1px 0px rgba(21, 50, 111, 0.05)",
    borderRadius: 10,
    padding: theme.spacing(4),
    alignItems: "center",
    textTransform: "none",
  },
  icon: {
    color: "#000",
    width: 22,
    height: 22,
    alignItems: "center",
    "&.-disabled": {color: "rgba(0, 0, 0, 0.26)"},
  },
  label: {
    fontSize: 15,
    color: "#202223",
    "&.-disabled": {color: "rgba(0, 0, 0, 0.26)"},
  },
}));

export default function AddItemCard({fullWidth, label, disabled, onClick}) {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      disabled={disabled}
      fullWidth={fullWidth}
      startIcon={<AddIcon />}
      classes={{
        root: classes.root,
        startIcon: clsx(classes.icon, {"-disabled": disabled}),
        label: clsx(classes.label, {"-disabled": disabled}),
      }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
}
