import React from "react";
import {Redirect} from "react-router";
import {useSelector} from "react-redux";
// UI
import {
  Avatar,
  Box,
  Button,
  CardHeader,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
// Custom
import ServiceInfoDialog from "components/Dialogs/ServiceInfoContent";
import EmptyContentText from "components/Misc/EmptyContentText";
import CustomCardHeader from "core/cards/CustomCardHeader";
import DeviceStatus from "../TextFields/DeviceStatus";
import WarningAlert from "core/alerts/WarningAlert";
// Utilities
import {addonServices} from "configuration/enums";
import {THEME} from "configuration/settings";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
  },
  loaderContainer: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  emptyContainer: {padding: theme.spacing(4)},
  content: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    "& .seam-table-header": {display: "none !important"},
    "& .seam-table-row": {height: "fit-content !important"},
  },
  avatar: {
    width: 32,
    height: 32,
  },
  listItem: {padding: theme.spacing(2, 4)},
}));

function DeviceList({
  selectedDevice,
  initialDevices,
  onDeviceSelect,
  includeHeader,
  disabled,
  disableEdit,
  flat,
  multiselect = false,
  selectedList = [],
  useOnePanelStyle = false,
  updateSeamDevices = () => {},
}) {
  const classes = useStyles();
  const loading = useSelector((state) => state.defaultReducer.loading).devices;
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  const devicesDict = useSelector((state) => state.defaultReducer.devices_dict);
  const isAccountConnected = true;
  const [redirectPage, setRedirectPage] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [seamClient, setSeamClient] = React.useState(null);
  const [showUnpairedDevices, setShowUnpairedDevices] = React.useState(false);
  const [loadingUnpaired, setLoadingUnpaired] = React.useState(false);
  let selectedDevices = React.useMemo(
    () => initialDevices.map((sd) => devicesDict[sd.device_id] ?? sd),
    [initialDevices, devicesDict],
  );
  let deviceIds = selectedDevices
    .map((sd) => `"${sd.integration_device_id}"`)
    .join(",");
  let unpairedDevices = selectedDevices.filter((sd) => !sd.paired);
  let unpairedDevicesIds = unpairedDevices
    .map((sd) => `"${sd.integration_device_id}"`)
    .join(",");

  React.useEffect(() => {
    if (!seamClient && !loading) {
      window.customElements.whenDefined("seam-device-table").then(() => {
        const elements =
          window.document.getElementsByTagName("seam-device-table");
        const element = elements[0];
        if (element == null) {
          console.error("Cannot find seam-device-table in document");
        } else {
          let seam;
          element.onSessionUpdate = (client) => {
            seam = client;
            setSeamClient((prev) => client);
            if (seam == null) return;
            seam.devices.list().then((devices) => {
              updateSeamDevices(devices);
            });
          };
          element.onDeviceClick = (deviceId) => {
            if (seam == null) return;
            seam.devices.get({device_id: deviceId}).then((device) => {
              console.log("Device selected:", device);
              onDeviceSelect(device);
            });
          };
        }
      });
    }
  });

  React.useEffect(() => {
    let timer = null;
    if (!!loadingUnpaired) {
      timer = setTimeout(() => {
        setLoadingUnpaired((prev) => false);
      }, 500);
    }
    return () => clearTimeout(timer);
  }, [loadingUnpaired]);

  React.useEffect(() => {
    if (showUnpairedDevices && selectedDevices.every((sd) => !!sd.paired)) {
      setLoadingUnpaired((prev) => true);
      setSeamClient((prev) => null);
      setShowUnpairedDevices((prev) => false);
    }
  }, [selectedDevices]);

  const handleEdit = (device) => () => {
    if (onDeviceSelect) {
      onDeviceSelect(device);
    }
  };

  const handleEnable = () => {
    setRedirectPage("adddevices");
  };

  const validateDeviceService = () => {
    if (!user_profile?.connected_services?.includes("sl")) {
      setShowDialog((prev) => true);
    } else {
      setRedirectPage("adddevices");
    }
  };

  const fullListFlat = !!flat && (
    <List>
      {selectedDevices.map((td, i) => (
        <ListItem
          button
          key={i}
          disableRipple
          selected={td.device_id === selectedDevice?.device_id}
          className={classes.listItem}
          onClick={handleEdit(td)}
          disabled={disabled || selectedDevice.device_id === td.device_id}
        >
          {multiselect && (
            <Checkbox
              edge="start"
              size="small"
              disabled={selectedDevice.device_id === td.device_id}
              checked={selectedList.includes(td.device_id)}
              disableRipple
              className={classes.checkbox}
            />
          )}
          <div style={{width: "75%", display: "flex"}}>
            <ListItemAvatar>
              <Avatar
                className={classes.avatar}
                alt={td.name}
                src={td.picture}
              />
            </ListItemAvatar>
            <ListItemText>{td.name}</ListItemText>
          </div>
          {!multiselect && (
            <div style={{width: "25%"}}>
              {(!td.paired || !td.connected) && <DeviceStatus device={td} />}
            </div>
          )}
        </ListItem>
      ))}
    </List>
  );

  const deviceDialog = (
    <ServiceInfoDialog
      open={showDialog}
      service={addonServices.seam[0]}
      headerIcon={addonServices.seam[0].icon_url}
      dialogBGImage={addonServices.seam[0].cover_photo_url}
      handleEnable={handleEnable}
      onClose={() => setShowDialog((prev) => false)}
      dialogBGColors={THEME.seam ?? null}
    />
  );

  if (!!redirectPage) return <Redirect push to={`/admin/${redirectPage}`} />;

  if (!isAccountConnected) {
    return (
      <>
        <CardHeader
          title="Smart Home Devices"
          titleTypographyProps={{variant: "h1"}}
        />
        <div className={classes.emptyContainer}>
          <Typography className="mb-4">
            You don't have a connected account yet.
          </Typography>
          <Button
            color="primary"
            disabled={disableEdit}
            variant="contained"
            onClick={() => setRedirectPage("adddevices")}
          >
            Connect Account
          </Button>
        </div>
      </>
    );
  }

  if (loading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {deviceDialog}
      {includeHeader && (
        <CustomCardHeader
          title="Smart Home Devices"
          action={
            !disableEdit && (
              <div>
                <Button
                  id="add-smart-device"
                  size="small"
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={validateDeviceService}
                >
                  Add Device
                </Button>
              </div>
            )
          }
        />
      )}
      <div className={classes.content}>
        {!!flat ? (
          fullListFlat
        ) : !selectedDevices?.length ? (
          <Box p={4}>
            <EmptyContentText label="No connected devices" />
          </Box>
        ) : (
          <>
            {!!unpairedDevices.length && (
              <Box mb={3}>
                <WarningAlert
                  small
                  disableRadius
                  disableMargin
                  title={`You have ${unpairedDevices.length} devices left to pair`}
                  actionButtonLabel={
                    showUnpairedDevices
                      ? "See all devices"
                      : "See unpaired devices"
                  }
                  action={() => {
                    setLoadingUnpaired((prev) => true);
                    setShowUnpairedDevices((prev) => !prev);
                    setSeamClient((prev) => null);
                  }}
                />
              </Box>
            )}
            {loadingUnpaired && (
              <Box
                flex={1}
                width={"100%"}
                height={"100%"}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            )}
            {!loadingUnpaired && !!serviceAccounts.seam_client_session_id && (
              <seam-device-table
                disable-search="true"
                disable-css-injection="true"
                device-ids={
                  showUnpairedDevices || useOnePanelStyle
                    ? `[${showUnpairedDevices ? unpairedDevicesIds : deviceIds}]`
                    : undefined
                }
                client-session-token={serviceAccounts.seam_client_session_id}
                prevent-default-on-device-click="true"
              ></seam-device-table>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default DeviceList;
