import React from "react";
import {isMobile} from "react-device-detect";
import {darken, lighten, Link, makeStyles, Typography} from "@material-ui/core";
import {THEME} from "configuration/settings";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "underline",
    width: "fit-content",
    color: (props) => props.color,
    "&:hover": {color: (props) => darken(props.color, 0.2)},
  },
}));

export default function CustomLink({link, light, target = "_blank", label}) {
  const classes = useStyles({
    color: light ? lighten(THEME.secondary, 0.5) : THEME.secondary,
  });

  return isMobile ? (
    <Typography color="secondary">
      <a
        href="#"
        className={classes.link}
        onClick={() => {
          window.open(link, "_system");
        }}
      >
        {label}
      </a>
    </Typography>
  ) : (
    <Link
      variant="body1"
      target={target}
      href={link}
      className={classes.link}
      rel="noopener noreferrer"
    >
      {label}
    </Link>
  );
}
