import React from "react";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {THEME} from "configuration/settings.js";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid rgba(229,229,234,0.75)",
    backgroundColor: "rgba(229,229,234,0.15)",
    borderRadius: 10,
    height: (props) => (!!props.thin ? "fit-content" : 50),
    padding: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-between",
    "&.-disabled": {cursor: "not-allowed"},
  },
  button: {
    transition: "0.15s",
    backgroundColor: "transparent",
    flexGrow: 1,
    padding: (props) => (!!props.thin ? theme.spacing(2) : theme.spacing(2, 3)),
    "& .-button-icon": {transition: "0.15s"},
    "&:hover": {
      color: (props) => (!!props.thin ? "rgba(67, 83, 95, 0.75)" : THEME.grey),
      backgroundColor: (props) =>
        !!props.thin
          ? !!props.coloredBG
            ? "rgba(47, 122, 251, 0.05)"
            : "transparent"
          : !!props.coloredBG
            ? "rgba(47, 122, 251, 0.05)"
            : "rgba(72, 72, 72, 0.04)",
      "& .-button-icon": {
        color: (props) =>
          !!props.thin ? "rgba(67, 83, 95, 0.75)" : THEME.grey,
      },
    },
  },
  nonSelected: {
    color: (props) => (!!props.thin ? "#979797" : THEME.grey),
    "& .-button-icon": {
      color: (props) => (!!props.thin ? "rgba(67, 83, 95, 0.5)" : THEME.grey),
    },
  },
  selected: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: (props) =>
      !!props.coloredBG ? "rgba(47, 122, 251, 0.05)" : "none",
    "& .-button-icon": {color: `${theme.palette.secondary.main} !important`},
  },
  disabled: {
    backgroundColor: "transparent !important",
    opacity: 0.6,
  },
  label: {
    fontWeight: (props) => (!!props.thin ? 400 : 500),
    fontSize: (props) => (!!props.thin ? 14 : 15),
    lineHeight: (props) => (!!props.thin ? "20px" : "22px"),
    textTransform: "none",
  },
  left: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: (props) =>
      !!props.dividers ? "1px solid rgba(229, 229, 234, 0.75)" : "none",
  },
  center: {
    borderRadius: 0,
    borderRight: (props) =>
      !!props.dividers ? "1px solid rgba(229, 229, 234, 0.75)" : "none",
  },
  right: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
}));

export default function MultiButtonBar({
  thin,
  value,
  dividers,
  labels,
  options,
  icons,
  onChange,
  id,
  coloredBG,
  disabled = false,
}) {
  const classes = useStyles({thin, dividers, coloredBG});

  return (
    <div className={clsx(classes.root, {"-disabled": disabled})} id={id}>
      {options.map((opt, ind) => (
        <Button
          key={opt}
          id={`${opt}${id ?? ""}`}
          size="small"
          variant="contained"
          disableRipple
          disableElevation
          disabled={disabled}
          className={clsx(classes.button, {
            [classes.left]: ind === 0,
            [classes.right]: ind === options.length - 1,
            [classes.center]:
              options.length > 2 && ind > 0 && ind < options.length - 1,
            [classes.selected]: value === opt,
            [classes.nonSelected]: value !== opt,
            [classes.disabled]: disabled,
          })}
          style={{maxWidth: `${100 / options.length}%`}}
          classes={{label: classes.label}}
          startIcon={!!icons && icons[ind]}
          onClick={() => {
            if (opt !== value) {
              onChange(opt);
            }
          }}
        >
          {labels[ind]}
        </Button>
      ))}
    </div>
  );
}
