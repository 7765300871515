import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
// UI
import {Skeleton} from "@material-ui/lab";
import {Box, makeStyles, Typography} from "@material-ui/core";
import ButtonTag from "./ButtonTag";
import ProductCard from "./ProductCard";
import VirtualizedList from "components/Lists/VirtualizedList";
import ViatorProductDetails from "./ViatorProductDetails";
import {newUuid, sleep} from "utilities/helperFunctions";
import {formatProduct} from "utilities/keycardHelpers";

const defaultLang = "en";
const productCardHeight = 120;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "auto",
    padding: theme.spacing(0, 2.5),
    "&.details": {padding: 0},
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  list: {
    height: "100%",
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  carouselWrapper: {
    display: "flex",
    flexDirection: "row",
    height: 44,
    overflow: "auto",
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
    "&::-webkit-scrollbar": {
      width: "0px !important",
      height: "0px !important",
    },
  },
  backBtnRow: {backgroundColor: theme.palette.primary.contrast},
  stickyRow: {
    top: -1,
    position: "sticky",
    backgroundColor: theme.palette.primary.contrast,
    zIndex: 1,
  },
}));

const ViatorProductList = ({sectionRef, disableGutters, kc}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const searchProductsRequestId = useRef(null);
  const initialTagSelected = useRef(false);
  const [loadingFilteredList, setLoadingFilteredList] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedTagID, setSelectedTagID] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const components = kc?.components ?? {};
  const language = components.guest?.language ?? defaultLang;
  const products = components.viator?.products || [];
  const tags = components.viator?.tags || [];
  const productList = !!filteredProducts.length ? filteredProducts : products;
  const place =
    components.listing?.line3 ||
    components.listing?.line2 ||
    components.listing?.line1 ||
    "this place";

  React.useEffect(() => {
    if (!initialTagSelected.current && tags.length) {
      initialTagSelected.current = true;
      onFilterClick(tags[0]);
    }
  }, [tags]);

  const handleOpenProduct = (product) => {
    const formattedProduct = formatProduct(product, language);
    setSelectedProduct(formattedProduct);
  };

  const onFilterClick = async (tag) => {
    const requestId = newUuid();
    searchProductsRequestId.current = requestId;
    if (selectedTagID === tag[1]) {
      setSelectedTagID(null);
      setFilteredProducts([]);
      setLoadingFilteredList(false);
    } else {
      setSelectedTagID(tag[1]);
      setLoadingFilteredList(true);
      sleep(1000).then(() => setLoadingFilteredList(false));
    }
  };

  function getMultiLangText(array) {
    let lang = components.guest?.language || "en";
    return (
      array.find((d) => d.language === lang)?.value ??
      (array.find((d) => d.language === "en")?.value || "")
    );
  }

  const getItem = ({index}) => {
    const pr = productList[index];
    let duration = null;
    if (pr.metadata?.duration?.fixedDurationInMinutes != null) {
      duration = pr.metadata?.duration?.fixedDurationInMinutes;
    } else if (pr.metadata?.duration?.variableDurationFromMinutes != null) {
      duration = [
        pr.metadata?.duration?.variableDurationFromMinutes,
        pr.metadata?.duration?.variableDurationToMinutes,
      ];
    }

    return (
      <ProductCard
        key={pr.product_id}
        resource={pr}
        image={pr.header_image}
        title={getMultiLangText(pr.name)}
        lang={components.guest?.language || "en"}
        duration={duration}
        rating={pr.metadata?.reviews?.combinedAverageRating}
        reviews={pr.metadata?.reviews?.totalReviews}
        price={pr.metadata?.pricing?.summary?.fromPrice}
        currency={pr.metadata?.pricing?.currency}
        onClick={() => handleOpenProduct(pr)}
      />
    );
  };

  if (selectedProduct) {
    return (
      <div className={clsx(classes.container, "details")}>
        <ViatorProductDetails
          product={selectedProduct}
          onClose={() => setSelectedProduct(null)}
        />
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <Box mt={4} mb={2.5}>
        <Typography
          variant="body2"
          component={"div"}
          ref={sectionRef}
          style={{textTransform: "uppercase", opacity: 1}}
        >
          <Typography component={"span"} style={{opacity: 0.5}}>
            {t("things_to_do")}
          </Typography>
          {` ${place}`}
        </Typography>
      </Box>

      <Box className={classes.carouselWrapper}>
        {tags.map((tag, i) => (
          <ButtonTag
            key={tag[1]}
            text={tag[0]}
            selected={selectedTagID === tag[1]}
            onClick={() => onFilterClick(tag)}
          />
        ))}
      </Box>

      <Box flex={1}>
        {loadingFilteredList ? (
          [...Array(6).keys()].map((el) => (
            <Skeleton
              key={el}
              animation="wave"
              width={"100%"}
              height={108}
              style={{
                borderRadius: "15px",
                transform: "none",
                marginBottom: 20,
              }}
            />
          ))
        ) : (
          <Box className={classes.list}>
            <VirtualizedList
              hideScrollbar
              getRowItem={getItem}
              rowHeight={productCardHeight}
              totalRows={productList?.length}
            />
          </Box>
        )}
      </Box>
      <Box px={2.5} pb={2.5} mt={1}>
        <Typography fontSize={14}>* {t("reviews_from_viator")}</Typography>
      </Box>
    </div>
  );
};

export default ViatorProductList;
