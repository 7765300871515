import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
// UI
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import CustomTabs from "core/tabs/CustomTabs";
import BackButton from "core/buttons/BackButton";
import CloseIconButton from "core/buttons/CloseIconButton";
import CustomCardHeader from "core/cards/CustomCardHeader";
import ListingExperiencePanel from "./ListingExperiencePanel";
import ListingContentList from "../../Lists/ListingContentList";
import ListingOverviewPanel from "components/Panels/Listing/ListingOverviewPanel";
// Actions
import {getHouses} from "redux/actions/listingsActions";
// Utilities
import usePrevious from "hooks/usePrevious";
import {getServiceAccounts} from "redux/actions/servicesActions";

const tabs = ["Listing Information", "Automated Messages"];

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  cardHeader: {
    padding: theme.spacing(4),
    paddingTop: 23,
    borderBottom: `1px solid ${theme.palette.primary.main + "0D"}`,
  },
  listItem: {padding: theme.spacing(2, 4)},
}));

export default function ListingDetailsPanel({
  listingId,
  outsideView,
  calendarProps,
  tab,
  setTab,
  selectExperience,
  onClose,
  setView,
  onBkgSelect,
  closeCalendarDialog,
  onCalendarPropChange,
  panel3,
  selectedExperience,
  disableEdit = false,
  saveSelectedItems = () => {},
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const didLoad = React.useRef(true);
  // Selectors
  const listings = useSelector((state) => state.defaultReducer.house_data_dict);
  const startKeys = useSelector((state) => state.defaultReducer.start_keys);
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  // General
  const prevListingId = usePrevious(listingId);
  const selectedHouse = React.useMemo(() => {
    return listings[listingId];
  }, [listingId, listings]);

  React.useEffect(() => {
    dispatch(getServiceAccounts({}));
  }, []);

  React.useEffect(() => {
    if (prevListingId !== listingId) {
      didLoad.current = false;
    }
    if (
      startKeys.listings !== null &&
      !!listingId &&
      !listings[listingId] &&
      !didLoad.current
    ) {
      didLoad.current = true;
      dispatch(
        getHouses(`listing_id=${listingId}&enso_key=${current_user}`, true),
      );
    }
  }, [listingId, listings, startKeys]);

  const selectTab = (tab) => {
    saveSelectedItems(listingId, {isGroup: false, tab});
    setTab(tab);
    !outsideView && setView(null);
  };

  const onExperienceSelect = (experience) => {
    if (!outsideView) {
      history.replace(`/admin/listings/${listingId}`);
    }
    selectExperience(experience);
    setView("config_exp");
  };

  const onExistingExperienceSelect = () => {
    if (!outsideView) {
      history.replace(`/admin/listings/${listingId}`);
    }
    setView("copy_existing_exp");
  };

  const handleSelectListingSection = (sectionId) => {
    if (!outsideView) {
      history.replace(`/admin/listings/${listingId}/${sectionId}`);
    }
    setView(sectionId);
  };

  const closeOpenExperience = () => {
    if (panel3 === "config_exp") {
      setView(null);
    }
  };

  function getTabContent() {
    switch (tab) {
      case 0:
        return (
          <ListingContentList
            panel3={panel3}
            listingId={listingId}
            loading={!selectedHouse}
            onSelect={handleSelectListingSection}
          />
        );
      case 1:
        return (
          <ListingExperiencePanel
            listingId={listingId}
            disableEdit={disableEdit}
            selectedExperience={selectedExperience}
            loading={!selectedHouse}
            createFromExisting={onExistingExperienceSelect}
            closeOpenExperience={closeOpenExperience}
            onExperienceSelect={onExperienceSelect}
          />
        );
      default:
        break;
    }
  }

  return (
    <>
      {!!outsideView && (
        <CustomCardHeader
          title={
            <BackButton
              header={!isMobileView}
              color={!!isMobileView ? "secondary" : "primary"}
              goBack={onClose}
            />
          }
          type="title"
          className={classes.cardHeader}
        />
      )}
      <div className={classes.content}>
        {!outsideView && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            mb={-4}
            px={4}
            pt={4}
          >
            <CloseIconButton
              sm
              disablePadding
              onClick={() => {
                setView(null);
                history.push(`/admin/listings`);
              }}
            />
          </Box>
        )}
        <ListingOverviewPanel
          listingId={listingId}
          loading={!selectedHouse}
          disableEdit={disableEdit}
          onClose={onClose}
        />
        <CustomTabs
          disableBottomSpacing
          selectedTab={tab}
          onChange={selectTab}
          tabs={tabs}
        />
        {getTabContent()}
      </div>
    </>
  );
}
