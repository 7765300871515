import React from "react";
import {SvgIcon} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import {ReactComponent as MessagingIcon} from "assets/icons/Icon_Messages.svg";
import {ReactComponent as AccessIcon} from "assets/icons/Icon_Door.svg";
import {ReactComponent as SmarthomeIcon} from "assets/icons/Icon_Smarthome.svg";
import {ReactComponent as KeycardIcon} from "assets/icons/Icon_Keycard.svg";

export default function ExperienceTypeIcon({type, className}) {
  function getIcon() {
    switch (type) {
      case "msg":
        return (
          <SvgIcon
            viewBox="0 0 35 35"
            component={MessagingIcon}
            className={className}
          />
        );
      case "acc":
        return (
          <SvgIcon
            viewBox="0 0 14 16"
            component={AccessIcon}
            className={className}
          />
        );
      case "sns":
        return (
          <SvgIcon
            viewBox="0 0 11 17"
            component={SmarthomeIcon}
            className={className}
          />
        );
      case "key":
        return (
          <SvgIcon
            viewBox="0 0 30 20"
            component={KeycardIcon}
            className={className}
          />
        );
      case "custom":
        return <SettingsIcon className={className} />;
      default:
        return null;
    }
  }

  return getIcon();
}
