import React, {useRef} from "react";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import BPStepStatusTag from "../tag/BPStepStatusTag";
import InfoCardItem from "ui/base/item/InfoCardItem";
import MoreIconButton from "ui/base/button/MoreIconButton";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(3),
  },
  step: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(3),
    position: "relative",
  },
  connector: {
    borderLeft: "1px solid #F1F3F9",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 1,
    marginRight: theme.spacing(3),
    "&.-start": {
      position: "absolute",
      height: `calc(50% + ${theme.spacing(4)}px)`,
      bottom: 0,
      justifyContent: "flex-start",
    },
    "&.-end": {
      position: "absolute",
      height: `calc(50% + ${theme.spacing(4)}px)`,
      top: 0,
      justifyContent: "flex-end",
    },
  },
}));

const BoardingPassStepper = ({steps = [], getStepProps}) => {
  const classes = useStyles();
  const iconRefs = useRef([]);

  return (
    <Box className={classes.stepper}>
      {steps.map((vfAction, i) => {
        const stepProps = getStepProps({action: vfAction, index: i});
        let isFirst = i === 0;
        let isLast = i === steps.length - 1;
        return (
          <Box className={classes.step} key={stepProps.title}>
            <div
              className={clsx(classes.connector, {
                "-start": isFirst,
                "-end": isLast,
              })}
            >
              <Box pb={2} style={{backgroundColor: "white"}}>
                {stepProps.icon}
              </Box>
            </div>
            <Box
              pb={2}
              flex={1}
              style={{marginLeft: isFirst || isLast ? 24 : 0}}
            >
              <InfoCardItem
                title={stepProps.title}
                titleTag={<BPStepStatusTag status={stepProps.status} />}
                description={stepProps.description}
                outlined={stepProps.outlined}
                disabled={stepProps.disabled}
                actions={
                  !!stepProps.actions?.length ? (
                    <MoreIconButton
                      btnRef={(el) => (iconRefs.current[i] = el)}
                      onClick={(e) => stepProps.onClick(e.currentTarget)}
                    />
                  ) : undefined
                }
                onClick={() => stepProps.onClick(iconRefs.current[i])}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default BoardingPassStepper;
