import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import ProductIcon from "core/icons/ProductIcon";
import LocalServiceCard from "components/Cards/LocalServiceCard";
import HTMLField from "components/TextFields/HTMLField";
import TemplateCard from "components/Cards/TemplateCard";
import DraggableItem from "core/listItems/DraggableItem";
import DraggableLayout from "./DraggableLayout";
// Redux
import {
  editHouse,
  updateListingResourceOrder,
} from "redux/actions/listingsActions";
// Utils
import {
  productsFees,
  productsServices,
  productsUpsells,
} from "configuration/enums";
import {getConnectionLabel} from "utilities/formatUtilities";
import {getProductBGImage} from "utilities/helperFunctions";
import {getContentTemplates} from "redux/actions/experiencesActions";
import _ from "lodash";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    "&.templates": {gap: theme.spacing(2)},
  },
  subtitle: {marginBottom: theme.spacing(3)},
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingRight: theme.spacing(2),
  },
  itemTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "fit-content",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    maxWidth: "70%",
    WebkitLineClamp: 1,
  },
  connectedText: {
    fontSize: 12,
    fontWeight: 400,
    marginLeft: theme.spacing(1),
    color: theme.palette.text.disabled,
    flex: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    lineClamp: 1,
  },
}));

export default function ProductGrid({
  type,
  data = [],
  onAdd,
  onEdit,
  onDelete,
  hideSuggestions,
  disableEdit,
  currency = null,
  disableReorder = false,
  setData,
  listing = {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDataOrdered = React.useRef(false);
  const orderedServices = React.useRef([]);
  const prevUpsellsOrder = React.useRef(null);
  const upsellToMove = React.useRef(null);
  const defaultLang = useSelector((state) => state.defaultReducer.language);
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const availableExperiences = useSelector(
    (state) => state.experiencesReducer.available_experiences,
  );
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const loadingResource = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_resource_action;
  const [expanded, setExpanded] = React.useState(null);
  const {obj, availableKeys} = React.useMemo(() => {
    let newObj = null;
    switch (type) {
      case "fees":
        newObj = productsFees;
        return {obj: newObj, availableKeys: Object.keys(newObj)};
      case "upsells":
        newObj = productsUpsells;
        return {obj: newObj, availableKeys: Object.keys(newObj)};
      case "services":
        dispatch(
          getContentTemplates({
            template_type: "local_service",
            locations: [],
            onSuccess: (r) => {
              console.log("GOT RESPONSE", r);
              const objKeys = Object.keys(r);
              return {obj: r, availableKeys: objKeys};
            },
          }),
        );
      default:
        return {obj: {}, availableKeys: []};
    }
  }, [type, data]);

  const upsellKeys = React.useMemo(() => {
    if (type === "upsells") {
      return [];
    } else {
      return data.map((up, i) => up.resource_id ?? i);
    }
  }, [data]);

  const serviceData = React.useMemo(() => {
    if (type !== "services") {
      return null;
    }
    const dataDict = {};
    data.forEach((d, i) => {
      dataDict[d.properties.product_type] = {data: d, index: i};
    });

    if (!isDataOrdered.current && !!data.length) {
      isDataOrdered.current = true;
      const newOrder = data.map((d) => d.properties.product_type);
      newOrder.push(...availableKeys.filter((ak) => !newOrder.includes(ak)));
      orderedServices.current = newOrder;
      return {dataDict, services: newOrder};
    } else if (!orderedServices.current.length) {
      return {dataDict, services: availableKeys};
    } else {
      return {dataDict, services: orderedServices.current};
    }
  }, [type, data]);

  const handleCheck = ({add, product, index, isTemplate}) => {
    if (add) {
      onAdd(product, isTemplate);
    } else {
      onDelete(product, index);
    }
  };

  function getDraggableItem(p, ind, id, name, description) {
    return (
      <DraggableItem
        edit
        key={id}
        disabled={loadingResource}
        title={
          <div className={classes.titleContainer}>
            <span className={classes.itemTitle}>{name}</span>
            <span className={classes.connectedText}>
              • {getConnectionLabel(p, houses, listingGroups)}
            </span>
          </div>
        }
        text={<HTMLField content={description} />}
        disableReorder={type !== "upsells" || disableEdit || disableReorder}
        picture={type === "upsells" ? p.properties.header_image : undefined}
        onItemClick={() => {
          if (type === "upsells" && !disableEdit && !disableReorder) {
            upsellToMove.current = p;
          } else {
            onEdit(p, ind);
          }
        }}
        onEdit={() => onEdit(p, ind)}
        onDelete={() => handleCheck({add: false, product: p, index: ind})}
      />
    );
  }

  const renderItem = (up, ind) => {
    const id = up.properties.product_id ?? ind;
    const name = !!up.properties.name
      ? Array.isArray(up.properties.name)
        ? (up.properties.name.find((n) => n.language === defaultLang)?.value ??
          (up.properties.name[0]?.value || ""))
        : up.properties.name
      : "";
    const description = !!up.properties.description
      ? Array.isArray(up.properties.description)
        ? (up.properties.description?.find((n) => n.language === defaultLang)
            ?.value ??
          (up.properties.description?.[0]?.value || ""))
        : up.properties.description
      : "";
    return getDraggableItem(up, ind, id, name, description);
  };

  const cancelReordering = () => {
    setData(prevUpsellsOrder.current);
    const prevListing = {
      ...listing,
      listing_content: {
        ...listing?.listing_content,
        upsells: prevUpsellsOrder.current,
      },
    };
    dispatch(editHouse(prevListing));
    upsellToMove.current = null;
    prevUpsellsOrder.current = null;
  };

  const saveReordering = (newUpsells) => {
    const utm = upsellToMove.current;
    let newListing = {
      ...listing,
      listing_content: {...listing?.listing_content, upsells: newUpsells},
    };
    setData(newUpsells);
    dispatch(
      updateListingResourceOrder({
        body: {
          resource_type: utm.resource,
          resource_id: utm.resource_id,
          index: newUpsells.findIndex((u) => u.resource_id === utm.resource_id),
          listing_id: listing.listing_id,
        },
        newListing,
        onSuccess: () => {
          upsellToMove.current = null;
          prevUpsellsOrder.current = null;
        },
        onError: cancelReordering,
      }),
    );
  };

  const handleGuidebooksReordering = (newUpsells) => {
    const prevOrder = data.map((g, i) => ({id: g.resource_id, order: i}));
    const newOrder = newUpsells.map((g, i) => ({id: g.resource_id, order: i}));
    prevUpsellsOrder.current = data;
    if (!_.isEqual(prevOrder, newOrder)) {
      saveReordering(newUpsells);
    }
  };

  return type === "services" ? (
    <div className={clsx(classes.container, "templates")}>
      {serviceData.services.map((s) => {
        if (!!serviceData.dataDict[s]) {
          const product = serviceData.dataDict[s].data;
          const name = !!product.properties.name
            ? Array.isArray(product.properties.name)
              ? (product.properties.name.find((n) => n.language === defaultLang)
                  ?.value ??
                (product.properties.name[0]?.value || ""))
              : product.properties.name
            : "";
          return (
            <LocalServiceCard
              sm
              key={s}
              editable
              isEnabled
              width={120}
              name={name}
              upsellId={s}
              disabled={disableEdit}
              onEdit={() => onEdit(product, serviceData.dataDict[s].index)}
              onCheckedChange={() =>
                handleCheck({
                  add: false,
                  product,
                  index: serviceData.dataDict[s].index,
                })
              }
            />
          );
        } else {
          return (
            <LocalServiceCard
              sm
              key={s}
              width={120}
              upsellId={s}
              isEnabled={false}
              name={obj[s]?.name}
              disabled={disableEdit}
              onCheckedChange={(add) => handleCheck({add, product: obj[s]})}
            />
          );
        }
      })}
    </div>
  ) : (
    <>
      {!!data.length && (
        <Typography variant="h1" className={classes.subtitle}>
          {`Active ${type}`}
        </Typography>
      )}
      <div className={classes.container}>
        {type === "upsells" && !disableReorder ? (
          <Box style={{width: "100%"}}>
            <DraggableLayout
              autoSize
              fullWidth
              virtualized
              disableDrag={disableEdit || disableReorder}
              disablePadding
              items={data ?? []}
              sort={upsellKeys}
              itemKey="resource_id"
              renderItem={renderItem}
              setNewOrder={handleGuidebooksReordering}
            />
          </Box>
        ) : (
          data.map((p, i) => {
            const id = p.properties.product_id ?? i;
            const name = !!p.properties.name
              ? Array.isArray(p.properties.name)
                ? (p.properties.name.find((n) => n.language === defaultLang)
                    ?.value ??
                  (p.properties.name[0]?.value || ""))
                : p.properties.name
              : "";
            const description = !!p.properties.description
              ? Array.isArray(p.properties.description)
                ? (p.properties.description?.find(
                    (n) => n.language === defaultLang,
                  )?.value ??
                  (p.properties.description?.[0]?.value || ""))
                : p.properties.description
              : "";

            return getDraggableItem(p, i, id, name, description);
          })
        )}
      </div>
      {/*<Typography variant='h1' className={classes.subtitle} style={{marginTop: 15}}>
        {`Upsell Automations`}
      </Typography>
      <Box display='flex' flexDirection='column' style={{ gap: 5 }}>
        {availableExperiences["cnf"]?.map(exp => (
          <TemplateCard
            template={exp}
            expandable
            expanded={false}
            key={exp.title}
            type='experience'
            title={exp.title}
            urlIcon={exp.icon_url}
            subheader={exp.subtitle}
            bgImage={exp.picture_url}
            category={"cnf"}
            bgPosition={'center'}
            onClick={() => console.log("CLICKED")}
          />
        ))}
      </Box>*/}
      {!hideSuggestions && (
        <>
          <Box marginTop={4}>
            <Box mb={4}>
              <Typography variant="h1">{`Recommended ${type}`}</Typography>
            </Box>
            <div className={clsx(classes.container, "templates")}>
              {availableKeys.map((k) => {
                const templ = {
                  ...obj[k],
                  upsellId: type === "fees" ? undefined : k,
                };
                let templExists = !!data.find(
                  (pr) =>
                    pr.properties.name
                      .find((pr_name) => pr_name.language === "en")
                      ?.value?.toLowerCase() === templ?.name?.toLowerCase(),
                );
                if (!!data.length && !!templExists) {
                  return null;
                }
                return (
                  <TemplateCard
                    key={k}
                    type={type}
                    expandable
                    currency={currency}
                    subheader={templ.header}
                    expanded={expanded === k}
                    expandItem={() =>
                      setExpanded((prev) => (prev === k ? null : k))
                    }
                    icon={<ProductIcon type={type} product={k} />}
                    template={templ}
                    title={templ.name}
                    bgImage={getProductBGImage(type, k)}
                    onClick={() =>
                      handleCheck({add: true, product: templ, isTemplate: true})
                    }
                  />
                );
              })}
            </div>
          </Box>
        </>
      )}
    </>
  );
}
