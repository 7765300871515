import React from "react";
import {makeStyles} from "@material-ui/core";
import {MuuriComponent} from "muuri-react";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: (props) => (props.padding ? theme.spacing(4) : 0),
    "& .muuri-item-dragging, .muuri-item-releasing": {zIndex: 3},
    "& .muuri-item-positioning": {zIndex: 2},
    "& .muuri-item-dragging": {cursor: "move"},
  },
  item: {
    width: (props) =>
      !!props.fullWidth ? "100%" : !props.autoSize ? 210 : "fit-content",
    height: (props) => (!props.autoSize ? 180 : "fit-content"),
    padding: (props) => (props.padding ? theme.spacing(1) : "1px 0"),
  },
}));

export default function DraggableLayout({
  items,
  disablePadding,
  renderItem,
  setNewOrder,
  disableDrag,
  itemKey = "picture",
  autoSize,
  fullWidth,
  sort,
}) {
  const styleProps = {padding: !disablePadding, fullWidth, autoSize};
  const classes = useStyles(styleProps);

  const storeNewOrder = (keys) => {
    if (
      !_.isEqual(
        items.map((i) => i[itemKey]),
        keys,
      )
    ) {
      const newItems = keys.map((k) => items.find((i) => i[itemKey] === k));
      setNewOrder && setNewOrder(newItems);
    }
  };

  const handleDragEnd = (item) => {
    const grid = item.getGrid();
    const newItems = grid.getItems();
    const keys = newItems.map((i) => i.getKey());
    storeNewOrder(keys);
  };

  const children = items.map((item, index) => (
    <div
      key={itemKey === "none" ? item : (item[itemKey] ?? index)}
      className={classes.item}
    >
      {renderItem(item, index)}
    </div>
  ));

  return (
    <div className={classes.root}>
      <MuuriComponent
        dragEnabled={!disableDrag}
        dragSortHeuristics={{sortInterval: 10}}
        dragHandle
        onDragEnd={handleDragEnd}
        sort={sort}
        forceSync
      >
        {children}
      </MuuriComponent>
    </div>
  );
}
