import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  lighten,
  makeStyles,
} from "@material-ui/core";
import KeycardHTMLField from "./KeycardHTMLField";
import {useTheme} from "@material-ui/core";

const defaultBGImg =
  "https://enso-image-assets.s3.amazonaws.com/article_placeholder_image.webp";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 250,
    height: "100%",
    borderRadius: "10px !important",
    boxShadow: "none !important",
    marginLeft: (props) => (!!props.firstItem ? 0 : 12),
    marginRight: 0,
    backgroundColor:
      lighten(theme.palette.primary.contrast, 0.15) + "!important",
  },
  cardContent: {
    padding: "20px !important",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  cardActionArea: {
    height: "100%",
    display: "flex !important",
    flexDirection: "column",
  },
  cardMedia: {
    width: "100%",
    height: 255,
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    borderRadius: "10px 10px 0 0",
  },
  description: {
    fontSize: "16px !important",
    fontWeight: "400 !important",
    lineHeight: "24px !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
    width: "100%",
    lineClamp: 3,
  },
  name: {
    fontSize: "22px !important",
    fontWeight: "500 !important",
    lineHeight: "28px !important",
    marginBottom: "4px !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
    width: "100%",
    lineClamp: 2,
  },
}));

const VerticalCard = ({resource, firstItem, lastItem, onClick}) => {
  const classes = useStyles({firstItem, lastItem});
  const theme = useTheme();

  return (
    <Card className={classes.card}>
      <CardActionArea
        disableRipple
        className={classes.cardActionArea}
        onClick={() => onClick(resource)}
      >
        <div
          className={classes.cardMedia}
          style={{
            background: `linear-gradient(180deg, rgba(255,255,255,0) 30%, ${lighten(theme.palette.primary.contrast, 0.15)} 100%), url("${resource?.header_image ?? defaultBGImg}") no-repeat center center / cover`,
          }}
        />
        <CardContent className={classes.cardContent}>
          <Typography className={classes.name}>
            {resource?.name || ""}
          </Typography>
          <KeycardHTMLField
            content={resource?.description ?? ""}
            className={classes.description}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default VerticalCard;
