import React from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loadingDialog: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
  },
  progressContainer: {
    width: "100%",
    margin: theme.spacing(4, 0, 2),
    textAlign: "center",
  },
  progressRoot: {
    height: 12,
    width: "100%",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    borderRadius: 20,
    backgroundColor: theme.palette.common.white,
  },
  progressBar: {
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function FullLoader({
  open,
  onClose = () => null,
  loadingText,
  subtitle,
  progress = false,
  disableDismiss,
  maxWidth = null,
}) {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (disableDismiss && reason === "backdropClick") {
      return;
    }
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      maxWidth={!!maxWidth ? maxWidth : "xs"}
      disableEscapeKeyDown={disableDismiss}
      open={open}
      aria-labelledby="loader-dialog"
    >
      <DialogContent className={classes.loadingDialog}>
        {!!loadingText && (
          <Typography variant="h1" color="primary">
            {loadingText}
          </Typography>
        )}
        {!!subtitle && (
          <Typography color="textSecondary">{subtitle}</Typography>
        )}
        <div className={classes.progressContainer}>
          {!!progress ? (
            <LinearProgress
              classes={{
                root: classes.progressRoot,
                bar: classes.progressBar,
              }}
              variant="determinate"
              value={Math.ceil((progress.loaded / progress.total) * 100)}
            />
          ) : (
            <CircularProgress />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
