import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useFlags, withLDConsumer} from "launchdarkly-react-client-sdk";
// UI
import {ListItem, Typography, CardContent, Card} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
//Custom
import PrimaryButton from "core/buttons/PrimaryButton.jsx";
import VirtualizedList from "components/Lists/VirtualizedList";
// Actions
import {signUpUrl} from "redux/actions/accountsActions";
import {openGeneralError} from "redux/actions/settingsActions";
// Utilities
import {channelIntegrationTypeMap} from "configuration/enums";
import {relativeTime} from "utilities/helperFunctions.js";
import {FIXED_SIZES} from "configuration/settings";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 50,
    padding: 10,
  },
  item: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  header: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    height: 500,
  },
  root: {
    padding: theme.spacing(4),
  },
}));

const ChannelAccountsList = ({
  channels,
  initialOpen,
  integrationType,
  selectedChannel,
  setSelectedChannel,
  setLoading,
}) => {
  const classes = useStyles();
  const flags = useFlags();
  const dispatch = useDispatch();
  // Selectors
  const loading = useSelector(
    (state) => state.defaultReducer.loading,
  ).connected_channel_accounts;
  const [showAccounts, setShowAccounts] = useState(initialOpen);

  function handleConnectAirbnbAccount() {
    setLoading(true);
    dispatch(
      signUpUrl(
        {integration_type: "abnb"},
        (response) => {
          setLoading(false);
          window.open(response.redirect_url);
        },
        () => {
          setLoading(false);
          dispatch(openGeneralError("Failed to get Airbnb Redirect Url"));
        },
      ),
    );
  }

  function getChannelItem({index}) {
    const channel = channels[index];

    return (
      <ListItem
        disableRipple
        divider
        dense
        className={classes.item}
        button
        ContainerComponent="div"
        selected={selectedChannel.id === channel.id}
        onClick={() => setSelectedChannel(channel)}
      >
        <div style={{width: "100%"}}>
          <div>
            <Typography variant="h1" style={{color: "#000"}}>
              {channel.integration_host_id}
            </Typography>
          </div>
          <div style={{display: "flex", width: "100%"}}>
            <div style={{flexGrow: 1}}>
              <Typography
                style={{color: "#707070"}}
              >{`${channel.listings.length} Listings Connected`}</Typography>
            </div>
            {channel.sync_data.length > 0 && (
              <Typography style={{color: "#707070", opacity: 0.5}}>
                Updated {relativeTime(channel.sync_data[0].start)} ago
              </Typography>
            )}
          </div>
        </div>
      </ListItem>
    );
  }

  const channelAccountsList = React.useMemo(() => {
    if (!channels.length) {
      return null;
    }
    return (
      <VirtualizedList
        hideScrollbar
        getRowItem={getChannelItem}
        rowHeight={FIXED_SIZES.connected_channel_accounts}
        totalRows={channels.length}
      />
    );
  }, [channels, loading, selectedChannel]);

  const multipleChannels = channels.length > 1;

  return (
    <Card style={{marginTop: 10}}>
      <CardContent>
        <Typography variant="h2">
          <img
            src={channelIntegrationTypeMap[channels[0].integration_type].icon}
            className={classes.icon}
          />
        </Typography>
        <Typography>
          Your {channelIntegrationTypeMap[channels[0].integration_type].title}{" "}
          account is connected and is receiving listings, bookings and messages
        </Typography>
        <div style={{display: "flex", marginTop: 5}}>
          <div style={{flex: 1}} />
          {integrationType === "abnb" && (
            <PrimaryButton
              size="small"
              variant="text"
              color="primary"
              onClick={handleConnectAirbnbAccount}
              label={"Connect Account"}
            />
          )}
          <PrimaryButton
            size="small"
            variant="text"
            color="primary"
            onClick={() => {
              if (multipleChannels) {
                setShowAccounts(!showAccounts);
              } else {
                setSelectedChannel(channels[0]);
              }
            }}
            label={`${showAccounts ? "Hide" : "View"} ${multipleChannels ? `${channels.length} Accounts` : "Account"}`}
          />
        </div>
        {showAccounts && (
          <div className={classes.content}>{channelAccountsList}</div>
        )}
      </CardContent>
    </Card>
  );
};

export default withLDConsumer()(ChannelAccountsList);
