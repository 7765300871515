import {makeStyles} from "@material-ui/core";

const useBookingHeaderPanelStyles = makeStyles((theme) => ({
  container: {
    background: (props) =>
      `linear-gradient(114deg, #000 19.18%, rgba(0, 0, 0, 0) 86.48%), url(${props.picture})`,
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    borderRadius: "10px 10px 0px 0px",
    padding: theme.spacing(3),
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  buttons: {
    flexWrap: "wrap",
    maxWidth: 170,
    minWidth: "fit-content",
    justifyContent: "flex-end",
  },
  alignCenter: {alignItems: "center"},
  gap8: {gap: theme.spacing(2)},
  f1: {flex: 1},
  title: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    lineClamp: 1,
  },
  group: {color: "rgba(250,250,250,0.5)"},
  white: {color: "#FFF"},
  arrowIcon: {
    fontSize: 16,
    color: "#FFF",
    margin: theme.spacing(0, 2),
  },
}));

export default useBookingHeaderPanelStyles;
