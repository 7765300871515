import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {Box, Button, Tooltip, SvgIcon, Typography} from "@material-ui/core";
import {ReactComponent as AIIcon} from "assets/icons/ai.svg";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import AIBGImage from "assets/img/AI-background.png";
import {addonServices} from "configuration/enums";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useEnsoAIButtonStyles = makeStyles((theme) => ({
  btn: {
    border: `1px solid ${THEME.AI.color}80`,
    borderRadius: 15,

    padding: 3,
    paddingRight: 5,
    paddingLeft: 5,
    backgroundColor: THEME.AI.bg,
    maxWidth: "fit-content",
    height: "fit-content",
    "&:hover": {
      backgroundColor: THEME.AI.hoverBG,
      border: `1px solid ${THEME.AI.color}`,
    },
    "&.zindex": {zIndex: theme.zIndex.modal + 1},
  },
  btnLabel: {
    gap: theme.spacing(1),
    color: THEME.AI.color,
    textAlign: "left",
  },
  AIIcon: {
    margin: 0,
    fontSize: 16,
    size: 16,
    color: THEME.AI.color,
  },
}));

export default function EnsoAIButton({text = ""}) {
  const classes = useEnsoAIButtonStyles();

  return (
    <>
      <Tooltip arrow title={text}>
        <span className={clsx(classes.btn, classes.btnLabel)}>
          {" "}
          <SvgIcon
            className={classes.AIIcon}
            component={AIIcon}
            viewBox="0 0 20 20"
          />{" "}
          EnsoAI
        </span>
      </Tooltip>
    </>
  );
}
