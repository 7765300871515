import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Skeleton} from "@material-ui/lab";
import {Divider} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #F2F2F2",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
  },
  toolbar: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: "1px solid #F2F2F2",
  },
  divider: {
    margin: theme.spacing(0, 1),
    height: 24,
    backgroundColor: "#E0E0E0",
  },
  skeletonButton: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(2),
  },
  contentSkeleton: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: 4,
  },
  skeletonItem: {
    marginBottom: theme.spacing(1),
  },
}));

const QuillSkeletonMimic = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <Skeleton
          variant="rectangular"
          width={60}
          className={classes.skeletonButton}
        />
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Skeleton variant="circle" className={classes.skeletonButton} />
        <Skeleton variant="circle" className={classes.skeletonButton} />
        <Skeleton variant="circle" className={classes.skeletonButton} />
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Skeleton variant="circle" className={classes.skeletonButton} />
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Skeleton variant="circle" className={classes.skeletonButton} />
        <Skeleton variant="circle" className={classes.skeletonButton} />
        <Skeleton variant="circle" className={classes.skeletonButton} />
      </div>
      <div className={classes.contentSkeleton}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={24}
          classes={classes.skeletonItem}
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={24}
          classes={classes.skeletonItem}
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={24}
          classes={classes.skeletonItem}
        />
      </div>
    </div>
  );
};

export default QuillSkeletonMimic;
