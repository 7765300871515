import React from "react";
import {SvgIcon} from "@material-ui/core";
import {ReactComponent as ImageIcon} from "assets/icons/image.svg";
import {ReactComponent as TextIcon} from "assets/icons/text.svg";
import {ReactComponent as MailIcon} from "assets/icons/mail.svg";
import {ReactComponent as PhoneIcon} from "assets/icons/phone.svg";
import {ReactComponent as AddressIcon} from "assets/icons/map-pin.svg";
import {ReactComponent as DateIcon} from "assets/icons/calendar.svg";
import {ReactComponent as TimeIcon} from "assets/icons/clock.svg";
import {ReactComponent as SingleListIcon} from "assets/icons/single-list.svg";
import {ReactComponent as MultiListIcon} from "assets/icons/list.svg";
import {ReactComponent as NumberIcon} from "assets/icons/numeric.svg";
import {THEME} from "configuration/settings";

export default function FieldTypeIcon({type, lg}) {
  function getFieldTypeIcon() {
    let icon = null;
    let viewBox = "";
    switch (type) {
      case "guest_email":
      case "email":
        icon = MailIcon;
        viewBox = "0 0 24 24";
        break;
      case "guest_phone":
      case "phone":
        icon = PhoneIcon;
        viewBox = "0 0 20 20";
        break;
      case "guest_address":
      case "address":
        icon = AddressIcon;
        viewBox = "0 0 20 20";
        break;
      case "guest_dob":
      case "date":
        icon = DateIcon;
        viewBox = "0 0 16 16";
        break;
      case "checkin_time":
      case "checkout_time":
      case "checkin_time":
      case "time":
        icon = TimeIcon;
        viewBox = "0 0 20 20";
        break;
      case "guest_language":
      case "single_select":
        icon = SingleListIcon;
        viewBox = "0 0 16 16";
        break;
      case "multi_select":
        icon = MultiListIcon;
        viewBox = "0 0 16 16";
        break;
      case "image":
        icon = ImageIcon;
        viewBox = "0 0 16 16";
        break;
      case "num_pets":
      case "num_adult":
      case "num_child":
      case "number":
        icon = NumberIcon;
        viewBox = "0 0 20 20";
        break;
      case "text":
      default:
        icon = TextIcon;
        viewBox = "0 0 20 20";
        break;
    }
    if (!!icon) {
      return (
        <SvgIcon
          style={{fontSize: lg ? 16 : 14}}
          htmlColor={THEME.text}
          component={icon}
          viewBox={viewBox}
        />
      );
    } else {
      return null;
    }
  }

  return getFieldTypeIcon();
}
