import React from "react";
import {useSelector} from "react-redux";
// UI
import {
  Avatar,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import FilledSelect from "core/selects/FilledSelect";
// Utilities
import {THEME} from "configuration/settings";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  select: {
    "& > div": {
      display: "flex",
      alignItems: "center",
    },
  },
  text: {
    fontWeight: 400,
    color: THEME.grey,
  },
  listItemIcon: {
    minWidth: "fit-content",
    width: "fit-content",
    paddingRight: theme.spacing(3),
  },
  avatar: {
    width: 28,
    height: 28,
  },
}));

export default function Assignee({assignee, edit, value, onValueChange}) {
  const classes = useStyles();
  const didMount = React.useRef(false);
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const [selectedUser, setSelectedUser] = React.useState("");
  const users = user_profile.enso_users || [];
  const user = assignee ?? value;
  const name = !!user
    ? users?.find((u) => u.enso_user_id === user)?.name || "Unknown"
    : "Unassigned";

  React.useEffect(() => {
    if (edit && value !== selectedUser) {
      setSelectedUser((prev) => value);
    }
  }, [value]);

  React.useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    if (edit && selectedUser !== value) {
      onValueChange(selectedUser);
    }
  }, [selectedUser]);

  const handleAssigneeChange = (e) => {
    const val = e.target.value;
    setSelectedUser((prev) => val);
  };

  if (edit) {
    return (
      <div className={classes.root}>
        <FilledSelect
          fullWidth
          label="Assignee"
          value={selectedUser}
          className={classes.select}
          onChange={handleAssigneeChange}
        >
          <MenuItem value="" disabled>
            Select a user
          </MenuItem>
          {users.map((u) => {
            const img = u.profile_picture ?? u.picture;
            return (
              <MenuItem value={u.enso_user_id} key={u.enso_user_id}>
                <ListItemAvatar className={classes.listItemIcon}>
                  <Avatar
                    alt={u.name}
                    className={classes.avatar}
                    src={!!img && img !== "False" ? img : null}
                  />
                </ListItemAvatar>
                <ListItemText primary={u.name} />
              </MenuItem>
            );
          })}
        </FilledSelect>
      </div>
    );
  } else {
    return (
      <Typography variant="body1" className={classes.text}>
        {name}
      </Typography>
    );
  }
}
