import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  hideScrollbar: {"&::-webkit-scrollbar": {width: "0!important"}},
}));

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 50,
});

export default function VirtualizedList({
  totalRows,
  hideScrollbar,
  getRowItem,
  rowHeight = "auto",
  itemBottomMargin = 0,
  listRef,
  cacheClearDependency,
  cacheClearIndexes = [],
  listClass,
  ...listProps
}) {
  const classes = useStyles();
  let newCacheClearDependency = Array.isArray(cacheClearDependency)
    ? cacheClearDependency
    : [cacheClearDependency];

  React.useEffect(() => {
    if (!!cacheClearDependency) {
      cache.clearAll();
    }
  }, [newCacheClearDependency]);

  React.useEffect(() => {
    if (!!cacheClearIndexes.length) {
      cacheClearIndexes.forEach((ind) => {
        cache.clear(ind);
      });
    }
  }, [cacheClearIndexes]);

  const rowRenderer = ({index, key, style, parent}) => {
    // index, isScrolling, isVisible, key, parent, style
    if (rowHeight === "auto") {
      return (
        <CellMeasurer
          key={key}
          cache={cache}
          parent={parent}
          columnIndex={0}
          rowIndex={index}
        >
          {({measure, registerChild}) => (
            <div ref={registerChild} style={style}>
              {getRowItem({index, onLoad: measure})}
            </div>
          )}
        </CellMeasurer>
      );
    } else {
      return (
        <div key={key} style={style}>
          {!!itemBottomMargin ? (
            <div style={{paddingBottom: itemBottomMargin, height: "100%"}}>
              {getRowItem({index})}
            </div>
          ) : (
            getRowItem({index})
          )}
        </div>
      );
    }
  };

  return (
    <AutoSizer>
      {({width, height}) => {
        return (
          <List
            ref={listRef}
            width={width}
            height={height}
            rowCount={totalRows}
            rowRenderer={rowRenderer}
            className={clsx(
              {[classes.hideScrollbar]: !!hideScrollbar},
              listClass,
            )}
            rowHeight={
              rowHeight === "auto"
                ? cache.rowHeight
                : rowHeight + itemBottomMargin
            }
            deferredMeasurementCache={rowHeight === "auto" ? cache : undefined}
            {...listProps}
            id={"listings-list"}
          />
        );
      }}
    </AutoSizer>
  );
}
