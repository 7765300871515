import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {Waypoint} from "react-waypoint";
import {SvgIcon, Typography} from "@material-ui/core";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab";
import {lighten, makeStyles} from "@material-ui/core/styles";
import NotificationIcon from "@material-ui/icons/Notifications";
import {ReactComponent as SmartDevicesIcon} from "assets/icons/Icon_Smart_Devices.svg";
// Custom
import NotificationErrorMessage from "../TextFields/NotificationErrorMessage";
import VirtualizedList from "components/Lists/VirtualizedList";
// Actions
import {getNotifications} from "redux/actions/notificationsActions";
// Utilities
import {FIXED_SIZES, THEME} from "configuration/settings";
import {format} from "date-fns";
import clsx from "clsx";
import _ from "lodash";
import qs from "qs";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    minHeight: 200,
    display: "flex",
    flexDirection: "column",
  },
  listContainer: {padding: 0},
  empty: {minHeight: 80},
  listItem: {paddingLeft: 0},
  timelineItem: {"&:before": {content: "none"}},
  past: {opacity: 0.5},
  timelineDot: {
    cursor: "pointer",
    border: "1px solid #bdbdbd",
    "&.-error": {
      border: `1px solid ${lighten(theme.palette.error.main, 0.15)}`,
    },
  },
  timelineConnector: {width: 1},
  timelineContent: {
    cursor: "pointer",
    padding: theme.spacing(2, 4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  icon: {
    width: 20,
    height: 20,
    color: THEME.grey,
    opacity: 0.5,
    margin: 3,
  },
  iconError: {
    color: theme.palette.error.light,
    opacity: 0.85,
  },
  message: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    WebkitLineClamp: 1,
  },
}));

export default function NotificationList({
  selected,
  deviceNotifications,
  onItemSelect,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notifications_dict = useSelector(
    (state) => state.defaultReducer.notifications_dict,
  );
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const [notifications, setNotifications] = React.useState(null);
  const [hasNextPage, setHasNextPage] = React.useState(true);

  React.useEffect(() => setNotifications((prev) => null), [selected]);

  React.useEffect(() => {
    let newNotifications = (deviceNotifications || []).filter(
      (n) => n.notification_type === "dve",
    );
    newNotifications = _.sortBy(newNotifications, "sent");
    setNotifications((prev) => newNotifications);
  }, [notifications_dict, deviceNotifications]);

  const loadNextPage = () => {
    let params = {
      enso_key: current_user,
      start: notifications.length,
      devide_id: selected,
    };

    dispatch(
      getNotifications(qs.stringify(params), true, (response) => {
        setHasNextPage((prev) => response.start_key);
        const newDeviceNotif = (response || []).filter(
          (n) => n.notification_type === "device_event",
        );
        setNotifications((prev) => _.sortBy([...prev, newDeviceNotif], "sent"));
      }),
    );
  };

  const handleItemSelect = (item) => () => {
    onItemSelect && onItemSelect(item);
  };

  const getIcon = (type, error) => {
    let icon = null;
    let viewbox = "0 0 35 35";
    switch (type) {
      case "device_event":
        icon = SmartDevicesIcon;
        viewbox = "0 0 25 25";
        break;
    }

    if (!icon) {
      return (
        <NotificationIcon
          color="disabled"
          className={clsx({[classes.iconError]: !!error})}
        />
      );
    }
    return (
      <SvgIcon
        fontSize="large"
        viewBox={viewbox}
        className={clsx(classes.icon, {[classes.iconError]: !!error})}
        component={icon}
      />
    );
  };

  function getNotificationItem({index}) {
    const notif = notifications[index];
    const hasErrors = !!notif.errors?.length;
    return (
      <>
        {hasNextPage && index === notifications.length - 10 && (
          <Waypoint onEnter={() => loadNextPage()} />
        )}
        <TimelineItem
          className={clsx(classes.timelineItem, {
            [classes.past]: notif.status === "pending",
          })}
        >
          <TimelineSeparator>
            <TimelineConnector className={classes.timelineConnector} />
            <TimelineDot
              variant="outlined"
              onClick={handleItemSelect(notif)}
              className={clsx(classes.timelineDot, {"-error": hasErrors})}
              id={"timeline-icon"}
            >
              {getIcon(notif.notification_type, hasErrors)}
            </TimelineDot>
          </TimelineSeparator>
          {notif.sent && (
            <TimelineContent
              className={classes.timelineContent}
              onClick={handleItemSelect(notif)}
              id={`timeline-item-${index}`}
            >
              {!hasErrors ? (
                <>
                  <Typography
                    variant="h1"
                    className={classes.message}
                    id={`timeline-item-${index}-title`}
                  >
                    {notif.message}
                  </Typography>
                  <Typography color="textSecondary">
                    {format(notif.sent, "MMM d, y")} at{" "}
                    {format(notif.sent, "h:mmaaa")}
                  </Typography>
                </>
              ) : (
                <NotificationErrorMessage
                  hideIcon
                  title={notif.message}
                  date={`${format(notif.sent, "MMM d, y")} at ${format(notif.sent, "h:mmaaa")}`}
                />
              )}
            </TimelineContent>
          )}
        </TimelineItem>
      </>
    );
  }

  const notificationList = React.useMemo(() => {
    if (!notifications) {
      return null;
    }
    return (
      <Timeline align="left" className={classes.listContainer}>
        <VirtualizedList
          hideScrollbar
          getRowItem={getNotificationItem}
          rowHeight={FIXED_SIZES.notifications}
          totalRows={notifications.length}
        />
      </Timeline>
    );
  }, [notifications, selected]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.empty]: !!notifications && !notifications.length,
      })}
    >
      {!!notifications && !notifications.length ? (
        <Typography
          variant="h2"
          color="textSecondary"
          className="mt-4 mb-3"
          align="center"
        >
          {"No Notifications"}
        </Typography>
      ) : (
        notificationList
      )}
    </div>
  );
}
