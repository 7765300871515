import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
    WebkitLineClamp: 1,
  },
  title: {color: theme.palette.error.main},
  icon: {
    color: theme.palette.error.main,
    fontSize: 28,
    marginRight: theme.spacing(2),
  },
}));

export default function NotificationError({title, subtitle, date, hideIcon}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!hideIcon && <ErrorIcon className={classes.icon} />}
      <div>
        <Typography variant="h1" className={clsx(classes.text, classes.title)}>
          {title}
        </Typography>
        {!!subtitle && (
          <Typography className={classes.text}>{subtitle}</Typography>
        )}
        {!!date && <Typography>{date}</Typography>}
      </div>
    </div>
  );
}
