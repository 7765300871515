import React from "react";
import {postServiceStripe} from "redux/api/servicesAPI.js";
import {Typography, Card, CardContent, SvgIcon, Modal} from "@material-ui/core";
import PrimaryButton from "core/buttons/PrimaryButton";
import {ReactComponent as StripeIcon} from "assets/icons/stripe.svg";
import FullLoader from "components/Dialogs/FullLoader";

export default function StripeAccountCard({account, setAccount, hideIcon}) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  let account_enabled = account?.properties?.enabled;

  function handleConnectStripe(newAccount = null) {
    if (!account && newAccount === null) {
      setModalOpen(true);
      return;
    }
    setLoading((prev) => true);
    postServiceStripe({
      new_account: newAccount || false,
      service_id: account?.service_id,
    }).then((r) => {
      if (r.success) {
        setAccount(r.service_account);
      } else {
        window.location.replace(r.url);
      }
    });
  }

  const handleLoadingDialogClose = () => setLoading((prev) => false);

  const loadingModal = (
    <FullLoader
      open={loading}
      disableDismiss
      onClose={handleLoadingDialogClose}
      loadingText="Redirecting..."
    />
  );

  return (
    <>
      {loadingModal}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div
          style={{
            padding: 20,
            position: "absolute",
            width: 400,
            top: "50%",
            left: "50%",
            transform: `translate(-50%, -50%)`,
            backgroundColor: "white",
          }}
        >
          <Typography variant="h2">Already have a Stripe account?</Typography>
          <div style={{marginTop: 20}}>
            <PrimaryButton
              style={{marginRight: 5}}
              label={"Yes"}
              onClick={() => {
                setModalOpen(false);
                handleConnectStripe(false);
              }}
            />
            <PrimaryButton
              label={"No"}
              onClick={() => {
                setModalOpen(false);
                handleConnectStripe(true);
              }}
            />
          </div>
        </div>
      </Modal>
      <Card style={{marginTop: 10}}>
        <CardContent>
          {!hideIcon && (
            <SvgIcon
              style={{width: 100, height: 50}}
              viewBox="0 0 500 243"
              component={StripeIcon}
            />
          )}

          {account_enabled ? (
            <>
              <Typography>
                Your account is connected & you will receive payouts from
                guests.
              </Typography>
            </>
          ) : (
            <>
              <Typography>
                Connect your account to get paid when a guest purchases an
                upsell or pays a fee.
              </Typography>
            </>
          )}
          <div style={{display: "flex", marginTop: 5}}>
            <div style={{flex: 1, display: "flex", justifyContent: "right"}}>
              {account_enabled ? (
                <PrimaryButton
                  size="small"
                  variant="text"
                  label={"Manage Payouts"}
                  color="primary"
                  onClick={() =>
                    window.open(account.properties?.login_details?.url)
                  }
                />
              ) : (
                <PrimaryButton
                  size="small"
                  variant="text"
                  label={
                    !!account ? "Complete Your Details" : "Connect account"
                  }
                  color="primary"
                  onClick={() => handleConnectStripe()}
                />
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
