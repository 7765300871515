import React, {useEffect, useState} from "react";
import {Box, Button, Typography, makeStyles} from "@material-ui/core";
import InfoText from "./InfoText";
import {bucketMap} from "../../configuration/constants";

const useStyles = makeStyles((theme) => ({
  btn: {
    background: (props) => props.channelColor,
    padding: theme.spacing(1, 2),
    width: "fit-content",
    height: "fit-content",
    borderRadius: 5,
    "&:hover": {background: (props) => props.channelColor},
    cursor: (props) =>
      !!props.hasAction ? "pointer" : "default" + "!important",
  },
  btnLabel: {
    color: (props) => theme.palette.getContrastText(props.channelColor),
    textTransform: "none",
  },
}));

function BkgIdIcon({PMSBkgId}) {
  return (
    <InfoText
      dark
      interactive
      disableMinWidth
      showCustomContent
      text={
        <div>
          <Box>
            <Box mb={3}>
              <Typography variant="subtitle2">Booking ID</Typography>
            </Box>
            <Typography variant="caption">{PMSBkgId}</Typography>
          </Box>
        </div>
      }
    />
  );
}

export default function BookingChannelButton({channel, PMSBkgId, onClick}) {
  const [channelColor, setChannelColor] = useState(
    bucketMap.bucket_metadata[channel]?.color ?? "#272727",
  );
  const classes = useStyles({channelColor, hasAction: !!onClick});

  useEffect(() => {
    let channelKeys = Object.keys(bucketMap.bucket_metadata);
    let newColor = channelKeys.includes(channel)
      ? bucketMap.bucket_metadata[channel].color
      : "#272727";
    setChannelColor((prev) => newColor);
  }, [channel]);

  return (
    <Button
      disableElevation
      disableRipple
      className={classes.btn}
      onClick={() => (!!onClick ? onClick() : {})}
    >
      <Typography variant="caption" className={classes.btnLabel}>
        {channel}
        {!!PMSBkgId && <BkgIdIcon PMSBkgId={PMSBkgId} />}
      </Typography>
    </Button>
  );
}
