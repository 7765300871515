import React from "react";
import {
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import {THEME} from "configuration/settings";
import IconCard from "../../../base/card/IconCard";
import {useTranslation} from "react-i18next";
import {HourglassEmptyRounded, PlusOneRounded} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    borderRadius: 10,
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.2), 0px 26px 80px rgba(0, 0, 0, 0.2)",
    maxHeight: "calc(100% - 100px)",
    maxWidth: "1000px",
    minWidth: "calc(100% - 200px)",
    backgroundColor: THEME.white,
    display: "flex",
    flexDirection: "column",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: theme.spacing(2),
    backgroundColor: "#f5f5f5",
  },
  titleText: {
    padding: THEME.spacing.lg,
    fontWeight: 600,
    fontSize: 18,
  },
  dialogContent: {
    padding: THEME.spacing.xl,
    overflowY: "auto",
    flexGrow: 1,
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    fontSize: 14,
    color: THEME.subdued,
  },
}));

const CrmTemplatesDialog = ({onClose, onPopulate}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const handlePopulate = (item) => {
    // You can pass through an entire experience object into onPopulate and it will be used to populate the form.
    // Leave the name and date empty so the panel handles it correctly.
    onPopulate(item);
    onClose();
  };

  // Remove this line to show dialog.
  handlePopulate(null);

  return (
    <Paper className={classes.paper}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography className={classes.titleText}>
          {t("create-campaign-dialog-title")}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body2" className={classes.subtitle}>
          {t("create-campaign-dialog-subtitle")}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            {/*TODO DYNAMICALLY LOAD TEMPLATES FROM ENDPOINT*/}
            {/* Example           {Object.entries(yourMap).map(([key, view]) => ( */}
            {/*Then populate the title descripton and on click.*/}
            <IconCard
              icon={<HourglassEmptyRounded />}
              title={"Empty Campaign"}
              description={"Create a new campaign from scratch"}
              onClick={() => handlePopulate(null)} // TODO pass through the item to load that template.
            />
          </Grid>
          <Grid item xs={4}>
            <IconCard
              icon={<PlusOneRounded />}
              title={"More Templates Coming Soon!"}
              description={
                "We are working on adding more templates to help you get started faster."
              }
              onClick={() => handlePopulate(null)} // TODO pass through the item to load that template.
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Paper>
  );
};

export default CrmTemplatesDialog;
