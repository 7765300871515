import React from "react";
import {useSelector} from "react-redux";
import {IconButton, InputAdornment, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FilledTextField from "core/inputs/FilledTextField";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.6)",
    marginBottom: theme.spacing(3),
  },
}));

export default function SimpleInfo({value = "", type, updateValue}) {
  const classes = useStyles();
  const language = useSelector((state) => state.defaultReducer.language);
  const [text, setText] = React.useState(value);
  const [showPassword, setShowPassword] = React.useState(type === "password");

  React.useEffect(() => {
    if (value !== text) {
      setText((prev) => value);
    }
  }, [value]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (value !== text) {
        updateValue([{language, value: text}]);
      }
    }, 150);
    return () => clearTimeout(timer);
  }, [text]);

  const onChange = (e) => {
    const val = e.target.value;
    setText((prev) => val);
  };

  const toggleShowPassword = () => setShowPassword((prev) => !prev);

  function getLabel() {
    switch (type) {
      case "special_note":
        return "Special Note";
      case "instructions":
        return "Instructions";
      case "restrictions":
        return "Restrictions";
      case "password":
        return "Password or Code";
      case "location":
        return "Physical Location";

      default:
        return "";
    }
  }

  return (
    <div className="mb-3">
      <Typography variant="subtitle1" className={classes.title}>
        {getLabel()}
      </Typography>
      <FilledTextField
        id={type}
        label={type === "location" ? "Location" : "Details"}
        placeholder=""
        onChange={onChange}
        value={text}
        fullWidth
        type={showPassword ? "password" : "text"}
        InputProps={{
          endAdornment:
            type === "password" ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ) : null,
        }}
      />
    </div>
  );
}
