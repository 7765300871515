import qs from "qs";
import * as API from "../api/experiencesAPI";
import * as types from "../actionTypes";
import {
  closeGeneralSuccessAlert,
  openGeneralSuccessAlert,
  setApiError,
  setApiStart,
  setApiSuccess,
  errorResponseAlert,
} from "./settingsActions";
import {addNotifications, deleteNotification} from "./notificationsActions";
import {editHouse} from "./listingsActions";
import {capitalize} from "utilities/helperFunctions";

function updateListingExperiences(listing, options, action) {
  const currExperiences = listing.listing_experiences ?? {};
  if (action === "add") {
    const newExpTypeItems = currExperiences[options.expType] ?? [];
    const oldExpIndex = newExpTypeItems.findIndex(
      (exp) => exp.experience_id === options.experience.experience_id,
    );
    if (oldExpIndex !== -1) {
      newExpTypeItems[oldExpIndex] = options.experience;
    } else {
      newExpTypeItems.push(options.experience);
    }
    return {
      ...listing,
      listing_experiences: {
        ...currExperiences,
        [options.expType]: newExpTypeItems,
      },
    };
  } else if (action === "delete") {
    return {
      ...listing,
      listing_experiences: {
        ...currExperiences,
        [options.experienceType]: currExperiences[
          options.experienceType
        ].filter((exp) => exp.experience_id !== options.experienceId),
      },
    };
  }
}

export function setAvailableExperiences(experiences) {
  return {type: types.SET_AVAILABLE_EXPERIENCES, experiences};
}
export function setConfiguredExperiences(experiences) {
  return {type: types.SET_CONFIGURED_EXPERIENCES, experiences};
}
export function setInstantExperiences(experiences) {
  return {type: types.SET_INSTANT_EXPERIENCES, experiences};
}
export function addExperience(experienceId, experience, listingId) {
  return {type: types.ADD_EXPERIENCE, experienceId, experience, listingId};
}
export function addListingExperiences(listingId, experiences) {
  return {type: types.ADD_LISTING_EXPERIENCES, listingId, experiences};
}
export function addPresetExperience(preset) {
  return {type: types.ADD_PRESET_EXPERIENCE, preset};
}
export function removeExperience(experienceId, experienceType, listingId) {
  return {
    type: types.REMOVE_EXPERIENCE,
    experienceId,
    experienceType,
    listingId,
  };
}
export function setLoadingVerificationSettings(isLoading, totalExp) {
  return {type: types.LOADING_X_VERIFICATION_SETTINGS, isLoading, totalExp};
}

export function getExperiencePresets({view = "store", presetId}) {
  return async (dispatch, getState) => {
    const current_user = getState().defaultReducer.current_user;
    let params = `enso_key=${current_user}&view=${view}`;
    if (!!presetId) {
      params += `&preset_id=${presetId}`;
    }
    dispatch(setApiStart("experience_presets"));
    try {
      const presets = await API.getExperiencesPresets(params);
      console.log(`GOT EXPERIENCE PRESETS`, presets);
      if (!!presetId) {
        dispatch(addPresetExperience(presets));
      } else {
        dispatch(
          view === "crm"
            ? setInstantExperiences(presets)
            : setAvailableExperiences(presets),
        );
      }
      dispatch(setApiSuccess("experience_presets"));
    } catch (error) {
      console.log("ERROR - GET EXPERIENCE PRESETS", error);
      dispatch(setApiError("experience_presets"));

      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

export function getContentTemplates({template_type, locations, onSuccess}) {
  return async (dispatch, getState) => {
    const current_user = getState().defaultReducer.current_user;
    let params = `template_type=${template_type}`;
    //if (!!presetId) { params += `&preset_id=${presetId}` }
    console.log("GOT  PARAMSA", params);
    dispatch(setApiStart("experience_presets"));
    try {
      const presets = await API.getExperiencesPresets(params);
      onSuccess(presets);
      dispatch(setApiSuccess("experience_presets"));
    } catch (error) {
      console.log("ERROR - GET EXPERIENCE PRESETS", error);
      dispatch(setApiError("experience_presets"));
      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

export function getExperiences({experienceId, listingId, type, onSuccess}) {
  return async (dispatch, getState) => {
    const current_user = getState().defaultReducer.current_user;
    let params = {enso_key: current_user};
    if (!!type) {
      params.experience_type = type;
    }
    if (!!experienceId) {
      params.experience_id = experienceId;
    }
    dispatch(setApiStart("configured_experiences"));
    try {
      const response = await API.getExperiences(qs.stringify(params));
      console.log(`GOT EXPERIENCES`, response);
      if (!!experienceId) {
        dispatch(addExperience(response.experience_id, response, listingId));
      } else if (!!response?.experience?.length) {
        dispatch(setConfiguredExperiences(response.experience));
      }
      dispatch(setApiSuccess("configured_experiences"));
      onSuccess && onSuccess(response);
    } catch (error) {
      console.log("ERROR - GET EXPERIENCES", error);
      dispatch(setApiError("configured_experiences"));

      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

export function saveExperience({
  body,
  listingId,
  isBoardingPass,
  type = "experience",
  isDeleting,
  disableAlerts = false,
  onSuccess = () => null,
  onError = () => null,
  onUpdates = () => null,
}) {
  return async (dispatch, getState) => {
    const listing = getState().defaultReducer.house_data_dict[listingId];
    const objectType = isBoardingPass ? "boarding pass" : type;
    try {
      let newBody = {...body};
      if (!!newBody.experience?.experience_id) {
        delete newBody.experience.experience_id;
      }
      if (!!newBody.experience_id) {
        delete newBody.experience_id;
      }
      const response = await API.postExperience({body: newBody});
      console.log(`RESPONSE - SAVE EXPERIENCE`, response);
      const expType = response.experience?.experience_type;
      if (!!response.error) {
        if (!disableAlerts) {
          errorResponseAlert(
            dispatch,
            `Failed to ${isDeleting ? "delete" : "save"} ${objectType}`,
            "Something went wrong, please try again later",
          );
        }
        onError();
      } else if (!!response.update_session?.payload) {
        onUpdates(response);
      } else {
        if (!!listing) {
          const newHouse = updateListingExperiences(
            listing,
            {expType, experience: response.experience},
            "add",
          );
          dispatch(editHouse(newHouse));
        }
        if (!disableAlerts) {
          successResponseAlert(
            dispatch,
            `${capitalize(objectType)} ${isDeleting ? "deleted" : "saved"}`,
          );
        }
        dispatch(
          addExperience(
            response.experience.experience_id,
            response.experience,
            listingId,
          ),
        );
        onSuccess(response);
      }
    } catch (error) {
      console.log("ERROR - POST EXPERIENCE", error);
      if (!disableAlerts) {
        errorResponseAlert(
          dispatch,
          `Failed to ${isDeleting ? "delete" : "save"} ${objectType}`,
          "Something went wrong, please try again later",
          error,
        );
      }
      onError();
    }
  };
}

export function updateExperience({
  body,
  listingId,
  isBoardingPass,
  type = "experience",
  isDeleting,
  disableAlerts = false,
  onSuccess = () => null,
  onError = () => null,
  onUpdates = () => null,
}) {
  return async (dispatch, getState) => {
    const listing = getState().defaultReducer.house_data_dict[listingId];
    const objectType = isBoardingPass ? "boarding pass" : type;
    try {
      // const response = await API.patchExperience({ newBody })
      const response = await API.postExperience({body});
      console.log(`RESPONSE - UPDATE EXPERIENCE`, response);
      const expType = response.experience?.experience_type;
      if (!!response.error) {
        if (!disableAlerts) {
          errorResponseAlert(
            dispatch,
            `Failed to ${isDeleting ? "delete" : "update"} ${objectType}`,
            "Something went wrong, please try again later",
          );
        }
        onError();
      } else if (!!response.update_session?.payload) {
        onUpdates(response);
      } else {
        if (!!listing) {
          const newHouse = updateListingExperiences(
            listing,
            {expType, experience: response.experience},
            "add",
          );
          dispatch(editHouse(newHouse));
        }
        if (!disableAlerts) {
          successResponseAlert(
            dispatch,
            `${capitalize(objectType)} ${isDeleting ? "deleted" : "updated"}`,
          );
        }
        dispatch(
          addExperience(
            response.experience.experience_id,
            response.experience,
            listingId,
          ),
        );
        onSuccess(response);
      }
    } catch (error) {
      console.log("ERROR - PATCH EXPERIENCE", error);
      if (!disableAlerts) {
        errorResponseAlert(
          dispatch,
          `Failed to ${isDeleting ? "delete" : "update"} ${objectType}`,
          "Something went wrong, please try again later",
          error,
        );
      }
      onError();
    }
  };
}

export function saveCollectDetails({
  body,
  groupId,
  onSuccess = () => null,
  onError = () => null,
}) {
  return async () => {
    try {
      const response = await API.postCollectDetails({body});
      console.log(`RESPONSE - SAVE COLLECT DETAILS`, response);
      if (!!response.error) {
        onError();
      } else {
        onSuccess(response);
      }
    } catch (error) {
      console.log("ERROR - SAVE COLLECT DETAILS", error);
      onError();
    }
  };
}

export function deleteExperience({
  experienceId = null,
  updateId = null,
  experienceType,
  deleteScheduled = null,
  listingId,
  onSuccess = () => null,
  onError = () => null,
  onUpdate = () => null,
}) {
  return async (dispatch, getState) => {
    const listing = getState().defaultReducer.house_data_dict[listingId];
    const current_user = getState().defaultReducer.current_user;
    const params = qs.stringify(
      {
        enso_key: current_user,
        experience_id: experienceId,
        delete_scheduled: deleteScheduled,
        update_id: updateId,
      },
      {addQueryPrefix: true, skipNulls: true},
    );
    dispatch(setApiStart("delete_experience"));
    try {
      const response = await API.delExperience(params);
      console.log(`RESPONSE - DELETE EXPERIENCE`, response);
      if (!!response.success && Object.keys(response).length === 1) {
        successResponseAlert(dispatch, "Experience deleted");
        dispatch(removeExperience(experienceId, experienceType, listingId));
        onSuccess(response);
      } else if (!!response.success) {
        if (!updateId) {
          onUpdate(response);
        } else {
          if (!!listing) {
            const newHouse = updateListingExperiences(
              listing,
              {experienceType, experienceId},
              "delete",
            );
            dispatch(editHouse(newHouse));
          }
          successResponseAlert(dispatch, "Experience deleted");
          dispatch(removeExperience(experienceId, experienceType, listingId));
          onSuccess(response);
        }
        dispatch(setApiSuccess("delete_experience"));
      } else {
        errorResponseAlert(
          dispatch,
          "Failed to delete experience",
          "Something went wrong, please try again later",
        );
        dispatch(setApiError("delete_experience"));
        onError();
      }
    } catch (error) {
      console.log("ERROR - DELETE EXPERIENCE", error);
      errorResponseAlert(
        dispatch,
        "Failed to delete experience",
        "Something went wrong, please try again later",
        error,
      );
      dispatch(setApiError("delete_experience"));
      onError();
    }
  };
}

export function updateAction(scheduledTriggerId, sendTime, action) {
  return async (dispatch) => {
    const body = {
      scheduled_trigger_id: scheduledTriggerId,
      send_time: sendTime,
      action,
    };
    try {
      const response = await API.patchAction({body});
      console.log(`GOT UPDATE ACTION RESPONSE`, response);
      dispatch(addNotifications(response));
      successResponseAlert(dispatch, "Action updated successfully");
    } catch (error) {
      console.log("ERROR - UPDATE ACTION", error);
      errorResponseAlert(
        dispatch,
        "Failed to update action",
        "Something went wrong, please try again later",
        error,
      );
    }
  };
}

export function triggerAction(
  scheduledTriggerId,
  onSuccess = () => null,
  onError = () => null,
) {
  return async (dispatch, getState) => {
    const newBody = {scheduled_action_id: scheduledTriggerId};
    const currNotif =
      getState().defaultReducer.notifications_dict.id[scheduledTriggerId];
    try {
      const response = await API.postAction({body: newBody});
      console.log(`GOT TRIGGERED ACTION`, response);
      dispatch(deleteNotification(currNotif));
      onSuccess();
    } catch (error) {
      console.log("ERROR - TRIGGER ACTION", error);
      errorResponseAlert(dispatch, null, null, error);
      onError();
    }
  };
}

export function cancelAction(
  scheduledTriggerId,
  onSuccess = () => null,
  onError = () => null,
) {
  return async (dispatch, getState) => {
    const params = {scheduled_trigger_id: scheduledTriggerId};
    const currNotif =
      getState().defaultReducer.notifications_dict.id[scheduledTriggerId];
    try {
      const response = await API.delAction(qs.stringify(params));
      console.log("GOT CANCEL ACTION RESPONSE", response);
      dispatch(deleteNotification(currNotif));
      onSuccess();
    } catch (error) {
      console.log("ERROR - CANCEL ACTION", error);
      errorResponseAlert(dispatch, null, null, error);
      onError();
    }
  };
}

export function createAction({
  trigger,
  action,
  bookingId,
  guestId,
  listingId,
  integrationHostId,
  integrationType,
  messageSent = null,
  onSuccess = () => null,
  onError = () => null,
  customErrorMessage = null,
}) {
  return async (dispatch) => {
    const body = {action};
    if (!!trigger) {
      body.trigger = trigger;
    }
    if (!!bookingId) {
      body.booking_id = bookingId;
    }
    if (!!guestId) {
      body.guest_id = guestId;
    }
    if (!!listingId) {
      body.listing_id = listingId;
    }
    if (!!messageSent) {
      body.message_sent = messageSent;
    }
    if (!!integrationHostId) {
      body.integration_host_id = integrationHostId;
    }
    if (!!integrationType) {
      body.integration_type = integrationType;
    }
    dispatch(setApiStart("create_action"));
    try {
      const response = await API.postInstantAction(body);
      console.log("CREATE ACTION - RESPONSE", response);
      dispatch(setApiSuccess("create_action"));
      onSuccess(response);
    } catch (error) {
      console.log("CREATE ACTION - ERROR", error);
      dispatch(setApiError("create_action"));
      onError();
      errorResponseAlert(
        dispatch,
        customErrorMessage,
        null,
        !!customErrorMessage ? null : error,
      );
    }
  };
}

export function postAccessCode({
  action,
  deviceID,
  bookingID,
  guestID,
  deviceList = null,
  onSuccess = () => {},
  onError = () => {},
}) {
  return async (dispatch) => {
    const body = {
      action,
      device_id: deviceID,
      booking_id: bookingID,
      guest_id: guestID,
    };
    if (!!deviceList) {
      body.device_ids = deviceList;
    }
    dispatch(setApiStart("create_access_code"));
    try {
      const response = await API.postInstantAction(body);
      console.log("GENERATE ACCESS CODE - RESPONSE", response);
      dispatch(setApiSuccess("create_access_code"));
      onSuccess(response);
    } catch (error) {
      console.log("GENERATE ACCESS CODE - ERROR", error);
      dispatch(setApiError("create_access_code"));
      onError();
      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

// ALERTS
function handleSuccessAlertClose(dispatch) {
  dispatch(closeGeneralSuccessAlert());
}

function successResponseAlert(dispatch, message = "Success!", subtitle = null) {
  dispatch(
    openGeneralSuccessAlert({
      message,
      subtitle,
      open: true,
      onClose: () => handleSuccessAlertClose(dispatch),
    }),
  );
}
