import React from "react";
// UI
import {Paper, Slide} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import BackButton from "core/buttons/BackButton";
// Utils
import usePrevious from "hooks/usePrevious";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: (props) => props.bg,
  },
  screenContainer: {
    height: "100%",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  screen: {
    flex: 1,
    overflow: "auto",
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  paper: {
    height: 0,
    overflow: "hidden",
    "&.-active": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
  },
  subnavRow: {
    height: 44,
    minHeight: 44,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2, 3),
    backgroundColor: (props) => props.bg,
    "&.-hide": {
      height: 0,
      padding: 0,
      minHeight: 0,
    },
  },
}));

export default function MobileThreePanel({
  bg = "#FFF",
  goBack,
  activeView,
  actions,
  backLabel,
  hideBackBtn,
  hideSubnavControls,
  leftPanel,
  midPanel,
  rightPanel,
}) {
  const classes = useStyles({bg});
  const {mobileView1, mobileView2, mobileView3} = React.useMemo(() => {
    const newValues = {
      mobileView1: false,
      mobileView2: false,
      mobileView3: false,
    };
    if (activeView === "panel1") {
      newValues.mobileView1 = true;
    } else if (activeView === "panel2") {
      newValues.mobileView2 = true;
    } else if (activeView === "panel3") {
      newValues.mobileView3 = true;
    }
    return newValues;
  }, [activeView]);
  const prevMV1 = usePrevious(mobileView1);
  const hideSubnavIsAnArray = Array.isArray(hideSubnavControls);

  function getSubnavbar(hideNavbar, index) {
    const goBackIsAnArray = Array.isArray(goBack);
    const actionsIsAnArray = Array.isArray(actions);
    const hideBackIsAnArray = Array.isArray(hideBackBtn);
    const backLabelIsAnArray = Array.isArray(backLabel);

    return (
      <div className={clsx(classes.subnavRow, {"-hide": !!hideNavbar})}>
        {!hideNavbar ? (
          <>
            {(hideBackIsAnArray ? hideBackBtn[index] : !hideBackBtn) ? (
              <BackButton
                transparent
                goBack={goBackIsAnArray ? goBack[index] : goBack}
                label={backLabelIsAnArray ? backLabel[index] : backLabel}
              />
            ) : (
              <div />
            )}
            {actionsIsAnArray
              ? !!actions[index] && <div>{actions[index]}</div>
              : !!actions && <div>{actions}</div>}
          </>
        ) : null}
      </div>
    );
  }

  function getPanel(direction, open, index, panel) {
    return (
      <Slide direction={direction} in={open}>
        <Paper elevation={0} className={clsx(classes.paper, {"-active": open})}>
          {hideSubnavIsAnArray
            ? getSubnavbar(hideSubnavControls[index], index)
            : getSubnavbar(!!hideSubnavControls, index)}
          <div className={classes.screen}>{panel}</div>
        </Paper>
      </Slide>
    );
  }

  return (
    <div className={clsx(classes.container, "mobileContainer")}>
      <div className={classes.screenContainer}>
        {getPanel("right", mobileView1, 0, leftPanel)}
        {getPanel(
          !!prevMV1 && mobileView2 ? "left" : "right",
          mobileView2,
          1,
          midPanel,
        )}
        {getPanel("left", mobileView3, 2, rightPanel)}
      </div>
    </div>
  );
}
