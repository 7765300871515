import React from "react";
// UI
import {Collapse, IconButton, Paper, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import LockIcon from "@material-ui/icons/Lock";
import UnlockIcon from "@material-ui/icons/LockOpen";
import useExperienceActionsStyles from "styles/useExperienceActionsStyles";
// Custom
import FilledTextField from "core/inputs/FilledTextField";
// Utilities
import clsx from "clsx";

export default function AccessAction({
  edit,
  data,
  selectedAction,
  editData,
  disableEdit,
  disableDelete,
  hideEdit,
  variant,
  onEdit,
  onDelete,
}) {
  const styleProps = {filled: variant === "filled"};
  const classes = useExperienceActionsStyles(styleProps);
  const [checkoutMinOffset, setCheckoutMinOffset] = React.useState(0);
  const isNew = !!selectedAction;
  const actionType = isNew ? selectedAction : data?.action_id;
  const isProvideAccess = actionType === "provide_access";
  const isUnlockAction = [
    "unlock_door",
    "unlock_box",
    "provide_access",
  ].includes(actionType);

  React.useEffect(() => {
    if (!data) {
      return;
    }
    if (isProvideAccess) {
      setCheckoutMinOffset((prev) => data.checkout_min_offset ?? 0);
    }
  }, [data]);

  React.useEffect(() => {
    if (!data) {
      return;
    }
    if (isProvideAccess && data.checkout_min_offset !== checkoutMinOffset) {
      editData({checkout_min_offset: checkoutMinOffset});
    }
  }, [checkoutMinOffset]);

  const handleOffsetChange = (e) => {
    const val = e.target.value;
    setCheckoutMinOffset((prev) => val);
  };

  return (
    <>
      <Collapse in={edit} timeout={{appear: 1000, enter: 150, exit: 150}}>
        <Paper elevation={0} className={classes.paper}>
          {isProvideAccess ? (
            <div className="mt-2 mb-3">
              <FilledTextField
                label="Checkout Min Offset (sec.)"
                placeholder="0"
                style={{minWidth: 172}}
                type="number"
                inputProps={{min: 0, max: 86400}}
                onChange={handleOffsetChange}
              />
            </div>
          ) : null}
        </Paper>
      </Collapse>
      <Collapse in={!edit} timeout={{appear: 1000, enter: 150, exit: 150}}>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.selectedOption}>
            <Typography
              className={clsx(
                classes.title,
                classes.flexGrow,
                classes.ellipsis,
              )}
              component="span"
            >
              {isUnlockAction ? (
                <UnlockIcon className={classes.iconPreview} />
              ) : (
                <LockIcon className={classes.iconPreview} />
              )}
              <span className={classes.capitalize}>
                {actionType.split("_").join(" ")}
              </span>
              {isProvideAccess && checkoutMinOffset > 0 && (
                <span className={clsx("ml-1", classes.medium)}>
                  {`with checkout min offset of ${checkoutMinOffset}s.`}
                </span>
              )}
            </Typography>
            {!hideEdit && (
              <IconButton
                className={clsx(classes.iconBtn, {"-disabled": disableEdit})}
                onClick={onEdit}
              >
                <EditIcon className={classes.icon} />
              </IconButton>
            )}
            {!disableDelete && (
              <IconButton
                className={clsx(classes.iconBtn, {"-disabled": disableEdit})}
                onClick={onDelete}
              >
                <DeleteIcon className={classes.icon} />
              </IconButton>
            )}
          </div>
        </Paper>
      </Collapse>
    </>
  );
}
