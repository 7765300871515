import React from "react";
// UI
import {
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
// Custom
import PrimaryButton from "core/buttons/PrimaryButton";
import PhoneField from "core/inputs/PhoneField";
import Tag from "core/chips/Tag";
// Utils
import {validateEmail} from "utilities/helperFunctions";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    width: "fit-content",
    flexWrap: "wrap",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(1),
  },
  iconBtn: {padding: 0},
  addIcon: {
    width: 14,
    height: 14,
    color: (props) =>
      !!props.light ? theme.palette.common.white : theme.palette.primary.main,
    "&.secondary": {color: theme.palette.secondary.main},
  },
  addBtn: {
    "&.tags": {
      color: (props) =>
        !!props.light ? theme.palette.common.white : theme.palette.primary.main,
    },
    padding: 0,
    backgroundColor: "transparent !important",
  },
  optionsPaper: {
    maxHeight: 300,
    overflowY: "auto",
    overflowX: "hidden",
  },
}));

function OptionsModal({open, anchorEl, suggestions, handleSelect}) {
  const classes = useStyles();

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
      style={{zIndex: 3}}
    >
      {({TransitionProps, placement}) => (
        <Grow {...TransitionProps}>
          <Paper className={classes.optionsPaper}>
            <MenuList>
              {!suggestions?.length && (
                <MenuItem disabled>{"No suggestions"}</MenuItem>
              )}
              {suggestions.map((sggtn) => {
                return (
                  <MenuItem key={sggtn} onClick={() => handleSelect(sggtn)}>
                    {sggtn}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

function TagList({
  disabled,
  objectId,
  light,
  suggestions = null,
  tags = [],
  flexItem,
  onChange,
  label = "Click to add tag",
  type = "tags",
  color = "primary",
  lgTag,
}) {
  const classes = useStyles({disabled, light});
  const anchorRef = React.useRef(null);
  const validPhone = React.useRef(false);
  const validEmail = React.useRef(false);
  const [isAddingNewTag, setIsAddingNewTag] = React.useState(false);
  const [optionsOpen, setOptionsOpen] = React.useState(false);
  const [options, setOptions] = React.useState(suggestions ?? []);
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState(null);
  const [hasErrors, setHasErrors] = React.useState({
    phone: false,
    email: false,
  });

  const resetFlags = () => {
    setIsAddingNewTag((prev) => false);
    setOptions((prev) => suggestions ?? []);
    setOptionsOpen((prev) => false);
    setPhone((prev) => "");
    setEmail((prev) => null);
  };

  React.useEffect(() => {
    if (!!objectId) {
      resetFlags();
    }
  }, [objectId]);

  const onTagSave = (newTag) => {
    if (type === "email" && !validEmail.current) {
      setHasErrors((prev) => ({...prev, email: true}));
    } else {
      onChange([...tags, newTag]);
      resetFlags();
    }
  };

  const onPhoneSave = () => {
    if (!validPhone.current || !phone.length) {
      setHasErrors((prev) => ({...prev, phone: true}));
    } else {
      onChange([...tags, phone]);
      resetFlags();
    }
  };

  const onTagDelete = (index) => {
    onChange(tags.filter((t, i) => i !== index));
    setIsAddingNewTag((prev) => false);
  };

  const handleTagFocus = (target) => {
    if (!!target) {
      anchorRef.current = target;
    }
    setOptionsOpen((prev) => true);
  };

  const handleTagBlur = (isEmail) => () => {
    if (isEmail) {
      onTagSave(email);
    }
    setOptionsOpen((prev) => false);
  };

  const handleAddTag = () => {
    setIsAddingNewTag((prev) => true);
    if (type === "email") {
      validEmail.current = false;
    }
    if (!!hasErrors.phone || !!hasErrors.email) {
      setHasErrors((prev) => ({phone: false, email: false}));
    }
  };

  const handleTextChange = (isEmail) => (newVal) => {
    if (isEmail) {
      validEmail.current = !!newVal?.length && validateEmail(newVal);
      setEmail((prev) => newVal);
    }
    if (!!suggestions) {
      setOptions((prev) =>
        suggestions.filter((opt) =>
          opt.toLowerCase().includes(newVal.toLowerCase()),
        ),
      );
    }
  };

  const onPhoneKeyDown = (e) => {
    if (!!hasErrors.phone) {
      setHasErrors((prev) => ({...prev, phone: false}));
    }
    if (e.key === "Enter") {
      onPhoneSave();
    } else if (e.key === "Escape") {
      resetFlags();
    }
  };

  const onEmailKeyDown = (e) => {
    if (!!hasErrors.email) {
      setHasErrors((prev) => ({...prev, email: false}));
    }
  };

  const onPhoneBlur = (e) => {
    onPhoneSave();
  };

  function getEditableContent() {
    switch (type) {
      case "tags":
      case "email":
        return (
          <Tag
            edit
            tagRef={anchorRef}
            light={light}
            md={lgTag}
            large={type === "email"}
            error={type === "email" && hasErrors.email}
            onFocus={handleTagFocus}
            onBlur={handleTagBlur(type === "email")}
            onTextChange={handleTextChange(type === "email")}
            onKeyDown={type === "email" ? onEmailKeyDown : undefined}
            onDelete={() => setIsAddingNewTag((prev) => false)}
            handleSaveTag={onTagSave}
          />
        );
      case "SMS":
        return (
          <PhoneField
            tag
            edit
            allowEmpty
            phone={phone}
            error={hasErrors.phone}
            onChange={setPhone}
            onDelete={resetFlags}
            onBlur={onPhoneBlur}
            onKeyDown={onPhoneKeyDown}
            setValidPhone={(isValid) => {
              validPhone.current = isValid;
            }}
          />
        );

      default:
        return null;
    }
  }

  const optionsMenu = !!suggestions && (
    <OptionsModal
      open={optionsOpen && !!anchorRef.current}
      suggestions={options}
      handleSelect={onTagSave}
      anchorEl={anchorRef.current}
    />
  );

  const content = (
    <>
      {optionsMenu}
      {tags.map((t, i) => {
        return type === "SMS" ? (
          <PhoneField
            tag
            phone={t}
            disableEdit
            edit={false}
            key={`${t}-tag`}
            onDelete={disabled ? null : () => onTagDelete(i)}
          />
        ) : (
          <Tag
            label={t}
            light={light}
            key={`${t}-tag`}
            md={lgTag}
            onDelete={disabled ? null : () => onTagDelete(i)}
          />
        );
      })}
      {!disabled &&
        (isAddingNewTag ? (
          getEditableContent()
        ) : (
          <>
            {!tags.length && (
              <PrimaryButton
                variant="text"
                color={color}
                className={clsx(classes.addBtn, {tags: type === "tags"})}
                label={label}
                onClick={handleAddTag}
              />
            )}
            <IconButton
              className={classes.iconBtn}
              onClick={handleAddTag}
              style={{marginTop: !light ? 2 : 0}}
            >
              <AddIcon
                className={clsx(classes.addIcon, {
                  secondary: color === "secondary",
                })}
              />
            </IconButton>
          </>
        ))}
    </>
  );

  return flexItem ? content : <div className={classes.list}>{content}</div>;
}

export default TagList;
