import React, {useMemo} from "react";
import {Box, makeStyles} from "@material-ui/core";
import useWindowDimensions from "hooks/useWindowDimensions";
import AnyChart from "anychart-react";
import clsx from "clsx";
import _ from "lodash";
const cardHeightDesktop = 320;
const smZoomPercentage = 75;
const xsZoomPercentage = 60;
const fixedSizes = {
  chart: {
    defaultWidth: "100%",
    verticalWidth: 450,
  },
  node: {
    defaultWidth: 175,
    verticalWidth: 80,
    defaultPadding: 43,
    verticalPadding: 10,
    label: {
      defaultWidth: 190,
      verticalWidth: 82,
      defaultHeight: undefined,
      verticalHeight: 90,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  mobileContainer: {
    width: "100%",
    height: 450,
    "&.displayingRevoked": {width: "calc(100vw - 40px - 16px - 24px)"}, // 40px card padding + 16px dashboard padding + 24px revoked element
  },
  chartContainer: {
    "&.vertical": {
      width: 450,
      height: "calc(100vw - 40px - 16px)", // 40px card padding + 16px dashboard padding
      transform: "rotate(90deg) translateY(-100%)",
      transformOrigin: "top left",
    },
    "& .anychart-credits": {display: "none"},
    "&.displayingRevoked": {height: "calc(100vw - 40px - 16px - 24px)"}, // 40px card padding + 16px dashboard padding + 24px revoked element
    "&.smZoom": {
      zoom: `${smZoomPercentage}%`,
      "& > #ac-chart-container > div": {
        height: (props) =>
          `${cardHeightDesktop * props.growthRate + 20}px !important`,
      },
    },
    "&.xsZoom": {
      zoom: `${xsZoomPercentage}%`,
      "& > #ac-chart-container > div": {
        height: (props) =>
          `${cardHeightDesktop * props.growthRate + 20}px !important`,
      },
    },
  },
}));

const getShortName = (key) => {
  switch (key) {
    case "Bypass Checkin Restrictions":
      return "Bypass Restrictions";
    case "Checked-In Through Boarding Pass":
      return "Checked-In";
    default:
      return key;
  }
};

const getPercentage = (name, total, count) => {
  if (name === "Boarding Pass Opened") {
    return "";
  }
  let newPercentage = Math.round((count * 100) / total);
  return `${newPercentage >= 1 ? newPercentage : 1}%`;
};

const getTotalVerificationSteps = (data) => {
  let verificationSteps = new Set();
  data.forEach((d) => {
    if (d.from === "Boarding Pass Opened") {
      verificationSteps.add(d.to);
    }
  });
  return [...verificationSteps];
};

export default function SankeyChart({
  data,
  getColor,
  vertical,
  displayingRevoked,
}) {
  const {width, height} = useWindowDimensions();
  let mobileChartWidth = vertical
    ? displayingRevoked
      ? width - 40 - 16 - 24 // 40px card padding + 16px dashboard padding + 24px revoked element
      : width - 40 - 16 // 40px card padding + 16px dashboard padding
    : null;
  let {chartData, totalBP, colors, verifSteps} = useMemo(() => {
    let newData = [];
    let newColors = {};
    let total = 0;
    data.forEach((d) => {
      if (!!d.count && d.from !== d.to) {
        if (d.from === "Boarding Pass Opened") {
          total += d.count;
        }
        newColors[d.from] = d.from_color;
        newColors[d.to] = d.to_color;
        newData.push({from: d.from, to: d.to, weight: d.count});
      }
    });
    return {
      chartData: newData,
      totalBP: total,
      colors: newColors,
      verifSteps: getTotalVerificationSteps(newData),
    };
  }, [data]);
  let useSmView = verifSteps.length > 3;
  let useXsView = verifSteps.length > 6;
  let growthRate =
    (100 - (useXsView ? xsZoomPercentage : smZoomPercentage)) / 100 + 1;
  const classes = useStyles({
    growthRate: useXsView ? growthRate + 0.2 : growthRate,
  });

  const formatColor = (n, isFlow) => {
    let val = colors[isFlow ? n.to : n.name];
    return val;
  };

  const getChartHeight = () => {
    if (useXsView) {
      return cardHeightDesktop * (growthRate + 0.2);
    } else if (useSmView) {
      return cardHeightDesktop * growthRate;
    } else return cardHeightDesktop;
  };

  return (
    <Box
      height={vertical ? undefined : cardHeightDesktop}
      className={clsx({
        [classes.mobileContainer]: !!vertical,
        displayingRevoked: vertical && displayingRevoked,
      })}
    >
      <div
        className={clsx("chart", classes.chartContainer, {
          vertical: vertical,
          displayingRevoked: vertical && displayingRevoked,
          smZoom: !!useSmView && !useXsView,
          xsZoom: !!useXsView,
        })}
      >
        <AnyChart
          type="sankey"
          data={chartData}
          width={
            vertical
              ? fixedSizes.chart.verticalWidth
              : fixedSizes.chart.defaultWidth
          }
          height={vertical ? mobileChartWidth : getChartHeight()}
          nodeWidth={
            vertical
              ? fixedSizes.node.verticalWidth
              : useSmView
                ? fixedSizes.node.defaultWidth * growthRate
                : fixedSizes.node.defaultWidth
          }
          nodePadding={
            vertical
              ? fixedSizes.node.verticalPadding
              : useSmView
                ? fixedSizes.node.defaultPadding * growthRate
                : fixedSizes.node.defaultPadding
          }
          tooltip={{enabled: !vertical}}
          node={{
            labels: {
              useHtml: true,
              padding: [4, 12],
              rotation: vertical ? -90 : 0,
              format: (n) => {
                let count = `<span style='font-size:${vertical ? 16 : 20};font-weight:500;color:${getColor(formatColor(n))}'>${n.value}</span>${vertical ? "<br>" : ""}`;
                let percentage = `<span style='font-size:${vertical ? 12 : 13};font-weight:600;color:${getColor(formatColor(n))}'>${getPercentage(n.name, totalBP, n.value)}</span>`;
                let name = `<span style='color:#000;font-size:${vertical ? 10 : 12}'>${getShortName(n.name)}</span>`;
                return `${count}${vertical ? "" : " "}${percentage}<br>${name}`;
              },
              width: vertical
                ? fixedSizes.node.label.verticalWidth
                : useSmView
                  ? fixedSizes.node.label.defaultWidth * growthRate
                  : fixedSizes.node.label.defaultWidth,
              height: vertical
                ? fixedSizes.node.label.verticalHeight
                : fixedSizes.node.label.defaultHeight,
              vAlign: "middle",
              hAlign: "left",
              wordWrap: "break-word",
              background: {
                enabled: true,
                fill: "#FFFFFF",
                cornerType: "round",
                corners: 10,
                stroke: "1 rgba(0,0,0,0.05)",
              },
            },
            normal: {fill: (n) => getColor(formatColor(n), "cc")},
            hovered: {fill: (n) => getColor(formatColor(n))},
          }}
          flow={{
            normal: {fill: (n) => getColor(formatColor(n, true), "40")},
            hovered: {
              labels: {enabled: false},
              fill: (n) => getColor(formatColor(n, true), "88"),
            },
          }}
        />
      </div>
    </Box>
  );
}
