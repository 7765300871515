import React, {useState} from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {MenuList} from "@material-ui/core";
import CustomMenu from "core/menus/CustomMenu";
import ActionMenuItem from "../item/ActionMenuItem";

const useStyles = makeStyles((theme) => ({
  menu: {
    padding: theme.spacing(3) + "px !important",
    maxWidth: 280,
    minWidth: 100,
  },
}));

const ActionsMenu = ({open, anchorEl, setAnchorEl, items = []}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [options, setOptions] = useState([]);

  const handleActionClick = (action) => (e) => {
    if (!!action.options) {
      setOptions(action.options);
      setOptionsAnchorEl(e.currentTarget);
    } else {
      setOptionsAnchorEl(null);
      setAnchorEl(null);
      action.onClick();
    }
  };

  return (
    <>
      <CustomMenu
        open={!!optionsAnchorEl}
        anchorEl={optionsAnchorEl}
        disablePortal
        zIndex={3}
        bottomPadding={theme.spacing(3)}
        modifiers={{
          flip: {enabled: false},
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
        }}
        content={
          <MenuList className={classes.menu}>
            {options.map((opt, i) => (
              <ActionMenuItem
                key={opt.title}
                title={opt.title}
                disableDivider={i === items.length - 1}
                description={opt.description}
                onClick={() => {
                  opt.onClick();
                  setOptionsAnchorEl(null);
                  setAnchorEl(null);
                }}
              />
            ))}
          </MenuList>
        }
        onClose={() => setOptionsAnchorEl(null)}
      />
      <CustomMenu
        open={open}
        anchorEl={anchorEl}
        disablePortal
        modifiers={{
          flip: {enabled: false},
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
        }}
        content={
          <MenuList className={classes.menu}>
            {items.map((item, i) => (
              <ActionMenuItem
                key={item.title}
                title={item.title}
                disabled={item.disabled}
                hasOptions={!!item.options}
                disableDivider={i === items.length - 1}
                description={item.description}
                onClick={handleActionClick(item)}
              />
            ))}
          </MenuList>
        }
        onClose={() => {
          setOptionsAnchorEl(null);
          setAnchorEl(null);
        }}
      />
    </>
  );
};

export default ActionsMenu;
