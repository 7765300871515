import React, {useEffect} from "react";
import CustomDialog from "core/dialogs/CustomDialog";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import PaymentMessage from "components/TextFields/PaymentMessage";
import {setForceBilling} from "redux/actions/accountsActions";
import {invalidPaymentPlans} from "configuration/constants";

export default function PaymentModal({
  outsideOpen = null,
  setOutsideOpen = () => {},
  confirmAction = () => {},
  onClose = () => {},
}) {
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = React.useState(outsideOpen ?? false);
  const remaining = user_profile?.pay_by - Date.now();
  const dismissible = remaining > 0;
  const daysRemaining = Math.floor(remaining / 86400000);

  const title =
    user_profile?.plan === "sus"
      ? "Update payment method"
      : "Add payment method";

  useEffect(() => {
    if (outsideOpen === null) {
      return;
    } else if (outsideOpen !== open) {
      setOpen((prev) => outsideOpen);
    }
  }, [outsideOpen]);

  useEffect(() => {
    if (outsideOpen === null) {
      return;
    } else if (open !== outsideOpen) {
      setOutsideOpen((prev) => open);
    }
  }, [open]);

  const handleClose = () => {
    if (dismissible) {
      onClose();
      setOpen(false);
      setOutsideOpen((prev) => false);
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    setOutsideOpen((prev) => false);
    confirmAction();
    if (outsideOpen === null) {
      if (["pay_now", "off", "cncl"].includes(user_profile?.plan)) {
        dispatch(setForceBilling(true));
      }
      history.push("/admin/settings/billing/:redirect");
    }
  };

  React.useEffect(() => {
    if (
      ["sus", "new", "pay_now", "off", "cncl"].includes(user_profile?.plan) &&
      !window.location.href.includes("/admin/settings/billing")
    ) {
      if ("new" === user_profile?.plan && dismissible && daysRemaining > 15) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    } else if (user_profile?.plan === "paid") {
      setOpen(false);
    }
  }, [user_profile]);

  return (
    <CustomDialog
      open={open}
      disableXClose={!dismissible}
      title={title}
      content={PaymentMessage()}
      labelConfirm={title}
      onClose={handleClose}
      actionConfirm={handleConfirm}
    />
  );
}
