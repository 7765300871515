import {SET_DASHBOARD_DATA, TRIGGER_DASHBOARD_REFRESH} from "../actionTypes";

const initial_state = {
  summaryData: {upcoming: null, today: null},
  revenueData: null,
  bpData: null,
  guestSatisfactionData: null,
  bookingsData: null,
  messageData: null,
  triggerDashboardRefresh: false,
};

export default function dashboardReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_DASHBOARD_DATA:
      return {...state, [action.section]: action.data};
    case TRIGGER_DASHBOARD_REFRESH:
      return {...state, triggerDashboardRefresh: action.value};

    default:
      return state;
  }
}
