import React from "react";
import {makeStyles} from "@material-ui/styles";
import {MenuItem} from "@material-ui/core";
import FilledTextField from "core/inputs/FilledTextField";
import FilledSelect from "core/selects/FilledSelect";

const millisecXMinute = 60000;
const useStyles = makeStyles((theme) => ({
  container: {
    width: "fit-content",
    height: "100%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  input: {
    width: "100%",
    minWidth: 80,
    maxWidth: 130,
    marginRight: 2,
  },
  select: {
    width: "100%",
    minWidth: 100,
    maxWidth: 200,
  },
}));

// Value is in milliseconds
export default function ElpsedTime({
  edit,
  value,
  onValueChange,
  label = "Value",
}) {
  const classes = useStyles();
  const didMount = React.useRef(false);
  const [elapsedTime, setElapsedTime] = React.useState(0); // In minutes
  const [timeMultiplier, setTimeMultiplier] = React.useState(1);

  React.useEffect(() => {
    const val = Number(value) / millisecXMinute; // Milliseconds -> minutes
    if (edit && val !== elapsedTime) {
      if (val % 1440 === 0) {
        setTimeMultiplier((prev) => 1440);
      } else if (val % 60 === 0) {
        setTimeMultiplier((prev) => 60);
      } else {
        setTimeMultiplier((prev) => 1);
      }
      setElapsedTime((prev) => val);
    }
  }, [value]);

  React.useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    const val = Number(value) / millisecXMinute; // Milliseconds -> minutes
    if (edit && val !== elapsedTime) {
      onValueChange(elapsedTime * millisecXMinute); // Save in milliseconds
    }
  }, [elapsedTime, timeMultiplier]);

  if (edit) {
    return (
      <div className={classes.container}>
        <FilledTextField
          type="number"
          label={label}
          inputProps={{min: 0}}
          className={classes.input}
          value={elapsedTime / timeMultiplier}
          onChange={(e) => setElapsedTime(e.target.value * timeMultiplier)}
        />
        <FilledSelect
          label="Unit"
          className={classes.select}
          value={timeMultiplier}
          onChange={(e) => {
            setTimeMultiplier(e.target.value);
            setElapsedTime(0);
          }}
          style={{borderRadius: 0}}
        >
          <MenuItem value={1}>minutes</MenuItem>
          <MenuItem value={60}>hours</MenuItem>
          <MenuItem value={1440}>days</MenuItem>
        </FilledSelect>
      </div>
    );
  } else {
    return <div className={classes.container}></div>;
  }
}
