import React from "react";
import {Auth} from "aws-amplify";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {setUser} from "redux/actions/accountsActions";
import LoginForm from "./LoginForm";

const queryParams = new URLSearchParams(window.location.search);

class Login extends React.Component {
  state = {
    password: queryParams.get("password") || "",
    email: queryParams.get("email") || "",
    username: "",
    wrong_pass: false,
    wrong_user: false,
    logged_in: false,
    redirect: "",
    loading: false,
    error: false,
    network: false,
  };

  componentDidMount() {
    if (!!this.state.password) {
      this.handleSubmit();
    }
  }

  handleResponse = (e) => {
    console.log("GOT RESPONSE", e);
    // Wrong pass
    if (e.code === "NotAuthorizedException") {
      this.setState({
        wrong_pass: true,
        wrong_user: false,
      });
    }
    // No mail
    else if (e.code === "UserNotFoundException") {
      this.setState({
        wrong_user: true,
      });
    } else if (e.code === "NetworkError") {
      this.setState({
        network: true,
      });
    } else if ("username" in e) {
      Auth.currentAuthenticatedUser().then((user) => {
        this.props.setUser(user.username);
        this.setState({
          logged_in: true,
        });
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
      loading: false,
      wrong_user: false,
      wrong_pass: false,
    });
  };

  handleSubmit = (event) => {
    event && event.preventDefault();
    this.setState({
      loading: true,
      wrong_pass: false,
      wrong_user: false,
      network: false,
      email: this.state.email.toLowerCase(),
    });

    if (this.state.password !== "" && this.state.email !== "") {
      this.setState({loading: true});
      console.log("SIGNING ING");
      Auth.signIn(this.state.email.trim().toLowerCase(), this.state.password)
        .then((data) => this.handleResponse(data))
        .catch((data) => {
          console.log("GOT ERROR", data);
          this.handleResponse(data);
          this.setState({loading: false});
        });
    } else {
      if (this.state.password === "") {
        this.setState({loading: false, wrong_pass: true});
      }
      if (this.state.username === "") {
        this.setState({loading: false, wrong_user: true});
      }
    }
  };

  render() {
    if (this.state.logged_in) {
      return <Redirect to="/admin/messages" />;
    }
    if (this.state.redirect === "register") {
      return <Redirect push to="/auth/register" />;
    }
    if (this.state.redirect === "forgot") {
      return <Redirect push to="/auth/forgot" />;
    }

    return (
      <LoginForm
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        handleResponse={this.handleResponse}
        loading={this.state.loading}
        wrong_user={this.state.wrong_user}
        wrong_pass={this.state.wrong_pass}
        network={this.state.network}
        email={this.state.email}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUser: (enso_key) => {
    dispatch(setUser(enso_key));
  },
});

export default connect(null, mapDispatchToProps)(Login);
