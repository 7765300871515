import React from "react";
import {Switch} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {THEME} from "../../../configuration/settings";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    backgroundColor: THEME.inputBg,
    paddingLeft: THEME.spacing.sm,
    paddingRight: THEME.spacing.sm,
    height: "100%",
    borderRadius: "5px",
  },
  track: {
    backgroundColor: "#111421",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.shortest,
    }),
    "&.Mui-checked": {
      backgroundColor: "#25D363",
    },
  },
  switchBase: {
    "&.Mui-checked": {
      color: "#FFFFFF",
      "& + .MuiSwitch-track": {
        backgroundColor: "#25D363",
      },
    },
  },
}));

/**
 * @name GenericSwitchInput
 * @description Slightly less ugly generic switch component. Assuming this will all be replaced with rebuild so kept it simple.
 * @param value
 * @param onValueChange
 * @returns {JSX.Element}
 */
export default function GenericSwitchInput({value = false, onValueChange}) {
  const classes = useStyles();

  const handleChange = (event) => {
    onValueChange(event.target.checked);
  };

  return (
    <div className={clsx(classes.container)}>
      <Switch
        checked={value}
        onChange={handleChange}
        size="medium"
        classes={{
          track: classes.track,
          switchBase: classes.switchBase,
        }}
      />
    </div>
  );
}
