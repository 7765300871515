import {makeStyles} from "@material-ui/core";
import {THEME} from "configuration/settings.js";

const useDashboardStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: theme.spacing(4),
    overflow: "auto",
    paddingRight: theme.spacing(4),
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  col: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  centered: {
    alignItems: "center",
    justifyContent: "center",
  },
  skeleton: {
    backgroundColor: theme.palette.grey[200],
    transform: "none",
  },
  flex1: {flex: 1},
  gap4: {gap: theme.spacing(1)},
  gap8: {gap: theme.spacing(2)},
  gap12: {gap: theme.spacing(3)},
  gap20: {gap: theme.spacing(4)},
  fullWidth: {width: "100%"},
  fullHeight: {height: "100%"},
  maxLongCardHeight: {minHeight: "250px !important"},
  maxChartCardHeight: {
    minHeight: "287px !important",
    "&.extraBottomSpace": {minHeight: "395px !important"},
  },
  hideScrollbar: {
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  bigTotal: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    "&.rating": {color: "#F3B146"},
  },
  starIcon: {
    color: "#F3B146",
    fontSize: 30,
    marginRight: -theme.spacing(1),
    "&.small": {
      fontSize: 26,
      marginRight: 0,
    },
  },
  cardTitle: {fontWeight: 500},
  cardsContainer: {
    marginLeft: -theme.spacing(4),
    marginRight: -theme.spacing(4),
    padding: theme.spacing(4),
  },
  mobileChartsContainer: {
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    gap: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F3F7F9",
    "&.whiteBG": {backgroundColor: "#FFFFFF"},
    "&.padd": {padding: theme.spacing(4, 2)},
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  mobileDashboardHeader: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "28px",
    padding: theme.spacing(4, 3, 0),
    "&.home": {
      padding: theme.spacing(0, 4),
      paddingTop: 40,
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
    boxShadow: "0px 0px 50px 0px rgba(0, 0, 0, 0.10)",
    "&.padd": {padding: theme.spacing(4)},
    "&.noPaddBottom": {paddingBottom: 0},
    "&.mobile": {
      height: "fit-content",
      flex: "none",
    },
  },
  cardHeader: {
    justifyContent: "space-between",
    alignItems: "center",
    "&.middle": {justifyContent: "center"},
  },
  lightSelector: {
    backgroundColor: "#F5F8FF",
    padding: theme.spacing(1, 3),
    "&.disabled": {backgroundColor: "#ecf0f9 !important"},
    "&:hover": {backgroundColor: "#ecf0f9"},
  },
  lightSelectorLabel: {color: theme.palette.text.primary},
  menuItemText: {
    fontSize: 14,
    padding: theme.spacing(1, 3),
    "&.selected": {
      fontWeight: 500,
      backgroundColor: "rgba(0,0,0,0.07)",
    },
  },
  summaryHeader: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(4),
    backgroundColor: "#F5F8FF",
    borderRadius: "10px 0 0 0",
    width: "100%",
    "&.fullRounded": {borderRadius: "10px 10px 0 0"},
    "&.mobile": {
      backgroundColor: "#F3F7F9",
      alignItems: "flex-end",
      paddingTop: 0,
    },
  },
  summaryPanel: {
    minWidth: 420,
    maxWidth: 420,
    alignItems: "flex-start",
    flex: "1 0 0",
    alignSelf: "stretch",
    margin: theme.spacing(4, 0),
    borderRadius: "10px 0 0 10px",
    boxShadow: "none",
    "&.fullRounded": {borderRadius: 10},
    "&.mobile": {
      borderRadius: 0,
      margin: 0,
      flex: 1,
      width: "100%",
      maxWidth: "unset",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: 380,
      maxWidth: 380,
    },
  },
  summaryContent: {
    flex: 1,
    width: "100%",
    overflow: "hidden",
  },
  summarySubtitle: {
    color: THEME.subdued,
    fontWeight: 600,
    fontSize: 13,
  },
  summaryExpanded: {
    flex: 1,
    background: "#F1F3F8",
    padding: theme.spacing(3, 4),
    borderRadius: "0 0 0 10px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    "&.fullRounded": {borderRadius: "0 0 10px 10px"},
    "&.mobile": {backgroundColor: "#FFFFFF"},
  },
  summaryList: {
    flex: 1,
    overflow: "auto",
  },
  arrow: {
    fontSize: 16,
    transition: "0.15s",
    "&.expanded": {transform: "rotate(90deg)"},
  },
  mobileListItem: {
    backgroundColor: "#FFFFFF !important",
    borderBottom: "1px solid #F3F7F9",
  },
  listItem: {padding: theme.spacing(3, 4)},
  listItemDivider: {borderBottom: "1px solid #F3F7F9"},
  listItemIcon: {
    minWidth: 0,
    marginRight: theme.spacing(3),
    "&.nomargin": {marginRight: 0},
  },
  listItemText: {color: theme.palette.primary.main},
  listItemTotal: {color: "#FFFFFF"},
  doneIcon: {
    fontSize: 16,
    color: theme.palette.primary.main,
  },
  mark: {
    width: 30,
    height: 24,
    borderRadius: 360,
    "&.complete": {backgroundColor: "#F5F8FF"},
    "&.missing": {backgroundColor: "#FC635B"},
    "&.message": {backgroundColor: theme.palette.secondary.main},
  },
  loadingMark: {backgroundColor: "#F5F6F7"},
  cardListContainer: {
    overflow: "auto",
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  starNumber: {color: "#F3B146"},
  starProgressbar: {
    flex: 1,
    height: 12,
    borderRadius: 360,
  },
  starProgressbarBG: {backgroundColor: "#F0F0F1"},
  starProgressbarBar: {
    backgroundColor: THEME.subdued,
    opacity: 0.3,
  },
  cardListItem: {
    cursor: "pointer",
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    borderTop: "1px solid rgba(13, 86, 140, 0.05)",
    "&:hover": {backgroundColor: "rgba(67, 84, 96, 0.0313726)"},
    "&[aria-selected=true]": {
      fontWeight: 700,
      backgroundColor: "rgba(67, 84, 96, 0.0313726)",
    },
  },
  bookingsBar: {
    height: 44,
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    position: "sticky",
    top: 0,
    padding: theme.spacing(3, 0),
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
  },
  bookingsBarSegment: {height: "100%"},
  summarySkeletonsList: {gap: theme.spacing(2)},
  summarySkeletons: {borderRadius: 10},
  remainingTimeLabel: {gap: theme.spacing(1)},
  captionStrong: {fontWeight: 700},
  blueBox: {
    height: "100%",
    width: "22%",
    borderRadius: 10,
    backgroundColor: "#F5F8FF",
    "&.mobile": {
      height: 100,
      width: "100%",
    },
  },
  revenueContent: {
    paddingTop: theme.spacing(1),
    overflow: "hidden",
    height: "100%",
    minHeight: 130,
    "&.mobile": {
      overflow: "visible",
    },
  },
  bigNumber: {
    ...theme.typography.h3,
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
  chip: {
    padding: theme.spacing(1, 3),
    backgroundColor: "#FFFFFF",
    height: "fit-content",
    boxShadow: "none",
    "&:hover": {backgroundColor: "rgba(0, 0, 0, 0.02)"},
    "&.selected": {
      backgroundColor: theme.palette.secondary.main,
      color: "#FFFFFF",
    },
    "&.mobile": {backgroundColor: "#F5F8FF"},
  },
  chipLabel: {
    ...theme.typography.subtitle2,
    padding: 0,
    "&.selected": {color: "#FFFFFF"},
  },
  noDataTitle: {
    fontWeight: 500,
    color: THEME.subdued,
  },
  noDataInfo: {color: THEME.subdued},
  groupsModalContent: {
    display: "flex",
    flexDirection: "column",
    width: 300,
    height: 350,
    padding: theme.spacing(4, 0, 0),
  },
  revokedLabel: {color: theme.palette.common.white},
  revokedContainer: {
    backgroundColor: "#FD5C63",
    borderRadius: 10,
    width: 135,
    padding: theme.spacing(0, 2),
    lineHeight: "5px",
    height: 17,
    borderRadius: 5,
    textAlign: "center",
    "&.mobile": {
      width: 20,
      height: 80,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  messagingContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    position: "relative",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
    "&.oneColumn": {
      margin: theme.spacing(4, 0),
      marginLeft: -theme.spacing(4),
      height: "calc(100vh - 40px)",
      borderRadius: "0 10px 10px 0",
      flex: 1,
    },
  },
  mobileMessagingContainer: {
    position: "relative",
    height: "100%",
    paddingTop: 40,
    backgroundColor: "#FAFAFA",
    "&.guestDetails": {backgroundColor: "#FFFFFF"},
  },
  closeIconContainer: {
    position: "absolute",
    zIndex: 1500,
    top: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: theme.spacing(3, 3, 0),
    borderRadius: 5,
    "&.messaging": {backgroundColor: "#FAFAFA"},
    "&.detailsPanel": {
      left: theme.spacing(2),
      justifyContent: "space-between",
      backgroundColor: "#FFFFFF",
      top: 0,
      padding: theme.spacing(3, 0, 2, 4),
    },
    "&.mobileMessaging": {backgroundColor: "#FAFAFA !important"},
    "&.mobileBookingDrawer": {backgroundColor: "#FFFFFF !important"},
  },
  mobileContainer: {
    flex: 1,
    width: "100%",
    overflow: "auto",
  },
  verticalChart: {
    display: "flex",
    flexDirection: "row",
    height: 450,
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  emptyDataContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
  },
  chipsContainer: {
    overflowY: "hidden",
    overflowX: "auto",
    marginRight: -theme.spacing(4),
    paddingRight: theme.spacing(4),
    "&::-webkit-scrollbar": {height: "0!important"},
  },
}));

export default useDashboardStyles;
