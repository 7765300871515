import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch, useParams} from "react-router-dom";
// UI
import {Box, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import {EmptyListingGroup} from "components/Helpers/EmptyPanels";
import CustomCardHeader from "core/cards/CustomCardHeader";
import UpdateSession from "components/Dialogs/UpdateSession";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import FullLoader from "components/Dialogs/FullLoader";
import ProgressSummaryPanel from "./ProgressSummaryPanel";
import CloseIconButton from "core/buttons/CloseIconButton";
import ListingGroupSettings from "./ListingGroupSettings";
import ButtonLabelLoader from "core/loaders/ButtonLabelLoader";
// Actions
import {getListingGroupDetails} from "redux/actions/listingsActions";
import {
  deleteExperience,
  getExperiencePresets,
} from "redux/actions/experiencesActions";
import {getServiceAccounts} from "redux/actions/servicesActions";
// Utils
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  content: {
    padding: theme.spacing(0, 4, 4),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto",
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: "1px solid #0D568C0D",
  },
}));

export default function GJEPanel({
  missingCardActive,
  closeGroup,
  selectExperience,
  saveSelectedItems = () => {},
}) {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch("/admin/listings/groups/:group_id/:step/:card");
  const {group_id} = useParams();
  const dispatch = useDispatch();
  const didMount = React.useRef(false);
  const gotDetails = React.useRef(false);
  const containerRef = React.useRef(null);
  const progressContainerRef = React.useRef(null);
  const deleteParams = React.useRef(null);
  const stepRefs = React.useRef([]);
  const awaitingScrolling = React.useRef(null);
  const infoLoaded = React.useRef({
    wifi: false,
    checkinSteps: false,
    checkoutSteps: false,
  });
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const availableExperiences = useSelector(
    (state) => state.experiencesReducer.available_experiences,
  );
  // State
  const [updateSession, setUpdateSession] = React.useState(null);
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [deleteButtonLoading, setDeleteButtonLoading] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [groupDetailsLoaded, setGroupDetailsLoaded] = React.useState(false);
  const [data, setData] = React.useState({
    name: "",
    listings: [],
    groupId: null,
    connection_type: "ids",
  });
  // General
  const readOnly = user_profile.scopes?.listings !== "write";
  const selectedGroup = React.useMemo(
    () => listingGroups[group_id] ?? {},
    [group_id, listingGroups],
  );
  const loadingGroups =
    !!group_id && (!selectedGroup.group_id || !selectedGroup?.guest_journey);
  const sections = selectedGroup?.guest_journey?.sections;

  React.useEffect(() => {
    dispatch(getExperiencePresets({}));
    dispatch(getServiceAccounts({}));
  }, []);

  React.useEffect(() => {
    if (!!missingCardActive && awaitingScrolling.current) {
      const element = stepRefs.current[missingCardActive.step];
      if (!missingCardActive.card) {
        const containerEl = containerRef.current;
        const containerRect = containerEl.getBoundingClientRect();
        const targetRect = element.getBoundingClientRect();
        const relativeTop =
          targetRect.top - containerRect.top + containerEl.scrollTop;
        containerEl.scrollTo({
          top: relativeTop - progressContainerRef.current.offsetHeight,
          behavior: "smooth",
        });
      } else {
        element.scrollIntoView({block: "end", behavior: "smooth"});
      }
    }
  }, [missingCardActive]);

  React.useEffect(() => {
    setSettingsOpen((prev) => false);
    if (!!containerRef.current) {
      containerRef.current.scrollTop = 0;
      setCollapsed((prev) => false);
    }
  }, [group_id]);

  React.useEffect(() => {
    gotDetails.current = false;
  }, [group_id]);

  React.useEffect(() => {
    if (!didMount.current && !!groupDetailsLoaded) {
      let paramCard = match?.params?.card;
      if (paramCard === "wifi") {
        getCardDetails("ci", "wifi");
        infoLoaded.current = {...infoLoaded.current, wifi: true};
      }
      didMount.current = true;
    } else {
      infoLoaded.current = {...infoLoaded.current, wifi: false};
    }
  }, [group_id, groupDetailsLoaded]);

  React.useEffect(() => {
    if (!!Object.keys(selectedGroup).length && !gotDetails.current) {
      gotDetails.current = true;
      setGroupDetailsLoaded(false);
      resetData();
      dispatch(
        getListingGroupDetails({
          groupId: selectedGroup.group_id,
          keepLoading: !!match?.params?.card,
          onSuccess: () => setGroupDetailsLoaded(true),
        }),
      );
    } else {
      resetData();
    }
  }, [selectedGroup]);

  const handleLoadingDialogClose = () => {
    setLoading((prev) => false);
    setIsDeleting((prev) => false);
  };

  const resetData = () => {
    const newData = {
      name: selectedGroup.name || "",
      listings: selectedGroup.connected_to?.ids || [],
      groupId: null,
      connection_type: "ids",
    };
    setData(newData);
  };

  const handleEdit = (val) => {
    setSettingsOpen((prev) => val);
    if (!val) {
      setCollapsed((prev) => false);
    }
  };

  const getCardDetails = (section, card) => {
    dispatch(
      getListingGroupDetails({
        groupId: selectedGroup.group_id ?? group_id,
        section,
        card,
      }),
    );
  };

  const handleSetupAction = (cardKey, sectionKey, item = null) => {
    let newRoute = `/admin/listings/groups/${selectedGroup.group_id}/${sectionKey}/${cardKey}${item !== null ? `/${item}` : ""}`;
    switch (cardKey) {
      case "experiences":
        const experiencesLength =
          sections[sectionKey]?.cards[cardKey]?.data?.length ?? 0;
        if (!!experiencesLength) {
          selectExperience(null);
        }
        newRoute = `/admin/listings/groups/${selectedGroup.group_id}/${sectionKey}/${cardKey}`;
        break;
      case "checkin_steps":
      case "checkout_steps":
        if (match?.params?.card !== cardKey) {
          if (cardKey === "checkin_steps") {
            getCardDetails(sectionKey, cardKey);
            infoLoaded.current = {...infoLoaded.current, checkinSteps: true};
          } else if (cardKey === "checkout_steps") {
            getCardDetails(sectionKey, cardKey);
            infoLoaded.current = {...infoLoaded.current, checkoutSteps: true};
          }
        }
        break;
      case "wifi":
        if (!infoLoaded.current.wifi) {
          getCardDetails(sectionKey, cardKey);
          infoLoaded.current = {...infoLoaded.current, wifi: true};
        }
        break;
      case "guidebooks":
        newRoute = `/admin/listings/groups/${selectedGroup.group_id}/${sectionKey}/${cardKey}`;
        break;
      case "fees":
      case "upsells":
        newRoute = `/admin/listings/groups/${selectedGroup.group_id}/${sectionKey}/${cardKey}`;
        break;
      case "boarding_pass":
        if (item === null) {
          handleAddKeycard();
        } else {
          newRoute = `/admin/listings/groups/${selectedGroup.group_id}/${sectionKey}/${cardKey}/boarding_pass`;
        }
        break;

      case "local_services":
      default:
        break;
    }
    if (cardKey !== "boarding_pass" || item !== null) {
      saveSelectedItems(selectedGroup.group_id, {isGroup: true});
      history.replace(newRoute);
    }
  };

  const handleAddKeycard = () => {
    if (!availableExperiences.key) {
      dispatch(getExperiencePresets({experienceType: "key"}));
    }
    saveSelectedItems(selectedGroup.group_id, {isGroup: true});
    history.replace(
      `/admin/listings/groups/${selectedGroup.group_id}/cnf/boarding_pass`,
    );
  };

  const deleteElement = (deleteBtnLoading) => {
    if (!!deleteBtnLoading) {
      setDeleteButtonLoading((prev) => true);
    }
    dispatch(
      deleteExperience({
        experienceId: deleteParams.current.experienceId,
        updateId: updateSession?.update_id,
        onUpdate: (response) => {
          setUpdateSession(response.update_session);
        },
        onSuccess: (response) => {
          setIsDeleting((prev) => false);
          setDeleteButtonLoading((prev) => false);
          setUpdateSession(null);
          deleteParams.current.getDetails();
          deleteParams.current = null;
          saveSelectedItems(selectedGroup.group_id, {isGroup: true});
          history.replace(`/admin/listings/groups/${selectedGroup.group_id}`);
        },
        onError: () => {
          setIsDeleting((prev) => false);
          setDeleteButtonLoading((prev) => false);
        },
      }),
    );
  };

  const loadingModal = (
    <FullLoader
      open={!!loading || !!isDeleting}
      disableDismiss
      onClose={handleLoadingDialogClose}
      loadingText={isDeleting ? "Deleting verification settings" : loading}
    />
  );

  const confirmModal = !!updateSession && (
    <ConfirmDialog
      disableDismiss
      open={true}
      onClose={() => setUpdateSession(null)}
      title="Delete element?"
      message={
        <>
          {"Deleting this element will affect the following:"}
          {!!updateSession ? (
            <UpdateSession updates={updateSession.payload} />
          ) : (
            <Box
              mt={4}
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          )}
        </>
      }
      confirmLabel={
        deleteButtonLoading ? <ButtonLabelLoader variant="white" /> : "Confirm"
      }
      confirmDisabled={!updateSession}
      confirmAction={() => deleteElement(true)}
      cancelLabel="Cancel"
      cancelAction={() => {
        setUpdateSession(null);
        setIsDeleting((prev) => false);
      }}
    />
  );

  return (
    <div className={classes.root}>
      {confirmModal}
      <CustomCardHeader
        title={
          !!selectedGroup.name
            ? `${selectedGroup.name} guest journey`
            : "Listing group guest journey"
        }
        className={classes.header}
        action={
          <CloseIconButton disablePadding sm onClick={() => closeGroup()} />
        }
      />
      <div
        id={
          !!selectedGroup.name && !loadingGroups
            ? "gje-panel-container"
            : undefined
        }
        ref={containerRef}
        className={classes.content}
      >
        {loadingGroups ? (
          <EmptyListingGroup loading />
        ) : (
          <>
            {loadingModal}
            <ListingGroupSettings
              data={data}
              disableEdit={!!readOnly}
              loading={loading}
              setData={setData}
              resetData={resetData}
              setLoading={setLoading}
              closeGroup={closeGroup}
              selectedGroup={selectedGroup}
              edit={!!settingsOpen}
              onEdit={handleEdit}
            />
            {!settingsOpen && (
              <>
                <ProgressSummaryPanel
                  groupId={group_id}
                  data={selectedGroup}
                  collapsed={collapsed}
                  handleSetupAction={(item) =>
                    handleSetupAction(item.card_key, item.section_key, null)
                  }
                  containerRef={progressContainerRef}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
