import React from "react";
import {useSelector} from "react-redux";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
// UI
import {ListSubheader, MenuItem} from "@material-ui/core";
import useExperienceActionsStyles from "styles/useExperienceActionsStyles";
// Custom
import FilledSelect from "core/selects/FilledSelect";
// Utils
import usePrevious from "hooks/usePrevious";
import _ from "lodash";

export default function ProductTriggerSelector({
  value,
  onChange,
  type = "fees_upsells",
  label = null,
}) {
  const location = useLocation();
  const matchGroupId = useRouteMatch("/admin/listings/groups/:group_id");
  const matchListingIdSection = useRouteMatch(
    "/admin/listings/:listing_id/:section",
  );
  const matchListingId = useRouteMatch("/admin/listings/:listing_id");
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const listings = useSelector((state) => state.defaultReducer.house_data_dict);

  const {enabled_fees, enabled_upsells} = React.useMemo(() => {
    if (!!matchGroupId?.params?.group_id) {
      let group = listingGroups[matchGroupId.params.group_id];
      return {
        enabled_fees:
          group?.guest_journey?.sections?.cnf?.cards?.fees?.data || [],
        enabled_upsells:
          group?.guest_journey?.sections?.pci?.cards?.upsells?.data || [],
      };
    } else if (!!matchListingId?.isExact || matchListingIdSection?.isExact) {
      let listing = listings[matchListingId.params.listing_id];
      return {
        enabled_fees: listing?.listing_content?.fees || [],
        enabled_upsells: listing?.listing_content?.upsells || [],
      };
    } else {
      return {
        enabled_fees: [],
        enabled_upsells: [],
      };
    }
  }, [location]);

  const prevValue = usePrevious(value);
  const classes = useExperienceActionsStyles();
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );

  const {allOptions, feeOptions, upsellOptions} = React.useMemo(() => {
    let newFees = enabled_fees
      ?.filter(
        (fee, index) =>
          enabled_fees
            .map((f) => f.properties.product_type)
            .indexOf(fee.properties.product_type) == index,
      )
      ?.map((fee) => ({
        key: fee?.properties?.product_type,
        label: fee?.properties?.product_type,
      }));
    let newUpsells = enabled_upsells
      ?.filter(
        (upsell, index) =>
          enabled_upsells
            .map((u) => u.properties.product_type)
            .indexOf(upsell.properties.product_type) == index,
      )
      ?.map((upsell) => ({
        key: upsell?.properties?.product_type,
        label: upsell?.properties?.product_type,
      }));
    const all = [
      {key: "ALL", label: "Any Product"},
      ...newFees.concat(newUpsells),
    ];
    return {
      allOptions: all,
      feeOptions: [{key: "ALL", label: "Any Fee"}, ...newFees],
      upsellOptions: [{key: "ALL", label: "Any Upsell"}, ...newUpsells],
    };
  }, [user_profile]);

  React.useEffect(() => {
    if (_.isEqual(prevValue, value)) {
      return;
    }
    if (!!value.key && !value.label) {
      onChange({
        key: value.key,
        label: allOptions.find((opt) => opt.key === value.key)?.label || "",
      });
    }
  }, [value]);

  const handleChange = (e) => {
    const val = e.target.value;
    onChange({
      key: val,
      label:
        (type === "fees"
          ? feeOptions
          : type === "upsells"
            ? upsellOptions
            : allOptions
        ).find((opt) => opt.key === val)?.label || "",
    });
  };

  function getFeeItems() {
    return feeOptions.map((opt) => (
      <MenuItem
        key={opt.key}
        value={opt.key}
        selected={value.key === opt.key}
        classes={{selected: classes.selected}}
      >
        {opt.label}
      </MenuItem>
    ));
  }

  function getUpsellItems() {
    return upsellOptions.map((opt) => (
      <MenuItem
        key={opt.key}
        value={opt.key}
        selected={value.key === opt.key}
        classes={{selected: classes.selected}}
      >
        {opt.label}
      </MenuItem>
    ));
  }

  function getAllProductItems() {
    return allOptions.map((opt) => (
      <MenuItem
        key={opt.key}
        value={opt.key}
        selected={value.key === opt.key}
        classes={{selected: classes.selected}}
      >
        {opt.label}
      </MenuItem>
    ));
  }

  function getContent() {
    switch (type) {
      case "fees":
        return getFeeItems();
      case "upsells":
        return getUpsellItems();
      case "fees_upsells":
        return getAllProductItems();
      default:
        return [
          <ListSubheader
            key="fees_subheader"
            disableSticky
            color="primary"
            className={classes.listSubheader}
          >
            {"Fees"}
          </ListSubheader>,
          getFeeItems(),
          <ListSubheader
            key="upsells_subheader"
            disableSticky
            color="primary"
            className={classes.listSubheader}
          >
            {"Upsells"}
          </ListSubheader>,
          getUpsellItems(),
        ];
    }
  }

  return (
    <FilledSelect
      fullWidth
      label={label}
      value={value.key}
      onChange={handleChange}
      className={classes.inlineInput}
    >
      <MenuItem disabled value="">
        {"Select a value"}
      </MenuItem>
      {getContent()}
    </FilledSelect>
  );
}
