import React from "react";
// UI
import {Col, Row} from "reactstrap";
import {Button, Collapse, MenuItem, Paper, Typography} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import DeviceEventIcon from "@material-ui/icons/NotificationImportant";
import useExperienceActionsStyles from "styles/useExperienceActionsStyles";
// Custom
import FilledTextField from "core/inputs/FilledTextField";
import FilledSelect from "core/selects/FilledSelect";
import PrimaryButton from "core/buttons/PrimaryButton";
import EditIconButton from "core/buttons/EditIconButton";
import TriggerValueSelector from "../../../MultiOption/TriggerValueSelector";
// Utilities
import {allTriggers, deviceTriggerLabels} from "configuration/constants";
import {capitalize} from "utilities/helperFunctions";
import {triggerParams} from "configuration/specs";
import usePrevious from "hooks/usePrevious";
import clsx from "clsx";

const triggerTypeOptions = Object.keys(triggerParams);

export default function Trigger({
  experience,
  triggerType,
  data,
  disableEdit,
  setTriggerType,
  setData,
  setTriggerEdit,
  resetSelectedTrigger,
  viewOnly,
  listing,
}) {
  const styleProps = {filled: true};
  const isEditing = React.useRef(false);
  const isDifferentExperience = React.useRef(false);
  const classes = useExperienceActionsStyles(styleProps);
  // State
  const [edit, setEdit] = React.useState(false);
  const [timeOffset, setTimeOffset] = React.useState({
    value: 0,
    unit: "days",
    moment: "when",
  });
  const [showOffset, setShowOffset] = React.useState(false);
  const [triggerValue, setTriggerValue] = React.useState({key: "", label: ""});
  const [trigger, setTrigger] = React.useState("");
  // General
  const prevExperience = usePrevious(experience);
  const saveDisabled =
    !trigger ||
    (allTriggers[trigger]?.hasOwnProperty("trigger_value") &&
      !triggerValue.key);

  const triggerText = React.useMemo(() => {
    return allTriggers[trigger]?.text || allTriggers[trigger]?.name || "";
  }, [triggerType, trigger]);

  const selectedTriggerTypeOptions = React.useMemo(() => {
    if (!!triggerParams[triggerType]) {
      return triggerParams[triggerType].map((t) => t.trigger);
    } else {
      return [];
    }
  }, [triggerType]);

  React.useEffect(() => {
    setTriggerEdit(edit);
  }, [edit]);

  React.useEffect(() => {
    if (
      !!prevExperience &&
      prevExperience.experience_id !== experience?.experience_id
    ) {
      isDifferentExperience.current = true;
    }
  }, [experience]);

  React.useEffect(() => {
    resetData();
  }, [data]);

  React.useEffect(() => {
    if (
      triggerType !== "guest" ||
      (!!trigger && !["cin", "out"].includes(trigger))
    ) {
      if (timeOffset.moment === "before") {
        setTimeOffset((prev) => ({...prev, moment: "when"}));
        setShowOffset((prev) => false);
      }
    }
  }, [triggerType, trigger]);

  const triggerIcons = {
    guest: <PersonIcon className={clsx(classes.iconPreview, "-iconLarge")} />,
    host: <PersonIcon className={clsx(classes.iconPreview, "-iconLarge")} />,
    device: <DeviceEventIcon className={clsx(classes.iconPreview, "-icon")} />,
  };

  const resetData = (cancel) => {
    const newTimeOffset = data?.time_offset ?? 0;
    const newMoment = isEditing.current
      ? timeOffset.moment
      : newTimeOffset < 0
        ? "before"
        : newTimeOffset > 0
          ? "after"
          : "when";
    const {offsetValue, offsetUnit} = getOffsetParams(newTimeOffset);

    if (cancel || (!trigger && !data?.trigger)) {
      setEdit((prev) => false);
    } else if (isDifferentExperience.current) {
      isDifferentExperience.current = false;
      setEdit((prev) => false);
    }

    setTrigger((prev) => data?.trigger ?? "");
    setTimeOffset((prev) => ({
      value: offsetValue,
      unit: offsetUnit,
      moment: newMoment,
    }));
    setShowOffset((prev) => newMoment !== "when");
    setTriggerValue((prev) => ({
      key: data?.trigger_value ?? "",
      label: data?.trigger_value === prev.key && !!prev.label ? prev.label : "",
    }));
  };

  const getOffsetParams = (time_offset) => {
    const time = Math.abs(time_offset);
    let offsetValue = 0;
    let offsetUnit = "days";

    if (time === 0 || time % 86400 === 0) {
      offsetUnit = "days";
      offsetValue = time / 86400;
    } else if (time % 3600 === 0) {
      offsetUnit = "hours";
      offsetValue = time / 3600;
    } else if (time % 60 === 0) {
      offsetUnit = "minutes";
      offsetValue = time / 60;
    } else {
      offsetUnit = "seconds";
      offsetValue = time;
    }

    return {offsetValue, offsetUnit};
  };

  const onEdit = () => {
    isEditing.current = true;
    setEdit((prev) => true);
  };

  const handleTypeChange = (e) => {
    const val = e.target.value;
    isEditing.current = true;
    const hasOneOption = triggerParams[val]?.length === 1;
    setTrigger((prev) =>
      val !== prev ? (hasOneOption ? triggerParams[val][0].trigger : "") : prev,
    );
    setTriggerType(val);
    setTriggerValue((prev) => ({key: "", label: ""}));
  };

  const handleTriggerChange = (e) => {
    const val = e.target.value;
    isEditing.current = true;
    setTrigger((prev) => val);
    setTriggerValue((prev) => ({key: "", label: ""}));
  };

  const handleTriggerValueChange = (val) => {
    isEditing.current = true;
    setTriggerValue((prev) => val);
  };

  const handleTimeOffsetChange = (field) => (e) => {
    const val = e.target.value;
    isEditing.current = true;
    if (field === "moment") {
      setShowOffset((prev) => val !== "when");
    }
    setTimeOffset((prev) => ({...prev, [field]: val}));
  };

  const onCancel = () => {
    isEditing.current = false;
    resetSelectedTrigger();
    resetData(true);
  };

  const onSave = () => {
    isEditing.current = false;
    setEdit((prev) => false);
    const newData = !!data
      ? {...data, trigger: trigger}
      : {trigger: trigger, conditions: []};
    if (!!triggerValue.key) {
      newData.trigger_value = triggerValue.key;
    }
    if (!showOffset || timeOffset.value === 0) {
      newData.time_offset = 0;
    } else {
      let newValue =
        timeOffset.unit === "days"
          ? timeOffset.value * 86400
          : timeOffset.unit === "hours"
            ? timeOffset.value * 3600
            : timeOffset.unit === "minutes"
              ? timeOffset.value * 60
              : timeOffset.value;
      newData.time_offset =
        timeOffset.moment === "before" ? newValue * -1 : newValue;
    }
    setData(newData);
  };

  if (!triggerType) {
    return null;
  }
  return (
    <>
      <Collapse in={edit} timeout={{appear: 0, enter: 150, exit: 150}}>
        <Paper elevation={0} className={classes.paper}>
          <div
            className={classes.card}
            style={{paddingLeft: 0, paddingRight: 0}}
          >
            <Row className={classes.triggerRow}>
              {!!showOffset && (
                <Col
                  xs={12}
                  lg={12}
                  className={clsx("mb-2", classes.triggerCol, "-offset-col")}
                >
                  <div className={classes.row}>
                    <FilledTextField
                      placeholder="0"
                      value={timeOffset.value}
                      type="number"
                      inputProps={{min: 0}}
                      className={clsx(
                        classes.inlineInput,
                        classes.inputNumber,
                        "-bbrr0 -btrr0",
                      )}
                      onChange={handleTimeOffsetChange("value")}
                    />
                    <FilledSelect
                      fullWidth
                      value={timeOffset.unit}
                      className={classes.inlineInput}
                      onChange={handleTimeOffsetChange("unit")}
                      style={{
                        borderBottomLeftRadius: 0,
                        borderTopLeftRadius: 0,
                      }}
                    >
                      {["seconds", "minutes", "hours", "days"].map((t) => (
                        <MenuItem
                          key={t}
                          value={t}
                          selected={timeOffset.unit === t}
                          classes={{selected: classes.selected}}
                        >
                          {t}
                        </MenuItem>
                      ))}
                    </FilledSelect>
                  </div>
                </Col>
              )}
              <Col
                xs={12}
                lg={6}
                className={clsx("mb-2", classes.triggerCol, {
                  "-with-offset": showOffset,
                })}
              >
                <FilledSelect
                  fullWidth
                  value={timeOffset.moment}
                  className={classes.inlineInput}
                  onChange={handleTimeOffsetChange("moment")}
                  style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                >
                  <MenuItem
                    value={"when"}
                    selected={timeOffset.moment === "when"}
                    classes={{selected: classes.selected}}
                  >
                    {"when"}
                  </MenuItem>
                  <MenuItem
                    value={"after"}
                    selected={timeOffset.moment === "after"}
                    classes={{selected: classes.selected}}
                  >
                    {"after"}
                  </MenuItem>
                  {triggerType === "guest" &&
                    ["cin", "out"].includes(
                      !!trigger ? trigger : data?.trigger,
                    ) && (
                      <MenuItem
                        value={"before"}
                        selected={timeOffset.moment === "before"}
                        classes={{selected: classes.selected}}
                      >
                        {"before"}
                      </MenuItem>
                    )}
                </FilledSelect>
              </Col>
              <Col
                xs={12}
                lg={6}
                className={clsx("mb-2", classes.triggerCol, {
                  "-with-offset": showOffset,
                })}
              >
                <FilledSelect
                  fullWidth
                  value={triggerType}
                  className={classes.inlineInput}
                  onChange={handleTypeChange}
                >
                  <MenuItem disabled value="">
                    Select a type
                  </MenuItem>
                  {triggerTypeOptions.map((tt) => (
                    <MenuItem
                      key={tt}
                      value={tt}
                      classes={{selected: classes.selected}}
                    >
                      {capitalize(tt)}
                    </MenuItem>
                  ))}
                </FilledSelect>
              </Col>
              {selectedTriggerTypeOptions.length > 1 && (
                <Col
                  xs={12}
                  lg={12}
                  className={clsx("mb-2 -trigger-value", classes.triggerCol, {
                    "-with-offset": showOffset,
                  })}
                >
                  <FilledSelect
                    fullWidth
                    title={triggerText}
                    value={trigger}
                    className={classes.inlineInput}
                    onChange={handleTriggerChange}
                  >
                    <MenuItem disabled value="">
                      Select a trigger
                    </MenuItem>
                    {selectedTriggerTypeOptions.map((tk) => (
                      <MenuItem
                        key={tk}
                        value={tk}
                        selected={trigger === tk}
                        classes={{selected: classes.selected}}
                      >
                        {allTriggers[tk]?.text || allTriggers[tk]?.name || ""}
                      </MenuItem>
                    ))}
                  </FilledSelect>
                </Col>
              )}
              {!!allTriggers[trigger]?.trigger_value && (
                <Col
                  xs={12}
                  lg={12}
                  className={clsx("mb-2 -trigger-value", classes.triggerCol, {
                    "-with-offset": showOffset,
                  })}
                >
                  <TriggerValueSelector
                    type={allTriggers[trigger].trigger_value}
                    value={triggerValue}
                    onChange={handleTriggerValueChange}
                    listing={listing}
                  />
                </Col>
              )}
            </Row>
            <div className={classes.actions}>
              <Button
                className="mr-2"
                classes={{label: classes.cancelBtn}}
                size="small"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <PrimaryButton
                size="small"
                label="Done"
                style={{marginRight: 16}}
                disabled={saveDisabled}
                onClick={onSave}
              />
            </div>
          </div>
        </Paper>
      </Collapse>
      <Collapse in={!edit} timeout={{appear: 0, enter: 150, exit: 50}}>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.selectedOption}>
            <Typography
              className={clsx(classes.title, classes.flexGrow, classes.row)}
              component="span"
            >
              {triggerIcons[triggerType]}
              {timeOffset.value !== 0
                ? `${timeOffset.value} ${timeOffset.value === 1 ? timeOffset.unit.slice(0, -1) : timeOffset.unit} ${timeOffset.moment} `
                : "When "}
              {`${triggerType} `}
              {!!allTriggers[trigger]?.trigger_value && !!triggerValue
                ? trigger === "dve"
                  ? deviceTriggerLabels[triggerValue.key]
                  : `${triggerText} for '${triggerValue.label}'`
                : triggerText}
            </Typography>
            {!viewOnly && (
              <EditIconButton
                disablePadding
                disabled={disableEdit}
                onClick={disableEdit ? () => null : onEdit}
              />
            )}
          </div>
        </Paper>
      </Collapse>
    </>
  );
}
