import React from "react";
import CustomDialog from "../../../../core/dialogs/CustomDialog";

const ImageDisplayDialog = ({
  open,
  onClose,
  title = "Select an Image",
  children,
}) => {
  return (
    <CustomDialog
      title={title}
      fullWidth={true}
      open={open}
      onClose={onClose}
      maxWidth={"lg"}
      content={children}
      labelConfirm="Close"
      actionConfirm={onClose}
    />
  );
};

export default ImageDisplayDialog;
