import React from "react";
import {lighten, withStyles} from "@material-ui/core/styles";
import GenericSelectionInput from "../../input/GenericSelectionInput";
import {
  Checkbox,
  ListItemAvatar,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecord";
import {BOOKING_STATUS_COLORS, THEME} from "configuration/settings";
import {bookingStatusLabels} from "configuration/constants";
import BaseFieldComponent from "../BaseFieldComponent";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";

const styles = (theme) => ({
  listItemIcon: {
    minWidth: 0,
    padding: 0,
  },
  tooltipIcon: {
    width: 10,
    height: 10,
    marginRight: theme.spacing(1),
  },
});

const bookingOptions = Object.entries(bookingStatusLabels).map(
  ([key, value]) => ({
    key,
    label: value,
  }),
);

const colors = Object.fromEntries(
  Object.entries(BOOKING_STATUS_COLORS).map(([key, color]) => [
    key,
    {color, bg: lighten(color, 0.85)},
  ]),
);

class BookingStatusField extends BaseFieldComponent {
  renderInput({fieldData, handleValueChange, classes}) {
    return (
      <GenericSelectionInput
        options={bookingOptions.map((opt) => opt.key)}
        enableSearch={true}
        edit={true}
        value={fieldData.val}
        multiselect={fieldData?.operator !== "is_not"}
        onValueChange={(newValue) => {
          handleValueChange(
            fieldData.type,
            Array.isArray(newValue)
              ? newValue.filter((f) => f !== "")
              : newValue,
          );
        }}
        label="Choose Booking Status"
        customMenuItem={(option, selectedOptions) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={selectedOptions.includes(option)} />
            <ListItemAvatar className={classes.listItemIcon}>
              <DotIcon
                className={classes.tooltipIcon}
                style={{
                  color: colors[option]?.color ?? THEME.primary,
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                bookingOptions.find((o) => o.key === option)?.label || option
              }
            />
          </MenuItem>
        )}
      />
    );
  }

  renderDisplay({fieldData, classes}) {
    return (
      <div>
        {fieldData.val.map((value) => (
          <DotIcon
            key={value}
            style={{
              color: colors[value]?.color ?? THEME.primary,
            }}
          />
        ))}
      </div>
    );
  }

  renderText({value}) {
    return getDefaultFieldLabel(value);
  }
}

export default withStyles(styles)(BookingStatusField);
