import _ from "lodash";
import {v4 as uuidv4} from "uuid";
import {
  channelEmails,
  maxImageSize,
  rangeFilters,
} from "configuration/constants.js";
import defaultHeaderBG from "assets/img/product_header_bg.jfif";
import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addSeconds,
  addYears,
  differenceInCalendarDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isSameDay as isTheSameDay,
  isWithinInterval,
  set,
  startOfDay,
  sub,
} from "date-fns";
import {addonServices} from "configuration/enums";

const millisecondsInADay = 86400000;

export function isValidDate(date) {
  const d = new Date(date);
  return !isNaN(d);
}

export const isAddressValid = (address, showErrors = false) => {
  if (!address || !address?.lng) {
    return false;
  }
  let hasCity = !!address.city;
  let hasCountry = !!address.country;
  let hasStreet = !!address.street;
  let hasState = !!address.state;
  let hasZipCode = !!address.zip_code;
  let isValid = hasCity && hasCountry && hasStreet && hasState && hasZipCode;
  if (!showErrors) {
    return isValid;
  } else {
    return {
      valid: isValid,
      errors: {
        city: !hasCity,
        country: !hasCountry,
        street: !hasStreet,
        state: !hasState,
        zip_code: !hasZipCode,
      },
    };
  }
};

export function convertTextToHTML(text) {
  if (!!text) {
    let result = "";
    text.split("\n").forEach((t) => {
      result += !t ? "<br>" : `<p>${t}</p>`;
    });
    return result;
  } else {
    return "";
  }
}

export function gradientRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},1)`;
}

export function generateEmail(name) {
  return name.replace(/[^A-Z0-9]/gi, "").toLowerCase() + "@ensoconnectmail.com";
}

export function replaceFields(field, guest, booking) {
  if (!booking) {
    return false;
  }
  field = field
    .replace("<guest_name>", guest.name)
    .replace("<listing_name>", booking.listing)
    .replace("<assigned_manager_name>", guest.assigned_to);
  return field;
}

export function listString(list) {
  let last_item = list.pop();
  return `${list.join(", ")}${!!list.length && " & "}${last_item}`;
}

export function replaceEmtpyTags(text) {
  let newText = text
    .replace("<guest_phone>", "No Phone")
    .replace("<guest_email>", "No Email")
    .replace("<reply_phone>", "No Phone")
    .replace("<reply_email>", "No Email");
  return newText;
}

export function stripHtmlTags(str) {
  if (!str) {
    return "";
  }
  return str
    .replace(/<\/p>/gi, " ")
    .replace(/<br\s*\/?>/gi, "\n")
    .replace(/<\/?[^>]+(>|$)/g, "");
}

export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateNonEmptyHTML(html) {
  const re = /^(<p><br><\/p>|<p>(\s)*<\/p>)*$/;
  return !re.test(html);
}

export function validateFile(file) {
  if (!file) return {isValid: false, error: "no file"};
  let isValid = true;
  let error = "";
  const re = /^image\/.+$/;
  const maxSize = 1048576 * maxImageSize; // 1 MB (bytes) * maxSize (MB)
  if (!re.test(file.type)) {
    isValid = false;
    error = "Invalid file. Only images are allowed";
  } else if (file.size > maxSize) {
    isValid = false;
    error = `${file.name} exceeds the maximum upload size (${maxImageSize}MB)`;
  }
  return {isValid, error};
}

export function isHTMLEmpty(html) {
  if (!html) {
    return true;
  }
  const htmlWithoutSpaces = html
    .replace(/<style>.*<\/style>/g, "")
    .replace(/<(.|n)*?>/g, "")
    .trim();
  return !htmlWithoutSpaces.length;
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

export function capitalize(text, splitChar = " ") {
  if (!text) {
    return "";
  }
  return text
    .split(splitChar)
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(" ");
}

export function getAbsoluteUrl(url) {
  if (url.startsWith("https://") || url.startsWith("https://")) {
    return url;
  } else {
    return `https://${url}`;
  }
}

export function getVideoLink(url) {
  if (url.includes("youtube") || url.includes("youtu.be")) {
    let videoUrl = "https://www.youtube.com/embed/";
    let videoID = "";
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    videoID = match ? match[1] : null;
    return videoUrl + videoID;
  } else return url;
}

export function getSender(sender) {
  switch (sender) {
    case "user":
      return "Host";
    case "other_user":
      return "Guest";
    case "automessage":
      return "Automessage";
    case "chatbot":
      return "Chatbot";
    case "notification":
      return "Notification";
    default:
      return sender?.split("_").join(" ");
  }
}

export function getEmailContentWrapper(html) {
  if (html.startsWith("<!DOCTYPE html PUBLIC")) {
    return html;
  } else
    return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">\
  <html>\
    <head>\
      <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />\
      <link rel="stylesheet" href="https://unpkg.com/react-quill@1.3.3/dist/quill.snow.css">\
      <style>.ql-editor img {max-width: 100%;}</style>\
    </head>\
    <body><div class="ql-editor">${html}</div></body>\
  </html>`;
}

export function getAffectedInboxArray(
  guest,
  tab,
  defaultInbox,
  usePriorityGuest,
) {
  let newInboxArr = [];
  newInboxArr.push(guest.guest_type ?? "guest");
  if (guest.tags.includes("inquiry")) {
    newInboxArr.push("inquiry");
  }
  if (!!usePriorityGuest) {
    let isPriorityGuest =
      !!guest.unanswered_messages || !!guest.unread_messages;
    if (!isPriorityGuest) {
      return defaultInbox;
    } else {
      newInboxArr.push(defaultInbox);
    }
  }

  return newInboxArr.filter((inb) => inb === "all" || inb !== tab);
}

export function isChannelAvailable(chnnl, guest) {
  if (!guest) {
    return false;
  }
  let isAvailable =
    guest.channels?.includes(chnnl) &&
    !!guest.emails?.find((e) => e.email.includes(channelEmails[chnnl]));
  return isAvailable;
}

/**
 * Generates a random number within a range
 * @param {Number} min Min. number (included)
 * @param {Number} max Max. number (excluded)
 * @param {Boolean} returnRaw If true, returns the floating point number. By default, returns an integer using Math.floor()
 * @returns Random number
 */
export const getRandomNumber = (min, max, returnRaw) => {
  if (returnRaw) {
    return Math.random() * (max - min) + min;
  } else {
    return Math.floor(Math.random() * (max - min)) + min;
  }
};

// *********** TIME ***********
export const setStartOfDay = (date) =>
  set(date, {hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
export const setEndOfDay = (date) =>
  set(date, {hours: 23, minutes: 59, seconds: 59, milliseconds: 999});
export const isSameDay = (dt1, dt2) => differenceInCalendarDays(dt1, dt2) === 0;
export const isWithinRange = (date, range) =>
  isWithinInterval(date, {start: range[0], end: range[1]});
export const isWithinRanges = (date, ranges) =>
  ranges.some((range) => isWithinRange(date, range));
export const getElapsedDays = (date) => {
  const timestamp = date.getTime();
  const offset = date.getTimezoneOffset();
  return parseInt((timestamp - offset * 6000) / millisecondsInADay);
};

export const getUTCTimestamp = (timestamp) => {
  return timestamp - new Date(timestamp).getTimezoneOffset() * 60000;
};

export const get12hrStringFormat = (time = "00:00") => {
  return format(new Date(`${new Date().toDateString()} ${time}`), "hh:mm a");
};

export const getGreeting = () => {
  const now = new Date();
  const morningRange = [
    set(now, {hours: 0, minutes: 0, seconds: 0, milliseconds: 0}),
    set(now, {hours: 11, minutes: 59, seconds: 59, milliseconds: 999}),
  ];
  const afternoonRange = [
    set(now, {hours: 12, minutes: 0, seconds: 0, milliseconds: 0}),
    set(now, {hours: 17, minutes: 59, seconds: 59, milliseconds: 999}),
  ];
  if (isWithinRange(now, morningRange)) {
    return "morning";
  } else if (isWithinRange(now, afternoonRange)) {
    return "afternoon";
  } else {
    return "evening";
  }
};

export function getDateFromTimeStamp(timeStamp) {
  if (timeStamp.toString().length === 10) timeStamp = timeStamp * 1000;
  const newDate = new Date(timeStamp);
  const dateObj = {
    full: format(newDate, "PPp"),
    monthDay: format(newDate, "MMM dd"),
    partialDate: format(newDate, "PP"),
    fullDate: format(newDate, "eee, PP"),
    fullTime: format(newDate, "p"),
  };
  return dateObj;
}

export function getDateFromInterval(amount, interval) {
  const now = new Date();
  switch (interval) {
    case "year":
    case "years":
      return addYears(now, amount);
    case "month":
    case "months":
      return addMonths(now, amount);
    case "day":
    case "days":
      return addDays(now, amount);
    case "hour":
    case "hours":
      return addHours(now, amount);
    case "minute":
    case "minutes":
      return addMinutes(now, amount);
    case "second":
    case "seconds":
      return addSeconds(now, amount);

    default:
      return null;
  }
}

export function getLabelsFromDateRange(
  count,
  interval,
  initialDate,
  caps,
  start,
) {
  let labels = [];
  let formatFn = null;
  let subFn = null;
  let endDate = initialDate;
  switch (interval) {
    case "day":
      initialDate = sub(initialDate, {days: start});
      endDate = addHours(startOfDay(initialDate), count);
      subFn = (date, amount) => sub(date, {hours: amount});
      formatFn = (date) => format(date, "haaa");
      break;
    case "week":
      initialDate = sub(initialDate, {weeks: start});
      endDate = addDays(endOfWeek(endOfDay(initialDate)), 1);
      subFn = (date, amount) => sub(date, {hours: amount});
      formatFn = (date) => format(date, "E d");
      break;
    case "month":
      initialDate = sub(initialDate, {months: start});
      endDate = addDays(endOfMonth(initialDate), 1);
      subFn = (date, amount) => sub(date, {days: amount});
      formatFn = (date) => format(date, "MMM d");
      break;
    case "year":
      initialDate = sub(initialDate, {years: start});
      endDate = endOfYear(initialDate);
      subFn = (date, amount) => sub(date, {weeks: amount});
      formatFn = (date) => format(date, "MMM");
      break;
  }

  for (let i = count; i >= 0; --i) {
    let newDate = subFn(endDate, i);
    const newLabel = formatFn(newDate.getTime());
    labels.push(caps ? newLabel.toUpperCase() : newLabel);
  }

  return labels;
}

export function getTimeFromString(time) {
  return new Date(`${new Date().toDateString()} ${time}`);
}

//Timur Carpeev https://stackoverflow.com/questions/6108819/
export function timeSince(timeStamp, agoWord) {
  if (timeStamp.toString().length === 13)
    timeStamp = parseInt(timeStamp / 1000);
  let now = new Date();
  let secondsPast = parseInt(now.getTime() / 1000 - timeStamp);
  if (secondsPast < 0 || secondsPast > 172800) {
    // 48 hours
    const dateObj = getDateFromTimeStamp(timeStamp);
    const isSameYear = new Date(timeStamp).getFullYear() === now.getFullYear();
    return isSameYear ? dateObj.monthDay : dateObj.partialDate;
  } else if (secondsPast < 60) {
    return parseInt(secondsPast) + `s ${agoWord && "ago"}`;
  } else if (secondsPast < 3600) {
    return parseInt(secondsPast / 60) + `min ${agoWord && "ago"}`;
  } else {
    return parseInt(secondsPast / 3600) + `h ${agoWord && "ago"}`;
  }
}

export function relativeTime(timeStamp) {
  if (timeStamp.toString().length === 13)
    timeStamp = parseInt(timeStamp / 1000);
  let now = new Date();
  let secondsPast = parseInt(now.getTime() / 1000 - timeStamp);
  if (secondsPast < 60) {
    return parseInt(secondsPast) + "s";
  }
  if (secondsPast < 3600) {
    return parseInt(secondsPast / 60) + "min";
  }
  if (secondsPast <= 86400) {
    return parseInt(secondsPast / 3600) + "h";
  }
  return parseInt(secondsPast / 86400) + "d";
}

export function timeElapsed(time) {
  if (time < 60) {
    return parseInt(time) + "s";
  }
  if (time < 3600) {
    return parseInt(time / 60) + "min";
  }
  if (time <= 86400) {
    return parseInt(time / 3600) + "h";
  }
  return parseInt(time / 86400) + "d";
}

export function hoursMinSeconds(milliseconds) {
  let totalSeconds = milliseconds / 1000;
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = Math.floor(totalSeconds % 60);

  return minutes ? `${hours && hours + "h"}${minutes}min` : `${seconds}s`;
}

export function messageTime(timestamp, dateType = false) {
  const now = new Date();
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const timestampDate = new Date(timestamp);
  const dateObj = getDateFromTimeStamp(timestamp);
  const formatted =
    now.setHours(0, 0, 0, 0) === timestampDate.setHours(0, 0, 0, 0)
      ? "Today"
      : yesterday.setHours(0, 0, 0, 0) === timestampDate.setHours(0, 0, 0, 0)
        ? "Yesterday"
        : dateObj.fullDate;

  switch (dateType) {
    case "only_time":
      return dateObj.fullTime;
    case "only_date":
      return dateObj.partialDate;
    case "formatted":
      return formatted;

    case "full_date":
    default:
      return dateObj.full;
  }
}

export function formatLength(str, l) {
  if (str.length > l) {
    return `${str.slice(0, l)}...`;
  } else {
    return str;
  }
}

export function searchRows(rows, searchKeys, searchInput = "", filters = []) {
  let filteredRows = [];
  let emptySearch = searchInput === "";
  let emptyFilters = filters.length === 0;

  if (emptySearch && emptyFilters) {
    return rows;
  }

  filteredRows = rows.filter((row) => {
    const reducer = (accum, current) => {
      let keys = current.split(".");
      return (
        accum + (keys.length > 1 ? row[keys[0]][keys[1]] : row[current]) + ""
      );
    };
    const row_str = searchKeys.reduce(reducer, "").toLowerCase();
    let discardRow = false;

    if (!emptySearch) {
      let searchList = searchInput.toLowerCase().split(" ");
      searchList.map((element) => {
        if (row_str.indexOf(element) === -1) {
          discardRow = true;
        }
      });
    }
    if (!emptyFilters) {
      filters.map((filter) => {
        if (filter.array) {
          if (!_.get(row, filter.path).includes(filter.value)) {
            discardRow = true;
          }
        } else {
          if (_.get(row, filter.path) !== filter.value) {
            discardRow = true;
          }
        }
      });
    }
    return !discardRow;
  });
  return filteredRows;
}

// Converts a string into color using saturation & lightness values
export function stringToHslColor(str, s, l) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let h = hash % 360;
  return `hsl(${h},${s}%,${l}%)`;
}

export function replaceURLs(text) {
  let urlValidator =
    /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi;
  let match = "",
    textArr = [],
    startInd = 0;
  let newText = replaceEmtpyTags(text);

  while ((match = urlValidator.exec(newText)) != null) {
    textArr.push({
      text: newText.substr(startInd, match.index - startInd),
      type: "text",
    });
    textArr.push({
      text: newText.substr(match.index, match[0].length),
      type: "link",
    });
    startInd = match.index + match[0].length;
  }
  if (startInd < newText.length)
    textArr.push({text: newText.substr(startInd), type: "text"});
  return textArr;
}

export function getGuestContactInfo(guest) {
  const hasEmail = !!guest?.email_destination;
  const guest_email = hasEmail ? guest.email_destination.split("#")[0] : "";
  const hasPhone = !!guest?.phone;
  const guest_phone = hasPhone ? guest.phone : "";
  return {guest_email: guest_email !== " " ? guest_email : "", guest_phone};
}

export function getRangeFilters(f) {
  const newRangeFilters = [];
  const valueIndex = rangeFilters[f.path].values.indexOf(f.value);
  const filterRanges = rangeFilters[f.path].ranges;
  const selectedRange = filterRanges[valueIndex];
  if (selectedRange.hasOwnProperty("from")) {
    const rangeFilter_from = {
      path: f.path,
      value: selectedRange.from,
      operator: ">=",
    };
    newRangeFilters.push(rangeFilter_from);
  }
  if (selectedRange.hasOwnProperty("to")) {
    const rangeFilter_to = {
      path: f.path,
      value: selectedRange.to,
      operator: "<=",
    };
    newRangeFilters.push(rangeFilter_to);
  }
  if (selectedRange.hasOwnProperty("equal")) {
    const equalFilter = {
      path: f.path,
      value: selectedRange.equal,
      operator: "==",
    };
    newRangeFilters.push(equalFilter);
  }
  if (selectedRange.hasOwnProperty("above")) {
    const aboveFilter = {
      path: f.path,
      value: selectedRange.above,
      operator: ">",
    };
    newRangeFilters.push(aboveFilter);
  }
  if (selectedRange.hasOwnProperty("below")) {
    const belowFilter = {
      path: f.path,
      value: selectedRange.below,
      operator: "<",
    };
    newRangeFilters.push(belowFilter);
  }

  return newRangeFilters;
}

export function getDateRangeFilters(f) {
  const newDateRangeFilters = [];
  let isValueArray = Array.isArray(f.value);
  let startDateFilter = {};
  let endDateFilter = {};
  if (isValueArray) {
    startDateFilter = {
      path: f.path,
      value: setStartOfDay(new Date(f.value[0])).getTime(),
      operator: ">",
    };
    endDateFilter = {
      path: f.path,
      value: setEndOfDay(new Date(f.value[1])).getTime(),
      operator: "<",
    };
    newDateRangeFilters.push(startDateFilter);
    newDateRangeFilters.push(endDateFilter);
  } else if (!!f.operator) {
    startDateFilter = {path: f.path, value: f.value, operator: f.operator};
    newDateRangeFilters.push(startDateFilter);
  } else {
    startDateFilter = {
      path: f.path,
      value: setStartOfDay(new Date(f.value)).getTime(),
      operator: ">",
    };
    endDateFilter = {
      path: f.path,
      value: setEndOfDay(new Date(f.value)).getTime(),
      operator: "<",
    };
    newDateRangeFilters.push(startDateFilter);
    newDateRangeFilters.push(endDateFilter);
  }
  return newDateRangeFilters;
}

export function getEncodedFilters(filters, encode = true) {
  const rangeFilterKeys = Object.keys(rangeFilters);
  const new_filters = [];
  filters.forEach((f) => {
    const isRangeFilter =
      rangeFilterKeys.includes(f.path) &&
      rangeFilters[f.path].values.includes(f.value);
    const isDateFilter = ["checkin_date", "checkout_date"].includes(f.path);
    const isAssignedToFilter = f.path === "assigned_to";

    if (isRangeFilter) {
      new_filters.push(...getRangeFilters(f));
    } else if (isDateFilter) {
      new_filters.push(...getDateRangeFilters(f));
    } else if (isAssignedToFilter) {
      const new_f = {
        path: f.path,
        value: f.value === "Unassigned" ? null : f.value,
      };
      new_filters.push(new_f);
    } else {
      const new_f = {path: f.path, value: f.value};
      if (!!f.operator) {
        new_f.operator = f.operator;
      }
      new_filters.push(new_f);
    }
  });
  if (encode) return encodeURIComponent(JSON.stringify(new_filters));
  return new_filters;
}

export function setFilterCells(houses, guests, bookings) {
  const hasHouses = houses && !!houses.length;
  const hasGuests = guests && !!guests.length;
  const hasBookings = bookings && !!bookings.length;
  const countries = [],
    states = [],
    cities = [],
    assigned_to = [],
    tags = [],
    airbnb_listing_id = [],
    guests_names = [],
    guests_listings = [],
    booking_guests = [],
    booking_listings = [];

  hasHouses &&
    houses.map((h) => {
      h.address.country && countries.push(h.address.country);
      h.address.state && states.push(h.address.state);
      h.address.city && cities.push(h.address.city);
      assigned_to.push(h.owner.name);
      h.airbnb_listing_id && airbnb_listing_id.push(h.airbnb_listing_id);
      let tags_list = h.tags;
      let hasTags = tags_list && tags_list.length > 0 && tags_list[0] !== " ";
      hasTags && tags_list.map((t) => tags.push(t));
    });

  hasGuests &&
    guests.map((g) => {
      !!g.name && guests_names.push(g.name);
      !!g.listings && guests_listings.push(g.listings);
    });

  hasBookings &&
    bookings.map((b) => {
      !!b.name && booking_guests.push(b.name);
      !!b.listing && booking_listings.push(b.listing);
    });

  return {
    countries: _.uniq(countries),
    states: _.uniq(states),
    cities: _.uniq(cities),
    assigned_to: _.uniq(assigned_to),
    tags: _.uniq(tags),
    airbnb_listing_id: _.uniq(airbnb_listing_id),
    guests_names: _.uniq(guests_names),
    guests_listings: _.uniq(guests_listings),
    booking_guests: _.uniq(booking_guests),
    booking_listings: _.uniq(booking_listings),
  };
}

export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function newUuid() {
  return uuidv4();
}

export const asyncLoop = async (arr = [], callback) => {
  for (let i = 0; i < arr.length; i++) {
    await callback(arr[i], i, arr);
  }
};

export function oneOf(array) {
  return array[Math.floor(Math.random() * Math.floor(array.length))];
}

export function getEnv() {
  return process.env.NODE_ENV;
}

export function getRandomColor(saturation = 80, lightness = 50) {
  const randomColor = `hsla(${Math.floor(Math.random() * 360)}, ${saturation}%, ${lightness}%, 1)`;
  return randomColor;
}

/**
 * Takes an object and returns the value of a nested field
 * @param {String} object Information from which to get the field
 * @param {Array} path The path of the field
 * @returns The value of the field
 */
export function getNestedObject(object, path) {
  let fieldValue = object;
  _.each(path, (field) => {
    if (!fieldValue[field]) {
      fieldValue = null;
      return false;
    } else {
      fieldValue = fieldValue[field];
    }
  });

  return fieldValue;
}

export function addArrayItemsToSet(array, setVariable) {
  _.each(array, (el) => setVariable.add(el));
}

export function getProductBGImage(type, product) {
  if (type === "fees") {
    return `https://enso-image-assets.s3.amazonaws.com/products/${product === "pet_fee" ? "pet.jpg" : "damage.jpeg"}`;
  } else {
    let url = "https://enso-image-assets.s3.amazonaws.com/products/";
    switch (product) {
      case "checkin":
        url += "checkin.jpg";
        break;
      case "checkout":
        url += "checkin.jpg";
        break;
      case "stocked_fridge":
        url += "fridge.png";
        break;
      case "mid_stay_clean":
        url += "clean.jpg";
        break;
      case "coffee_pods":
        url += "coffee.jpg";
        break;
      case "welcome_basket":
        url += "basket.jpeg";
        break;
      case "carbon_offset":
        url += "woods.jpg";
        break;
      case "tip_staff":
        url += "tip.jpg";
        break;
      case "parking_instructions":
        url += "parking.jpg";
        break;
      case "ac_heating":
        url += "air-conditioner.png";
        break;
      case "local_restaurants":
        url += "restaurants.png";
        break;

      default:
        url = null;
        break;
    }
    return url ?? defaultHeaderBG;
  }
}

export function selectDefaultChannel({
  guest,
  userProfile,
  guestPhone,
  guestEmail,
  lastGuestMessage,
  airbnbHidden,
  whatsappHidden = false,
  vrboHidden = false,
  homeAwayHidden = false,
  bookingComHidden = false,
  allListingsDisabled = false,
}) {
  if (allListingsDisabled) {
    return -1;
  }
  if (!!lastGuestMessage?.channel) {
    console.log(
      "[HelperFunctions - selectDefaultChannel] Using last guest message channel: ",
      lastGuestMessage.channel.toLowerCase(),
    );
    switch (lastGuestMessage.channel.toLowerCase()) {
      case "airbnb":
        if (!airbnbHidden) return 1;
        break;
      case "email":
      case "sms":
        if (!!guestPhone && userProfile?.connected_services?.includes("sms"))
          return 0;
        break;
      case "whatsapp":
        if (!whatsappHidden) return 3;
        break;
      case "vrbo":
        if (!vrboHidden) return 4;
        break;
      case "homeaway":
        if (!homeAwayHidden) return 5;
        break;
      case "booking_com":
        if (!bookingComHidden) return 6;
        break;
      default:
        return -1;
    }
  } else {
    console.log(
      "[HelperFunctions - selectDefaultChannel] Falling back to default channel logic.",
    );
    if (!airbnbHidden && guest.channels?.includes("Airbnb")) return 1;
    else if (!!guestPhone && userProfile?.connected_services?.includes("sms"))
      return 0;
    else if (!!guestEmail) return 2;
    else if (!vrboHidden && guest.channels?.includes("VRBO")) return 4;
    else if (!homeAwayHidden && guest.channels?.includes("Home Away")) return 5;
    else if (!bookingComHidden && guest.channels?.includes("Booking.com"))
      return 6;
    else return -1;
  }
}

export function isServiceEnabledFor({verifier, targets = [], serviceAccounts}) {
  let selectedService = null;
  _.each(Object.keys(addonServices), (addServKey) => {
    _.each(addonServices[addServKey], (addServ) => {
      if (addServ["Verifier"] === verifier) {
        selectedService = addServ;
        return false;
      }
    });
  });
  let provider = selectedService?.["Provider"];
  let subscriptionID = selectedService?.["Subscription ID"];
  if (
    !verifier ||
    !targets.length ||
    !provider ||
    !subscriptionID ||
    !serviceAccounts
  ) {
    return {enabled: false, service: selectedService};
  }
  let isEnabled = false;
  if (!!serviceAccounts.accounts[provider]?.[0]?.properties?.group_ids) {
    if (
      targets.every((tgt) =>
        serviceAccounts.accounts[provider][0].properties.group_ids[
          tgt
        ]?.includes(subscriptionID),
      )
    ) {
      isEnabled = true;
    }
  }
  return {enabled: isEnabled, service: selectedService};
}

export const orderBookings = (guest, bookings_dict, selectedGuestId) => {
  let isInquiry = false;
  let bkgId = guest.booking_id;
  if (guest.booking_status === "inquiry") {
    if (!!guest.bookings?.length) {
      const firstInquiry = guest.inquiries[guest.inquiries.length - 1];
      const firstBkg =
        bookings_dict.guest_id[selectedGuestId]?.[guest.bookings[0]];
      bkgId = guest.bookings[0];
      isInquiry =
        !isTheSameDay(firstInquiry?.checkin_raw, firstBkg?.checkin_raw) ||
        !isTheSameDay(firstInquiry?.checkout_raw, firstBkg?.checkout_raw);
    } else {
      isInquiry = true;
    }
  }

  const activeBooking = isInquiry
    ? guest.inquiries[guest.inquiries.length - 1]
    : bookings_dict.guest_id[selectedGuestId]?.[bkgId];
  const currBookingsObj = bookings_dict.guest_id[selectedGuestId] ?? {};
  const currBookingsArr =
    isInquiry || !activeBooking
      ? Object.values(currBookingsObj)
      : Object.values(currBookingsObj).filter((b) => b.booking_id !== bkgId);
  const currInquiries = isInquiry
    ? guest.inquiries.slice(0, guest.inquiries.length - 1) || []
    : guest.inquiries || [];
  const newBookings = _.orderBy(
    [...currBookingsArr, ...currInquiries],
    "updated_at",
    "desc",
  );
  if (!!activeBooking) {
    newBookings.unshift(activeBooking);
  }

  return newBookings;
};

export function searchCommand(str, commands = []) {
  let searchText = str
    .split("/")
    .at(-1)
    .split(/[\s\n]+/)[0]
    .toLowerCase()
    .substring(0);
  let filtered = commands
    .map((cmd) => {
      const titleIndex = cmd.title.toLowerCase().indexOf(searchText);
      const subheaderIndex = cmd.subheader.toLowerCase().indexOf(searchText);

      if (titleIndex !== -1 || subheaderIndex !== -1) {
        return {...cmd, titleIndex, subheaderIndex};
      }
      return null;
    })
    .filter((command) => command !== null);
  return {results: filtered, searchText};
}

export function getTrimmedCommandSubheader(subheader, index) {
  if (index === -1 || index <= 100) return subheader;
  const lastSpaceIndex = subheader.lastIndexOf(" ", index);
  return subheader.slice(lastSpaceIndex === -1 ? 0 : lastSpaceIndex).trim();
}

export function hyphenToCamelCase(str) {
  return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
}

export function getScheduledMsgsData(messages) {
  const messagesByBooking = {};
  _.each(messages, (msg) => {
    let bkgId = msg.objects?.booking?.booking_id;
    let triggerId = msg.trigger?.trigger_id ?? msg.id;
    if (!!messagesByBooking[bkgId]) {
      if (!!messagesByBooking[bkgId][triggerId]) {
        messagesByBooking[bkgId][triggerId].push(msg);
      } else {
        messagesByBooking[bkgId][triggerId] = [msg];
      }
    } else {
      messagesByBooking[bkgId] = {[triggerId]: [msg]};
    }
  });

  const msgGroups = [];
  _.each(Object.keys(messagesByBooking), (bId) => {
    _.each(Object.keys(messagesByBooking[bId]), (tId) => {
      const messageChannels = {};
      _.each(messagesByBooking[bId][tId], (schMsg) => {
        messageChannels[schMsg.action.action_id] = !!schMsg.meets_conditions;
      });
      msgGroups.push({
        channels: messageChannels,
        data: messagesByBooking[bId][tId],
      });
    });
  });

  return msgGroups;
}

export function buildGuestFilterUrl(filtersArray, columns) {
  const formattedFilters = filtersArray.map((filter) => ({
    id: filter.id,
    path: filter.path || filter.id,
    value: filter.value,
    type: filter.type || "select",
  }));

  const encodedFilters = encodeURIComponent(JSON.stringify(formattedFilters));
  const encodedColumns = encodeURIComponent(JSON.stringify(columns));

  return `?filters=${encodedFilters}&columns=${encodedColumns}`;
}

export function getElapsedTimeLabel(value) {
  const minutes = value / 60000;
  let num = 0;
  let unit = "";
  if (minutes === 0) {
    num = 0;
    unit = "minutes";
  } else if (minutes % 1440 === 0) {
    num = minutes / 1440;
    unit = `day${num === 1 ? "" : "s"}`;
  } else if (minutes % 60 === 0) {
    num = minutes / 60;
    unit = `hour${num === 1 ? "" : "s"}`;
  } else {
    num = minutes;
    unit = `minute${num === 1 ? "" : "s"}`;
  }

  return `${num} ${unit}`;
}

export function getDefaultFieldLabel(value) {
  if (value instanceof Array) {
    return value
      .map((v) => v.toString().toLowerCase().replace(/_/g, " "))
      .join(", ");
  }
  return value?.toString().toLowerCase().replace(/_/g, " ") ?? "";
}
