import React from "react";
import {useSelector} from "react-redux";
import {
  ListItem,
  List,
  ListItemText,
  ListItemIcon,
  Typography,
  Divider,
  Chip,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ListingList from "components/Lists/ListingList";
import PrimaryButton from "core/buttons/PrimaryButton";
import DeviceTypeIcon from "../Helpers/DeviceTypeIcon";
import {useEffect} from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  listSections: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "space-between",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  column: {width: "48%"},
  divider: {backgroundColor: "rgba(13, 86, 140, 0.05)"},
  selected: {backgroundColor: theme.palette.secondary.main + "20!important"},
  btn: {
    alignSelf: "flex-end",
    marginTop: theme.spacing(2),
  },
  itemTextRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  chip: {
    maxWidth: 150,
    backgroundColor: "rgba(13, 86, 140, 0.75)",
  },
  infoText: {
    fontWeight: 400,
    marginTop: theme.spacing(4),
  },
}));

export default function MatchDevicesListings({nextStep, options}) {
  const classes = useStyles();
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const [selectedDevice, setSelectedDevice] = React.useState(null);
  const [deviceMap, setDeviceMap] = React.useState({});
  const [selectedHouses, setSelectedHouses] = React.useState([]);

  useEffect(() => {
    if (selectedDevice) {
      setDeviceMap((prev) => ({
        ...prev,
        [selectedDevice.integration_device_id]: [...selectedHouses],
      }));
    }
  }, [selectedHouses]);

  const onDeviceSelect = (device) => () => {
    setSelectedHouses((prev) => deviceMap[device.integration_device_id] || []);
    setSelectedDevice((prev) => device);
  };

  const disconnectDevice = () => {
    const newDeviceMap = {...deviceMap};
    delete newDeviceMap[selectedDevice.integration_device_id];
    setSelectedHouses([]);
  };

  const handleListingConnection = (newSelection) => {
    if (Array.isArray(newSelection)) {
      if (!!newSelection.length) {
        setSelectedHouses((prev) => newSelection.map((h) => h.listing_id));
      } else {
        setSelectedHouses((prev) => []);
      }
    } else if (selectedHouses.includes(newSelection.listing_id)) {
      setSelectedHouses((prev) =>
        prev.filter((lid) => lid !== newSelection.listing_id),
      );
    } else {
      setSelectedHouses((prev) => [...prev, newSelection.listing_id]);
    }
  };

  const handleSave = () => {
    nextStep({
      device_map: deviceMap,
    });
  };

  const deviceList = (
    <List>
      {options?.devices?.map((device) => {
        const isConnected = deviceMap[device.integration_device_id]?.length > 0;
        const connectionLabel = isConnected
          ? houses[deviceMap[device.integration_device_id][0]]?.nickname ||
            houses[deviceMap[device.integration_device_id][0]].name
          : "No Connected";
        return (
          <ListItem
            disableRipple
            button
            key={device.integration_device_id}
            selected={
              selectedDevice?.integration_device_id ===
              device.integration_device_id
            }
            onClick={onDeviceSelect(device)}
            classes={{selected: classes.selected}}
          >
            <ListItemIcon>
              <DeviceTypeIcon type={device.device_type} />
            </ListItemIcon>
            <ListItemText
              primary={
                <div className={classes.itemTextRow}>
                  {device.name}
                  <Chip
                    size="small"
                    color="primary"
                    label={connectionLabel}
                    className={classes.chip}
                  />
                </div>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );

  const houseList = (
    <ListingList
      multiselect
      disableGutters
      selectHouse={handleListingConnection}
      selected={selectedHouses}
      customActions={<></>}
    />
  );

  return (
    <div className={classes.root}>
      <div className={classes.listSections}>
        <div className={classes.column}>
          <Typography variant="h2" color="primary">
            Devices
          </Typography>
          {deviceList}
        </div>
        <Divider orientation="vertical" className={classes.divider} />
        <div className={classes.column}>
          <div className={classes.row}>
            <Typography variant="h2" color="primary">
              Listings
            </Typography>
            {!!selectedDevice && (
              <PrimaryButton
                variant="outlined"
                label="Disconnect"
                size="small"
                onClick={disconnectDevice}
              />
            )}
          </div>
          {!!selectedDevice ? (
            houseList
          ) : (
            <Typography
              variant="h1"
              align="center"
              color="textSecondary"
              className={classes.infoText}
            >
              Select a device to get started
            </Typography>
          )}
        </div>
      </div>
      <PrimaryButton
        label="Save"
        onClick={handleSave}
        className={classes.btn}
      />
    </div>
  );
}
