import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  dot: {
    borderRadius: "50%",
    height: 11,
    width: 11,
  },
  unread: {backgroundColor: "#0E7AFD"},
  unanswered: {backgroundColor: THEME.subdued},
  white: {backgroundColor: theme.palette.common.white},
}));

export default function GuestDot({white, isUnread, isUnanswered}) {
  const classes = useStyles({white});

  if (!isUnread && !isUnanswered) {
    return null;
  }
  return (
    <div
      className={clsx(classes.dot, {
        [classes.unread]: !!isUnread,
        [classes.unanswered]: !!isUnanswered && !isUnread,
        [classes.white]: !!white,
      })}
    />
  );
}
