import React from "react";
import {useSelector} from "react-redux";
import {useRouteMatch} from "react-router-dom";
import {Button, SvgIcon} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as UserIcon} from "assets/icons/user.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0),
    backgroundColor: "transparent !important",
  },
  text: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "14.32px",
    color: "#425460",
    "&.-secondary": {
      ...theme.typography.h2,
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
  },
  icon: {
    width: 22,
    fontSize: "22px !important",
  },
}));

export default function ViewProfile({onClick, desktop}) {
  const classes = useStyles();
  const match = useRouteMatch("/admin/messages/:guest_id");
  const mobileChatMatch = useRouteMatch("/admin/messages/chat/:guest_id");
  const mobileProfileMatch = useRouteMatch("/admin/messages/profile/:guest_id");
  const guests = useSelector((state) => state.defaultReducer.guests_dict);
  let paramsGuestId =
    mobileChatMatch?.params?.guest_id ??
    mobileProfileMatch?.params?.guest_id ??
    match?.params.guest_id;
  let guest = guests[paramsGuestId];

  return (
    <Button
      disableRipple
      disableElevation
      onClick={onClick}
      startIcon={
        desktop ? (
          <SvgIcon
            color={desktop ? "primary" : "secondary"}
            viewBox="0 0 24 24"
            className={clsx({[classes.icon]: !!desktop})}
            component={UserIcon}
          />
        ) : null
      }
      classes={{
        root: classes.root,
        label: clsx(classes.text, {"-secondary": desktop}),
      }}
    >
      {desktop ? `${guest?.name ?? "Guest"} Profile` : "View Profile"}
    </Button>
  );
}
