import React from "react";
import {useSelector} from "react-redux";
import {useFlags} from "launchdarkly-react-client-sdk";
import {Box, makeStyles, Typography, useTheme} from "@material-ui/core";
import EmptyContentText from "components/Misc/EmptyContentText";
import HTMLField from "components/TextFields/HTMLField";
import DraggableItem from "core/listItems/DraggableItem";
import {getConnectionLabel} from "utilities/formatUtilities";
import {productsUpsells} from "configuration/enums";
import TemplatesList from "./TemplatesList";
import UpsellProviderCard from "../card/UpsellProviderCard";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    "&.templates": {gap: theme.spacing(2)},
  },
  subtitle: {marginBottom: theme.spacing(3)},
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingRight: theme.spacing(2),
  },
  itemTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "fit-content",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    maxWidth: "70%",
    WebkitLineClamp: 1,
  },
  connectedText: {
    fontSize: 12,
    fontWeight: 400,
    marginLeft: theme.spacing(1),
    color: theme.palette.text.disabled,
    flex: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    lineClamp: 1,
  },
}));

const UpsellsList = ({
  upsells = [],
  onItemClick,
  addUpsell,
  currency,
  deleteUpsell,
  handleItemSelect,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const flags = useFlags();
  const defaultLang = useSelector((state) => state.defaultReducer.language);
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const loadingResource = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_resource_action;

  const handleCheck = ({add, product, index, isTemplate}) => {
    if (add) {
      addUpsell(product, isTemplate);
    } else {
      deleteUpsell(product, index);
    }
  };

  function getDraggableItem(p, ind, id, name, description) {
    return (
      <DraggableItem
        edit
        key={id}
        disableReorder
        disabled={loadingResource}
        title={
          <div className={classes.titleContainer}>
            <span className={classes.itemTitle}>{name}</span>
            <span className={classes.connectedText}>
              • {getConnectionLabel(p, houses, listingGroups)}
            </span>
          </div>
        }
        text={<HTMLField content={description} />}
        picture={p.properties.header_image}
        onItemClick={() => onItemClick(p, ind)}
        onEdit={() => onItemClick(p, ind)}
        onDelete={() => handleCheck({add: false, product: p, index: ind})}
      />
    );
  }

  return (
    <>
      {!!flags.enableKeycardProductApi && (
        <>
          <Typography variant="h1" style={{marginBottom: theme.spacing(3)}}>
            {"Upsell providers"}
          </Typography>
          <UpsellProviderCard />
        </>
      )}
      <Box mt={!!flags.enableKeycardProductApi ? 4 : 0}>
        <Typography variant="h1" style={{marginBottom: theme.spacing(3)}}>
          {"Active custom upsells"}
        </Typography>
      </Box>
      {!upsells.length && <EmptyContentText label="No active upsells" />}
      {upsells.map((p, i) => {
        const id = p.properties.product_id ?? i;
        const name = !!p.properties.name
          ? Array.isArray(p.properties.name)
            ? (p.properties.name.find((n) => n.language === defaultLang)
                ?.value ??
              (p.properties.name[0]?.value || ""))
            : p.properties.name
          : "";
        const description = !!p.properties.description
          ? Array.isArray(p.properties.description)
            ? (p.properties.description?.find((n) => n.language === defaultLang)
                ?.value ??
              (p.properties.description?.[0]?.value || ""))
            : p.properties.description
          : "";

        return getDraggableItem(p, i, id, name, description);
      })}
      <Box marginTop={4}>
        <Box mb={4}>
          <Typography variant="h1">{"Recommended upsells"}</Typography>
        </Box>
        <TemplatesList
          resource="upsells"
          obj={productsUpsells}
          templateKeyName="upsellId"
          currency={currency}
          handleItemSelect={handleItemSelect}
        />
      </Box>
    </>
  );
};

export default UpsellsList;
