import React from "react";
import {useSelector} from "react-redux";
// UI
import CountryFlag from "react-country-flag";
import {Avatar, Chip, Typography} from "@material-ui/core";
import {darken, lighten, makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
// Helpers & Utilities
import {STAGE_COLORS} from "configuration/settings.js";
import {
  bookingStatusLabels,
  countryName,
  guestStageLabels,
  guestTagsLabels,
  languages,
  messageChannelLabels,
  messageSenderLabels,
  questionTypeLabels,
  specialFilters,
} from "configuration/constants";
import {actionParams, fieldParams} from "configuration/specs";
import {isFirefox} from "react-device-detect";
import {format} from "date-fns";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  channelIcon: {
    width: (props) => (props.smch ? 15 : 18),
    height: (props) => (props.smch ? 15 : 18),
    objectFit: "contain",
    marginRight: (props) => (props.smch ? theme.spacing(1) : theme.spacing(2)),
  },
  flag: {
    width: (props) => (!props.smfl ? "18px !important" : "14px !important"),
    height: (props) => (!props.smfl ? "18px !important" : "14px !important"),
    marginRight: (props) => (!!props.smfl ? theme.spacing(1) : undefined),
  },
  stage: {
    padding: 5,
    borderRadius: 5,
    color: (props) => props.color ?? "#FFFFFF",
    backgroundColor: (props) => props.backgroundColor ?? "transparent",
  },
  smStage: {padding: "0px 5px !important"},
  chip: {
    backgroundColor: (props) =>
      !props.outlined
        ? `${lighten(theme.palette.primary.light, 0.7)}!important`
        : "rgba(13, 86, 140, 0.1)",
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    margin: 0,
    marginLeft: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
    height: "auto",
    maxWidth: isFirefox ? "-moz-available" : "-webkit-fill-available",
  },
  chipDeleteIcon: {
    width: 18,
    height: 18,
    color: theme.palette.primary.main,
    "&:hover": {color: darken(theme.palette.primary.main, 0.6)},
  },
  chipLabel: {
    display: "flex",
    alignItems: "baseline",
    flexWrap: "wrap",
    columnGap: theme.spacing(1),
    rowGap: 0,
  },
  filterValue: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  avatar: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
  },
}));

export const CustomCountryFlag = ({countryCode, sm}) => {
  const classes = useStyles({smfl: sm});
  return <CountryFlag svg countryCode={countryCode} className={classes.flag} />;
};

export const StageIcon = ({stage, sm, textOnly}) => {
  const styleProps = !!textOnly
    ? {color: STAGE_COLORS[stage]}
    : {backgroundColor: STAGE_COLORS[stage]};
  const classes = useStyles(styleProps);
  return (
    <div className={clsx(classes.stage, {[classes.smStage]: !!sm})}>
      {guestStageLabels[stage]}
    </div>
  );
};

export const ChannelIcon = ({channel, sm}) => {
  const classes = useStyles({smch: sm});

  switch (channel) {
    case "ab":
    case "Airbnb":
      return (
        <img
          key="airbnb"
          className={classes.channelIcon}
          src={require("assets/img/airbnb_stock.png")}
        />
      );
    case "VRBO":
      return (
        <img
          key="vrbo"
          className={classes.channelIcon}
          src={require("assets/img/vrbo-V-logo.png")}
        />
      );
    case "Expedia":
      return (
        <img
          key="expedia"
          className={classes.channelIcon}
          src={require("assets/img/expedia.png")}
        />
      );
    case "Google":
      return (
        <img
          key="google"
          className={classes.channelIcon}
          src={require("assets/img/google.png")}
        />
      );
    case "Booking.com":
      return (
        <img
          key="bookingcom"
          className={classes.channelIcon}
          src={require("assets/img/bookingcom.png")}
        />
      );
    default:
      return null;
  }
};

export const SelectedFilterList = ({
  filters,
  outlined,
  onDelete,
  hiddenFilters = [],
}) => {
  const classes = useStyles({outlined});
  const userProfile = useSelector((state) => state.defaultReducer.user_profile);
  const guests = useSelector((state) => state.defaultReducer.guests_dict);
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const groups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const users = userProfile.enso_users;
  const {t} = useTranslation();

  return filters.map((f) => {
    if (hiddenFilters.includes(f.path)) {
      return null;
    }
    const filterKey = f.id ?? f.path;
    const filterLabel =
      fieldParams[filterKey]?.name || specialFilters[filterKey]?.name || "";

    const user =
      filterKey === "assigned_to"
        ? (users.find((u) => u.enso_user_id === f.value) ?? {
            name: "Unassigned",
          })
        : null;
    let filterContent = null;

    switch (filterKey) {
      case "assigned_to":
        const img = user?.profile_picture ?? user?.picture;
        filterContent = (
          <div className={classes.row}>
            {!!user && !!img && (
              <Avatar
                alt={user.name || ""}
                src={img}
                className={classes.avatar}
              />
            )}
            <Typography>{user?.name || ""}</Typography>
          </div>
        );
        break;
      case "guest_purchased_product_types":
        filterContent = t("product-type-" + f.value);
        break;
      case "booking_action":
        filterContent =
          actionParams[f.value]?.name ?? (f.operator === "?" ? "exist" : "");
        break;
      case "booking_tags":
        filterContent = `${f.operator === "!=" ? "Not " : ""}${f.value}`;
        break;
      case "guest_status":
      case "booking_status":
        filterContent = Array.isArray(f.value)
          ? f.value.map((val) => bookingStatusLabels[val]).join(", ")
          : bookingStatusLabels[f.value];
        break;
      case "question_type":
        filterContent = questionTypeLabels[f.value];
        break;
      case "message_channel":
        filterContent = messageChannelLabels[f.value];
        break;
      case "message_sender":
        filterContent = messageSenderLabels[f.value];
        break;
      case "guest_tags":
        filterContent = f.value;
        break;
      case "guest_language":
        filterContent = languages[f.value];
        break;
      case "guest_id":
        filterContent = guests[f.value]?.name ?? f.value;
        break;
      case "guest_group_id":
        filterContent =
          groups[f.value]?.name ?? (f.value === null ? "None" : f.value);
        break;
      case "unread_messages":
        filterContent = !!f.value ? "Unread" : "Read";
        break;
      case "unanswered_messages":
        filterContent = !!f.value ? "Unanswered" : "Answered";
        break;
      case "checkin_date":
      case "checkout_date":
        if (f.type === "range" && Array.isArray(f.value)) {
          const startFilter = f.value[0];
          const endFilter = f.value[1];
          return (
            <Chip
              key={`${f.path}-${f.value}`}
              label={
                <>
                  {filterLabel}:{" "}
                  <Typography component="div" className={classes.filterValue}>
                    {`${format(startFilter, "MMM d")} - ${format(endFilter, "MMM d")}`}
                  </Typography>
                </>
              }
              variant="outlined"
              color="primary"
              size="small"
              onDelete={() => onDelete(f, true)}
              deleteIcon={<CloseIcon />}
              className={classes.chip}
              classes={{
                deleteIcon: classes.chipDeleteIcon,
                label: classes.chipLabel,
              }}
            />
          );
        } else {
          filterContent = `${
            f.operator === "<" ? "Before " : f.operator === ">" ? "After " : ""
          }${format(f.value, "MMM d")}`;
        }
        break;
      case "guest_stage":
        filterContent = <StageIcon sm stage={f.value} />;
        break;
      case "listing_active":
        filterContent = !!f.value ? "Yes" : "No";
        break;
      case "listing_tags":
        filterContent = f.value;
        break;
      case "listing_errors":
        filterContent = "exist";
        break;
      case "guest_channel":
      case "guest_integration":
      case "booking_channel":
        filterContent = (
          <>
            <ChannelIcon channel={f.value} sm />
            {f.value}
          </>
        );
        break;
      case "num_listings":
      case "listing_id":
        filterContent = f.value;
        break;
      case "listing_group_id":
        filterContent = "none";
        break;
      case "guest_listing":
      case "booking_listing":
        filterContent =
          houses[f.value]?.nickname ?? houses[f.value]?.name ?? f.value;
        break;
      case "guest_country":
      case "country":
        filterContent = (
          <>
            <CustomCountryFlag countryCode={f.value} sm />
            {countryName[f.value]}
          </>
        );
        break;

      default:
        filterContent = f.value;
        break;
    }

    return (
      <Chip
        key={`${f.path}-${f.value}`}
        label={
          <>
            {filterLabel}:{" "}
            <Typography component="div" className={classes.filterValue}>
              {filterContent}
            </Typography>
          </>
        }
        variant="outlined"
        color="primary"
        size="small"
        onDelete={() => onDelete(f)}
        deleteIcon={<CloseIcon />}
        className={classes.chip}
        classes={{
          deleteIcon: classes.chipDeleteIcon,
          label: classes.chipLabel,
        }}
      />
    );
  });
};
