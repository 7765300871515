import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    borderRadius: 10,
    overflow: "hidden",
    position: "relative",
  },
  shaft: {
    width: "100%",
    height: 10,
    border: "1px solid rgba(154, 212, 214, 0.5)",
    backgroundColor: "#FFF",
    borderRadius: 10,
  },
  bar: {
    height: 10,
    width: "100%",
    backgroundColor: "#9AD4D6",
    borderRadius: 10,
    position: "absolute",
    top: 0,
    left: 0,
    transition: "0.15s",
    transform: (props) => `translateX(-${100 - props.progress}%)`,
  },
}));

export default function ProgressBar({progress = 0}) {
  const value = progress < 0 ? 0 : progress > 100 ? 100 : progress;
  const classes = useStyles({progress: value});

  return (
    <div className={classes.container}>
      <div className={classes.shaft} />
      <div className={classes.bar} />
    </div>
  );
}
