export function formatProduct(product, language) {
  const formattedProduct = {...product};
  formattedProduct.name =
    product.name.find((n) => n.language === language)?.value ?? "";
  formattedProduct.description =
    product.description.find((d) => d.language === language)?.value ?? "";
  formattedProduct.skus = product.skus.map((s) => ({
    ...s,
    name: s.name.find((n) => n.language === language)?.value ?? "",
    description:
      s.description.find((d) => d.language === language)?.value ?? "",
  }));
  return formattedProduct;
}

export function convertMinutes(minutes) {
  if (minutes < 60) {
    return `${minutes}m`;
  } else {
    const hours = minutes / 60;
    const formattedHours = Number.isInteger(hours)
      ? `${hours}H`
      : `${Math.round(hours * 10) / 10}H`;
    return formattedHours;
  }
}

export function getCurrencySymbol(currency) {
  switch (currency) {
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    default:
      return "$";
  }
}

export function getCurrency({
  price = "",
  currency = "",
  showPrefix,
  hideSuffix,
}) {
  let symbol = "";
  if (showPrefix) {
    symbol = getCurrencySymbol(currency);
  }
  const total = price.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return `${symbol}${total}${!hideSuffix ? ` ${currency}` : ""}`;
}
