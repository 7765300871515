import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import Link from "@material-ui/core/Link";
import Alert from "@material-ui/lab/Alert";
import {Col, Container, Row} from "reactstrap";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "100vh",
  },
  col: {
    padding: 0,
    overflow: "hidden",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  logo: {
    width: "150px",
  },
  button: {
    width: "220px !important",
  },
  card: {
    width: "80vw",
    maxWidth: "600px",
    boxShadow: "none",
    border: "none",
  },
}));

export default function ResetView({
  error,
  loading,
  account_name,
  phone,
  password,
  confirm_password,
  email,
  name,
  profile_picture,
  handleChange,
  handleSubmit,
  handleImage,
  verification,
  code,
  handleVerificationChange,
}) {
  const classes = useStyles();

  return (
    <Grid
      container
      style={{margin: "1rem 0"}}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Card className={classes.card}>
          <CardHeader title="Find your Enso account" />
          <CardContent>
            {error && (
              <Alert severity="error">
                We couldn't find your account with that information
              </Alert>
            )}
          </CardContent>
          <CardContent>
            <Typography>
              Enter your email, phone number, or username.
            </Typography>
          </CardContent>
          <CardHeader
            title={
              <TextField
                fullWidth
                id="name"
                value={name}
                onChange={handleChange}
              />
            }
          />
        </Card>
      </Grid>
      <Grid item className="mt-4">
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={handleSubmit}
        >
          Search
        </Button>
      </Grid>
      <Grid item className="mt-3">
        <Typography>
          <Link href="/auth/login">Go Back</Link>
        </Typography>
      </Grid>
    </Grid>
  );
}
