import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import App from "App.js";
import {asyncWithLDProvider} from "launchdarkly-react-client-sdk";

const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
  });

  ReactDOM.render(
    <LDProvider>
      <Suspense fallback={<div></div>}>
        <App />
      </Suspense>
    </LDProvider>,
    document.getElementById("root"),
  );
};

renderApp();
