import React, {useState, useEffect} from "react";
// UI
//Custom
import {useDispatch, useSelector} from "react-redux";
import FullSizeCard from "core/cards/FullSizeCard";
import {CardContent, makeStyles, CircularProgress} from "@material-ui/core";
// Custom
import MatchDevicesListingsCard from "components/Cards/MatchDevicesListingsCard";
import CustomCardHeader from "core/cards/CustomCardHeader";

// Utilities
import clsx from "clsx";

import {getDevices, saveDevice} from "redux/actions/devicesActions";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    overflow: "hidden auto",
  },
  centered: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    height: "100%",
    overflow: "hidden auto",
    display: "flex",
    flexDirection: "column",
  },
  stepper: {padding: theme.spacing(4)},
  cardHeader: {paddingBottom: 0},
  subtitle: {display: "flex"},
  link: {
    margin: theme.spacing(0, 2),
    textDecoration: "underline",
  },
}));

export default function AddDevicesSteps({history}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );

  useEffect(() => {
    setLoading(true);
    if (!!current_user) {
      dispatch(
        getDevices(
          `enso_key=${current_user}&paired=false`,
          (newDevices) => {
            setDevices(newDevices);
            setLoading(false);
          },
          true,
        ),
      );
    }
  }, [current_user]);

  const goBack = () => {
    history.goBack();
  };

  const handleSave = ({device_map}) => {
    for (let deviceId in device_map) {
      let device = devices.find((d) => d.integration_device_id == deviceId);
      device.listing_ids = [
        ...(device.listing_ids ?? []),
        ...device_map[deviceId],
      ];
      dispatch(
        saveDevice(device, (resp) =>
          console.log(`DEVICE ${resp.device.integration_device_id} PAIRED`),
        ),
      );
    }
    history.push("/admin/settings/devices");
  };

  return (
    <FullSizeCard goBack={goBack}>
      <CustomCardHeader title="Pair Devices" className={classes.cardHeader} />
      <CardContent className={classes.content}>
        <div className={clsx(classes.container, {[classes.centered]: loading})}>
          {!!loading && <CircularProgress />}
          {!loading && (
            <MatchDevicesListingsCard
              nextStep={handleSave}
              options={{devices}}
            />
          )}
        </div>
      </CardContent>
    </FullSizeCard>
  );
}
