import React from "react";
// UI
import {
  Collapse,
  IconButton,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PhoneIcon from "@material-ui/icons/Call";
import useExperienceActionsStyles from "styles/useExperienceActionsStyles";
// Custom
import InputField from "core/inputs/InputField";
import FilledTextField from "core/inputs/FilledTextField";
import FilledSelect from "core/selects/FilledSelect";
// Utilities
import {
  callDestinationLabels,
  callRedirectLabels,
} from "configuration/constants";
import clsx from "clsx";
import _ from "lodash";

export default function PhoneCallAction({
  edit,
  data,
  selectedAction,
  editData,
  disableEdit,
  disableDelete,
  hideEdit,
  variant,
  onEdit,
  onDelete,
}) {
  const styleProps = {
    filled: variant === "filled",
    viewOnly: !!hideEdit && !!disableDelete,
  };
  const classes = useExperienceActionsStyles(styleProps);
  const [destination, setDestination] = React.useState("guest_phone");
  const [redirect, setRedirect] = React.useState("assigned_manager");
  const [dial, setDial] = React.useState("");
  const [voiceMessage, setVoiceMessage] = React.useState("");
  const isCall = !!selectedAction
    ? selectedAction === "call"
    : data.action_id === "call";

  // DESTINATION SPECIFIC NUMBER
  const [destSpecNum, setDestSpecNum] = React.useState("");
  const [destSpecValid, setDestSpecValid] = React.useState(false); // TODO implement validation

  // REDIRECT SPECIFIC NUMBER
  const [redSpecNum, setRedSpecNum] = React.useState("");
  const [redSpecValid, setRedSpecValid] = React.useState(false); // TODO implement validation

  React.useEffect(() => {
    if (!data) {
      return;
    }
    setDestination((prev) => data.destination ?? "guest_phone");
    setRedirect((prev) => data.redirect ?? "assigned_manager");
    setDial((prev) => data.dial ?? "");
    setVoiceMessage((prev) => data.voice_message ?? "");
    setDestSpecNum(data.specific_destination_number ?? "");
    setRedSpecNum(data.specific_redirect_number ?? "");
  }, [data]);

  React.useEffect(() => {
    if (!data) {
      return;
    }
    const oldData = {};
    const newData = {id: data.id};
    if (isCall) {
      oldData.destination = data.destination || "guest_phone";
      newData.destination = destination;
      if (destination === "specific number") {
        oldData.specific_destination_number =
          data.specific_destination_number || "";
        newData.specific_destination_number = destSpecNum;
      }
    }

    oldData.redirect = data.redirect || "assigned_manager";
    if (!!data.voice_message) {
      oldData.voice_message = data.voice_message;
    }
    if (!!data.dial) {
      oldData.dial = data.dial;
    }
    newData.redirect = redirect;

    if (redirect === "specific number") {
      oldData.specific_redirect_number = data.specific_redirect_number;
      newData.specific_redirect_number = redSpecNum;
    }
    if (!!voiceMessage.trim()) {
      newData.voice_message = voiceMessage;
    }
    if (!!dial.trim()) {
      newData.dial = dial;
    }
    if (isCall) {
      newData.destination = destination;
      if (destination === "specific number") {
        newData.specific_destination_number = destSpecNum;
      }
    }

    if (!_.isEqual(oldData, newData)) {
      editData(newData);
    }
  }, [destination, redirect, dial, voiceMessage, destSpecNum, redSpecNum]);

  const handleDestinationChange = (e) => {
    const val = e.target.value;
    setDestination((prev) => val);
  };

  const handleRedirectChange = (e) => {
    const val = e.target.value;
    setRedirect((prev) => val);
  };

  const handleVoiceMessageChange = (e) => {
    const val = e.target.value;
    setVoiceMessage((prev) => val);
  };
  const handleDialChange = (e) => {
    const val = e.target.value;
    setDial((prev) => val);
  };

  function getPhone(phone, setPhone, setValidPhone) {
    return (
      <div className={classes.phoneContainer}>
        <InputField
          editOnly
          fullWidth
          type="phone"
          label="Specific Number"
          value={phone}
          onChange={setPhone}
          phoneInputProps={{setValidPhone: setValidPhone}}
        />
      </div>
    );
  }

  return (
    <>
      <Collapse in={edit}>
        <Paper elevation={0} className={classes.paper}>
          <div className="mt-2 mb-3">
            {isCall && (
              <>
                <FilledSelect
                  fullWidth
                  value={destination}
                  label="Destination"
                  className="mb-2"
                  onChange={handleDestinationChange}
                >
                  {Object.keys(callDestinationLabels).map((d) => (
                    <MenuItem key={d} value={d}>
                      {callDestinationLabels[d]}
                    </MenuItem>
                  ))}
                </FilledSelect>
                {destination === "specific number" &&
                  getPhone(destSpecNum, setDestSpecNum, setDestSpecValid)}
              </>
            )}
            <FilledSelect
              fullWidth
              value={redirect}
              label="Redirect"
              className="mb-2"
              onChange={handleRedirectChange}
            >
              {Object.keys(callRedirectLabels).map((r) => (
                <MenuItem key={r} value={r}>
                  {callRedirectLabels[r]}
                </MenuItem>
              ))}
            </FilledSelect>
            {redirect === "specific number" &&
              getPhone(redSpecNum, setRedSpecNum, setRedSpecValid)}
            <FilledTextField
              label="Voice Message (Optional)"
              value={voiceMessage}
              fullWidth
              className="mb-2"
              placeholder="e.g: Hi, this is a recorded message..."
              onChange={handleVoiceMessageChange}
            />
            <FilledTextField
              label="Dial (Optional)"
              value={dial}
              placeholder="e.g: 1234"
              onChange={handleDialChange}
            />
          </div>
        </Paper>
      </Collapse>
      <Collapse in={!edit}>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.selectedOption}>
            <Typography
              className={clsx(
                classes.title,
                classes.flexGrow,
                classes.ellipsis,
              )}
              component="span"
            >
              <PhoneIcon className={classes.iconPreview} />
              <span>
                {isCall
                  ? `Call to ${
                      destination === "specific number"
                        ? destSpecNum
                        : callDestinationLabels[destination].toLowerCase()
                    }`
                  : "Respond "}
              </span>
              <span className={clsx("ml-1", classes.medium)}>
                {`${
                  !!voiceMessage
                    ? `with voice message: "${voiceMessage}"`
                    : `and redirect to ${
                        redirect === "specific number"
                          ? redSpecNum
                          : callDestinationLabels[redirect].toLowerCase()
                      }`
                }`}
              </span>
            </Typography>
            {!hideEdit && (
              <IconButton
                className={clsx(classes.iconBtn, {"-disabled": disableEdit})}
                onClick={onEdit}
              >
                <EditIcon className={classes.icon} />
              </IconButton>
            )}
            {!disableDelete && (
              <IconButton
                className={clsx(classes.iconBtn, {"-disabled": disableEdit})}
                onClick={onDelete}
              >
                <DeleteIcon className={classes.icon} />
              </IconButton>
            )}
          </div>
        </Paper>
      </Collapse>
    </>
  );
}
