import React from "react";
import {languages} from "configuration/constants.js";
import _ from "lodash";
// UI
import {
  FormControl,
  ListItemSecondaryAction,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import useLanguageSelectorStyles from "styles/useLanguageSelectorStyles";
// Custom
import DeleteIconButton from "core/buttons/DeleteIconButton";
import CustomDialog from "core/dialogs/CustomDialog";
import ExpandButton from "core/buttons/ExpandButton";
import ChipSelect from "core/selects/ChipSelect";
import CustomMenu from "core/menus/CustomMenu";
// Utils
import usePrevious from "hooks/usePrevious";
import clsx from "clsx";

const languageKeys = Object.keys(languages);
const languageOptions = Object.entries(languages).map((langEntry) => ({
  key: langEntry[0],
  label: langEntry[1],
}));

export default function LanguageSelector({
  simple,
  edit,
  hideButton,
  selector,
  addLang,
  disableSearch,
  light,
  selectedLangs,
  selectedLanguage,
  setSelectedLang,
  preventOverflow = false,
  alwaysEditable,
  plain,
  removeLang,
  selectLang,
  placement = "bottom-end",
  transformOrigin = "left top",
  disableEdit = false,
  nonEditableLangs = [],
}) {
  const classes = useLanguageSelectorStyles();
  const [remainingLangs, setRemainingLangs] = React.useState([]);
  const [selectorLangs, setSelectorLangs] = React.useState(languageKeys);
  const [langMenuAnchorEl, setLangMenuAnchorEl] = React.useState(null);
  const [selectorMenuAnchorEl, setSelectorMenuAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [openLanguageModal, setOpenLanguageModal] = React.useState(false);
  const prevSearchText = usePrevious(searchText);

  React.useEffect(() => {
    setRemainingLangs((prev) => _.difference(languageKeys, selectedLangs));
    setSearchText((prev) => "");
  }, [selectedLangs]);

  React.useEffect(() => {
    let timer = null;
    if (!!prevSearchText && !searchText) {
      setRemainingLangs(_.difference(languageKeys, selectedLangs));
      setSelectorLangs((prev) => languageKeys);
    } else if (!!searchText) {
      timer = setTimeout(() => {
        const newLangs = _.difference(languageKeys, selectedLangs);
        setRemainingLangs((prev) => filterLangs(newLangs));
        setSelectorLangs((prev) => filterLangs(languageKeys));
      }, 50);
    }

    return () => clearTimeout(timer);
  }, [searchText]);

  const handleSelectorChange =
    (lang, force = false) =>
    () => {
      if ((!!simple && !!edit) || !!force) {
        addLang(lang);
      }
      setSelectedLang(lang);
      setSelectorMenuAnchorEl(null);
      setOpenLanguageModal((prev) => false);
    };

  const filterLangs = (arr) => {
    return arr.filter((l) => {
      if (languages[l].toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
        return true;
      } else {
        return false;
      }
    });
  };

  const handleSearchChange = (e) => {
    const val = e.target.value;
    setSearchText((prev) => val);
  };

  const handleAddLanguage = () => {
    setOpenLanguageModal((prev) => true);
    setSelectorMenuAnchorEl((prev) => null);
  };

  const searchItem = (value, onChange, modalContainer) => {
    return (
      <MenuItem
        disableRipple
        onKeyDown={(e) => e.stopPropagation()}
        className={clsx(classes.search, {"-modal-item": !!modalContainer})}
      >
        <SearchIcon className={classes.searchInputIcon} />
        <input
          className={classes.searchInput}
          autoFocus
          value={value}
          type="search"
          placeholder="Search"
          autoComplete="off"
          onChange={onChange}
        />
      </MenuItem>
    );
  };

  const noResultsItem = (modalContainer) => {
    return (
      <MenuItem
        disabled
        className={clsx(classes.noResultsMessage, {
          "-modal-item": !!modalContainer,
        })}
      >
        {"No search results"}
      </MenuItem>
    );
  };

  const languagesModal = (
    <CustomDialog
      disableActions
      open={openLanguageModal}
      maxHeight={"calc(100% - 200px)"}
      dialogContentExtraClass={classes.modalContent}
      transitionDuration={0}
      title="Select language"
      onClose={() => setOpenLanguageModal((prev) => false)}
      content={
        <MenuList>
          {!disableSearch && searchItem(searchText, handleSearchChange, true)}
          {!disableSearch &&
            !!searchText &&
            !selectorLangs.length &&
            noResultsItem(true)}
          {_.map(selectorLangs, (lang) => (
            <MenuItem
              key={lang}
              disableRipple
              className={classes.modalContentItem}
              onClick={handleSelectorChange(lang, true)}
            >
              {languages[lang]}
            </MenuItem>
          ))}
        </MenuList>
      }
    />
  );

  function getSelectorMenu() {
    return (
      <MenuList className={clsx({[classes.list]: !!edit})}>
        {!disableSearch && !!edit && searchItem(searchText, handleSearchChange)}
        {!disableSearch &&
          !!edit &&
          !!searchText &&
          !selectorLangs.length &&
          noResultsItem()}
        {_.map(!!simple && !!edit ? selectorLangs : selectedLangs, (lang) => (
          <MenuItem
            key={lang}
            disableRipple
            onClick={handleSelectorChange(lang)}
          >
            {languages[lang]}
          </MenuItem>
        ))}
        {!!alwaysEditable && !edit && (
          <MenuItem
            key="add_language"
            disableRipple
            onClick={handleAddLanguage}
          >
            {"Add language"}
          </MenuItem>
        )}
      </MenuList>
    );
  }

  function getFullMenu() {
    return (
      <MenuList className={classes.list}>
        {!disableSearch && searchItem(searchText, handleSearchChange)}
        {!!searchText && !remainingLangs.length && noResultsItem()}
        {!searchText && !plain && !!selectedLangs.length && (
          <MenuItem disabled className={classes.disabledMenuItem}>
            {"Edited languages"}
          </MenuItem>
        )}
        {!searchText &&
          _.map(selectedLangs, (lang) => (
            <MenuItem
              key={lang}
              disableRipple
              className={classes.menuItem}
              onClick={() => {
                if (!!plain) {
                  setSelectedLang(lang);
                } else if (!!selectLang) {
                  selectLang(lang);
                }
                setLangMenuAnchorEl(null);
              }}
            >
              {languages[lang]}
              {selectedLangs.length > 1 &&
                !disableEdit &&
                !!removeLang &&
                !nonEditableLangs.includes(lang) && (
                  <ListItemSecondaryAction className={classes.secondaryAction}>
                    <DeleteIconButton
                      disablePadding
                      onClick={() => {
                        removeLang(lang);
                        setLangMenuAnchorEl(null);
                      }}
                    />
                  </ListItemSecondaryAction>
                )}
            </MenuItem>
          ))}
        {!!remainingLangs.length && !disableEdit && (
          <MenuItem disabled className={classes.disabledMenuItem}>
            {"All languages"}
          </MenuItem>
        )}
        {!disableEdit &&
          _.map(remainingLangs, (lang) => (
            <MenuItem
              key={lang}
              disableRipple
              className={classes.menuItem}
              onClick={() => {
                if (!!plain) {
                  setSelectedLang(lang);
                } else {
                  addLang(lang);
                }
                setLangMenuAnchorEl(null);
              }}
            >
              {languages[lang]}
            </MenuItem>
          ))}
      </MenuList>
    );
  }

  function getMenu(anchorEl, onClose, content, minHeight = 0) {
    return (
      <CustomMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        timeout={0}
        zIndex={4}
        overflowAuto
        hideScrollbar
        maxHeight={510}
        bottomPadding={14}
        disablePortal={!plain}
        minHeight={minHeight}
        placement={placement}
        transformOrigin={transformOrigin}
        fitAnchorElHeight={!plain}
        modifiers={{
          flip: {enabled: !!plain},
          preventOverflow: {
            enabled: preventOverflow,
            boundariesElement: !plain ? "scrollParent" : "viewport",
          },
        }}
        onClose={onClose}
        content={content}
      />
    );
  }

  return (
    <FormControl>
      <div className={classes.root}>
        {languagesModal}
        {!hideButton && !simple && !plain && (
          <ExpandButton
            hasPopup
            variant="outlined"
            className={clsx(classes.expandBtn, {light: !!light})}
            label={languages[selectedLanguage]}
            onClick={(e) => setLangMenuAnchorEl(e.currentTarget)}
          />
        )}
        {!!plain && (
          <ChipSelect
            fullWidth
            label="Language"
            options={languageOptions}
            value={languages[selectedLanguage]}
            onChange={setSelectedLang}
            onClick={(e) => setLangMenuAnchorEl(e.currentTarget)}
            emptyOption="No language selected"
          />
        )}
        {selector && (
          <>
            <ExpandButton
              hasPopup
              variant="text"
              className={classes.filled}
              label={languages[selectedLanguage]}
              onClick={(e) => setSelectorMenuAnchorEl(e.currentTarget)}
            />
            {getMenu(
              selectorMenuAnchorEl,
              () => setSelectorMenuAnchorEl((prev) => null),
              getSelectorMenu(),
            )}
          </>
        )}
      </div>
      {getMenu(
        langMenuAnchorEl,
        () => setLangMenuAnchorEl((prev) => null),
        getFullMenu(),
        80,
      )}
    </FormControl>
  );
}
