import React from "react";
// UI
import {
  Collapse,
  IconButton,
  Paper,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {ReactComponent as MessagesIcon} from "assets/icons/Icon_Messages.svg";
import useExperienceActionsStyles from "styles/useExperienceActionsStyles";
// Custom
import MultiLangText from "components/Helpers/MultiLangText";
// Utilities
import clsx from "clsx";
import _ from "lodash";

const fields = [
  {
    id: "url",
    label: "Webhook URL (POST - https only)",
    placeholder: "https://yourapi.com",
  },
  {
    id: "headers",
    label: "Headers (must be valid json)",
    placeholder: "{\n   'auth': 'YOUR_AUTH_TOKEN'\n}",
  },
  {
    id: "body",
    label: "Body (must be valid json)",
    placeholder: "{\n   'guest_name': '<guest_name>'\n}",
  },
];

export default function MessageAction({
  edit,
  data,
  selectedAction,
  disableEdit,
  disableDelete,
  hideEdit,
  variant,
  editData,
  onEdit,
  onDelete,
  setIsContentValid,
  additionalObjectsVariables,
}) {
  const styleProps = {
    filled: variant === "filled",
    viewOnly: !!hideEdit && !!disableDelete,
  };
  const classes = useExperienceActionsStyles(styleProps);
  // State
  const [content, setContent] = React.useState({});

  React.useEffect(() => {
    if (!Object.keys(content).length) {
      return;
    }
    editData({
      id: data.id,
      url: content.url.en,
      headers: content.headers.en,
      body: content.body.en,
      action_id: "webhook",
    });
  }, [content]);

  React.useEffect(() => {
    const headers =
      typeof data.headers === "string"
        ? data.headers
        : !!Object.keys(data.headers ?? {}).length
          ? JSON.stringify(data.headers)
          : "";
    const body =
      typeof data.body === "string"
        ? data.body
        : !!Object.keys(data.body ?? {}).length
          ? JSON.stringify(data.body)
          : "";
    const newContent = {
      url: {en: data.url || ""},
      headers: {en: headers},
      body: {en: body},
    };
    if (!_.isEqual(content, newContent)) {
      setContent((prev) => newContent);
    }
  }, [data, selectedAction]);

  const handleContentChange = (channel, value, lang) => {
    if (_.isEqual(content, value)) {
      return;
    }
    setContent((prev) => value);
  };

  const handleContentValidity = (value) => {
    setIsContentValid((prev) => value);
  };

  return (
    <>
      <Collapse in={edit} timeout={{appear: 0, enter: 150, exit: 150}}>
        <Paper elevation={0} className={classes.paper}>
          <div className="mt-2 mb-3">
            <MultiLangText
              noValidation
              channel="webhook"
              langs={["en"]}
              fields={fields}
              content={content}
              autotranslate={true}
              selectedLang="en"
              editContent={handleContentChange}
              onValidityCheck={handleContentValidity}
              additionalObjectsVariables={additionalObjectsVariables}
            />
          </div>
        </Paper>
      </Collapse>
      <Collapse in={!edit} timeout={{appear: 0, enter: 150, exit: 50}}>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.selectedOption}>
            <Typography
              className={clsx(
                classes.title,
                classes.flexGrow,
                classes.ellipsis,
              )}
              component="span"
            >
              <SvgIcon
                viewBox="0 0 35 35"
                className={classes.iconPreview}
                component={MessagesIcon}
              />
              <span>Send Webhook Message</span>
              <span className={clsx("ml-1", classes.medium)}>
                to {data.url}
              </span>
            </Typography>
            {!hideEdit && (
              <IconButton
                className={clsx(classes.iconBtn, {"-disabled": disableEdit})}
                onClick={onEdit}
              >
                <EditIcon className={classes.icon} />
              </IconButton>
            )}
            {!disableDelete && (
              <IconButton
                className={clsx(classes.iconBtn, {"-disabled": disableEdit})}
                onClick={onDelete}
              >
                <DeleteIcon className={classes.icon} />
              </IconButton>
            )}
          </div>
        </Paper>
      </Collapse>
    </>
  );
}
