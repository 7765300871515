import {makeStyles} from "@material-ui/core/styles";
import {THEME} from "configuration/settings";

const useListingProductsPanelStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: theme.spacing(4),
    "&.pt0": {paddingTop: 0},
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.primary.main + "0D"}`,
  },
  saveDialogBtn: {marginLeft: theme.spacing(3)},
  dialogTitle: {
    padding: theme.spacing(4),
    fontWeight: 590,
    fontSize: 24,
    lineHeight: "24px",
    color: "#3F575C",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogContent: {padding: theme.spacing(4)},
  dialogActions: {
    backgroundColor: "#F0F1F1",
    justifyContent: "right",
    padding: theme.spacing(4),
  },
  editorPreviewContainer: {
    border: "1px solid rgba(229, 229, 234, 0.75)",
    borderRadius: 8,
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    "& > label": {marginBottom: 0},
  },
  radio: {paddingRight: 7},
  radioLabel: {
    color: "rgba(0,0,0,0.6)",
    fontSize: theme.typography.button.fontSize,
    fontWeight: theme.typography.button.fontWeight,
    lineHeight: theme.typography.button.lineHeight,
    marginRight: theme.spacing(5),
    display: "flex",
    alignItems: "center",
  },
  title: {...THEME.customTypography.title2},
  selected: {fontWeight: 700},
  templateContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    "&::-webkit-scrollbar": {width: "0!important"},
  },
}));

export default useListingProductsPanelStyles;
