import React, {useRef} from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {
  Box,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  item: {
    backgroundColor: "#F1F3F9",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(3),
    borderRadius: 10,
    "&.-outlined": {
      backgroundColor: theme.palette.common.white,
      border: "2px solid #F1F3F9",
      opacity: 1,
    },
    "&.-btn": {cursor: "pointer"},
    "&.-disabled": {opacity: 0.5},
  },
  itemContainer: {listStyleType: "none"},
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  title: {
    fontWeight: 500,
    color: "#5F6682",
  },
  actionsContainer: {right: theme.spacing(3)},
}));

const InfoCardItem = ({
  title,
  titleTag,
  description,
  outlined,
  onClick,
  actions,
  disabled,
}) => {
  const classes = useStyles();
  const actionsContainerRef = useRef(null);
  const theme = useTheme();
  let actionsWidth =
    actionsContainerRef.current?.offsetWidth || !!actions ? 28 : 0;

  return (
    <ListItem
      button
      disableRipple
      disabled={disabled}
      classes={{container: classes.itemContainer}}
      className={clsx(classes.item, {
        "-outlined": !!outlined,
        "-btn": !!onClick,
        "-disabled": !!disabled,
      })}
      style={{paddingRight: actionsWidth + theme.spacing(4)}}
      onClick={onClick}
    >
      <ListItemText
        disableTypography
        style={{margin: 0, width: "100%"}}
        primary={
          <Box className={classes.titleContainer}>
            <Typography className={classes.title}>{title}</Typography>
            {titleTag}
          </Box>
        }
        secondary={<Typography>{description}</Typography>}
      />
      {!!actions && (
        <ListItemSecondaryAction
          ref={actionsContainerRef}
          className={classes.actionsContainer}
        >
          {actions}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default InfoCardItem;
