import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useRouteMatch} from "react-router-dom";
// UI
import {Box, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import CustomCardHeader from "core/cards/CustomCardHeader";
import FilledTextField from "core/inputs/FilledTextField";
import PrimaryButton from "core/buttons/PrimaryButton";
import CloseIconButton from "core/buttons/CloseIconButton";
import WifiList from "components/Lists/WifiList";
// Actions
import {
  editListingGroup,
  getHouseContent,
  updateListing,
} from "redux/actions/listingsActions";
import GJEInfoCard from "components/Cards/GJEInfoCard";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: "1px solid #0D568C0D",
  },
  content: {
    overflow: "auto",
    height: "100%",
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  selectorContainer: {
    textAlign: "right",
    marginBottom: theme.spacing(3),
  },
  box: {gap: theme.spacing(2)},
  input: {
    flexBasis: 210,
    flexGrow: 1,
  },
  loading: {
    height: "20px !important",
    width: "20px !important",
    color: "#FFF",
  },
  itemContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: theme.spacing(4),
  },
}));

export default function GJEListingContent({onClose, disableEdit}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const stopLoadingState = React.useRef(false);
  const matchCardSection = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card",
  );
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const loadingGroupDetails = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_group_details;
  const [info, setInfo] = React.useState({ssid: "", password: ""});
  const [selectedListing, setSelectedListing] = React.useState(null);
  const [saving, setSaving] = React.useState(false);
  // General
  const saveDisabled = React.useMemo(() => {
    if (!selectedListing) {
      return true;
    }
    const isEmpty = !info.ssid.trim() || !info.password.trim();
    const isSameInfo =
      info.ssid === selectedListing.data.ssid &&
      info.password === selectedListing.data.password;
    return isEmpty || isSameInfo || saving;
  }, [info, selectedListing, saving]);
  const {groupId, section, card} = React.useMemo(() => {
    setSelectedListing((prev) => null);
    if (!matchCardSection?.isExact) {
      return {groupId: null, section: null, card: null};
    }
    return {
      groupId: matchCardSection.params.group_id,
      section: matchCardSection.params.step,
      card: matchCardSection.params.card,
    };
  }, [location]);

  let connectedListings = listingGroups[groupId]?.connected_to?.ids ?? [];
  let guestJourney = listingGroups[groupId]?.guest_journey ?? {};
  let data = guestJourney.sections?.[section]?.cards[card]?.data ?? [];

  const allListings = React.useMemo(() => {
    const allKeys = data.map((item, ind) => {
      return {
        index: ind,
        listing_id: item.listing_id,
        name: item.name ?? "",
        data: item.data,
        hasInfo: !!Object.keys(item.data ?? {}).length,
      };
    });
    return allKeys;
  }, [listingGroups, houses, card, data]);

  React.useEffect(() => {
    if (!!selectedListing && selectedListing.hasInfo) {
      setInfo((prev) => ({
        ssid: selectedListing.data?.ssid ?? "",
        password: selectedListing.data?.password ?? "",
      }));
    } else {
      setInfo((prev) => ({ssid: "", password: ""}));
    }
  }, [selectedListing]);

  React.useEffect(() => {
    if (!!data?.length && !loadingGroupDetails) {
      stopLoadingState.current = true;
    }
  }, [data, loadingGroupDetails]);

  const getListingItemDescription = (item) => {
    if (loadingGroupDetails & !stopLoadingState.current) {
      return "";
    }
    return !item.hasInfo ? "Missing wifi information" : "";
  };

  const onItemClick = (newItem) => {
    if (!houses[newItem.listing_id]) {
      dispatch(getHouseContent(newItem.listing_id));
    }
    setSelectedListing((prev) =>
      newItem.listing_id === prev?.listing_id ? null : newItem,
    );
  };

  const handleInfoChange = (e) => {
    const field = e.target.id;
    const val = e.target.value;
    setInfo((prev) => ({...prev, [field]: val}));
  };

  const onResponseSuccess = () => {
    setSaving((prev) => false);
    setSelectedListing((prev) => ({...prev, data: info, hasInfo: true}));
  };

  const handleSave = () => {
    setSaving((prev) => true);
    const newListingGroup = Object.assign({}, listingGroups[groupId]);
    const newDataInfo = {...info};
    newListingGroup.guest_journey.sections[section].cards[card].data[
      selectedListing.index
    ].data = newDataInfo;
    dispatch(editListingGroup(newListingGroup));
    const listing = houses[selectedListing.listing_id];
    const body = {policy: {wifi_info: info}};
    dispatch(
      updateListing({
        body,
        listing,
        onSuccess: onResponseSuccess,
        onError: () => setSaving((prev) => false),
      }),
    );
  };

  function getItemContent() {
    return (
      <div className={classes.itemContent}>
        <Box display="flex" flexDirection="row" className={classes.box}>
          <FilledTextField
            id="ssid"
            fullWidth
            label="Network"
            disabled={disableEdit}
            className={classes.input}
            placeholder="My wifi network"
            value={info.ssid}
            onChange={handleInfoChange}
          />
          <FilledTextField
            id="password"
            fullWidth
            label="Password"
            placeholder="12345"
            value={info.password}
            disabled={disableEdit}
            className={classes.input}
            onChange={handleInfoChange}
          />
        </Box>
        {!disableEdit && (
          <Box textAlign="right" paddingTop={3}>
            <PrimaryButton
              size="small"
              disabled={saveDisabled}
              onClick={handleSave}
              label={
                saving ? (
                  <CircularProgress
                    color="secondary"
                    className={classes.loading}
                  />
                ) : (
                  "Save"
                )
              }
            />
          </Box>
        )}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <CustomCardHeader
        title="Wifi"
        className={classes.header}
        action={<CloseIconButton sm disablePadding onClick={onClose} />}
      />
      <div className={classes.content}>
        <Box>
          <GJEInfoCard banner type="wifi" />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          minHeight={
            (connectedListings.length + 2) * 70 + (!!selectedListing ? 135 : 0)
          }
        >
          <WifiList
            loadingItems={loadingGroupDetails && !stopLoadingState.current}
            getDescription={getListingItemDescription}
            connectedListings={connectedListings}
            allListings={allListings}
            selectedListing={selectedListing}
            content={getItemContent()}
            onExpand={onItemClick}
          />
        </Box>
      </div>
    </div>
  );
}
