import React from "react";
import {ListItem, ListItemText} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ArrowIcon from "@material-ui/icons/NavigateNext";
import {FIXED_SIZES, THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(2, 4),
    height: FIXED_SIZES.listings_dense,
    "&.-selected": {backgroundColor: "#F1F3F9"},
  },
  arrow: {
    marginLeft: -6,
    marginRight: theme.spacing(3),
    color: "rgba(60, 60, 67, 0.301961)",
    fontSize: 28,
    transition: "0.15s",
    "&.-expanded": {transform: "rotate(90deg)"},
    "&.-right": {margin: 0},
  },
  descRow: {
    display: "flex",
    alignItems: "flex-end",
  },
  icon: {
    "& > svg": {
      fontSize: 20,
      marginRight: theme.spacing(2),
    },
  },
  desc: {
    fontSize: 13,
    color: THEME.grey,
  },
  title: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    lineClamp: 1,
  },
}));

export default function ExpandableItem({
  open,
  title,
  description,
  disabled,
  descIcon = null,
  onClick,
  rightArrow,
}) {
  const classes = useStyles();

  return (
    <ListItem
      button
      disableRipple
      divider={!open}
      onClick={onClick}
      disabled={disabled}
      className={clsx(classes.item, {"-selected": open})}
    >
      {!rightArrow && (
        <ArrowIcon className={clsx(classes.arrow, {"-expanded": open})} />
      )}
      <ListItemText
        primary={title}
        secondary={
          !!description && (
            <div className={classes.descRow}>
              {!!descIcon && <span className={classes.icon}>{descIcon}</span>}
              {description}
            </div>
          )
        }
        primaryTypographyProps={{variant: "h1", className: classes.title}}
        secondaryTypographyProps={{
          component: "div",
          variant: "caption",
          className: classes.desc,
        }}
      />
      {!!rightArrow && <ArrowIcon className={clsx(classes.arrow, "-right")} />}
    </ListItem>
  );
}
