import React from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, makeStyles, Typography, useTheme} from "@material-ui/core";
import HTMLContent from "./HTMLContent";
import ScrollablePage from "./ScrollablePage";
import SectionHeaderCard from "./SectionHeaderCard";
import defaultBGImg from "assets/img/article_placeholder_image.webp";

const useStyles = makeStyles((theme) => ({
  content: {paddingBottom: 100},
}));

export default function ViatorProductDetails({product, onClose}) {
  const classes = useStyles();
  const {t} = useTranslation();
  const theme = useTheme();

  const header = product.header_image ? (
    <SectionHeaderCard
      disableGrayscale
      title={product.name || ""}
      img={product.header_image || defaultBGImg}
    />
  ) : (
    <div className={classes.simpleHeadercontainer}>
      <Box px={2.5}>
        <Typography variant="h3">{product.name}</Typography>
      </Box>
    </div>
  );

  const content = (
    <div className={classes.content}>
      <HTMLContent v={product.description} />
    </div>
  );

  const actionPanel = (
    <div className={classes.actionsContainer}>
      <Button
        disableRipple
        fullWidth
        disabled
        onClick={() => {}}
        variant="contained"
        style={{
          borderRadius: 30,
          fontWeight: 500,
          fontSize: 16,
          textTransform: "none",
          boxShadow: "none",
          color: theme.palette.primary.contrast,
          backgroundColor: theme.palette.primary.mainLight,
          lineHeight: "18px",
          opacity: 0.5,
          padding: theme.spacing(1.5, 2.5),
        }}
      >
        {t("check-availability")}
      </Button>
    </div>
  );

  return (
    <>
      <ScrollablePage
        onClose={onClose}
        header={header}
        actionPanel={actionPanel}
        pageContent={content}
      />
    </>
  );
}
