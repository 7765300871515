import React from "react";
import {CircularProgress} from "@material-ui/core";
import {THEME} from "configuration/settings";

export default function ButtonLabelLoader({
  variant = "primary",
  size = 20,
  ...restProps
}) {
  function getColor() {
    switch (variant) {
      case "secondary":
        return THEME.secondary;
      case "error":
        return THEME.error;
      case "disabled":
        return THEME.subdued;
      case "white":
        return THEME.white;
      case "request":
        return "#4AA44D";
      case "pending":
        return "#FC635B";
      case "primary":
      default:
        return THEME.primary;
    }
  }

  return (
    <CircularProgress size={size} style={{color: getColor()}} {...restProps} />
  );
}
