import React, {useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Typography} from "@material-ui/core";
import PrimaryButton from "core/buttons/PrimaryButton";
import ButtonLabelLoader from "core/loaders/ButtonLabelLoader";
import {Waypoint} from "react-waypoint";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import {getHouses} from "redux/actions/listingsActions";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 10,
    gap: theme.spacing(1),
    backgroundColor: "#FFFFFF",
    padding: theme.spacing(2, 3),
    cursor: "pointer",
    border: "2px solid #FFFFFF",
    "&.mobile": {
      backgroundColor: "rgba(13, 86, 140, 0.05)",
      border: "none",
    },
    "&:hover": {
      backgroundColor: "#f8f8f9",
      border: "2px solid #f8f8f9",
    },
    "&.selected": {border: `2px solid ${theme.palette.secondary.main}`},
  },
  col: {
    display: "flex",
    flexDirection: "column",
    "&.reverse": {flexDirection: "column-reverse"},
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  dot: {
    borderRadius: 360,
    width: 11,
    height: 11,
  },
  gap12: {gap: theme.spacing(3)},
  pending: {backgroundColor: "#FC635B"},
  request: {backgroundColor: "#4AA44D"},
  message: {backgroundColor: theme.palette.secondary.main},
  unansweredMessage: {backgroundColor: "#BDBDBD"},
  pendingLabel: {color: "#FC635B !important"},
  requestLabel: {color: "#4AA44D !important"},
  messageLabel: {color: theme.palette.secondary.main + "!important"},
  unansweredMessageLabel: {color: theme.palette.secondary.main + "!important"},
  name: {color: "#435460"},
  listingName: {color: "#8C9196"},
  btn: {
    width: "fit-content",
    whiteSpace: "nowrap",
    height: "fit-content",
    minWidth: "fit-content",
    padding: theme.spacing(1, 3),
    "&.noPadd": {padding: 0},
  },
  actionMessage: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
    lineClamp: 3,
    flex: 1,
  },
  mobileBtn: {
    width: "100%",
    padding: theme.spacing(2, 3),
    "&.confirm": {backgroundColor: "#FFFFFF !important"},
  },
}));

const getButtonLoader = (isSecondary, mobile, type) => {
  return (
    <ButtonLabelLoader
      variant={
        mobile
          ? isSecondary
            ? "disabled"
            : type
          : isSecondary
            ? "disabled"
            : "white"
      }
    />
  );
};

const BookingCard = ({
  booking,
  untilLabel = null,
  actions = [],
  loadNextPage,
  openGuest,
  mobile,
  selected,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const btnClicked = useRef(false);
  // Selectors
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const listings =
    useSelector((state) => state.defaultReducer.house_data) || [];
  const listing =
    listings.find((l) => l.listing_id === booking.listing_id) || {};

  const handleOpenGuest = () => {
    if (!btnClicked.current) {
      openGuest();
    } else {
      btnClicked.current = false;
    }
  };

  const handleBkgActionClick = (btn) => {
    btnClicked.current = true;
    btn.action();
  };

  React.useEffect(() => {
    if (!listing.listing_id) {
      dispatch(
        getHouses(
          `listing_id=${booking.listing_id}&enso_key=${current_user}`,
          true,
        ),
      );
    }
  }, [listing]);

  return (
    <div
      className={clsx(classes.col, classes.card, {
        mobile: !!mobile,
        selected: !!selected,
      })}
      onClick={handleOpenGuest}
    >
      {!!loadNextPage && <Waypoint onEnter={() => loadNextPage()} />}
      <Box className={classes.row}>
        <Box className={classes.col} width={"100%"}>
          <Box className={classes.row} justifyContent="space-between">
            <Typography
              variant="subtitle2"
              className={classes.name}
              style={{flex: 1}}
            >
              {booking.name}
            </Typography>
            {!!untilLabel && untilLabel}
          </Box>
          <Typography
            className={classes.listingName}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {listing.nickname || booking.listing}
          </Typography>
        </Box>
      </Box>
      {actions.map((act) => {
        return (
          <Box
            key={`${act.type}${act.message}`}
            className={clsx(classes.gap12, {
              [classes.row]: !mobile,
              [classes.col]: !!mobile,
            })}
            alignItems="center"
            py={1}
          >
            <Box
              width={"100%"}
              className={clsx(classes.row, classes.gap12)}
              alignItems="center"
              justifyContent="flex-start"
            >
              <div className={clsx(classes.dot, classes[act.type])} />
              <Typography className={classes.actionMessage}>
                {act.message}
              </Typography>
            </Box>
            <Box
              className={clsx(classes.gap12, {
                [classes.row]: !mobile,
                [classes.col]: !!mobile,
                reverse: !!mobile,
              })}
              width={"100%"}
              flex={!!mobile ? "auto" : 1}
              alignItems="center"
              justifyContent="flex-start"
            >
              {act.buttons.map((btn) => {
                let isSecondary = btn.type === "secondary";
                return (
                  <PrimaryButton
                    sm
                    className={clsx(classes.btn, {
                      noPadd: isSecondary,
                      [classes.mobileBtn]: !!mobile,
                      confirm: !!mobile && !isSecondary,
                      [classes.pendingLabel]:
                        !!mobile && act.type === "pending" && !isSecondary,
                      [classes.requestLabel]:
                        !!mobile && act.type === "request" && !isSecondary,
                      [classes.messageLabel]:
                        !!mobile && act.type === "message" && !isSecondary,
                      [classes.unansweredMessageLabel]:
                        !!mobile &&
                        act.type === "unansweredMessage" &&
                        !isSecondary,
                    })}
                    key={`${btn.label}${act.message}`}
                    subdued={!!isSecondary}
                    color="primary"
                    disabled={btn.disabled}
                    variant={isSecondary ? "text" : "contained"}
                    label={
                      btn.loading
                        ? getButtonLoader(isSecondary, mobile, act.type)
                        : btn.label
                    }
                    onClick={() => handleBkgActionClick(btn)}
                  />
                );
              })}
            </Box>
          </Box>
        );
      })}
    </div>
  );
};

export default BookingCard;
