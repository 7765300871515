import React, {useState, useEffect} from "react";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import {makeStyles} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/WarningRounded";
import ExpandableItem from "core/listItems/ExpandableItem";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  list: {flex: 1},
  warningIcon: {color: "#EE4F4F"},
  hideScrollbar: {"&::-webkit-scrollbar": {width: "0!important"}},
}));

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 100,
});

function Collapsible({
  children,
  title,
  onChange,
  loadingItems,
  description,
  icon,
  isSelected,
  onExpand,
}) {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (isSelected && !expanded) {
      setExpanded(!expanded);
    } else if (!isSelected && expanded) {
      setExpanded(!expanded);
    }
  }, [isSelected]);

  useEffect(() => {
    onChange && onChange();
  }, [expanded, isSelected, onChange]);

  const handleExpand = () => {
    setExpanded(!expanded);
    onExpand();
  };

  return (
    <>
      <ExpandableItem
        disabled={loadingItems}
        open={expanded}
        title={title}
        description={description}
        descIcon={icon}
        onClick={handleExpand}
      />
      {expanded && <>{children}</>}
    </>
  );
}

const renderRow =
  (props) =>
  ({index, key, style, parent}) => {
    let li = props.loadingItems
      ? {
          index: index,
          listing_id: props.connectedListings[index],
          hideIcon: true,
        }
      : props.allListings[index];
    let isSelected = props.selectedListing?.listing_id === li?.listing_id;
    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {({registerChild, measure}) => (
          <div style={style} ref={registerChild}>
            <Collapsible
              isSelected={isSelected}
              onChange={measure}
              title={li.name === null ? "" : (li.name ?? "Loading listing...")}
              onExpand={() => props.onExpand(li)}
              icon={
                !!li.hideIcon ? null : (
                  <WarningIcon className={props.classes.warningIcon} />
                )
              }
              description={props.getDescription(li)}
              loadingItems={props.loadingItems}
            >
              {props.content}
            </Collapsible>
          </div>
        )}
      </CellMeasurer>
    );
  };

function WifiList({
  loadingItems,
  getDescription,
  connectedListings,
  allListings,
  selectedListing,
  content,
  onExpand = () => {},
}) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.list}>
        <AutoSizer>
          {({width, height}) => (
            <List
              width={width}
              height={height}
              className={classes.hideScrollbar}
              deferredMeasurementCache={cache}
              rowHeight={cache.rowHeight}
              rowRenderer={renderRow({
                classes,
                loadingItems,
                getDescription,
                connectedListings,
                selectedListing,
                allListings,
                content,
                onExpand,
              })}
              rowCount={
                loadingItems ? connectedListings.length : allListings.length
              }
              overscanRowCount={3}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
}

export default WifiList;
