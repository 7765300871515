import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {Col, Row} from "reactstrap";
import {Box, IconButton, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
// Custom
import InputField from "core/inputs/InputField";
import {EmptyListingAccess} from "components/Helpers/EmptyPanels";
import LanguageSelector from "components/MultiOption/LanguageSelector";
import CustomCardHeader from "core/cards/CustomCardHeader";
import ListingContentList from "../../Lists/ListingContentList";
import BackButton from "core/buttons/BackButton";
import CheckinStepList from "../../Lists/CheckinStepList";
// Actions
import {editHouse, updateListing} from "redux/actions/listingsActions";
// Utils
import {
  addArrayItemsToSet,
  get12hrStringFormat,
} from "utilities/helperFunctions";
import {THEME} from "configuration/settings";
import usePrevious from "hooks/usePrevious";
import {format, isValid} from "date-fns";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  cardHeader: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.primary.main + "0D"}`,
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: theme.spacing(4),
    position: "relative",
  },
  selectorContainer: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "#FFF",
  },
  closeBtn: {
    padding: 2,
    marginLeft: theme.spacing(3),
    marginRight: -theme.spacing(2),
  },
  closeBtnIcon: {
    fontSize: 18,
    color: "#AAA",
  },
  timePicker: {margin: 0},
}));

export default function ListingAccessPanel({
  outsideView,
  listing,
  isParentLoading,
  onClose,
  disableEdit,
  goBack,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const updatedFields = React.useRef([]);
  const contentLoaded = React.useRef(false);
  const defaultLang = useSelector((state) => state.defaultReducer.language);
  const loadingContent = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_content;
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  // State
  const [selectedLang, setSelectedLang] = React.useState(defaultLang);
  const [availableLangs, setAvailableLangs] = React.useState([]);
  const [checkin, setCheckin] = React.useState(new Date());
  const [checkout, setCheckout] = React.useState(new Date());
  const [invalidCheckin, setInvalidCheckin] = React.useState(false);
  const [invalidCheckout, setInvalidCheckout] = React.useState(false);
  const [checkinSteps, setCheckinSteps] = React.useState([]);
  const [checkoutSteps, setCheckoutSteps] = React.useState([]);
  const [isEditing, setIsEditing] = React.useState({});
  const [loadingSteps, setLoadingSteps] = React.useState({
    checkin: true,
    checkout: true,
  });
  const [multiLangValues, setMultiLangValues] = React.useState({
    checkinSteps: {languages: [], steps: []},
    checkoutSteps: {languages: [], steps: []},
  });
  // General
  const listingContent = listing?.listing_content || {};
  const prevLoadingContent = usePrevious(loadingContent);
  const invalidData = React.useMemo(() => {
    return invalidCheckin || invalidCheckout || !checkin || !checkout;
  }, [invalidCheckin, invalidCheckout, checkin, checkout]);

  React.useEffect(() => {
    if (!!prevLoadingContent && !loadingContent) {
      contentLoaded.current = true;
    }
  }, [loadingContent]);

  React.useEffect(() => {
    if (Object.keys(isEditing).find((i) => !!isEditing[i])) {
      return;
    }
    const newCheckinTime = new Date(
      `${new Date().toDateString()} ${listing?.check_times.check_in_time || "16:00"}`,
    );
    const newCheckoutTime = new Date(
      `${new Date().toDateString()} ${listing?.check_times.check_out_time || "11:00"}`,
    );
    const newSteps = (listingContent?.access_method?.steps || []).map(
      (s, i) => ({...s, index: String(i)}),
    );
    const newCheckoutSteps = (
      listingContent?.access_method?.checkout_steps || []
    ).map((s, i) => ({...s, index: String(i)}));
    const allLangs = new Set();

    // Get all languages from all CHECKIN step descriptions
    _.each(newSteps, (s) => {
      _.each(s.properties.description || [], (desc) => {
        allLangs.add(desc.language);
      });
    });

    // Get all languages from all CHECKOUT step descriptions
    _.each(newCheckoutSteps, (s) => {
      _.each(s.properties.description || [], (desc) => {
        allLangs.add(desc.language);
      });
    });

    let newLangs = [...allLangs];
    setCheckin((prev) => newCheckinTime);
    setCheckout((prev) => newCheckoutTime);
    setMultiLangValues((prev) => ({
      checkinSteps: {languages: newLangs, steps: newSteps},
      checkoutSteps: {languages: newLangs, steps: newCheckoutSteps},
    }));
  }, [isEditing, listing]);

  React.useEffect(() => {
    const allAvalLangs = new Set();
    addArrayItemsToSet(multiLangValues.checkinSteps.languages, allAvalLangs);
    addArrayItemsToSet(multiLangValues.checkoutSteps.languages, allAvalLangs);
    const newAvalLangs = [...allAvalLangs];

    setCheckinSteps((prev) => multiLangValues.checkinSteps.steps);
    setCheckoutSteps((prev) => multiLangValues.checkoutSteps.steps);
    setAvailableLangs((prev) => newAvalLangs);
  }, [selectedLang, multiLangValues, listing]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setMultiLangValues((prev) => ({
        checkinSteps: {...prev.checkinSteps, steps: checkinSteps},
        checkoutSteps: {...prev.checkoutSteps, steps: checkoutSteps},
      }));
    }, 200);

    return () => clearTimeout(timer);
  }, [checkinSteps, checkoutSteps]);

  React.useEffect(() => {
    let newLoadingStates = {checkin: true, checkout: true};
    if (
      !!listingContent?.access_method?.steps?.length &&
      !checkinSteps.length
    ) {
      newLoadingStates.checkin = true;
    } else {
      newLoadingStates.checkin = false;
    }
    if (
      !!listingContent?.access_method?.checkout_steps?.length &&
      !checkoutSteps.length
    ) {
      newLoadingStates.checkout = true;
    } else {
      newLoadingStates.checkout = false;
    }
    setLoadingSteps((prev) => newLoadingStates);
  }, [checkinSteps, checkoutSteps]);

  const addUpdatedField = (f) => {
    if (!updatedFields.current.includes(f)) {
      updatedFields.current.push(f);
    }
  };

  const handleCheckinTimeChange = (newValue) => {
    setCheckin((prev) => newValue);
    addUpdatedField("checkin_time");
  };

  const handleCheckoutTimeChange = (newValue) => {
    setCheckout((prev) => newValue);
    addUpdatedField("checkout_time");
  };

  const handleCancel = (type) => () => {
    updatedFields.current = [];
    if (type === "checkin") {
      const newCheckinTime = new Date(
        `${new Date().toDateString()} ${listing?.check_times.check_in_time || "16:00"}`,
      );
      setCheckin((prev) => newCheckinTime);
    } else if (type === "checkout") {
      const newCheckoutTime = new Date(
        `${new Date().toDateString()} ${listing?.check_times.check_out_time || "11:00"}`,
      );
      setCheckout((prev) => newCheckoutTime);
    }
  };

  const handleStepsChange = (newSteps, isCheckout = false) => {
    setMultiLangValues((prev) => {
      if (isCheckout) {
        return {
          ...prev,
          checkoutSteps: {...prev.checkoutSteps, steps: newSteps},
        };
      } else {
        return {...prev, checkinSteps: {...prev.checkinSteps, steps: newSteps}};
      }
    });

    if (isCheckout) {
      setCheckoutSteps((prev) => newSteps);
    } else {
      setCheckinSteps((prev) => newSteps);
    }
  };

  const addLangToSteps = (lang) => {
    const newSteps = {...multiLangValues.checkinSteps};
    const newCheckoutSteps = {...multiLangValues.checkoutSteps};

    // Checkin
    const newLangSteps = multiLangValues.checkinSteps.steps.map((s) => {
      const langExists = s.properties.description.find(
        (d) => d.language === lang && !!d.value?.trim(),
      );
      if (langExists) {
        return s;
      } else {
        return {
          ...s,
          properties: {
            ...s.properties,
            description: [
              ...s.properties.description,
              {language: lang, value: ""},
            ],
          },
        };
      }
    });

    newSteps.steps = newLangSteps;
    if (!!newSteps.languages.length && !newSteps.languages.includes(lang)) {
      newSteps.languages.push(lang);
    }

    // Checkout
    const newCheckoutLangSteps = multiLangValues.checkoutSteps.steps.map(
      (s) => {
        const langExists = s.properties.description.find(
          (d) => d.language === lang && !!d.value?.trim(),
        );
        if (langExists) {
          return s;
        } else {
          return {
            ...s,
            properties: {
              ...s.properties,
              description: [
                ...s.properties.description,
                {language: lang, value: ""},
              ],
            },
          };
        }
      },
    );

    newCheckoutSteps.steps = newCheckoutLangSteps;
    if (
      !!newCheckoutSteps.languages.length &&
      !newCheckoutSteps.languages.includes(lang)
    ) {
      newCheckoutSteps.languages.push(lang);
    }

    setMultiLangValues((prev) => ({
      ...prev,
      checkinSteps: newSteps,
      checkoutSteps: newCheckoutSteps,
    }));
  };

  const removeLangFromSteps = (lang) => {
    setMultiLangValues((prev) => ({
      checkinSteps: {
        ...prev.checkinSteps,
        steps: prev.checkinSteps.steps.map((s) => {
          return {
            ...s,
            properties: {
              ...s.properties,
              description: s.properties.description.filter(
                (d) => d.language !== lang,
              ),
            },
          };
        }),
        languages: prev.checkinSteps.languages.filter((l) => l !== lang),
      },
      checkoutSteps: {
        ...prev.checkoutSteps,
        steps: prev.checkoutSteps.steps.map((s) => {
          return {
            ...s,
            properties: {
              ...s.properties,
              description: s.properties.description.filter(
                (d) => d.language !== lang,
              ),
            },
          };
        }),
        languages: prev.checkoutSteps.languages.filter((l) => l !== lang),
      },
    }));
  };

  const addLang = (lang) => {
    addLangToSteps(lang);
    setAvailableLangs((prev) => [...prev, lang]);
    setSelectedLang((prev) => lang);
  };

  const removeLang = (lang) => {
    let newLangs = availableLangs.filter((l) => l !== lang);
    setAvailableLangs((prev) => newLangs);
    setSelectedLang((prev) => (prev === lang ? newLangs[0] : prev));
    removeLangFromSteps(lang);
  };

  function getData() {
    let body = null;

    if (updatedFields.current.includes("checkin_time")) {
      body = {
        check_times: {
          check_in_time: isValid(checkin) ? format(checkin, "HH:mm") : null,
          check_in_to_time: "20:00",
        },
      };
    }
    if (updatedFields.current.includes("checkout_time")) {
      body = {
        ...body,
        check_times: {
          ...body?.check_times,
          check_out_time: isValid(checkout) ? format(checkout, "HH:mm") : null,
        },
      };
    }
    return body;
  }

  function handleSave(type, option = null) {
    const body = getData();
    if (!body) {
      return;
    }
    const newListing = Object.assign({}, listing);
    newListing.check_times[type] = body.check_times[type];
    dispatch(editHouse(newListing));
    dispatch(updateListing({body, listing, option}));
    updatedFields.current = [];
  }

  const checkinStepFields = (
    <CheckinStepList
      edit={!disableEdit}
      lang={selectedLang}
      data={checkinSteps}
      listing={listing}
      loading={loadingSteps.checkin}
      setIsEditing={(newVal) =>
        setIsEditing((prev) => ({...prev, checkin_step: newVal}))
      }
      onChange={(newSteps) => handleStepsChange(newSteps)}
    />
  );

  const checkoutStepFields = (
    <CheckinStepList
      isCheckout
      edit={!disableEdit}
      lang={selectedLang}
      data={checkoutSteps}
      listing={listing}
      loading={loadingSteps.checkout}
      setIsEditing={(newVal) =>
        setIsEditing((prev) => ({...prev, checkout_step: newVal}))
      }
      onChange={(newSteps) => handleStepsChange(newSteps, true)}
    />
  );

  // Title components
  const languageSelector = React.useMemo(() => {
    return (
      <LanguageSelector
        preventOverflow
        selectedLangs={availableLangs}
        selectedLanguage={selectedLang}
        addLang={addLang}
        removeLang={removeLang}
        selectLang={setSelectedLang}
      />
    );
  }, [listing, selectedLang, availableLangs]);

  const title = (
    <CustomCardHeader
      title={
        !!goBack ? (
          <BackButton
            header={!isMobileView}
            color={!!isMobileView ? "secondary" : "primary"}
            goBack={goBack}
          />
        ) : (
          "Check-in & checkout instructions"
        )
      }
      className={classes.cardHeader}
      action={
        !goBack && (
          <IconButton className={classes.closeBtn} onClick={onClose}>
            <CloseIcon className={classes.closeBtnIcon} />
          </IconButton>
        )
      }
    />
  );

  const outsideTitleRow = (
    <ListingContentList
      listingId={listing?.listing_id}
      onlyItems={["access"]}
      disableBackground
      actions={languageSelector}
    />
  );

  return (
    <div className={classes.root}>
      {title}
      {!!outsideView && outsideTitleRow}
      <div className={classes.content}>
        {isParentLoading || (loadingContent && !contentLoaded.current) ? (
          <EmptyListingAccess loading />
        ) : (
          <>
            {!outsideView && (
              <div className={classes.selectorContainer}>
                {languageSelector}
              </div>
            )}
            <Row style={{marginTop: THEME.spacing.lg}}>
              <Col xs={12}>
                <Box width={"100%"} mb={3}>
                  <Typography variant="h1">
                    {"Check-in & checkout times"}
                  </Typography>
                </Box>
              </Col>
              <Col xs={12} md={6} className="mb-4">
                <InputField
                  fullWidth
                  type="timepicker"
                  useMultiOptionSave
                  label="Check-in time"
                  value={get12hrStringFormat(
                    listing?.check_times.check_in_time,
                  )}
                  placeholder="e.g: 01:00 PM"
                  onChange={handleCheckinTimeChange}
                  onCancel={handleCancel("checkin")}
                  onSave={(opt) => handleSave("check_in_time", opt)}
                  setIsEditing={(newVal) =>
                    setIsEditing((prev) => ({...prev, checkin_time: newVal}))
                  }
                  isInvalid={invalidData}
                  timePickerProps={{
                    type: "time",
                    value: checkin,
                    onInvalidChange: (isInvalid) =>
                      setInvalidCheckin((prev) => isInvalid),
                    className: classes.timePicker,
                  }}
                />
              </Col>
              <Col xs={12} md={6} className="mb-4">
                <InputField
                  fullWidth
                  type="timepicker"
                  useMultiOptionSave
                  label="Checkout time"
                  value={get12hrStringFormat(
                    listing?.check_times.check_out_time,
                  )}
                  placeholder="e.g: 12:00 PM"
                  onChange={handleCheckoutTimeChange}
                  onCancel={handleCancel("checkin")}
                  onSave={(opt) => handleSave("check_out_time", opt)}
                  setIsEditing={(newVal) =>
                    setIsEditing((prev) => ({...prev, checkout_time: newVal}))
                  }
                  isInvalid={invalidData}
                  timePickerProps={{
                    type: "time",
                    value: checkout,
                    onInvalidChange: (isInvalid) =>
                      setInvalidCheckout((prev) => isInvalid),
                    className: classes.timePicker,
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                className="mb-4"
                style={{marginTop: THEME.spacing.md}}
              >
                {checkinStepFields}
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                className="mb-4"
                style={{marginTop: THEME.spacing.md}}
              >
                {checkoutStepFields}
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
}
