import React from "react";
import {isMobile} from "react-device-detect";
import {makeStyles} from "@material-ui/core/styles";
import {onboardingContent} from "configuration/specs";
import {Button, Typography} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: (props) => (!!props.banner ? 0 : 10),
    background: "rgba(47, 122, 251, 0.10)",
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(4),
  },
  summary: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    padding: (props) => theme.spacing(!!props.banner ? 4 : 3),
    flex: 1,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(3),
  },
  img: {
    width: "30%",
    objectFit: "contain",
    height: "fit-content",
    maxHeight: 100,
    alignSelf: "flex-end",
  },
  btn: {
    borderRadius: 25,
    textTransform: "none",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    padding: theme.spacing(1, 3),
    width: "fit-content",
  },
  buttons: {flexWrap: "wrap"},
}));

const GJEInfoCard = ({type, banner}) => {
  const classes = useStyles({banner});
  let typeObj = type.includes(".") ? type.split(".") : null;
  let content = !!typeObj
    ? onboardingContent[typeObj[0]][typeObj[1]]
    : onboardingContent[type];

  const handleBtnClick = (articleId) => () => {
    if (!!window.Intercom && !isMobile) {
      window.Intercom("showArticle", articleId);
    }
  };

  if (!content) {
    return null;
  }
  return (
    <div className={classes.card}>
      <div className={classes.summary}>
        <Typography variant="subtitle2" color="primary">
          {content.description}
        </Typography>
        <div className={clsx(classes.row, classes.buttons)}>
          <Button
            key={"learn-more"}
            id={"learn-more"}
            disableRipple
            disableElevation
            className={classes.btn}
            onClick={handleBtnClick(content.buttons[0].article_id)}
          >
            Learn More
          </Button>
        </div>
      </div>
      <img className={classes.img} src={content.image_url} />
    </div>
  );
};

export default GJEInfoCard;
