import React from "react";
import {SvgIcon} from "@material-ui/core";
import {ReactComponent as DamageIcon} from "assets/icons/hammer.svg";
import {ReactComponent as PetIcon} from "assets/icons/pet_print.svg";
import {ReactComponent as CheckinIcon} from "assets/icons/checkin.svg";
import {ReactComponent as CheckoutIcon} from "assets/icons/checkout.svg";
import {ReactComponent as CoffeeIcon} from "assets/icons/coffee.svg";
import {ReactComponent as CleanIcon} from "assets/icons/clean.svg";
import {ReactComponent as FridgeIcon} from "assets/icons/fridge.svg";
import {ReactComponent as CarbonIcon} from "assets/icons/carbon.svg";

export default function ProductIcon({type, product}) {
  function getProductIcon() {
    if (type === "fees") {
      return (
        <SvgIcon
          style={{fontSize: 24}}
          component={product === "pet_fee" ? PetIcon : DamageIcon}
          viewBox={product === "pet_fee" ? "0 0 23 23" : "0 0 25 24"}
        />
      );
    } else {
      let icon = null;
      let viewBox = "";
      switch (product) {
        case "checkin":
          icon = CheckinIcon;
          viewBox = "0 -0.5 22 23";
          break;
        case "checkout":
          icon = CheckoutIcon;
          viewBox = "0 -0.5 22 23";
          break;
        case "stocked_fridge":
          icon = FridgeIcon;
          viewBox = "0 0 18 28";
          break;
        case "mid_stay_clean":
          icon = CleanIcon;
          viewBox = "0 0 20 21";
          break;
        case "coffee_pods":
          icon = CoffeeIcon;
          viewBox = "0 0 22 24";
          break;
        case "welcome_basket":
          icon = null;
          viewBox = "";
          break;
        case "carbon_offset":
          icon = CarbonIcon;
          viewBox = "0 0 23 24";
          break;
        case "tip_staff":
          icon = null;
          viewBox = "";
          break;
      }
      if (!!icon) {
        return (
          <SvgIcon style={{fontSize: 24}} component={icon} viewBox={viewBox} />
        );
      } else {
        return null;
      }
    }
  }

  return getProductIcon();
}
