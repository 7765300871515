import React, {useRef, useState} from "react";
import {DateRangePickerCalendar, START_DATE, END_DATE} from "react-nice-dates";
// UI
import {Box, List, ListItem, ListItemText} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import useDashboardStyles from "styles/useDashboardStyles";
import useCalendarStyles from "styles/useCalendarStyles";
// Custom
import CustomMenu from "core/menus/CustomMenu";
import ExpandButton from "core/buttons/ExpandButton";
// Utils
import {
  addDays,
  differenceInDays,
  endOfDay,
  format,
  startOfDay,
  subDays,
} from "date-fns";
import {enCA} from "date-fns/locale";
import clsx from "clsx";
const now = new Date();
const minPastDate = new Date("10 Mar 2024");

const useStyles = makeStyles((theme) => ({
  modalContent: {
    width: 380,
    height: 284,
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2),
  },
  list: {
    borderRadius: 5,
    background: "#F5F8FF",
    flex: 1,
  },
  listItem: {padding: 0},
  itemText: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    padding: theme.spacing(3),
    "&.selected": {color: theme.palette.common.white},
  },
  calendar: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2),
  },
  selected: {
    backgroundColor: theme.palette.primary.main + "!important",
    borderRadius: 4,
  },
}));

function getBtnLabel(range, isPast) {
  if (
    Math.abs(
      differenceInDays(
        startOfDay(new Date(range[isPast ? 1 : 0])),
        startOfDay(isPast ? now : subDays(now, 1)),
      ),
    ) > 1
  ) {
    let start = format(range[0], "MMM d");
    let end = !range[1] ? "-" : format(range[1], "MMM d");
    return `From ${start} to ${end}`;
  } else {
    let numOfDays = Math.abs(
      differenceInDays(
        isPast ? addDays(now, 1) : subDays(now, 1),
        range[isPast ? 0 : 1] ?? range[0],
      ),
    );
    let timeUnit = numOfDays > 30 ? "months" : "days";
    return `${isPast ? "Last" : "Next"} ${numOfDays > 30 ? numOfDays / 30 : numOfDays} ${timeUnit}`;
  }
}

const TimePeriodModal = ({
  past,
  disableBtn,
  defaultTime = 29,
  selectedRange,
  onChange,
  rightPosition,
  disableDateRestriction,
}) => {
  const classes = useStyles();
  const calendarStyles = useCalendarStyles();
  const prevRange = useRef([...selectedRange]);
  const dashboardsStyles = useDashboardStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [calendarFocus, setCalendarFocus] = useState(START_DATE);
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: new Date(selectedRange[0]),
    end: new Date(selectedRange[1]),
  });
  const [timeTemplateSelected, setTimeTemplateSelected] = useState(defaultTime);

  const openMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const closeMenu = (clickAway) => {
    if (clickAway && !selectedRange[1]) {
      onChange(prevRange.current);
      setSelectedDateRange((prev) => ({
        start: new Date(prevRange.current[0]),
        end: new Date(prevRange.current[1]),
      }));
      setCalendarFocus((prev) => START_DATE);
    }
    setAnchorEl((prev) => null);
  };

  const handleTimePeriodSelect = (time) => () => {
    let now = new Date();
    let startDate = past
      ? startOfDay(new Date(subDays(now, time))).getTime()
      : startOfDay(new Date(now)).getTime();
    let endDate = past
      ? endOfDay(new Date(now)).getTime()
      : endOfDay(new Date(addDays(now, time))).getTime();

    setTimeTemplateSelected((prev) => time);
    setSelectedDateRange((prev) => ({
      start: new Date(startDate),
      end: new Date(endDate),
    }));
    onChange([startDate, endDate], true);
    closeMenu();
  };

  const handleRangeSelect = (newDate, isEndDate) => {
    let newRange = [null, null];
    if (!isEndDate) {
      prevRange.current = [...selectedRange];
      newRange[0] = startOfDay(newDate).getTime();
      setSelectedDateRange((prev) => ({...prev, start: newDate, end: null}));
      setCalendarFocus((prev) => END_DATE);
      onChange(newRange);
    } else {
      newRange[0] = selectedRange[0];
      newRange[1] = endOfDay(newDate).getTime();
      setTimeTemplateSelected((prev) => null);
      setSelectedDateRange((prev) => ({...prev, end: newDate}));
      setCalendarFocus((prev) => START_DATE);
      onChange(newRange, true);
      closeMenu();
    }
  };

  return (
    <>
      <ExpandButton
        size="small"
        variant="contained"
        disabled={disableBtn}
        buttonClasses={{label: dashboardsStyles.lightSelectorLabel}}
        className={clsx(dashboardsStyles.lightSelector, {disabled: disableBtn})}
        label={getBtnLabel(selectedRange, past)}
        onClick={openMenu}
      />
      <CustomMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => closeMenu(true)}
        placement={rightPosition ? "bottom-start" : "bottom-end"}
        content={
          <div className={classes.modalContent}>
            <Box className={dashboardsStyles.col}>
              <List className={classes.list}>
                <ListItem
                  button
                  disableRipple
                  className={clsx(classes.listItem, {
                    [classes.selected]: timeTemplateSelected === 6,
                  })}
                >
                  <ListItemText
                    style={{margin: 0}}
                    primary={`${past ? "Last" : "Next"} 7 days`}
                    primaryTypographyProps={{
                      className: clsx(classes.itemText, {
                        selected: timeTemplateSelected === 6,
                      }),
                    }}
                    onClick={handleTimePeriodSelect(6)}
                  />
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  className={clsx(classes.listItem, {
                    [classes.selected]: timeTemplateSelected === 29,
                  })}
                >
                  <ListItemText
                    style={{margin: 0}}
                    primary={`${past ? "Last" : "Next"} 30 days`}
                    primaryTypographyProps={{
                      className: clsx(classes.itemText, {
                        selected: timeTemplateSelected === 29,
                      }),
                    }}
                    onClick={handleTimePeriodSelect(29)}
                  />
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  className={clsx(classes.listItem, {
                    [classes.selected]: timeTemplateSelected === 89,
                  })}
                >
                  <ListItemText
                    style={{margin: 0}}
                    primary={`${past ? "Last" : "Next"} 3 months`}
                    primaryTypographyProps={{
                      className: clsx(classes.itemText, {
                        selected: timeTemplateSelected === 89,
                      }),
                    }}
                    onClick={handleTimePeriodSelect(89)}
                  />
                </ListItem>
              </List>
            </Box>
            <Box className={classes.calendar}>
              <div className={clsx(calendarStyles.calendar, "subcalendar")}>
                <DateRangePickerCalendar
                  startDate={selectedDateRange.start}
                  endDate={selectedDateRange.end}
                  focus={calendarFocus}
                  touchDragEnabled={false}
                  minimumDate={
                    !past
                      ? now
                      : disableDateRestriction
                        ? undefined
                        : minPastDate
                  }
                  maximumDate={past ? now : undefined}
                  onStartDateChange={(newDate) => handleRangeSelect(newDate)}
                  onEndDateChange={(newDate) =>
                    handleRangeSelect(newDate, calendarFocus === END_DATE)
                  }
                  locale={enCA}
                />
              </div>
            </Box>
          </div>
        }
      />
    </>
  );
};

export default TimePeriodModal;
