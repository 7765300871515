import React from "react";
import {Typography, Box} from "@material-ui/core";
import FilledTextField from "core/inputs/FilledTextField";
import PrimaryButton from "core/buttons/PrimaryButton";
import ImageTextField from "components/TextFields/ImageTextField";
import CustomDialog from "core/dialogs/CustomDialog";
import {useDispatch} from "react-redux";
import {createAction} from "redux/actions/experiencesActions";
import WarningAlert from "core/alerts/WarningAlert";
import {useTranslation} from "react-i18next";

const UpdateKnowledgeInput = ({
  submitKnowledge,
  classes,
  listing,
  // customization
  placeholder_text = "Copy/Paste information about your listing: descriptions, amenities, local places of interest, check-in process etc..",
  description_text = "Add files or text containing information about your listing. EnsoAI will extract relevant information and summarize it in the correct article.",
  button_label = "Save Knowledge",
}) => {
  const dispatch = useDispatch();
  const [knowledgeLoader, setKnowledgeLoader] = React.useState(null);
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [newKnowledgeText, setNewKnowledgeText] = React.useState(null);
  const {t} = useTranslation();
  let isLoading = !["failed", "complete", undefined].includes(
    knowledgeLoader?.status,
  );
  let isError = knowledgeLoader?.status == "failed";
  let isComplete = knowledgeLoader?.status == "complete";
  let contentLabel = !!uploadedFile
    ? `PDF ${uploadedFile?.content_name}`
    : "Knowledge";
  let saveDisabled = !newKnowledgeText && !uploadedFile;

  React.useEffect(() => {
    let uploaded_content = listing?.listing_content?.uploaded_content;
    if (!knowledgeLoader && uploaded_content?.length > 0) {
      setKnowledgeLoader(uploaded_content[uploaded_content.length - 1]);
    }
  }, [listing]);

  const saveKnowledgeBase = (uploaded_file) => {
    setNewKnowledgeText("");
    setKnowledgeLoader({
      content_url: uploaded_file?.content_url,
      content_name: uploaded_file?.content_name,
      status: "extracting_content",
    });
    if (saveDisabled) {
      submitKnowledge(null);
      return;
    }
    dispatch(
      createAction({
        listingId: listing?.listing_id,
        action: {
          action_id: "update_knowledge_base",
          content: newKnowledgeText,
          content_url: uploaded_file?.content_url,
          content_name: uploaded_file?.content_name,
          extract_content_types: !!newKnowledgeText
            ? []
            : [
                {
                  content_type: "AMENITY",
                  content_type_context:
                    "include details and instructions about on-site amenities like kitchen, entertainment, outdoor amenities, appliances, toilet, gym, laundry, pool etc.. but exclude off-site/local amenities like restaurants and bars",
                  content_split: 4,
                },
              ],
        },
        onSuccess: (r) => {
          console.log("UPDATED KNOWLEDGE BASE", r);
          //setKnowledgeLoader(null);
          //submitKnowledge(r?.action?.response)
        },
        onError: (err) => {
          console.log(err);
          setKnowledgeLoader({status: "failed"});
        },
      }),
    );
  };

  function getKnowledgeLabel() {
    if (isError) {
    }
  }

  return (
    <Box>
      <Typography>{description_text}</Typography>
      {!!knowledgeLoader && (
        <>
          <div style={{height: 10}} />
          <WarningAlert
            sync={isLoading}
            small
            disableMargin
            severity={isError && "critical"}
            actionButtonLabel={isError && "Retry"}
            action={isError && saveKnowledgeBase(knowledgeLoader?.content_url)}
            title={
              {
                extracting_content: `Uploading ${contentLabel}`,
                content_extracted: `Analyzing ${contentLabel}`,
                content_analyzed: `Analyzing ${contentLabel}`,
                updated_article: `Updating Articles from ${contentLabel}...`,
                complete: `${contentLabel} uploaded succesfully`,
                failed: `Failed to upload ${contentLabel}`,
              }[knowledgeLoader?.status]
            }
            subtitle={
              {
                extracting_content: "Content is being extracted",
                content_extracted:
                  "Content extraction complete. Analyzing content",
                content_analyzed:
                  "Content analysis complete. Saving to knowledge base articles",
                updated_article:
                  "Sucessfully updated 2/4 articles: Amenities, policies ",
                complete: "PDF Upload Complete",
                failed: "EnsoAI request timed out. Please retry the upload.",
              }[knowledgeLoader?.status]
            }
          />
        </>
      )}
      <div style={{height: 10}} />
      <FilledTextField
        id="add_to_knowledge_base"
        fullWidth
        multiline
        value={newKnowledgeText}
        placeholder={placeholder_text}
        onChange={(e) => setNewKnowledgeText(e.target.value)}
      />
      <div style={{height: 10}} />
      <ImageTextField
        picture={uploadedFile?.content_name}
        available_pictures={[]}
        setPicture={(e) => {
          console.log("GOT CONTENT URL", e);
          setUploadedFile(e);
        }}
        label={`Upload PDF`}
        assetType={"pdf"}
        asset_types={["pdf"]}
      />
      <div style={{display: "flex", marginTop: 10}}>
        <div style={{flex: 1}} />
        <PrimaryButton
          color="secondary"
          label={button_label}
          disabled={saveDisabled}
          onClick={() => saveKnowledgeBase(uploadedFile)}
        />
      </div>
    </Box>
  );
};

export default UpdateKnowledgeInput;
