import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {
  Box,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import useDashboardStyles from "styles/useDashboardStyles";
// Custom
import TimePeriodModal from "components/Dialogs/TimePeriodModal";
import Rating from "components/TextFields/Rating";
import InfoText from "components/Misc/InfoText";
// Redux
import {getDashboardGuestSatisfaction} from "redux/actions/dashboardActions";
// Utils
import {endOfDay, startOfDay, subDays} from "date-fns";
import infoTexts from "assets/infoTexts";
import clsx from "clsx";
import {FIXED_SIZES} from "configuration/settings";
import {guestViewsUrlMap, satisfaction} from "../../../configuration/constants";
import {buildGuestFilterUrl} from "../../../utilities/helperFunctions";

const now = new Date();

const GuestStatsCard = ({
  loading,
  selectedGroups = [],
  row,
  refreshDasboard,
  onRefreshCompleted,
  mobile,
  saveSelectedRange = () => {},
}) => {
  const classes = useDashboardStyles();
  const dispatch = useDispatch();
  const didMount = useRef(false);
  const didRefresh = useRef(false);
  const data = useSelector(
    (state) => state.dashboardReducer.guestSatisfactionData,
  );
  const selectedViewItems = useSelector(
    (state) => state.defaultReducer.selected_view_items,
  );
  let savedProps = selectedViewItems.dashboard.props;
  const [localLoading, setLocalLoading] = useState(false);
  const [dateRange, setDateRange] = useState(
    !!savedProps.guestsStatsRange
      ? savedProps.guestsStatsRange
      : [
          new Date(startOfDay(subDays(now, 29))).getTime(),
          new Date(endOfDay(now)).getTime(),
        ],
  );
  let avgRating = data?.avg_rating ?? 0;
  let ratingRange = (data?.rating_range || []).sort((a, b) => b.key - a.key);

  function getData(range) {
    dispatch(
      getDashboardGuestSatisfaction({
        start_date: range[0],
        end_date: range[1],
        listing_group_ids: !!selectedGroups.length ? selectedGroups : null,
        onSuccess: (res) => {
          if (!!didRefresh.current) {
            didRefresh.current = false;
            onRefreshCompleted();
          }
          setLocalLoading((prev) => false);
        },
        onError: () => {
          setLocalLoading((prev) => false);
        },
      }),
    );
  }

  useEffect(() => {
    if (!!refreshDasboard && !didRefresh.current) {
      didRefresh.current = true;
      getData(dateRange);
    }
  }, [refreshDasboard]);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      if (!!savedProps.guestsStatsRange) {
        didRefresh.current = true;
        getData(dateRange);
      }
      return;
    }
    setLocalLoading((prev) => true);
    getData(dateRange);
  }, [selectedGroups]);

  const handleTimePeriodChange = (newRange, isRangeComplete) => {
    setDateRange((prev) => newRange);
    if (isRangeComplete) {
      saveSelectedRange(newRange);
      setLocalLoading((prev) => true);
      getData(newRange);
    }
  };

  const timePeriodModal = (
    <TimePeriodModal
      past
      disableBtn={loading || localLoading}
      selectedRange={dateRange}
      onChange={handleTimePeriodChange}
    />
  );

  const handleItemClick = (satisfactionNumber) => {
    let satisfactionTitle = Object.values(satisfaction).find(
      (item) => item.value === parseInt(satisfactionNumber.key),
    )?.label;

    if (satisfactionTitle) {
      const url = buildGuestFilterUrl(
        [{id: "rating", value: satisfactionTitle}],
        [
          "guest_name",
          "guest_email",
          "guest_phone",
          "guest_destination",
          "rating",
        ],
      );
      window.location.href = "/admin/guests" + url;
    }
  };

  const infoElement = (
    <InfoText disableGutters text={infoTexts.guestSatisfaction} subdued />
  );

  return (
    <div
      className={clsx(classes.card, "nogap", "padd", classes.flex1, {
        lg: !!row,
        mobile: !!mobile,
      })}
      style={{height: FIXED_SIZES.dashboard_sm_card}}
    >
      <Box
        className={clsx(classes.row, classes.cardHeader, classes.gap8, {
          middle: !!mobile,
        })}
        flex={loading || localLoading ? 0 : 1}
      >
        <Box className={clsx(classes.row, classes.gap8)} alignItems="center">
          <Typography variant="h2" className={classes.cardTitle}>
            {"Guest satisfaction"}
          </Typography>
          {!!mobile && infoElement}
        </Box>
        <Box className={clsx(classes.row, classes.gap8)} alignItems="center">
          {!mobile && infoElement}
          {!mobile && !loading && <Rating value={avgRating} useIcon />}
          {!mobile && timePeriodModal}
        </Box>
      </Box>
      {!!mobile && (
        <Box
          className={classes.row}
          alignItems="center"
          justifyContent="space-between"
          mt={1}
        >
          <Box className={classes.row}>
            <Rating value={avgRating} useIcon />
          </Box>
          {timePeriodModal}
        </Box>
      )}
      {loading || localLoading ? (
        <Box mt={2} flex={1}>
          <Skeleton
            animation="wave"
            width="100%"
            height="100%"
            className={classes.skeleton}
          />
        </Box>
      ) : (
        <Box pt={3} className={classes.cardListContainer}>
          <List>
            {ratingRange.map((r) => {
              let progressValue = !r.guest_count
                ? 0
                : (r.guest_count * 100) / data.total_guests;
              return (
                <Tooltip title="Open in CRM" arrow>
                  <ListItem
                    key={`rating-${r.key}-stars`}
                    onClick={() => handleItemClick(r)}
                    className={classes.cardListItem}
                  >
                    <ListItemIcon
                      className={clsx(classes.listItemIcon, "nomargin")}
                    >
                      <Rating value={r.key} useIcon showNumber />
                    </ListItemIcon>
                    <LinearProgress
                      variant="determinate"
                      value={progressValue}
                      className={classes.starProgressbar}
                      classes={{
                        colorPrimary: classes.starProgressbarBG,
                        barColorPrimary: classes.starProgressbarBar,
                      }}
                    />
                    <Box minWidth={28}>
                      <Typography variant="h1" align="right">
                        {r.guest_count}
                      </Typography>
                    </Box>
                  </ListItem>
                </Tooltip>
              );
            })}
          </List>
        </Box>
      )}
    </div>
  );
};

export default GuestStatsCard;
