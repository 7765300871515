import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
// UI
import {
  Box,
  Collapse,
  Divider,
  ListItem,
  ListItemText,
  Paper,
} from "@material-ui/core";
import ArrowIcon from "@material-ui/icons/NavigateNext";
import useCreateFromExistingListStyles from "styles/useCreateFromExistingListStyles";
// Custom
import {ExistingResourceListLoader} from "components/Helpers/EmptyPanels";
import SelectableResourceItem from "core/listItems/SelectableResourceItem";
import EmptyContentText from "components/Misc/EmptyContentText";
import VirtualizedList from "./VirtualizedList";
// Actions
import {getListingGroupDetails} from "redux/actions/listingsActions";
// Utils
import {
  getConnectionLabel,
  getExperienceConnectionLabel,
  getObjectValueFromStringPath,
} from "utilities/formatUtilities";
import {
  EXISTING_RESOURCE_CONFIG,
  GJE_RESOURCE_PATHS,
} from "configuration/constants";
import {FIXED_SIZES} from "configuration/settings";
import clsx from "clsx";
import _ from "lodash";

/**
 * CreateFromExistingList
 * @param {{resource}} resource 'fee' | 'upsell' | 'checkin_step' | 'checkout_step' | 'guidebook' | 'experience' | 'keycard'
 * @returns A list of selectable resource items grouped by listing group
 */
export default function CreateFromExistingList({
  resource = "",
  lang,
  onSelect = () => null,
}) {
  const classes = useCreateFromExistingListStyles();
  const dispatch = useDispatch();
  const didMount = React.useRef(false);
  const listingListRef = React.useRef(null);
  const groups = useSelector((state) => state.defaultReducer.listing_groups);
  const groupsDict = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const listingsDict = useSelector(
    (state) => state.defaultReducer.house_data_dict,
  );
  const [loading, setLoading] = React.useState("list");
  const [expanded, setExpanded] = React.useState(null);
  const [groupData, setGroupData] = React.useState(
    groups.filter((g) => g.group_id !== "ALL"),
  );
  const [data, setData] = React.useState([]);
  const isStep = ["checkin_step", "checkout_step"].includes(resource);
  const isExp = resource === "experience";
  const isKeycard = resource === "keycard";

  React.useEffect(() => {
    if (!expanded && !!data.length) {
      setData((prev) => []);
    }
  }, [expanded]);

  React.useEffect(() => {
    setGroupData((prev) => prev.map((g) => groupsDict[g.group_id]));
  }, [groupsDict]);

  React.useEffect(() => {
    if (!!didMount.current) {
      return;
    }
    if (!!groups.length) {
      didMount.current = true;
      setLoading((prev) => null);
    }
  }, [groups]);

  const handleSteps = (groupId, firstLoad) => {
    let isCIN = resource === "checkin_step";
    let section = isCIN ? "ci" : "co";
    let card = isCIN ? "checkin_steps" : "checkout_steps";
    let cardData = getObjectValueFromStringPath(
      groupsDict[groupId]?.guest_journey,
      GJE_RESOURCE_PATHS[resource],
    );
    let isDataEmpty = firstLoad || !cardData;
    if (isDataEmpty) {
      if (!loading) {
        setLoading((prev) => "group_content");
      }
      dispatch(
        getListingGroupDetails({
          groupId,
          section,
          card,
          onSuccess: (res) => {
            let allSteps = {};
            let cardData = getObjectValueFromStringPath(
              res,
              GJE_RESOURCE_PATHS[resource],
            );
            _.each(cardData, (cd) => {
              _.each(cd.data, (d) => {
                allSteps[d.resource_id] = d;
              });
            });
            setData((prev) => Object.values(allSteps));
            setLoading((prev) => null);
          },
        }),
      );
    } else {
      let allSteps = {};
      _.each(cardData, (cd) => {
        _.each(cd.data, (d) => {
          allSteps[d.resource_id] = d;
        });
      });
      setData((prev) => Object.values(allSteps));
    }
  };

  const handleGroupClick = (groupId, index) => () => {
    const newVal = expanded === groupId ? null : groupId;
    const newList = [...groups].filter((g) => g.group_id !== "ALL");
    setGroupData((prev) => (!!newVal ? newList.slice(0, index + 1) : newList));
    let isCIN = isStep ? resource === "checkin_step" : false;
    let stepData = isStep
      ? groupsDict[groupId]?.guest_journey?.sections?.[isCIN ? "ci" : "co"]
          ?.cards?.[`${resource}s`]?.data || []
      : null;

    if (!!newVal) {
      if (
        !!groupsDict[groupId]?.guest_journey &&
        (!isStep || (isStep && !!stepData.length))
      ) {
        if (isExp) {
          let allExp = [];
          GJE_RESOURCE_PATHS.experience.forEach((path) => {
            allExp.push(
              ...(getObjectValueFromStringPath(
                groupsDict[groupId].guest_journey,
                path,
              ) || []),
            );
          });
          setData((prev) => allExp);
        } else if (isStep) {
          handleSteps(groupId);
        } else {
          setData((prev) =>
            getObjectValueFromStringPath(
              groupsDict[groupId].guest_journey,
              GJE_RESOURCE_PATHS[resource],
            ),
          );
        }
      } else {
        setLoading((prev) => "group_content");
        setData((prev) => []);
        dispatch(
          getListingGroupDetails({
            groupId: groupId,
            onSuccess: (response) => {
              if (isExp) {
                let allExp = [];
                GJE_RESOURCE_PATHS.experience.forEach((path) => {
                  allExp.push(
                    ...(getObjectValueFromStringPath(response, path) || []),
                  );
                });
                setData((prev) => allExp);
                setLoading((prev) => null);
              } else if (isStep) {
                handleSteps(groupId, true);
              } else {
                setData((prev) =>
                  getObjectValueFromStringPath(
                    response,
                    GJE_RESOURCE_PATHS[resource],
                  ),
                );
                setLoading((prev) => null);
              }
            },
          }),
        );
      }
    }
    setExpanded((prev) => newVal);
  };

  const handleResourceSelect = (res) => () => {
    onSelect(res);
  };

  const getGroupContent = ({index}) => {
    let res = data[index];
    let keycardDescription =
      getObjectValueFromStringPath(
        groupsDict[expanded]?.guest_journey,
        GJE_RESOURCE_PATHS.keycard_labels,
      )?.[index] ?? {};
    let title = isStep
      ? `Step`
      : isKeycard
        ? (keycardDescription.header ?? "")
        : getObjectValueFromStringPath(
            res,
            EXISTING_RESOURCE_CONFIG[resource].title,
          );
    let desc = isKeycard
      ? (keycardDescription.text ?? "")
      : getObjectValueFromStringPath(
          res,
          EXISTING_RESOURCE_CONFIG[resource].description,
        );

    return (
      <SelectableResourceItem
        key={isExp || isKeycard ? res.experience_id : res.resource_id}
        lang={lang}
        isExperience={isExp}
        title={title}
        description={desc}
        tag={
          isExp || isKeycard
            ? getExperienceConnectionLabel(res, listingsDict, groupsDict)
            : getConnectionLabel(res, listingsDict, groupsDict)
        }
        onClick={handleResourceSelect(res)}
      />
    );
  };

  function getGroupItem({index, selectedGroup}) {
    const g = selectedGroup ?? groupData[index];
    if (g.group_id === "ALL") {
      return null;
    }
    const isExpanded = expanded === g.group_id;
    const divider =
      index === groupData.length - 1 ? null : (
        <Divider className={clsx(classes.divider, {mt3: !!isExpanded})} />
      );

    return (
      <>
        <ListItem
          button
          disableRipple
          className={classes.listItem}
          onClick={handleGroupClick(g.group_id, index)}
        >
          <ListItemText
            primary={g.name}
            className={classes.listItemText}
            primaryTypographyProps={{variant: "h1"}}
          />
          <ArrowIcon className={clsx(classes.arrow, {expanded: isExpanded})} />
        </ListItem>
        {!isExpanded && divider}
      </>
    );
  }

  function getResourceList() {
    return (
      <Collapse
        in
        classes={{wrapper: classes.collapseWrapper}}
        mountOnEnter
        unmountOnExit
      >
        <Paper elevation={0} className={classes.paper}>
          {loading === "group_content" ? (
            <Box pt={2}>
              <ExistingResourceListLoader resources totalItems={3} />
            </Box>
          ) : (
            <div className={classes.resourcesContainer}>
              {!data.length ? (
                <EmptyContentText label="No resources" basic />
              ) : (
                <div
                  className={classes.innerVirtualList}
                  style={{
                    height:
                      data.length * FIXED_SIZES.copy_existing_resource_item,
                  }}
                >
                  <VirtualizedList
                    hideScrollbar
                    cacheClearDependency={expanded}
                    getRowItem={getGroupContent}
                    totalRows={data.length}
                    rowHeight={FIXED_SIZES.copy_existing_resource_item}
                  />
                </div>
              )}
            </div>
          )}
          <Divider className={clsx(classes.divider, "mt3")} />
        </Paper>
      </Collapse>
    );
  }

  return (
    <div className={classes.container}>
      {loading === "list" ? (
        <ExistingResourceListLoader />
      ) : (
        <div className={classes.listContainer}>
          <div>
            {groupData.map((g, i) => (
              <React.Fragment key={g.group_id}>
                {getGroupItem({index: i, selectedGroup: g})}
              </React.Fragment>
            ))}
          </div>
          {!!expanded && (
            <div className="listing-list" ref={listingListRef}>
              {getResourceList()}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

CreateFromExistingList.propTypes = {
  /** The resource to use. One of the following: 'fee' | 'upsell' | 'checkin_step' | 'checkout_step' | 'guidebook' | 'experience' | 'keycard' */
  resource: PropTypes.oneOf([
    "fee",
    "upsell",
    "checkin_step",
    "checkout_step",
    "guidebook",
    "experience",
    "keycard",
  ]),
};
