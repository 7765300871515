import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFlags, withLDConsumer} from "launchdarkly-react-client-sdk";
// UI
import {Card, CardContent, SvgIcon, Typography} from "@material-ui/core";
import useBookingPanelStyles from "styles/useBookingPanelStyles";
import {ReactComponent as CalendarIcon} from "assets/icons/calendar.svg";
// Custom
import BoardingPassInfo from "ui/component/messaging/list/BoardingPassInfo";
import BookingCustomFields from "components/Panels/Booking/BookingCustomFields";
import {EmptyBookingInfoPanel} from "components/Helpers/EmptyPanels";
import BookingSummary from "components/Panels/Booking/BookingSummary";
import ListingOverviewPanel from "../Listing/ListingOverviewPanel";
import BookingFeesUpsells from "./BookingFeesUpsells";
import BookingAccessCodes from "./BookingAccessCodes";
import BookingHeaderPanel from "./BookingHeaderPanel";
// Actions
import {
  closeGeneralSuccessAlert,
  openGeneralSuccessAlert,
} from "redux/actions/settingsActions";
import {editBooking, updateBooking} from "redux/actions/bookingsActions";
import {createAction} from "redux/actions/experiencesActions";
import {getHouses} from "redux/actions/listingsActions";
import {editGuest} from "redux/actions/guestsActions";
import {sleep} from "utilities/helperFunctions";
import {isMobile} from "react-device-detect";

function BookingPanel({booking, guestId, hideListingLink, openListingDetails}) {
  const classes = useBookingPanelStyles();
  const dispatch = useDispatch();
  const flags = useFlags();
  // Selectors
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const houses_dict = useSelector(
    (state) => state.defaultReducer.house_data_dict,
  );
  const guests_dict = useSelector((state) => state.defaultReducer.guests_dict);
  const loadingListings = useSelector(
    (state) => state.defaultReducer.loading,
  ).listings;
  const guests = useSelector((state) => state.defaultReducer.guests_dict);
  // State
  const [loading, setLoading] = React.useState([]);
  const [accessCodesOpen, setAccessCodesOpen] = React.useState(false);
  // General
  const guest = React.useMemo(() => guests[guestId] ?? {}, [guests, guestId]);
  const house = houses_dict[booking.listing_id];
  let bookingStatus = booking?.status || "inquiry";

  React.useEffect(() => {
    if (house === undefined) {
      dispatch(
        getHouses(
          `listing_id=${booking.listing_id}&enso_key=${current_user}`,
          true,
        ),
      );
    }
  }, [house]);

  const handleSuccessAlertClose = () => dispatch(closeGeneralSuccessAlert());

  const onSuccess = (label) => {
    removeLoading(label);
    dispatch(
      openGeneralSuccessAlert({
        open: true,
        message: "Booking updated",
        onClose: handleSuccessAlertClose,
      }),
    );
  };

  const onError = (label) => {
    removeLoading(label);
  };
  const removeLoading = (label) => {
    setLoading(loading.filter((l) => l !== label));
  };

  const openAccessCodes = (val) => {
    setAccessCodesOpen((prev) => val);
    sleep(500).then(() => setAccessCodesOpen((prev) => !val));
  };

  function handleBookingAction(props, action_type = null) {
    setLoading([...loading, props.label]);
    let action = null;
    if (!action_type) {
      action = props.action_options;
    } else {
      action = props[action_type].action_options;
    }
    dispatch(
      createAction({
        bookingId: booking.booking_id,
        guestId: guest?.guest_id,
        action,
        onSuccess: (r) => {
          if (action.action_id === "verification_report") {
            if (isMobile) {
              window.open(
                r.booking.verification_report_url,
                "_system",
                "location=yes",
              );
            } else {
              window.open(r.booking.verification_report_url);
            }
          }

          const currGuest = guests_dict[booking.guest_id];
          if (!!currGuest) {
            if (!!r.guest_bills) {
              dispatch(editGuest({...currGuest, bills: r.guest_bills}));
            }
            if (!!r.guest_bill) {
              const newBills = (currGuest?.bills || []).map((b) =>
                b.id === r.guest_bill.id ? r.guest_bill : b,
              );
              dispatch(editGuest({...currGuest, bills: newBills}));
            }
          }
          dispatch(editBooking(r.booking));
          onSuccess();
        },
        onError: onError,
      }),
    );
  }

  const handleCustomFieldUpdate = (id, customField) => {
    const body = {
      guest_id: booking.guest_id,
      booking_id: booking.booking_id,
      custom: customField,
    };
    dispatch(
      editBooking({
        ...booking,
        custom: {...booking.custom, [id]: customField[id]},
      }),
    );
    dispatch(updateBooking(body));
  };

  if (loadingListings) {
    return <EmptyBookingInfoPanel loading bkgPanel />;
  } else if (!house) return null;
  return (
    <Card className={classes.root}>
      {!!flags.bookingPanelRedesign ? (
        <BookingHeaderPanel
          booking={booking}
          guest={guest}
          openListingDetails={openListingDetails}
        />
      ) : (
        <ListingOverviewPanel
          cardHeader
          showAirbnbLink={!hideListingLink && booking.channel === "Airbnb"}
          openListingDetails={openListingDetails}
          status={bookingStatus}
          listingId={booking.listing_id}
          channel={booking.channel}
        />
      )}

      <CardContent classes={{root: classes.cardContent}} id={"booking-info"}>
        {!flags.bookingPanelRedesign && (
          <div className={classes.subtitleRow}>
            <SvgIcon
              viewBox="0 0 16 16"
              className="-icon"
              component={CalendarIcon}
            />
            <Typography variant="h1">{"Booking Information"}</Typography>
          </div>
        )}
        <BookingSummary
          booking={booking}
          guest={guest}
          CTAsOnly={flags.bookingPanelRedesign}
          handleAction={handleBookingAction}
        />
        <BoardingPassInfo
          booking={booking}
          loading={loading}
          guestMetadata={guest?.metadata}
          handleAction={handleBookingAction}
          openAccessCodes={() => openAccessCodes(true)}
        />
        <BookingFeesUpsells
          type="fees"
          guest={guest}
          loading={loading}
          booking={booking}
          handleAction={handleBookingAction}
        />
        <BookingFeesUpsells
          guest={guest}
          loading={loading}
          booking={booking}
          handleAction={handleBookingAction}
        />
        <BookingAccessCodes
          booking={booking}
          forceOpen={accessCodesOpen}
          closeAccessCodes={() => openAccessCodes(false)}
        />
        <BookingCustomFields
          booking={booking}
          onCustomFieldSave={handleCustomFieldUpdate}
        />
      </CardContent>
    </Card>
  );
}

export default withLDConsumer()(BookingPanel);
