import {makeStyles} from "@material-ui/core";
import {THEME} from "configuration/settings.js";

const useScheduledMessagePanelStyles = makeStyles((theme) => ({
  root: {padding: theme.spacing(3)},
  card: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: 10,
    border: "none",
    display: "flex",
    flexDirection: "column",
  },
  cardHeader: {padding: theme.spacing(4, 4, 1)},
  cardContent: {
    padding: theme.spacing(0, 4, 4) + "!important",
    maxHeight: 350,
    overflow: "auto",
  },
  title: {
    fontWeight: 600,
    color: THEME.grey,
  },
  listItemText: {
    margin: 0,
    minHeight: 22,
    display: "flex",
    alignItems: "center",
  },
  listItem: {
    border: "1px solid #F1F3F9",
    width: "100%",
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: theme.spacing(3),
    gap: theme.spacing(2),
    transition: "0.15s",
    minHeight: 48,
    "&:hover": {
      backgroundColor: "transparent !important",
      border: "1.2px solid #0E7AFD",
    },
    "&.-disabled": {backgroundColor: "rgba(140, 145, 150, 0.1) !important"},
  },
  itemTitleDisabled: {opacity: 0.5},
  divider: {
    backgroundColor: "rgba(13,86,140,0.03)",
    margin: theme.spacing(0, 3),
  },
  mt: {marginTop: theme.spacing(3)},
  cancelOption: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: theme.spacing(1),
  },
  checkbox: {
    padding: 0,
    marginTop: 2,
    "& svg": {fontSize: 16},
  },
  text: {
    textTransform: "none",
    paddingLeft: theme.spacing(2),
    color: "rgba(0,0,0,0.65)",
  },
  leftInput: {
    marginRight: 1,
    maxWidth: 60,
    "& div, input": {
      borderBottomRightRadius: "0 !important",
      borderTopRightRadius: "0 !important",
    },
  },
  rightInput: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
  formControlLabel: {
    alignItems: "flex-start",
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  emptyLabel: {textTransform: "none"},
  createBtn: {
    padding: 0,
    textTransform: "none",
    textAlign: "right",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(3),
  },
  timeInfo: {
    fontSize: 12,
    flexShrink: 0,
    minHeight: 22,
    display: "flex",
    alignItems: "center",
  },
  messagesInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    paddingBottom: theme.spacing(3),
  },
  btnSelector: {padding: theme.spacing(1, 3)},
}));

export default useScheduledMessagePanelStyles;
