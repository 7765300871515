import {Box, styled} from "@material-ui/core";
import TemplateCard from "components/Cards/TemplateCard";
import ProductIcon from "core/icons/ProductIcon";
import React, {useState} from "react";
import {getProductBGImage} from "utilities/helperFunctions";

const TemplateContainer = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  "&::-webkit-scrollbar": {width: "0!important"},
}));

const TemplatesList = ({
  resource,
  obj,
  templateKeyName,
  currency,
  handleItemSelect,
}) => {
  const [expanded, setExpanded] = useState(null);
  const availableKeys = Object.keys(obj);

  return (
    <TemplateContainer>
      {availableKeys.map((k) => {
        const templ = {...obj[k], [templateKeyName]: k};
        return (
          <TemplateCard
            key={k}
            expandable
            type={resource}
            currency={currency}
            subheader={templ.header}
            expanded={expanded === k}
            expandItem={() => setExpanded((prev) => (prev === k ? null : k))}
            icon={<ProductIcon type={resource} product={k} />}
            template={templ}
            title={templ.name}
            bgImage={getProductBGImage(resource, k)}
            onClick={() => {
              setExpanded(null);
              handleItemSelect(templ, k);
            }}
          />
        );
      })}
    </TemplateContainer>
  );
};

export default TemplatesList;
