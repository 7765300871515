import {API} from "aws-amplify";

export const getNotifications = async (params) => {
  try {
    const response = await API.get("ensoAPI", `/notifications?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const patchNotification = async (body) => {
  try {
    const response = await API.patch("ensoAPI", "/notifications", body);
    return response;
  } catch (err) {
    throw err;
  }
};
