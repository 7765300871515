import React from "react";
import {withRouter} from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    this.props.history.push("/admin/error");
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return <></>;
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
