import React from "react";
import BaseFieldComponent from "../BaseFieldComponent";
import {withStyles} from "@material-ui/core/styles";
import {countryName} from "configuration/constants";
import GenericSelectionInput from "../../input/GenericSelectionInput";
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {CustomCountryFlag} from "../../../../components/Helpers/filterHelpers";
import {THEME} from "../../../../configuration/settings";

const styles = (theme) => ({
  text: {
    fontWeight: 400,
    color: THEME.grey,
  },
  listItemIcon: {
    minWidth: "fit-content",
    paddingRight: theme.spacing(3),
  },
});

class CountryField extends BaseFieldComponent {
  renderInput({fieldData, handleValueChange, options, classes}) {
    return (
      <GenericSelectionInput
        options={options}
        enableSearch={true}
        edit={true}
        value={fieldData.val}
        multiselect={fieldData?.operator !== "is_not"}
        onValueChange={(newValue) => {
          handleValueChange(
            fieldData.type,
            Array.isArray(newValue)
              ? newValue.filter((f) => f !== "")
              : newValue,
          );
        }}
        label="Choose Country"
        customMenuItem={(option, selectedOptions) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={selectedOptions.includes(option)} />
            <ListItemIcon className={classes.listItemIcon}>
              <CustomCountryFlag countryCode={option} />
            </ListItemIcon>
            <ListItemText primary={countryName[option]} />
          </MenuItem>
        )}
      />
    );
  }

  renderDisplay({fieldData, classes}) {
    return (
      <Typography variant="body1" className={classes.text}>
        {fieldData.val ?? "-"}
      </Typography>
    );
  }

  renderText({fieldData, classes}) {
    return fieldData.val ?? "-";
  }
}

export default withStyles(styles)(CountryField);
