import React from "react";
// UI
import {makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  statusRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(3),
  },
  statusDot: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: theme.palette.grey[400],
    marginRight: theme.spacing(1),
    "&.-unpaired": {backgroundColor: "#fc6f03"},
  },
  statusText: {
    color: theme.palette.grey[500],
    "&.-unpaired": {color: "#fc6f03"},
  },
}));

export default function TemplateStatus({template}) {
  const classes = useStyles();

  if (!template) {
    return null;
  }
  return (
    <div className={classes.statusRow}>
      <div
        className={clsx(classes.statusDot, {"-unpaired": !template.paired})}
      />
      <Typography
        className={clsx(classes.statusText, {"-unpaired": !template.paired})}
      >
        {template.paired ? "Ready" : "Needs to be Set Up"}
      </Typography>
    </div>
  );
}
