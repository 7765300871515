import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, styled, SvgIcon, Typography, useTheme} from "@material-ui/core";
import {ReactComponent as EnsoIcon} from "assets/icons/Enso.svg";
import PrimaryButton from "core/buttons/PrimaryButton";
import CustomSwitch from "core/switches/CustomSwitch";
import EnsoExperiencesDialog from "../dialog/EnsoExperiencesDialog";
import {updateViatorService} from "redux/actions/servicesActions";
import {useTranslation} from "react-i18next";

const Card = styled(Box)(({theme}) => ({
  borderRadius: "10px",
  border: "1px solid #F5F5F5",
  backgroundColor: "#F5F5F5",
  display: "flex",
  flexDirection: "row",
  padding: theme.spacing(3),
  gap: theme.spacing(3),
  alignItems: "center",
}));

const IconContainer = styled(Box)(({theme}) => ({
  borderRadius: "5px",
  backgroundColor: theme.palette.primary.main,
  width: 60,
  height: 60,
  padding: theme.spacing(3),
}));

const TextContainer = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
}));

const UpsellProviderCard = ({}) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  const loading = useSelector((state) => state.defaultReducer.loading);
  const viatorEnabled =
    !!serviceAccounts?.accounts?.viator?.[0]?.properties?.enabled;
  const [openModal, setOpenModal] = useState(false);
  const [enabled, setEnabled] = useState(viatorEnabled);

  useEffect(() => {
    setEnabled(!!serviceAccounts?.accounts?.viator?.[0]?.properties?.enabled);
  }, [serviceAccounts]);

  const toggleSwitch = () => {
    const currValue = enabled;
    setEnabled(!currValue);
    dispatch(
      updateViatorService({
        enable: !enabled,
        create: !serviceAccounts?.accounts?.viator,
        onError: () => setEnabled((prev) => currValue),
      }),
    );
  };

  return (
    <>
      <EnsoExperiencesDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
      <Card>
        <IconContainer>
          <SvgIcon
            component={EnsoIcon}
            viewBox="0 0 30 29"
            style={{
              color: theme.palette.common.white,
              width: "100%",
              height: "100%",
            }}
          />
        </IconContainer>
        <TextContainer>
          <Typography variant="h1">
            {t("enso-experiences-item-title")}
          </Typography>
          <Typography>{t("enso-experiences-item-text")}</Typography>
        </TextContainer>
        <PrimaryButton
          variant="text"
          color="secondary"
          label="Learn More"
          style={{
            padding: 0,
            alignSelf: "center",
            height: 30,
            backgroundColor: "transparent",
          }}
          onClick={() => setOpenModal(true)}
        />
        <Box alignSelf={"center"}>
          <CustomSwitch
            checked={enabled}
            disabled={loading.viator_service || loading.service_accounts}
            customActiveColor={theme.palette.secondary.main}
            onClick={toggleSwitch}
          />
        </Box>
      </Card>
    </>
  );
};

export default UpsellProviderCard;
