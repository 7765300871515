import React from "react";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {setUser} from "redux/actions/accountsActions";
import {Auth} from "aws-amplify";
import EnterCode from "./EnterCode";
import EnterEmail from "./EnterEmail";

class Forgot extends React.Component {
  state = {
    new_password: "",
    new_password_confirm: "",
    pass_match: true,
    email: "",
    email_state: true,
    enso_key: "",
    code: "",
    code_state: true,
    redirect_page: "",
    step: 1,
  };

  changeCode = (code) => {
    this.setState({code: code});
  };

  handleChange = (input) => (e) => {
    if (input === "new_password_confirm") {
      if (e.target.value === this.state.new_password) {
        this.setState({pass_match: false});
      } else {
        this.setState({pass_match: false});
      }
    }
    if (input === "email_input") {
      e.target.value = e.target.value.toLowerCase();
    }

    console.log("GOT INPUT", input, e.target.value);
    this.setState({
      [input]: e.target.value,
      email_state: true,
    });
  };

  selectPage = (type) => {
    if (type === "step_1") {
      this.setState({step: 1});
    } else {
      this.setState({
        redirect_page: type,
      });
    }
  };

  requestReset = () => {
    const username = this.state.email;
    if (username === "") {
      this.setState({email_state: true});
      return false;
    }
    Auth.forgotPassword(username)
      .then((data) => {
        this.setState({
          step: 2,
        });
        return false;
      })
      .catch((err) => {
        switch (err.code) {
          case "UserNotFoundException":
            this.setState({email_state: false});
            break;
          case "NotAuthorizedException":
            this.setState({email_state: false});
            return false;
          default:
            this.setState({email_state: false});
            return false;
        }
      });
  };

  resetPassword = () => {
    this.setState({
      step: 3,
    });
    Auth.forgotPasswordSubmit(
      this.state.email,
      this.state.code,
      this.state.new_password,
    )
      .then((data) => {
        Auth.signIn(this.state.email, this.state.new_password).then((data) => {
          Auth.currentAuthenticatedUser().then((user) => {
            this.props.setUser(user.username);
            this.setState({redirect_page: "login"});
          });
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          step: 2,
        });
      });
  };

  switchPage(step) {
    if (this.state.redirect_page === "login") {
      return <Redirect push to="/admin/listings" />;
    }

    switch (this.state.step) {
      case 1:
        return (
          <EnterEmail
            requestReset={this.requestReset}
            email_state={this.state.email_state}
            selectPage={this.selectPage}
            handleChange={this.handleChange}
          />
        );
      case 2:
        return (
          <EnterCode
            resetPassword={this.resetPassword}
            handleChange={this.handleChange}
            selectPage={this.selectPage}
            code_state={this.state.code_state}
            pass_match={this.state.pass_match}
            changeCode={this.changeCode}
            requestReset={this.requestReset}
          />
        );
      default:
        return <></>;
    }
  }

  render() {
    if (this.state.redirect_page === "login") {
      return <Redirect push to="/auth/login" />;
    }
    if (this.state.redirect_page === "listings") {
      return <Redirect push to="/admin/listings" />;
    } else
      return (
        <div className="login_row">
          <div className="login_column">
            <img
              alt="house example"
              style={{
                borderRadius: "0px !important",
                width: "100%",
                height: "100vh",
              }}
              src={require("assets/img/airbnb_register_photo.jpg")}
            />
          </div>
          <div className="login_column" style={{backgroundColor: "#FFFFFF"}}>
            {this.switchPage(this.state.step)}
          </div>
        </div>
      );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUser: (enso_key) => {
    dispatch(setUser(enso_key));
  },
});

export default connect(null, mapDispatchToProps)(Forgot);
