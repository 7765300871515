import React from "react";
// UI
import {makeStyles} from "@material-ui/core/styles";
import {Tooltip, Typography} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  bg: {
    width: 15,
    height: 15,
    cursor: "pointer",
    borderRadius: 999,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    aspectRatio: 1,
    border: (props) =>
      !!props.subdued ? `2px solid ${THEME.subdued}` : "none",
    background: (props) =>
      !!props.subdued
        ? "transparent"
        : !!props.dark
          ? "rgba(255, 255, 255, .20)"
          : "rgba(13, 86, 140, 0.05)",
    margin: (props) => (!!props.disableGutters ? 0 : theme.spacing(0, 2)),
  },
  text: {
    fontSize: 10,
    fontWeight: (props) => (!!props.subdued ? 700 : 400),
    color: (props) =>
      !!props.subdued
        ? THEME.subdued
        : !!props.dark
          ? "#FFF"
          : theme.palette.primary.main,
    lineHeight: (props) => (!!props.subdued ? "15px" : "16px"),
  },
  tooltip: {
    border: "none",
    "&.disableMinWidth": {minWidth: "130px !important"},
  },
  customTooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: "0px 0px 50px 0px rgba(0, 0, 0, 0.10)",
    padding: theme.spacing(3),
    borderRadius: 10,
    minWidth: 250,
  },
  arrow: {
    marginTop: "-9px!important",
    "&.customContent": {color: theme.palette.common.white},
  },
  infoIcon: {
    color: THEME.subdued,
    fontSize: 16,
  },
}));

export default function InfoText({
  text,
  dark,
  subdued,
  interactive,
  showCustomContent,
  disableMinWidth,
  disableGutters = false,
}) {
  const classes = useStyles({disableGutters, dark, subdued});

  return (
    <Tooltip
      arrow
      title={text}
      enterDelay={200}
      leaveDelay={200}
      enterTouchDelay={0}
      interactive={interactive}
      classes={{
        tooltip: clsx(classes.tooltip, {
          [classes.customTooltip]: !!showCustomContent,
          disableMinWidth: !!disableMinWidth,
        }),
        arrow: clsx(classes.arrow, {customContent: !!showCustomContent}),
      }}
    >
      {!!subdued ? (
        <InfoIcon className={classes.infoIcon} />
      ) : (
        <span className={classes.bg}>
          <Typography component="span" className={classes.text}>
            i
          </Typography>
        </span>
      )}
    </Tooltip>
  );
}
