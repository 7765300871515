import React from "react";
// UI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  IconButton,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useBookingPanelStyles from "styles/useBookingPanelStyles";
import {ReactComponent as FieldListIcon} from "assets/icons/list.svg";
import {ReactComponent as PencilIcon} from "assets/icons/pencil.svg";
// Custom
import EmptyContentText from "components/Misc/EmptyContentText";
import AccordionItem from "core/listItems/AccordionItem";
import PrimaryButton from "core/buttons/PrimaryButton";
import CustomDialog from "core/dialogs/CustomDialog";
import InputField from "core/inputs/InputField";
// Utils
import {getVariablesAuth} from "redux/api/settingsAPI";
import {THEME} from "configuration/settings";
import clsx from "clsx";

export default function BookingCustomFields({booking, onCustomFieldSave}) {
  const classes = useBookingPanelStyles();
  const [editElement, setEditElement] = React.useState({
    id: null,
    key: null,
    val: null,
    subkey: null,
  });
  const [expanded, setExpanded] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [loadingAutohost, setLoadingAutohost] = React.useState(false);
  const totalFields = Object.keys(booking.custom || {}).length;
  let isUsingAutohost = React.useMemo(() => {
    let bkCstmKeys = Object.keys(booking.custom ?? {});
    if (bkCstmKeys.includes("autohost_status")) {
      return true;
    } else {
      return false;
    }
  }, [booking]);

  React.useEffect(() => {
    if (!window.AutohostSDK || !booking || !modalOpen) {
      return;
    }

    async function initializeAutohost() {
      setLoadingAutohost((prev) => true);
      console.log("INITIALIZING AUTOHOST");
      const AutohostSDK = window.AutohostSDK;
      let params = `?auth_type=autohost_auth&resource_id=${booking.custom?.autohost_booking_id?.value}`;
      let response = await getVariablesAuth(params);
      setLoadingAutohost((prev) => false);
      const client = AutohostSDK.init({apiToken: response.auth_token});
      let options = {
        reservationId: booking.custom?.autohost_booking_id?.value,
        styles: {
          root: {padding: "15px"},
          card: {"box-shadow": "none"},
        },
      };
      client
        .component("ReservationResults", options)
        .mount("#autohost-details-target");
    }

    initializeAutohost();
  }, [modalOpen]);

  const handleCancel = () => {
    setEditElement((prev) => ({id: null, key: null, val: null, subkey: null}));
  };

  const handleEdit = (id, key, val, subkey) => () => {
    setEditElement((prev) => ({id, key, val, subkey}));
  };

  const handleValueChange = (e) => {
    const val = e.target.value;
    setEditElement((prev) => ({...prev, val: val}));
  };

  const handleSave = (id, key, newElement, isDict) => () => {
    if (!!isDict) {
      onCustomFieldSave(id, {
        [id]: {
          name: key,
          value: {
            ...booking.custom[id].value,
            [newElement.subkey]: newElement.val,
          },
          source: "user",
        },
      });
    } else {
      onCustomFieldSave(id, {
        [id]: {name: key, value: newElement.val, source: "user"},
      });
    }
    booking.custom[id].source = "user";
    handleCancel();
  };

  function getValueContent(val = {}, isDict) {
    let isEmpty = isDict ? !Object.keys(val).length : !val;
    let newVal = isDict ? JSON.stringify(val, null, 2) : val;
    if (isEmpty) {
      return <EmptyContentText basic label="Empty" />;
    } else {
      return newVal;
    }
  }

  function EditableRow({id, key, isDict = false}) {
    return (
      <Box>
        <InputField
          editOnly
          fullWidth
          useButtons
          disableEnterSave
          label={key}
          value={editElement.val}
          onChange={handleValueChange}
          onCancel={handleCancel}
          onSave={handleSave(id, key, editElement, isDict)}
        />
      </Box>
    );
  }

  function ViewOnlyRow({id, key, val, source, isDict = false, dense = false}) {
    let isForeignKey = source === "foreign_key";
    let isManuallyUpdated = source === "user";
    let showAutohostBtn = isUsingAutohost && id === "autohost_status";
    return (
      <div
        key={key}
        onClick={
          !isForeignKey && !showAutohostBtn
            ? handleEdit(id, key, isDict ? val[1] : val, isDict ? val[0] : null)
            : undefined
        }
      >
        <div
          className={clsx(classes.customFieldRow, {
            "-disable": isForeignKey,
            [classes.defaultCursor]: !isForeignKey && showAutohostBtn,
          })}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{gap: THEME.spacing.sm}}
          >
            <Typography variant="subtitle2">{isDict ? val[0] : key}</Typography>
            {showAutohostBtn && (
              <PrimaryButton
                size="small"
                variant="text"
                color="secondary"
                label="See Autohost details"
                className={classes.autohostBtn}
                onClick={openModal}
              />
            )}
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography component="div" className={classes.customFieldText}>
              {getValueContent(isDict ? val[1] : val, isDict)}
            </Typography>
            {!isForeignKey && (
              <IconButton
                size="small"
                className={clsx(classes.iconBtn, "-iconBtn", {"-dense": dense})}
                onClick={
                  !isForeignKey
                    ? handleEdit(
                        id,
                        key,
                        isDict ? val[1] : val,
                        isDict ? val[0] : null,
                      )
                    : undefined
                }
              >
                <SvgIcon
                  viewBox="0 0 12 12"
                  className={classes.editIcon}
                  component={PencilIcon}
                />
              </IconButton>
            )}
          </Box>
        </div>
        {!isDict && isManuallyUpdated && (
          <Typography className={clsx(classes.tagText)}>
            {" "}
            Manually Edited{" "}
          </Typography>
        )}
      </div>
    );
  }

  function SubItemRow({k, v, ...other}) {
    return (
      <div className={classes.subitemRow}>
        {Object.keys(v).map((valKey) => {
          let isValueDict = typeof v[valKey] === "object";
          return ViewOnlyRow({
            key: valKey,
            val: isValueDict ? [valKey, v[valKey]] : v[valKey],
            isDict: isValueDict,
            ...other,
          });
        })}
      </div>
    );
  }

  function DictionaryRow({id, key, val, source, isSubitem}) {
    let isManuallyUpdated = source === "user";
    let isArray = Array.isArray(val);
    return (
      <Accordion className={clsx(classes.accordion, {subitem: !!isSubitem})}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummary}
          classes={{
            expandIcon: classes.expandIcon,
            content: classes.summaryContent,
          }}
        >
          <div style={{display: "flex", flexDirection: "column"}}>
            <Typography className={classes.leftText}>{key}</Typography>
            {isManuallyUpdated && (
              <Typography className={clsx(classes.tagText)}>
                {" "}
                Manually Edited{" "}
              </Typography>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails
          className={clsx(classes.accordionDetails, {
            [classes.subitemRow]: !isArray && isSubitem,
          })}
        >
          {Object.entries(val).map((v, i) => {
            const edit = editElement.key === key && editElement.subkey === v[0];
            if (!v[1]) {
              return;
            }
            if (isArray) {
              v[0] = `Item ${v[0]}`;
            }
            return (
              <React.Fragment key={`${key}-${i}`}>
                {edit
                  ? EditableRow({id, key, isDict: true, dense: true})
                  : typeof v[1] === "object"
                    ? isSubitem
                      ? isArray
                        ? SubItemRow({
                            id,
                            k: v[0],
                            v: v[1],
                            source: "foreign_key",
                            dense: true,
                          })
                        : ViewOnlyRow({
                            id,
                            key: v[0],
                            val: [v[0], v[1]],
                            source,
                            isDict: true,
                            dense: true,
                          })
                      : DictionaryRow({
                          id,
                          key: v[0],
                          val: v[1],
                          source: "foreign_key",
                          isSubitem: true,
                        })
                    : ViewOnlyRow({
                        id,
                        key,
                        val: v,
                        source,
                        isDict: true,
                        dense: true,
                      })}
              </React.Fragment>
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  }

  function CustomField(id, key, val, source) {
    if (!val || typeof val !== "object") {
      return editElement.key === key
        ? EditableRow({id, key})
        : ViewOnlyRow({id, key, val, source});
    }
    return DictionaryRow({id, key, val, source});
  }

  const onClose = () => setModalOpen(false);

  const openModal = () => {
    setLoadingAutohost(true);
    setModalOpen(true);
  };

  const autohostModal = (
    <CustomDialog
      open={modalOpen}
      fullWidth
      maxWidth="sm"
      customHeight={"100%"}
      disableContentPadding
      title="Autohost details"
      content={
        loadingAutohost ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={"100%"}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div
            id="autohost-details-target"
            className={classes.autohostDetailsContainer}
          />
        )
      }
      onClose={() => onClose()}
      disableActions
    />
  );

  if (!totalFields) {
    return null;
  }
  return (
    <>
      {autohostModal}
      <AccordionItem
        divider
        disableHover
        infoText={
          totalFields === 1 ? "1 custom field" : `${totalFields} Custom Fields`
        }
        expanded={expanded}
        title={"Custom Fields"}
        onClick={() => setExpanded((prev) => !prev)}
        icon={<SvgIcon viewBox="0 0 16 16" component={FieldListIcon} />}
      />
      {!!expanded && (
        <div className={classes.expandedSection}>
          {Object.entries(booking.custom || {}).map((entry) => (
            <Box key={entry[0]} p={2}>
              {CustomField(
                entry[0],
                entry[1].name,
                entry[1].value,
                entry[1].source,
              )}
            </Box>
          ))}
        </div>
      )}
    </>
  );
}
