import {fieldParams} from "../../../configuration/specs";
import {collectFieldTypeEnum} from "../../../configuration/enums";

/**
 * @author Chloe Hill
 * @param fieldInfo
 * @param customFieldData
 * @param children
 * @returns {children: {optionData: [], customFieldData: {}, operatorType: string, componentMap: {}} => JSX.Element
 * @name FieldComponentProvider
 * @description Provides the necessary data for options, custom component mappings and field operator types.
 * @usage Universal component for handling fields anywhere in the platform.
 */
export default function FieldComponentProvider({
  fieldInfo,
  customFieldData,
  children,
}) {
  /**
   * @name getFieldOperatorType
   * @description Determines the operator type for a given field.
   * @param field - Field name
   * @param customField - Singlular custom field object
   * @returns {string} - Operator type based on field type
   */
  function getFieldOperatorType(field, customField = null) {
    let operator = null;

    if (fieldParams[field]) {
      operator = fieldParams[field].field_type || fieldParams[field].type;
    } else if (customField) {
      operator = customField.type; // Directly use the provided customField's type
    }

    if (operator === "") {
      operator = null;
    }

    switch (operator) {
      case collectFieldTypeEnum.custom:
        if (fieldParams[field]?.type === "keyword") return "keyword";
        if (fieldParams[field]?.type) return fieldParams[field].type;
        break;
      case collectFieldTypeEnum.multi_select:
        return "array";
      case collectFieldTypeEnum.single_select:
        return "text";
      case collectFieldTypeEnum.date:
      case collectFieldTypeEnum.time:
        return "time";
      case collectFieldTypeEnum.number:
        return "number";
      case collectFieldTypeEnum.image:
        return "text";
      case collectFieldTypeEnum.checkbox:
        return "boolean";
      case collectFieldTypeEnum.time_offset:
        return "time";
      case "keyword":
        return "keyword";
      default:
        return operator || "text";
    }
  }

  /**
   * @name getOptionData
   * @description Generates the option data and filtered fields for the given fieldinfo, pulling from custom fields and hardcoded enum values.
   * @returns {{optionData: [], filteredField: {}}}
   */
  function getOptionData() {
    let optionData = [];
    let field = fieldParams[fieldInfo.field];

    if (field?.options) {
      optionData = field?.options;
    }

    let filteredField;
    let i = 0,
      len = Object.keys(customFieldData)?.length;
    for (; i <= len; i++) {
      filteredField = customFieldData[Object.keys(customFieldData)[i]]?.find(
        (f) => {
          return f.field === fieldInfo.field;
        },
      );
      if (!!filteredField) {
        break;
      }
    }

    if (filteredField && filteredField.data) {
      optionData = [
        ...new Set([
          ...optionData,
          ...filteredField?.data?.filter((f) => f !== ""),
        ]),
      ];
    }

    return {optionData, filteredField};
  }

  /**
   * @name getComponentMap
   * @description Loads and maps custom field components, returning a map of possible components.
   * @example const CustomComponent = componentMap["MyCustomComponent"];
   */
  function getComponentMap() {
    if (process.env.NODE_ENV === "development") {
      // Use require.context dynamically during development
      const componentContext = require.context(
        "../field/fields",
        false,
        /\.jsx?$/,
      );
      return componentContext.keys().reduce((map, filePath) => {
        const componentName = filePath
          .replace("./", "")
          .replace(/\.(js|jsx)$/, "");
        map[componentName] = componentContext(filePath).default;
        return map;
      }, {});
    } else {
      // Use pre-generated index.js in production
      return require("../field/fields").default || require("../field/fields");
    }
  }

  const {optionData, filteredField} = getOptionData();
  const componentMap = getComponentMap();
  const operatorType = getFieldOperatorType(fieldInfo.field, filteredField);
  // console.log("FINAL OPERATOR TYPE", operatorType)
  return children({
    optionData,
    customFieldData,
    operatorType,
    componentMap,
  });
}
