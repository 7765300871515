import React from "react";
import {useSelector} from "react-redux";
import {Col, Row} from "reactstrap";
import {MenuItem, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import FilledTextField from "core/inputs/FilledTextField";
import FilledSelect from "core/selects/FilledSelect";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.6)",
    marginBottom: theme.spacing(3),
  },
  unit: {
    width: "fit-content",
    position: "absolute",
    top: 23,
    color: "rgba(0,0,0,0.5)",
  },
}));

export default function Temperature({value = "", updateValue}) {
  const classes = useStyles();
  const language = useSelector((state) => state.defaultReducer.language);
  const [unit, setUnit] = React.useState("c");
  const [temp, setTemp] = React.useState(value);

  React.useEffect(() => {
    if (!!value) {
      const isC = value.includes("C");
      const isF = value.includes("F");
      const isK = value.includes("K");
      const hasUnit = isC || isF || isK;

      setTemp((prev) =>
        hasUnit ? value.substring(0, value.length - 1) : value,
      );
      setUnit((prev) => (isF ? "f" : isK ? "k" : "c"));
    }
  }, [value]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (value !== temp) {
        updateValue([{language, value: `${temp}${unit.toUpperCase()}`}]);
      }
    }, 150);

    return () => clearTimeout(timer);
  }, [temp, unit]);

  const onTempChange = (e) => {
    const val = e.target.value;
    setTemp((prev) => val);
  };

  const onUnitChange = (e) => {
    const val = e.target.value;
    setUnit((prev) => val);
  };

  function getSymbol() {
    switch (unit) {
      case "c":
        return "°C";
      case "f":
        return "°F";
      case "k":
        return "K";
      default:
        return "";
    }
  }

  return (
    <div className="mb-3">
      <Typography variant="subtitle1" className={classes.title}>
        Temperature
      </Typography>
      <Row>
        <Col xs={6}>
          <FilledTextField
            id="temperature"
            label="Temperature"
            placeholder={getSymbol()}
            onChange={onTempChange}
            value={temp}
            type="number"
            fullWidth
          />
          {!!temp.length && (
            <div
              className={classes.unit}
              style={{left: 34 + 7.7 * temp.length}}
            >
              {getSymbol()}
            </div>
          )}
        </Col>
        <Col xs={6}>
          <FilledSelect
            fullWidth
            value={unit}
            inputProps={{min: -10000, max: 10000}}
            onChange={onUnitChange}
            label="Unit of Measurement"
          >
            <MenuItem selected={unit === "c"} value="c">
              Celsius
            </MenuItem>
            <MenuItem selected={unit === "f"} value="f">
              Fahrenheit
            </MenuItem>
            <MenuItem selected={unit === "k"} value="k">
              Kelvin
            </MenuItem>
          </FilledSelect>
        </Col>
      </Row>
    </div>
  );
}
