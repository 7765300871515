import React from "react";
import {useSelector} from "react-redux";
import {isSafari} from "react-device-detect";
import clsx from "clsx";
// UI
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ChatBubbleIcon from "@material-ui/icons/Sms";
import EmailIcon from "@material-ui/icons/Email";
import useMessageStyles from "../../styles/useMessageStyles";
// Custom
import FormattedMessage from "components/Helpers/FormattedMessage";
import StyledEmailCard from "components/Cards/StyledEmailCard";
import PartialComponentLayer from "components/Helpers/PartialComponentLayer";
import RateResponse from "../Misc/RateResponse";

import useComponentHeightObserver from "hooks/useComponentHeightObserver";

export default function AutoMessage({
  m,
  who,
  getHTML,
  guest_phone,
  sentByLabel,
  onEmailLoad,
}) {
  const classes = useMessageStyles({who, isSafari});
  const userProfile = useSelector((state) => state.defaultReducer.user_profile);
  const wrapperRef = React.useRef();
  // General
  const showSMS = m.SMS_details && !!m.SMS_details.body.trim();
  const showAirbnb = m.airbnb_details && !!m.airbnb_details.body.trim();
  const showEmail = !!m.email_details;
  const ensoUsers = userProfile ? userProfile.enso_users : null;
  const assignedTo =
    ensoUsers && m.assigned_to_id
      ? ensoUsers.find((eu) => eu.enso_user_id === m.assigned_to_id)
      : null;
  const assignedToInfo = !!assignedTo
    ? {
        name: assignedTo.name,
        email: assignedTo.reply_email,
        phone: guest_phone ? guest_phone.split("#")[3] : "",
        picture: assignedTo.picture,
      }
    : null;
  const avalChannels = {
    email: {valid: showEmail, index: 2},
    airbnb: {valid: showAirbnb, index: 1},
    SMS: {valid: showSMS, index: 0},
  };
  // State
  const [channel, setChannel] = React.useState(
    showSMS ? 0 : showAirbnb ? 1 : showEmail ? 2 : 3,
  );
  const [expanded, setExpanded] = React.useState(false);

  const isLarge = useComponentHeightObserver({ref: wrapperRef, expanded});

  React.useEffect(() => {
    const timer = setTimeout(() => {
      onEmailLoad();
    }, 0);
    return () => clearTimeout(timer);
  }, []);

  const getChannelIcon = (type) => {
    switch (type) {
      case "SMS":
        return <ChatBubbleIcon className={classes.channelIcon} />;
      case "airbnb":
        return (
          <img
            src={require("assets/img/airbnb_white.png")}
            className={classes.channelIcon}
          />
        );
      case "vrbo":
        return (
          <img
            src={require("assets/img/vrbo-V-logo.png")}
            className={classes.channelIcon}
          />
        );
      case "homeaway":
        return (
          <img
            src={require("assets/img/homeaway_logo.svg")}
            className={classes.channelIcon}
          />
        );
      case "booking_com":
        return (
          <img
            src={require("assets/img/bookingcom_logo.svg")}
            className={classes.channelIcon}
          />
        );
      case "email":
        return <EmailIcon className={classes.channelIcon} />;
    }
  };

  const channels = (
    <div className={clsx(classes.channelSection, classes[`${who}Channels`])}>
      {Object.keys(avalChannels).map(
        (c) =>
          avalChannels[c].valid && (
            <div
              key={c}
              className={clsx(classes.sectionChannel, classes[c], {
                [classes.selected]: channel === avalChannels[c].index,
              })}
              onClick={() => setChannel(avalChannels[c].index)}
            >
              {getChannelIcon(c)}
            </div>
          ),
      )}
    </div>
  );

  const showCommonMsg = (msg) => (
    <Typography style={{fontSize: 14}}>
      <FormattedMessage message={msg} />
    </Typography>
  );

  return (
    <>
      <div className={clsx(classes.messageWrapper, classes.automessageWrapper)}>
        {who === "them" && channels}
        <div className={classes.automessageText}>
          <div
            className={clsx(classes.bubblePeak, classes[`${who}BubblePeak`])}
          />
          <div
            className={clsx(
              classes.bubblePeak,
              classes[`${who}BubblePeakAfter`],
            )}
          />
          <div
            ref={wrapperRef}
            className={clsx(classes.textWrapper, classes[`${who}Text`], {
              [classes.fullMessage]: expanded,
              [classes.partialMessage]: !expanded && isLarge,
            })}
          >
            {/*m.sender_type === 'chatbot' && <RateResponse message={m} />*/}
            {!expanded && isLarge && (
              <PartialComponentLayer
                who={who}
                label="Show Full Message"
                onExpand={() => setExpanded(true)}
              />
            )}
            {
              {
                0: showSMS && showCommonMsg(m.SMS_details.body),
                1: showAirbnb && showCommonMsg(m.airbnb_details.body),
                2:
                  showEmail &&
                  (m.email_details.hasOwnProperty("html") &&
                  m.email_details.html ? (
                    <div>
                      <Typography style={{fontSize: 14}}>
                        <strong>Subject:</strong> {m.email_details.subject}
                        <Divider />
                        <div
                          className={classes.emailMsg}
                          dangerouslySetInnerHTML={getHTML(
                            m.email_details.html,
                          )}
                        />
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      <Typography style={{fontSize: 14}}>
                        <strong>Subject:</strong> {m.email_details.subject}
                      </Typography>
                      <Divider />
                      <div style={{marginTop: 8}}>
                        <StyledEmailCard
                          email_header={m.email_details.header}
                          email_body={m.email_details.body}
                          variant="auto_message"
                          grid={false}
                          image={m.email_details.picture}
                          //contact_picture={assignedToInfo.picture}
                          //contact_name={assignedToInfo.name}
                          //contact_email={assignedToInfo.email}
                          //contact_phone={assignedToInfo.phone}
                        />
                      </div>
                    </div>
                  )),
              }[channel]
            }
          </div>
        </div>
        {who === "me" && channels}
      </div>
      <Typography variant="caption" className={classes.sentLabel}>
        {sentByLabel}
      </Typography>
    </>
  );
}
