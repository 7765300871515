import React from "react";
import {createBrowserHistory} from "history";
import {Router, Route, Switch, Redirect} from "react-router-dom";
import {Amplify} from "aws-amplify";
import {Provider} from "react-redux";
import store from "redux/index.js";
import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";
import ScrollTop from "./components/Helpers/ScrollTop.jsx";
import {Storage} from "aws-amplify";
import config from "configuration/settings.js";
import {ThemeProvider} from "@material-ui/core";
import ErrorBoundary from "routes/ErrorBoundary.jsx";
import theme from "configuration/theme.js";
import PaymentModal from "components/Dialogs/PaymentModal.jsx";
import {I18nextProvider} from "react-i18next";
import {consoleI18next} from "configuration/i18next.js";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "ensoAPI",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
  Storage: {
    bucket: config.s3.BUCKET,
    region: config.s3.REGION,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
});

Storage.configure({
  bucket: config.s3.BUCKET,
  level: "public",
  region: config.s3.REGION,
  identityPoolId: config.cognito.IDENTITY_POOL_ID,
});

const hist = createBrowserHistory();

const App = () => {
  return (
    <I18nextProvider i18n={consoleI18next}>
      <Provider store={store}>
        <Router history={hist}>
          <ThemeProvider theme={theme}>
            <ErrorBoundary>
              <PaymentModal />
              <ScrollTop />
              <Switch>
                <Route
                  path="/auth"
                  render={(props) => <AuthLayout {...props} />}
                />
                <Route
                  path="/admin"
                  render={(props) => <AdminLayout {...props} />}
                />
                <Redirect from="/" to="/auth/login" />
              </Switch>
            </ErrorBoundary>
          </ThemeProvider>
        </Router>
      </Provider>
    </I18nextProvider>
  );
};

export default App;
