import React from "react";
import PrimaryButton from "./PrimaryButton";
import ArrowNextIcon from "@material-ui/icons/NavigateNext";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as UserIcon} from "assets/icons/user.svg";
import {SvgIcon} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: (props) =>
      `${!!props.transparent ? "transparent" : "#FFF"} !important`,
    padding: 0,
  },
  btnLabel: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: "28px",
    display: "flex",
    alignItems: "center",
  },
  btnLabelSecondary: {
    fontSize: 17,
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "-0.41px",
    display: "flex",
    alignItems: "center",
  },
  btnIcon: {
    fontSize: "28px !important",
    paddingTop: 2,
  },
  endIcon: {
    marginRight: -12,
    marginLeft: 0,
  },
}));

const NextButton = ({
  label = "Back",
  size = "large",
  variant = "text",
  secondary,
  transparent,
  goBack = () => null,
}) => {
  const classes = useStyles({transparent});

  return (
    <PrimaryButton
      label={label}
      size={size}
      color={!!secondary ? "secondary" : "primary"}
      variant={variant}
      className={classes.btn}
      icon={
        secondary ? (
          <SvgIcon color="secondary" viewBox="0 0 24 24" component={UserIcon} />
        ) : (
          <ArrowNextIcon className={classes.btnIcon} />
        )
      }
      iconPlacement={secondary ? "start" : "end"}
      onClick={() => goBack()}
      classes={{
        label: clsx({
          [classes.btnLabel]: !secondary,
          [classes.btnLabelSecondary]: !!secondary,
        }),
        endIcon: classes.endIcon,
      }}
    />
  );
};

export default NextButton;
