import React from "react";
// UI
import {
  Button,
  MenuItem,
  MenuList,
  SvgIcon,
  makeStyles,
} from "@material-ui/core";
import {ReactComponent as EmptyFileIcon} from "assets/icons/file.svg";
import {ReactComponent as FileIcon} from "assets/icons/filled-file.svg";
import {ReactComponent as HomeIcon} from "assets/icons/house.svg";
// Custom
import ExpandButton from "core/buttons/ExpandButton";
import CustomMenu from "core/menus/CustomMenu";

const useStyles = makeStyles((theme) => ({
  list: {
    padding: theme.spacing(2) + "px !important",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  menuItem: {
    fontWeight: 500,
    borderRadius: 5,
    padding: theme.spacing(2),
  },
  icon: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(3),
    width: 16,
    height: 16,
  },
  button: {padding: theme.spacing(1, 3)},
  btnsRow: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    gap: theme.spacing(2),
  },
  btn: {
    flex: 1,
    border: `1px solid ${theme.palette.text.primary}`,
    padding: theme.spacing(1, 3),
    textTransform: "none",
  },
}));

export default function CreationButton({
  type = "",
  disabled,
  onScratch = () => null,
  onExisting,
  onTemplate,
  separatedButtons = false,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const existingEnabled = !!onExisting;
  const templateEnabled = !!onTemplate;
  let btns = !!separatedButtons
    ? [
        {id: "scratch", label: "Create from scratch", show: true},
        {id: "existing", label: "Copy existing", show: !!existingEnabled},
        {
          id: "template",
          label: "Create from template",
          show: !!templateEnabled,
        },
      ]
    : [];

  const handleCloseMenu = () => {
    setAnchorEl((prev) => null);
  };

  const handleOptionClick = (opt) => () => {
    switch (opt) {
      case "scratch":
        onScratch();
        break;
      case "existing":
        onExisting();
        break;
      case "template":
        onTemplate();
        break;
      default:
        break;
    }
    handleCloseMenu();
  };

  function getMenuItem(itemType, label, iconComponent) {
    return (
      <MenuItem
        button
        disableRipple
        className={classes.menuItem}
        onClick={handleOptionClick(itemType)}
      >
        <SvgIcon
          className={classes.icon}
          viewBox="0 0 16 16"
          component={iconComponent}
        />
        {label}
      </MenuItem>
    );
  }

  function getMenuContent() {
    return (
      <MenuList className={classes.list}>
        {getMenuItem("scratch", "Create from scratch", EmptyFileIcon)}
        {!!existingEnabled &&
          getMenuItem("existing", "Copy existing", HomeIcon)}
        {!!templateEnabled &&
          getMenuItem("template", "Create from template", FileIcon)}
      </MenuList>
    );
  }

  if (!!separatedButtons) {
    return (
      <div className={classes.btnsRow}>
        {btns.map((btn) => {
          if (!!btn.show) {
            return (
              <Button
                key={btn.id}
                disableRipple
                onClick={handleOptionClick(btn.id)}
                className={classes.btn}
              >
                {btn.label}
              </Button>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  }

  return (
    <>
      <CustomMenu
        overflowAuto
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        content={getMenuContent()}
      />
      <ExpandButton
        label={`Add ${type}`}
        disabled={disabled}
        variant="contained"
        className={classes.button}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
    </>
  );
}
